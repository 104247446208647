import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

const PrivateRoute = ({ component, path, location }) => {
  const { pathname } = location;

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  if (!currentUser.objectId) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { redirectUrl: pathname },
        }}
      />
    );
  }

  return <Route path={path} component={component} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.any,
};

export default PrivateRoute;
