import _get from 'lodash/get';
import _round from 'lodash/round';
import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Button, Table, Typography, Space, Row, Col
} from 'antd';
import {
  PlusOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import Helpers from 'utils/helpers';
import {
  TextField,
  InputNumber,
  RadioGroup,
  CheckboxField,
} from 'components/Form';
import MarketPriceIcon from 'images/icon-market-price.svg';
import PackagePriceIcon from 'images/icon-package-price.svg';
import TagIcon from 'images/tag.svg';
import TrashIcon from 'images/icon-trash.svg';
import { PRODUCT_PRICE_TYPE } from 'utils/constants';

const renderPricesField = ({ fields }) => {
  const formVal = useSelector(
    (state) => _get(state.form, 'productCreateForm.values', {}),
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';
  const brandFee = _get(formVal, 'brandFee', 0);
  const cost = _get(formVal, 'cost');
  const isBasic = _get(formVal, 'isBasic');
  const columns = [
    {
      title: '名称',
      key: 'name',
      width: 150,
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        return (
          <div className="form-item-no-margin">
            {priceVal?.type === PRODUCT_PRICE_TYPE.DISCOUNT ? (
              <Field
                label={(
                  <Space>
                    <img src={TagIcon} alt="tag-icon" />
                    割引価格
                  </Space>
                )}
                name={`${price}.isSelected`}
                component={CheckboxField}
              />
            ) : (
              <Field
                name={`${price}.name`}
                component={TextField}
                disabled={priceVal?.type === PRODUCT_PRICE_TYPE.MASTER}
              />
            )}
          </div>
        );
      },
    },
    {
      title: '単価',
      key: 'priceBeforeTax',
      width: 170,
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        return (
          <div className="form-item-no-margin">
            <Field
              name={`${price}.priceBeforeTax`}
              component={InputNumber}
              min={0}
              precision={0}
              disabled={priceVal?.type === PRODUCT_PRICE_TYPE.DISCOUNT && !priceVal?.isSelected}
              formatter={(value) => Helpers.numberWithCommas(value)}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonBefore="¥"
            />
          </div>
        );
      },
    },
    {
      title: '税金',
      key: 'taxRate',
      width: 200,
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        return (
          <div className="form-item-no-margin">
            <Field
              name={`${price}.taxRate`}
              component={RadioGroup}
              disabled={priceVal?.type === PRODUCT_PRICE_TYPE.DISCOUNT && !priceVal?.isSelected}
              isButton
              fullWidth
              options={[
                {
                  objectId: 0,
                  name: '非課税',
                },
                {
                  objectId: 0.08,
                  name: '8%',
                },
                {
                  objectId: 0.1,
                  name: '10%',
                },
              ]}
            />
          </div>
        );
      },
    },
    {
      title: '税込',
      key: 'priceAfterTax',
      width: 120,
      align: 'right',
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        const taxFee = Math.floor(
          _get(priceVal, 'taxRate', 0) * _get(priceVal, 'priceBeforeTax', 0)
        );
        const priceAfterTax = _get(priceVal, 'priceBeforeTax', 0) + taxFee;
        return (
          <Typography.Text>
            ¥{Helpers.numberWithCommas(priceAfterTax)}
          </Typography.Text>
        );
      },
    },
    {
      title: '粗利',
      key: 'profit',
      width: 120,
      align: 'right',
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        const profit = _get(priceVal, 'priceBeforeTax', 0) - cost - brandFee;
        return (
          <Typography.Text>
            {(cost || cost === 0) ? `¥${Helpers.numberWithCommas(profit)}` : ''}
          </Typography.Text>
        );
      },
    },
    {
      title: '粗利率',
      key: 'profitRate',
      width: 120,
      align: 'right',
      render: ({ price }) => {
        const priceVal = _get(formVal, price);
        const priceBeforeTax = _get(priceVal, 'priceBeforeTax', 0);
        const profit = priceBeforeTax - cost - brandFee;
        const profitRate = priceBeforeTax
          ? `${_round((profit / priceBeforeTax) * 100)}%`
          : 'N/V';
        return (
          <Typography.Text>
            {cost || cost === 0 ? profitRate : ''}
          </Typography.Text>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: ({ price, index }) => {
        const priceVal = _get(formVal, price);
        return (
          <Row gutter={0}>
            <Col span={12} className="flex items-center justify-center">
              <CheckCircleFilled
                style={{
                  color: priceVal?.name ? '#399E0E' : 'rgba(0, 0, 0, 0.25)',
                }}
              />
            </Col>
            <Col span={12} className="flex items-center justify-center">
              {priceVal?.type !== PRODUCT_PRICE_TYPE.MASTER && priceVal?.type !== PRODUCT_PRICE_TYPE.DISCOUNT && (
                <Button
                  type="link"
                  icon={<img src={TrashIcon} alt="delete-icon" />}
                  onClick={() => fields.remove(index)}
                />
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  const prices = fields.map((price, index) => ({ price, index, key: index }));
  const data = [...prices];
  const discountPrice = fields?.getAll()?.some((price) => price?.type === PRODUCT_PRICE_TYPE.DISCOUNT);
  if (isShikomelSupplier && !isBasic && discountPrice) {
    data.pop();
  }

  return (
    <Table
      columns={
        !isShikomelSupplier
          ? columns.filter(
            ({ key }) => key !== 'profit' && key !== 'profitRate'
          )
          : columns
      }
      dataSource={data}
      pagination={false}
      footer={() => (
        <Button
          id="add-price-button"
          className="full-w"
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            fields.splice(-1, 0, {
              priceBeforeTax: 0,
              taxRate: 0.08,
              type: PRODUCT_PRICE_TYPE.OTHER,
            });
          }}
        >
          価格を追加
        </Button>
      )}
      scroll={{ x: 960 }}
    />
  );
};

renderPricesField.propTypes = {
  fields: PropTypes.any,
};

const PriceField = () => (
  <Form labelCol={{ span: 3 }} colon={false}>
    <Form.Item label="単価" className="price-field">
      <Form.Item>
        <FieldArray name="prices" component={renderPricesField} />
      </Form.Item>
      <Field
        label={(
          <Space>
            <img src={MarketPriceIcon} alt="market-price-icon" />
            時価
          </Space>
        )}
        description="時価商品のため注文後に価格を変更する商品の場合はチェックしてください。"
        name="isMarketPrice"
        component={CheckboxField}
      />
      <Field
        label={(
          <Space>
            <img src={PackagePriceIcon} alt="indefinite-price-icon" />
            不定貫
          </Space>
        )}
        description="不定貫商品のため注文後に数量の変更する商品の場合はチェックしてください。"
        name="isIndefinitePrice"
        component={CheckboxField}
      />
    </Form.Item>
  </Form>
);

export default PriceField;
