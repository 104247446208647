import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';

import SupplierInfo from '../SupplierInfo';
import BrandList from '../BrandList';
import RestaurantList from '../RestaurantList';
import Holiday from '../Holiday';
import ShippingFee from '../ShippingFee';

const RedirectToInAppConfig = (props) => {
  const { supplierId } = _get(props, 'match.params');
  return <Redirect to={`/suppliers/detail/${supplierId}/in-app`} />;
};

const indexRoutes = [
  {
    path: '/suppliers/detail/:supplierId/in-app',
    component: SupplierInfo,
  },
  {
    path: '/suppliers/detail/:supplierId/connected-restaurants',
    component: RestaurantList,
  },
  {
    path: '/suppliers/detail/:supplierId/connected-brands',
    component: BrandList,
  },
  {
    path: '/suppliers/detail/:supplierId/holiday',
    component: Holiday,
  },
  {
    path: '/suppliers/detail/:supplierId/shippingFee',
    component: ShippingFee,
  },
  {
    path: '/suppliers/detail/:supplierId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
