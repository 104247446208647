import Constants from 'utils/constants';
import UtilValidate from 'utils/validate';
import Regex from 'utils/regex';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    values.nameKana?.trim()
    && !Regex.REGX_KATAKANA.test(values.nameKana?.trim())
  ) {
    errors.nameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (
    values.managerNameKana?.trim()
    && !Regex.REGX_KATAKANA.test(values.managerNameKana?.trim())
  ) {
    errors.managerNameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (values.phone?.trim() && !Regex.PHONE.test(values.phone?.trim())) {
    errors.phone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (
    values.emergencyPhone?.trim()
    && !Regex.PHONE.test(values.emergencyPhone?.trim())
  ) {
    errors.emergencyPhone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (
    values.postalCode?.trim()
    && !UtilValidate.validPostalCode(values.postalCode?.trim())
  ) {
    errors.postalCode = Constants.ERROR_MESSAGE.INVALID;
  }

  return errors;
};

export default validate;
