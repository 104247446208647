import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isDirty, reset, submit, getFormValues
} from 'redux-form';
import { push } from 'connected-react-router';
import {
  Card, Modal, Typography, Space, Button, Affix, Spin
} from 'antd';
import moment from 'moment';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import {
  getSupplierHolidayListRequest,
  createSupplierHolidayRequest,
  deleteSupplierHolidayRequest,
  getSupplierDetailRequest,
  updateSupplierRequest,
} from 'providers/SupplierProvider/actions';

import toastMessage from 'utils/toastMessage';

import useTableControl from 'components/Hooks/useTableControl';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { SHIKOMEL } from 'utils/constants';
import AddHolidayModal from './Create';
import HolidaySettingForm from './HolidaySettingForm';
import Columns from './Columns';

import './style.less';

const { Text } = Typography;
const { confirm } = Modal;

const showConfirm = (message, content, actionFn, okText = 'はい', cancelText = 'いいえ') => {
  confirm({
    title: message,
    okText,
    okType: 'primary',
    cancelText,
    content,
    maskClosable: false,
    onOk: () => {
      actionFn();
    },
  });
};

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: '営業日・休業日',
  },
];

const HolidayPage = () => {
  const dispatch = useDispatch();

  const { list, total } = useSelector(
    (state) => state.supplierProvider.supplierHolidayList
  );
  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail
  );
  const isShikomelSupplier = supplierDetail?.type === SHIKOMEL;
  const isHolidaySettingDirty = useSelector(isDirty('holidaySettingForm'));
  const holidayFormValues = useSelector((state) => getFormValues('holidaySettingForm')(state));
  const [holidayModalVisible, setHolidayModalVisible] = useState(false);
  const [createHolidayLoading, setCreateHolidayLoading] = useState(false);
  const [updateActionLoading, setUpdateActionLoading] = useState(false);
  const [holidayListLoading, setHolidayListLoading] = useState(false);
  const [supplierDataLoading, setSupplierDataLoading] = useState(false);

  const [{ page, limit }, , onTableChange] = useTableControl({});

  const fetchSupplierHolidayList = async () => {
    setHolidayListLoading(true);
    try {
      await dispatch(getSupplierHolidayListRequest({ limit, page }));
    } catch (error) {
      console.error(error);
    }
    setHolidayListLoading(false);
  };

  useEffect(() => {
    fetchSupplierHolidayList();
  }, [page, limit]);

  useEffect(() => {
    const fetchSupplierDetail = async () => {
      setSupplierDataLoading(true);
      try {
        await dispatch(getSupplierDetailRequest());
      } catch (error) {
        console.error(error);
      }
      setSupplierDataLoading(false);
    };
    fetchSupplierDetail();
  }, []);

  const handleUpdateSupplier = async (values) => {
    setUpdateActionLoading(true);
    try {
      await dispatch(updateSupplierRequest(values));
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      await dispatch(getSupplierDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setUpdateActionLoading(false);
  };

  const handleDeleteSupplierHoliday = async (record) => {
    setHolidayListLoading(true);
    try {
      await dispatch(deleteSupplierHolidayRequest(record));
      toastMessage.success({
        description: '休業日を削除しました。',
      });
      fetchSupplierHolidayList();
    } catch (error) {
      console.error(error);
    }
    setHolidayListLoading(false);
  };

  const handleAddHoliday = async (value) => {
    setCreateHolidayLoading(true);
    try {
      await dispatch(createSupplierHolidayRequest(value));
      setHolidayModalVisible(false);
      toastMessage.success({
        description: '休業日を追加しました。',
      });
      fetchSupplierHolidayList();
    } catch (err) {
      console.error(err);
    }
    setCreateHolidayLoading(false);
  };

  const columns = [
    Columns.date,
    Columns.status,
    {
      ...Columns.action,
      render: (record) => {
        if (moment(record.date) <= moment()) {
          return null;
        }

        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showConfirm(
                '休業日を削除してよろしいですか？',
                `${moment(record.date).format('YYYY年MM月DD日')} を削除します`,
                () => handleDeleteSupplierHoliday(record),
                '削除する',
                'キャンセル'
              );
            }}
            type="text"
            danger
            icon={<CloseOutlined />}
          >
            削除
          </Button>
        );
      },
    },
  ];

  if (isShikomelSupplier) {
    columns.splice(2, 0,
      Columns.applyTo
    );
  }

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  return (
    <div id="holiday-page">
      <AddHolidayModal
        onCancel={() => setHolidayModalVisible(false)}
        visible={holidayModalVisible}
        onSubmit={handleAddHoliday}
        actionLoading={createHolidayLoading}
        isShikomelSupplier={isShikomelSupplier}
      />
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={
          isHolidaySettingDirty
            ? () => showConfirm('中断してよろしいですか？', null, handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Space direction="vertical" size={16} className="full-w">
          <Spin spinning={supplierDataLoading || updateActionLoading}>
            <HolidaySettingForm
              onSubmit={handleUpdateSupplier}
              formValues={holidayFormValues}
              isShikomelSupplier={isShikomelSupplier}
              initialValues={{
                workingDays: _get(supplierDetail, 'workingDays', []),
                workingDays_manufacturer: _get(supplierDetail, 'workingDays_manufacturer', []),
                shippingMethod: _get(supplierDetail, 'shippingMethod', ''),
                leadTimeIncludeHoliday: _get(
                  supplierDetail,
                  'leadTimeIncludeHoliday',
                  false
                ),
                orderDeadlineTime:
                  _get(supplierDetail, 'orderDeadlineTime')
                  && moment(_get(supplierDetail, 'orderDeadlineTime')),
              }}
            />
          </Spin>
          <Card
            className="highlight-card padding-card"
            bordered={false}
            title="休業日"
            headStyle={{
              fontSize: 20, fontWeight: 'bold', padding: '0 16px', borderBottom: '2px solid #e8e8e8'
            }}
            bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
            extra={(
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setHolidayModalVisible(true)}
              >
                休業日を追加
              </Button>
            )}
          >
            <Table
              columns={columns}
              data={list}
              total={total}
              loading={holidayListLoading}
              onChange={onTableChange}
              pagination={
                total > limit && {
                  current: Number(page),
                  pageSize: Number(limit),
                }
              }
              scroll={{
                x: 500,
              }}
            />
          </Card>
        </Space>
      </Card>
      {isHolidaySettingDirty && (
        <Affix offsetBottom={0} className="full-w">
          <div className="full-w" style={{ background: '#293137' }}>
            <Space
              size={8}
              className="flex justify-between"
              style={{ padding: '16px 32px' }}
            >
              <Text style={{ color: '#fff' }}>保存されていない変更</Text>
              <Space size={8} className="full-w flex justify-end">
                <Button
                  type="default"
                  onClick={() => showConfirm('中断してよろしいですか？', null, () => dispatch(reset('holidaySettingForm'))
                  )}
                >
                  取り消す
                </Button>
                <Button
                  type="primary"
                  loading={updateActionLoading}
                  onClick={() => dispatch(submit('holidaySettingForm'))}
                >
                  保存する
                </Button>
              </Space>
            </Space>
          </div>
        </Affix>
      )}
    </div>
  );
};

export default HolidayPage;
