import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';

import { TextField } from 'components/Form';

import validate from './validate';

const ChangeEmailForm = ({ handleSubmit, actionLoading, children }) => (
  <Form
    layout="horizontal"
    colon={false}
    onSubmit={handleSubmit}
    labelCol={{ span: 8 }}
  >
    <Form.Item label="メールアドレス">{children}</Form.Item>
    <Field
      name="email"
      label="新しいメールアドレス"
      type="text"
      component={TextField}
    />
    <Field
      name="password"
      label="現在のパスワード"
      type="password"
      component={TextField}
    />
    <Space className="flex justify-end">
      <Button
        type="primary"
        htmlType="submit"
        disabled={actionLoading}
        loading={actionLoading}
      >
        送信
      </Button>
    </Space>
  </Form>
);

ChangeEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.any,
  children: PropTypes.any,
};

export default reduxForm({ form: 'changeEmailForm', validate })(
  ChangeEmailForm
);
