import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';
import { change } from 'redux-form';
import { changeTagStatusRequest } from 'providers/GenreProvider/actions';

const { confirm } = Modal;

const showConfirm = (message, content, actionFn) => {
  confirm({
    title: message,
    content,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const StatusToggle = ({ record, rowRecord, isEditForm }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const changeStatusTag = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(changeTagStatusRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  // update status filed in redux-form
  const updateStatus = (status) => {
    dispatch(change('tagForm', 'isVisible', status));
  };

  return (
    <div
      role="button"
      className="status-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-hidden
    >
      <div>
        <span
          className={`${record ? 'dot' : 'dot dot--inactive'}`}
        />
        <span
          style={{
            color: '#000000d9',
            fontWeight: 'normal'
          }}
        >{record ? '有効' : '無効'}
        </span>
      </div>
      <Switch
        disabled={
          changeStatusLoading
        }
        size="small"
        checked={record}
        onClick={() => {
          const { name, objectId } = rowRecord;
          const nextStatus = !record;
          showConfirm(
            nextStatus
              ? ` タグ ${name || ''} を表示しますか？`
              : `タグ ${name || ''} を非表示にしますか？`,
            nextStatus ? 'このタグがレストランに表示され、検索でも利用できるようになります。' : 'このタグがレストランに表示されなくなり、検索でも利用できなくなります。',
            () => {
              if (isEditForm) {
                return updateStatus(nextStatus);
              }
              return changeStatusTag({
                objectId,
                isVisible: nextStatus,
              });
            }
          );
        }}
      />
    </div>
  );
};

StatusToggle.propTypes = {
  rowRecord: PropTypes.object,
  record: PropTypes.string,
  isEditForm: PropTypes.bool,
};

export default StatusToggle;
