import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import { getRestaurantListRequest } from 'providers/RestaurantProvider/actions';
import { MultipleSelectionWithRemoteSearch } from 'components/Form';
import './style.less';

const AddRestaurantForm = ({
  handleSubmit,
  onCancel,
  createActionLoading,
  collectionId,
}) => {
  const dispatch = useDispatch();

  const getRestaurantList = useCallback(() => {
    dispatch(
      getRestaurantListRequest({
        limit: 10000,
        status: 'ACCEPTED',
        checkAssignedToCollectionId: collectionId,
      })
    );
  }, [collectionId]);

  useEffect(() => {
    getRestaurantList();
  }, [getRestaurantList]);

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getRestaurantListRequest({
          ...data,
          checkAssignedToCollectionId: collectionId,
          limit: 10000,
          status: 'ACCEPTED',
        })
      ),
      500
    ),
    []
  );

  const onSearchEmailKeyWork = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const { list: restaurantList } = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <div>
        <Field
          label="レストラン名"
          name="restaurantIds"
          placeholder="レストラン選択"
          options={_map(restaurantList, (item) => ({
            ...item,
            isAdded: !!item?.restaurantCollection?.objectId,
          }))}
          onSearch={onSearchEmailKeyWork}
          component={MultipleSelectionWithRemoteSearch}
          defaultImage={DefaultRestaurantImage}
        />
      </div>
      <div id="restaurant-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button
            className="form-button"
            onClick={onCancel}
            disabled={createActionLoading}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            htmlType="submit"
            loading={createActionLoading}
          >
            送信
          </Button>
        </Space>
      </div>
    </Form>
  );
};

AddRestaurantForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  createActionLoading: PropTypes.any,
  collectionId: PropTypes.any,
};

export default reduxForm({ form: 'addBrandOwnerForm' })(AddRestaurantForm);
