import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { Tabs, Card, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getRestaurantDetailRequest } from 'providers/RestaurantProvider/actions';
import indexRoutes from './routes/indexRoutes';
import RestaurantHeader from '../Supplier_Detail/RestaurantHeader';
import './styles.scss';

const { TabPane } = Tabs;

const RestaurantDetail = () => {
  const [restaurantDetailLoaded, setRestaurantDetailLoaded] = useState(false);
  const { restaurantId } = useParams();
  const dispatch = useDispatch();

  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );
  const restaurantListData = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  const fetchData = useCallback(async () => {
    setRestaurantDetailLoaded(true);
    await dispatch(
      getRestaurantDetailRequest({
        formattedObjectId: restaurantId,
      })
    );
    setRestaurantDetailLoaded(false);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div id="restaurant-detail-page" className="bo-restaurant-detail">
      <RestaurantHeader
        restaurantDetail={restaurantDetail}
        footer={(
          <>
            <TabPane tab="レストラン詳細" key="in-app" />
            <TabPane tab="ブランド" key="brand-list" />
            <TabPane tab="商品" key="product-list" />
          </>
        )}
        idList={_map(restaurantListData?.list, (item) => _get(item, 'formattedObjectId')
        )}
      />
      {restaurantDetailLoaded ? (
        <div
          className="full-w flex justify-center items-center"
          style={{ height: '100%' }}
        >
          <Spin />
        </div>
      ) : (
        <Card className="bg-transparent" bordered={false}>
          <Switch>
            {indexRoutes.map((route, key) => (
              <Route key={key} path={route.path} component={route.component} />
            ))}
          </Switch>
        </Card>
      )}
    </div>
  );
};

export default RestaurantDetail;
