import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DeliveredProcedureOutlined,
  CheckCircleTwoTone,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  Space, Modal, Spin, Result, Button
} from 'antd';
import { ORDER_STATUS } from 'utils/constants';
import { doRefresh } from 'providers/CommonProvider/actions';
import {
  bulkUpdateOrdersRequest,
  exportOrdersWithPurchaseInfoRequest,
} from 'providers/OrderProvider/actions';

import './style.scss';

const { confirm } = Modal;

const BulkActions = ({ selectedOrder }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resultChangeStatus, setResultChangeStatus] = useState(null);
  const [exportPurchaseOrderLoading, setExportPurchaseOrderLoading] = useState(false);

  const orderIds = _get(selectedOrder, 'selectedOrderIds');
  const status = _get(selectedOrder, 'status');

  const dispatch = useDispatch();

  const changeStatus = async (params) => {
    try {
      setIsModalVisible(true);
      const orderUpdateResponse = await dispatch(
        bulkUpdateOrdersRequest(params)
      );
      setResultChangeStatus(orderUpdateResponse);
    } catch (error) {
      setIsModalVisible(false);
      dispatch(doRefresh({ target: 'orderList' }));
    }
  };

  const showConfirm = (message, actionFn, confirmText) => {
    confirm({
      title: message,
      okText: _get(confirmText, 'yes', 'はい'),
      okType: 'primary',
      cancelText: _get(confirmText, 'no', 'いいえ'),
      onOk: () => {
        actionFn();
      },
    });
  };

  const onExportAllPurchase = async (params) => {
    setExportPurchaseOrderLoading(true);
    try {
      await dispatch(exportOrdersWithPurchaseInfoRequest(params));
      dispatch(doRefresh({ target: 'orderList' }));
    } catch (error) {
      console.error(error);
    }
    setExportPurchaseOrderLoading(false);
  };

  const menuItemActionMap = {
    [ORDER_STATUS.PROCESSING]: () => showConfirm('続行してよろしいですか？', () => changeStatus({
      orderIds,
      status: ORDER_STATUS.PROCESSING,
    })
    ),
    [ORDER_STATUS.DELIVERED]: () => showConfirm('配送を完了しますか？', () => changeStatus({
      orderIds,
      status: ORDER_STATUS.DELIVERED,
    })
    ),
  };

  const targetStatusMap = {
    [ORDER_STATUS.SUBMITTED]: [
      { target: ORDER_STATUS.PROCESSING, label: '続行' },
    ],
    [ORDER_STATUS.DELIVERING]: [
      { target: ORDER_STATUS.DELIVERED, label: '配送を完了する' },
    ],
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        title="Changing the order's status"
        maskClosable={false}
        closable={false}
        footer={
          resultChangeStatus ? (
            <Button
              type="primary"
              onClick={() => {
                setIsModalVisible(false);
                dispatch(doRefresh({ target: 'orderList' }));
              }}
            >
              OK
            </Button>
          ) : null
        }
      >
        <Result
          icon={
            resultChangeStatus ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <Spin />
            )
          }
          subTitle={
            resultChangeStatus ? (
              <>
                <div>Done</div>
                <div>{`${resultChangeStatus.successCount} order's status changed successfully`}</div>
                <div>{`${resultChangeStatus.failedCount} order's status cannot be changed`}</div>
              </>
            ) : (
              <>
                <div>Processing...</div>
                <div>Please keep this screen until the process is done</div>
              </>
            )
          }
        />
      </Modal>
      <Space>
        <Button
          type="ghost"
          icon={<DownloadOutlined />}
          loading={exportPurchaseOrderLoading}
          onClick={() => onExportAllPurchase({ orderIds })}
        >
          納品書を出力する
        </Button>
        {[ORDER_STATUS.DELIVERING, ORDER_STATUS.SUBMITTED].includes(status)
          && _map(targetStatusMap[status], (item) => (
            <Button
              type="ghost"
              icon={<DeliveredProcedureOutlined />}
              onClick={() => {
                menuItemActionMap[item.target]();
              }}
            >
              {item.label}
            </Button>
          ))}
      </Space>
    </>
  );
};

BulkActions.propTypes = {
  selectedOrder: PropTypes.object,
};

export default BulkActions;
