import { call, put, takeLatest } from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import { getCategoryListSuccess, getCategoryListError } from './actions';
import { GET_CATEGORY_LIST_REQUEST } from './constants';

export function* handleGetCategoryList(action) {
  const params = {
    includeChildren: true,
    ...action.payload,
  };
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getCategories`,
      params
    );
    yield put(getCategoryListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getCategoryListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_CATEGORY_LIST_REQUEST, handleGetCategoryList);
}
