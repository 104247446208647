import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card
} from 'antd';
import { useParams } from 'react-router-dom';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import { DATE_TIME_FORMAT } from 'utils/constants';
import { getSupplierListRequest } from 'providers/SupplierProvider/actions';

import DefaultSupplierImage from 'images/defaultSupplier.svg';
import ProfileInfo from 'components/ProfileInfo';

const SupplierList = () => {
  const { brandOwnerId } = useParams();

  const supplierList = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getSupplierListRequest({
          page,
          limit,
          brandOwnerId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, keyword]);

  const columns = [
    {
      title: '販売者',
      width: 250,
      render: (record) => (
        <ProfileInfo
          image={_get(record, 'image', DefaultSupplierImage)}
          name={_get(record, 'name')}
          email={_get(record, 'email')}
        />
      ),
      fixed: 'left',
    },
    {
      title: '作成日',
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
    },
    {
      title: '商品件数',
      width: 120,
      dataIndex: 'menuCount',
      key: 'menuCount',
      align: 'right',
    },
  ];

  return (
    <Card className="bg-transparent">
      <Card className="highlight-card padding-card" bordered={false}>
        <Table
          columns={columns}
          data={_get(supplierList, 'list', [])}
          total={_get(supplierList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 526 }}
        />
      </Card>
    </Card>
  );
};

export default SupplierList;
