import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getRestaurantGroupListError,
  getRestaurantGroupListSuccess,
} from './actions';
import { GET_RESTAURANT_GROUP_REQUEST } from './constants';

export function* handleGetRestaurantGroupList(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantGroupList`,
      payload
    );
    yield put(getRestaurantGroupListSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantGroupListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_RESTAURANT_GROUP_REQUEST, handleGetRestaurantGroupList);
}
