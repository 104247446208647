import { createSelector } from 'reselect';

const selectCategory = (state) => state.categoryProvider || {};

const makeSelectCategoryList = () => createSelector(selectCategory, (state) => state.categoryList);
const makeSelectSubcategoryMap = () => createSelector(selectCategory, (state) => state.subcategoryMap);
const makeSelectCategoryLoading = () => createSelector(selectCategory, (state) => state.loading);
export {
  makeSelectCategoryList,
  makeSelectSubcategoryMap,
  makeSelectCategoryLoading
};
