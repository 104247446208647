import _get from 'lodash/get';
import _pick from 'lodash/pick';
import axios from 'axios';
import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';

import Helpers from 'utils/helpers';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX, MESSAGE, UTF_8_BOM } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

import {
  getListRestaurantAnnouncementSuccess,
  getListRestaurantAnnouncementError,
  createRestaurantAnnouncementSuccess,
  createRestaurantAnnouncementError,
  getRestaurantAnnouncementDetailSuccess,
  getRestaurantAnnouncementDetailError,
  deleteRestaurantAnnouncementSuccess,
  deleteRestaurantAnnouncementError,
  exportRestaurantAnnouncementResultSuccess,
  exportRestaurantAnnouncementResultError,
} from './actions';
import {
  GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST,
  DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST,
  CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST,
  EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST,
} from './constants';

export function* handleGetListRestaurantAnnouncement(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getListRestaurantAnnouncement`,
      action.data
    );
    yield put(getListRestaurantAnnouncementSuccess(response.result));
  } catch (error) {
    yield put(getListRestaurantAnnouncementError(error));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleCreateAnnouncement(action) {
  const { meta, payload } = action;
  let fileId;

  try {
    if (payload.file) {
      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadFileUrl`,
        {
          fileName: _get(payload, 'file.name'),
          fileType: _get(payload, 'file.type'),
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, payload.file, {
        headers: {
          'Content-Type': payload.file.type,
        },
      })
      );
    }
    const params = _pick(payload, [
      'title',
      'message',
      'menuId',
      'url',
      'announcementTarget',
      'restaurantGroupKey',
      'restaurantGroupParams',
    ]);

    const schedule = _get(payload, 'schedule');
    if (schedule) {
      params.announceTime = _get(payload, 'announceTime');
    }

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createRestaurantAnnouncement`,
      { ...params, fileId }
    );

    yield put(createRestaurantAnnouncementSuccess(result, meta));
  } catch (error) {
    yield put(createRestaurantAnnouncementError(error, meta));
    if (error.code !== 9548) {
      toastMessage.error({
        message: 'エラー',
        description: error.error,
      });
    }
  }
}

export function* handleGetRestaurantAnnouncementDetail(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantAnnouncementDetail`,
      action.data
    );
    yield put(getRestaurantAnnouncementDetailSuccess(response.result));
  } catch (error) {
    yield put(getRestaurantAnnouncementDetailError(error));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleDeleteRestaurantAnnouncement(action) {
  const { payload, meta } = action;
  const params = _pick(payload, 'objectId');

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}deleteRestaurantAnnouncement`,
      params
    );
    yield put(deleteRestaurantAnnouncementSuccess(result, meta));
    toastMessage.success({
      description: MESSAGE.DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS,
    });
  } catch (error) {
    yield put(deleteRestaurantAnnouncementError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleExportRestaurantAnnouncementResult(action) {
  const { payload, meta } = action;
  try {
    const { data } = yield call(
      request,
      '/restaurant-announcement-result/export',
      { restaurantAnnouncementId: payload?.restaurantAnnouncementId }
    );

    Helpers.saveAsFile(
      data,
      payload.fileName,
      'text/csv;charset=utf-8',
      UTF_8_BOM
    );
    yield put(
      exportRestaurantAnnouncementResultSuccess(
        MESSAGE.EXPORT_CSV_SUCCESS,
        meta
      )
    );
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportRestaurantAnnouncementResultError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}
export default function* saga() {
  yield takeLatest(
    GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST,
    handleGetListRestaurantAnnouncement
  );
  yield takeLeading(
    CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST,
    handleCreateAnnouncement
  );
  yield takeLatest(
    GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST,
    handleGetRestaurantAnnouncementDetail
  );
  yield takeLeading(
    DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST,
    handleDeleteRestaurantAnnouncement
  );
  yield takeLeading(
    EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST,
    handleExportRestaurantAnnouncementResult
  );
}
