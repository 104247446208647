import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import React from 'react';
import { replace } from 'connected-react-router';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const NavigationButton = (supplierId, dispatch, supplierList) => {
  const calcNavigateId = () => {
    const supplierIdList = _map(supplierList?.list, (item) => item.objectId) || [];

    const index = _findIndex(supplierIdList, (item) => item === supplierId);
    return {
      current: index,
      backTo: index > 0 && supplierIdList[index - 1],
      nextTo: index < supplierIdList.length - 1 && supplierIdList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, id) => {
    evt.preventDefault();
    dispatch(replace(`/suppliers/detail/${id}/in-app`));
  };

  const { current, backTo, nextTo } = calcNavigateId();
  const suppliers = _get(supplierList, 'list', []);
  if (suppliers.length < 1) {
    return [];
  }

  return [
    <span
      key="current"
      className="text-bold"
      style={{ fontSize: 14, marginLeft: 16 }}
    >
      {`${current + 1} / ${suppliers.length}`}
    </span>,
    <Button
      key="prev"
      type="primary"
      disabled={!backTo}
      onClick={(evt) => handleReplaceRoute(evt, backTo)}
      icon={<LeftOutlined />}
    />,
    <Button
      key="next"
      type="primary"
      disabled={!nextTo}
      onClick={(evt) => handleReplaceRoute(evt, nextTo)}
      icon={<RightOutlined />}
    />,
  ];
};

export default NavigationButton;
