import {
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_ERROR,
  GET_BRAND_LIST_REQUEST,
  GET_BRAND_LIST_SUCCESS,
  GET_BRAND_LIST_ERROR,
  GET_BRAND_DETAIL_REQUEST,
  GET_BRAND_DETAIL_SUCCESS,
  GET_BRAND_DETAIL_ERROR,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERROR,
  REGISTER_BRAND_OWNER_REQUEST,
  REGISTER_BRAND_OWNER_SUCCESS,
  REGISTER_BRAND_OWNER_ERROR,
  ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST,
  ASSIGN_BRAND_OWNER_TO_BRAND_SUCCESS,
  ASSIGN_BRAND_OWNER_TO_BRAND_ERROR,
  REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST,
  REMOVE_BRAND_OWNER_FROM_BRAND_SUCCESS,
  REMOVE_BRAND_OWNER_FROM_BRAND_ERROR,
  GET_BRAND_LIST_OF_RESTAURANT_REQUEST,
  GET_BRAND_LIST_OF_RESTAURANT_SUCCESS,
  GET_BRAND_LIST_OF_RESTAURANT_ERROR,
} from './constants';

export function createBrandRequest(payload) {
  return {
    type: CREATE_BRAND_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function createBrandSuccess(payload, meta) {
  return { type: CREATE_BRAND_SUCCESS, payload, meta };
}

export function createBrandError(payload, meta) {
  return {
    type: CREATE_BRAND_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getBrandListRequest(payload) {
  return { type: GET_BRAND_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getBrandListSuccess(payload, meta) {
  return { type: GET_BRAND_LIST_SUCCESS, payload, meta };
}

export function getBrandListError(payload, meta) {
  return {
    type: GET_BRAND_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getBrandDetailRequest(payload) {
  return { type: GET_BRAND_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getBrandDetailSuccess(payload, meta) {
  return { type: GET_BRAND_DETAIL_SUCCESS, payload, meta };
}

export function getBrandDetailError(payload, meta) {
  return {
    type: GET_BRAND_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateBrandRequest(payload) {
  return {
    type: UPDATE_BRAND_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateBrandSuccess(payload, meta) {
  return { type: UPDATE_BRAND_SUCCESS, payload, meta };
}

export function updateBrandError(payload, meta) {
  return {
    type: UPDATE_BRAND_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function registerBrandOwnerRequest(payload) {
  return { type: REGISTER_BRAND_OWNER_REQUEST, payload, meta: { thunk: true } };
}

export function registerBrandOwnerSuccess(payload, meta) {
  return { type: REGISTER_BRAND_OWNER_SUCCESS, payload, meta };
}

export function registerBrandOwnerError(payload, meta) {
  return {
    type: REGISTER_BRAND_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function assignBrandOwnerToBrandRequest(payload) {
  return {
    type: ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function assignBrandOwnerToBrandSuccess(payload, meta) {
  return { type: ASSIGN_BRAND_OWNER_TO_BRAND_SUCCESS, payload, meta };
}

export function assignBrandOwnerToBrandError(payload, meta) {
  return {
    type: ASSIGN_BRAND_OWNER_TO_BRAND_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeBrandOwnerFromBrandRequest(payload) {
  return {
    type: REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeBrandOwnerFromBrandSuccess(payload, meta) {
  return { type: REMOVE_BRAND_OWNER_FROM_BRAND_SUCCESS, payload, meta };
}

export function removeBrandOwnerFromBrandError(payload, meta) {
  return {
    type: REMOVE_BRAND_OWNER_FROM_BRAND_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getBrandListOfRestaurantRequest(payload) {
  return {
    type: GET_BRAND_LIST_OF_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getBrandListOfRestaurantSuccess(payload, meta) {
  return { type: GET_BRAND_LIST_OF_RESTAURANT_SUCCESS, payload, meta };
}

export function getBrandListOfRestaurantError(payload, meta) {
  return {
    type: GET_BRAND_LIST_OF_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}
