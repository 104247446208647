import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  Card, Space, Avatar, Typography
} from 'antd';
import CopyIcon from 'images/icon-copy.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

const { Text } = Typography;

const SupplierInfo = ({ orderDetail }) => (
  <Card
    className="highlight-card supplier-info"
    title="販売者"
    headStyle={{ fontSize: '20px', color: '#000' }}
    bordered={false}
  >
    <Space>
      <Avatar src={_get(orderDetail, 'supplier.image', DefaultSupplierImage)} />
      <div className="supplier-name">
        {_get(orderDetail, 'supplier.name', '—')}
        <br />
        <Text
          copyable={
            !!_get(orderDetail, 'supplier.email') && {
              tooltips: ['コピー', 'コピーしました'],
              icon: <img src={CopyIcon} alt="copy" />
            }
          }
          type="secondary"
          style={{ fontSize: 12 }}
        >
          {_get(orderDetail, 'supplier.email', '—')}
        </Text>
      </div>
    </Space>
  </Card>
);

SupplierInfo.propTypes = {
  orderDetail: PropTypes.object,
};

export default SupplierInfo;
