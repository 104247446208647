import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import ProductList from 'containers/AdminPage/Content/Products/List';

const ProductInfo = ({ match }) => {
  const manufacturerCode = _get(match, 'params.manufacturerCode');

  return <ProductList manufacturerCode={manufacturerCode} />;
};

ProductInfo.propTypes = {
  match: PropTypes.any,
};

export default ProductInfo;
