import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Card,
  Button,
  Form,
  DatePicker,
  Avatar,
  AutoComplete,
  Select,
  Tooltip,
  Typography,
  Space,
} from 'antd';
import { USER_ROLE } from 'utils/constants';

import CalendarIcon from 'images/icon-calendar.svg';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

import { getSupplierListRequest } from 'providers/SupplierProvider/actions';
import { getRestaurantListRequest } from 'providers/RestaurantProvider/actions';

import './style.scss';

const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

const OrderListSearchForm = ({ filterControl, onFilter, onFilterChange }) => {
  const {
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    restaurantId,
    supplierId,
  } = filterControl;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const { list: supplierList } = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );
  const { list: restaurantList } = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  const userRole = currentUser.role;

  const isRestaurantOwner = userRole === USER_ROLE.RESTAURANT_OWNER;
  const isOperator = userRole === USER_ROLE.OPERATOR;

  const fetchData = useCallback(() => {
    if (isOperator) {
      dispatch(
        getSupplierListRequest({
          limit: 10000,
        })
      );
      dispatch(getRestaurantListRequest({ limit: 10000 }));
    }
    if (isRestaurantOwner) {
      dispatch(
        getRestaurantListRequest({
          limit: 10000,
          statuses: ['ACCEPTED'],
        })
      );
      dispatch(
        getSupplierListRequest({
          limit: 10000,
        })
      );
    }
  }, [isOperator, isRestaurantOwner]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const clearFilters = () => {
    form.setFieldsValue({
      createdDate: null,
      deliveryDate: null,
      supplierId: undefined,
      restaurantId: undefined,
    });

    onFilterChange({
      deliveryDateFrom: '',
      deliveryDateTo: '',
      createdDateFrom: '',
      createdDateTo: '',
      supplierId: '',
      restaurantId: '',
    });
  };

  const renderItem = (option, defaultImage) => (
    <Option
      key={option.objectId}
      value={option.objectId}
      label={(
        <Space className="flex items-center">
          <Avatar
            alt="avatar"
            src={option.image || <img alt="default" src={defaultImage} />}
            size={16}
          />
          <Typography.Text>{option.name}</Typography.Text>
        </Space>
      )}
    >
      <Card
        bordered={false}
        className="bg-transparent"
        bodyStyle={{ padding: '4px 3px' }}
      >
        <div className="full-w flex justify-between items-center">
          <Meta
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
            avatar={(
              <Avatar
                src={option.image || <img alt="default" src={defaultImage} />}
              />
            )}
            title={(
              <Tooltip placement="topLeft" title={option.name}>
                {option.name}
              </Tooltip>
            )}
            description={(
              <Tooltip placement="topLeft" title={option.email}>
                <Text type="secondary" ellipsis>
                  {option.email}
                </Text>
              </Tooltip>
            )}
          />
        </div>
      </Card>
    </Option>
  );
  const optionElementsRestaurant = _map(restaurantList, (o) => renderItem({ ...o, ...o.restaurant }, DefaultRestaurantImage)
  );

  const optionElementsSupplier = _map(supplierList, (o) => renderItem(o, DefaultSupplierImage)
  );

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getSupplierListRequest({ ...data, limit: 10000 })),
      500
    ),
    []
  );

  const getRestaurantListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getRestaurantListRequest({ ...data, limit: 10000, statuses: isRestaurantOwner ? ['ACCEPTED'] : undefined })),
      500
    ),
    []
  );

  const onSearchSupplierList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const onSearchRestaurantList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getRestaurantListWithDelay(data);
  };

  return (
    <Form
      layout="inline"
      initialValues={{
        createdDate: [
          createdDateFrom && moment(createdDateFrom),
          createdDateTo && moment(createdDateTo),
        ],
        deliveryDate: [
          deliveryDateFrom && moment(deliveryDateFrom),
          deliveryDateTo && moment(deliveryDateTo),
        ],
        supplierId,
        restaurantId,
      }}
      onValuesChange={onFilter}
      form={form}
      className="order-list-search-form"
    >
      <div className="flex justify-end items-end flex-wrap">
        <Space
          wrap
          size={[8, 16]}
          style={{
            width: '100%',
            flex: 1,
          }}
        >
          <Form.Item label="注文日: " name="createdDate">
            <RangePicker
              placeholder={['開始日', '終了日']}
              className="form-range-picker-field"
              onBlur={(e) => e.preventDefault()}
              format="YYYY/MM/DD"
              suffixIcon={<img alt="calendar-icon" src={CalendarIcon} />}
              separator={<ArrowRightOutlined />}
            />
          </Form.Item>
          <Form.Item label="納品希望日: " name="deliveryDate">
            <RangePicker
              placeholder={['開始日', '終了日']}
              className="form-range-picker-field"
              onBlur={(e) => e.preventDefault()}
              format="YYYY/MM/DD"
              suffixIcon={<img alt="calendar-icon" src={CalendarIcon} />}
              separator={<ArrowRightOutlined />}
            />
          </Form.Item>
          {[USER_ROLE.OPERATOR, USER_ROLE.RESTAURANT_OWNER].includes(
            userRole
          ) && (
            <>
              <Form.Item label="販売者: " name="supplierId">
                <Select
                  placeholder="すべて"
                  allowClear
                  showSearch
                  filterOption={false}
                  style={{ width: '250px', marginTop: '-14px' }}
                  onSearch={onSearchSupplierList}
                  onClear={() => onSearchSupplierList('')}
                  optionLabelProp="label"
                >
                  {optionElementsSupplier}
                </Select>
              </Form.Item>
              <Form.Item label="店舗名: " name="restaurantId">
                <Select
                  allowClear
                  showSearch
                  filterOption={false}
                  style={{ width: '250px' }}
                  placeholder="すべて"
                  onSearch={onSearchRestaurantList}
                  onClear={() => onSearchRestaurantList('')}
                  optionLabelProp="label"
                >
                  {optionElementsRestaurant}
                </Select>
              </Form.Item>
            </>
          )}
        </Space>
        <Button
          type="text"
          icon={<CloseOutlined />}
          style={{ color: '#1890ff' }}
          hidden={
            !deliveryDateFrom
            && !deliveryDateTo
            && !createdDateFrom
            && !createdDateTo
            && !supplierId
            && !restaurantId
          }
          onClick={clearFilters}
        >
          Clear all filters
        </Button>
      </div>
    </Form>
  );
};

OrderListSearchForm.propTypes = {
  filterControl: PropTypes.object,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
};

export default OrderListSearchForm;
