import produce from 'immer';

import { GET_PENDING_LIST_SUCCESS, GET_PENDING_LIST_ERROR } from './constants';

const initialState = {
  pendingList: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_PENDING_LIST_SUCCESS:
      state.pendingList = action.payload;
      break;

    case GET_PENDING_LIST_ERROR:
      state.pendingList = {};
      break;

    default:
  }
  return state;
});

export default reducer;
