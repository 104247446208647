import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import {
  Tooltip, Badge, Row, Col, Avatar, Typography, Tag
} from 'antd';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import NoteIcon from 'images/iconNote.svg';
import EditIcon from 'images/icon-edit.svg';
import MessageIcon from 'images/icon-message.svg';

import {
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  ORDER_STATUS,
  ORDER_STATUS_JP,
  USER_ROLE,
  ORDER_STATUS_COLOR,
  AIR_LOGI_ORDER_SYNC_STATUS_ERROR,
} from 'utils/constants';
import Helpers from 'utils/helpers';

import OrderStatusChange from '../Components/OrderStatusChange';

const Columns = (userRole) => {
  const columns = {};

  columns.ID = {
    title: 'ID',
    width: 110,
    key: 'objectId',
    dataIndex: 'objectId',
    align: 'right',
  };

  columns.icon = {
    title: '',
    width: 110,
    key: 'icon',
    fixed: 'left',
    render: (record) => (
      <Row>
        <Col
          className="flex justify-center items-center"
          xs={{ span: 24 }}
          lg={{ span: 8 }}
        >
          {_get(record, 'note') && (
            <Tooltip title="備考">
              <img alt="note-icon" width={24} height={24} src={NoteIcon} />
            </Tooltip>
          )}
        </Col>
        <Col
          className="flex justify-center items-center"
          xs={{ span: 24 }}
          lg={{ span: 8 }}
        >
          {_get(record, 'isEdited') && (
            <Tooltip title="更新されました">
              <img alt="edit-icon" width={24} height={24} src={EditIcon} />
            </Tooltip>
          )}
        </Col>
        <Col
          className="flex justify-center items-center"
          xs={{ span: 24 }}
          lg={{ span: 8 }}
        >
          {_get(record, 'totalMessagesCount') > 0 && (
            <Tooltip title="メッセージ">
              <Badge
                count={
                  userRole === USER_ROLE.SUPPLIER
                    ? _get(record, 'unReadMessagesCount', 0)
                    : 0
                }
                dot
              >
                <img
                  alt="message-icon"
                  width={24}
                  height={24}
                  src={MessageIcon}
                />
              </Badge>
            </Tooltip>
          )}
        </Col>
      </Row>
    ),
  };

  columns.restaurantInfo = {
    title: 'レストラン名',
    width: 190,
    key: 'restaurantName',
    ellipsis: {
      showTitle: false,
    },
    fixed: 'left',
    render: (record) => (
      <div
        className="flex items-center full-w"
      >
        <Avatar
          alt="avatar"
          src={
            record?.image
              || _get(record, 'restaurant.image', DefaultRestaurantImage)
          }
          size={32}
        />
        <div
          className="restaurant-info-wrapper"
        >
          <Tooltip
            placement="topLeft"
            title={_get(record, 'restaurantName') || record?.name}
          >
            <Typography.Text ellipsis>
              {_get(record, 'restaurantName') || record?.name}
            </Typography.Text>
          </Tooltip>
        </div>
      </div>
    ),
  };

  columns.createdAt = {
    title: '注文日',
    width: 150,
    dataIndex: 'createdAt',
    key: 'createdAt',
    responsive: ['md'],
    align: 'right',
    render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
  };

  columns.trackingInfo = {
    title: '配送業者',
    width: 120,
    key: 'trackingInfo',
    dataIndex: 'trackingInfo',
    ellipsis: {
      showTitle: false,
    },
    render: (trackingInfo, record) => {
      const str = [
        ORDER_STATUS.DELIVERING,
        ORDER_STATUS.DELIVERED,
        ORDER_STATUS.FIXED,
      ].includes(record.status || record?.orderInfo?.status)
        && (trackingInfo && trackingInfo.length > 0
          ? trackingInfo.map((k) => k.nameKana).join('，')
          : '自社配送');
      return (
        <Tooltip placement="topLeft" title={str}>
          {str}
        </Tooltip>
      );
    },
  };

  columns.deliveryPrefecture = {
    title: '都道府県',
    width: 100,
    key: 'deliveryPrefecture',
    dataIndex: ['deliveryInfo', 'deliveryPrefecture'],
  };

  columns.deliveryDate = {
    title: '納品希望日',
    width: 120,
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    responsive: ['md'],
    align: 'right',
    render: (record) => moment(record).format(DATE_FORMAT),
  };

  columns.totalPrice = {
    title: '総計',
    width: 120,
    dataIndex: 'total',
    key: 'total',
    responsive: ['md'],
    align: 'right',
    render: (record) => Helpers.numberWithCommas(record),
  };

  columns.status = {
    title: '注文の状況',
    width: 150,
    key: 'status',
    responsive: ['md'],
    fixed: 'right',
    render: (record) => {
      // eslint-disable-next-line camelcase
      const { status, airLogi_info } = record;
      const isDeliveringStatus = status === ORDER_STATUS.DELIVERING;
      const isSupplier = userRole === USER_ROLE.SUPPLIER;
      const isAirLogiOrder = _get(record, 'warehouse_airLogiIntegrated');
      let statusItem = (
        <Tag color={ORDER_STATUS_COLOR[status]}>{ORDER_STATUS_JP[status]}</Tag>
      );

      if (isSupplier && (!isAirLogiOrder || isDeliveringStatus)) {
        statusItem = <OrderStatusChange orderDetail={record} />;
      }

      if (AIR_LOGI_ORDER_SYNC_STATUS_ERROR.includes(airLogi_info?.syncStatus)) {
        statusItem = (
          <Tag color={ORDER_STATUS_COLOR.PAYMENT_FAILED}>
            {ORDER_STATUS_JP.AIR_LOGI_ERROR}
          </Tag>
        );
      }
      return (
        <div
          style={{ width: 'fit-content' }}
          onClick={(e) => e.stopPropagation()}
          aria-hidden="true"
        >
          {statusItem}
        </div>
      );
    },
  };

  columns.parentId = {
    title: '親注文ID',
    width: 110,
    key: 'parentOrderId',
    dataIndex: 'parentOrderId',
  };

  return columns;
};

export default Columns;
