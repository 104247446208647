/* eslint-disable no-empty */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import validator from 'validator';

import { TextField } from 'components/Form';
import Constants from 'utils/constants';

import { changePrimaryEmailRequest } from 'providers/EmailInvitationProvider/actions';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.email)) {
    errors.email = '無効なメールアドレスです';
  }

  return errors;
};

let PrimaryEmailForm = ({ handleSubmit, onCancel, actionLoading }) => (
  <Form onSubmit={handleSubmit} layout="vertical">
    <Field
      label="新しいメールアドレス"
      name="email"
      placeholder="sample@gmail.com"
      component={TextField}
    />
    <Space
      direction="horizontal"
      size="middle"
      className="full-w flex justify-end"
    >
      <Button onClick={onCancel} disabled={actionLoading}>
        キャンセル
      </Button>
      <Button type="primary" htmlType="submit" loading={actionLoading}>
        保存
      </Button>
    </Space>
  </Form>
);

PrimaryEmailForm = reduxForm({ form: 'primaryEmailForm', validate })(
  PrimaryEmailForm
);

PrimaryEmailForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  actionLoading: PropTypes.bool,
};

const showConfirm = (message, actionFn) => {
  Modal.confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const PrimaryEmailModal = ({
  restaurantId,
  handleFinish,
  handleCancel,
  supplierId,
  brandOwnerId,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal
      title="メールアドレスの変更"
      visible
      onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <PrimaryEmailForm
        actionLoading={actionLoading}
        onSubmit={async ({ email }) => {
          setActionLoading(true);
          try {
            await dispatch(
              changePrimaryEmailRequest({
                email,
                restaurantId,
                supplierId,
                brandOwnerId,
              })
            );
            handleFinish();
          } catch (err) {}
          setActionLoading(false);
        }}
        onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
      />
    </Modal>
  );
};

PrimaryEmailModal.propTypes = {
  restaurantId: PropTypes.string,
  handleFinish: PropTypes.func,
  handleCancel: PropTypes.func,
  supplierId: PropTypes.string,
  brandOwnerId: PropTypes.string,
};

export default PrimaryEmailModal;
