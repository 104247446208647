import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, ConfigProvider, Space } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import './style.less';

class DatePickerField extends React.PureComponent {
  render() {
    const {
      placeholder,
      label,
      colon,
      input,
      dateFormat,
      meta: {
        touched, invalid, error, valid
      },
      picker,
      disabled,
      disabledDate,
      disabledTime,
      showTime,
      required,
      requiredSpace,
    } = this.props;
    const isError = touched && invalid && error;
    const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
    const requiredSpaceColor = requiredSpace
      ? 'transparent'
      : requiredIconColor;
    const onChange = (value) => {
      if (value === null) {
        return input.onChange('');
      }
      return input.onChange(value);
    };

    return (
      <Form.Item
        help={
          isError && (
            <Space size={4}>
              <InfoCircleOutlined />
              {error}
            </Space>
          )
        }
        validateStatus={touched && invalid && error ? 'error' : ''}
        label={label}
        colon={colon}
        className="field-form-item"
      >
        <ConfigProvider locale={jaJP}>
          <div className="flex items-center">
            <DatePicker
              {...input}
              onChange={onChange}
              picker={picker}
              placeholder={placeholder}
              disabled={disabled}
              showTime={showTime}
              className="form-date-picker-field"
              onBlur={(e) => e.preventDefault()}
              format={dateFormat}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
            />
            {(required || requiredSpace) && (
              <CheckCircleFilled
                style={{
                  fontSize: 16,
                  marginLeft: 8,
                  color: requiredSpaceColor,
                }}
              />
            )}
          </div>
        </ConfigProvider>
      </Form.Item>
    );
  }
}

DatePickerField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  colon: PropTypes.bool,
  disabled: PropTypes.bool,
  picker: PropTypes.any,
  dateFormat: PropTypes.any,
  showTime: PropTypes.any,
  meta: PropTypes.object.isRequired,
  disabledDate: PropTypes.any,
  disabledTime: PropTypes.any,
  required: PropTypes.bool,
  requiredSpace: PropTypes.bool,
};

export default DatePickerField;
