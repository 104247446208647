import {
  call, put, takeLatest, all
} from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import helpers from 'utils/helpers';
import {
  getInAppMessageListSuccess,
  getInAppMessageListError,
  createInAppMessageSuccess,
  createInAppMessageError,
  getInAppMessageDetailError,
  getInAppMessageDetailSuccess,
  stopInAppMessageSuccess,
  stopInAppMessageError
} from './actions';
import {
  GET_IN_APP_MESSAGE_LIST_REQUEST,
  CREATE_IN_APP_MESSAGE_REQUEST,
  GET_IN_APP_MESSAGE_DETAIL_REQUEST,
  STOP_IN_APP_MESSAGE_REQUEST
} from './constants';

const { getUploadUrlList, uploadFileList } = helpers;

export function* getInAppMessageList({ payload, meta }) {
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getInAppMessageList`,
      payload
    );
    yield put(getInAppMessageListSuccess(result, meta));
  } catch (error) {
    yield put(getInAppMessageListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* getInAppMessageDetail({ payload, meta }) {
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getInAppMessageDetail`,
      payload
    );
    yield put(getInAppMessageDetailSuccess(result, meta));
  } catch (error) {
    yield put(getInAppMessageDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* createInAppMessage({ payload, meta }) {
  try {
    const { images = [], file } = payload;
    const params = _pick(payload, [
      'title',
      'message',
      'startDate',
      'endDate',
      'targetUrl',
      'targetMenuId',
    ]);
    let imageId;
    let fileId;
    let uploadImageResults = [];
    let uploadFileResults = [];
    if (!_isEmpty(images)) {
      uploadImageResults = yield all(
        getUploadUrlList(images, 'generateUploadImageUrl', 'AVATAR')
      );
      imageId = _get(uploadImageResults, '[0].result.objectId');
      params.imageId = imageId;
    }

    if (!_isEmpty(file)) {
      uploadFileResults = yield all(
        getUploadUrlList([{
          originFileObj: file,
        }], 'generateUploadFileUrl', 'SNS')
      );
      fileId = _get(uploadFileResults, '[0].result.objectId');
      params.targetFileId = fileId;
    }

    if (!_isEmpty(images) || !_isEmpty(file)) {
      yield all(
        uploadFileList([
          ...uploadImageResults,
          ...uploadFileResults,
        ], [
          ...images,
          {
            originFileObj: file,
          }
        ])
      );
    }

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createInAppMessage`,
      params
    );
    yield put(createInAppMessageSuccess(result, meta));
    toastMessage.success({ message: '保存しました。', description: '情報を保存しました' });
  } catch (error) {
    yield put(createInAppMessageError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* stopInAppMessage({ payload, meta }) {
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}stopInAppMessage`,
      payload
    );
    yield put(stopInAppMessageSuccess(result, meta));
    toastMessage.success({ message: '保存しました。', description: '変更した内容を保存しました。' });
  } catch (error) {
    yield put(stopInAppMessageError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_IN_APP_MESSAGE_LIST_REQUEST, getInAppMessageList);
  yield takeLatest(GET_IN_APP_MESSAGE_DETAIL_REQUEST, getInAppMessageDetail);
  yield takeLatest(CREATE_IN_APP_MESSAGE_REQUEST, createInAppMessage);
  yield takeLatest(STOP_IN_APP_MESSAGE_REQUEST, stopInAppMessage);
}
