import _pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { isDirty, reset, submit } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card,
  Button,
  Affix,
  Space,
  Modal,
  Spin,
  Typography,
  Row,
  Col,
} from 'antd';

import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';

import toastMessage from 'utils/toastMessage';

import {
  getSupplierDetailRequest,
  updateSupplierRequest,
} from 'providers/SupplierProvider/actions';
import { getCurrentUserRequest } from 'providers/AuthProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';

import ProfileUpdateForm from './ProfileUpdateForm';

const { confirm } = Modal;

const Profile = () => {
  const dispatch = useDispatch();
  const profileDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const dirty = useSelector((state) => isDirty('profileUpdateForm')(state));

  const fetchSupplierDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getSupplierDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  });

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const showConfirm = (message, actionFn) => {
    confirm({
      title: message,
      okText: 'はい',
      okType: 'primary',
      cancelText: 'いいえ',
      onOk: () => {
        actionFn();
      },
    });
  };

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateSupplierRequest(values));
      toastMessage.success({
        description: 'プロフィールを更新しました。',
      });
      dispatch(getCurrentUserRequest());
      dispatch(doRefresh({ target: 'profileDetail' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const breadcrumbItems = [
    {
      path: '/settings',
      breadcrumbName: '各種設定',
    },
    {
      breadcrumbName: 'プロフィール',
    },
  ];

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <ProfileUpdateForm
          onSubmit={handleSubmit}
          initialValues={{
            ..._pick(profileDetail, [
              'name',
              'nameKana',
              'postalCode',
              'prefecture',
              'address',
              'building',
              'phone',
              'fax',
              'managerName',
              'managerNameKana',
              'emergencyPhone',
            ]),
            image: profileDetail.image && [
              { uid: '-1', url: profileDetail.image },
            ],
          }}
        />
      </Card>
      {dirty && (
        <Affix offsetBottom={0} className="full-w">
          <div className="full-w" style={{ background: '#293137' }}>
            <Row>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Space
                  size={8}
                  className="flex justify-between"
                  style={{ padding: '16px 16px 16px 32px' }}
                >
                  <Typography.Text style={{ color: '#fff' }}>
                    保存されていない変更
                  </Typography.Text>
                  <Space size={8} className="full-w flex justify-end">
                    <Button
                      type="default"
                      disabled={actionLoading}
                      onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('profileUpdateForm'))
                      )}
                    >
                      取り消す
                    </Button>
                    <Button
                      type="primary"
                      loading={actionLoading}
                      onClick={() => dispatch(submit('profileUpdateForm'))}
                    >
                      保存する
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </Affix>
      )}
    </>
  );
};

export default withRefresh('profileDetail')(Profile);
