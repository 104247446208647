import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Card } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { TextField, InputNumber } from 'components/Form';
import Constants from 'utils/constants';
import Helpers from 'utils/helpers';
import './style.scss';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }

  return errors;
};

const TagForm = ({ handleSubmit, children, isFreeTag = true }) => (
  <Row gutter={[16, 16]}>
    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
      <Card className="highlight-card tag-form" title="フリータグ">
        <Form
          onSubmit={handleSubmit}
          layout="horizontal"
          colon={false}
        >
          <Field
            label="タグ"
            name="name"
            placeholder="タグ"
            component={TextField}
            required
            disabled={!isFreeTag}
            maxLength={50}
          />
          <Field
            label="表示優先度"
            name="order"
            min={0}
            max={1000}
            precision={0}
            formatter={(value) => Helpers.numberWithCommas(value)}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            component={InputNumber}
            requiredSpace
          />
        </Form>
      </Card>
    </Col>
    {children}
  </Row>
);

TagForm.propTypes = {
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
  isFreeTag: PropTypes.bool,
};

export default reduxForm({ form: 'tagForm', validate })(TagForm);
