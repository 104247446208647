import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';

import {
  getNotificationListRequest,
  readAllNotificationRequest,
  deleteNotificationRequest,
  markNotificationReadRequest,
} from 'providers/NotificationProvider/actions';
import {
  makeSelectNotificationList,
  makeSelectNotificationLoading,
  makeSelectNotificationListHasMore,
} from 'providers/NotificationProvider/selectors';
import { makeSelectCurrentUser } from 'providers/AuthProvider/selectors';

import { doRefresh } from 'providers/CommonProvider/actions';

import Notification from './Notification';

const mapDispatchToProps = (dispatch) => ({
  getNotificationListRequest: (data) => dispatch(getNotificationListRequest(data)),
  deleteNotificationRequest: (data) => dispatch(deleteNotificationRequest(data)),
  readAllNotificationRequest: () => dispatch(readAllNotificationRequest()),
  markNotificationReadRequest: (data) => dispatch(markNotificationReadRequest(data)),
  push: (path) => dispatch(push(path)),
  doRefresh: (data) => dispatch(doRefresh(data)),
});

const mapStateToProps = createStructuredSelector({
  notificationList: makeSelectNotificationList(),
  notificationListHasMore: makeSelectNotificationListHasMore(),
  notificationLoading: makeSelectNotificationLoading(),
  currentUser: makeSelectCurrentUser(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Notification);
