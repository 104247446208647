export const GET_MANUFACTURER_LIST_REQUEST = 'ManufacturerProvider/GET_MANUFACTURER_LIST_REQUEST';
export const GET_MANUFACTURER_LIST_SUCCESS = 'ManufacturerProvider/GET_MANUFACTURER_LIST_SUCCESS';
export const GET_MANUFACTURER_LIST_ERROR = 'ManufacturerProvider/GET_MANUFACTURER_LIST_ERROR';

export const CHANGE_STATUS_MANUFACTURER_REQUEST = 'ManufacturerProvider/CHANGE_STATUS_MANUFACTURER_REQUEST';
export const CHANGE_STATUS_MANUFACTURER_SUCCESS = 'ManufacturerProvider/CHANGE_STATUS_MANUFACTURER_SUCCESS';
export const CHANGE_STATUS_MANUFACTURER_ERROR = 'ManufacturerProvider/CHANGE_STATUS_MANUFACTURER_ERROR';

export const GET_MANUFACTURER_DETAIL_REQUEST = 'ManufacturerProvider/GET_MANUFACTURER_DETAIL_REQUEST';
export const GET_MANUFACTURER_DETAIL_SUCCESS = 'ManufacturerProvider/GET_MANUFACTURER_DETAIL_SUCCESS';
export const GET_MANUFACTURER_DETAIL_ERROR = 'ManufacturerProvider/GET_MANUFACTURER_DETAIL_ERROR';

export const CREATE_MANUFACTURER_REQUEST = 'ManufacturerProvider/CREATE_MANUFACTURER_REQUEST';
export const CREATE_MANUFACTURER_SUCCESS = 'ManufacturerProvider/CREATE_MANUFACTURER_SUCCESS';
export const CREATE_MANUFACTURER_ERROR = 'ManufacturerProvider/CREATE_MANUFACTURER_ERROR';

export const EXPORT_MANUFACTURER_REQUEST = 'ManufacturerProvider/EXPORT_MANUFACTURER_REQUEST';
export const EXPORT_MANUFACTURER_SUCCESS = 'ManufacturerProvider/EXPORT_MANUFACTURER_SUCCESS';
export const EXPORT_MANUFACTURER_ERROR = 'ManufacturerProvider/EXPORT_MANUFACTURER_ERROR';

export const UPDATE_MANUFACTURER_REQUEST = 'ManufacturerProvider/UPDATE_MANUFACTURER_REQUEST';
export const UPDATE_MANUFACTURER_SUCCESS = 'ManufacturerProvider/UPDATE_MANUFACTURER_SUCCESS';
export const UPDATE_MANUFACTURER_ERROR = 'ManufacturerProvider/UPDATE_MANUFACTURER_ERROR';
