import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { USER_ROLE } from 'utils/constants';
import BrandDetailForOperator from '../Operator_BrandDetail';
import BrandDetailForBrandOwner from '../BrandOwner_BrandDetail';
import ProductInBrand from '../ProductInBrand';

const RedirectToInAppConfig = (props) => {
  const { brandId } = _get(props, 'match.params');
  return <Redirect to={`/brands/detail/${brandId}/in-app`} />;
};

const renderBrandDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const brandDetailByRole = {
    [USER_ROLE.OPERATOR]: <BrandDetailForOperator {...props} />,
    [USER_ROLE.BRAND_OWNER]: <BrandDetailForBrandOwner {...props} />,
  };

  return brandDetailByRole[currentUser?.role];
};

const indexRoutes = [
  {
    path: '/brands/detail/:brandId/in-app',
    component: renderBrandDetailByRole,
  },
  {
    path: '/brands/detail/:brandId/product-in-brand',
    component: ProductInBrand,
  },
  {
    path: '/brands/detail/:brandId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
