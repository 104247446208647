module.exports = {
  API_APP_ID: process.env.REACT_APP_PARSE_APP_ID || '',
  API_REST_API_KEY: process.env.REACT_APP_PARSE_REST_API_KEY || '',
  API_JAVASCRIPT_KEY: process.env.REACT_APP_PARSE_SERVER_JAVASCRIPT_KEY || '',
  API_SERVER_URL: process.env.REACT_APP_PARSE_SERVER_URL || 'http://localhost:1337/api',
  REACT_APP_LIVE_QUERY_SERVER_URL: process.env.REACT_APP_LIVE_QUERY_SERVER_URL || 'ws://localhost:1337/api',
  API_SERVER_BASE_URL: process.env.REACT_APP_PARSE_SERVER_BASE_URL || 'http://localhost:1337',
  REACT_APP_MAX_MENU_IMAGE_SIZE: process.env.REACT_APP_MAX_MENU_IMAGE_SIZE ? Number(process.env.REACT_APP_MAX_MENU_IMAGE_SIZE) : 5e6,
  React_APP_MAX_FILE_SIZE: process.env.REACT_APP_MAX_FILE_SIZE ? Number(process.env.REACT_APP_MAX_FILE_SIZE) : 5e7,
};
