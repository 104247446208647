import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Button, Card, Space, Typography, Image
} from 'antd';
import '@ant-design/compatible/assets/index.css';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { RadioGroup } from 'components/Form';
import { SHIPPING_METHOD } from 'utils/constants';
import HelpImage from 'images/HelpDelivery/Image.png';
import ExplanationImage1 from 'images/HelpDelivery/Image-1.png';
import ExplanationImage2 from 'images/HelpDelivery/Image-2.png';
import ExplanationImage3 from 'images/HelpDelivery/Image-3.png';
import ExplanationImage4 from 'images/HelpDelivery/Image-4.png';
import './style.less';

const { Text } = Typography;

const ExplanationImage = {
  OWN_SHIPPING: {
    true: ExplanationImage2,
    false: ExplanationImage1,
  },
  SHIPPING_COMPANY: {
    true: ExplanationImage4,
    false: ExplanationImage3,
  },
};

const ShippingMethodForm = ({ formValues = {}, className = '' }) => (
  <Card
    headStyle={{ fontSize: 20, fontWeight: 'bold' }}
    bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
    className={`highlight-card ${className}`}
    title="配送方法"
    bordered={false}
  >
    <Space direction="vertical" className="full-w">
      <div>
        <div>配送方法を選択してください</div>
        <Text style={{ fontSize: 12, display: 'block' }}>
          店舗アプリに表示される最短納品日に影響します。両方ご利用の場合は「配送業者」の選択をおすすめします。
        </Text>
      </div>
      <div className="flex justify-between">
        <div style={{ flex: 1 }}>
          <Field
            name="shippingMethod"
            component={RadioGroup}
            isButton
            options={SHIPPING_METHOD}
          />
        </div>
        <Button
          target="_blank"
          href={HelpImage}
          type="link"
          icon={<QuestionCircleOutlined />}
        >
          ヘルプ
        </Button>
      </div>
    </Space>
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        color: 'rgba(0, 0, 0, 0.45)',
        height: 164,
        width: '100%',
        borderRadius: '4px',
        textAlign: 'center',
        marginTop: -8,
      }}
      className="flex justify-center items-center"
    >
      <Image
        src={_get(
          ExplanationImage[formValues.shippingMethod],
          `${formValues.leadTimeIncludeHoliday}`
        )}
        height={164}
        width="100%"
      />
    </div>
  </Card>
);

ShippingMethodForm.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default ShippingMethodForm;
