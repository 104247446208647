import moment from 'moment';
import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Typography, Space, Input, Avatar, Tooltip
} from 'antd';
import { push } from 'connected-react-router';

import { getRestaurantOwnerListRequest } from 'providers/RestaurantOwnerProvider/actions';

import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';

import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';
import StatusToggle from './StatusToggle';

const { Search } = Input;

const RestaurantList = () => {
  const restaurantOwnerList = useSelector(
    (state) => state.restaurantOwnerProvider.restaurantOwnerList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantOwnerListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = [
    {
      title: 'ID',
      width: 120,
      dataIndex: 'objectId',
      key: 'formattedObjectId',
    },
    {
      title: 'レストランオーナー名',
      width: 250,
      render: (record) => (
        <Space className="flex items-center">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultRestaurantImage)}
            size={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Space size={2}>
              {_get(record, 'user.emailVerified') ? (
                <Tooltip placement="topLeft" title="Email is verified">
                  <img alt="verified-icon" src={VerifiedImage} />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="topLeft"
                  title="Email has not been verified"
                >
                  <img alt="warning-icon" src={WarningImage} />
                </Tooltip>
              )}
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {_get(record, 'email', '-')}
              </Typography.Text>
            </Space>
          </Space>
        </Space>
      ),
    },
    {
      title: '登録日',
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },

    {
      title: 'レストラン件数',
      width: 130,
      dataIndex: 'restaurantCount',
      key: 'restaurantCount',
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
    },
    {
      title: 'ステータス',
      width: 130,
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      render: (record, rowRecord) => (
        <StatusToggle
          record={record}
          rowRecord={{ ...rowRecord, callBack: fetchData }}
        />
      ),
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <div className="flex items-center flex-wrap padding-16 border-bottom">
          <Search
            placeholder="メール/名で検索"
            defaultValue={keyword}
            onSearch={(val) => onFilterChange({ keyword: val })}
            style={{ width: 200 }}
            className="search-input"
          />
        </div>
        <Table
          columns={columns}
          data={_get(restaurantOwnerList, 'list', [])}
          total={_get(restaurantOwnerList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 780 }}
          onRowClick={(record) => {
            dispatch(
              push(`/restaurant-owner/detail/${record.objectId}/in-app`)
            );
          }}
        />
      </Card>
    </Card>
  );
};

export default RestaurantList;
