import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Card } from 'antd';
import Helpers from 'utils/helpers';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'utils/constants';

import { getCollectionMenusRequest } from 'providers/CollectionProvider/actions';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

const CollectionList = ({ match }) => {
  const [tableLoading, setTableLoading] = useState(false);

  const collectionMenus = useSelector(
    (state) => state.collectionProvider.collectionMenus,
    shallowEqual
  );

  const formattedMenuId = _get(match, 'params.productId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl();

  const { page, limit } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getCollectionMenusRequest({
            page,
            limit,
            formattedMenuId,
            opts: {
              getCollectionProductCount: true,
            },
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, formattedMenuId]);

  const columns = [
    {
      title: 'ID',
      key: 'ID',
      dataIndex: 'objectId',
      width: 130,
      fixed: 'left',
    },
    {
      title: 'セット商品名',
      dataIndex: ['collection', 'name'],
      key: 'name',
      width: 150,
    },
    {
      title: 'メモ',
      key: 'note',
      dataIndex: ['collection', 'note'],
      width: 150,
    },
    {
      title: '商品件数',
      key: 'productCount',
      dataIndex: ['collection', 'productCount'],
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
      width: 150,
    },
    {
      title: '作成日',
      key: 'createdAt',
      dataIndex: ['collection', 'createdAt'],
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
      width: 150,
    },
    {
      title: '最終更新日',
      key: 'updatedAt',
      dataIndex: ['collection', 'updatedAt'],
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
      width: 150,
    },
  ];

  return (
    <Card
      className="bg-transparent"
      bodyStyle={{ paddingTop: 16 }}
      bordered={false}
    >
      <Card className="highlight-card padding-card" bordered={false}>
        <Table
          columns={columns}
          data={_get(collectionMenus, 'list', [])}
          total={_get(collectionMenus, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 900 }}
        />
      </Card>
    </Card>
  );
};

CollectionList.propTypes = {
  match: PropTypes.any,
};
export default CollectionList;
