import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  RESEND_VERIFY_EMAIL_REQUEST,
  RESEND_VERIFY_EMAIL_SUCCESS,
  RESEND_VERIFY_EMAIL_ERROR,
  SET_RESTAURANT_REQUEST_COUNT,
  CLEAR_CURRENT_USER_DATA,
} from './constants';

export function getAuthToken() {
  return localStorage.getItem('token');
}

export function setAuthToken(data) {
  return localStorage.setItem('token', data);
}

export function removeAuthToken() {
  return localStorage.removeItem('token');
}

export function getCurrentUserRequest() {
  return { type: GET_CURRENT_USER_REQUEST, meta: { thunk: true } };
}

export function getCurrentUserSuccess(payload, meta) {
  return { type: GET_CURRENT_USER_SUCCESS, payload, meta };
}

export function getCurrentUserError(payload, meta) {
  return {
    type: GET_CURRENT_USER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function loginRequest(payload) {
  return { type: LOGIN_REQUEST, payload, meta: { thunk: true } };
}

export function loginSuccess(payload, meta) {
  return { type: LOGIN_SUCCESS, payload, meta };
}

export function loginError(payload, meta) {
  return {
    type: LOGIN_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function logoutRequest() {
  return { type: LOGOUT_REQUEST };
}

export function logoutSuccess(data) {
  return { type: LOGOUT_SUCCESS, data };
}

export function logoutError(error) {
  return { type: LOGOUT_ERROR, error };
}

export function resetPasswordRequest(payload) {
  return { type: RESET_PASSWORD_REQUEST, payload, meta: { thunk: true } };
}

export function resetPasswordSuccess(payload, meta) {
  return { type: RESET_PASSWORD_SUCCESS, payload, meta };
}

export function resetPasswordError(payload, meta) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function changePasswordRequest(payload) {
  return { type: CHANGE_PASSWORD_REQUEST, payload, meta: { thunk: true } };
}

export function changePasswordSuccess(payload, meta) {
  return { type: CHANGE_PASSWORD_SUCCESS, payload, meta };
}

export function changePasswordError(payload, meta) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload,
    meta,
    error: true
  };
}

export function signupRequest(payload) {
  return { type: SIGNUP_REQUEST, payload, meta: { thunk: true } };
}

export function signupSuccess(payload, meta) {
  return { type: SIGNUP_SUCCESS, payload, meta };
}

export function signupError(payload, meta) {
  return {
    type: SIGNUP_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function resendVerifyEmailRequest(payload) {
  return { type: RESEND_VERIFY_EMAIL_REQUEST, payload, meta: { thunk: true } };
}

export function resendVerifyEmailSuccess(payload, meta) {
  return { type: RESEND_VERIFY_EMAIL_SUCCESS, payload, meta };
}

export function resendVerifyEmailError(payload, meta) {
  return {
    type: RESEND_VERIFY_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function setRestaurantRequestCount(payload) {
  return { type: SET_RESTAURANT_REQUEST_COUNT, payload };
}

export function clearCurrentUserData(payload) {
  return { type: CLEAR_CURRENT_USER_DATA, payload };
}
