import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isDirty, reset } from 'redux-form';
import {
  Modal, Spin, Card, Descriptions, Button
} from 'antd';

import {
  getCollectionDetailRequest,
  updateCollectionRequest,
  assignMenuToCollectionRequest,
  removeCollectionMenusRequest,
  getCollectionMenusRequest,
  deleteCollectionRequest,
} from 'providers/CollectionProvider/actions';

import toastMessage from 'utils/toastMessage';
import { DATE_TIME_FORMAT } from 'utils/constants';

import TrashIcon from 'images/icon-trash.svg';
import CollectionsUpdate from '../Create/CollectionUpdate';
import DeleteCollectionModal from './DeleteCollectionModal';

import './style.less';

const { confirm } = Modal;

const showConfirm = (modalProps, actionFn) => {
  confirm({
    okText: '削除',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
    ...modalProps,
  });
};

const CollectionsInfo = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { collectionId } = useParams();
  const [visible, setVisible] = useState(false);
  const [collectionDetailLoading, setCollectionDetailLoading] = useState(false);
  const [updateActionLoading, setUpdateActionLoading] = useState(false);
  const [collectionMenusLoading, setCollectionMenusLoading] = useState(false);
  const [assignProductsSubmitLoading, setAssignProductsSubmitLoading] = useState(false);

  const dirty = useSelector((state) => isDirty('collectionCreateForm')(state));

  const handleCancel = () => {
    if (!dirty) {
      return dispatch(reset('collectionCreateForm'));
    }
    return showConfirm(
      { title: '中断してよろしいですか？', okText: 'はい' },
      () => dispatch(reset('collectionCreateForm'))
    );
  };

  const collectionDetail = useSelector(
    (state) => state.collectionProvider.collectionDetail,
    shallowEqual
  );

  const collectionMenus = useSelector(
    (state) => state.collectionProvider.collectionMenus,
    shallowEqual
  );

  const fetchCollectionDetail = useCallback(async () => {
    setCollectionDetailLoading(true);
    try {
      await dispatch(getCollectionDetailRequest({ objectId: collectionId }));
    } catch (error) {
      console.log(error);
    }
    setCollectionDetailLoading(false);
  }, [dispatch, collectionId]);

  const fetchCollectionMenus = useCallback(async () => {
    setCollectionMenusLoading(true);
    try {
      await dispatch(
        getCollectionMenusRequest({ page: 1, limit: 1000, collectionId })
      );
    } catch (error) {
      console.log(error);
    }
    setCollectionMenusLoading(false);
  }, [dispatch, collectionId]);

  useEffect(() => {
    fetchCollectionMenus();
  }, [fetchCollectionMenus]);

  const handleRemove = (record) => {
    showConfirm(
      {
        title: 'コレクションから商品を削除します?',
        content: `${record?.name} は ${collectionDetail?.name}から削除されます。コレクションにリンクしているレストラン商品からも削除されます。`,
        okButtonProps: { danger: true },
      },
      async () => {
        await dispatch(
          removeCollectionMenusRequest({
            collectionMenuId: record.assignedMenuId,
          })
        );
        fetchCollectionMenus();
        toastMessage.success({
          description: `商品グループ${collectionDetail?.name}から商品${record?.name}を削除しました。`,
        });
      }
    );
  };

  const handleDeleteCollection = async (param) => {
    try {
      await dispatch(
        deleteCollectionRequest({
          objectId: collectionId,
          ...param,
        })
      );

      toastMessage.success({
        description: `商品グループ${collectionDetail?.name}を削除しました。`,
      });
      replace('/collections');
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteButton = () => (
    <Button
      icon={<img src={TrashIcon} alt="" />}
      onClick={() => setVisible(true)}
      danger
      type="primary"
      className="delete-button"
    >
      セット商品を削除
    </Button>
  );

  const handleSubmit = async (values) => {
    setUpdateActionLoading(true);
    try {
      await dispatch(
        updateCollectionRequest({
          ...values,
          objectId: collectionDetail.objectId,
        })
      );
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      fetchCollectionDetail();
    } catch (err) {
      console.error(err);
    }
    setUpdateActionLoading(false);
  };

  const handleAssignMenu = async (menus) => {
    setAssignProductsSubmitLoading(true);
    try {
      await dispatch(
        assignMenuToCollectionRequest({
          collectionId,
          menuItems: menus.map((menu) => menu.objectId),
        })
      );
      fetchCollectionMenus();
    } catch (error) {
      console.error(error);
    }
    setAssignProductsSubmitLoading(false);
  };

  const handleMenuList = (menuList) => ({
    list: menuList?.list?.map((menu) => ({
      ...menu?.menu,
      images: menu?.menu?.images?.map((image) => ({ url: image })),
      assignedMenuId: menu?.objectId,
    })),
    total: menuList?.total,
  });

  const renderDate = (date, format = DATE_TIME_FORMAT) => (date ? moment(date).format(format) : '');

  if (collectionDetailLoading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <CollectionsUpdate
        title={`${collectionDetail?.name}商品選択`}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitLoading={updateActionLoading}
        submitConfirm={{
          title: 'コレクションに商品を追加します',
          content:
            'コレクションにリンクしているすべてのレストランに商品を追加します',
          okText: '保存',
          cancelText: 'キャンセル',
        }}
        handleAssignProductsSubmit={handleAssignMenu}
        assignProductsSubmitLoading={assignProductsSubmitLoading}
        handleRemoveAssignedProduct={handleRemove}
        assignedProducts={handleMenuList(collectionMenus)}
        assignedProductsLoading={collectionMenusLoading}
        collectionDetail={collectionDetail}
        deleteButton={<DeleteButton />}
      >
        <Card className="highlight-card" title="変更履歴" bordered={false}>
          <Descriptions
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            <Descriptions.Item label="作成日">
              {renderDate(collectionDetail?.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="最終更新日">
              {renderDate(collectionDetail?.updatedAt)}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </CollectionsUpdate>
      {visible && (
        <DeleteCollectionModal
          collectionName={collectionDetail?.name}
          setVisible={setVisible}
          onDelete={handleDeleteCollection}
        />
      )}
    </>
  );
};

export default CollectionsInfo;
