import produce from 'immer';

import {
  GET_BANNER_LIST_ERROR,
  GET_BANNER_LIST_REQUEST,
  GET_BANNER_LIST_SUCCESS,

} from './constants';

const initialState = {
  bannerList: [],
  isLoading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER_LIST_REQUEST:
      state.isLoading = true;
      break;
    case GET_BANNER_LIST_SUCCESS:
      state.isLoading = false;
      state.bannerList = action.payload;
      break;
    case GET_BANNER_LIST_ERROR:
      state.isLoading = false;
      break;

    default:
  }
  return state;
});

export default reducer;
