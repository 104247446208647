import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getRelatedProductListSuccess,
  getRelatedProductListError,
  addProductRelationSuccess,
  addProductRelationError,
  removeProductRelationError,
  removeProductRelationSuccess,
} from './actions';
import {
  GET_RELATED_PRODUCT_LIST_REQUEST,
  ADD_PRODUCT_RELATION_REQUEST,
  REMOVE_PRODUCT_RELATION_REQUEST,
} from './constants';

export function* handleGetRelatedProductList(action) {
  const { meta, payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRelatedProductList`,
      payload
    );
    yield put(getRelatedProductListSuccess(result, meta));
  } catch (error) {
    yield put(getRelatedProductListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAddProductRelation(action) {
  const { meta, payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}addProductRelation`,
      payload
    );

    yield put(addProductRelationSuccess(result, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました',
    });
  } catch (error) {
    yield put(addProductRelationError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveProductRelation(action) {
  const { meta, payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeProductRelation`,
      payload
    );

    yield put(removeProductRelationSuccess(result, meta));
    toastMessage.success({
      description: '削除しました。',
    });
  } catch (error) {
    yield put(removeProductRelationError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_RELATED_PRODUCT_LIST_REQUEST, handleGetRelatedProductList);
  yield takeLeading(ADD_PRODUCT_RELATION_REQUEST, handleAddProductRelation);
  yield takeLeading(REMOVE_PRODUCT_RELATION_REQUEST, handleRemoveProductRelation);
}
