import { call, put, takeLeading } from 'redux-saga/effects';
import _pick from 'lodash/pick';

import request from 'utils/request';
import toastMessage from 'utils/toastMessage';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import {
  changePrimaryEmailSuccess,
  changePrimaryEmailError,
  getInvitationEmailsSuccess,
  getInvitationEmailsError,
  removeInvitationEmailSuccess,
  removeInvitationEmailError,
  resendInvitationEmailSuccess,
  resendInvitationEmailError,
  addInvoiceEmailSuccess,
  addInvoiceEmailError,
} from './actions';
import {
  CHANGE_PRIMARY_EMAIL_REQUEST,
  GET_INVITATION_EMAILS_REQUEST,
  REMOVE_INVITATION_EMAIL_REQUEST,
  RESEND_INVITATION_EMAIL_REQUEST,
  ADD_INVOICE_EMAIL_REQUEST,
} from './constants';

export function* handleChangePrimaryEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}requestPrimaryEmailChange`,
      payload
    );
    yield put(changePrimaryEmailSuccess(result, meta));
    toastMessage.success({ description: '認証メールを送信しました。' });
  } catch (error) {
    yield put(changePrimaryEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetInvitationEmails(action) {
  const { payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getInvitationEmails`,
      payload
    );
    yield put(getInvitationEmailsSuccess(result));
  } catch (error) {
    yield put(getInvitationEmailsError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveInvitationEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeInvitationEmail`,
      payload
    );
    yield put(removeInvitationEmailSuccess(result, meta));
    toastMessage.success({ description: '削除しました。' });
  } catch (error) {
    yield put(removeInvitationEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleResendInvitationEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}resendInvitationEmail`,
      payload
    );
    yield put(resendInvitationEmailSuccess(result, meta));
    toastMessage.success({ description: '認証メールを再送信しました。' });
  } catch (error) {
    yield put(resendInvitationEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAddInvoiceEmail(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['restaurantId', 'emails', 'brandId']);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}addEmailForSendingInvoice`,
      params
    );
    yield put(addInvoiceEmailSuccess(result, meta));
    toastMessage.success({ description: '保存しました。' });
  } catch (error) {
    yield put(addInvoiceEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(CHANGE_PRIMARY_EMAIL_REQUEST, handleChangePrimaryEmail);
  yield takeLeading(GET_INVITATION_EMAILS_REQUEST, handleGetInvitationEmails);
  yield takeLeading(REMOVE_INVITATION_EMAIL_REQUEST, handleRemoveInvitationEmail);
  yield takeLeading(RESEND_INVITATION_EMAIL_REQUEST, handleResendInvitationEmail);
  yield takeLeading(ADD_INVOICE_EMAIL_REQUEST, handleAddInvoiceEmail);
}
