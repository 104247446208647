import { createSelector } from 'reselect';

const selectConversation = (state) => state.conversationProvider || {};

const makeSelectUnreadConversationCount = () => createSelector(selectConversation, (state) => state.unreadCount);
const makeSelectConversationListLoading = () => createSelector(selectConversation, (state) => state.loading);
const makeSelectConversationListHasMore = () => createSelector(selectConversation, (state) => state.hasMore);
const makeSelectConversationList = () => createSelector(selectConversation, (state) => state.conversationList);

export {
  makeSelectUnreadConversationCount,
  makeSelectConversationListLoading,
  makeSelectConversationListHasMore,
  makeSelectConversationList,
};
