import {
  call, put, takeLatest, takeLeading, select
} from 'redux-saga/effects';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _invoke from 'lodash/invoke';
import axios from 'axios';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX, USER_ROLE } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  registerSupplierSuccess,
  registerSupplierError,
  getSupplierListSuccess,
  getSupplierListError,
  getSupplierDetailSuccess,
  getSupplierDetailError,
  updateSupplierSuccess,
  updateSupplierError,
  getSupplierHolidayListSuccess,
  getSupplierHolidayListError,
  createSupplierHolidaySuccess,
  createSupplierHolidayError,
  deleteSupplierHolidaySuccess,
  deleteSupplierHolidayError,
  changeEmailSuccess,
  changeEmailError,
  getInvitationEmailError,
  getInvitationEmailSuccess,
  removeInvitationEmailError,
  removeInvitationEmailSuccess,
  resendInvitationEmailError,
  resendInvitationEmailSuccess,
  getListSupplierForRestaurantSuccess,
  getListSupplierForRestaurantError,
  disableSupplierAccountSuccess,
  disableSupplierAccountError,
  enableSupplierAccountSuccess,
  enableSupplierAccountError,
} from './actions';
import {
  REGISTER_SUPPLIER_REQUEST,
  GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST,
  GET_SUPPLIER_DETAIL_REQUEST,
  UPDATE_SUPPLIER_REQUEST,
  GET_SUPPLIER_HOLIDAY_LIST_REQUEST,
  CREATE_SUPPLIER_HOLIDAY_REQUEST,
  DELETE_SUPPLIER_HOLIDAY_REQUEST,
  CHANGE_EMAIL_REQUEST,
  GET_INVITATION_EMAIL_REQUEST,
  REMOVE_INVITATION_EMAIL_REQUEST,
  RESEND_INVITATION_EMAIL_REQUEST,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST,
  DISABLE_SUPPLIER_ACCOUNT_REQUEST,
  ENABLE_SUPPLIER_ACCOUNT_REQUEST,
} from './constants';

export function* handleRegisterSupplier(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
        {
          fileName: _get(image, 'originFileObj.name'),
          fileType: _get(image, 'originFileObj.type'),
          type: 'AVATAR',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
        headers: {
          'Content-Type': _get(image, 'originFileObj.type'),
        },
      })
      );
    }

    const params = _pick(payload, [
      'name',
      'nameKana',
      'postalCode',
      'prefecture',
      'address',
      'building',
      'phone',
      'fax',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'workingDays',
      'workingDays_manufacturer',
      'leadTimeIncludeHoliday',
      'shippingMethod',
      'shippingFee',
      'bankName',
      'bankBranchName',
      'bankAccountType',
      'bankAccountNumber',
      'bankAccountName',
      'invoiceShowPaymentDeadline',
      'invoiceNote',
    ]);

    if (payload.setPriceOfFreeShip) {
      params.priceOfFreeShip = payload.priceOfFreeShip;
    }
    if (payload.orderDeadlineTime) {
      params.orderDeadlineTime = _invoke(payload.orderDeadlineTime, 'toISOString') || payload.orderDeadlineTime;
    }

    params.image = fileId;

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}registerSupplier`,
      params
    );
    yield put(registerSupplierSuccess(result, meta));
  } catch (error) {
    yield put(registerSupplierError(error, meta));
  }
}

export function* handleGetListSupplier(action) {
  const currentUser = yield select((state) => state.authProvider.currentUser);

  const appCallForRole = {
    [USER_ROLE.OPERATOR]: `${CLOUD_FUNCTION_PREFIX}${
      action.payload?.brandOwnerId
        ? 'getSupplierListForBrandOwner'
        : 'getSupplierListForOperator'
    }`,
    [USER_ROLE.BRAND_OWNER]: `${CLOUD_FUNCTION_PREFIX}getSupplierListForBrandOwner`,
    [USER_ROLE.RESTAURANT_OWNER]: `${CLOUD_FUNCTION_PREFIX}rO_getSupplierList`,
  };

  try {
    const response = yield call(
      request,
      appCallForRole[_get(currentUser, 'role')],
      action.payload
    );
    yield put(getSupplierListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getSupplierListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetListSupplierForRestaurant(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getSupplierListForRestaurant`,
      action.payload
    );
    yield put(getListSupplierForRestaurantSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getListSupplierForRestaurantError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetSupplierDetail(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getSupplierDetail`,
      action.payload
    );
    yield put(getSupplierDetailSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getSupplierDetailError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateSupplier(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    const params = _pick(payload, [
      'name',
      'nameKana',
      'postalCode',
      'prefecture',
      'address',
      'building',
      'phone',
      'fax',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'workingDays',
      'workingDays_manufacturer',
      'leadTimeIncludeHoliday',
      'shippingMethod',
      'shippingFee',
      'invoiceClosingDate',
      'bankName',
      'bankBranchName',
      'bankAccountType',
      'bankAccountNumber',
      'bankAccountName',
      'invoiceShowPaymentDeadline',
      'invoiceNote',
      'newOrderEmailNotify',
      'dailyOrderReportEmail',
      'taxId',
    ]);
    if (payload.setPriceOfFreeShip) {
      params.priceOfFreeShip = payload.priceOfFreeShip;
    } else if (payload.setPriceOfFreeShip === false) {
      params.priceOfFreeShip = '';
    }

    if (payload.orderDeadlineTime) {
      params.orderDeadlineTime = _invoke(payload.orderDeadlineTime, 'toISOString') || payload.orderDeadlineTime;
    }

    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      if (image?.originFileObj) {
        const { result: fileUploadResult } = yield call(
          request,
          `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
          {
            fileName: _get(image, 'originFileObj.name'),
            fileType: _get(image, 'originFileObj.type'),
            type: 'AVATAR',
          }
        );

        fileId = _get(fileUploadResult, 'objectId');

        yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
          headers: {
            'Content-Type': _get(image, 'originFileObj.type'),
          },
        })
        );
      }
    }
    params.image = fileId || (_isArray(payload.image) && _isEmpty(payload.image) ? '' : undefined);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateSupplier`,
      params
    );
    yield put(updateSupplierSuccess(result, meta));
  } catch (error) {
    yield put(updateSupplierError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetSupplierHolidayList(action) {
  try {
    const params = _pick(action.payload, ['limit', 'page', 'supplierId']);
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getSupplierHolidayList`,
      params
    );
    yield put(getSupplierHolidayListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getSupplierHolidayListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleCreateSupplierHoliday(action) {
  const { payload, meta } = action;

  const params = _pick(payload, ['date', 'applyTo']);
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createSupplierHoliday`, {
        ...params,
      }
    );
    yield put(createSupplierHolidaySuccess(result, meta));
  } catch (error) {
    yield put(createSupplierHolidayError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleDeleteSupplierHoliday(action) {
  const { payload, meta } = action;

  const params = _pick(payload, ['objectId']);
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}deleteSupplierHoliday`,
      params
    );
    yield put(deleteSupplierHolidaySuccess(result, meta));
  } catch (error) {
    yield put(deleteSupplierHolidayError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleChangeEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}requestPrimaryEmailChangeForDashboardUser`,
      payload
    );
    yield put(changeEmailSuccess(result, meta));
    toastMessage.success({
      message: '確認メールが送信されました。',
      description: `${payload.email} に認証依頼をお送りしました。メールに記載されたボタンをタップして認証してください。`,
    });
  } catch (error) {
    yield put(changeEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetInvitationEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getInvitationEmailsForDashboardUser`,
      payload
    );
    yield put(getInvitationEmailSuccess(result, meta));
  } catch (error) {
    yield put(getInvitationEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveInvitationEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeInvitationEmailForDashboardUser`,
      payload
    );
    yield put(removeInvitationEmailSuccess(result, meta));
  } catch (error) {
    yield put(removeInvitationEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleResendInvitationEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}resendInvitationEmailForDashboardUser`,
      payload
    );
    yield put(resendInvitationEmailSuccess(result, meta));
    toastMessage.success({ description: '認証メールを再送しました' });
  } catch (error) {
    yield put(resendInvitationEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleDisableSupplierAccount(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}disableSupplierAccount`,
      payload
    );

    yield put(disableSupplierAccountSuccess(result, meta));
  } catch (error) {
    yield put(disableSupplierAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleEnableSupplierAccount(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}enableSupplierAccount`,
      payload
    );

    yield put(enableSupplierAccountSuccess(result, meta));
  } catch (error) {
    yield put(enableSupplierAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST, handleGetListSupplier);
  yield takeLatest(GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST, handleGetListSupplierForRestaurant);
  yield takeLatest(REGISTER_SUPPLIER_REQUEST, handleRegisterSupplier);
  yield takeLatest(GET_SUPPLIER_DETAIL_REQUEST, handleGetSupplierDetail);
  yield takeLatest(UPDATE_SUPPLIER_REQUEST, handleUpdateSupplier);
  yield takeLatest(GET_SUPPLIER_HOLIDAY_LIST_REQUEST, handleGetSupplierHolidayList);
  yield takeLeading(CREATE_SUPPLIER_HOLIDAY_REQUEST, handleCreateSupplierHoliday);
  yield takeLeading(DELETE_SUPPLIER_HOLIDAY_REQUEST, handleDeleteSupplierHoliday);
  yield takeLeading(CHANGE_EMAIL_REQUEST, handleChangeEmail);
  yield takeLatest(GET_INVITATION_EMAIL_REQUEST, handleGetInvitationEmail);
  yield takeLeading(REMOVE_INVITATION_EMAIL_REQUEST, handleRemoveInvitationEmail);
  yield takeLeading(RESEND_INVITATION_EMAIL_REQUEST, handleResendInvitationEmail);
  yield takeLeading(DISABLE_SUPPLIER_ACCOUNT_REQUEST, handleDisableSupplierAccount);
  yield takeLeading(ENABLE_SUPPLIER_ACCOUNT_REQUEST, handleEnableSupplierAccount);
}
