import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import { Alert, Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { MultipleSelectionWithRemoteSearch } from 'components/Form';
import { pagingGetMenuItemListRequest } from 'providers/MenuProvider/actions';

import { STATUS } from 'utils/constants';
import DefaultProductImage from 'images/defaultProduct.svg';

import './style.less';

const RelatedProductsField = ({
  label,
  placeholder,
  title,
  ownerId,
  selectedProductIds = [],
  relatedProductIds = []
}) => {
  const dispatch = useDispatch();

  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );

  const fetchProductList = useCallback(
    (keyword) => {
      dispatch(
        pagingGetMenuItemListRequest({
          keyword,
          limit: 10000,
          menuType: 'BASIC',
          opts: { getMenuCountPerType: false, getWarehouseMenuData: false }
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    fetchProductList();
  }, [fetchProductList]);

  const getProductListWithDelay = useCallback(
    _debounce((keyword) => {
      fetchProductList(keyword);
    }, 500),
    []
  );

  const onSearchProductKeyWork = (keyword) => {
    getProductListWithDelay(keyword);
  };

  const options = _reduce(
    productList.list,
    (pre, product) => {
      const image = _get(product, 'images[0].url', DefaultProductImage);
      const extra = product?.status === STATUS.INACTIVE && (
        <Typography.Text
          style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft: 4 }}
        >
          無効
        </Typography.Text>
      );

      if (product.objectId === ownerId) {
        return pre;
      }

      const obj = {
        ...product,
        image,
        extra,
      };

      if (selectedProductIds?.includes(product.objectId)) {
        obj.isAdded = true;
      }

      return [...pre, obj];
    },
    []
  );

  const isSelectedRelatedProductsContainInactiveItem = _findIndex(
    productList?.list,
    (o) => o.status === STATUS.INACTIVE && relatedProductIds?.includes(o.objectId)
  ) > -1;

  const note = isSelectedRelatedProductsContainInactiveItem && (
    <Alert
      message="ステータスが無効の商品であるため、レストランには案内されません"
      type="warning"
      showIcon
      icon={<WarningOutlined />}
    />
  );

  return (
    <Field
      name="relatedProductIds"
      component={MultipleSelectionWithRemoteSearch}
      options={options}
      onSearch={onSearchProductKeyWork}
      note={note}
      avatarShape="square"
      placeholder={placeholder}
      label={label}
      title={title}
    />
  );
};

RelatedProductsField.propTypes = {
  label: PropTypes.string,
  ownerId: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.element,
  selectedProductIds: PropTypes.array,
  relatedProductIds: PropTypes.array,
};

export default RelatedProductsField;
