import { createSelector } from 'reselect';

const selectRestaurant = (state) => state.restaurantProvider || {};

const makeSelectRestaurantList = () => createSelector(selectRestaurant, (state) => state.restaurantList);
const makeSelectRestaurantDetail = () => createSelector(selectRestaurant, (state) => state.restaurantDetail);
const makeSelectRestaurantMenuItemList = () => createSelector(selectRestaurant, (state) => state.restaurantMenuItemList);
const makeSelectRestaurantMenuItemTotal = () => createSelector(selectRestaurant, (state) => state.restaurantMenuItemTotal);
const makeSelectRestaurantActionLoading = () => createSelector(selectRestaurant, (state) => state.actionLoading);
const makeSelectRestaurantDataLoading = () => createSelector(selectRestaurant, (state) => state.dataLoading);
const makeSelectAssignedMenuItemList = () => createSelector(selectRestaurant, (state) => state.allAssignedMenuItemList);
const makeSelectAssignedMenuItemTotal = () => createSelector(selectRestaurant, (state) => state.allAssignedMenuItemTotal);

export {
  makeSelectRestaurantList,
  makeSelectRestaurantDetail,
  makeSelectRestaurantActionLoading,
  makeSelectRestaurantDataLoading,
  makeSelectRestaurantMenuItemList,
  makeSelectRestaurantMenuItemTotal,
  makeSelectAssignedMenuItemList,
  makeSelectAssignedMenuItemTotal,
};
