import moment from 'moment';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Button, Space, Badge, Typography, Avatar, Tooltip
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import useTableControl from 'components/Hooks/useTableControl';

import DefaultProductImage from 'images/defaultProduct.svg';
import {
  DATE_TIME_FORMAT,
  ANNOUNCEMENT_STATUS,
  RESTAURANT_ANNOUNCEMENT,
} from 'utils/constants';
import Table from 'components/Table';
import Helpers from 'utils/helpers';

import './style.less';

export const STATUS_BADGE_CONTENT = {
  [ANNOUNCEMENT_STATUS.PENDING]: {
    type: 'warning',
    text: '配信予定',
  },
  [ANNOUNCEMENT_STATUS.PUBLISHED]: {
    type: 'success',
    text: '配信済',
  },
  [ANNOUNCEMENT_STATUS.RUNNING]: {
    type: 'processing',
    text: '配信中',
  },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'objectId',
    fixed: 'left',
    width: 130,
  },
  {
    title: 'タイトル',
    dataIndex: 'title',
    width: 240,
    render: (value) => (
      <Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: 2, tooltip: value }}
      >
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    title: '本文',
    dataIndex: 'message',
    width: 240,
    render: (value) => (
      <Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: 2, tooltip: value }}
      >
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    title: '添付ファイル',
    key: 'attachmentFile',
    render: (value) => {
      const attachmentFile = _get(value, 'attachmentFile');
      const productDetail = _get(value, 'menu');
      const productItem = (
        <div className="flex items-center full-w product-item">
          <Avatar
            alt="name"
            src={_get(productDetail, 'images[0].url', DefaultProductImage)}
            size={32}
            shape="square"
          />
          <Space
            direction="vertical"
            size={0}
            className="restaurant-info-wrapper"
          >
            <Tooltip placement="topLeft" title={_get(productDetail, 'name')}>
              <Typography.Text ellipsis>
                {_get(productDetail, 'name')}
              </Typography.Text>
            </Tooltip>
            <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
              {_get(productDetail, 'standard', '-')} •{' '}
              {_get(productDetail, 'amount', '-')} ／{' '}
              {_get(productDetail, 'unit', '-')}
            </Typography.Text>
          </Space>
        </div>
      );
      const fileItem = (
        <Typography.Paragraph
          style={{ marginBottom: 0 }}
          ellipsis={{ rows: 2, tooltip: attachmentFile?.name }}
        >
          {attachmentFile?.name}
        </Typography.Paragraph>
      );
      const destinationUrl = value?.url && (
        <Tooltip title={value?.url}>
          <Typography.Paragraph ellipsis={{ rows: 2 }}>
            {value?.url}
          </Typography.Paragraph>
        </Tooltip>
      );
      return (
        <>
          {attachmentFile?.url && fileItem}
          {productDetail && productItem}
          {destinationUrl}
          {!attachmentFile?.url && !productDetail && !destinationUrl && '--'}
        </>
      );
    },
    width: 240,
  },
  {
    title: '配信日時',
    dataIndex: 'announceTime',
    width: 150,
    render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
  },
  {
    title: '通知対象',
    width: 240,
    render: (data) => {
      const announcementTarget = data?.announcementTarget;
      const restaurantGroup = data?.restaurantGroup;
      const displayData = announcementTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL
        ? '全てのユーザー'
        : restaurantGroup?.name;

      return (
        <Typography.Paragraph
          style={{ marginBottom: 0 }}
          ellipsis={{ rows: 2, tooltip: displayData }}
        >
          {displayData}
        </Typography.Paragraph>
      );
    },
  },
  {
    title: '総数',
    key: 'sum',
    width: 130,
    render: (record) => {
      if (record?.status !== ANNOUNCEMENT_STATUS.PUBLISHED) {
        return '-';
      }
      const nPushSuccess = record?.result?.nPushSuccess;
      if (record?.announcementTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL) {
        const nTargetInstallations = record?.result?.nTargetInstallations;
        if (nTargetInstallations) {
          return `${Helpers.numberWithCommas(nTargetInstallations)}件`;
        }
        if (nPushSuccess) {
          return `${Helpers.numberWithCommas(nPushSuccess)}件`;
        }
        return '-';
      }
      const sum = record?.sum;
      if (sum) {
        return `${Helpers.numberWithCommas(sum)}件`;
      }
      if (nPushSuccess) {
        return `${Helpers.numberWithCommas(nPushSuccess)}件`;
      }
      return '-';
    },
  },
  {
    title: '許諾数',
    key: 'sumHasDeviceToken',
    width: 130,
    render: (record) => {
      if (record?.status !== ANNOUNCEMENT_STATUS.PUBLISHED) {
        return '-';
      }
      if (record?.announcementTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL) {
        const nTargetInstallationWithDeviceToken = record?.result?.nTargetInstallationWithDeviceToken;
        if (nTargetInstallationWithDeviceToken) {
          return `${Helpers.numberWithCommas(
            nTargetInstallationWithDeviceToken
          )}件`;
        }
        return '-';
      }
      if (
        record?.restaurantGroup?.key
        === RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.GUEST_USER
      ) {
        const sumInstallationHasDeviceToken = record?.sumInstallationHasDeviceToken;
        if (sumInstallationHasDeviceToken) {
          return `${Helpers.numberWithCommas(sumInstallationHasDeviceToken)}件`;
        }
        return '-';
      }
      const sumRestaurantHasDeviceToken = record?.sumRestaurantHasDeviceToken;
      if (sumRestaurantHasDeviceToken) {
        return `${Helpers.numberWithCommas(sumRestaurantHasDeviceToken)}件`;
      }
      return '-';
    },
  },
  {
    title: '開封数',
    key: 'sumIsOpened',
    width: 130,
    render: (record) => {
      if (
        record?.status !== ANNOUNCEMENT_STATUS.PUBLISHED
        || record?.announcementTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL
        || record?.restaurantGroup?.key
          === RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.GUEST_USER
      ) {
        return '-';
      }
      const sumIsOpened = record?.sumIsOpened;
      if (sumIsOpened) {
        return `${Helpers.numberWithCommas(sumIsOpened)}件`;
      }
      return '-';
    },
  },
  {
    title: 'ステータス',
    key: 'status',
    width: 130,
    fixed: 'right',
    render: (record) => (
      <div id="announcement-detail-status-dot">
        {[
          ANNOUNCEMENT_STATUS.PENDING,
          ANNOUNCEMENT_STATUS.PUBLISHED,
          ANNOUNCEMENT_STATUS.RUNNING,
        ].includes(record.status) && (
          <Badge
            status={STATUS_BADGE_CONTENT[record.status].type}
            text={STATUS_BADGE_CONTENT[record.status].text}
          />
        )}
      </div>
    ),
  },
];

const AnnouncementList = ({
  getListRestaurantAnnouncement,
  isRestaurantAnnouncementLoading,
  restaurantAnnouncementTotal,
  restaurantAnnouncements,
  push,
}) => {
  const [pageControl, , onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  useEffect(() => {
    getListRestaurantAnnouncement({ page, limit });
  }, [page, limit]);

  const onRowClick = (record) => {
    push(`/announcements/detail/${record.objectId}`);
  };

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Space
          direction="horizontal"
          size="middle"
          className="flex justify-end padding-16 border-bottom"
          id="manufacturer-list-action"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => push('/announcements/create')}
          >
            新規作成
          </Button>
        </Space>
        <Table
          columns={columns}
          onRowClick={onRowClick}
          data={restaurantAnnouncements}
          total={restaurantAnnouncementTotal}
          loading={isRestaurantAnnouncementLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 1760 }}
        />
      </Card>
    </Card>
  );
};

AnnouncementList.propTypes = {
  restaurantAnnouncements: PropTypes.array,
  restaurantAnnouncementTotal: PropTypes.number,
  isRestaurantAnnouncementLoading: PropTypes.bool,
  getListRestaurantAnnouncement: PropTypes.func,
  push: PropTypes.func,
};

export default AnnouncementList;
