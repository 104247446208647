import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

import {
  restaurantOwnerInviteRestaurantRequest,
  restaurantOwnerRequestConnectToRestaurantRequest,
} from 'providers/RestaurantOwnerProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';

import toastMessage from 'utils/toastMessage';

import InviteRestaurantForm from './InviteRestaurantForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: actionFn,
  });
};

const InviteRestaurant = ({ handleCloseDialog, visible }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    showConfirm('中断してよろしいですか？', handleCloseDialog);
  };

  const [createActionLoading, setCreateActionLoading] = useState(false);

  const handleSubmit = async (restaurantList, emailList) => {
    setCreateActionLoading(true);
    try {
      const promises = [];
      if (!_isEmpty(emailList)) {
        promises.push(
          dispatch(
            restaurantOwnerInviteRestaurantRequest({
              emails: emailList.map((item) => item.name),
            })
          )
        );
      }
      if (!_isEmpty(restaurantList)) {
        promises.push(
          dispatch(
            restaurantOwnerRequestConnectToRestaurantRequest({
              restaurantIds: restaurantList.map((item) => item.objectId),
            })
          )
        );
      }
      await Promise.all(promises);
      toastMessage.success({
        message: '成功',
        description: '招待を送信しました',
      });
      handleCloseDialog();
      dispatch(doRefresh({ target: 'restaurantList' }));
    } catch (error) {
      console.error(error);
    }
    setCreateActionLoading(false);
  };

  return (
    <Modal
      title="レストランを招待する"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <p>
        管理したいレストランを招待することができます。<br />
        招待したいレストランのメールアドレスを入力してください。一度に複数設定できます。 <br />
        レストランが招待を受け入れるとそのレストランの情報を参照することがっ出来ます。
      </p>
      <InviteRestaurantForm
        {...{ createActionLoading, handleSubmit }}
        onCancel={handleCancel}
      />
    </Modal>
  );
};

InviteRestaurant.propTypes = {
  handleCloseDialog: PropTypes.func,
  visible: PropTypes.bool,
  brandId: PropTypes.any,
};

export default InviteRestaurant;
