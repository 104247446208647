import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  Tabs, Card, Spin, Avatar, Typography, Space
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import withRefresh from 'components/HOCs/withRefresh';
import PageHeader from 'components/PageHeader';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';

import { getRestaurantDetailRequest } from 'providers/RestaurantProvider/actions';

import indexRoutes from './routes';
import './style.less';

const { TabPane } = Tabs;
const { Text } = Typography;

const RestaurantDetail = () => {
  const dispatch = useDispatch();
  const [restaurantDetailLoaded, setRestaurantDetailLoaded] = useState(false);
  const { restaurantId } = useParams();
  const { pathname } = useLocation();
  const { replace, goBack } = useHistory();
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail
  );
  const fetchRestaurantDetail = useCallback(async () => {
    await dispatch(
      getRestaurantDetailRequest({
        formattedObjectId: restaurantId,
      })
    );
    setRestaurantDetailLoaded(true);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchRestaurantDetail();
  }, [fetchRestaurantDetail]);

  const onTabClick = (key) => {
    const pushPath = `/restaurants/detail/${restaurantId}/${key}`;
    replace(pushPath);
  };

  const selectedKeys = pathname.split('/')[4];

  const breadcrumbItems = [
    {
      path: '/restaurants',
      breadcrumbName: 'レストラン',
    },
    {
      breadcrumbName: _get(restaurantDetail, 'name', '-'),
    },
  ];

  const renderRestaurantInfoHeader = () => (
    <Space>
      <Avatar
        size={40}
        src={_get(restaurantDetail, 'image', DefaultRestaurantImage)}
      />
      <div>
        <Text
          className="restaurant-name"
          style={{
            width: 250,
          }}
          ellipsis={{
            tooltip: restaurantDetail?.name,
          }}
        >
          {_get(restaurantDetail, 'name', '')}
        </Text>
        <Text
          className="email"
          type="secondary"
          copyable={
            restaurantDetailLoaded && {
              tooltips: ['コピー', 'コピーしました'],
            }
          }
        >
          {_get(restaurantDetail, 'email', '')}
        </Text>
      </div>
    </Space>
  );

  if (!restaurantDetailLoaded) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="restaurant-detail-page">
      <PageHeader
        onBack={goBack}
        title={renderRestaurantInfoHeader()}
        breadcrumbRoutes={breadcrumbItems}
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="概要" key="in-app" />
            <TabPane tab="販売者" key="connected-suppliers" />
            <TabPane tab="ポイント付与・利用履歴" key="point-history" />
          </Tabs>
        )}
      />
      <Card className="bg-transparent" bordered={false}>
        <Switch>
          {indexRoutes.map((route, key) => (
            <Route key={key} path={route.path} component={route.component} />
          ))}
        </Switch>
      </Card>
    </div>
  );
};

export default withRefresh('restaurantDetail')(RestaurantDetail);
