import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _union from 'lodash/union';
import _get from 'lodash/get';
import _find from 'lodash/find';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography, Space, Button, Tag, Dropdown, Menu, Collapse
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import {
  getCollectionListRequest,
  getCollectionMenusRequest,
} from 'providers/CollectionProvider/actions';

import { STATUS, MENU_PRICE_TYPE } from 'utils/constants';
import './style.less';

const { Panel } = Collapse;

const renderCollectionList = (
  collectionList,
  selectedCollectionIds,
  setSelectedCollectionIds,
  getCollectionMenus
) => (
  <Menu
    style={{
      maxHeight: 314,
      overflowY: 'auto',
    }}
  >
    {_isEmpty(collectionList) && (
      <div style={{ textAlign: 'center' }}>
        <Typography.Text>セット商品 なし</Typography.Text>
      </div>
    )}
    {_map(collectionList, (collectionItem) => {
      const collectionId = collectionItem?.objectId;

      const handleSelectCollectionItem = async () => {
        await getCollectionMenus(collectionId);
        setSelectedCollectionIds((prev) => _union(prev, [collectionId]));
      };

      const isCollectionSelected = selectedCollectionIds.includes(collectionId);

      return (
        <Menu.Item
          key={collectionId}
          onClick={handleSelectCollectionItem}
          disabled={isCollectionSelected}
        >
          <Space
            className="full-w flex items-start justify-end"
            style={{
              width: 314,
            }}
          >
            {isCollectionSelected && (
              <CheckOutlined
                style={{
                  color: 'green',
                }}
              />
            )}
            <Space
              direction="vertical"
              style={{
                width: 294,
              }}
            >
              <Typography.Text ellipsis strong>
                {collectionItem?.name}
              </Typography.Text>
              <Typography.Text type="secondary">
                {collectionItem?.productCount || 0} products
              </Typography.Text>
              <Typography.Text ellipsis type="secondary">
                {collectionItem?.note}
              </Typography.Text>
            </Space>
          </Space>
        </Menu.Item>
      );
    })}
  </Menu>
);

const AssignCollections = ({
  selectedCollectionIds,
  setSelectedCollectionIds,
  selectedMenusMap,
  setSelectedMenusMap,
  children,
}) => {
  const dispatch = useDispatch();
  const [collectionListLoading, setCollectionDetailLoading] = useState(false);
  const [collectionMenuLoading, setCollectionMenuLoading] = useState(false);

  const { list: collectionList } = useSelector(
    (state) => state.collectionProvider.collectionList,
    shallowEqual
  );

  const fetchData = useCallback(async () => {
    setCollectionDetailLoading(true);
    await dispatch(
      getCollectionListRequest({
        page: 1,
        limit: 1000,
        status: STATUS.ACTIVE,
      })
    );
    setCollectionDetailLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getCollectionMenus = async (collectionId) => {
    setCollectionMenuLoading(true);
    try {
      const collectionMenus = await dispatch(
        getCollectionMenusRequest({
          page: 1,
          limit: 1000,
          collectionId,
          opts: {
            getMenuPrices: true,
          },
        })
      );
      const incomingCollMenuList = _get(collectionMenus, 'menus.list');

      setSelectedMenusMap((preMenusMap) => {
        const incomingMenusMap = { ...preMenusMap };
        _forEach(incomingCollMenuList, ({ menu: incomingMenu }) => {
          if (incomingMenusMap[incomingMenu.objectId]) {
            if (
              _isEmpty(incomingMenusMap?.[incomingMenu.objectId]?.collectionIds)
            ) {
              incomingMenusMap[incomingMenu.objectId].collectionIds = [
                collectionId,
              ];
            } else {
              const currentMenu = incomingMenusMap?.[incomingMenu.objectId];
              incomingMenusMap[incomingMenu.objectId] = {
                ...currentMenu,
                collectionIds: [...currentMenu.collectionIds, collectionId],
              };
            }
          } else {
            const masterMenuPrice = _find(
              incomingMenu.prices,
              (o) => o.type === MENU_PRICE_TYPE.MASTER
            );
            incomingMenusMap[incomingMenu.objectId] = {
              ...incomingMenu,
              menuPriceId: masterMenuPrice?.objectId,
              collectionIds: [collectionId],
            };
          }
        });
        return incomingMenusMap;
      });
    } catch (err) {
      console.error(err);
    }
    setCollectionMenuLoading(false);
  };

  const renderSelectedCollections = _map(
    selectedCollectionIds,
    (collectionId) => {
      const selectedCollection = _find(
        collectionList,
        (o) => o.objectId === collectionId
      );

      const onDeselectCollection = (e) => {
        e.preventDefault();
        setSelectedCollectionIds((prevCollectionIds) => {
          const newCollectionIds = _filter(
            prevCollectionIds,
            (id) => id !== collectionId
          );
          return newCollectionIds;
        });

        setSelectedMenusMap((preMenusMap) => {
          const incomingMenusMap = { ...preMenusMap };
          _forEach(incomingMenusMap, (menu, key) => {
            if (
              menu?.collectionIds?.length === 1
              && menu?.collectionIds?.includes(collectionId)
            ) {
              delete incomingMenusMap[key];
            }
            if (
              menu?.collectionIds?.length > 1
              && menu?.collectionIds?.includes(collectionId)
            ) {
              incomingMenusMap[key] = {
                ...menu,
                collectionIds: menu?.collectionIds?.filter(
                  (el) => el !== collectionId
                ),
              };
            }
          });
          return incomingMenusMap;
        });
      };

      return (
        <span
          className="collectionTag"
          key={collectionId}
          style={{
            position: 'relative',
          }}
        >
          <Tag closable onClose={onDeselectCollection}>
            <div>{selectedCollection?.name}</div>
          </Tag>
        </span>
      );
    }
  );

  return (
    <Collapse ghost expandIconPosition="right" defaultActiveKey="1">
      <Panel
        header={`セット商品 (${
          _filter(
            Object.values(selectedMenusMap),
            (o) => !_isEmpty(o.collectionIds)
          ).length
        } 商品)`}
        key="1"
      >
        <div>
          {!_isEmpty(selectedCollectionIds) && (
            <div className="collection-tag">{renderSelectedCollections}</div>
          )}
          <Dropdown
            trigger={['click']}
            overlay={renderCollectionList(
              collectionList,
              selectedCollectionIds,
              setSelectedCollectionIds,
              getCollectionMenus
            )}
          >
            <Button
              type="link"
              loading={collectionListLoading || collectionMenuLoading}
              disabled={collectionListLoading || collectionMenuLoading}
              style={{
                margin: '4px 0',
                padding: '2px 16px 2px 8px',
                borderRadius: '4px',
                backgroundColor: '#e7f2ff',
                fontSize: '14px',
                color: '#1890ff',
              }}
            >
              + セット商品選択
            </Button>
          </Dropdown>
        </div>
        {children}
      </Panel>
    </Collapse>
  );
};

AssignCollections.propTypes = {
  setSelectedCollectionIds: PropTypes.func,
  selectedCollectionIds: PropTypes.array,
  selectedMenusMap: PropTypes.any,
  setSelectedMenusMap: PropTypes.func,
  children: PropTypes.any,
};

export default React.memo(AssignCollections);
