import _get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Tag, Typography, Space, Skeleton
} from 'antd';
import { Link } from 'react-router-dom';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';
import {
  getRestaurantDiscountPointHistoryRequest,
  getRestaurantPointInfoRequest,
} from 'providers/RestaurantPointProvider/actions';

const { Text } = Typography;

const TransactionType = {
  GrantedInvoice: 'GrantedInvoice',
  GrantedReturnedOrder: 'GrantedReturnedOrder',
  UsageOrder: 'UsageOrder',
  ExpireExpiredPoint: 'ExpireExpiredPoint',
  ExpireDeleteAccount: 'ExpireDeleteAccount',
};
const TransactionTypeColor = {
  [TransactionType.GrantedInvoice]: '#399E0E',
  [TransactionType.GrantedReturnedOrder]: '#399E0E',
  [TransactionType.UsageOrder]: '#000000D9',
  [TransactionType.ExpireExpiredPoint]: '#CF1421',
  [TransactionType.ExpireDeleteAccount]: '#CF1421',
};
const TransactionTypeJP = {
  [TransactionType.GrantedInvoice]: '獲得',
  [TransactionType.GrantedReturnedOrder]: '獲得',
  [TransactionType.UsageOrder]: '失効',
  [TransactionType.ExpireExpiredPoint]: '利用',
  [TransactionType.ExpireDeleteAccount]: '利用',
};
const TransactionPointColor = {
  [TransactionType.GrantedInvoice]: '##000000D9',
  [TransactionType.GrantedReturnedOrder]: '##000000D9',
  [TransactionType.UsageOrder]: '#CF1421',
  [TransactionType.ExpireExpiredPoint]: '#CF1421',
  [TransactionType.ExpireDeleteAccount]: '#CF1421',
};

const DiscountPointHistory = () => {
  const dispatch = useDispatch();

  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail
  );
  const restaurantId = restaurantDetail?.objectId;

  const [tableLoading, setTableLoading] = useState(false);
  const [pageControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const discountPointHistory = useSelector(
    (state) => state?.restaurantPointProvider?.restaurantDiscountPointHistory,
    shallowEqual
  );
  const fetchDiscountPointHistory = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantDiscountPointHistoryRequest({
          restaurantId,
          page,
          limit,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [dispatch, page, limit, restaurantId]);

  const [isFetchingRestaurantPointInfo, setIsFetchingRestaurantPointInfo] = useState(true);
  const [restaurantPointInfo, setRestaurantPointInfo] = useState();
  const fetchPointInfo = useCallback(async () => {
    setIsFetchingRestaurantPointInfo(true);
    try {
      const pointInfoResult = await dispatch(
        getRestaurantPointInfoRequest({
          restaurantId,
        })
      );
      setRestaurantPointInfo(pointInfoResult);
    } catch (error) {
      console.error(error);
    }
    setIsFetchingRestaurantPointInfo(false);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchDiscountPointHistory();
    fetchPointInfo();
  }, [fetchDiscountPointHistory, fetchPointInfo]);

  const columns = [
    {
      title: '履歴日',
      dataIndex: 'createdAt',
      render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '種別',
      dataIndex: 'type',
      render: (record) => (
        <Tag color={TransactionTypeColor[record]}>
          {TransactionTypeJP[record]}
        </Tag>
      ),
    },
    {
      title: '注文',
      dataIndex: 'orderId',
      render: (record) => <Link to={`/orders/detail/${record}`}>{record}</Link>,
    },
    {
      title: 'ポイント',
      align: 'right',
      render: (record) => (
        <Text
          style={{
            color: TransactionPointColor[record?.type],
          }}
        >
          {[
            TransactionType.UsageOrder,
            TransactionType.ExpireExpiredPoint,
            TransactionType.ExpireDeleteAccount,
          ].includes(record?.type) && '-'}
          {helpers.numberWithCommas(record?.point || 0)}pt
        </Text>
      ),
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <Space size={4} className="flex justify-end padding-16">
        <Text type="secondary" style={{ fontSize: 16 }}>
          保有ポイント:
        </Text>
        {isFetchingRestaurantPointInfo ? (
          <Skeleton.Input size="small" active />
        ) : (
          <Text style={{ fontSize: 20, fontWeight: 700, color: '#000000D9' }}>
            {helpers.numberWithCommas(
              restaurantPointInfo?.pointInfo?.discountPoint || 0
            )}
            pt
          </Text>
        )}
      </Space>
      <Table
        columns={columns}
        data={_get(discountPointHistory, 'list', [])}
        total={_get(discountPointHistory, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
      />
    </Card>
  );
};

export default DiscountPointHistory;
