import _get from 'lodash/get';
import _find from 'lodash/find';
import produce from 'immer';

import {
  GET_MANUFACTURER_LIST_SUCCESS,
  CHANGE_STATUS_MANUFACTURER_SUCCESS,
  GET_MANUFACTURER_DETAIL_REQUEST,
  GET_MANUFACTURER_DETAIL_SUCCESS,
} from './constants';

const initialState = {
  manufacturerList: [],
  manufacturerTotal: 0,
  manufacturerDetail: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_MANUFACTURER_LIST_SUCCESS:
      state.manufacturerList = action.payload.list;
      state.manufacturerTotal = action.payload.total;
      break;

    case GET_MANUFACTURER_DETAIL_REQUEST:
      state.manufacturerDetail = {};
      break;

    case GET_MANUFACTURER_DETAIL_SUCCESS:
      state.manufacturerDetail = action.payload;
      break;

    case CHANGE_STATUS_MANUFACTURER_SUCCESS: {
      const updatedManufacturer = _get(action, 'payload');
      state.manufacturerDetail = updatedManufacturer;

      _find(state.manufacturerList, (manufacturer) => {
        if (manufacturer.objectId === updatedManufacturer.objectId) {
          manufacturer.status = updatedManufacturer.status;
          return true;
        }
        return false;
      });

      break;
    }

    default:
  }
  return state;
});

export default reducer;
