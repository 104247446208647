import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _debounce from 'lodash/debounce';
import validator from 'validator';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Form } from '@ant-design/compatible';
import {
  Button, Space, List, Avatar, Select, Card, Typography
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';

import { getRestaurantByEmailRequest } from 'providers/RestaurantOwnerProvider/actions';

import EmailImage from 'images/icon-email.svg';

import './style.less';

const { Option } = Select;
const { Meta } = Card;

const InviteRestaurantForm = ({
  handleSubmit,
  onCancel,
  createActionLoading,
}) => {
  const dispatch = useDispatch();

  const [restaurantList, setRestaurantList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [inputValue, setInputValue] = useState(null);

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const restaurantOwnerId = _get(currentUser, 'restaurantOwner.objectId');

  const handleSearchEmailKeyword = _debounce(async (value) => {
    if (value !== '' && validator.isEmail(value)) {
      const result = await dispatch(
        getRestaurantByEmailRequest({ email: value })
      );
      if (!_isEmpty(result)) {
        setSearchResult([result]);
      } else {
        setSearchResult([
          {
            name: value,
            email: '登録されていないメールアドレスです',
            image: EmailImage,
          },
        ]);
      }
    } else {
      setSearchResult([]);
    }
  }, 500);

  const onSearchEmailKeyWork = (value) => {
    setInputValue(value);
    handleSearchEmailKeyword(value);
  };

  const handleSelect = (value, option) => {
    if (option?.data?.objectId) {
      setRestaurantList([...restaurantList, option?.data]);
    } else {
      setEmailList([...emailList, option?.data]);
    }
    setSearchResult([]);
    setInputValue(null);
  };

  const optionElements = _map(searchResult, (option) => {
    const optionSelected = option.objectId
      ? _find(restaurantList, { objectId: option.objectId })
      : _find(emailList, { name: option.name });

    const status = _get(option, 'restaurant_restaurantOwner.status');
    const objectId = _get(
      option,
      'restaurant_restaurantOwner.restaurantOwner.objectId'
    );

    const isDisabled = (objectId
        && objectId === restaurantOwnerId
        && ['PENDING', 'ACCEPTED'].includes(status))
      || (objectId && objectId !== restaurantOwnerId && status === 'ACCEPTED');

    return (
      <Option
        key={option.email}
        value={option?.objectId || option.email}
        disabled={isDisabled || !!optionSelected}
        data={option}
      >
        <Card
          bordered={false}
          className="bg-transparent"
          bodyStyle={{ padding: 16 }}
        >
          <div className="full-w flex justify-between items-center">
            <Meta
              style={{
                overflow: 'hidden',
                width: '100%',
              }}
              avatar={(
                <Avatar
                  src={option.image && option.image}
                  size={32}
                  style={{ padding: 6, background: '#ccc' }}
                />
              )}
              title={option.name}
              description={option.email}
            />
            {optionSelected && (
              <Space>
                <CheckOutlined style={{ color: '#399e0e' }} />
                <Typography.Text style={{ color: '#399e0e' }}>
                  登録済
                </Typography.Text>
              </Space>
            )}
            {objectId
              && objectId !== restaurantOwnerId
              && status === 'ACCEPTED' && (
              <Typography.Text style={{ color: '#EE780F' }}>
                別のオーナーに連携済み
              </Typography.Text>
            )}
            {objectId && objectId === restaurantOwnerId && (
              <Space>
                <Typography.Text style={{ color: '#399e0e' }}>
                  {status === 'PENDING' ? (
                    <Typography.Text style={{ color: '#ee780f' }}>
                      Sent invitation
                    </Typography.Text>
                  ) : (
                    <>
                      <CheckOutlined style={{ color: '#399e0e' }} />
                      <Typography.Text style={{ color: '#399e0e' }}>
                        連携済
                      </Typography.Text>
                    </>
                  )}
                </Typography.Text>
              </Space>
            )}
          </div>
        </Card>
      </Option>
    );
  });

  const renderItem = (item) => {
    const handleRemove = () => {
      if (item?.objectId) {
        setRestaurantList(
          restaurantList.filter((i) => i.objectId !== item.objectId)
        );
      } else {
        setEmailList(emailList.filter((i) => i.name !== item.name));
      }
    };
    return (
      <List.Item
        actions={[
          <Button
            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
            key={item.objectId}
            type="link"
            icon={<CloseOutlined />}
            onClick={handleRemove}
          />,
        ]}
      >
        <List.Item.Meta
          avatar={(
            <Avatar
              src={item.image}
              size={32}
              style={{ padding: 6, background: '#ccc' }}
            />
          )}
          title={(
            <Typography.Text
              ellipsis={{
                tooltip: item.name,
              }}
            >{item.name}
            </Typography.Text>
          )}
          description={item?.objectId ? item?.email : 'Invite via email'}
        />
      </List.Item>
    );
  };

  const renderList = () => (
    <>
      <Typography.Text>招待するレストラン</Typography.Text>
      <List
        itemLayout="horizontal"
        dataSource={[...restaurantList, ...emailList]}
        renderItem={renderItem}
      />
    </>
  );

  const onSubbmit = () => {
    if (!_isEmpty(restaurantList) || !_isEmpty(emailList)) {
      handleSubmit(restaurantList, emailList);
    }
  };

  const onClear = () => {
    setInputValue(null);
    setSearchResult([]);
  };

  return (
    <Form layout="vertical">
      <Form.Item label="メールアドレス">
        <Select
          value={inputValue}
          placeholder="email@mail.com"
          showSearch
          onSearch={onSearchEmailKeyWork}
          onSelect={handleSelect}
          notFoundContent={null}
          showArrow={false}
          allowClear
          filterOption={false}
          style={{ width: '100%' }}
          onClear={onClear}
        >
          {optionElements}
        </Select>
      </Form.Item>
      <div>{!_isEmpty([...restaurantList, ...emailList]) && renderList()}</div>
      <div id="invite-restaurant-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button
            className="form-button"
            onClick={onCancel}
            disabled={createActionLoading}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            onClick={onSubbmit}
            loading={createActionLoading}
          >
            招待を送信
          </Button>
        </Space>
      </div>
    </Form>
  );
};

InviteRestaurantForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  createActionLoading: PropTypes.any,
};

export default InviteRestaurantForm;
