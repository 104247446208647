import _get from 'lodash/get';
import queryString from 'query-string';
import { createSelector } from 'reselect';

const selectRouter = (state) => state.router || {};

const makeSelectLocationPathname = () => createSelector(selectRouter, (routerState) => routerState.location.pathname);

const makeSelectLocationSearch = () => createSelector(selectRouter, (routerState) => {
  const search = _get(routerState, 'location.search', '');
  return queryString.parse(search);
});

export { makeSelectLocationPathname, makeSelectLocationSearch };
