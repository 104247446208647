import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Card, Space, Typography, Avatar
} from 'antd';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import { getSupplierBrandListRequest } from 'providers/SupplierBrandProvider/actions';

import DefaultBrandImage from 'images/defaultBrand.svg';

const AssignedBrand = () => {
  const supplierBrandList = useSelector(
    (state) => state.supplierBrandProvider.supplierBrandList,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();

  const { page, limit } = pageControl;
  const { supplierId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getSupplierBrandListRequest({
            page,
            limit,
            supplierId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, supplierId]);

  const columns = [
    {
      title: 'ID',
      width: 130,
      dataIndex: 'objectId',
      key: 'objectId',
      fixed: 'left',
    },
    {
      title: 'ブランド名',
      dataIndex: 'brand',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <div className="flex items-center full-w">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultBrandImage)}
            size={32}
          />
          <Space direction="vertical" size={0} style={{ overflow: 'hidden', flex: 1, marginLeft: 4 }}>
            <Typography.Text
              ellipsis={{
                tooltip: _get(record, 'name'),
              }}
            >{_get(record, 'name')}
            </Typography.Text>
            <Typography.Text
              ellipsis={{
                tooltip: _get(record, 'email'),
              }}
            >{_get(record, 'email')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
    {
      title: '担当者',
      dataIndex: 'brand',
      width: 150,
      render: (record) => (record?.managerName || record?.managerNameKana ? (
        <Space direction="vertical" size={0}>
          <Typography.Text>{_get(record, 'managerName')}</Typography.Text>
          <Typography.Text>{_get(record, 'managerNameKana')}</Typography.Text>
        </Space>
      ) : (
        '-'
      )),
    },
    {
      title: '担当者緊急連絡先',
      dataIndex: 'brand',
      width: 150,
      render: (record) => (
        <Typography.Text>{_get(record, 'emergencyPhone', '-')}</Typography.Text>
      ),
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <Table
        columns={columns}
        data={_get(supplierBrandList, 'list', [])}
        total={_get(supplierBrandList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 660 }}
      />
    </Card>
  );
};

export default AssignedBrand;
