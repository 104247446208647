import _isInteger from 'lodash/isInteger';
import _isSafeInteger from 'lodash/isSafeInteger';
import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (
    !_isInteger(values.shippingFee)
    || values.shippingFee < 0
    || !_isSafeInteger(values.shippingFee)
  ) {
    errors.shippingFee = Constants.ERROR_MESSAGE.INVALID;
  }
  if (values.setPriceOfFreeShip) {
    if (
      !_isInteger(values.priceOfFreeShip)
      || values.priceOfFreeShip < 0
      || !_isSafeInteger(values.priceOfFreeShip)
    ) {
      errors.priceOfFreeShip = Constants.ERROR_MESSAGE.INVALID;
    }
  }

  return errors;
};

export default validate;
