import _get from 'lodash/get';
import React from 'react';
import { Space, Typography, Tooltip } from 'antd';

import DefaultSupplierImage from 'images/defaultSupplier.svg';

const Columns = {};

Columns.ID = {
  title: '製造者コード',
  dataIndex: 'manufacturerCode',
  width: 130,
  fixed: 'left',
};

Columns.name = {
  title: '製造会社',
  dataIndex: 'name',
  width: 220,
};

Columns.supplierName = {
  title: '販売者 ',
  width: 220,
  dataIndex: 'supplier',
  render: (record) => (
    <div className="flex items-center full-w">
      <img
        style={{ borderRadius: 4, marginRight: 8 }}
        alt="name"
        src={_get(record, 'image', DefaultSupplierImage)}
        width={32}
        height={32}
      />
      <Space
        direction="vertical"
        size={0}
        className="full-w"
        style={{ overflow: 'hidden', flex: 1 }}
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
        </Tooltip>
        <Tooltip placement="topLeft" title={_get(record, 'email')}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12 }}
            ellipsis
            copyable={
              !!_get(record, 'email') && {
                tooltips: ['コピー', 'コピーしました'],
              }
            }
          >
            {_get(record, 'email', '-')}
          </Typography.Text>
        </Tooltip>
      </Space>
    </div>
  ),
};

Columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  width: 150,
};

export default Columns;
