import produce from 'immer';

import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SET_RESTAURANT_REQUEST_COUNT,
  CLEAR_CURRENT_USER_DATA,
} from './constants';

const initialState = {
  currentUserLoading: true,
  loading: false,
  error: false,
  currentUser: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_REQUEST:
      state.currentUser = {};
      state.currentUserLoading = true;
      state.error = false;
      break;

    case GET_CURRENT_USER_SUCCESS:
      state.currentUser = action.payload;
      state.currentUserLoading = false;
      break;

    case GET_CURRENT_USER_ERROR:
      state.error = action.payload;
      state.currentUserLoading = false;
      break;

    case LOGOUT_REQUEST:
      state.loading = true;
      state.error = false;
      break;

    case LOGOUT_SUCCESS:
      state.loading = false;
      state.error = false;
      state.currentUser = {};
      break;

    case LOGOUT_ERROR:
      state.loading = false;
      state.error = action.error;
      break;

    case SET_RESTAURANT_REQUEST_COUNT:
      state.currentUser = {
        ...state.currentUser,
        supplier: {
          ...state.currentUser.supplier,
          supplierRestaurantRequestCount: action.payload,
        },
      };
      break;

    case CLEAR_CURRENT_USER_DATA:
      state.currentUser = {};
      break;

    default:
  }
  return state;
});

export default reducer;
