import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { TextField } from 'components/Form';

const BrandOwnerForm = ({ handleSubmit }) => (
  <Form
    colon={false}
    onSubmit={handleSubmit}
    layout="horizontal"
    labelCol={{ span: 2 }}
  >
    <Field
      label="メモ"
      description="任意"
      name="memo"
      maxLength={500}
      component={TextField}
      textArea
    />
  </Form>
);

BrandOwnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'brandOwnerForm',
  touchOnChange: true,
  enableReinitialize: true,
})(BrandOwnerForm);
