import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card, Button, Typography, Space, Badge, Modal
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Table from 'components/Table';

import toastMessage from 'utils/toastMessage';

import {
  acceptAllRestaurantRequest,
  acceptRestaurantRequest,
  declineRestaurantRequest,
  declineAllRestaurantRequest,
} from 'providers/RestaurantProvider/actions';
import { getPendingListRequest } from 'providers/RelationBetweenRestaurantAndSupplierProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import Columns from './Columns';

import './style.scss';

const { confirm } = Modal;

const showConfirm = (message, actionFn, okText) => {
  confirm({
    title: message,
    okText,
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: actionFn,
  });
};
const InvitationList = () => {
  const [actionLoading, setActionLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const dispatch = useDispatch();

  const invitationList = useSelector(
    (state) => state.relationBetweenRestaurantAndSupplierProvider.pendingList,
    shallowEqual
  );

  const [pageControl, setPageControl] = useState({
    page: '1',
    limit: '10',
  });
  const { page, limit } = pageControl;

  const onTableChange = ({ pagination }) => {
    setPageControl({
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    });
  };

  const getRestaurantList = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(getPendingListRequest({ page, limit }));
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [pageControl]);

  useEffect(() => {
    getRestaurantList();
  }, [getRestaurantList]);

  const acceptAllRestaurant = async () => {
    setActionLoading(true);
    try {
      await dispatch(acceptAllRestaurantRequest(invitationList.total));
      dispatch(doRefresh({ target: 'restaurantList' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const declineAllRestaurant = async () => {
    setActionLoading(true);
    try {
      await dispatch(declineAllRestaurantRequest(invitationList.total));
      getRestaurantList();
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const acceptRestaurant = async (record) => {
    setActionLoading(true);
    try {
      await dispatch(
        acceptRestaurantRequest({
          objectId: _get(record, 'supplierRestaurant.objectId'),
        })
      );
      toastMessage.success({
        description: `レストラン${record?.name || ''}が招待を承認しました。`,
      });
      dispatch(doRefresh({ target: 'restaurantList' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const declineRestaurant = async (record) => {
    setActionLoading(true);
    try {
      await dispatch(
        declineRestaurantRequest({
          objectId: _get(record, 'supplierRestaurant.objectId'),
        })
      );
      toastMessage.success({
        description: `レストラン${
          record?.name || ''
        }からの招待を否認しました。`,
      });
      dispatch(doRefresh({ target: 'restaurantList' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const columns = [
    Columns.restaurantInfo,
    Columns.invitedDate,
    Columns.invitedDate,
    Columns.numberOfProduct,
    {
      width: 220,
      fixed: 'right',
      align: 'center',
      title: '操作',
      render: (record) => (
        <div className="filter-container flex items-center justify-between flex-wrap">
          <Space direction="horizontal" size={8}>
            <Button
              type="link"
              icon={<CheckOutlined />}
              disabled={actionLoading}
              onClick={() => acceptRestaurant(record)}
            >
              承認
            </Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              danger
              disabled={actionLoading}
              onClick={() => declineRestaurant(record)}
            >
              否認
            </Button>
          </Space>
        </div>
      ),
    },
  ];

  if (_isEmpty(invitationList) || invitationList.total === 0) {
    return null;
  }

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <div className="flex justify-between flex-wrap padding-16">
        <Space size={4} className="flex items-center">
          <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
            取引申請
          </Typography.Text>
          <Badge className="badge" count={invitationList.total} />
        </Space>
        <Space size={4}>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            disabled={actionLoading}
            onClick={() => showConfirm(
              `${invitationList.total}件の全ての招待を承認してよろしいですか?`,
              acceptAllRestaurant,
              '全承認'
            )}
          >
            全承認
          </Button>
          <Button
            type="ghost"
            danger
            icon={<CloseOutlined />}
            disabled={actionLoading}
            onClick={() => showConfirm(
              `${invitationList.total}件の全ての招待を否認してよろしいですか？`,
              declineAllRestaurant,
              '全否認'
            )}
          >
            全否認
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        data={invitationList.list}
        total={invitationList.total}
        onChange={onTableChange}
        pagination={
          invitationList.total > limit && {
            current: Number(page),
            pageSize: Number(limit),
            hideOnSinglePage: true,
          }
        }
        loading={tableLoading}
        scroll={{ x: 620 }}
      />
    </Card>
  );
};

export default InvitationList;
