/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';

import { STATUS } from 'utils/constants';
import { changeMenuItemStatusRequest } from 'providers/MenuProvider/actions';

import './style.less';

const { confirm } = Modal;

const showConfirm = (message, content, actionFn) => {
  confirm({
    title: message,
    content,
    okText: '配信',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const StatusToggle = ({ record, rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const changeStatusMenuItem = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(changeMenuItemStatusRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <div
      role="button"
      className="status-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <span
          className={`${record === STATUS.ACTIVE ? 'dot' : 'dot dot--inactive'}`}
        />
        <span>{record === STATUS.ACTIVE ? '有効' : '無効'}</span>
      </div>
      <Switch
        disabled={
          changeStatusLoading
          || rowRecord?.manufacturerStatus === STATUS.INACTIVE
        }
        size="small"
        checked={record === STATUS.ACTIVE}
        onClick={() => {
          const menuItemId = rowRecord.objectId;
          const { isBasic, name } = rowRecord;
          const nextStatus = record === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE;
          if (nextStatus === STATUS.INACTIVE) {
            showConfirm(
              isBasic
                ? `ベーシックメニュー${name}を無効化しますか？`
                : `ショップメニュー${name}を無効化しますか？`,
              '無効化すると、レストランのアプリにはこのメニューが表示されなくなります。注文済みの商品には影響しません。',
              () => changeStatusMenuItem({
                menuItemId,
                status: nextStatus,
              })
            );
          } else {
            changeStatusMenuItem({ menuItemId, status: nextStatus });
          }
        }}
      />
    </div>
  );
};

StatusToggle.propTypes = {
  rowRecord: PropTypes.object,
  record: PropTypes.string,
};

export default StatusToggle;
