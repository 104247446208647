import React from 'react';
import { Space, Typography } from 'antd';

const FooterBar = () => (
  <div className="flex justify-center items-center footer-bar">
    <Space
      size={[40, 8]}
      wrap
      style={{
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.65)',
      }}
    >
      <Typography.Text>
        ©2021 Shikomel inc. All rights reserved.
      </Typography.Text>
      <a
        className="info-link"
        href={process.env.REACT_APP_LANDING_PAGE_HOME_URL}
        target="_blank"
        rel="noreferrer"
      >
        シコメルについて
      </a>
      <a
        className="info-link"
        href={process.env.REACT_APP_LANDING_PAGE_CONTACT_US_URL}
        target="_blank"
        rel="noreferrer"
      >
        お問い合わせ
      </a>
    </Space>
  </div>
);

export default FooterBar;
