import produce from 'immer';

import {
  GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST,
  GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS,
  GET_SUPPLIER_DETAIL_SUCCESS,
  GET_SUPPLIER_DETAIL_ERROR,
  GET_SUPPLIER_HOLIDAY_LIST_SUCCESS,
  GET_SUPPLIER_HOLIDAY_LIST_ERROR,
  GET_INVITATION_EMAIL_SUCCESS,
  GET_INVITATION_EMAIL_REQUEST,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS,
} from './constants';

const initialState = {
  supplierList: {},
  supplierDetail: {},
  supplierHolidayList: {
    list: [],
    total: 0,
  },
  invitationEmail: {},
  supplierListForRestaurant: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST:
      state.supplierList = {};
      break;

    case GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS:
      state.supplierList = action.payload;
      break;

    case GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST:
      state.supplierListForRestaurant = {};
      break;

    case GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS:
      state.supplierListForRestaurant = action.payload;
      break;

    case GET_SUPPLIER_DETAIL_SUCCESS:
      state.supplierDetail = action.payload;
      break;

    case GET_SUPPLIER_DETAIL_ERROR:
      state.supplierDetail = {};
      break;

    case GET_SUPPLIER_HOLIDAY_LIST_SUCCESS:
      state.supplierHolidayList = action.payload;
      break;

    case GET_SUPPLIER_HOLIDAY_LIST_ERROR:
      state.supplierHolidayList = {};
      break;

    case GET_INVITATION_EMAIL_REQUEST:
      state.invitationEmail = {};
      break;

    case GET_INVITATION_EMAIL_SUCCESS:
      state.invitationEmail = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
