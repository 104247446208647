import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  RightOutlined,
  CheckCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Spin,
  Button,
  Input,
  Typography,
  Avatar,
  Checkbox,
  Space,
  Col,
  Row,
  Tooltip,
} from 'antd';

import { STATUS, MENU_LIST_FILTER, MENU_PRICE_TYPE } from 'utils/constants';

import DefaultProductImage from 'images/defaultProduct.svg';

import './style.scss';

const { confirm } = Modal;
const { Text, Paragraph } = Typography;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const UpdateDialog = ({
  getCategoryListRequest,
  handleCloseDialog,
  getMenuItemListRequest,
  menuItemList,
  menuLoading,
  categoryList,
  categoryLoading,
  resetMenuItemList,
  assignedProductList,
  handleSubmit,
  title,
  submitLoading,
  submitConfirm,
}) => {
  const [selections, setSelections] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [menuKeyword, setMenuKeyword] = useState('');

  useEffect(() => {
    if (!_isEmpty(assignedProductList)) {
      setSelections(assignedProductList);
    }
  }, [assignedProductList]);

  const handleSelectCategory = (e, id, subList) => {
    if (e) {
      e.preventDefault();
    }
    setSubcategoryList(subList);
    setSelectedCategory(id);
    setSelectedSubcategory(subList[0].objectId);
  };

  useEffect(() => {
    if (!selectedSubcategory) {
      return;
    }
    getMenuItemListRequest({
      page: 1,
      limit: 1000,
      keyword: menuKeyword,
      subCategoryId: selectedSubcategory,
      menuType: MENU_LIST_FILTER.NOT_BASIC,
      opts: { getMenuCountPerType: false, getWarehouseMenuData: false },
    });
  }, [selectedSubcategory]);

  useEffect(() => {
    if (categoryList.length === 0 || selectedCategory) {
      return;
    }
    const { objectId, childs } = categoryList[0];
    handleSelectCategory(null, objectId, childs);
  }, [categoryList]);

  const handleSelectMenuItem = (e, menuItem) => {
    e.preventDefault();
    if (!selections.find((item) => item.objectId === menuItem.objectId)) {
      const masterMenuPrice = (menuItem.prices || []).find(
        (price) => price.type === MENU_PRICE_TYPE.MASTER
      );
      const masterMenuPriceId = _get(masterMenuPrice, 'objectId');
      setSelections([
        ...selections,
        { ...menuItem, menuPriceId: masterMenuPriceId },
      ]);
    } else {
      setSelections(
        selections.filter((item) => item.objectId !== menuItem.objectId)
      );
    }
  };

  const handleUnselectItem = (e, id) => {
    e.preventDefault();
    setSelections(selections.filter((menuItem) => menuItem.objectId !== id));
  };

  const getCategoryList = (searchMenuKeyword) => {
    setSelectedCategory('');
    setSelectedSubcategory('');
    setSubcategoryList([]);
    resetMenuItemList();

    getCategoryListRequest({ menuKeyword: searchMenuKeyword });
  };

  const getCategoryListWithDelay = useCallback(
    _debounce(getCategoryList, 700),
    []
  );

  useEffect(() => {
    if (!menuKeyword) {
      getCategoryList(menuKeyword);
    } else {
      getCategoryListWithDelay(menuKeyword);
    }
  }, [menuKeyword]);

  const selectedIds = selections.map((item) => item.objectId);
  const activeMenuItemList = menuItemList.filter(
    (item) => item.status === STATUS.ACTIVE
  );

  const renderSelection = () => selections.map((menuItem) => (
    <div key={menuItem.objectId} className="selected-menu-bg">
      <div className="selected-menu-item">
        <div
          className="flex items-center menu-detail-container"
          style={{ flex: 1 }}
        >
          <Avatar
            size={32}
            shape="square"
            src={_get(menuItem, 'images[0].url', DefaultProductImage)}
          />
          <div className="menu-detail">
            <Tooltip title={menuItem.name}>
              <Paragraph ellipsis={{ rows: 1 }} className="menu-name">
                {menuItem.name}
              </Paragraph>
            </Tooltip>
            {menuItem.note && (
              <div className="menu-note">{menuItem.note}</div>
            )}
          </div>
        </div>
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={(event) => handleUnselectItem(event, menuItem.objectId)}
        />
      </div>
    </div>
  ));

  return (
    <Modal
      title={title}
      visible
      width={1200}
      closable={!submitLoading}
      onCancel={() => showConfirm('中断してよろしいですか？', handleCloseDialog)}
      footer={(
        <div>
          <Button
            className="form-button"
            disabled={submitLoading}
            onClick={() => showConfirm('中断してよろしいですか？', handleCloseDialog)}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            loading={submitLoading}
            onClick={async () => {
              if (submitConfirm) {
                confirm({
                  ...submitConfirm,
                  onOk: async () => {
                    await handleSubmit(selections);
                    handleCloseDialog();
                  },
                });
              } else {
                await handleSubmit(selections);
                handleCloseDialog();
              }
            }}
          >
            保存する
          </Button>
        </div>
      )}
      destroyOnClose
      centered
      maskClosable={false}
      className="collection-update-modal"
    >
      <Row>
        <Col
          className="menu-selector-container flex direction-column"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 16 }}
        >
          <div className="filter-container">
            <Input.Search
              value={menuKeyword}
              onChange={(e) => {
                setMenuKeyword(e.target.value);
              }}
              placeholder="商品名で検索"
            />
          </div>
          <div className="collection-menu-container">
            <Text strong> カテゴリー </Text>
            <Text strong> サブカテゴリー </Text>
            <Text strong> 商品 </Text>
            <div className="category-menu-list">
              {categoryList.length > 0
                && categoryList.map((category) => (
                  <button
                    key={category.objectId}
                    className={`category-menu-item${
                      selectedCategory === category.objectId ? '--selected' : ''
                    }`}
                    onClick={(event) => handleSelectCategory(
                      event,
                      category.objectId,
                      category.childs
                    )}
                    type="button"
                    disabled={menuLoading}
                  >
                    <div> {category.name} </div>
                    <RightOutlined />
                  </button>
                ))}
              {categoryLoading && <Spin className="loading-icon" />}
            </div>
            <div className="category-menu-list">
              {subcategoryList.length > 0
                && subcategoryList.map((subcategory) => (
                  <button
                    key={subcategory.objectId}
                    className={`category-menu-item${
                      selectedSubcategory === subcategory.objectId
                        ? '--selected'
                        : ''
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedSubcategory(subcategory.objectId);
                    }}
                    type="button"
                    disabled={menuLoading}
                  >
                    <div> {subcategory.name} </div>
                    <RightOutlined />
                  </button>
                ))}
              {categoryLoading && <Spin className="loading-icon" />}
            </div>
            <div className="category-menu-list">
              {activeMenuItemList.length > 0
                && activeMenuItemList.map((menuItem) => (
                  <button
                    key={menuItem.objectId}
                    className={`category-menu-item${
                      selectedIds.includes(menuItem.objectId)
                        ? '--selected'
                        : ''
                    }`}
                    onClick={(event) => handleSelectMenuItem(event, menuItem)}
                    type="button"
                  >
                    <div className="menu-item-detail">
                      <Checkbox
                        checked={selectedIds.includes(menuItem.objectId)}
                      >
                        <Space>
                          <Avatar
                            size={32}
                            shape="square"
                            src={_get(
                              menuItem,
                              'images[0].url',
                              DefaultProductImage
                            )}
                          />
                          <div>
                            <Tooltip title={menuItem.name}>
                              <Paragraph ellipsis={{ rows: 2 }}>
                                {menuItem.name}
                              </Paragraph>
                            </Tooltip>
                            {menuItem.note && (
                              <div className="menu-item-note">
                                {' '}
                                {menuItem.note}{' '}
                              </div>
                            )}
                          </div>
                        </Space>
                      </Checkbox>
                    </div>
                  </button>
                ))}
              {menuLoading && <Spin className="loading-icon" />}
            </div>
          </div>
        </Col>
        <Col
          className="selected-menu-container"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <div className="flex items-center" style={{ marginBottom: 8 }}>
            <CheckCircleFilled className="checked-icon" />
            <Text strong>選択中の商品 ({selections.length})</Text>
          </div>
          <div className="selected-menu-list">{renderSelection()}</div>
        </Col>
      </Row>
    </Modal>
  );
};

UpdateDialog.propTypes = {
  menuItemList: PropTypes.array,
  menuLoading: PropTypes.bool,
  getMenuItemListRequest: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  resetMenuItemList: PropTypes.func,
  getCategoryListRequest: PropTypes.func,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  categoryLoading: PropTypes.bool,
  assignedProductList: PropTypes.array,
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
  submitLoading: PropTypes.any,
  submitConfirm: PropTypes.any,
};

export default UpdateDialog;
