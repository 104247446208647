import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import {
  Row, Col, Button, Typography
} from 'antd';
import _get from 'lodash/get';
import { TextField, InputNumber } from 'components/Form';
import { reduxForm, Field, FieldArray } from 'redux-form';
import ReturnProductionIcon from 'images/icon-return-product.svg';
import DeliveryIcon from 'images/icon-delivery.svg';

import { Form } from '@ant-design/compatible';
import TrashIcon from 'images/icon-trash.svg';
import helpers from 'utils/helpers';
import OrderUpdateValidate from './validate';

const renderUpdateItems = ({ fields }) => {
  const fieldTypes = fields.map((_, index) => _get(fields.get(index), 'type'));
  const allowAddNewShippingFee = !fieldTypes.find(
    (type) => type === 'NEW_SHIPPING_FEE'
  );

  return (
    <div>
      {fields.length > 0
        && fields.map((updateItem, index) => {
          const itemValue = fields.get(index);
          const isNewShippingFeeItem = itemValue.type === 'NEW_SHIPPING_FEE';

          return (
            <div key={index} style={{ padding: '0px 16px' }}>
              <div className="flex items-center" style={{ marginBottom: 5 }}>
                <img
                  src={
                    isNewShippingFeeItem ? DeliveryIcon : ReturnProductionIcon
                  }
                  alt="icon"
                  style={{ marginRight: 4 }}
                />
                <span className="text-title" style={{ marginRight: 8 }}>
                  {isNewShippingFeeItem ? '送料変更' : '返品・訂正'}{' '}
                </span>
                <Typography.Text type="secondary" className="text-secondary">
                  ※
                  {isNewShippingFeeItem
                    ? '変更後の送料を税込で入力してください。'
                    : '変更後の送料を税込で入力してください。'}
                </Typography.Text>
              </div>
              <Row gutter={16}>
                <Col flex={1}>
                  <Field
                    name={`${updateItem}.name`}
                    component={TextField}
                    textArea
                    placeholder="送料を変更いたしました。"
                  />
                </Col>
                <Col>
                  <Field
                    name={`${updateItem}.value`}
                    component={InputNumber}
                    addonBefore={(
                      <div
                        className={
                          !isNewShippingFeeItem ? 'negative-menu-color' : ''
                        }
                      >
                        ￥
                      </div>
                    )}
                    className={
                      !isNewShippingFeeItem ? 'negative-menu-color' : ''
                    }
                    precision={0}
                    min={isNewShippingFeeItem ? 0 : Number.MIN_SAFE_INTEGER}
                    max={9999999999}
                    formatter={(value) => helpers.numberWithCommas(value)}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="金額"
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => fields.remove(index)}
                    type="text"
                    icon={<img src={TrashIcon} alt="icon" />}
                  />
                </Col>
              </Row>
            </div>
          );
        })}

      <div className="flex">
        <Button
          className="flex items-center"
          icon={<PlusCircleFilled style={{ fontSize: 16 }} />}
          type="text"
          style={{ color: '#1890ff' }}
          onClick={() => {
            fields.push({
              type: 'RETURNED_MENU',
              value: 1,
              name: '',
            });
          }}
        >
          返品・訂正
        </Button>
        {allowAddNewShippingFee && (
          <Button
            className="flex items-center"
            icon={<PlusCircleFilled style={{ fontSize: 16 }} />}
            type="text"
            style={{ color: '#1890ff' }}
            onClick={() => {
              fields.push({
                type: 'NEW_SHIPPING_FEE',
                value: 0,
                name: '',
              });
            }}
          >
            送料訂正
          </Button>
        )}
      </div>
    </div>
  );
};

const OrderUpdateForm = () => (
  <Form onSubmit={() => {}} layout="horizontal" colon={false}>
    <FieldArray name="updateItems" component={renderUpdateItems} disabled />
  </Form>
);

export default reduxForm({
  form: 'orderItemUpdate',
  enableReinitialize: true,
  validate: OrderUpdateValidate,
  touchOnChange: true,
})(OrderUpdateForm);
