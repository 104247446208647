import { reduxForm } from 'redux-form';
import validate from './validate';
import ProfileForm from './ProfileForm';

export default reduxForm({
  form: 'supplierRegisterForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate,
})(ProfileForm);
