import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Card, Space, Typography } from 'antd';

import { pagingGetMenuItemListRequest } from 'providers/MenuProvider/actions';

import Table from 'components/Table';
import Helpers from 'utils/helpers';
import DefaultProductImage from 'images/defaultProduct.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import useTableControl from 'components/Hooks/useTableControl';

const ProductInBrand = ({ match }) => {
  const [tableLoading, setTableLoading] = useState(false);

  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );

  const brandId = _get(match, 'params.brandId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();

  const {
    page, limit, orderBy, order
  } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          pagingGetMenuItemListRequest({
            page,
            limit,
            orderBy,
            order,
            brandId,
            opts: { getMenuCountPerType: false, getWarehouseMenuData: false },
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, brandId]);

  const columns = [
    {
      title: '商品名',
      render: (record) => (
        <Space className="flex items-center">
          <img
            style={{ borderRadius: 4 }}
            alt="name"
            src={_get(record.images[0], 'url', DefaultProductImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'standard', '-')} • {_get(record, 'amount', '-')} ／{' '}
              {_get(record, 'unit', '-')}
            </Typography.Text>
          </Space>
        </Space>
      ),
      fixed: 'left',
    },
    {
      title: '販売者名',
      dataIndex: 'supplier',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Space className="flex items-center">
          <img
            style={{ borderRadius: 25 }}
            alt="name"
            src={_get(record, 'image', DefaultSupplierImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: 'ブランドフィー',
      dataIndex: 'brandFee',
      key: 'brandFee',
      align: 'right',
      render: (record) => record && `¥${Helpers.numberWithCommas(record)}`,
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card
        className="highlight-card padding-card"
        bordered={false}
      >
        <Table
          columns={columns}
          data={_get(productList, 'list', [])}
          total={_get(productList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            marginRight: true,
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 'max-content' }}
        />
      </Card>
    </Card>
  );
};

ProductInBrand.propTypes = {
  match: PropTypes.any,
};
export default ProductInBrand;
