export const GET_GENRE_LIST_REQUEST = 'GenreProvider/GET_GENRE_LIST_REQUEST';
export const GET_GENRE_LIST_SUCCESS = 'GenreProvider/GET_GENRE_LIST_SUCCESS';
export const GET_GENRE_LIST_ERROR = 'GenreProvider/GET_GENRE_LIST_ERROR';

export const ADD_TAG_REQUEST = 'GenreProvider/ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'GenreProvider/ADD_TAG_SUCCESS';
export const ADD_TAG_ERROR = 'GenreProvider/ADD_TAG_ERROR';

export const UPDATE_TAG_REQUEST = 'GenreProvider/UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'GenreProvider/UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_ERROR = 'GenreProvider/UPDATE_TAG_ERROR';

export const GET_TAG_DETAILS_REQUEST = 'GenreProvider/GET_TAG_DETAILS_REQUEST';
export const GET_TAG_DETAILS_SUCCESS = 'GenreProvider/GET_TAG_DETAILS_SUCCESS';
export const GET_TAG_DETAILS_ERROR = 'GenreProvider/GET_TAG_DETAILS_ERROR';

export const GET_TAG_LIST_REQUEST = 'GenreProvider/GET_TAG_LIST_REQUEST';
export const GET_TAG_LIST_SUCCESS = 'GenreProvider/GET_TAG_LIST_SUCCESS';
export const GET_TAG_LIST_ERROR = 'GenreProvider/GET_TAG_LIST_ERROR';

export const CHANGE_TAG_STATUS_REQUEST = 'GenreProvider/CHANGE_TAG_STATUS_REQUEST';
export const CHANGE_TAG_STATUS_SUCCESS = 'GenreProvider/CHANGE_TAG_STATUS_SUCCESS';
export const CHANGE_TAG_STATUS_ERROR = 'GenreProvider/CHANGE_TAG_STATUS_ERROR';
