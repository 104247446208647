import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Button, Space, Typography
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import withAuthorize from 'components/HOCs/withAuthorize';
import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import {
  getManufacturerListRequest,
  exportManufacturerRequest,
  changeManufacturerStatusRequest,
} from 'providers/ManufacturerProvider/actions';

import { USER_ROLE } from 'utils/constants';
import withRefresh from 'components/HOCs/withRefresh';
import StatusSwitch from '../StatusSwitch';
import Columns from './Columns';

const ManufacturerList = () => {
  const dispatch = useDispatch();

  const manufacturerList = useSelector(
    (state) => state.manufacturerProvider.manufacturerList,
    shallowEqual
  );
  const manufacturerTotal = useSelector(
    (state) => state.manufacturerProvider.manufacturerTotal,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const [tableLoading, setTableLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(getManufacturerListRequest({ page, limit }));
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit]);

  const handleChangeStatus = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(changeManufacturerStatusRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  const handleExport = async () => {
    setExportLoading(true);
    try {
      await dispatch(exportManufacturerRequest());
    } catch (error) {
      console.error(error);
    }
    setExportLoading(false);
  };

  const columns = {
    [USER_ROLE.OPERATOR]: [
      Columns.ID,
      Columns.name,
      Columns.supplierName,
      {
        ...Columns.status,
        render: (record) => (
          <Space className="status-container">
            <div className={record === 'ACTIVE' && 'active'}>
              <Typography.Text>
                {record === 'ACTIVE' ? '有効' : '無効'}
              </Typography.Text>
            </div>
          </Space>
        ),
      },
    ],
    [USER_ROLE.SUPPLIER]: [
      Columns.ID,
      Columns.name,
      {
        ...Columns.status,
        render: (record, rowRecord) => (
          <StatusSwitch
            status={record}
            manufacturer={rowRecord}
            isManufacturerLoading={changeStatusLoading}
            changeManufacturerStatus={handleChangeStatus}
          />
        ),
      },
    ],
  };

  const ManufacturersCreateButton = withAuthorize([USER_ROLE.SUPPLIER])(() => (
    <Button
      type="primary"
      onClick={() => dispatch(push('/manufacturers/create'))}
    >
      製造会社を追加
    </Button>
  ));

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Space
          direction="horizontal"
          size={[8, 8]}
          className="flex justify-end padding-16 border-bottom"
          wrap
        >
          <Button
            loading={exportLoading}
            type="ghost"
            icon={<DownloadOutlined />}
            onClick={handleExport}
          >
            出力
          </Button>
          <ManufacturersCreateButton />
        </Space>
        <Table
          onRowClick={(record) => {
            dispatch(
              push(`manufacturers/detail/${record.manufacturerCode}/product-list`)
            );
          }}
          columns={columns[currentUser?.role]}
          data={manufacturerList}
          total={manufacturerTotal}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 706 }}
        />
      </Card>
    </Card>
  );
};

export default withRefresh('manufacturerList')(ManufacturerList);
