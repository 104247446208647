import React from 'react';
import { Space, Tag } from 'antd';
import moment from 'moment';
import { APPLY_TO } from 'utils/constants';

const Columns = {};

Columns.date = {
  title: '日時',
  dataIndex: 'date',
  render: (date) => moment(date).format('YYYY年MM月DD日'),
  width: 150,
};

Columns.status = {
  title: 'ステータス',
  key: 'status',
  dataIndex: 'date',
  align: 'center',
  width: 150,
  render: (date) => (
    <Tag
      color={moment(date) > moment() ? 'green' : 'default'}
      style={{ fontWeight: 500, fontSize: 14 }}
    >
      <Space>
        <div
          style={{
            borderRadius: '50%',
            width: 14,
            height: 14,
            backgroundColor:
              moment(date) > moment() ? 'green' : 'rgba(0, 0, 0, 0.45)',
          }}
        />
        {moment(date) > moment() ? '設定済' : '過去日'}
      </Space>
    </Tag>
  ),
};
Columns.applyTo = {
  title: '対象',
  key: 'applyTo',
  dataIndex: 'applyTo',
  align: 'left',
  width: 200,
  render: (applyTo) => {
    let applyToList = applyTo ? [...applyTo] : [];
    if (applyTo && applyTo[0] === APPLY_TO.MANUFACTURER.value) {
      applyToList = applyToList.reverse();
    }
    return (
      <div className={'flex'}>
        {
          applyToList?.map((item) => {
            const isWarehouseHoliday = APPLY_TO.WAREHOUSE.value === item;
            return (
              <div
                key={item}
                style={{
                  marginLeft: !isWarehouseHoliday && applyTo?.length === 1 ? 52 : 8,
                  padding: 4,
                  borderRadius: 4
                }}
                className={isWarehouseHoliday ? 'warehouse-bg' : 'factory-bg'}
              >{APPLY_TO[item].label}
              </div>
            );
          })
        }
      </div>
    );
  },
};

Columns.action = {
  title: () => <div style={{ paddingRight: 24 }}>操作</div>,
  key: 'action',
  align: 'right',
  width: 100,
  fixed: 'right',
};

export default Columns;
