import {
  GET_PREFECTURE_LIST_REQUEST,
  GET_PREFECTURE_LIST_SUCCESS,
  GET_PREFECTURE_LIST_ERROR,
  GET_PARSE_CONFIG_REQUEST,
  GET_PARSE_CONFIG_SUCCESS,
  GET_PARSE_CONFIG_ERROR,
  GET_ALLERGY_LIST_REQUEST,
  GET_ALLERGY_LIST_SUCCESS,
  GET_ALLERGY_LIST_ERROR,
} from './constants';

export function getPrefectureListRequest() {
  return { type: GET_PREFECTURE_LIST_REQUEST };
}

export function getPrefectureListSuccess(data) {
  return { type: GET_PREFECTURE_LIST_SUCCESS, data };
}

export function getPrefectureListError(error) {
  return { type: GET_PREFECTURE_LIST_ERROR, error };
}

export function getParseConfigRequest() {
  return { type: GET_PARSE_CONFIG_REQUEST };
}

export function getParseConfigSuccess(data) {
  return { type: GET_PARSE_CONFIG_SUCCESS, data };
}

export function getParseConfigError(error) {
  return { type: GET_PARSE_CONFIG_ERROR, error };
}

export function getAllergyListRequest() {
  return { type: GET_ALLERGY_LIST_REQUEST, meta: { thunk: true } };
}

export function getAllergyListSuccess(payload, meta) {
  return { type: GET_ALLERGY_LIST_SUCCESS, payload, meta };
}

export function getAllergyListError(payload, meta) {
  return {
    type: GET_ALLERGY_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
