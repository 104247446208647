import React from 'react';
import { notification } from 'antd';

import IconVerified from 'images/icon-verified.svg';
import IconInfo from 'images/icon-info.svg';
import IconClear from 'images/icon-clear.svg';
import IconWarning from 'images/icon-warning.svg';
import IconClose from 'images/icon-close.svg';

export default {
  success: ({ message, description, onClick }) => {
    notification.open({
      message,
      description,
      icon: (
        <img alt="icon-verified" src={IconVerified} width={24} height={24} />
      ),
      closeIcon: (
        <img alt="icon-close" src={IconClose} width={16} height={16} />
      ),
      style: {
        backgroundColor: '#f6ffec',
      },
      onClick,
    });
  },

  info: ({
    message, description, onClick, key
  }) => {
    notification.open({
      key,
      message,
      description,
      icon: <img alt="icon-info" src={IconInfo} width={24} height={24} />,
      closeIcon: (
        <img alt="icon-close" src={IconClose} width={16} height={16} />
      ),
      style: {
        backgroundColor: '#ffffff',
      },
      onClick,
    });
  },

  error: ({ message, description, onClick }) => {
    if (!message && !description) {
      message = 'エラー';
      description = 'Something went wrong';
    }
    notification.open({
      message,
      description,
      icon: <img alt="icon-clear" src={IconClear} width={24} height={24} />,
      closeIcon: (
        <img alt="icon-close" src={IconClose} width={16} height={16} />
      ),
      style: {
        backgroundColor: '#fff1f0',
      },
      onClick,
    });
  },

  warning: ({ message, description, onClick }) => {
    notification.open({
      message,
      description,
      icon: <img alt="icon-warning" src={IconWarning} width={24} height={24} />,
      closeIcon: (
        <img alt="icon-close" src={IconClose} width={16} height={16} />
      ),
      style: {
        backgroundColor: '#fffbe6',
      },
      onClick,
    });
  },
};
