import {
  Button, Avatar, Typography, Tabs, Spin
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { replace, push } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';

import Helpers from 'utils/helpers';
import withRefresh from 'components/HOCs/withRefresh';
import { getMenuItemDetailRequest } from 'providers/MenuProvider/actions';
import { getRestaurantMenuItemRequest } from 'providers/RestaurantProvider/actions';
import PageHeader from 'components/PageHeader';
import DefaultProductImage from 'images/defaultProduct.svg';
import routes from './routes';
import StatusToggle from '../List/StatusToggle';
import InventoryStatus from '../List/InventoryStatus';
import './style.scss';
import '../List/InventoryStatus/style.less';

const { TabPane } = Tabs;

const Detail = ({ match, location }) => {
  const dispatch = useDispatch();
  const stateRef = React.useRef(location.state);

  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );
  const menuItemDetail = useSelector(
    (state) => state.menuProvider.menuItemDetail,
    shallowEqual
  );
  const assignedMenuItemTotal = useSelector(
    (state) => state.restaurantProvider.restaurantMenuItemTotal,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const [productDetailLoaded, setProductDetailLoaded] = useState(false);

  const menuId = _get(match, 'params.menuId');
  const selectedKeys = location.pathname.split('/')[4];

  const breadcrumbItems = [
    {
      path: '/products',
      breadcrumbName: '商品',
    },
    {
      breadcrumbName: menuItemDetail?.name,
    },
  ];

  const fetchMenuItemDetail = useCallback(async () => {
    setProductDetailLoaded(false);
    try {
      await dispatch(getMenuItemDetailRequest({ formattedObjectId: menuId }));
    } catch (error) {
      console.log(error);
      dispatch(push('/products'));
    }
    setProductDetailLoaded(true);
  }, [dispatch, menuId]);

  useEffect(() => {
    fetchMenuItemDetail();
  }, [fetchMenuItemDetail]);

  useEffect(() => {
    dispatch(
      getRestaurantMenuItemRequest({
        formattedMenuId: menuId,
        limit: 10,
        page: 1,
      })
    );
  }, [menuId]);

  const calcNavigateId = () => {
    const menuIdList = _map(productList.list, (item) => item.formattedObjectId) || [];
    const index = _findIndex(menuIdList, (item) => item === menuId);
    return {
      current: index,
      backTo: index > 0 && menuIdList[index - 1],
      nextTo: index < menuIdList.length - 1 && menuIdList[index + 1],
    };
  };

  const onTabClick = (key) => {
    const pushPath = `/products/detail/${menuId}/${key}`;
    dispatch(replace(pushPath));
  };

  const handleReplaceRoute = (evt, id) => {
    evt.preventDefault();
    dispatch(replace(`/products/detail/${id}/in-app`));
  };

  const renderNavigationButton = () => {
    const { current, backTo, nextTo } = calcNavigateId();
    const orderListList = _get(productList, 'list', []);
    if (orderListList.length < 1) {
      return [];
    }

    return [
      <span
        key="current"
        className="text-bold"
        style={{ fontSize: 14, marginLeft: 16 }}
      >
        {`${current + 1} / ${orderListList.length}`}
      </span>,
      <Button
        key="prev"
        type="primary"
        disabled={!backTo}
        onClick={(evt) => handleReplaceRoute(evt, backTo)}
        icon={<LeftOutlined />}
      />,
      <Button
        key="next"
        type="primary"
        disabled={!nextTo}
        onClick={(evt) => handleReplaceRoute(evt, nextTo)}
        icon={<RightOutlined />}
      />,
    ];
  };

  const syncStatus = () => {
    const status = Helpers.getSyncStatus(menuItemDetail?.warehouseMenus);

    if (menuItemDetail?.warehouse_airLogiIntegrated) {
      return (
        <>
          <div className="inventory-container">
            <div className="status-wrapper">
              <div className={`status ${status?.className}`}>{status?.label}</div>
            </div>
          </div>
          <p> | </p>
        </>

      );
    }
    return null;
  };

  const renderProductDetail = () => (
    <div className="flex items-start">
      <Button
        type="ghost"
        icon={<ArrowLeftOutlined />}
        onClick={() => {
          if (stateRef.current?.from) {
            dispatch(replace(stateRef.current.from));
            return;
          }
          dispatch(replace('/products'));
        }}
      />
      <Avatar
        shape="square"
        size={40}
        src={menuItemDetail?.images[0]?.url || DefaultProductImage}
      />
      <div className="product-info">
        <Typography.Title level={5}>{menuItemDetail?.name}</Typography.Title>
        <div className="flex items-center">
          <p>ID: {menuId} | </p>
          {syncStatus()}
          <InventoryStatus
            menuDetail={menuItemDetail}
            fetchData={fetchMenuItemDetail}
            showInStock={false}
          />
          <p> | </p>
          <StatusToggle
            record={menuItemDetail?.status}
            rowRecord={{
              objectId: menuItemDetail.objectId,
              isBasic: menuItemDetail.isBasic,
              name: menuItemDetail.name,
            }}
          />
        </div>
      </div>
    </div>
  );

  if (!productDetailLoaded || _isEmpty(menuItemDetail)) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className="product-detail-container">
      <PageHeader
        title={renderProductDetail()}
        breadcrumbRoutes={breadcrumbItems}
        extra={[renderNavigationButton()]}
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="商品詳細" key="in-app" />
            <TabPane
              tab={(
                <span>
                  レストラン
                  <span id="background-filter-menu-total">
                    {assignedMenuItemTotal}
                  </span>
                </span>
              )}
              key="assigned-restaurant"
            />
            {currentUser.role === 'SUPPLIER' && (
              <TabPane
                tab={(
                  <span>
                    セット商品
                    <span id="background-filter-menu-total">
                      {_get(menuItemDetail, 'collectionMenuCount', 0)}
                    </span>
                  </span>
                )}
                key="collection-list"
              />
            )}
            {menuItemDetail?.isBasic && (
              <TabPane
                tab={(
                  <span>
                    関連商品
                    <span id="background-filter-menu-total">
                      {_get(menuItemDetail, 'relatedProductCount', 0)}
                    </span>
                  </span>
                )}
                key="related-product"
              />
            )}
          </Tabs>
        )}
      />
      <Switch>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

Detail.propTypes = {
  match: PropTypes.any,
  pathname: PropTypes.any,
  location: PropTypes.any,
};

export default withRefresh('product-detail')(Detail);
