import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getRestaurantDetailRequest } from 'providers/RestaurantProvider/actions';
import {
  makeSelectRestaurantDetail,
  makeSelectRestaurantList,
} from 'providers/RestaurantProvider/selectors';

import RestaurantDetail from './RestaurantDetail';

const mapStateToProps = createStructuredSelector({
  restaurantDetail: makeSelectRestaurantDetail(),
  restaurantListData: makeSelectRestaurantList(),
});

const mapDispatchToProps = (dispatch) => ({
  getRestaurantDetailRequest: (value) => dispatch(getRestaurantDetailRequest(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(RestaurantDetail);
