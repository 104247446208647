export const GET_CURRENT_USER_REQUEST = 'AuthProvider/GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'AuthProvider/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'AuthProvider/GET_CURRENT_USER_ERROR';

export const LOGIN_REQUEST = 'AuthProvider/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'AuthProvider/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'AuthProvider/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'AuthProvider/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'AuthProvider/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'AuthProvider/LOGOUT_ERROR';

export const RESET_PASSWORD_REQUEST = 'AuthProvider/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'AuthProvider/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'AuthProvider/RESET_PASSWORD_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'AuthProvider/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'AuthProvider/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'AuthProvider/CHANGE_PASSWORD_ERROR';

export const SIGNUP_REQUEST = 'AuthProvider/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'AuthProvider/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'AuthProvider/SIGNUP_ERROR';

export const RESEND_VERIFY_EMAIL_REQUEST = 'AuthProvider/RESEND_VERIFY_EMAIL_REQUEST';
export const RESEND_VERIFY_EMAIL_SUCCESS = 'AuthProvider/RESEND_VERIFY_EMAIL_SUCCESS';
export const RESEND_VERIFY_EMAIL_ERROR = 'AuthProvider/RESEND_VERIFY_EMAIL_ERROR';

export const SET_RESTAURANT_REQUEST_COUNT = 'AuthProvider/SET_RESTAURANT_REQUEST_COUNT';

export const CLEAR_CURRENT_USER_DATA = 'AuthProvider/CLEAR_CURRENT_USER_DATA';
export const INVALID_SESSION_TOKEN = 'AuthProvider/INVALID_SESSION_TOKEN';
