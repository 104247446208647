import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card } from 'antd';

import { TextField } from 'components/Form';
import validate from './validate';
import StatusToggle from '../List/StatusToggle';
import DisplayToggle from '../List/DisplayToggle';

const RankingForm = ({
  handleSubmit, tag, genre, rankingDetail
}) => (
  <Form
    colon={false}
    onSubmit={handleSubmit}
    layout="horizontal"
    id="rankingForm"
  >
    <Card
      className="highlight-card"
      title="General"
      bordered={false}
      headStyle={{ fontWeight: 'bold', fontSize: '20px', padding: '0 16px' }}
    >
      <Field
        label="ランキング名"
        name="name"
        maxLength={50}
        component={TextField}
        required
      />
      <Form.Item label="ステータス">
        <StatusToggle record={rankingDetail?.status} rowRecord={rankingDetail} />
      </Form.Item>
      <Form.Item
        label="ホーム画面で表示するか"
        className="display-toggle"
      >
        <DisplayToggle record={rankingDetail?.isVisibleOnHome} rowRecord={rankingDetail} />
      </Form.Item>
      <Form.Item label="Specify to">
        <div className="specify-field">
          <div className={(tag && 'tag') || ''}>{tag || genre}</div>
        </div>
      </Form.Item>
      <Field
        label="メモ"
        name="memo"
        maxLength={500}
        component={TextField}
        textArea
        requiredSpace
      />
    </Card>
  </Form>
);

RankingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  tag: PropTypes.string,
  genre: PropTypes.string,
  rankingDetail: PropTypes.object,
};

export default reduxForm({
  form: 'rankingForm',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(RankingForm);
