import _map from 'lodash/map';
import _get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Tag, Space, Dropdown, Menu
} from 'antd';
import { updateInfoOEMRequest } from 'providers/RestaurantProvider/actions';

const OriginalRecipeConsultationSupportField = ({ restaurantDetail }) => {
  const status = restaurantDetail?.infoOEM?.isOriginalRecipeConsultationSupport || false;
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateStatus = async (target) => {
    setIsUpdating(true);
    try {
      await dispatch(
        updateInfoOEMRequest({
          restaurantId: restaurantDetail?.objectId,
          isOriginalRecipeConsultationSupport: target,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setIsUpdating(false);
  };

  const targetStatusMap = {
    [true]: [false],
    [false]: [true],
  };

  const statusTextMap = {
    [true]: '相談済み',
    [false]: '未相談',
  };
  const statusColorMap = {
    [false]: '#edb317',
    [true]: '',
  };

  const renderStatusMenu = () => (
    <Menu
      onClick={({ key }) => {
        handleUpdateStatus(key);
      }}
    >
      {_map(targetStatusMap[status], (target) => (
        <Menu.Item key={target}>
          <Space>
            <span style={{ fontWeight: 500, fontSize: 14 }}>
              ステータス変更
            </span>
            <Tag color={statusColorMap[target]}>{statusTextMap[target]}</Tag>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderStatusMenu}
      trigger={['click']}
      disabled={isUpdating}
    >
      <Tag
        style={{
          fontSize: 14,
          fontWeight: 500,
          padding: '2px 8px',
        }}
        color={statusColorMap[status]}
      >
        <Space style={{ cursor: 'pointer' }}>
          {isUpdating && <SyncOutlined spin />}
          <span>{statusTextMap[status]}</span>
          {_get(targetStatusMap, status, []).length > 0 && <DownOutlined />}
        </Space>
      </Tag>
    </Dropdown>
  );
};

OriginalRecipeConsultationSupportField.propTypes = {
  restaurantDetail: PropTypes.object,
};

export default OriginalRecipeConsultationSupportField;
