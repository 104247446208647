import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectRestaurantDetail } from 'providers/RestaurantProvider/selectors';

import RestaurantInfo from './RestaurantInfo';

const mapStateToProps = createStructuredSelector({
  restaurantDetail: makeSelectRestaurantDetail(),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(RestaurantInfo);
