import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Card } from 'antd';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import { getRestaurantGroupListRequest } from 'providers/RestaurantGroupProvider/actions';

import Columns from './Columns';

const RestaurantGroupList = () => {
  const dispatch = useDispatch();

  const [pageControl, , onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;

  const restaurantGroupList = useSelector(
    (state) => state.restaurantGroupProvider.restaurantGroupList,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantGroupListRequest({
          orderBy,
          order,
          page,
          limit,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [orderBy, order, page, limit, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [Columns.ID, Columns.name, Columns.note, Columns.createdAt];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Table
          bordered={false}
          columns={columns}
          data={restaurantGroupList?.list}
          total={restaurantGroupList?.total}
          loading={tableLoading}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 800 }}
          onChange={onTableChange}
        />
      </Card>
    </Card>
  );
};

export default RestaurantGroupList;
