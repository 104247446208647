import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Dropdown,
  Popover,
  Badge,
  Space,
  Typography,
  Avatar,
} from 'antd';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';

import { USER_ROLE } from 'utils/constants';

import MessageIcon from 'images/icon-message.svg';
import Notification from './Notification';
import Conversation from './Conversation';

import './style.less';

const getNameFieldByRole = {
  [USER_ROLE.OPERATOR]: 'operator.name',
  [USER_ROLE.SUPPLIER]: 'supplier.name',
  [USER_ROLE.BRAND_OWNER]: 'brandOwner.name',
  [USER_ROLE.RESTAURANT_OWNER]: 'restaurantOwner.name',
};

const getAvatarFileByRole = {
  [USER_ROLE.SUPPLIER]: 'supplier.image',
  [USER_ROLE.BRAND_OWNER]: 'brandOwner.image',
  [USER_ROLE.RESTAURANT_OWNER]: 'restaurantOwner.image',
};

const HeaderComponent = ({
  onLogout,
  countUnreadConversationRequest,
  currentUser,
  unreadConversationCount,
}) => {
  const [conversationVisible, setConversation] = useState(false);

  useEffect(() => {
    if (currentUser.role === USER_ROLE.SUPPLIER) {
      countUnreadConversationRequest();
    }
  }, []);

  const menu = (
    <Menu>
      <Menu.Item icon={<LogoutOutlined />} key="logout" onClick={onLogout}>
        ログアウト
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="full-w flex justify-end items-center header-container">
        <Space size={32}>
          {currentUser.role === USER_ROLE.SUPPLIER && (
            <Popover
              placement="bottomRight"
              content={(
                <Conversation
                  closePopup={() => setConversation(false)}
                  popupVisible={conversationVisible}
                />
              )}
              trigger="click"
              visible={conversationVisible}
              onVisibleChange={setConversation}
              getPopupContainer={(t) => t.parentNode}
            >
              <Badge count={unreadConversationCount}>
                <img
                  width={24}
                  height={24}
                  alt="message-icon"
                  className="cursor-pointer"
                  src={MessageIcon}
                />
              </Badge>
            </Popover>
          )}
          <Notification />
          <Dropdown placement="bottomRight" overlay={menu} trigger={['click']}>
            <Space className="flex justify-center items-center cursor-pointer">
              <Avatar
                size={24}
                src={_get(currentUser, getAvatarFileByRole[currentUser.role])}
              >
                {_get(
                  currentUser,
                  getNameFieldByRole[currentUser.role]
                )?.substr(0, 1)}
              </Avatar>
              <Typography.Text>
                {_get(currentUser, getNameFieldByRole[currentUser.role])}
              </Typography.Text>
              <DownOutlined />
            </Space>
          </Dropdown>
        </Space>
      </div>
    </>
  );
};

HeaderComponent.propTypes = {
  onLogout: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  countUnreadConversationRequest: PropTypes.func,
  unreadConversationCount: PropTypes.number,
};

export default HeaderComponent;
