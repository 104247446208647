import {
  GET_SUPPLIER_BRAND_LIST_REQUEST,
  GET_SUPPLIER_BRAND_LIST_SUCCESS,
  GET_SUPPLIER_BRAND_LIST_ERROR,
  CREATE_SUPPLIER_BRAND_REQUEST,
  CREATE_SUPPLIER_BRAND_SUCCESS,
  CREATE_SUPPLIER_BRAND_ERROR,
} from './constants';

export function getSupplierBrandListRequest(payload) {
  return {
    type: GET_SUPPLIER_BRAND_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getSupplierBrandListSuccess(payload, meta) {
  return { type: GET_SUPPLIER_BRAND_LIST_SUCCESS, payload, meta };
}

export function getSupplierBrandListError(payload, meta) {
  return {
    type: GET_SUPPLIER_BRAND_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function createSupplierBrandRequest(payload) {
  return { type: CREATE_SUPPLIER_BRAND_REQUEST, payload, meta: { thunk: true } };
}

export function createSupplierBrandSuccess(payload, meta) {
  return { type: CREATE_SUPPLIER_BRAND_SUCCESS, payload, meta };
}

export function createSupplierBrandError(payload, meta) {
  return {
    type: CREATE_SUPPLIER_BRAND_ERROR,
    payload,
    meta,
    error: true,
  };
}
