import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';

import RestaurantList from '../RestaurantList';
import RestaurantOwnerInfo from '../RestaurantOwnerInfo';

const RedirectToInAppConfig = (props) => {
  const { restaurantOwnerId } = _get(props, 'match.params');
  return (
    <Redirect to={`/restaurant-owner/detail/${restaurantOwnerId}/in-app`} />
  );
};

const indexRoutes = [
  {
    path: '/restaurant-owner/detail/:restaurantOwnerId/in-app',
    component: RestaurantOwnerInfo,
  },
  {
    path: '/restaurant-owner/detail/:restaurantOwnerId/restaurant-list',
    component: RestaurantList,
  },
  {
    path: '/restaurant-owner/detail/:restaurantOwnerId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
