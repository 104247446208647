import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { Space, Typography, Upload } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import 'antd/es/slider/style';
import pdfIcon from 'images/icon-pdf.svg';
import { IMAGE_ACCEPT_TYPE } from 'utils/constants';

const UploadField = (props) => {
  const {
    label,
    input,
    description,
    additionalContent,
    maxCount,
    meta: { touched, invalid, error },
    shape,
  } = props;

  const uploadRef = useRef(null);

  useEffect(() => () => {
    if (uploadRef.current) { URL.revokeObjectURL(uploadRef.current); }
  }, []);

  const onChange = ({ fileList }) => {
    if (fileList.length < input.value.length) {
      input.onChange(fileList);
    }
  };

  const beforeUploadHandler = (file) => {
    if (uploadRef.current) { URL.revokeObjectURL(uploadRef.current); }
    const url = URL.createObjectURL(file);
    const isPdfFile = file.type === 'application/pdf';

    input.onChange([
      ...input.value,
      {
        url: isPdfFile ? pdfIcon : url, originFileObj: file, status: 'done', name: file.name
      },
    ]);
    uploadRef.current = isPdfFile ? pdfIcon : url;

    return false;
  };

  const customItemRender = (originNode, file) => {
    const name = file?.name;
    const start = name?.slice(0, name?.length - 3).trim();
    const suffix = name?.slice(-3).trim();
    return (
      <Space
        direction="vertical"
        style={{
          width: 120,
          height: 120,
        }}
      >
        {originNode}
        <Typography.Text
          ellipsis={{
            suffix: `${suffix}`,
          }}
          style={{
            width: 120,
          }}
        >{start}
        </Typography.Text>
      </Space>
    );
  };
  const upload = (
    <Upload
      className={shape === 'round' ? 'round-image-field' : ''}
      listType="picture-card"
      fileList={input.value || []}
      beforeUpload={beforeUploadHandler}
      onChange={onChange}
      accept={`${IMAGE_ACCEPT_TYPE}, .pdf`}
      maxCount={maxCount}
      showUploadList={{
        showPreviewIcon: false,
      }}
      itemRender={customItemRender}
    >
      {input.value.length < maxCount && (
        <Space direction="vertical">
          <UploadOutlined />
          <Typography.Text>Select file or Drop to upload
          </Typography.Text>
        </Space>
      )}
    </Upload>
  );

  return (
    <Form.Item
      label={
        !description ? (
          label
        ) : (
          <Space
            size={0}
            direction="vertical"
            style={{ display: 'inline-block' }}
          >
            {label}
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {description}
            </Typography.Text>
          </Space>
        )
      }
      help={touched && invalid && error}
      validateStatus={touched && invalid && error ? 'error' : ''}
      className="field-form-item upload-field"
    >
      {additionalContent}
      {upload}
    </Form.Item>
  );
};

UploadField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  additionalContent: PropTypes.any,
  meta: PropTypes.object.isRequired,
  description: PropTypes.any,
  maxCount: PropTypes.any,
  shape: PropTypes.string,
};

export default UploadField;
