import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Switch } from 'antd';

import { STATUS } from 'utils/constants';

import './style.scss';

const { confirm } = Modal;

const showPopup = (product, { onOk }) => {
  const { name } = product;

  confirm({
    title: `製造会社${name}を無効にしますか？`,
    content:
      '無効にするとこの製造会社のメニューはアプリから注文ができなくなります。注文済みの商品には影響しません。',
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk
  });
};
const StatusSwitch = (props) => {
  const {
    status, manufacturer, isManufacturerLoading, changeManufacturerStatus
  } = props;
  return (
    <div role="button" className="status-container" onClick={(e) => { e.stopPropagation(); }}>  {/*eslint-disable-line*/}
      <div className="status-content">
        <span className={`dot${status === STATUS.ACTIVE ? '' : '--inactive'}`} />
        <span>{status === STATUS.ACTIVE ? '有効' : '無効' }</span>
      </div>
      <Switch
        disabled={isManufacturerLoading}
        checked={status === STATUS.ACTIVE}
        onClick={() => {
          const manufacturerId = manufacturer.objectId;
          const nextStatus = (status === STATUS.ACTIVE) ? STATUS.INACTIVE : STATUS.ACTIVE;
          if (nextStatus === STATUS.INACTIVE) {
            showPopup(manufacturer, {
              onOk: () => {
                changeManufacturerStatus({
                  manufacturerId,
                  status: nextStatus,
                });
              },
            });
          } else {
            changeManufacturerStatus({ manufacturerId, status: nextStatus });
          }
        }}
        size="small"
      />
    </div>
  );
};

StatusSwitch.propTypes = {
  status: PropTypes.any,
  manufacturer: PropTypes.object,
  isManufacturerLoading: PropTypes.bool,
  changeManufacturerStatus: PropTypes.func
};

export default StatusSwitch;
