import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _join from 'lodash/join';
import moment from 'moment';
import React, {
  useCallback, useState, useEffect, useMemo
} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  reduxForm, Field, getFormValues, change
} from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row, Col, Card, Space, Typography, Button, Upload
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import csv from 'csv';
import toastMessage from 'utils/toastMessage';

import {
  TextField,
  CheckboxField,
  DatePickerField,
  FileField,
  RadioGroup,
  SelectField,
} from 'components/Form';

import Constants from 'utils/constants';
import Helpers from 'utils/helpers';

import { getRestaurantGroupListRequest } from 'providers/RestaurantGroupProvider/actions';

import validate from './validate';
import './style.less';
import ProductsField from './ProductField';

const { Text } = Typography;

const attachmentTypeOptions = [
  {
    name: 'PDF/Image',
    objectId: 'file',
  },
  {
    name: (
      <Typography.Text
        ellipsis={{
          tooltip: 'シコメルストア商品',
        }}
      >
        シコメルストア商品
      </Typography.Text>
    ),
    objectId: 'product',
  },
  {
    name: 'Web',
    objectId: 'url',
  },
];

const AnnouncementCreateForm = ({ handleSubmit, formScheduleValue }) => {
  const formValues = useSelector((state) => getFormValues('announcementCreateForm')(state));
  const announcementTarget = formValues?.announcementTarget;
  const restaurantGroup = formValues?.restaurantGroup;
  const productIds = formValues?.productIds || [];

  const dispatch = useDispatch();
  const [restaurantGroupsLoading, setRestaurantGroupsLoading] = useState(false);

  const restaurantGroupList = useSelector(
    (state) => state.restaurantGroupProvider.restaurantGroupList,
    shallowEqual
  );
  const parseConfig = useSelector(
    (state) => state.prefectureProvider?.parseConfig?.params,
    shallowEqual
  );

  const fetchData = useCallback(async () => {
    setRestaurantGroupsLoading(true);
    try {
      await dispatch(
        getRestaurantGroupListRequest({
          order: 'ascend',
        })
      );
    } catch (error) {
      console.error(error);
    }
    setRestaurantGroupsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUploadCSV = useCallback(
    (data) => {
      data[0][0] = 'formattedId';
      const restaurantIds = _reduce(
        Helpers.convertToArrayOfObjects(data),
        (pre, cur) => {
          pre.push(cur.formattedId?.trim());
          return pre;
        },
        []
      );
      let preValue = `${formValues?.restaurantIds?.trim() || ''}\n`;
      preValue += _join(restaurantIds, '\n');
      dispatch(
        change('announcementCreateForm', 'restaurantIds', preValue.trim())
      );
      toastMessage.success({
        description: 'CSV imported successfully',
      });
    },
    [formValues?.restaurantIds]
  );

  const VersusTimeInput = useMemo(() => {
    if (
      ![
        Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
          .USER_REGISTER_DATE_VERSUS_TIME,
        Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
          .USER_LAST_ORDER_DATE_VERSUS_TIME,
      ].includes(restaurantGroup)
    ) {
      return null;
    }
    return (
      <div className="flex items-center">
        <div id="radio-group-2">
          <Field
            name="userDateComparison"
            component={RadioGroup}
            options={[
              {
                objectId: 'lte',
                name: 'より前',
              },
              {
                objectId: 'gte',
                name: 'より後',
              },
            ]}
            isButton
          />
        </div>
        <Field
          name="userDate"
          placeholder="日時を選択"
          dateFormat={'YYYY/MM/DD'}
          component={DatePickerField}
        />
      </div>
    );
  }, [
    announcementTarget,
    restaurantGroup,
    formValues?.restaurantIds,
    parseConfig?.announceCustomUserSampleCSV,
  ]);

  const RestaurantIdsInput = useMemo(() => {
    if (
      restaurantGroup
      !== Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER
    ) {
      return null;
    }
    return (
      <Space size={4} direction="vertical" className="full-w">
        <Text>レストランID</Text>
        <Text type="secondary">
          下の入力ボックスに対象レストランのIDを1行に1つずつ入力するか、CSVファイルをアップロードしてください。
        </Text>
        <Text type="secondary">
          ※サンプルCSVは
          <a
            href={parseConfig?.announceCustomUserSampleCSV}
            target="_blank"
            rel="noreferrer"
          >
            こちら
          </a>
          からダウンロードできます。
        </Text>
        <Upload
          beforeUpload={(file) => {
            if (file?.type !== 'text/csv') {
              toastMessage.error({
                description: 'ファイル拡張子が正しくありません',
              });
              return false;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              csv.parse(e.target.result, (err, data) => {
                if (err) {
                  toastMessage.error({ description: err?.message });
                  return;
                }
                handleUploadCSV(data);
              });
            };
            reader.readAsText(file);
            return false;
          }}
          accept=".csv"
          maxCount={1}
          showUploadList={false}
        >
          <Button style={{ margin: '12px 0' }} icon={<UploadOutlined />}>
            ファイルを選択
          </Button>
        </Upload>
        <Field
          name="restaurantIds"
          component={TextField}
          textArea
          autoSize={{ minRows: 20, maxRows: 20 }}
        />
      </Space>
    );
  }, [
    announcementTarget,
    restaurantGroup,
    formValues?.restaurantIds,
    parseConfig?.announceCustomUserSampleCSV,
  ]);

  const RestaurantTargetInput = useMemo(() => {
    if (
      announcementTarget
      !== Constants.RESTAURANT_ANNOUNCEMENT.TARGET.RESTAURANT_GROUP
    ) {
      return null;
    }
    return (
      <Space size={16} direction="vertical" className="full-w">
        <Text>特定のグループを選択して、プッシュ通知を送ります。</Text>
        <Field
          showSearch
          placeholder="対象グループを選択してください"
          loading={restaurantGroupsLoading}
          disabled={_isEmpty(restaurantGroupList?.list)}
          name="restaurantGroup"
          component={SelectField}
          options={_map(restaurantGroupList?.list, (item) => ({
            objectId: item.key,
            name: item.name,
          }))}
        />
        {VersusTimeInput}
        {RestaurantIdsInput}
      </Space>
    );
  }, [
    announcementTarget,
    restaurantGroup,
    formValues?.restaurantIds,
    parseConfig?.announceCustomUserSampleCSV,
  ]);

  const renderPushAttachmentContent = () => {
    switch (formValues?.attachmentType) {
      case 'file':
        return (
          <Field
            name="file"
            component={FileField}
            label={(
              <Text type="secondary">
                PDFまたは画像を選択してください。ユーザーが通知をタップするとファイルが開かれます。
              </Text>
            )}
          />
        );

      case 'product':
        return (
          <ProductsField
            placeholder="販売者名から検索"
            productIds={productIds}
            label="Select a Shikomel Store product. The restaurant tap on the notification will open product detail in app. If the selected product is disable, the restaurant will see error message"
          />
        );

      case 'url':
        return (
          <Field
            name="url"
            component={TextField}
            label={<Text type="secondary">WEBページURL</Text>}
            placeholder="販売者名から検索"
          />
        );

      default:
        return null;
    }
  };

  return (
    <Form colon={false} onSubmit={handleSubmit} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Space direction="vertical" size={16} className="full-w flex">
            <Card title="通知内容" bordered={false}>
              <Field
                label={(
                  <>
                    <Text strong>タイトル</Text>
                    <br />
                    <Text type="secondary">
                      推奨：65字（66字以上は機種によって省略される場合があります）
                    </Text>
                    <br />
                    <Text type="secondary">※アプリ内には表示されません</Text>
                  </>
                )}
                name="title"
                placeholder="タイトル"
                component={TextField}
                maxLength={200}
                showCount
                required
              />
              <Field
                label={(
                  <>
                    <Text strong>本文</Text>
                    <br />
                    <Text type="secondary">推奨：178 字</Text>
                    <br />
                    <Text type="secondary">※アプリ内に表示されます</Text>
                  </>
                )}
                name="message"
                placeholder="本文"
                component={TextField}
                maxLength={500}
                showCount
                textArea
                required
              />
            </Card>
            <Card title="配信予約" bordered={false} id="form-schedule-items">
              <Field
                name="schedule"
                label="配信を予約"
                component={CheckboxField}
              />
              <Field
                label={(
                  <Text type="secondary">
                    プッシュ通知を配信する日時を選択してください。
                  </Text>
                )}
                name="announceTime"
                placeholder="日時を選択"
                showTime={{ format: 'HH:mm' }}
                dateFormat={'YYYY/MM/DD HH:mm'}
                component={DatePickerField}
                disabled={!formScheduleValue}
                disabledDate={(current) => current < moment().startOf('day')}
              />
            </Card>
          </Space>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Space direction="vertical" size={16} className="full-w flex">
            <Card title="添付ファイル" bordered={false}>
              <div className="flex full-w flex-wrap">
                <Text
                  style={{
                    width: 120,
                  }}
                >
                  遷移先タイプ
                </Text>
                <div className="attachment-type">
                  <Field
                    name="attachmentType"
                    component={RadioGroup}
                    options={attachmentTypeOptions}
                    isButton
                  />
                  {renderPushAttachmentContent()}
                </div>
              </div>
            </Card>
            <Card
              title="通知対象"
              bordered={false}
              id="announcement-user-segment"
            >
              <div className="flex full-w flex-wrap">
                <Text
                  style={{
                    width: 120,
                  }}
                >
                  通知対象
                </Text>
                <div style={{ flex: 1 }}>
                  <Space size={16} direction="vertical" className="full-w flex">
                    <span id="radio-group-1">
                      <Field
                        name="announcementTarget"
                        component={RadioGroup}
                        options={[
                          {
                            name: '全てのユーザー',
                            objectId:
                              Constants.RESTAURANT_ANNOUNCEMENT.TARGET.ALL,
                          },
                          {
                            name: '特定のグループ',
                            objectId:
                              Constants.RESTAURANT_ANNOUNCEMENT.TARGET
                                .RESTAURANT_GROUP,
                          },
                        ]}
                        isButton
                      />
                    </span>
                    {RestaurantTargetInput}
                  </Space>
                </div>
              </div>
            </Card>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

AnnouncementCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formScheduleValue: PropTypes.any,
};

export default reduxForm({
  form: 'announcementCreateForm',
  validate,
  touchOnChange: true,
})(AnnouncementCreateForm);
