import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { submit, isDirty, reset } from 'redux-form';
import _isEmpty from 'lodash/isEmpty';
import {
  Button, Space, Row, Col, Card, Typography, Empty, Alert, Modal,
} from 'antd';
import moment from 'moment';

import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import EmptyMenu from 'images/icon-empty-product.svg';
import Columns from 'containers/AdminPage/Content/Products/List/Columns';
import { STATUS, DATE_FORMAT } from 'utils/constants';
import { reorderRankingMenuRequest, updateRankingRequest } from 'providers/RankingProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import RankingForm from './RankingForm';
import ProductDialog from './ProductUpdate';
import Sortable from '../Sortable';

const { Text } = Typography;

const RankingUpdate = ({
  onRemoveAssignedProduct,
  assignedProducts,
  rankingDetail,
}) => {
  const [productDialog, setProductDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const dispatch = useDispatch();
  const dirty = useSelector((state) => isDirty('rankingForm')(state));

  const { confirm } = Modal;

  const showConfirm = (modalProps, actionFn) => {
    confirm({
      okText: '削除する',
      okType: 'primary',
      cancelText: 'いいえ',
      onOk: () => {
        actionFn();
      },
      ...modalProps,
    });
  };

  const columns = [
    {
      title: 'Sort',
      key: 'dragHandle',
      dataIndex: 'dragHandle',
      width: 70,
    },
    { ...Columns.ID, fixed: false, sorter: false },
    Columns.productName,
    {
      title: 'Inventory',
      key: 'inventory',
      width: 200,
      render: (record) => {
        const { inStock } = record;
        return (
          <div className="inventory-container">
            <div className="status-wrapper">
              <div className={`status ${!inStock ? 'sold-out' : ''}`}>
                {!inStock ? '売り切れ' : '在庫あり'}
              </div>
            </div>
            {!inStock && record?.stock_arrivalDate && (
              <div
                style={{
                  marginLeft: 18,
                }}
              >
                入荷予定日{moment(record?.stock_arrivalDate).format(DATE_FORMAT)}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      render: (record) => (
        <div className="status-wrapper">
          <div className={`status ${record === STATUS.INACTIVE ? 'inactive' : ''}`}>
            {record === STATUS.INACTIVE ? '無効' : '有効'}
          </div>
        </div>
      )
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'left',
      width: 110,
      render: (record) => (
        <Button
          type="text"
          danger
          icon={<CloseOutlined />}
          onClick={() => {
            showConfirm(
              {
                title: `ランキング表示から${record?.name}を削除してよろしいですか？`,
              },
              () => onRemoveAssignedProduct(record.rankingMenuId),
            );
          }}
        >
          削除
        </Button>
      ),
    },
  ];

  const button = (text = 'ランキング商品追加') => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => setProductDialog(true)}
    >
      {text}
    </Button>
  );

  const handleCancel = () => {
    dispatch(reset('rankingForm'));
  };

  const handleOk = () => {
    dispatch(submit('rankingForm'));
  };

  const handleUpdateRanking = async (values) => {
    setUpdateLoading(true);
    try {
      await dispatch(updateRankingRequest({ ...values, objectId: rankingDetail?.objectId }));
      setUpdateLoading(false);
      dispatch(doRefresh({ target: 'ranking-detail' }));
    } catch (error) {
      setUpdateLoading(false);
    }
  };

  return (
    <>
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ padding: '0 16px 16px' }}
        style={{ paddingBottom: 64 }}
      >
        {productDialog && (
          <ProductDialog
            title={`${rankingDetail?.name}商品選択`}
            assignedProductList={assignedProducts?.list}
            handleCloseDialog={() => setProductDialog(false)}
            rankingId={rankingDetail?.objectId}
          />
        )}
        <Row gutter={[16, 16]}>
          {assignedProducts?.total === 0 && (
            <Col span={24}>
              <Alert message="Ranking list which has no product will not be shown in the Restaurant App" type="warning" showIcon icon={<WarningOutlined style={{ color: '#d48805' }} />} />
            </Col>
          )}
          <Col xl={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <Card
                className="highlight-card padding-card ranking-menu-container"
                bordered={false}
                title="商品リスト"
                headStyle={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                  padding: '0 16px',
                  borderBottom: '3px solid #e8e8e8',
                }}
                extra={!_isEmpty(assignedProducts?.list) && button()}
              >
                {assignedProducts?.total > 0 ? (
                  <>
                    <p className="note">アプリのTOPに表示するランキング情報をメンテナンスできます。 最大１０商品まで追加可能です。 ドラッグ＆ドロップで順位の並べ替えが可能です</p>
                    <Sortable
                      columns={columns}
                      rankingList={assignedProducts?.list}
                      reorderProductRanking={reorderRankingMenuRequest}
                      scroll={{ x: 1000 }}
                      rankingId={rankingDetail?.objectId}
                    />
                  </>
                ) : (
                  <Space
                    size="middle"
                    direction="vertical"
                    align="center"
                    className="full-w"
                    style={{ paddingBottom: 24 }}
                  >
                    <Empty
                      image={EmptyMenu}
                      imageStyle={{
                        width: 160,
                        height: 160,
                        margin: '0 auto',
                      }}
                      description={(
                        <Text type="secondary">
                          商品が設定されておりません。
                        </Text>
                      )}
                    />
                    {button('商品を追加')}
                  </Space>
                )}
              </Card>
            </Space>
          </Col>
          <Col xl={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <RankingForm
              onSubmit={handleUpdateRanking}
              initialValues={{
                name: rankingDetail?.name || '',
                memo: rankingDetail?.memo || '',
              }}
              tag={rankingDetail?.tag?.name}
              genre={rankingDetail?.genre?.name}
              rankingDetail={rankingDetail}
            />
          </Col>
        </Row>
      </Card>
      {dirty && (
        <FormActionFooter
          handleCancel={handleCancel}
          actionLoading={updateLoading}
          handleOk={handleOk}
          isShowLabel={false}
          cancelText="キャンセル"
          okText="送信"
        />
      )}
    </>
  );
};

RankingUpdate.propTypes = {
  rankingDetail: PropTypes.object,
  onRemoveAssignedProduct: PropTypes.func,
  assignedProducts: PropTypes.object,
};

export default RankingUpdate;
