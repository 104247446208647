import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';
import _pick from 'lodash/pick';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getNotificationListSuccess,
  getNotificationListError,
  getUnreadNotificationCountSuccess,
} from './actions';
import {
  GET_NOTIFICATION_LIST_REQUEST,
  MARK_NOTIFICATION_READ_REQUEST,
  READ_ALL_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST,
  GET_UNREAD_NOTIFICATION_COUNT_REQUEST,
} from './constants';

export function* handleGetNotificationList(action) {
  const { page, limit, types } = _pick(action.data, ['page', 'limit', 'types']);
  const params = {
    page: page || 1,
    limit: limit || 10,
    types,
  };
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getNotificationList`,
      params
    );

    yield put(getNotificationListSuccess(response.result));
  } catch (error) {
    yield put(getNotificationListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetUnreadNotificationCountList() {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getNumOfNewNotifications`,
      {}
    );

    yield put(getUnreadNotificationCountSuccess(response.result));
  } catch (error) {
    console.error(error.error);
  }
}

export function* handleMarkNotificationRead(action) {
  const params = _pick(action.data, ['notificationId']);

  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}markNotificationIsRead`,
      params
    );
  } catch (error) {
    console.error(error.error);
  }
}

export function* handleReadAllNotification() {
  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}markAllNotificationsIsRead`,
      {}
    );
  } catch (error) {
    console.error(error.error);
  }
}

export function* handleDeleteNotification(action) {
  const params = _pick(action.data, ['notificationId']);

  try {
    yield call(request, `${CLOUD_FUNCTION_PREFIX}deleteNotification`, params);
  } catch (error) {
    console.error(error.error);
  }
}

export default function* saga() {
  yield takeLeading(GET_NOTIFICATION_LIST_REQUEST, handleGetNotificationList);
  yield takeLatest(GET_UNREAD_NOTIFICATION_COUNT_REQUEST, handleGetUnreadNotificationCountList);
  yield takeLeading(MARK_NOTIFICATION_READ_REQUEST, handleMarkNotificationRead);
  yield takeLeading(READ_ALL_NOTIFICATION_REQUEST, handleReadAllNotification);
  yield takeLeading(DELETE_NOTIFICATION_REQUEST, handleDeleteNotification);
}
