import React from 'react';
import {
  Tooltip, Space, Typography, Badge
} from 'antd';
import _get from 'lodash/get';
import Helpers from 'utils/helpers';

import DefaultBrandImage from 'images/defaultBrand.svg';
import ProfileInfo from 'components/ProfileInfo';

const Columns = {};

Columns.name = {
  title: 'ブランド名',
  key: 'name',
  width: 250,
};

Columns.menuCount = {
  title: '商品件数',
  dataIndex: 'menuCount',
  key: 'menuCount',
  align: 'right',
  width: 120,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.supplierCount = {
  title: '販売者件数',
  dataIndex: 'supplierCount',
  align: 'right',
  width: 120,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.brandOwner = {
  title: '企業名',
  width: 250,
  key: 'brandOwner',
  dataIndex: 'brandOwners',
  render: (record = []) => {
    if (record.length > 1) {
      return (
        <Space>
          <Badge count={record.length} className="badge-count" />
          <Typography.Text>企業</Typography.Text>
        </Space>
      );
    }
    return record.length === 1 && (
      <ProfileInfo
        name={_get(record, '[0].name')}
        email={_get(record, '[0].email')}
        image={_get(record, '[0].image', DefaultBrandImage)}
      />
    );
  },
};

Columns.memo = {
  title: 'メモ',
  dataIndex: 'memo',
  key: 'memo',
  ellipsis: {
    showTitle: false,
  },
  width: 170,
  render: (record) => (
    <Tooltip placement="topLeft" title={record}>
      {record || '-'}
    </Tooltip>
  ),
};

Columns.ID = {
  title: 'ID',
  dataIndex: 'objectId',
  key: 'objectId',
  width: 110,
  fixed: 'left',
};

Columns.managerName = {
  title: '担当者',
  width: 170,
  key: 'managerName',
};

Columns.emergencyPhone = {
  title: '担当者緊急連絡先',
  dataIndex: 'emergencyPhone',
  width: 170,
};

export default Columns;
