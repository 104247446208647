import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ManufacturerDetail from '../ManufacturerDetail';
import ProductInfo from '../ProductInfo';

const RedirectToInAppConfig = (props) => {
  const { manufacturerCode } = _get(props, 'match.params');
  return (
    <Redirect to={`/manufacturers/detail/${manufacturerCode}/product-list`} />
  );
};

const indexRoutes = [
  {
    path: '/manufacturers/detail/:manufacturerCode/product-list',
    component: ProductInfo,
  },
  {
    path: '/manufacturers/detail/:manufacturerCode/in-app',
    component: ManufacturerDetail,
  },
  {
    path: '/manufacturers/detail/:manufacturerCode',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
