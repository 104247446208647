import _get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  reset, isDirty, submit, getFormValues
} from 'redux-form';
import {
  Space, Typography, Button, Affix, Modal
} from 'antd';

import {
  updateManufacturerRequest,
  getManufacturerDetailRequest,
} from 'providers/ManufacturerProvider/actions';

import { USER_ROLE } from 'utils/constants';
import ManufacturerCreateForm from '../../Create/ManufacturerCreateForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const ManufacturerDetail = () => {
  const [updateActionLoading, setUpdateActionLoading] = useState(false);

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const manufacturerDetail = useSelector(
    (state) => state.manufacturerProvider.manufacturerDetail,
    shallowEqual
  );

  const createdAt = _get(manufacturerDetail, 'createdAt');
  const updatedAt = _get(manufacturerDetail, 'updatedAt');
  const manufacturerCode = _get(manufacturerDetail, 'manufacturerCode');

  const dirty = useSelector((state) => isDirty('manufacturerCreateForm')(state));
  const formValues = useSelector((state) => getFormValues('manufacturerCreateForm')(state));

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setUpdateActionLoading(true);
    try {
      await dispatch(updateManufacturerRequest(formValues));
      dispatch(getManufacturerDetailRequest({ manufacturerCode }));
    } catch (err) {
      console.error(err);
    }
    setUpdateActionLoading(false);
  };

  return (
    <>
      <ManufacturerCreateForm
        initialValues={manufacturerDetail}
        onSubmit={handleSubmit}
        updatedAt={updatedAt}
        createdAt={createdAt}
        disabled={currentUser?.role !== USER_ROLE.SUPPLIER}
      />
      {dirty && (
        <Affix offsetBottom={0}>
          <div
            className="full-w flex justify-between items-center"
            style={{
              padding: '16px 16px 16px 24px',
              backgroundColor: '#293137',
            }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8}>
              <Button
                disabled={updateActionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('manufacturerCreateForm')))}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(submit('manufacturerCreateForm'))}
                loading={updateActionLoading}
              >
                送信
              </Button>
            </Space>
          </div>
        </Affix>
      )}
    </>
  );
};

export default ManufacturerDetail;
