import produce from 'immer';
import _get from 'lodash/get';
import _find from 'lodash/find';

import {
  GET_COLLECTION_LIST_REQUEST,
  GET_COLLECTION_LIST_SUCCESS,
  GET_COLLECTION_DETAIL_SUCCESS,
  GET_COLLECTION_MENUS_SUCCESS,
  UPDATE_COLLECTION_SUCCESS,
  GET_RESTAURANT_COLLECTION_LIST_REQUEST,
  GET_RESTAURANT_COLLECTION_LIST_SUCCESS,
} from './constants';

const initialState = {
  collectionList: {},
  restaurantCollectionList: {},
  collectionDetail: {},
  collectionMenus: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTION_LIST_REQUEST:
      state.collectionList = {};
      break;
    case GET_COLLECTION_LIST_SUCCESS:
      state.collectionList = action.payload;
      break;
    case GET_RESTAURANT_COLLECTION_LIST_REQUEST:
      state.restaurantCollectionList = {};
      break;
    case GET_RESTAURANT_COLLECTION_LIST_SUCCESS:
      state.restaurantCollectionList = action.payload;
      break;
    case GET_COLLECTION_DETAIL_SUCCESS:
      state.collectionDetail = action.payload;
      break;
    case GET_COLLECTION_MENUS_SUCCESS:
      state.collectionMenus = action.payload.menus;
      break;

    case UPDATE_COLLECTION_SUCCESS: {
      const { objectId, status } = action.payload;

      state.collectionDetail = {
        ...state.collectionDetail,
        status,
      };

      _find(_get(state, 'collectionList.list'), (collectionItem) => {
        if (collectionItem.objectId === objectId) {
          collectionItem.status = status;
          return true;
        }
        return false;
      });

      break;
    }

    default:
  }
  return state;
});

export default reducer;
