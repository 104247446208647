import _get from 'lodash/get';
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { Card } from 'antd';

import Table from 'components/Table';

import Columns from './Columns';

const SupplierOrderList = (props) => {
  const { pageControl, onTableChange, tableLoading } = props;

  const orderListForBrandOwner = useSelector(
    (state) => state.brandOwnerProvider.orderListForBrandOwner,
    shallowEqual
  );

  const { page, limit } = pageControl;

  const columns = [
    {
      ...Columns.supplierInfo,
      fixed: 'left',
    },
    Columns.numberOfOrderEachSupplier,
    Columns.totalBrandFee,
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <Table
        columns={columns}
        data={_get(orderListForBrandOwner, 'list', []).map((o) => ({
          ...o,
          key: o.objectId,
        }))}
        total={_get(orderListForBrandOwner, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 600 }}
      />
    </Card>
  );
};

SupplierOrderList.propTypes = {
  pageControl: PropTypes.func,
  onTableChange: PropTypes.func,
  tableLoading: PropTypes.bool,
};

export default SupplierOrderList;
