export const GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST = 'AnnouncementProvider/GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST';
export const GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS = 'AnnouncementProvider/GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS';
export const GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR = 'AnnouncementProvider/GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR';

export const CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST = 'AnnouncementProvider/CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST';
export const CREATE_RESTAURANT_ANNOUNCEMENT_SUCCESS = 'AnnouncementProvider/CREATE_RESTAURANT_ANNOUNCEMENT_SUCCESS';
export const CREATE_RESTAURANT_ANNOUNCEMENT_ERROR = 'AnnouncementProvider/CREATE_RESTAURANT_ANNOUNCEMENT_ERROR';

export const GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST = 'AnnouncementProvider/GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST';
export const GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS = 'AnnouncementProvider/GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS';
export const GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR = 'AnnouncementProvider/GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR';

export const DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST = 'AnnouncementProvider/DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST';
export const DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS = 'AnnouncementProvider/DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS';
export const DELETE_RESTAURANT_ANNOUNCEMENT_ERROR = 'AnnouncementProvider/DELETE_RESTAURANT_ANNOUNCEMENT_ERROR';

export const EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST = 'GuestInformationProvider/EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST';
export const EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_SUCCESS = 'GuestInformationProvider/EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_SUCCESS';
export const EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_ERROR = 'GuestInformationProvider/EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_ERROR';
