import { call, put, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getGenreListSuccess,
  getGenreListError,
  addTagError,
  addTagSuccess,
  updateTagError,
  updateTagSuccess,
  getTagDetailsSuccess,
  getTagDetailsError,
  getTagListSuccess,
  getTagListError,
  changeTagStatusSuccess,
  changeTagStatusError,
} from './actions';
import {
  GET_GENRE_LIST_REQUEST, ADD_TAG_REQUEST, UPDATE_TAG_REQUEST, GET_TAG_DETAILS_REQUEST, GET_TAG_LIST_REQUEST, CHANGE_TAG_STATUS_REQUEST
} from './constants';

export function* handleGetGenreList(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getGenreList`,
      action.data
    );
    yield put(getGenreListSuccess(response.result));
  } catch (error) {
    yield put(getGenreListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleCreateTag(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createFreeTag`,
      action.payload
    );
    yield put(addTagSuccess(response.result, action.meta));
    toastMessage.success({ message: `タグ${action.payload?.name} を作成しました。` });
  } catch (error) {
    yield put(addTagError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateTag(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateTag`,
      action.payload
    );
    yield put(updateTagSuccess(response.result, action.meta));
    toastMessage.success({ message: '保存しました。', description: '変更した内容を保存しました。' });
  } catch (error) {
    yield put(updateTagError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetTagDetails(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getTagDetail`,
      action.payload
    );
    yield put(getTagDetailsSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getTagDetailsError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetTagList(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getTagList`,
      action.payload
    );
    yield put(getTagListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getTagListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleChangeTagStatus(action) {
  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateTag`,
      action.payload
    );
    yield put(changeTagStatusSuccess(action.payload, action.meta));
    toastMessage.success({ message: '保存しました。', description: '変更した内容を保存しました。' });
  } catch (error) {
    yield put(changeTagStatusError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(GET_GENRE_LIST_REQUEST, handleGetGenreList);
  yield takeLeading(ADD_TAG_REQUEST, handleCreateTag);
  yield takeLeading(UPDATE_TAG_REQUEST, handleUpdateTag);
  yield takeLeading(GET_TAG_DETAILS_REQUEST, handleGetTagDetails);
  yield takeLeading(GET_TAG_LIST_REQUEST, handleGetTagList);
  yield takeLeading(CHANGE_TAG_STATUS_REQUEST, handleChangeTagStatus);
}
