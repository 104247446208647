import _isNumber from 'lodash/isNumber';
import _isInteger from 'lodash/isInteger';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import Constants from 'utils/constants';
import Regexs from 'utils/regex';
import config from 'utils/config';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];

const validate = (values) => {
  const errors = {};

  if (!values.name?.trim()) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.nameKatakana?.trim()) {
    errors.nameKatakana = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regexs.REGX_KATAKANA.test(values.nameKatakana?.trim())) {
    errors.nameKatakana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.category) {
    errors.category = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.subcategory) {
    errors.subcategory = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (values.isBasic && _isEmpty(values.genres)) {
    errors.genreIds = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (values?.isBasic && !_isInteger(values?.order)) {
    errors.order = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (values.brand && !_isInteger(values.brandFee)) {
    errors.brandFee = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    (values.standard?.trim() || values.amount?.trim())
    && !values.unit?.trim()
  ) {
    errors.unit = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!_isEmpty(values.images)) {
    if (values.images.length > 8) {
      errors.images = Constants.ERROR_MESSAGE.INVALID;
    }
    _forEach(values.images, (image) => {
      if (image.originFileObj) {
        if (!acceptedFileExtensions.includes(image.originFileObj.type)) {
          errors.images = 'ファイル拡張子が正しくありません';
        }
        if (image.originFileObj.size > 5e6) {
          // Limit size to 5MB
          errors.images = `${5e6 / 1e6}MB以下のファイルを指定してください`;
        }
      }
    });
  }
  if (!_isEmpty(values.snsFiles)) {
    if (values.snsFiles.length > 8) {
      errors.snsFiles = Constants.ERROR_MESSAGE.INVALID;
    }
    _forEach(values.snsFiles, (file) => {
      if (file.originFileObj) {
        if (![...acceptedFileExtensions, 'application/pdf'].includes(file.originFileObj.type)) {
          errors.snsFiles = 'ファイル拡張子が正しくありません';
        }
        if (file.originFileObj.size > config.React_APP_MAX_FILE_SIZE) {
          // Limit size to 50MB
          errors.snsFiles = `${config.React_APP_MAX_FILE_SIZE / 1e6}MB以下のファイルを指定してください`;
        }
      }
    });
  }
  if (!_isInteger(values.leadTime)) {
    errors.leadTime = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    values.maxLimitOrder
    && values.minLimitOrder
    && values.maxLimitOrder < values.minLimitOrder
  ) {
    errors.minLimitOrder = '最小発注単位は最大発注単位より小さい値を設定してください';
    errors.maxLimitOrder = '最大発注単位は最小単位より大きい値を設定してください';
  }

  if (_isEmpty(values.prices)) {
    errors.prices = 'At least one member must be entered';
  } else {
    const priceArrErrors = [];
    values.prices.forEach((price, index) => {
      const priceError = {};
      if (!_isInteger(price.priceBeforeTax)) {
        priceError.priceBeforeTax = Constants.ERROR_MESSAGE.REQUIRED;
        priceArrErrors[index] = priceError;
      }
      if (!price.name?.trim()) {
        priceError.name = Constants.ERROR_MESSAGE.REQUIRED;
        priceArrErrors[index] = priceError;
      }
      if (!_isNumber(price.taxRate)) {
        priceError.taxRate = Constants.ERROR_MESSAGE.INVALID;
        priceArrErrors[index] = priceError;
      }
    });
    if (!_isEmpty(priceArrErrors)) {
      errors.prices = priceArrErrors;
    }
  }

  if (values.stock_quantity && !_isInteger(values.stock_quantity)) {
    errors.stock_quantity = Constants.ERROR_MESSAGE.REQUIRED;
  }

  if (values?.warehouse_airLogiIntegrated) {
    if (_isEmpty(values?.freeCode)) {
      errors.freeCode = 'エアロジ自動連携商品の自社コードを入力してください。';
    }
    if (
      values?.freeCode
      && !Regexs.FREE_CODE_FOR_AIR_LOGI.test(values?.freeCode)
    ) {
      errors.freeCode = 'エアロジ自動連携商品の自社コードは、先頭が A・B・C のいずれかで、続けて6桁の半角数字または半角アルファベット大文字で入力してください。';
    }
  }

  if (
    values?.warehouse_directShipment
    && !values?.warehouse_airLogiIntegrated
  ) {
    if (_isEmpty(values?.manufacturer)) {
      errors.manufacturer = '直送品の製造会社を入力してください。';
    }

    if (_isEmpty(values?.freeCode)) {
      errors.freeCode = '直送品の自社コードを入力してください。';
    }

    if (values?.freeCode && !Regexs.FREE_CODE.test(values?.freeCode)) {
      errors.freeCode = '直送品の自社コードは、先頭がTで、続けて6桁の半角数字または半角アルファベット大文字で入力してください。';
    }
  }

  return errors;
};

export default validate;
