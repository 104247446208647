import produce from 'immer';

import { DO_REFRESH } from './constants';

const initialState = {
  refreshValue: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case DO_REFRESH:
      state.refreshValue[action.data.target] = action.data.value || new Date().valueOf();
      break;

    default:
  }
  return state;
});

export default reducer;
