import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import { reduxForm, Field } from 'redux-form';
import '@ant-design/compatible/assets/index.css';

import { TextField } from 'components/Form';
import validate from './validate';

const DisableRestaurantOwnerFrom = ({
  handleSubmit,
  onCancel,
  invalid,
  submitLoading,
}) => (
  <Form layout="vertical" onSubmit={handleSubmit}>
    <div className="field-container">
      <Field
        name="text"
        label='確認のため下記テキストボックスに"無効にする"と入力してください'
        component={TextField}
      />
    </div>
    <div id="disable-account-action">
      <Space
        direction="horizontal"
        size="middle"
        className="full-w flex justify-end"
      >
        <Button
          className="form-button"
          onClick={onCancel}
          disabled={submitLoading}
        >
          キャンセル
        </Button>
        <Button
          className="form-button"
          type="primary"
          danger
          htmlType="submit"
          disabled={invalid}
          loading={submitLoading}
        >
          無効にする
        </Button>
      </Space>
    </div>
  </Form>
);

DisableRestaurantOwnerFrom.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  invalid: PropTypes.any,
  submitLoading: PropTypes.bool,
};

export default reduxForm({ form: 'DisableRestaurantOwnerFrom', validate })(
  DisableRestaurantOwnerFrom
);
