import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Popover, Badge } from 'antd';

import { USER_ROLE } from 'utils/constants';

import { getUnreadNotificationCountRequest } from 'providers/NotificationProvider/actions';

import NotificationIcon from 'images/icon-notification.svg';
import NotificationWrapper from './NotificationWrapper';

const NotificationPopover = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  if (
    ![USER_ROLE.SUPPLIER, USER_ROLE.RESTAURANT_OWNER].includes(
      currentUser?.role
    )
  ) {
    return null;
  }

  const dispatch = useDispatch();

  const [notificationVisible, setNotificationVisible] = useState(false);

  const unreadNotificationCount = useSelector(
    (state) => state.notificationProvider.unreadCount,
    shallowEqual
  );

  useEffect(() => {
    const getUnreadNotificationCount = async () => {
      await dispatch(getUnreadNotificationCountRequest());
    };

    getUnreadNotificationCount();
  }, []);

  return (
    <Popover
      placement="bottomRight"
      content={(
        <NotificationWrapper
          closePopup={() => setNotificationVisible(false)}
          popupVisible={notificationVisible}
        />
      )}
      trigger="click"
      visible={notificationVisible}
      onVisibleChange={setNotificationVisible}
      getPopupContainer={(t) => t.parentNode}
    >
      <Badge count={unreadNotificationCount}>
        <img
          width={24}
          height={24}
          alt="notification-icon"
          className="cursor-pointer"
          src={NotificationIcon}
        />
      </Badge>
    </Popover>
  );
};

export default NotificationPopover;
