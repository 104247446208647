import {
  GET_RELATED_PRODUCT_LIST_REQUEST,
  GET_RELATED_PRODUCT_LIST_SUCCESS,
  GET_RELATED_PRODUCT_LIST_ERROR,
  ADD_PRODUCT_RELATION_REQUEST,
  ADD_PRODUCT_RELATION_SUCCESS,
  ADD_PRODUCT_RELATION_ERROR,
  REMOVE_PRODUCT_RELATION_REQUEST,
  REMOVE_PRODUCT_RELATION_SUCCESS,
  REMOVE_PRODUCT_RELATION_ERROR,
} from './constants';

export function getRelatedProductListRequest(payload) {
  return {
    type: GET_RELATED_PRODUCT_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRelatedProductListSuccess(payload, meta) {
  return { type: GET_RELATED_PRODUCT_LIST_SUCCESS, payload, meta };
}

export function getRelatedProductListError(payload, meta) {
  return {
    type: GET_RELATED_PRODUCT_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addProductRelationRequest(payload) {
  return {
    type: ADD_PRODUCT_RELATION_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function addProductRelationSuccess(payload, meta) {
  return { type: ADD_PRODUCT_RELATION_SUCCESS, payload, meta };
}

export function addProductRelationError(payload, meta) {
  return {
    type: ADD_PRODUCT_RELATION_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeProductRelationRequest(payload) {
  return {
    type: REMOVE_PRODUCT_RELATION_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeProductRelationSuccess(payload, meta) {
  return { type: REMOVE_PRODUCT_RELATION_SUCCESS, payload, meta };
}

export function removeProductRelationError(payload, meta) {
  return {
    type: REMOVE_PRODUCT_RELATION_ERROR,
    payload,
    meta,
    error: true,
  };
}
