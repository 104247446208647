import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useState, useEffect } from 'react';
import { Field, getFormValues } from 'redux-form';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { SelectField } from 'components/Form';
import { getTagListRequest } from 'providers/GenreProvider/actions';

import './style.less';

const TagField = () => {
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const tagList = useSelector(
    (state) => state.genreProvider.tagList,
    shallowEqual
  );

  const formValues = useSelector((state) => getFormValues('productCreateForm')(state));

  const fetchData = useCallback(async (isFirstLoad) => {
    setLoading(true);
    try {
      const result = await dispatch(getTagListRequest({
        orderBy: 'order',
      }));
      if (isFirstLoad) {
        setTags(result);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData(true);
  }, [fetchData]);

  const getGenreListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getTagListRequest({
          ...data,
          orderBy: 'order',
        })
      ),
      500
    ),
    []
  );

  const onSearchGenreKeyword = (value) => {
    setIsSearch(true);
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getGenreListWithDelay(data);
  };
  const tagIds = formValues?.tagIds || [];
  const selectedTag = formValues?.tagIds?.map((item) => tags?.find((tag) => tag.objectId === item)) || [];
  const sortValue = selectedTag?.sort((a, b) => a?.order - b?.order) || [];

  return (
    <div
      className={`tag-field-wrapper ${_isEmpty(tagIds) && 'empty-tag-field'}`}
    >
      <Field
        showSearch
        filterOption={false}
        name="tagIds"
        component={SelectField}
        options={tagList}
        label="Tag"
        mode="multiple"
        requiredSpace
        onSearch={onSearchGenreKeyword}
        placeholder="Select tag"
        tagRender={() => null}
        onFocus={() => {
          if (isSearch) {
            fetchData();
            setIsSearch(false);
          }
        }}
        loading={loading}
      />
      {!_isEmpty(tags) && !_isEmpty(tagIds) && (
        <Field
          name="tagIds"
          component={SelectField}
          options={tags}
          label=" "
          mode="multiple"
          requiredSpace
          open={false}
          showSearch={false}
          className="tag-field"
          allowClear={false}
          sortValue={sortValue?.map((item) => item?.objectId)}
        />
      )}
    </div>
  );
};

TagField.propTypes = {
};

export default TagField;
