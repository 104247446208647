import React, { useState } from 'react';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { submit } from 'redux-form';
import { push, replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import {
  Modal, Button, Card, Space, Row, Col, Anchor, Affix
} from 'antd';

import { createMenuItemRequest } from 'providers/MenuProvider/actions';
import PageHeader from 'components/PageHeader';

import { PRODUCT_PRICE_TYPE } from 'utils/constants';
import ProductCreateForm from './ProductCreateForm';

import './style.less';

const { confirm } = Modal;
const { Link } = Anchor;

const ProductCreateDialog = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [createLoading, setCreateLoading] = useState(false);
  const stateRef = React.useRef(location.state);

  const { isBasic } = useSelector(
    (state) => _get(state.form, 'productCreateForm.values', {}),
    shallowEqual
  );

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';

  const createMenuItem = async (values) => {
    setCreateLoading(true);
    try {
      let params = { ...values };
      if (values?.warehouse_airLogiIntegrated) {
        params = _omit(params, ['stock_quantity', 'warehouse_directShipment']);
      }
      if (values.isBasic) {
        params = _omit(params, ['collectionIds']);
        const discountPrice = values?.prices.find(
          (price) => price?.type === PRODUCT_PRICE_TYPE.DISCOUNT
        );
        params.discountPrice = {
          priceBeforeTax: discountPrice?.priceBeforeTax,
          taxRate: discountPrice?.taxRate,
          name: discountPrice?.name,
          isSelected: discountPrice?.isSelected,
        };
      } else {
        params = _omit(params, ['membership_tier', 'order']);
      }

      params.prices = values?.prices.filter(
        (price) => price?.type !== PRODUCT_PRICE_TYPE.DISCOUNT
      );

      await dispatch(
        createMenuItemRequest({
          ...params,
          stock_isSoldOut: !params?.isInStock,
          mainAllergies: Object.keys(params?.mainAllergies || {}),
          subAllergies: Object.keys(params?.subAllergies || {}),
        })
      );
      dispatch(push('/products'));
    } catch (error) {
      console.error(error);
    }
    setCreateLoading(false);
  };

  const handleCreateMenu = (value) => {
    if (!_isEmpty(value.mainAllergies) || !_isEmpty(value.subAllergies)) {
      confirm({
        title: 'アレルギー情報が変更されています。保存してよろしいでしょうか？',
        okText: '保存して公開する',
        okType: 'primary',
        cancelText: '再確認する',
        onOk: () => createMenuItem(value),
      });
      return;
    }
    createMenuItem(value);
  };

  const showConfirm = (message, actionFn) => {
    confirm({
      title: message,
      okText: 'はい',
      okType: 'primary',
      cancelText: 'いいえ',
      onOk: () => {
        actionFn();
      },
    });
  };

  const breadcrumbItems = [
    {
      path: '/products',
      breadcrumbName: '商品',
    },
    {
      breadcrumbName: '商品の追加',
    },
  ];

  const prices = [
    {
      name: '基本価格',
      priceBeforeTax: 0,
      taxRate: 0.08,
      type: 'MASTER',
    },
  ];

  if (isShikomelSupplier) {
    prices.push({
      name: 'Discount',
      priceBeforeTax: 0,
      taxRate: 0.08,
      type: 'DISCOUNT',
      isSelected: false,
    });
  }

  const handleGoBack = () => {
    if (stateRef.current?.from) {
      dispatch(replace(stateRef.current.from));
      return;
    }
    dispatch(replace('/products'));
  };

  return (
    <div id="basic-info">
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleGoBack}
      />
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
      >
        <Row gutter={32}>
          <Col span={20}>
            <ProductCreateForm
              onSubmit={handleCreateMenu}
              initialValues={{
                isBasic: false,
                prices,
                isInStock: true,
                warehouse_directShipment: false,
                warehouse_airLogiIntegrated: !!isShikomelSupplier,
                membership_tier: 1,
                order: 5000,
                isAllowDownloadImage: !!isShikomelSupplier,
              }}
              isCreateForm
            />
          </Col>
          <Col span={4} id="product-create-anchor">
            <Anchor offsetTop={80}>
              <div className="anchor-item">
                <Link href="#basic-info" title="商品の追加" />
              </div>
              <div className="anchor-item">
                <Link href="#productOrganization" title="商品の管理" />
              </div>
              <div className="anchor-item">
                <Link href="#order-info" title="商品の納期および発注数量" />
              </div>
              <div className="anchor-item">
                <Link href="#addition-info" title="商品の追加情報" />
              </div>
              {isBasic && (
                <div className="anchor-item">
                  <Link href="#related-products" title="関連商品" />
                </div>
              )}
              {isShikomelSupplier && (
                <div className="anchor-item">
                  <Link href="#sns" title="Promotion Materials" />
                </div>
              )}
            </Anchor>
          </Col>
        </Row>
      </Card>
      <Affix offsetBottom={0} className="full-w">
        <Row
          className="full-w"
          style={{
            backgroundColor: '#293137',
          }}
        >
          <Col span={20}>
            <Space
              size={8}
              className="full-w flex justify-end"
              style={{ padding: '16px' }}
            >
              <Button
                type="default"
                disabled={createLoading}
                onClick={() => showConfirm('中断してよろしいですか', () => dispatch(push('/products'))
                )}
              >
                キャンセル
              </Button>
              <Button
                type="primary"
                loading={createLoading}
                onClick={() => dispatch(submit('productCreateForm'))}
                style={{ marginRight: '16px' }}
              >
                保存
              </Button>
            </Space>
          </Col>
        </Row>
      </Affix>
    </div>
  );
};

export default ProductCreateDialog;
