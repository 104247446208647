import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Card, Space, Button } from 'antd';
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';
import { SelectField, TextField } from 'components/Form';
import { getPrefectureListRequest } from 'providers/PrefectureProvider/actions';
import validate from './validate';

const BillingForm = ({ handleSubmit, billingDetail }) => {
  const dispatch = useDispatch();

  const prefectureList = useSelector(
    (state) => state.prefectureProvider.prefectureList,
    shallowEqual
  );

  useEffect(() => {
    if (_isEmpty(prefectureList)) {
      dispatch(getPrefectureListRequest());
    }
  }, []);

  const handleUpdateBilling = () => {
    _forEach(billingDetail, (value, key) => {
      dispatch(change('billingForm', key, value));
    });
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      colon={false}
      layout="horizontal"
      onSubmit={handleSubmit}
    >
      <Space size={40} direction="vertical" className="full-w">
        <Space size={16} direction="vertical" className="full-w">
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="請求情報の住所"
            extra={(
              <Button onClick={handleUpdateBilling}>
                店舗情報と同じ内容を設定する
              </Button>
            )}
          >
            <Field
              label="宛先名"
              name="billingName"
              description="※企業名または店舗名"
              component={TextField}
              maxLength={50}
              required
            />
            <Field
              label="郵便番号"
              name="billingPostalCode"
              component={TextField}
              maxLength={7}
              required
            />
            <Field
              label="都道府県"
              name="billingPrefecture"
              component={SelectField}
              loading={_isEmpty(prefectureList)}
              options={_map(prefectureList, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="市区町村番地"
              description="市区町村"
              name="billingAddress"
              component={TextField}
              maxLength={50}
              required
            />
            <Field
              label="ビル名等以降の住所"
              description="任意"
              name="billingBuildingName"
              component={TextField}
              maxLength={50}
              requiredSpace
            />
            <Field
              label="電話番号"
              name="billingPhone"
              component={TextField}
              maxLength={11}
              required
            />
            <Field
              label="担当者名"
              name="billingManagerName"
              component={TextField}
              maxLength={50}
              required
            />
          </Card>
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="振込元口座"
          >
            <Field
              label="担当者名"
              description="任意"
              name="bankAccountName"
              component={TextField}
              maxLength={50}
            />
            <Field
              label="振込元口座名義カナ"
              description="任意"
              name="bankAccountNameKana"
              component={TextField}
              maxLength={50}
            />
          </Card>
        </Space>
      </Space>
    </Form>
  );
};

BillingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  billingDetail: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'billingForm',
  touchOnChange: true,
  validate,
})(BillingForm);
