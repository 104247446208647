import produce from 'immer';

import {
  GET_GUEST_INFORMATION_LIST_REQUEST,
  GET_GUEST_INFORMATION_LIST_SUCCESS,
  GET_GUEST_INFORMATION_LIST_ERROR,
} from './constants';

const initialState = {
  guestInformationList: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_GUEST_INFORMATION_LIST_REQUEST:
      break;

    case GET_GUEST_INFORMATION_LIST_SUCCESS:
      state.guestInformationList = action.payload;
      break;

    case GET_GUEST_INFORMATION_LIST_ERROR:
      state.guestInformationList = {};
      break;

    default:
  }

  return state;
});

export default reducer;
