import _get from 'lodash/get';
import produce from 'immer';

import {
  GET_RESTAURANT_MENU_ITEM_REQUEST,
  GET_RESTAURANT_MENU_ITEM_SUCCESS,
  GET_RESTAURANT_MENU_ITEM_ERROR,
  REMOVE_RESTAURANT_MENU_ITEM_REQUEST,
  REMOVE_RESTAURANT_MENU_ITEM_SUCCESS,
  REMOVE_RESTAURANT_MENU_ITEM_ERROR,
  ASSIGN_RESTAURANT_MENU_ITEM_REQUEST,
  ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS,
  ASSIGN_RESTAURANT_MENU_ITEM_ERROR,
  SEND_RESTAURANT_PASSWORD_RESET_EMAIL,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_SUCCESS,
  GET_RESTAURANT_LIST_ERROR,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS,
  GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST,
  GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS,
  GET_RESTAURANT_DETAIL_REQUEST,
  GET_RESTAURANT_DETAIL_SUCCESS,
  GET_RESTAURANT_DETAIL_ERROR,
  RESET_ALL_ASSIGNED_MENU_ITEM_LIST,
  GET_RESTAURANT_SELECT_OPTIONS_SUCCESS,
  UPDATE_INFO_OEM_SUCCESS,
} from './constants';

const initialState = {
  dataLoading: false,
  actionLoading: false,
  error: false,
  restaurantList: {},
  restaurantDetail: {},
  restaurantMenuItemList: [],
  restaurantMenuItemTotal: 0,
  allAssignedMenuItemList: [],
  allAssignedMenuItemTotal: 0,
  selectOptions: {},
  restaurantListForSupplier: {},
  restaurantListForOperator: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_MENU_ITEM_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_RESTAURANT_MENU_ITEM_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      if (action.payload.limit === 1000) {
        state.allAssignedMenuItemList = action.payload.list;
        state.allAssignedMenuItemTotal = action.payload.total;
      } else {
        state.restaurantMenuItemList = action.payload.list;
        state.restaurantMenuItemTotal = action.payload.total;
      }
      break;

    case GET_RESTAURANT_MENU_ITEM_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      state.restaurantMenuItemList = [];
      state.restaurantMenuItemTotal = 0;
      break;

    case REMOVE_RESTAURANT_MENU_ITEM_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case REMOVE_RESTAURANT_MENU_ITEM_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      state.restaurantMenuItemList.splice(_get(action, 'payload.index'), 1);
      state.restaurantMenuItemTotal -= 1;
      break;

    case REMOVE_RESTAURANT_MENU_ITEM_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      break;

    case ASSIGN_RESTAURANT_MENU_ITEM_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      break;

    case ASSIGN_RESTAURANT_MENU_ITEM_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      break;

    case SEND_RESTAURANT_PASSWORD_RESET_EMAIL:
      break;

    case GET_RESTAURANT_LIST_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_RESTAURANT_LIST_SUCCESS: {
      state.dataLoading = false;
      state.error = false;
      state.restaurantList = action.payload;
      break;
    }

    case GET_RESTAURANT_LIST_ERROR:
      state.dataLoading = false;
      state.error = action.payload;
      break;

    case GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST:
      state.restaurantListForSupplier = {};
      break;

    case GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS: {
      state.restaurantListForSupplier = action.payload;
      break;
    }

    case GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST:
      state.restaurantListForOperator = {};
      break;

    case GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS: {
      state.restaurantListForOperator = action.payload;
      break;
    }

    case GET_RESTAURANT_DETAIL_REQUEST:
      state.restaurantDetail = {};
      state.error = false;
      break;

    case GET_RESTAURANT_DETAIL_SUCCESS:
      state.restaurantDetail = action.payload;
      break;

    case GET_RESTAURANT_DETAIL_ERROR:
      state.error = action.payload;
      break;

    case RESET_ALL_ASSIGNED_MENU_ITEM_LIST:
      state.allAssignedMenuItemList = [];
      state.allAssignedMenuItemTotal = 0;
      break;

    case GET_RESTAURANT_SELECT_OPTIONS_SUCCESS:
      state.selectOptions = action.payload;
      break;

    case UPDATE_INFO_OEM_SUCCESS:
      state.restaurantDetail.infoOEM.isOriginalRecipeConsultationSupport = action?.payload?.isOriginalRecipeConsultationSupport;
      break;

    default:
  }
  return state;
});

export default reducer;
