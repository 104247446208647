import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Col, Row, Card, Descriptions, Typography, Divider
} from 'antd';

const { Item } = Descriptions;
const { Text } = Typography;

const SupplierDetail = () => {
  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const {
    postalCode, prefecture, address, building, phone, fax
  } = supplierDetail;

  const renderAddressDescription = (
    _postalCode,
    _prefecture,
    _address,
    _building,
    _phone,
    _fax
  ) => (
    <Item>
      <div>
        <div>{_postalCode}</div>
        <div>
          {_prefecture} {_address}, {_building}
        </div>
        <div>店舗電話番号: {_phone}</div>
        <div>店舗FAX番号: {_fax}</div>
      </div>
    </Item>
  );

  return (
    <Row gutter={32} id="supplier-info-container">
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Card className="highlight-card" title="店舗情報" bordered={false}>
          <Descriptions
            title="配送に関する情報"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            {renderAddressDescription(
              postalCode,
              prefecture,
              address,
              building,
              phone,
              fax
            )}
          </Descriptions>
          <Divider />
          <Descriptions
            title="担当者の連絡先"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            <Item label="担当者">
              <div>{supplierDetail?.managerName}</div>
              <Text>{supplierDetail?.managerNameKana}</Text>
            </Item>
            <Item label="担当者緊急連絡先">
              {supplierDetail?.emergencyPhone}
            </Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default SupplierDetail;
