import produce from 'immer';

import {
  GET_INVITATION_EMAILS_REQUEST,
  GET_INVITATION_EMAILS_SUCCESS,
  GET_INVITATION_EMAILS_ERROR,
} from './constants';

const initialState = {
  dataLoading: false,
  error: false,
  invitationEmails: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_INVITATION_EMAILS_REQUEST:
      state.invitationEmails = {};
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_INVITATION_EMAILS_SUCCESS:
      state.invitationEmails = action.payload;
      state.dataLoading = false;
      break;

    case GET_INVITATION_EMAILS_ERROR:
      state.dataLoading = false;
      state.error = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
