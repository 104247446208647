import {
  GET_MENU_ITEM_LIST_REQUEST,
  GET_MENU_ITEM_LIST_SUCCESS,
  GET_MENU_ITEM_LIST_ERROR,
  RESET_MENU_ITEM_LIST,
  PAGING_GET_MENU_ITEM_LIST_REQUEST,
  PAGING_GET_MENU_ITEM_LIST_SUCCESS,
  PAGING_GET_MENU_ITEM_LIST_ERROR,
  GET_MENU_ITEM_DETAIL_REQUEST,
  GET_MENU_ITEM_DETAIL_SUCCESS,
  GET_MENU_ITEM_DETAIL_ERROR,
  CREATE_MENU_ITEM_REQUEST,
  CREATE_MENU_ITEM_SUCCESS,
  CREATE_MENU_ITEM_ERROR,
  UPDATE_MENU_ITEM_REQUEST,
  UPDATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_ERROR,
  GET_UNIT_LIST_REQUEST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_LIST_ERROR,
  CHANGE_MENU_ITEM_STATUS_REQUEST,
  CHANGE_MENU_ITEM_STATUS_SUCCESS,
  CHANGE_MENU_ITEM_STATUS_ERROR,
  EXPORT_MENU_LIST_REQUEST,
  EXPORT_MENU_LIST_SUCCESS,
  EXPORT_MENU_LIST_ERROR,
  CHANGE_SOLD_OUT_STATUS_ERROR,
  CHANGE_SOLD_OUT_STATUS_REQUEST,
  CHANGE_SOLD_OUT_STATUS_SUCCESS,
  REMOVE_PRODUCT_RANKING_ERROR,
  REMOVE_PRODUCT_RANKING_REQUEST,
  REMOVE_PRODUCT_RANKING_SUCCESS,
  REORDER_PRODUCT_RANKING_ERROR,
  REORDER_PRODUCT_RANKING_REQUEST,
  REORDER_PRODUCT_RANKING_SUCCESS,
  ADD_PRODUCT_RANKING_REQUEST,
  ADD_PRODUCT_RANKING_SUCCESS,
  ADD_PRODUCT_RANKING_ERROR,
} from './constants';

export function getMenuItemListRequest(payload) {
  return { type: GET_MENU_ITEM_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getMenuItemListSuccess(payload, meta) {
  return { type: GET_MENU_ITEM_LIST_SUCCESS, payload, meta };
}

export function getMenuItemListError(payload, meta) {
  return {
    type: GET_MENU_ITEM_LIST_ERROR,
    error: true,
    payload,
    meta,
  };
}

export function resetMenuItemList() {
  return { type: RESET_MENU_ITEM_LIST };
}

export function pagingGetMenuItemListRequest(payload) {
  return {
    type: PAGING_GET_MENU_ITEM_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function pagingGetMenuItemListSuccess(payload, meta) {
  return { type: PAGING_GET_MENU_ITEM_LIST_SUCCESS, payload, meta };
}

export function pagingGetMenuItemListError(payload, meta) {
  return {
    type: PAGING_GET_MENU_ITEM_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getMenuItemDetailRequest(payload) {
  return { type: GET_MENU_ITEM_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getMenuItemDetailSuccess(payload, meta) {
  return { type: GET_MENU_ITEM_DETAIL_SUCCESS, payload, meta };
}

export function getMenuItemDetailError(payload, meta) {
  return {
    type: GET_MENU_ITEM_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function createMenuItemRequest(payload) {
  return { type: CREATE_MENU_ITEM_REQUEST, payload, meta: { thunk: true } };
}

export function createMenuItemSuccess(payload, meta) {
  return { type: CREATE_MENU_ITEM_SUCCESS, payload, meta };
}

export function createMenuItemError(payload, meta) {
  return {
    type: CREATE_MENU_ITEM_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateMenuItemRequest(payload) {
  return { type: UPDATE_MENU_ITEM_REQUEST, payload, meta: { thunk: true } };
}

export function updateMenuItemSuccess(payload, meta) {
  return { type: UPDATE_MENU_ITEM_SUCCESS, payload, meta };
}

export function updateMenuItemError(payload, meta) {
  return {
    type: UPDATE_MENU_ITEM_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getUnitListRequest(data) {
  return { type: GET_UNIT_LIST_REQUEST, data };
}

export function getUnitListSuccess(data) {
  return { type: GET_UNIT_LIST_SUCCESS, data };
}

export function getUnitListError(error) {
  return { type: GET_UNIT_LIST_ERROR, error };
}

export function changeMenuItemStatusRequest(payload) {
  return {
    type: CHANGE_MENU_ITEM_STATUS_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function changeMenuItemStatusSuccess(payload, meta) {
  return { type: CHANGE_MENU_ITEM_STATUS_SUCCESS, payload, meta };
}

export function changeMenuItemStatusError(payload, meta) {
  return {
    type: CHANGE_MENU_ITEM_STATUS_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function changeSoldOutStatusRequest(payload) {
  return {
    type: CHANGE_SOLD_OUT_STATUS_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function changeSoldOutStatusSuccess(payload, meta) {
  return { type: CHANGE_SOLD_OUT_STATUS_SUCCESS, payload, meta };
}

export function changeSoldOutStatusError(payload, meta) {
  return {
    type: CHANGE_SOLD_OUT_STATUS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function exportMenuListRequest(payload) {
  return { type: EXPORT_MENU_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function exportMenuListSuccess(payload, meta) {
  return { type: EXPORT_MENU_LIST_SUCCESS, payload, meta };
}

export function exportMenuListError(payload, meta) {
  return {
    type: EXPORT_MENU_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeProductRankingRequest(payload) {
  return {
    type: REMOVE_PRODUCT_RANKING_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeProductRankingSuccess(payload, meta) {
  return { type: REMOVE_PRODUCT_RANKING_SUCCESS, payload, meta };
}

export function removeProductRankingError(payload, meta) {
  return {
    type: REMOVE_PRODUCT_RANKING_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function reorderProductRankingRequest(payload) {
  return {
    type: REORDER_PRODUCT_RANKING_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function reorderProductRankingSuccess(payload, meta) {
  return { type: REORDER_PRODUCT_RANKING_SUCCESS, payload, meta };
}

export function reorderProductRankingError(payload, meta) {
  return {
    type: REORDER_PRODUCT_RANKING_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addRankingProductRequest(payload) {
  return {
    type: ADD_PRODUCT_RANKING_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function addRankingProductSuccess(payload, meta) {
  return { type: ADD_PRODUCT_RANKING_SUCCESS, payload, meta };
}

export function addRankingProductError(payload, meta) {
  return {
    type: ADD_PRODUCT_RANKING_ERROR,
    payload,
    meta,
    error: true,
  };
}
