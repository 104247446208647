import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import produce from 'immer';

import {
  GET_NOTIFICATION_LIST_REQUEST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERROR,
  GET_UNREAD_NOTIFICATION_COUNT_REQUEST,
  GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
  MARK_NOTIFICATION_READ_REQUEST,
  READ_ALL_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST
} from './constants';

const initialState = {
  loading: false,
  error: false,
  notificationList: [],
  unreadCount: 0,
  hasMore: true
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      if (action.data.page === 1) {
        state.notificationList = [];
        state.hasMore = true;
      }
      break;

    case GET_NOTIFICATION_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.notificationList.push(...action.data);

      if (_isEmpty(action.data)) {
        state.hasMore = false;
      }

      break;

    case GET_NOTIFICATION_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      break;

    case GET_UNREAD_NOTIFICATION_COUNT_REQUEST:
      state.error = false;
      break;

    case GET_UNREAD_NOTIFICATION_COUNT_SUCCESS:
      state.error = false;
      state.unreadCount = action.data;
      break;

    case MARK_NOTIFICATION_READ_REQUEST:
      state.error = false;
      if (!state.notificationList[_get(action, 'data.index')].isRead && state.unreadCount > 0) {
        state.unreadCount -= 1;
      }
      state.notificationList[_get(action, 'data.index')].isRead = true;
      break;

    case READ_ALL_NOTIFICATION_REQUEST:
      state.error = false;
      state.notificationList.forEach((notification) => {
        notification.isRead = true;
      });
      state.unreadCount = 0;
      break;

    case DELETE_NOTIFICATION_REQUEST:
      state.error = false;
      if (!state.notificationList[_get(action, 'data.index')].isRead && state.unreadCount > 0) {
        state.unreadCount -= 1;
      }
      state.notificationList.splice(_get(action, 'data.index'), 1);
      break;

    default:
  }
  return state;
});

export default reducer;
