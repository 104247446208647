import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { reduxForm, submit } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { addProductRelationRequest } from 'providers/RelatedProductProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';

import RelatedProductsField from '../../Create/RelatedProductsField';

const { confirm } = Modal;
const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

let RelatedProductsForm = ({ handleSubmit, ownerDetail }) => {
  const relatedProductList = useSelector(
    (state) => state.relatedProductProvider.relatedProductList,
    shallowEqual
  );

  const { relatedProductIds } = useSelector(
    (state) => _get(state.form, 'addRelatedProductForm.values', {}),
    shallowEqual
  );

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <RelatedProductsField
        label="商品名"
        placeholder="商品名"
        title={<Typography.Text>選択済みの商品</Typography.Text>}
        ownerId={ownerDetail?.objectId}
        selectedProductIds={_map(relatedProductList?.list, (o) => o?.objectId)}
        relatedProductIds={relatedProductIds}
      />
    </Form>
  );
};

RelatedProductsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  ownerDetail: PropTypes.object.isRequired,
};

RelatedProductsForm = reduxForm({ form: 'addRelatedProductForm' })(
  RelatedProductsForm
);

const AddRelatedProducts = ({ handleCloseDialog, visible, ownerDetail }) => {
  const dispatch = useDispatch();
  const [actionLoading, setActionLoading] = useState(false);

  const handleSubmit = async ({ relatedProductIds }) => {
    if (!_isEmpty(relatedProductIds)) {
      setActionLoading(true);
      try {
        await dispatch(
          addProductRelationRequest({
            relatedTo: relatedProductIds,
            ownerId: ownerDetail?.objectId,
          })
        );
        dispatch(doRefresh({ target: 'product-detail' }));
        handleCloseDialog();
      } catch (error) {
        console.log(error);
      }
      setActionLoading(false);
    }
  };

  const handleCancel = () => showConfirm('中断してよろしいですか？', handleCloseDialog);
  const onOK = () => {
    dispatch(submit('addRelatedProductForm'));
  };

  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      okText="追加"
      cancelText="キャンセル"
      visible={visible}
      confirmLoading={actionLoading}
      onCancel={handleCancel}
      onOk={onOK}
      title={`${ownerDetail?.name}の関連商品を追加`}
      width={578}
    >
      <RelatedProductsForm onSubmit={handleSubmit} ownerDetail={ownerDetail} />
    </Modal>
  );
};

AddRelatedProducts.propTypes = {
  visible: PropTypes.bool.isRequired,
  ownerDetail: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default AddRelatedProducts;
