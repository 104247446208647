import produce from 'immer';
import _find from 'lodash/find';
import {
  GET_GENRE_LIST_REQUEST,
  GET_GENRE_LIST_SUCCESS,
  GET_GENRE_LIST_ERROR,
  GET_TAG_DETAILS_ERROR,
  GET_TAG_DETAILS_REQUEST,
  GET_TAG_DETAILS_SUCCESS,
  GET_TAG_LIST_ERROR,
  GET_TAG_LIST_REQUEST,
  GET_TAG_LIST_SUCCESS,
  CHANGE_TAG_STATUS_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  error: false,
  genreList: [],
  tagDetail: {},
  tagList: []
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_GENRE_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      break;

    case GET_GENRE_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.genreList = action.data;
      break;

    case GET_GENRE_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      state.genreList = [];
      break;
    case GET_TAG_DETAILS_REQUEST:
      state.loading = true;
      state.error = false;
      state.tagDetail = {};
      break;
    case GET_TAG_DETAILS_SUCCESS:
      state.loading = false;
      state.error = false;
      state.tagDetail = action.payload;
      break;
    case GET_TAG_DETAILS_ERROR:
      state.loading = false;
      state.error = action.error;
      state.tagDetail = {};
      break;
    case GET_TAG_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      break;
    case GET_TAG_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.tagList = action.payload;
      break;
    case GET_TAG_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      state.tagList = [];
      break;
    case CHANGE_TAG_STATUS_SUCCESS: {
      state.tagDetail.isVisible = action.payload.isVisible;

      _find(state.tagList, (menuItem) => {
        if (menuItem.objectId === action.payload.objectId) {
          menuItem.isVisible = action.payload.isVisible;
          return true;
        }
        return false;
      });
      break;
    }

    default:
  }
  return state;
});

export default reducer;
