export const GET_BRAND_LIST_REQUEST = 'BrandProvider/GET_BRAND_LIST_REQUEST';
export const GET_BRAND_LIST_SUCCESS = 'BrandProvider/GET_BRAND_LIST_SUCCESS';
export const GET_BRAND_LIST_ERROR = 'BrandProvider/GET_BRAND_LIST_ERROR';

export const CREATE_BRAND_REQUEST = 'BrandProvider/CREATE_BRAND_REQUEST';
export const CREATE_BRAND_SUCCESS = 'BrandProvider/CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'BrandProvider/CREATE_BRAND_ERROR';

export const GET_BRAND_DETAIL_REQUEST = 'BrandProvider/GET_BRAND_DETAIL_REQUEST';
export const GET_BRAND_DETAIL_SUCCESS = 'BrandProvider/GET_BRAND_DETAIL_SUCCESS';
export const GET_BRAND_DETAIL_ERROR = 'BrandProvider/GET_BRAND_DETAIL_ERROR';

export const UPDATE_BRAND_REQUEST = 'BrandProvider/UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'BrandProvider/UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'BrandProvider/UPDATE_BRAND_ERROR';

export const REGISTER_BRAND_OWNER_REQUEST = 'BrandProvider/REGISTER_BRAND_OWNER_REQUEST';
export const REGISTER_BRAND_OWNER_SUCCESS = 'BrandProvider/REGISTER_BRAND_OWNER_SUCCESS';
export const REGISTER_BRAND_OWNER_ERROR = 'BrandProvider/REGISTER_BRAND_OWNER_ERROR';

export const ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST = 'BrandProvider/ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST';
export const ASSIGN_BRAND_OWNER_TO_BRAND_SUCCESS = 'BrandProvider/ASSIGN_BRAND_OWNER_TO_BRAND_SUCCESS';
export const ASSIGN_BRAND_OWNER_TO_BRAND_ERROR = 'BrandProvider/ASSIGN_BRAND_OWNER_TO_BRAND_ERROR';

export const REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST = 'BrandProvider/REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST';
export const REMOVE_BRAND_OWNER_FROM_BRAND_SUCCESS = 'BrandProvider/REMOVE_BRAND_OWNER_FROM_BRAND_SUCCESS';
export const REMOVE_BRAND_OWNER_FROM_BRAND_ERROR = 'BrandProvider/REMOVE_BRAND_OWNER_FROM_BRAND_ERROR';

export const GET_BRAND_LIST_OF_RESTAURANT_REQUEST = 'BrandProvider/GET_BRAND_LIST_OF_RESTAURANT_REQUEST';
export const GET_BRAND_LIST_OF_RESTAURANT_SUCCESS = 'BrandProvider/GET_BRAND_LIST_OF_RESTAURANT_SUCCESS';
export const GET_BRAND_LIST_OF_RESTAURANT_ERROR = 'BrandProvider/GET_BRAND_LIST_OF_RESTAURANT_ERROR';
