import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  CloseOutlined, CheckOutlined, CheckCircleFilled
} from '@ant-design/icons';
import {
  Card,
  Avatar,
  Space,
  Button,
  List,
  Typography,
  AutoComplete,
  Empty,
  Spin,
} from 'antd';
import './style.less';

const { Option } = AutoComplete;
const { Meta } = Card;

const MultipleSelectionWithRemoteSearch = (props) => {
  const {
    label,
    placeholder,
    input,
    options,
    onSearch,
    isShowAvatar = true,
    meta: { invalid, error, valid },
    note,
    avatarShape = 'circle',
    title,
    isSingleSelect = false,
    defaultImage,
    requiredSpace,
    required,
    loading,
    showNotFound,
  } = props;

  const [selections, setSelections] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    input.onChange(_map(selections, (o) => o.objectId));
  }, [selections]);

  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace
    ? 'transparent'
    : requiredIconColor;

  const optionElements = _map(options, (option) => {
    const optionSelected = _find(
      selections,
      (o) => o.objectId === option.objectId
    );
    return (
      <Option
        key={option.objectId}
        value={option.objectId}
        disabled={option.isAdded || option.isDisabled}
      >
        <Card
          bordered={false}
          className="bg-transparent"
          bodyStyle={{ padding: 16 }}
        >
          <div className="full-w flex justify-between items-center">
            <Meta
              style={{
                overflow: 'hidden',
                width: '100%',
              }}
              avatar={
                isShowAvatar && (
                  <Avatar
                    src={
                      (option.image && option.image)
                      || (option.images && option?.images[0]?.url) || defaultImage
                    }
                    shape={avatarShape}
                  />
                )
              }
              title={option.name}
              description={
                option.email
                || (isShowAvatar && `${option?.standard || '-'} • ${option?.amount || '-'} / ${
                  option?.unit || '-'
                }`)
              }
            />
            <div className="flex direction-column items-end justify-center">
              {optionSelected && (
                <Space>
                  <CheckOutlined style={{ color: '#399e0e' }} />
                  <Typography.Text style={{ color: '#399e0e' }}>
                    登録済
                  </Typography.Text>
                </Space>
              )}
              {option.isAdded && (
                <Space>
                  <CheckOutlined style={{ color: '#399e0e' }} />
                  <Typography.Text style={{ color: '#399e0e' }}>
                    追加済
                  </Typography.Text>
                </Space>
              )}
              {option.isDisabled && (
                <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  無効
                </Typography.Text>
              )}
              {option.extra}
            </div>
          </div>
        </Card>
      </Option>
    );
  });

  const handleChange = (value) => {
    const valueObj = _find(options, (o) => o.objectId === value);
    if (!_find(selections, (o) => o.objectId === value)) {
      if (isSingleSelect) {
        setSelections([valueObj]);
      } else {
        setSelections((pre) => [...pre, valueObj]);
      }
    } else {
      setSelections((pre) => _filter(pre, (o) => o.objectId !== value));
    }
  };

  const handleSearch = (keyword) => {
    setSearch(keyword);
    onSearch(keyword);
  };

  return (
    <>
      <Form.Item
        label={label}
        help={invalid && error}
        validateStatus={invalid && error ? 'error' : ''}
        hasFeedback
      >
        <div className="flex items-center">
          <AutoComplete
            className="form-select-field"
            allowClear
            filterOption={false}
            showArrow={false}
            mode="multiple"
            placeholder={placeholder}
            value={search}
            onSelect={handleChange}
            onSearch={handleSearch}
            notFoundContent={
              showNotFound
              && (loading ? (
                <div
                  className="full-w flex justify-center items-center"
                  style={{ height: '100%', padding: '16px 0' }}
                >
                  <Spin />
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ))
            }
          >
            {optionElements}
          </AutoComplete>
          {(required || requiredSpace) && (
            <CheckCircleFilled
              style={{
                fontSize: 16,
                marginLeft: 8,
                color: requiredSpaceColor,
              }}
            />
          )}
        </div>
      </Form.Item>
      {!_isEmpty(selections) && (
        <Space direction="vertical" className="full-w item-list-container">
          {title}
          <List
            itemLayout="horizontal"
            dataSource={selections}
            className="item-list"
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    key={item.objectId}
                    type="link"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      handleChange(item.objectId);
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    isShowAvatar && (
                      <Avatar
                        src={
                          (item?.image && item?.image)
                          || (item.images && item?.images[0]?.url) || defaultImage
                        }
                        shape={avatarShape}
                      />
                    )
                  }
                  title={item.name}
                  description={
                    item.email
                    || (isShowAvatar && `${item?.standard || '-'} • ${item?.amount || '-'} / ${
                      item?.unit || '-'
                    }`)
                  }
                />
                {item.extra}
              </List.Item>
            )}
          />
          {note}
        </Space>
      )}
    </>
  );
};

MultipleSelectionWithRemoteSearch.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  isShowAvatar: PropTypes.bool,
  note: PropTypes.any,
  avatarShape: PropTypes.string,
  title: PropTypes.element,
  isSingleSelect: PropTypes.bool,
  defaultImage: PropTypes.string,
  requiredSpace: PropTypes.bool,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  showNotFound: PropTypes.bool,
};

export default MultipleSelectionWithRemoteSearch;
