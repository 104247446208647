import _pick from 'lodash/pick';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { isDirty, reset, submit } from 'redux-form';
import { push } from 'connected-react-router';
import {
  Card, Button, Modal, Space, Typography, Spin
} from 'antd';

import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';

import toastMessage from 'utils/toastMessage';

import {
  getSupplierDetailRequest,
  updateSupplierRequest,
} from 'providers/SupplierProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';

import InvoiceUpdateForm from './InvoiceUpdateForm';

import './style.less';

const { confirm } = Modal;

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: '請求書',
  },
];

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const Invoice = () => {
  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );
  const dirty = useSelector((state) => isDirty('invoiceUpdateForm')(state));

  const fetchSupplierDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getSupplierDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateSupplierRequest(values));
      toastMessage.success({ description: '請求書の設定を更新しました。' });
      dispatch(doRefresh({ target: 'invoicePage' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  return (
    <div id="invoice-page">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title="請求書"
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Card title="請求書の設定" className="highlight-card max-width-644" bordered={false}>
          <InvoiceUpdateForm
            onSubmit={handleSubmit}
            initialValues={_pick(supplierDetail, [
              'bankName',
              'bankBranchName',
              'bankAccountType',
              'bankAccountNumber',
              'bankAccountName',
              'invoiceNote',
              'invoiceShowPaymentDeadline',
              'taxId'
            ])}
          />
        </Card>
      </Card>
      {dirty && (
        <div className="full-w actions-container">
          <Space
            size={8}
            className="flex justify-between"
            style={{ padding: '16px 16px 16px 32px' }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8} className="full-w flex justify-end">
              <Button
                type="default"
                disabled={actionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('invoiceUpdateForm'))
                )}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                loading={actionLoading}
                onClick={() => dispatch(submit('invoiceUpdateForm'))}
              >
                保存する
              </Button>
            </Space>
          </Space>
        </div>
      )}
    </div>
  );
};

export default withRefresh('invoicePage')(Invoice);
