import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Card,
  Button,
  Form,
  DatePicker,
  Avatar,
  AutoComplete,
  Select,
  Tooltip,
  Typography,
  Space,
} from 'antd';

import CalendarIcon from 'images/icon-calendar.svg';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

import { getSupplierListRequest } from 'providers/SupplierProvider/actions';
import { getBrandListRequest } from 'providers/BrandProvider/actions';

const { RangePicker } = DatePicker;
const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

const BrandOwnerListSearchForm = ({
  filterControl,
  onFilter,
  onFilterChange,
}) => {
  const {
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    supplierId,
    brandId,
  } = filterControl;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { list: supplierList } = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );
  const { list: brandList } = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      getSupplierListRequest({
        limit: 10000,
      })
    );
    dispatch(getBrandListRequest({ limit: 10000 }));
  }, []);

  const clearFilters = () => {
    form.setFieldsValue({
      createdDate: null,
      deliveryDate: null,
      supplierId: undefined,
      brandId: undefined,
    });

    onFilterChange({
      deliveryDateFrom: '',
      deliveryDateTo: '',
      createdDateFrom: '',
      createdDateTo: '',
      supplierId: '',
      brandId: '',
    });
  };

  const renderItem = (option, defaultImage) => (
    <Option
      key={option.objectId}
      value={option.objectId}
      label={(
        <Space className="flex items-center">
          <Avatar
            alt="avatar"
            src={option.image || <img alt="default" src={defaultImage} />}
            size={16}
          />
          <Typography.Text>{option.name}</Typography.Text>
        </Space>
      )}
    >
      <Card
        bordered={false}
        className="bg-transparent"
        bodyStyle={{ padding: '4px 3px' }}
      >
        <div className="full-w flex justify-between items-center">
          <Meta
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
            avatar={(
              <Avatar
                src={option.image || <img alt="default" src={defaultImage} />}
              />
            )}
            title={(
              <Tooltip placement="topLeft" title={option.name}>
                {option.name}
              </Tooltip>
            )}
            description={(
              <Tooltip placement="topLeft" title={option.email}>
                <Text type="secondary" ellipsis>
                  {option.email}
                </Text>
              </Tooltip>
            )}
          />
        </div>
      </Card>
    </Option>
  );

  const optionElementsBrand = _map(brandList, (o) => renderItem(o, DefaultRestaurantImage)
  );

  const optionElementsSupplier = _map(supplierList, (o) => renderItem(o, DefaultSupplierImage)
  );

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getSupplierListRequest({ ...data, limit: 10000 })),
      500
    ),
    []
  );

  const getBrandListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getBrandListRequest({ ...data, limit: 10000 })),
      500
    ),
    []
  );

  const onSearchSupplierList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const onSearchBrandList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getBrandListWithDelay(data);
  };

  return (
    <Form
      layout="inline"
      initialValues={{
        createdDate: [
          createdDateFrom && moment(createdDateFrom),
          createdDateTo && moment(createdDateTo),
        ],
        deliveryDate: [
          deliveryDateFrom && moment(deliveryDateFrom),
          deliveryDateTo && moment(deliveryDateTo),
        ],
        brandId,
        supplierId,
      }}
      onValuesChange={onFilter}
      form={form}
      labelAlign="right"
      className="brand-owner-list-search-form"
    >
      <Space align="end">
        <Space wrap size={[8, 16]}>
          <Form.Item label="販売者: " name="supplierId">
            <Select
              placeholder="すべて"
              allowClear
              showSearch
              filterOption={false}
              style={{ width: '250px' }}
              onSearch={onSearchSupplierList}
              onClear={() => onSearchSupplierList('')}
              optionLabelProp="label"
            >
              {optionElementsSupplier}
            </Select>
          </Form.Item>
          <Form.Item label="ブランド: " name="brandId">
            <Select
              allowClear
              showSearch
              filterOption={false}
              style={{ width: '250px' }}
              placeholder="すべて"
              onSearch={onSearchBrandList}
              onClear={() => onSearchBrandList('')}
              optionLabelProp="label"
            >
              {optionElementsBrand}
            </Select>
          </Form.Item>
          <Form.Item label="注文日: " name="createdDate">
            <RangePicker
              placeholder={['開始日', '終了日']}
              className="form-range-picker-field"
              onBlur={(e) => e.preventDefault()}
              format="YYYY/MM/DD"
              suffixIcon={<img alt="calendar-icon" src={CalendarIcon} />}
              separator={<ArrowRightOutlined />}
            />
          </Form.Item>
          <Form.Item label="納品希望日: " name="deliveryDate">
            <RangePicker
              placeholder={['開始日', '終了日']}
              className="form-range-picker-field"
              onBlur={(e) => e.preventDefault()}
              format="YYYY/MM/DD"
              suffixIcon={<img alt="calendar-icon" src={CalendarIcon} />}
              separator={<ArrowRightOutlined />}
            />
          </Form.Item>
        </Space>
        <Button
          type="text"
          icon={<CloseOutlined />}
          style={{ color: '#1890ff' }}
          hidden={
            !deliveryDateFrom
            && !deliveryDateTo
            && !createdDateFrom
            && !createdDateTo
            && !brandId
            && !supplierId
          }
          onClick={clearFilters}
        >
          Clear all filters
        </Button>
      </Space>
    </Form>
  );
};

BrandOwnerListSearchForm.propTypes = {
  filterControl: PropTypes.object,
  onFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
};

export default BrandOwnerListSearchForm;
