import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import { isDirty } from 'redux-form';
import {
  Typography, Tabs, Modal, Spin, Space, Avatar
} from 'antd';

import { getBrandOwnerDetailRequest } from 'providers/BrandOwnerProvider/actions';

import PageHeader from 'components/PageHeader';
import DefaultBrandImage from 'images/defaultBrand.svg';
import withRefresh from 'components/HOCs/withRefresh';
import routes from './routes';

import './style.less';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Text } = Typography;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const BrandOwnerDetail = ({ match, location }) => {
  const dispatch = useDispatch();
  const dataLoading = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerDetailLoading,
    shallowEqual
  );
  const brandOwnerDetail = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerDetail,
    shallowEqual
  );

  const dirty = useSelector((state) => isDirty('brandOwnerForm')(state));

  const breadcrumbItems = [
    {
      path: '/brands/brand-owners',
      breadcrumbName: 'ブランド ',
    },
    {
      breadcrumbName: _get(brandOwnerDetail, 'name'),
    },
  ];

  const objectId = _get(match, 'params.brandOwnerId');

  const fetchBrandOwnerDetail = useCallback(() => {
    dispatch(getBrandOwnerDetailRequest({ objectId }));
  }, [objectId]);

  useEffect(() => {
    fetchBrandOwnerDetail();
  }, [fetchBrandOwnerDetail]);

  const selectedKeys = location.pathname.split('/')[5];

  const onTabClick = (key) => {
    const pushPath = `/brands/brand-owners/detail/${objectId}/${key}`;
    dispatch(replace(pushPath));
  };

  const handleGoBack = () => {
    dispatch(goBack('/brands/brand-owners'));
  };

  const renderBrandOwnerDetailHeader = () => (
    <Space>
      <Avatar
        size={40}
        src={_get(brandOwnerDetail, 'image', DefaultBrandImage)}
      />
      <div style={{ lineHeight: 'normal' }}>
        <div className="brand-owner-name">{brandOwnerDetail?.name}</div>
        <Text
          copyable={
            !!brandOwnerDetail?.email && {
              tooltips: ['コピー', 'コピーしました'],
            }
          }
          className="email"
        >
          {brandOwnerDetail?.email}
        </Text>
      </div>
    </Space>
  );

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="brand-owner-detail-page">
      <PageHeader
        title={renderBrandOwnerDetailHeader()}
        breadcrumbRoutes={breadcrumbItems}
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="概要" key="in-app" />
            <TabPane tab="ブランド" key="brand-list" />
            <TabPane tab="販売者" key="supplier-list" />
          </Tabs>
        )}
      />
      <Switch>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

BrandOwnerDetail.propTypes = {
  match: PropTypes.any,
  pathname: PropTypes.any,
  location: PropTypes.any,
};

export default withRefresh('brandOwnerDetail')(BrandOwnerDetail);
