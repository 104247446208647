import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Typography, Space, Spin, Switch, Divider
} from 'antd';

import {
  getSupplierDetailRequest,
  updateSupplierRequest,
} from 'providers/SupplierProvider/actions';

import toastMessage from 'utils/toastMessage';
import PageHeader from 'components/PageHeader';
import './style.scss';

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: 'メール通知',
  },
];

const EmailNotification = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchSupplierDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getSupplierDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  });

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const handleChangeStatus = async (value, key) => {
    setActionLoading(true);
    try {
      await dispatch(updateSupplierRequest({ [key]: value }));
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      dispatch(getSupplierDetailRequest());
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  const handleOrderNotification = (value) => handleChangeStatus(value, 'newOrderEmailNotify');
  const handleDailyReportNotification = (value) => handleChangeStatus(value, 'dailyOrderReportEmail');

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="email-notification-page">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={handleGoBack}
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Card bordered={false} className="highlight-card">
          <Space size={16} className="flex justify-between">
            <div>
              <Typography.Paragraph strong className="label">
                受注通知メール
              </Typography.Paragraph>
              <Typography.Text>
                注文を受注したら受注情報をメールで送信します
              </Typography.Text>
            </div>
            <Switch
              onChange={handleOrderNotification}
              checked={supplierDetail?.newOrderEmailNotify}
              loading={actionLoading}
            />
          </Space>
          <Divider />
          <Space size={16} className="flex justify-between">
            <div>
              <Typography.Paragraph strong className="label">
                日次売上送信メール
              </Typography.Paragraph>
              <Typography.Text>
                １日１回、前日分の売上情報をメールで送信します {currentUser?.email} 毎日7時頃送信
              </Typography.Text>
            </div>
            <Switch
              onChange={handleDailyReportNotification}
              checked={supplierDetail?.dailyOrderReportEmail}
              loading={actionLoading}
            />
          </Space>
        </Card>
      </Card>
    </div>
  );
};

export default EmailNotification;
