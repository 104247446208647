export const GET_NOTIFICATION_LIST_REQUEST = 'NotificationProvider/GET_NOTIFICATION_LIST_REQUEST';
export const GET_NOTIFICATION_LIST_SUCCESS = 'NotificationProvider/GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_ERROR = 'NotificationProvider/GET_NOTIFICATION_LIST_ERROR';

export const GET_UNREAD_NOTIFICATION_COUNT_REQUEST = 'NotificationProvider/GET_UNREAD_NOTIFICATION_COUNT_REQUEST';
export const GET_UNREAD_NOTIFICATION_COUNT_SUCCESS = 'NotificationProvider/GET_UNREAD_NOTIFICATION_COUNT_SUCCESS';

export const MARK_NOTIFICATION_READ_REQUEST = 'NotificationProvider/MARK_NOTIFICATION_READ_REQUEST';

export const READ_ALL_NOTIFICATION_REQUEST = 'NotificationProvider/READ_ALL_NOTIFICATION_REQUEST';

export const DELETE_NOTIFICATION_REQUEST = 'NotificationProvider/DELETE_NOTIFICATION_REQUEST';
