import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Switch, Route } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Card, Space, Radio } from 'antd';

import BrandIcon from 'images/icon-brand.svg';
import BrandOwnerIcon from 'images/icon-brand-owner.svg';

import routes from './routes';

import './style.less';

const OperatorBrandList = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const onTabClick = (key) => {
    const pushPath = `/brands/${key}`;
    dispatch(replace(pushPath));
  };

  return (
    <Card className="bg-transparent brand-list-container" bordered={false}>
      <Card
        bordered={false}
        className="highlight-card flex items-center padding-card-16"
        style={{
          borderRadius: '4px 4px 0 0',
          borderBottom: '1px solid #0000000d',
        }}
      >
        <Radio.Group
          onChange={(e) => {
            onTabClick(e.target.value);
          }}
          value={pathname.split('/')[2]}
          buttonStyle="solid"
        >
          <Radio.Button value="brand-owners">
            <Space>
              <img src={BrandOwnerIcon} alt="brand-owner-icon" />
              オーナー表示
            </Space>
          </Radio.Button>
          <Radio.Button value="list">
            <Space>
              <img src={BrandIcon} alt="brand-icon" />
              ブランド表示
            </Space>
          </Radio.Button>
        </Radio.Group>
      </Card>
      <Switch>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </Card>
  );
};

export default OperatorBrandList;
