import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import axios from 'axios';
import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX, ORDER_STATUS } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getBrandOwnerListSuccess,
  getBrandOwnerListError,
  getBrandOwnerDetailSuccess,
  getBrandOwnerDetailError,
  updateBrandOwnerSuccess,
  updateBrandOwnerError,
  getOrderListForBrandOwnerSuccess,
  getOrderListForBrandOwnerError,
  enableBrandOwnerAccountSuccess,
  enableBrandOwnerAccountError,
  disableBrandOwnerAccountSuccess,
  disableBrandOwnerAccountError,
} from './actions';
import {
  GET_BRAND_OWNER_LIST_REQUEST,
  GET_BRAND_OWNER_DETAIL_REQUEST,
  UPDATE_BRAND_OWNER_REQUEST,
  GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST,
  ENABLE_BRAND_OWNER_ACCOUNT_REQUEST,
  DISABLE_BRAND_OWNER_ACCOUNT_REQUEST,
} from './constants';

export function* handleGetBrandOwnerList(action) {
  const { payload, meta } = action;

  const params = _pick(payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'checkAssignedToBrandId',
  ]);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBrandOwnerList`,
      params
    );
    yield put(getBrandOwnerListSuccess(result, meta));
  } catch (error) {
    yield put(getBrandOwnerListError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleGetBrandOwnerDetail(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBrandOwnerDetail`,
      action.payload
    );
    yield put(getBrandOwnerDetailSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getBrandOwnerDetailError(error, action.meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleUpdateBrandOwner(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    const params = _pick(payload, [
      'name',
      'nameKana',
      'phone',
      'memo',
      'brandOwnerId',
    ]);

    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      if (image?.originFileObj) {
        const { result: fileUploadResult } = yield call(
          request,
          `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
          {
            fileName: _get(image, 'originFileObj.name'),
            fileType: _get(image, 'originFileObj.type'),
            type: 'AVATAR',
          }
        );

        fileId = _get(fileUploadResult, 'objectId');

        yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
          headers: {
            'Content-Type': _get(image, 'originFileObj.type'),
          },
        })
        );
      }
    }
    params.image = fileId
      || (_isArray(payload.image) && _isEmpty(payload.image) ? '' : undefined);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateBrandOwner`,
      params
    );
    yield put(updateBrandOwnerSuccess(result, meta));
  } catch (error) {
    yield put(updateBrandOwnerError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleGetOrderListForBrandOwner(action) {
  const { payload, meta } = action;

  const params = _pick(payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'brandId',
    'supplierId',
    'viewMode',
  ]);
  const createdDateFrom = _get(payload, 'createdDateFrom');
  const createdDateTo = _get(payload, 'createdDateTo');

  if (createdDateFrom && createdDateTo) {
    const createdDateFormMoment = moment(createdDateFrom);
    const createdDateToMoment = moment(createdDateTo);
    params.createdDateFrom = createdDateFormMoment
      .startOf('date')
      .toISOString();
    params.createdDateTo = createdDateToMoment.endOf('date').toISOString();
  }

  const deliveryDateFrom = _get(payload, 'deliveryDateFrom');
  const deliveryDateTo = _get(payload, 'deliveryDateTo');

  if (deliveryDateFrom && deliveryDateTo) {
    const deliveryDateFormMoment = moment(deliveryDateFrom);
    const deliveryDateToMoment = moment(deliveryDateTo);
    params.deliveryDateFrom = deliveryDateFormMoment
      .startOf('date')
      .toISOString();
    params.deliveryDateTo = deliveryDateToMoment.endOf('date').toISOString();
  }

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderListForBrandOwner`,
      params
    );
    _forEach(result?.list, (o) => {
      if (o?.status === ORDER_STATUS.DELIVERED && o?.fixed === true) {
        o.status = ORDER_STATUS.FIXED;
      }
    });
    yield put(getOrderListForBrandOwnerSuccess(result, meta));
  } catch (error) {
    yield put(getOrderListForBrandOwnerError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleEnableBrandOwnerAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}enableBrandOwnerAccount`,
      payload
    );
    yield put(enableBrandOwnerAccountSuccess(result, meta));
  } catch (error) {
    yield put(enableBrandOwnerAccountError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleDisableBrandOwnerAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}disableBrandOwnerAccount`,
      payload
    );
    yield put(disableBrandOwnerAccountSuccess(result, meta));
  } catch (error) {
    yield put(disableBrandOwnerAccountError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export default function* saga() {
  yield takeLatest(GET_BRAND_OWNER_LIST_REQUEST, handleGetBrandOwnerList);
  yield takeLatest(GET_BRAND_OWNER_DETAIL_REQUEST, handleGetBrandOwnerDetail);
  yield takeLatest(UPDATE_BRAND_OWNER_REQUEST, handleUpdateBrandOwner);
  yield takeLatest(GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST, handleGetOrderListForBrandOwner);
  yield takeLeading(ENABLE_BRAND_OWNER_ACCOUNT_REQUEST, handleEnableBrandOwnerAccount);
  yield takeLeading(DISABLE_BRAND_OWNER_ACCOUNT_REQUEST, handleDisableBrandOwnerAccount);
}
