import _get from 'lodash/get';
import _join from 'lodash/join';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaperClipOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  Space,
  Badge,
  Card,
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  Avatar,
  Tooltip,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  ANNOUNCEMENT_STATUS,
  DATE_TIME_FORMAT,
  RESTAURANT_ANNOUNCEMENT,
} from 'utils/constants';

import DefaultProductImage from 'images/defaultProduct.svg';
import popOutIcon from 'images/icon-pop-out.svg';
import LinkIcon from 'images/icon-link.svg';

import PageHeader from 'components/PageHeader';
import { exportRestaurantAnnouncementResultRequest } from 'providers/AnnouncementProvider/actions';
import Helpers from 'utils/helpers';
import { STATUS_BADGE_CONTENT } from '../List/AnnouncementList';

import './style.less';

const { confirm } = Modal;

const restaurantGroupDataComparisonText = {
  lte: 'より前',
  gte: 'より後',
};

const AnnouncementDetail = ({
  announcementDetail,
  getAnnouncementDetailRequest,
  deleteAnnouncementRequest,
  match,
  push,
}) => {
  const objectId = _get(match, 'params.announcementId');
  const status = _get(announcementDetail, 'status');

  useEffect(() => {
    getAnnouncementDetailRequest({ objectId });
  }, []);

  const { goBack } = useHistory();

  const showConfirm = (message, actionFn) => {
    confirm({
      title: message,
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      onOk: () => {
        actionFn();
      },
    });
  };

  const deleteRestaurantAnnouncement = async (announcementId) => {
    await deleteAnnouncementRequest({ objectId: announcementId });
    push('/announcements');
  };

  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState(false);
  const handleExport = async () => {
    setExportLoading(true);
    try {
      await dispatch(
        exportRestaurantAnnouncementResultRequest({
          restaurantAnnouncementId: objectId,
          fileName: `プッシュ通知配信詳細_${moment(
            _get(announcementDetail, 'announceTime')
          ).format('YYYY年MM月DD日HHmm')}_${_get(
            announcementDetail,
            'restaurantGroup.name'
          )}.csv`,
        })
      );
    } catch (err) {
      console.error(err);
    }
    setExportLoading(false);
  };

  const breadcrumbItems = [
    {
      path: '/announcements',
      breadcrumbName: 'プッシュ通知',
    },
    {
      breadcrumbName: _get(announcementDetail, 'objectId'),
    },
  ];

  const attachmentFile = _get(announcementDetail, 'attachmentFile');
  const attachmentItem = attachmentFile?.url && (
    <a href={attachmentFile?.url} target="_blank" rel="noreferrer">
      <Space direction="horizontal">
        <PaperClipOutlined />
        {attachmentFile?.name}
      </Space>
    </a>
  );

  const productDetail = _get(announcementDetail, 'menu');
  const productItem = productDetail && (
    <div className="flex items-center full-w product-item">
      <Avatar
        alt="name"
        src={_get(
          announcementDetail,
          'menu.images[0].url',
          DefaultProductImage
        )}
        size={32}
        shape="square"
      />
      <Space
        direction="vertical"
        size={0}
        className="restaurant-info-wrapper"
        style={{ marginLeft: 4 }}
      >
        <Tooltip
          placement="topLeft"
          title={_get(announcementDetail, 'menu.name')}
        >
          <Typography.Text ellipsis style={{ fontWeight: 400 }}>
            {_get(announcementDetail, 'menu.name')}
          </Typography.Text>
        </Tooltip>
        <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
          {_get(announcementDetail, 'menu.standard', '-')} •{' '}
          {_get(announcementDetail, 'menu.amount', '-')} ／{' '}
          {_get(announcementDetail, 'menu.unit', '-')}
        </Typography.Text>
      </Space>
      <Button
        type="link"
        href={`/products/detail/${_get(
          announcementDetail,
          'menu.formattedObjectId'
        )}/in-app`}
        icon={<img src={popOutIcon} alt="icon-pop-out" />}
        target="_blank"
      />
    </div>
  );

  const destinationUrl = announcementDetail?.url && (
    <a href={announcementDetail?.url} target="_blank" rel="noreferrer">
      <img src={LinkIcon} alt="LinkIcon" style={{ marginRight: 4 }} />
      <span style={{ verticalAlign: 'middle' }}>{announcementDetail?.url}</span>
    </a>
  );

  const restaurantTarget = _get(announcementDetail, 'announcementTarget');
  const restaurantGroup = _get(announcementDetail, 'restaurantGroup');
  const restaurantGroupData = _get(announcementDetail, 'restaurantGroupData');

  const getDescription = () => {
    const nPushSuccess = announcementDetail?.result?.nPushSuccess;
    const formattedNPushSuccess = nPushSuccess && `${Helpers.numberWithCommas(nPushSuccess)}件`;
    if (restaurantTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL) {
      const nTargetInstallations = announcementDetail?.result?.nTargetInstallations;
      const formattedNTargetInstallations = nTargetInstallations
        && `${Helpers.numberWithCommas(nTargetInstallations)}件`;
      const nTargetInstallationWithDeviceToken = announcementDetail?.result?.nTargetInstallationWithDeviceToken;
      const formattedNTargetInstallationWithDeviceToken = nTargetInstallationWithDeviceToken
        && `${Helpers.numberWithCommas(nTargetInstallationWithDeviceToken)}件`;
      return (
        <Descriptions layout="horizontal" column={1} bordered>
          <Descriptions.Item label="総数">
            {formattedNTargetInstallations || formattedNPushSuccess || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="許諾数">
            {formattedNTargetInstallationWithDeviceToken || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="開封数">-</Descriptions.Item>
        </Descriptions>
      );
    }
    const sum = announcementDetail?.sum;
    const formattedSum = sum && `${Helpers.numberWithCommas(sum)}件`;
    if (
      restaurantGroup?.key
      === RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.GUEST_USER
    ) {
      const sumInstallationHasDeviceToken = announcementDetail?.sumInstallationHasDeviceToken;
      const formattedSumInstallationHasDeviceToken = sumInstallationHasDeviceToken
        && `${Helpers.numberWithCommas(sumInstallationHasDeviceToken)}件`;
      return (
        <Descriptions layout="horizontal" column={1} bordered>
          <Descriptions.Item label="総数">
            {formattedSum || formattedNPushSuccess || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="許諾数">
            {formattedSumInstallationHasDeviceToken || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="開封数">-</Descriptions.Item>
        </Descriptions>
      );
    }

    const sumRestaurantHasDeviceToken = announcementDetail?.sumRestaurantHasDeviceToken;
    const formattedSumRestaurantHasDeviceToken = sumRestaurantHasDeviceToken
      && `${Helpers.numberWithCommas(sumRestaurantHasDeviceToken)}件`;
    const sumIsOpened = announcementDetail?.sumIsOpened;
    const formattedSumIsOpened = sumIsOpened && `${Helpers.numberWithCommas(sumIsOpened)}件`;
    return (
      <Descriptions layout="horizontal" column={1} bordered>
        <Descriptions.Item label="総数">
          {formattedSum || formattedNPushSuccess || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="許諾数">
          {formattedSumRestaurantHasDeviceToken || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="開封数">
          {formattedSumIsOpened || '-'}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const target = () => {
    let title;
    if (restaurantTarget === RESTAURANT_ANNOUNCEMENT.TARGET.ALL) {
      title = '全てのユーザー';
    } else if (
      restaurantGroup?.key
      === RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER
    ) {
      title = '以下のレストランに対して一括送信しております。';
    } else {
      title = _get(announcementDetail, 'restaurantGroup.name');
      if (restaurantGroupData) {
        title += `: ${moment(restaurantGroupData?.date).format(
          'DD日MM月YYYY年'
        )} ${
          restaurantGroupDataComparisonText[restaurantGroupData?.comparison]
        }`;
      }
    }

    return (
      <Space direction="vertical" className="full-w">
        <Space direction="vertical" className="full-w" size={0}>
          <Typography.Text style={{ fontWeight: 400 }}>{title}</Typography.Text>
          {status === ANNOUNCEMENT_STATUS.PUBLISHED
            && restaurantTarget
              === RESTAURANT_ANNOUNCEMENT.TARGET.RESTAURANT_GROUP
            && [
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER,
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.LOGGED_IN_USER,
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.USER_HAS_NO_ORDER,
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.USER_HAS_ORDER,
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
                .USER_LAST_ORDER_DATE_VERSUS_TIME,
              RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
                .USER_REGISTER_DATE_VERSUS_TIME,
            ].includes(restaurantGroup?.key) && (
            <Button
              icon={<DownloadOutlined />}
              type="link"
              style={{ padding: 0 }}
              loading={exportLoading}
              onClick={handleExport}
            >
              {`プッシュ通知配信詳細_${moment(
                _get(announcementDetail, 'announceTime')
              ).format('YYYY年MM月DD日HHmm')}_${_get(
                announcementDetail,
                'restaurantGroup.name'
              )}.csv`}
            </Button>
          )}
        </Space>
        {status === ANNOUNCEMENT_STATUS.PUBLISHED && getDescription()}
        {restaurantGroup?.key
          === RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER && (
          <div
            className="product-item"
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            <Space direction="vertical" size={0}>
              <Typography.Text style={{ fontWeight: 400 }}>
                {_join(restaurantGroupData.formattedIds, '\n')}
              </Typography.Text>
            </Space>
          </div>
        )}
      </Space>
    );
  };

  const title = (
    <div className="flex direction-column announcement-header">
      <Typography.Text>{_get(announcementDetail, 'objectId')}</Typography.Text>
      {[
        ANNOUNCEMENT_STATUS.PENDING,
        ANNOUNCEMENT_STATUS.PUBLISHED,
        ANNOUNCEMENT_STATUS.RUNNING,
      ].includes(status) && (
        <Badge
          id="announcement-detail-status-dot"
          status={STATUS_BADGE_CONTENT[status].type}
          text={STATUS_BADGE_CONTENT[status].text}
        />
      )}
    </div>
  );

  return (
    <div className="announcement-detail-page">
      <PageHeader
        title={title}
        breadcrumbRoutes={breadcrumbItems}
        onBack={goBack}
      />
      <Card className="bg-transparent" bordered={false}>
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <Card
                className="highlight-card"
                bordered={false}
                title="通知内容"
                id="announcement-detail-description"
              >
                <Descriptions layout="horizontal" column={1} bordered>
                  <Descriptions.Item label="タイトル">
                    {_get(announcementDetail, 'title')}
                  </Descriptions.Item>
                  <Descriptions.Item label="本文">
                    {_get(announcementDetail, 'message')}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Card
                className="highlight-card"
                bordered={false}
                title="配信予約"
                id="announcement-detail-description"
              >
                <Descriptions layout="horizontal" column={1} bordered>
                  <Descriptions.Item label="作成日時">
                    {moment(_get(announcementDetail, 'createdAt')).format(
                      DATE_TIME_FORMAT
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      [ANNOUNCEMENT_STATUS.PENDING].includes(status)
                        ? '配信予定'
                        : '送信日'
                    }
                  >
                    {moment(_get(announcementDetail, 'announceTime')).format(
                      DATE_TIME_FORMAT
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>

              {[ANNOUNCEMENT_STATUS.PENDING].includes(status) && (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    showConfirm('プッシュ通知を削除?', () => deleteRestaurantAnnouncement(objectId)
                    );
                  }}
                >
                  削除
                </Button>
              )}
            </Space>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <Card
                className="highlight-card"
                bordered={false}
                title="添付ファイル"
                id="announcement-detail-description"
              >
                {attachmentItem}
                {productItem}
                {destinationUrl}
                {!attachmentFile?.url
                  && !productDetail
                  && !destinationUrl
                  && '--'}
              </Card>
              <Card
                className="highlight-card"
                bordered={false}
                title="通知対象"
                id="announcement-detail-description"
              >
                {target()}
              </Card>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
AnnouncementDetail.propTypes = {
  announcementDetail: PropTypes.object,
  getAnnouncementDetailRequest: PropTypes.any,
  deleteAnnouncementRequest: PropTypes.func,
  match: PropTypes.any,
  push: PropTypes.func,
};

export default AnnouncementDetail;
