import _map from 'lodash/map';
import _get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DownOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Tag, Space, Dropdown, Menu
} from 'antd';

import { doRefresh } from 'providers/CommonProvider/actions';
import { updateGuestInformationRequest } from 'providers/GuestInformationProvider/actions';

const StatusChange = ({ guestInformationDetail }) => {
  const guestInformationStatus = guestInformationDetail?.status;
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  const dispatch = useDispatch();

  const updateOrder = async (params) => {
    setStatusChangeLoading(true);
    try {
      await dispatch(updateGuestInformationRequest(params));
      dispatch(doRefresh({ target: 'guestInformationList' }));
    } catch (error) {
      console.error(error);
    }
    setStatusChangeLoading(false);
  };

  const targetStatusActionMap = {
    NEW: () => updateOrder({
      objectId: guestInformationDetail?.objectId,
      status: 'NEW',
    }),
    CONTACTED: () => updateOrder({
      objectId: guestInformationDetail?.objectId,
      status: 'CONTACTED',
    }),
  };

  const targetStatusMap = {
    NEW: ['CONTACTED'],
    CONTACTED: ['NEW'],
  };

  const statusColorMap = {
    NEW: '#edb317',
    CONTACTED: '',
  };

  const renderStatusMenu = () => (
    <Menu
      onClick={({ key }) => {
        targetStatusActionMap[key]();
      }}
    >
      {_map(targetStatusMap[guestInformationStatus], (target) => (
        <Menu.Item key={target}>
          <Space>
            <span style={{ fontWeight: 500, fontSize: 14 }}>
              ステータス変更
            </span>
            <Tag color={statusColorMap[target]}>{ target === 'CONTACTED' ? '連絡済み' : '未連絡'}</Tag>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={renderStatusMenu} trigger={['click']}>
      <Tag
        style={{
          fontSize: 14,
          fontWeight: 500,
          padding: '2px 8px',
        }}
        color={statusColorMap[guestInformationStatus]}
      >
        <Space style={{ cursor: 'pointer' }}>
          {statusChangeLoading && <SyncOutlined spin />}
          <span>{guestInformationStatus === 'CONTACTED' ? '連絡済み' : '未連絡'}</span>
          {_get(targetStatusMap, guestInformationStatus, []).length > 0 && (
            <DownOutlined />
          )}
        </Space>
      </Tag>
    </Dropdown>
  );
};

StatusChange.propTypes = {
  guestInformationDetail: PropTypes.object,
};

export default StatusChange;
