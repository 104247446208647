import moment from 'moment';
import {
  submit, isDirty, reset
} from 'redux-form';
import { useParams } from 'react-router-dom';
import {
  Card, Modal, Descriptions, Col, Spin
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import PageHeader from 'components/PageHeader';
import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import {
  updateTagRequest,
  getTagDetailsRequest,
} from 'providers/GenreProvider/actions';
import { DATE_TIME_FORMAT } from 'utils/constants';
import withRefresh from 'components/HOCs/withRefresh';
import { doRefresh } from 'providers/CommonProvider/actions';
import TagDetailForm from '../Create/TagForm';
import StatusToggle from '../List/StatusToggle';

const { confirm } = Modal;
const { Item } = Descriptions;
const freeTagType = 2;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '配信',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const TagDetail = () => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();
  const { tagId } = useParams();

  const dirty = useSelector((state) => isDirty('tagForm')(state));
  const tagDetail = useSelector((state) => state.genreProvider.tagDetail);

  const getTagDetail = useCallback(async () => {
    try {
      await dispatch(getTagDetailsRequest({ objectId: tagId }));
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, tagId]);

  useEffect(() => {
    getTagDetail();
  }, [getTagDetail]);

  const breadcrumbItems = [
    {
      path: '/tags',
      breadcrumbName: '商品のタグ',
    },
    {
      breadcrumbName: tagDetail?.name,
    },
  ];

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateTagRequest({ ...values, order: (values?.order === 0 || values?.order) ? values.order : '' }));
      dispatch(doRefresh({ target: 'tag-detail' }));
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  const handleOk = () => {
    dispatch(submit('tagForm'));
  };

  const handleCancel = () => {
    dispatch(goBack());
  };

  const handleReset = () => {
    dispatch(reset('tagForm'));
  };

  const handleGoBack = () => {
    if (dirty) {
      showConfirm('変更を破棄しますか？', handleCancel);
    } else {
      handleCancel();
    }
  };

  if (_isEmpty(tagDetail)) {
    return (
      <div className="flex justify-center full-w">
        <Spin />
      </div>
    );
  }

  const title = (
    <div className="flex direction-column items-start tag-header">
      <div className="text-2xl font-bold">{tagDetail?.name}</div>
      <StatusToggle record={tagDetail?.isVisible} rowRecord={tagDetail} />
    </div>
  );

  return (
    <div className="create-announcement-page flex direction-column">
      <PageHeader
        title={title}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleGoBack}
      />
      <Card className="bg-transparent">
        <TagDetailForm
          onSubmit={handleSubmit}
          initialValues={{
            name: tagDetail?.name,
            objectId: tagDetail?.objectId,
            order: tagDetail?.order || '',
          }}
          isFreeTag={tagDetail?.type === freeTagType}
        >
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Card title="変更履歴" className="highlight-card" bordered={false}>
              <Descriptions column={1} bordered>
                <Item label="更新日">
                  {moment(tagDetail?.updatedAt).format(DATE_TIME_FORMAT)}
                </Item>
                <Item label="作成日">
                  {moment(tagDetail?.createdAt).format(DATE_TIME_FORMAT)}
                </Item>
              </Descriptions>
            </Card>
          </Col>
        </TagDetailForm>
      </Card>
      {dirty && (
        <FormActionFooter
          handleCancel={handleReset}
          actionLoading={actionLoading}
          handleOk={handleOk}
          isShowLabel={false}
          cancelText="キャンセル"
        />
      )}
    </div>
  );
};

export default withRefresh('tag-detail')(TagDetail);
