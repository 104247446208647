import Constants from 'utils/constants';
import Regex from 'utils/regex';
import UtilValidate from 'utils/validate';

const validate = (values) => {
  const errors = {};

  if (!values.deliveryPostalCode?.trim()) {
    errors.deliveryPostalCode = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!UtilValidate.validPostalCode(values.deliveryPostalCode?.trim())) {
    errors.deliveryPostalCode = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.deliveryPrefecture?.trim()) {
    errors.deliveryPrefecture = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.deliveryAddress?.trim()) {
    errors.deliveryAddress = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.deliveryPhone?.trim()) {
    errors.deliveryPhone = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regex.PHONE.test(values.deliveryPhone?.trim())) {
    errors.deliveryPhone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (
    values.deliveryFax?.trim()
    && !Regex.PHONE.test(values.deliveryFax?.trim())
  ) {
    errors.deliveryFax = Constants.ERROR_MESSAGE.INVALID_FAX;
  }

  return errors;
};

export default validate;
