import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Field, reduxForm, submit, SubmissionError
} from 'redux-form';
import {
  Button, Modal, Space, Typography
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import useSearch from 'components/EmailInvitation/useSearch';
import validator from 'validator';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { MultipleSelectionWithRemoteSearch, TextField } from 'components/Form';
import Constants from 'utils/constants';

import { addInvoiceEmailRequest } from 'providers/EmailInvitationProvider/actions';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.email)) {
    errors.email = '無効なメールアドレスです';
  }
  return errors;
};

let ForwardInvoiceEmailForm = ({ onCancel, actionLoading, isBrand }) => {
  const invitationEmails = useSelector(
    (state) => state.emailInvitationProvider.invitationEmails,
    shallowEqual
  );
  const [{ invoiceEmails }, { onSearchEmail }] = useSearch(
    _get(
      invitationEmails,
      isBrand ? 'BRAND_INVOICE_FORWARD' : 'INVOICE_FORWARD',
      []
    )
  );

  let field = (
    <Field
      label="請求書を送付するメールアドレスを入力してください"
      name="email"
      placeholder="sample@gmail.com"
      component={TextField}
    />
  );

  if (isBrand) {
    field = (
      <>
        <Form.Item>
          <Typography.Text>
            このブランドに連絡用メールアドレスを追加することができます。 <br />
            一度に複数のメールアドレスを指定可能です。 <br />
            追加したメールアドレスにはブランドの月次の売上レポートが送付されます。
          </Typography.Text>
        </Form.Item>
        <Field
          label="メールアドレス"
          name="emails"
          placeholder="email@mail.com"
          options={invoiceEmails}
          component={MultipleSelectionWithRemoteSearch}
          onSearch={onSearchEmail}
          isShowAvatar={false}
        />
      </>
    );
  }

  const dispatch = useDispatch();

  return (
    <Form layout="vertical">
      {field}
      <Space
        direction="horizontal"
        size="middle"
        className="full-w flex justify-end"
      >
        <Button onClick={onCancel} disabled={actionLoading}>
          キャンセル
        </Button>
        <Button
          type="primary"
          onClick={() => dispatch(submit('forwardInvoiceEmailForm'))}
          loading={actionLoading}
        >
          追加
        </Button>
      </Space>
    </Form>
  );
};

ForwardInvoiceEmailForm = reduxForm({
  form: 'forwardInvoiceEmailForm',
  validate,
})(ForwardInvoiceEmailForm);

ForwardInvoiceEmailForm.propTypes = {
  onCancel: PropTypes.func,
  actionLoading: PropTypes.bool,
  isBrand: PropTypes.bool,
};

const showConfirm = (message, actionFn) => {
  Modal.confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const InvoiceEmailModal = ({
  restaurantId,
  brandId,
  handleFinish,
  handleCancel,
  title,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async ({ emails, email }) => {
    if (brandId && _isEmpty(emails)) {
      throw new SubmissionError({
        emails: Constants.ERROR_MESSAGE.REQUIRED,
      });
    }
    setActionLoading(true);
    try {
      await dispatch(
        addInvoiceEmailRequest({
          emails: emails || [email],
          restaurantId,
          brandId,
        })
      );
      handleFinish();
    } catch (err) {
      console.log(err);
    }
    setActionLoading(false);
  };
  const onCancel = () => showConfirm('中断してよろしいですか？', handleCancel);

  return (
    <Modal
      title={title}
      visible
      onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <ForwardInvoiceEmailForm
        actionLoading={actionLoading}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isBrand={!!brandId}
      />
    </Modal>
  );
};

InvoiceEmailModal.propTypes = {
  restaurantId: PropTypes.string,
  brandId: PropTypes.string,
  handleFinish: PropTypes.func,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
};

export default InvoiceEmailModal;
