import moment from 'moment';
import _get from 'lodash/get';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Button, Typography, Space, Badge
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { getPendingListRequest } from 'providers/RelationBetweenRestaurantAndRestaurantOwnerProvider/actions';
import { updateRestaurantInvitationStatusRequest } from 'providers/RestaurantOwnerProvider/actions';

import { DATE_TIME_FORMAT } from 'utils/constants';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import Table from 'components/Table';
import './style.less';
import ProfileInfo from 'components/ProfileInfo';

const PendingList = () => {
  const pendingList = useSelector(
    (state) => state.relationBetweenRestaurantAndRestaurantOwnerProvider.pendingList,
    shallowEqual
  );
  const [pageControl, setPageControl] = useState({
    page: '1',
    limit: '10',
  });
  const { page, limit } = pageControl;

  const onTableChange = ({ pagination }) => {
    setPageControl({
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    });
  };

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getPendingListRequest({
          page,
          limit,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [pageControl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteRestaurantInvite = async (record) => {
    setActionLoading(true);
    try {
      await dispatch(
        updateRestaurantInvitationStatusRequest({
          invitationId: _get(record, 'objectId'),
          status: 'DELETED',
        })
      );
      fetchData();
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const columns = [
    {
      title: 'レストラン名',
      width: 250,
      render: (record) => (
        <ProfileInfo
          image={_get(record, 'restaurant.image', DefaultRestaurantImage)}
          name={_get(record, 'restaurant.name', '-')}
          email={_get(record, 'restaurant.email', '-')}
        />
      ),
    },
    {
      title: '招待日',
      width: 150,
      dataIndex: 'statusLog',
      key: 'invitedDate',
      render: (record) => (_get(record, 'PENDING')
        ? moment(_get(record, 'PENDING')).format(DATE_TIME_FORMAT)
        : '-'),
    },
    {
      title: '削除',
      width: 120,
      key: 'action',
      fixed: 'right',
      render: (record) => (
        <Button
          type="text"
          danger
          icon={<CloseOutlined />}
          disabled={actionLoading}
          onClick={() => deleteRestaurantInvite(record)}
        >
          削除
        </Button>
      ),
    },
  ];

  if (pendingList?.total <= 0) {
    return null;
  }

  return (
    <Card
      className="highlight-card padding-card"
      bordered={false}
      id="pending-list-container"
    >
      <Space size={4} className="flex items-center padding-16 border-bottom">
        <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
          招待中のレストラン
        </Typography.Text>
        <Badge className="badge" count={pendingList.total} />
      </Space>
      <Table
        columns={columns}
        data={_get(pendingList, 'list', [])}
        total={_get(pendingList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        rowKey="objectId"
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
          marginRight: true,
        }}
        scroll={{ x: 520 }}
      />
    </Card>
  );
};

export default PendingList;
