import { createSelector } from 'reselect';

const selectNotification = (state) => state.notificationProvider || {};

const makeSelectNotificationList = () => createSelector(selectNotification, (state) => state.notificationList);

const makeSelectUnreadNotificationCount = () => createSelector(selectNotification, (state) => state.unreadCount);

const makeSelectNotificationListHasMore = () => createSelector(selectNotification, (state) => state.hasMore);

const makeSelectNotificationLoading = () => createSelector(selectNotification, (state) => state.loading);

export {
  makeSelectNotificationList,
  makeSelectUnreadNotificationCount,
  makeSelectNotificationLoading,
  makeSelectNotificationListHasMore
};
