import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _differenceWith from 'lodash/differenceWith';

import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { RightOutlined } from '@ant-design/icons';
import {
  Spin,
  Typography,
  Avatar,
  Checkbox,
  Space,
  Tooltip,
  Row,
  Col,
  Button,
} from 'antd';

import { getCategoryListRequest } from 'providers/CategoryProvider/actions';
import { getMenuItemListRequest } from 'providers/MenuProvider/actions';

import { STATUS, MENU_LIST_FILTER, MENU_PRICE_TYPE } from 'utils/constants';
import DefaultProductImage from 'images/defaultProduct.svg';

import Filter from './Fillter';

import '../style.less';

const { Text, Paragraph } = Typography;

const renderMenuList = ({
  menuLoading,
  menuItemList,
  selectedMenusMap,
  handleSelectMenuItem,
}) => {
  if (menuLoading) {
    return <Spin className="loading-icon" />;
  }

  return _map(menuItemList, (menuItem) => {
    const onClick = (e) => {
      e.preventDefault();
      handleSelectMenuItem(menuItem);
    };

    const isMenuSelected = !!selectedMenusMap[menuItem.objectId];
    const isMenuDisabled = !!selectedMenusMap[menuItem.objectId]
      && !_isEmpty(selectedMenusMap[menuItem.objectId].collectionIds);

    return (
      <button
        type="button"
        key={menuItem.objectId}
        className={`category-menu-item ${isMenuSelected ? '--selected' : ''}`}
        disabled={isMenuDisabled}
        onClick={onClick}
      >
        <div className="menu-item-detail">
          <Checkbox checked={isMenuSelected} disabled={isMenuDisabled}>
            <Space>
              <Avatar
                size={32}
                shape="square"
                src={_get(menuItem, 'images[0].url', DefaultProductImage)}
              />
              <div>
                <Tooltip title={menuItem.name}>
                  <Paragraph ellipsis={{ rows: 2 }}>{menuItem.name}</Paragraph>
                </Tooltip>
                {menuItem.note && (
                  <div className="menu-item-note">{menuItem.note}</div>
                )}
              </div>
            </Space>
          </Checkbox>
        </div>
      </button>
    );
  });
};

const renderCategoryList = ({
  dataLoading,
  list,
  selected,
  onSelect,
  disabled,
}) => {
  if (dataLoading) {
    return <Spin className="loading-icon" />;
  }

  return _map(list, (item) => (
    <button
      type="button"
      key={item.objectId}
      className={`category-menu-item ${
        selected === item.objectId ? '--selected' : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
        onSelect(item);
      }}
      disabled={disabled}
      size={'large'}
    >
      <div>{item.name}</div>
      <RightOutlined />
    </button>
  ));
};

const AssignMenu = ({ selectedMenusMap, setSelectedMenusMap }) => {
  const dispatch = useDispatch();
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [menuLoading, setMenusLoading] = useState(true);

  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedSubcategory, setSelectedSubcategory] = useState(-1);
  const [filter, setFilter] = useState({});

  // #region API Fetching and Retrieving Data

  const categoryListFromProps = useSelector(
    (state) => state.categoryProvider.categoryList,
    shallowEqual
  );
  const menuItemList = useSelector(
    (state) => state.menuProvider.menuItemList,
    shallowEqual
  );

  const fetchMenuItemList = useCallback(async () => {
    if (selectedSubcategory === -1) {
      return;
    }
    setMenusLoading(true);
    try {
      await dispatch(
        getMenuItemListRequest({
          page: 1,
          limit: 1000,
          keyword: filter?.menuKeyword,
          brandId: filter?.brandId,
          genreId: filter?.genreId,
          subCategoryId: selectedSubcategory,
          status: STATUS.ACTIVE,
          menuType: MENU_LIST_FILTER.NOT_BASIC,
          opts: { getMenuCountPerType: false, getWarehouseMenuData: false }
        })
      );
    } catch (err) {
      console.error(err);
    }
    setMenusLoading(false);
  }, [filter, selectedSubcategory]);

  useEffect(() => {
    fetchMenuItemList();
  }, [fetchMenuItemList]);

  const fetchCategoryList = useCallback(async () => {
    const { menuKeyword, brandId, genreId } = filter;
    setCategoryLoading(true);
    try {
      await dispatch(
        getCategoryListRequest({
          menuKeyword,
          menuBrandId: brandId,
          menuGenreId: genreId,
        })
      );
    } catch (err) {
      console.error(err);
    }
    setCategoryLoading(false);
  }, [filter]);

  useEffect(() => {
    // Use -1 as pending state, not handle anything when state is -1
    setSelectedSubcategory(-1);

    fetchCategoryList();
  }, [fetchCategoryList]);

  // #endregion

  const showSelectAll = (selectedCategory || selectedSubcategory || !_isEmpty(filter))
    && !_isEmpty(menuItemList);

  const handleSelectCategory = (id, subList) => {
    setSubcategoryList(subList);
    setSelectedCategory(id);
    setSelectedSubcategory(subList[0].objectId);
  };

  useEffect(() => {
    if (!_isEmpty(categoryListFromProps)) {
      // If category list is not empty, add all category options and auto select it
      const allCategoriesOption = {
        objectId: undefined,
        name: '全カテゴリー',
        childs: [{ objectId: undefined, name: '全サブカテゴリー' }],
      };

      setCategoryList([allCategoriesOption, ...categoryListFromProps]);
      handleSelectCategory(
        allCategoriesOption.objectId,
        allCategoriesOption.childs
      );
    } else {
      // If category list is empty, set default value
      setCategoryList([]);
      setSubcategoryList([]);
      setSelectedCategory(undefined);
      setSelectedSubcategory(undefined);
    }
  }, [categoryListFromProps]);

  const handleSelectMenuItem = (menuItem) => {
    if (!selectedMenusMap[menuItem.objectId]) {
      const masterMenuPrice = _find(
        menuItem.prices,
        (o) => o.type === MENU_PRICE_TYPE.MASTER
      );

      setSelectedMenusMap((pre) => {
        const incoming = { ...pre };
        incoming[menuItem.objectId] = {
          ...menuItem,
          menuPriceId: masterMenuPrice?.objectId,
        };
        return incoming;
      });
    } else {
      setSelectedMenusMap((pre) => {
        const incoming = { ...pre };
        delete incoming[menuItem.objectId];
        return incoming;
      });
    }
  };

  const handleSelectAll = () => {
    setSelectedMenusMap((pre) => {
      const incoming = { ...pre };
      const incomingMenus = _differenceWith(
        menuItemList,
        Object.keys(incoming),
        (a, b) => a.objectId === b
      );
      _forEach(incomingMenus, (menuItem) => {
        const masterMenuPrice = _find(
          menuItem.prices,
          (price) => price.type === MENU_PRICE_TYPE.MASTER
        );
        incoming[menuItem.objectId] = {
          ...menuItem,
          menuPriceId: masterMenuPrice.objectId,
        };
      });
      return incoming;
    });
  };

  const handleUnselectAll = () => {
    setSelectedMenusMap((pre) => {
      const incoming = { ...pre };
      _forEach(incoming, (val, key) => {
        if (_isEmpty(val.collectionIds)) {
          delete incoming[key];
        }
      });
      return incoming;
    });
  };

  return (
    <div id="menu-selector-column">
      <div id="menu-item-search">
        <Filter onFilter={setFilter} />
      </div>
      <div id="category-search-wrapper">
        <Row gutter={24} style={{ height: '100%' }}>
          <Col span={7} className="category-column-wrapper">
            <Text strong>カテゴリー</Text>
            <div className="category-column-content">
              {renderCategoryList({
                dataLoading: categoryLoading,
                list: categoryList,
                selected: selectedCategory,
                onSelect: (item) => {
                  handleSelectCategory(item.objectId, item.childs);
                },
              })}
            </div>
          </Col>
          <Col span={7} className="category-column-wrapper">
            <Text strong>サブカテゴリー</Text>
            <div className="category-column-content">
              {renderCategoryList({
                dataLoading: categoryLoading,
                list: subcategoryList,
                selected: selectedSubcategory,
                onSelect: (item) => {
                  setSelectedSubcategory(item.objectId);
                },
                disabled: menuLoading,
              })}
            </div>
          </Col>
          <Col span={10} className="category-column-wrapper">
            <div className="flex items-center full-w" style={{ maxHeight: 22 }}>
              <Text strong style={{ flexGrow: 1 }}>
                商品
              </Text>
              <Button type="link" danger onClick={handleUnselectAll}>
                全解除
              </Button>
              {showSelectAll && (
                <Button type="link" onClick={handleSelectAll}>
                  全選択
                </Button>
              )}
            </div>
            <div className="category-column-content">
              {renderMenuList({
                menuLoading: menuLoading || categoryLoading,
                menuItemList,
                selectedMenusMap,
                handleSelectMenuItem,
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

AssignMenu.propTypes = {
  selectedMenusMap: PropTypes.any,
  setSelectedMenusMap: PropTypes.any,
};

export default React.memo(AssignMenu);
