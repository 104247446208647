import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getRankingListSuccess,
  getRankingListError,
  getRankingDetailSuccess,
  getRankingDetailError,
  removeRankingMenuError,
  removeRankingMenuSuccess,
  reorderRankingListError,
  reorderRankingListSuccess,
  reorderRankingMenuError,
  reorderRankingMenuSuccess,
  addRankingMenuError,
  addRankingMenuSuccess,
  updateRankingError,
  updateRankingSuccess,
} from './actions';
import {
  GET_RANKING_LIST_REQUEST,
  GET_RANKING_DETAIL_REQUEST,
  REMOVE_RANKING_MENU_REQUEST,
  REORDER_RANKING_LIST_REQUEST,
  REORDER_RANKING_MENU_REQUEST,
  ADD_RANKING_MENU_REQUEST,
  UPDATE_RANKING_REQUEST,
} from './constants';

export function* handleGetRankingList(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRankingList`,
      payload
    );
    yield put(getRankingListSuccess(result, meta));
  } catch (error) {
    yield put(getRankingListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRankingDetail(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRankingDetail`,
      payload
    );
    yield put(getRankingDetailSuccess(result, meta));
  } catch (error) {
    yield put(getRankingDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveRankingMenu(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeRankingMenu`,
      payload
    );
    yield put(removeRankingMenuSuccess(result, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。'
    });
  } catch (error) {
    yield put(removeRankingMenuError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleReorderRankingList(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}reorderRankingList`,
      payload
    );
    yield put(reorderRankingListSuccess(result, meta));
  } catch (error) {
    yield put(reorderRankingListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleReorderRankingMenu(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}reorderRankingMenu`,
      payload
    );
    yield put(reorderRankingMenuSuccess(result, meta));
  } catch (error) {
    yield put(reorderRankingMenuError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAddRankingMenu(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}addRankingMenu`,
      payload
    );
    yield put(addRankingMenuSuccess(result, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。'
    });
  } catch (error) {
    yield put(addRankingMenuError(error, meta));
    if (error?.code === -1) {
      toastMessage.error({ message: '無効な商品をランキングに追加することはできません。', description: error.error });
    } else {
      toastMessage.error({ message: 'エラー', description: error.error });
    }
  }
}

export function* handleUpdateRanking(action) {
  const { payload, meta } = action;

  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateRanking`,
      payload
    );
    yield put(updateRankingSuccess(payload, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。'
    });
  } catch (error) {
    yield put(updateRankingError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_RANKING_LIST_REQUEST, handleGetRankingList);
  yield takeLatest(GET_RANKING_DETAIL_REQUEST, handleGetRankingDetail);
  yield takeLatest(REMOVE_RANKING_MENU_REQUEST, handleRemoveRankingMenu);
  yield takeLatest(REORDER_RANKING_LIST_REQUEST, handleReorderRankingList);
  yield takeLatest(REORDER_RANKING_MENU_REQUEST, handleReorderRankingMenu);
  yield takeLatest(ADD_RANKING_MENU_REQUEST, handleAddRankingMenu);
  yield takeLatest(UPDATE_RANKING_REQUEST, handleUpdateRanking);
}
