import _isNumber from 'lodash/isNumber';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Typography, Space } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

class InputNumberComponent extends React.PureComponent {
  render() {
    const {
      label,
      description,
      disabled,
      placeholder,
      defaultValue,
      min,
      max,
      formatter,
      parser,
      input,
      precision,
      addonBefore,
      className = '',
      meta: {
        touched, invalid, error, valid
      },
      requiredSpace,
      required,
      note = '',
      otherProps,
    } = this.props;
    const isError = touched && invalid && error;
    const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
    const requiredSpaceColor = requiredSpace
      ? 'transparent'
      : requiredIconColor;

    const onChange = (value) => {
      if (value === null) {
        return input.onChange('');
      }
      return input.onChange(value);
    };

    return (
      <Form.Item
        label={
          !description ? (
            label
          ) : (
            <Space
              style={{ display: 'inline-block' }}
              size={0}
              direction="vertical"
            >
              {label}
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {description}
              </Typography.Text>
            </Space>
          )
        }
        help={
          isError && (
            <Space size={4}>
              <InfoCircleOutlined />
              {error}
            </Space>
          )
        }
        validateStatus={touched && invalid && error ? 'error' : ''}
        className="field-form-item"
      >
        <div className="flex items-center">
          <InputNumber
            addonBefore={addonBefore}
            className={`full-w ${className}`}
            {...input}
            placeholder={placeholder}
            defaultValue={defaultValue}
            min={_isNumber(min) ? min : -10000000}
            max={_isNumber(max) ? max : 10000000}
            formatter={formatter}
            parser={parser}
            onFocus={(e) => e.preventDefault()}
            onBlur={(e) => e.preventDefault()}
            disabled={disabled}
            precision={precision}
            onChange={onChange}
            {...otherProps}
          />
          {(required || requiredSpace) && (
            <CheckCircleFilled
              style={{
                fontSize: 16,
                marginLeft: 8,
                color: requiredSpaceColor,
              }}
            />
          )}
        </div>
        {note}
      </Form.Item>
    );
  }
}

InputNumberComponent.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  note: PropTypes.any,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  formatter: PropTypes.any,
  otherProps: PropTypes.object,
  parser: PropTypes.any,
  disabled: PropTypes.bool,
  precision: PropTypes.any,
  meta: PropTypes.object.isRequired,
  addonBefore: PropTypes.string,
  className: PropTypes.string,
  requiredSpace: PropTypes.bool,
  required: PropTypes.bool,
};

export default InputNumberComponent;
