import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Space, Radio } from 'antd';

import OrderIcon from 'images/icon-order.svg';
import SupplierIcon from 'images/icon-supplier.svg';

import { getOrderListForBrandOwnerRequest } from 'providers/BrandOwnerProvider/actions';

import useTableControl from 'components/Hooks/useTableControl';
import OrderList from './OrderList';
import SupplierList from './SupplierList';
import BrandOwnerListSearchForm from './BranOwnerListSearchForm';

import './style.less';

const BrandOwnerList = () => {
  const dispatch = useDispatch();

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
      deliveryDateFrom: 'df',
      deliveryDateTo: 'dt',
      createdDateFrom: 'cf',
      createdDateTo: 'ct',
      brandId: 'bi',
      supplierId: 'si',
      viewMode: 'vm',
    },
    defaultFilter: {
      viewMode: 'ORDER',
    },
  });

  const {
    page, limit, orderBy, order
  } = pageControl;
  const {
    keyword,
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    brandId,
    supplierId,
    viewMode,
  } = filterControl;

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getOrderListForBrandOwnerRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
          deliveryDateFrom,
          deliveryDateTo,
          createdDateFrom,
          createdDateTo,
          supplierId,
          brandId,
          viewMode,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    limit,
    orderBy,
    order,
    keyword,
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    brandId,
    supplierId,
    viewMode,
  ]);

  const onFilter = (changedValues, allValues) => {
    let strDeliveryDateForm;
    let strDeliveryDateTo;
    let strCreatedDateFrom;
    let strCreatedDateTo;

    if (allValues.createdDate) {
      strCreatedDateFrom = allValues.createdDate[0]
        && moment(allValues.createdDate[0]).toISOString();
      strCreatedDateTo = allValues.createdDate[1]
        && moment(allValues.createdDate[1]).toISOString();
    }
    if (allValues.deliveryDate) {
      strDeliveryDateForm = allValues.deliveryDate[0]
        && moment(allValues.deliveryDate[0]).toISOString();
      strDeliveryDateTo = allValues.deliveryDate[1]
        && moment(allValues.deliveryDate[1]).toISOString();
    }

    onFilterChange({
      deliveryDateFrom: strDeliveryDateForm,
      deliveryDateTo: strDeliveryDateTo,
      createdDateFrom: strCreatedDateFrom,
      createdDateTo: strCreatedDateTo,
      supplierId: allValues.supplierId,
      brandId: allValues.brandId,
    });
  };

  return (
    <Card
      className="bg-transparent"
      id="order-list-brand-owner-container"
      bordered={false}
    >
      <Space direction="vertical" size="middle" className="full-w">
        <Card className="highlight-card padding-card-16" bordered={false}>
          <div
            className="flex items-center flex-wrap filter-container"
          >
            <div>
              <BrandOwnerListSearchForm
                filterControl={filterControl}
                onFilter={onFilter}
                onFilterChange={onFilterChange}
              />
            </div>
            <div>
              <Radio.Group
                style={{ whiteSpace: 'nowrap' }}
                onChange={(e) => {
                  onFilterChange({ viewMode: e.target.value });
                }}
                value={viewMode}
                buttonStyle="solid"
              >
                <Radio.Button value="SUPPLIER">
                  <Space>
                    <img src={SupplierIcon} alt="supplier-icon" />
                    販売者別表示
                  </Space>
                </Radio.Button>
                <Radio.Button value="ORDER">
                  <Space>
                    <img src={OrderIcon} alt="order-icon" />
                    注文表示
                  </Space>
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </Card>
        {viewMode === 'SUPPLIER' ? (
          <SupplierList
            pageControl={pageControl}
            onTableChange={onTableChange}
            tableLoading={tableLoading}
          />
        ) : (
          <OrderList
            onFilterChange={onFilterChange}
            filterControl={filterControl}
            pageControl={pageControl}
            onTableChange={onTableChange}
            tableLoading={tableLoading}
          />
        )}
      </Space>
    </Card>
  );
};

export default BrandOwnerList;
