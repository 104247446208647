import {
  GET_NOTIFICATION_LIST_REQUEST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERROR,
  GET_UNREAD_NOTIFICATION_COUNT_REQUEST,
  GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
  MARK_NOTIFICATION_READ_REQUEST,
  READ_ALL_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST
} from './constants';

export function getNotificationListRequest(data) {
  return { type: GET_NOTIFICATION_LIST_REQUEST, data };
}

export function getNotificationListSuccess(data) {
  return { type: GET_NOTIFICATION_LIST_SUCCESS, data };
}

export function getNotificationListError(error) {
  return { type: GET_NOTIFICATION_LIST_ERROR, error };
}

export function getUnreadNotificationCountRequest() {
  return { type: GET_UNREAD_NOTIFICATION_COUNT_REQUEST };
}

export function getUnreadNotificationCountSuccess(data) {
  return { type: GET_UNREAD_NOTIFICATION_COUNT_SUCCESS, data };
}

export function markNotificationReadRequest(data) {
  return { type: MARK_NOTIFICATION_READ_REQUEST, data };
}

export function readAllNotificationRequest() {
  return { type: READ_ALL_NOTIFICATION_REQUEST };
}

export function deleteNotificationRequest(data) {
  return { type: DELETE_NOTIFICATION_REQUEST, data };
}
