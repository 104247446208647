import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import ExportOrders from './ExportOrders';

const formSelector = formValueSelector('exportOrdersForm');

const mapStateToProps = createStructuredSelector({
  statusFixed: (state) => formSelector(state, 'FIXED'),
});

const withConnect = connect(mapStateToProps);

export default withConnect(ExportOrders);
