import _get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  Button,
  Typography,
  Card,
  Descriptions,
  Row,
  Col,
  Divider,
  Select,
  Spin,
  Space,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getBrandDetailRequest } from 'providers/BrandProvider/actions';

import { DATE_TIME_FORMAT } from 'utils/constants';

import PageHeader from 'components/PageHeader';
import DefaultBrandImage from 'images/defaultBrand.svg';

import NavigationButton from '../OperatorAndBrandOwner_Detail/NavigationButton';
import './style.less';

const { Item } = Descriptions;
const { Text } = Typography;

const BrandDetailForSupplier = ({ match }) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);

  const objectId = _get(match, 'params.brandId');

  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );

  const brandList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  const breadcrumbItems = [
    {
      path: '/brands',
      breadcrumbName: 'ブランド ',
    },
    {
      breadcrumbName: _get(brandDetail, 'name'),
    },
  ];

  const fetchBrandDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getBrandDetailRequest({ objectId }));
    } catch (error) {
      console.log(error);
    }
    setDataLoading(false);
  }, [objectId]);

  useEffect(() => {
    fetchBrandDetail();
  }, [fetchBrandDetail]);

  const handleGoBack = () => {
    dispatch(goBack());
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="brand-detail-page">
      <PageHeader
        title={(
          <div className="flex direction-column">
            <Text className="brand-name">{_get(brandDetail, 'name')}</Text>
            <Text
              className="email"
              copyable={
                !!brandDetail?.email && {
                  tooltips: ['コピー', 'コピーしました'],
                }
              }
            >
              {brandDetail?.email}
            </Text>
          </div>
        )}
        avatar={{ src: _get(brandDetail, 'image', DefaultBrandImage) }}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleGoBack}
        extra={NavigationButton(objectId, dispatch, brandList)}
      />
      <Card className="bg-transparent" bodyStyle={{ paddingTop: 0 }}>
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Card
              title="ブランド情報"
              className="highlight-card"
              bordered={false}
            >
              <Descriptions title="一般" column={1} bordered>
                <Item label="ブランド名">
                  <div>{brandDetail?.name}</div>
                  <Text>{brandDetail?.nameKana}</Text>
                </Item>
                <Item label="メールアドレス">{brandDetail?.email}</Item>
              </Descriptions>
              <Divider />
              <Descriptions title="担当者の連絡先" column={1} bordered>
                <Item label="担当者">
                  <div>{brandDetail?.managerName}</div>
                  <Text>{brandDetail?.managerNameKana}</Text>
                </Item>
                <Item label="担当者緊急連絡先">
                  {brandDetail?.emergencyPhone}
                </Item>
              </Descriptions>
            </Card>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" className="full-w flex" size={16}>
              <Card title="請求書" className="highlight-card" bordered={false}>
                <div className="flex direction-column">
                  <Text>請求書をダウンロードする月を選択してください。</Text>
                  <Typography.Title
                    style={{ fontSize: 14, fontWeight: 'normal' }}
                  >
                    請求書
                  </Typography.Title>
                  <div className="flex justify-between">
                    <div className="invoice-selected-dropdown">
                      <Select style={{ width: '100%' }} />
                    </div>
                    <Button disabled type="primary" icon={<DownloadOutlined />}>
                      ダウンロード
                    </Button>
                  </div>
                </div>
              </Card>
              <Card title="履歴" className="highlight-card" bordered={false}>
                <Descriptions bordered column={1}>
                  <Item label="招待日"></Item>
                  <Item label="承認日">
                    {moment(brandDetail?.supplierBrand?.createdAt).format(
                      DATE_TIME_FORMAT
                    )}
                  </Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

BrandDetailForSupplier.propTypes = {
  match: PropTypes.any,
};

export default BrandDetailForSupplier;
