import moment from 'moment';
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Col,
  Row,
  Card,
  Descriptions,
  Typography,
  Divider,
  Space,
  Button,
} from 'antd';

import { DATE_TIME_FORMAT } from 'utils/constants';
import { getSupplierDetailRequest } from 'providers/SupplierProvider/actions';
import EmailInvitation from 'components/EmailInvitation';
import StatusToggle from '../../List/StatusToggle';

import './style.less';

const { Item } = Descriptions;
const { Text } = Typography;

const SupplierInfo = () => {
  const dispatch = useDispatch();
  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const renderAddressDescription = (
    _postalCode,
    _prefecture,
    _address,
    _buildingName,
    _phone,
    _fax
  ) => (
    <Item>
      <div>
        <div>{_postalCode}</div>
        <div>
          {_prefecture} {_address}, {_buildingName}
        </div>
        <div>店舗電話番号: {_phone}</div>
        <div>店舗FAX番号: {_fax}</div>
      </div>
    </Item>
  );

  return (
    <Row gutter={[16, 16]} id="supplier-info-container">
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <Card
            className="highlight-card"
            title="プロフィール設定"
            bordered={false}
          >
            <Descriptions
              title="店舗名"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="会社詳細">
                <div>{supplierDetail?.name}</div>
                <Text>{supplierDetail?.nameKana}</Text>
              </Item>
            </Descriptions>
            <Divider />
            <Descriptions
              title="会社の住所"
              labelStyle={{ verticalAlign: 'top' }}
            >
              {renderAddressDescription(
                supplierDetail?.postalCode,
                supplierDetail?.prefecture,
                supplierDetail?.address,
                supplierDetail?.building,
                supplierDetail?.phone,
                supplierDetail?.fax
              )}
            </Descriptions>
            <Divider />
            <Descriptions
              title="会社の連絡先"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="担当者">
                <div>{supplierDetail?.managerName}</div>
                <Text>{supplierDetail?.managerNameKana}</Text>
              </Item>
              <Item label="担当者緊急連絡先">
                {supplierDetail?.emergencyPhone}
              </Item>
            </Descriptions>
          </Card>
          <Card className="highlight-card" title="請求書" bordered={false}>
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="新しい振込先銀行名">{supplierDetail?.bankName}</Item>
              <Item label="振込先支店名">{supplierDetail?.bankBranchName}</Item>
              <Item label="振込先口座種別">
                {supplierDetail?.bankAccountType}
              </Item>
              <Item label="振込先口座番号">
                {supplierDetail?.bankAccountNumber}
              </Item>
              <Item label="振込先口座名義">
                {supplierDetail?.bankAccountName}
              </Item>
              <Item label="請求書備考">{supplierDetail?.invoiceNote}</Item>
              <Item label="支払い期限を表示するかどうか">
                {supplierDetail?.invoiceShowPaymentDeadline ? 'はい' : 'いいえ'}
              </Item>
            </Descriptions>
          </Card>
        </Space>
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <Card title="アカウント" className="highlight-card" bordered={false}>
            <Descriptions column={1} bordered>
              <Item label="アカウント">
                <EmailInvitation
                  isPrimary
                  supplierId={supplierDetail?.objectId}
                  email={supplierDetail?.email}
                  emailVerified={supplierDetail?.emailVerified}
                />
              </Item>
              <Item>
                <div className="divider">
                  <Divider />
                </div>
              </Item>
              <Item label="ステータス">
                <StatusToggle
                  rowRecord={{
                    objectId: supplierDetail.objectId,
                    name: supplierDetail.name,
                    status: supplierDetail?.status,
                    callBack: () => {
                      dispatch(
                        getSupplierDetailRequest({
                          objectId: supplierDetail.objectId,
                        })
                      );
                    },
                  }}
                />
              </Item>
            </Descriptions>
          </Card>
          <Card title="販売者" className="highlight-card" bordered={false}>
            <Descriptions column={1} bordered>
              <Item label="レストラン件数">
                <Space className="flex justify-between">
                  <Text>{supplierDetail?.restaurantCount} レストラン</Text>
                  <Button
                    type="ghost"
                    onClick={() => dispatch(
                      push(
                        `/suppliers/detail/${supplierDetail.objectId}/connected-restaurants`
                      )
                    )}
                  >
                    詳細
                  </Button>
                </Space>
              </Item>
            </Descriptions>
            <Divider />
            <Descriptions column={1} bordered>
              <Item label="ブランド件数">
                <Space className="flex justify-between">
                  <Text>{supplierDetail?.brandCount} ブランド</Text>
                  <Button
                    type="ghost"
                    onClick={() => dispatch(
                      push(
                        `/suppliers/detail/${supplierDetail.objectId}/connected-brands`
                      )
                    )}
                  >
                    詳細
                  </Button>
                </Space>
              </Item>
            </Descriptions>
          </Card>
          <Card title="変更履歴" className="highlight-card" bordered={false}>
            <Descriptions column={1} bordered>
              <Item label="更新日">
                {moment(supplierDetail?.updatedAt).format(DATE_TIME_FORMAT)}
              </Item>
              <Divider />
              <Item label="作成日">
                {moment(supplierDetail?.createdAt).format(DATE_TIME_FORMAT)}
              </Item>
            </Descriptions>
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

export default SupplierInfo;
