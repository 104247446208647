import _get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Space, Typography, Avatar
} from 'antd';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import { DATE_TIME_FORMAT } from 'utils/constants';
import { getListSupplierForRestaurantRequest } from 'providers/SupplierProvider/actions';

import DefaultSupplierImage from 'images/defaultSupplier.svg';

const SupplierList = () => {
  const dispatch = useDispatch();

  const [pageControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const supplierList = useSelector(
    (state) => state.supplierProvider.supplierListForRestaurant,
    shallowEqual
  );
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail
  );
  const [tableLoading, setTableLoading] = useState(false);
  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getListSupplierForRestaurantRequest({
          page,
          limit,
          restaurant: restaurantDetail?.objectId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [dispatch, page, limit, restaurantDetail]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: '販売者',
      width: 250,
      render: (record) => (
        <Space className="flex items-center">
          <Avatar size={32} src={_get(record, 'image', DefaultSupplierImage)} />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </Space>
      ),
      fixed: 'left',
    },
    {
      title: '招待日',
      width: 150,
      dataIndex: ['supplierRestaurant', 'statusLog', 'PENDING'],
      render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '承認日',
      width: 150,
      dataIndex: ['supplierRestaurant', 'statusLog', 'ACCEPTED'],
      render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '商品件数',
      width: 120,
      dataIndex: 'menuCount',
      key: 'menuCount',
    },
  ];

  return (
    <Card
      className="highlight-card padding-card"
      bordered={false}
    >
      <Table
        columns={columns}
        data={_get(supplierList, 'list', [])}
        total={_get(supplierList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
          marginRight: true,
        }}
        scroll={{ x: 670 }}
      />
    </Card>
  );
};

export default SupplierList;
