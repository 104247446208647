import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Space, Typography } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import './style.less';

const TextField = (props) => {
  const {
    label,
    description,
    placeholder,
    input,
    type,
    disabled,
    maxLength,
    textArea = false,
    showCount,
    required,
    requiredSpace,
    meta: {
      touched, invalid, error, valid
    },
    ...rest
  } = props;
  const isError = touched && invalid && error;
  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace ? 'transparent' : requiredIconColor;
  const renderField = () => {
    if (textArea) {
      return (
        <Input.TextArea
          {...input}
          autoSize={{ minRows: 2, maxRows: 6 }}
          disabled={disabled}
          placeholder={placeholder}
          className="form-text-field"
          type={type}
          maxLength={maxLength}
          {...rest}
        />
      );
    }

    if (type === 'password') {
      return (
        <Input.Password
          disabled={disabled}
          {...input}
          placeholder={placeholder}
          className="form-text-field"
        />
      );
    }

    return (
      <Input
        disabled={disabled}
        {...input}
        placeholder={placeholder}
        className="form-text-field"
        type={type}
        maxLength={maxLength}
      />
    );
  };

  return (
    <Form.Item
      label={
        !description ? (
          label
        ) : (
          <Space
            style={{ display: 'inline-block' }}
            size={0}
            direction="vertical"
          >
            {label}
            {description && (
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {description}
              </Typography.Text>
            )}
          </Space>
        )
      }
      help={
        isError && (
          <Space size={4}>
            <InfoCircleOutlined />
            {error}
          </Space>
        )
      }
      validateStatus={isError ? 'error' : ''}
      className="field-form-item"
    >
      <div className="flex items-center">
        {renderField()}
        {(required || requiredSpace) && (
          <CheckCircleFilled
            style={{
              fontSize: 16,
              marginLeft: 8,
              color: requiredSpaceColor,
            }}
          />
        )}
      </div>
      {maxLength && showCount && (
        <Typography.Text
          type="secondary"
          style={{
            float: 'right',
            marginRight: required || requiredSpace ? 24 : 0,
          }}
        >
          {_get(input, 'value.length')}/{maxLength}
        </Typography.Text>
      )}
    </Form.Item>
  );
};

TextField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  textArea: PropTypes.bool,
  maxLength: PropTypes.number,
  showCount: PropTypes.any,
  required: PropTypes.bool,
  requiredSpace: PropTypes.bool,
};

export default TextField;
