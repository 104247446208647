import React from 'react';
import {
  Avatar, Typography, Tabs, Space
} from 'antd';
import { useDispatch } from 'react-redux';
import { replace, goBack } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import PageHeader from 'components/PageHeader';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

const { Text } = Typography;

const SupplierHeader = ({
  supplierDetail, footer, extra, supplierId
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedKeys = pathname.split('/')[4];
  const breadcrumbItems = [
    {
      path: '/suppliers',
      breadcrumbName: '販売者',
    },
    {
      breadcrumbName: supplierDetail?.name,
    },
  ];

  const onTabClick = (key) => {
    const pushPath = `/suppliers/detail/${supplierId}/${key}`;
    dispatch(replace(pushPath));
  };

  const renderSupplierInfoHeader = () => (
    <Space>
      <Avatar
        size={40}
        src={_get(supplierDetail, 'image', DefaultSupplierImage)}
      />
      <div style={{ lineHeight: 'normal' }}>
        <div className="supplier-name">{supplierDetail?.name}</div>
        <Text
          copyable={
            !!supplierDetail?.email && {
              tooltips: ['コピー', 'コピーしました'],
            }
          }
          className="email"
        >
          {supplierDetail?.email}
        </Text>
      </div>
    </Space>
  );

  return (
    <PageHeader
      title={renderSupplierInfoHeader()}
      breadcrumbRoutes={breadcrumbItems}
      onBack={() => dispatch(goBack())}
      footer={(
        <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
          {footer}
        </Tabs>
      )}
      extra={extra}
    />
  );
};

SupplierHeader.propTypes = {
  supplierDetail: PropTypes.object.isRequired,
  footer: PropTypes.element.isRequired,
  extra: PropTypes.element,
  supplierId: PropTypes.string.isRequired,
};

export default SupplierHeader;
