import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import toastMessage from 'utils/toastMessage';
import { STATUS } from 'utils/constants';

import { updateCollectionRequest } from 'providers/CollectionProvider/actions';

const StatusSwitch = ({ rowRecord }) => {
  const dispatch = useDispatch();
  const { status, objectId } = rowRecord;
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const nextStatus = status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE;
  const message = status === 'ACTIVE'
    ? 'セット商品を無効化しました'
    : 'セット商品を有効化しました';

  const handleChangeStatus = async () => {
    setChangeStatusLoading(true);
    try {
      await dispatch(updateCollectionRequest({ objectId, status: nextStatus }));
      toastMessage.success({
        description: message,
      });
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <div role="button" className="status-container" onClick={(e) => { e.stopPropagation(); }}>  {/*eslint-disable-line*/}
      <div className="status-content">
        <span className={`dot${status === STATUS.ACTIVE ? '' : '--inactive'}`} />
        <span>{status === STATUS.ACTIVE ? '有効' : '無効'}</span>
      </div>
      <Switch
        disabled={changeStatusLoading}
        checked={status === STATUS.ACTIVE}
        onClick={handleChangeStatus}
        size="small"
      />
    </div>
  );
};

StatusSwitch.propTypes = {
  rowRecord: PropTypes.any,
};

export default StatusSwitch;
