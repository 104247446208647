import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import {
  Card,
  Button,
  Form,
  Avatar,
  AutoComplete,
  Select,
  Tooltip,
  Typography,
  Space,
} from 'antd';

import withAuthorize from 'components/HOCs/withAuthorize';
import { USER_ROLE } from 'utils/constants';
import { getSupplierListRequest } from 'providers/SupplierProvider/actions';
import { getBrandListRequest } from 'providers/BrandProvider/actions';

import DefaultSupplierImage from 'images/defaultSupplier.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

const ProductListSearchForm = ({ filterControl, onFilterChange }) => {
  const { brandId, supplierId } = filterControl;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { list: supplierList } = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );
  const { list: brandList } = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      getSupplierListRequest({
        limit: 10000,
      })
    );
    dispatch(getBrandListRequest({ limit: 10000 }));
  }, []);

  const clearFilters = () => {
    form.setFieldsValue({
      supplierId: undefined,
      brandId: undefined,
    });

    onFilterChange({
      supplierId: '',
      brandId: '',
    });
  };

  const renderItem = (option, defaultImage) => (
    <Option
      key={option.objectId}
      value={option.objectId}
      label={(
        <Space className="flex items-center">
          <Avatar
            alt="avatar"
            src={option.image || <img alt="default" src={defaultImage} />}
            size={16}
          />
          <Typography.Text>{option.name}</Typography.Text>
        </Space>
      )}
    >
      <Card
        bordered={false}
        className="bg-transparent"
        bodyStyle={{ padding: '4px 3px' }}
      >
        <div className="full-w flex justify-between items-center">
          <Meta
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
            avatar={(
              <Avatar
                src={option.image || <img alt="default" src={defaultImage} />}
              />
            )}
            title={(
              <Tooltip placement="topLeft" title={option.name}>
                {option.name}
              </Tooltip>
            )}
            description={(
              <Tooltip placement="topLeft" title={option.email}>
                <Text type="secondary" ellipsis>
                  {option.email}
                </Text>
              </Tooltip>
            )}
          />
        </div>
      </Card>
    </Option>
  );

  const optionElementsBrand = _map(brandList, (o) => renderItem(o, DefaultBrandImage)
  );
  const optionElementsSupplier = _map(supplierList, (o) => renderItem(o, DefaultSupplierImage)
  );

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getSupplierListRequest({
          ...data,
          limit: 10000,
        })
      ),
      500
    ),
    []
  );

  const getRestaurantListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getBrandListRequest({ ...data, limit: 10000 })),
      500
    ),
    []
  );

  const onSearchSupplierList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const onSearchRestaurantList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getRestaurantListWithDelay(data);
  };

  const onFilter = (_, values) => {
    onFilterChange(values);
  };

  return (
    <Form
      layout="inline"
      initialValues={{
        supplierId,
        brandId,
      }}
      onValuesChange={onFilter}
      form={form}
    >
      <Space align="end">
        <Space wrap size={[8, 8]}>
          <Form.Item name="supplierId">
            <Select
              placeholder="販売者すべて"
              allowClear
              showSearch
              filterOption={false}
              style={{ width: '250px' }}
              onSearch={onSearchSupplierList}
              onClear={() => onSearchSupplierList('')}
              optionLabelProp="label"
            >
              {optionElementsSupplier}
            </Select>
          </Form.Item>
          <Form.Item name="brandId">
            <Select
              allowClear
              showSearch
              filterOption={false}
              style={{ width: '250px' }}
              placeholder="ブランドすべて"
              onSearch={onSearchRestaurantList}
              onClear={() => onSearchRestaurantList('')}
              optionLabelProp="label"
            >
              {optionElementsBrand}
            </Select>
          </Form.Item>
        </Space>
        <Button
          type="text"
          icon={<CloseOutlined />}
          style={{ color: '#1890ff' }}
          hidden={!supplierId && !brandId}
          onClick={clearFilters}
        >
          Clear all filters
        </Button>
      </Space>
    </Form>
  );
};

ProductListSearchForm.propTypes = {
  filterControl: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default withAuthorize([USER_ROLE.BRAND_OWNER])(ProductListSearchForm);
