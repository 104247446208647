import { call, put, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getShippingCompanyListSuccess,
  getShippingCompanyListError,
} from './actions';
import { GET_SHIPPING_COMPANY_LIST_REQUEST } from './constants';

export function* handleGetShippingCompanyList(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getShippingCompanyList`,
      action.data
    );
    yield put(getShippingCompanyListSuccess(response.result));
  } catch (error) {
    yield put(getShippingCompanyListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(GET_SHIPPING_COMPANY_LIST_REQUEST, handleGetShippingCompanyList);
}
