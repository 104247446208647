import produce from 'immer';

import {
  GET_SHIPPING_COMPANY_LIST_REQUEST,
  GET_SHIPPING_COMPANY_LIST_SUCCESS,
  GET_SHIPPING_COMPANY_LIST_ERROR,
} from './constants';

const initialState = {
  loading: false,
  error: false,
  shippingCompanyList: [],
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPPING_COMPANY_LIST_REQUEST:
      state.shippingCompanyList = [];
      state.loading = true;
      state.error = false;
      break;

    case GET_SHIPPING_COMPANY_LIST_SUCCESS:
      state.loading = false;
      state.shippingCompanyList = action.data;
      break;

    case GET_SHIPPING_COMPANY_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      break;
    default:
  }
  return state;
});

export default reducer;
