import _map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Space, Typography } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import './style.less';

const { Option } = Select;

const SelectField = (props) => {
  const {
    showSearch,
    label,
    placeholder,
    input,
    disabled,
    options,
    loading,
    onFormValueChange,
    allowClear = true,
    meta: {
      touched, invalid, error, valid
    },
    required,
    requiredSpace,
    sortValue,
    ...other
  } = props;
  const isError = touched && invalid && error;
  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace ? 'transparent' : requiredIconColor;
  const onChange = (value) => {
    input.onChange(value || '');
    if (onFormValueChange) {
      onFormValueChange(value);
    }
  };

  const optionElements = _map(options, (option) => (
    <Option
      key={option.objectId}
      value={option.objectId}
      style={{ width: '100%', overflow: 'hidden' }}
      disabled={option.disabled}
      filterValue={option.name}
    >
      <div className="flex items-center">
        {option.avatar && (
          <img
            width={16}
            height={16}
            alt="avatar"
            src={option.avatar}
            style={{
              borderRadius: '50%',
              marginRight: 4,
            }}
          />
        )}
        <Typography.Text ellipsis style={option.disabled && { color: 'gray' }}>
          {option.name}
        </Typography.Text>
        <div style={{ flexGrow: 1 }} />
        <Typography.Text style={option.disabled && { color: 'gray' }}>
          {option.extraText}
        </Typography.Text>
      </div>
    </Option>
  ));

  return (
    <Form.Item
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
      }}
      label={label}
      help={
        isError && (
          <Space size={4}>
            <InfoCircleOutlined />
            {error}
          </Space>
        )
      }
      validateStatus={touched && invalid && error ? 'error' : ''}
      className="field-form-item select-field"
    >
      <div
        className="flex items-center"
        style={{ width: '100%', flex: 1, lineHeight: 'normal' }}
      >
        <Select
          {...input}
          showSearch={showSearch}
          optionFilterProp="children"
          filterOption={(filterInput, filterOption) => filterOption.props?.filterValue
            ?.toLowerCase()
            .indexOf(filterInput?.toLowerCase()) >= 0}
          disabled={disabled}
          allowClear={allowClear}
          className="form-select-field"
          placeholder={placeholder}
          value={sortValue || input.value || undefined}
          onChange={onChange}
          onBlur={(e) => e.preventDefault()}
          loading={loading}
          {...other}
          style={{ flex: 1, width: '70%' }}
        >
          {optionElements}
        </Select>
        {(required || requiredSpace) && (
          <div style={{ width: 25 }}>
            <CheckCircleFilled
              style={{
                fontSize: 16,
                marginLeft: 8,
                color: requiredSpaceColor,
              }}
            />
          </div>
        )}
      </div>
    </Form.Item>
  );
};

SelectField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  allowClear: PropTypes.bool,
  onFormValueChange: PropTypes.any,
  required: PropTypes.bool,
  requiredSpace: PropTypes.bool,
  sortValue: PropTypes.array,
  showSearch: PropTypes.bool,
};

export default SelectField;
