import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import React, { useState, useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { replace, goBack } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { Button, Typography, Tabs } from 'antd';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PageHeader from 'components/PageHeader';
import {
  changeManufacturerStatusRequest,
  getManufacturerDetailRequest,
} from 'providers/ManufacturerProvider/actions';

import StatusSwitch from '../StatusSwitch';
import routes from './routes';

import './style.scss';

const { TabPane } = Tabs;

const ManufacturerDetail = ({ match, location }) => {
  const dispatch = useDispatch();

  const manufacturerCode = _get(match, 'params.manufacturerId');
  const selectedKeys = location.pathname.split('/')[4];

  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const manufacturerList = useSelector(
    (state) => state.manufacturerProvider.manufacturerList,
    shallowEqual
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getManufacturerDetailRequest({ manufacturerCode }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [manufacturerCode]);

  const manufacturerDetail = useSelector(
    (state) => state.manufacturerProvider.manufacturerDetail,
    shallowEqual
  );

  const handleChangeStatus = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(changeManufacturerStatusRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  const calcNavigateId = () => {
    const idList = _map(manufacturerList, (item) => item.manufacturerCode) || [];
    const index = _findIndex(idList, (item) => item === manufacturerCode);
    return {
      current: index,
      backTo: index > 0 && idList[index - 1],
      nextTo: index < idList.length - 1 && idList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, id) => {
    evt.preventDefault();
    dispatch(replace(`/manufacturers/detail/${id}`));
  };

  const renderNavigationButton = () => {
    const { current, backTo, nextTo } = calcNavigateId();
    if (manufacturerList.length < 1) {
      return [];
    }

    return [
      <span
        key="current"
        className="text-bold"
        style={{ fontSize: 14, marginLeft: 16 }}
      >
        {`${current + 1} / ${manufacturerList.length}`}
      </span>,
      <Button
        key="prev"
        type="primary"
        disabled={!backTo}
        onClick={(evt) => handleReplaceRoute(evt, backTo)}
        icon={<LeftOutlined />}
      />,
      <Button
        key="next"
        type="primary"
        disabled={!nextTo}
        onClick={(evt) => handleReplaceRoute(evt, nextTo)}
        icon={<RightOutlined />}
      />,
    ];
  };

  const renderHeaderTitle = () => (
    <div className="manufacturer-info">
      <Typography.Text style={{ fontSize: '20px' }}>
        {_get(manufacturerDetail, 'name', '--')}
      </Typography.Text>
      <div className="flex items-center">
        <p>ID: {manufacturerCode} | </p>
        <StatusSwitch
          status={_get(manufacturerDetail, 'status')}
          manufacturer={manufacturerDetail}
          isManufacturerLoading={changeStatusLoading}
          changeManufacturerStatus={handleChangeStatus}
        />
      </div>
    </div>
  );

  const breadcrumbItems = [
    {
      path: '/manufacturers',
      breadcrumbName: '製造会社',
    },
    {
      breadcrumbName: _get(manufacturerDetail, 'name'),
    },
  ];

  const onTabClick = (key) => {
    const pushPath = `/manufacturers/detail/${manufacturerCode}/${key}`;
    dispatch(replace(pushPath));
  };

  const onGoBack = () => dispatch(goBack());

  return (
    <div className="manufacturer-detail-container">
      <PageHeader
        title={renderHeaderTitle()}
        onBack={onGoBack}
        breadcrumbRoutes={breadcrumbItems}
        extra={[renderNavigationButton()]}
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="商品" key="product-list" />
            <TabPane tab="製造会社詳細" key="in-app" />
          </Tabs>
        )}
      />
      <Switch>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

ManufacturerDetail.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
};

export default ManufacturerDetail;
