import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Button, Card, Divider, Space, Typography
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  CheckboxField,
  CheckboxGroup,
  InputNumber,
  TimePickerField,
} from 'components/Form';

import { WORKING_DAYS } from 'utils/constants';
import Helpers from 'utils/helpers';
import InvoiceForm from './InvoiceForm';
import ShippingMethodForm from './ShippingMethodForm';
import validate from './validate';
import './style.less';

const workingDayColor = {
  6: '#1890ff',
  7: '#cf1421',
};

const workingDayOptions = Object.keys(WORKING_DAYS).map((key) => ({
  label: WORKING_DAYS[key],
  value: Number(key),
  style: { color: workingDayColor[key] || '#262626' },
}));

const RegisterFormStep2 = ({
  handleSubmit,
  onPrevious,
  actionLoading,
  formValues,
}) => {
  const formVal = useSelector(
    (state) => _get(state.form, 'supplierRegisterForm.values', {}),
    shallowEqual
  );

  return (
    <Form
      id="register-form-step2"
      labelCol={{ span: 7 }}
      colon={false}
      layout="horizontal"
      onSubmit={handleSubmit}
    >
      <Space size={40} direction="vertical" className="full-w">
        <div style={{ textAlign: 'center' }}>
          <Typography.Text>ステップ 2/2</Typography.Text>
          <br />
          <Typography.Text
            strong
            style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.85)' }}
          >
            受注業務に必要な情報を登録してください
          </Typography.Text>
        </div>
        <Space size={16} direction="vertical" className="full-w">
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="営業日"
          >
            <Space direction="vertical" className="full-w">
              <div>
                <Typography.Text>営業日を選択してください。</Typography.Text>
                <br />
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  更新・臨時休業日の設定はマイページから設定していただけます。
                </Typography.Text>
              </div>
              <span id="working-day-field">
                <Field
                  name="workingDays"
                  component={CheckboxGroup}
                  colSpan={3}
                  options={workingDayOptions}
                />
              </span>
              <Divider style={{ margin: 0 }} />
              <Field
                label="休業日をリードタイムに含める"
                description="リードタイムに休業日を含む場合のみチェックを入れてください。最短納品日の計算に影響します。"
                name="leadTimeIncludeHoliday"
                component={CheckboxField}
              />
              <Divider style={{ margin: 0 }} />
              <Field
                label="注文締め時間"
                name="orderDeadlineTime"
                timeFormat="HH:mm"
                component={TimePickerField}
              />
            </Space>
          </Card>
          <ShippingMethodForm formValues={formValues} />
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="送料無料の設定"
          >
            <Field
              label="送料"
              description="税込"
              name="shippingFee"
              component={InputNumber}
              min={0}
              precision={0}
              formatter={(value) => Helpers.numberWithCommas(value)}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonBefore="¥"
              requiredSpace
            />
            <Divider style={{ marginTop: 0 }} />
            <Field
              label="送料無料金額を設定する"
              description="一度の注文で「送料無料金額」を超える購入(税抜金額)があった場合、送料が無料になります。"
              name="setPriceOfFreeShip"
              component={CheckboxField}
            />
            <Field
              label="送料無料金額"
              description="税抜"
              name="priceOfFreeShip"
              disabled={!_get(formVal, 'setPriceOfFreeShip')}
              component={InputNumber}
              min={0}
              precision={0}
              formatter={(value) => Helpers.numberWithCommas(value)}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonBefore="¥"
              requiredSpace
            />
          </Card>
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="請求書"
          >
            <Form labelCol={{ span: 7 }} colon={false}>
              <InvoiceForm />
            </Form>
          </Card>
          <Space size={16} className="flex justify-end">
            <Button
              type="default"
              onClick={onPrevious}
              disabled={actionLoading}
            >
              戻る
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              // disabled={invalid}
              loading={actionLoading}
            >
              登録
            </Button>
          </Space>
        </Space>
      </Space>
    </Form>
  );
};

RegisterFormStep2.propTypes = {
  onPrevious: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.any,
  formValues: PropTypes.object,
};

export default reduxForm({
  form: 'supplierRegisterForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate,
})(RegisterFormStep2);
