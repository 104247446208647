import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { submit, isDirty } from 'redux-form';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import {
  Button, Space, Row, Col, Card, Typography, Empty
} from 'antd';

import EmptyMenu from 'images/icon-empty-product.svg';
import DefaultProductImage from 'images/defaultProduct.svg';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import CollectionCreateForm from './CollectionCreateForm';
import ProductDialog from './ProductUpdate';

const { Text } = Typography;

const CollectionsUpdate = ({
  title,
  onCancel,
  onSubmit,
  submitLoading,
  submitConfirm,
  handleAssignProductsSubmit,
  handleRemoveAssignedProduct,
  assignedProducts,
  collectionDetail,
  children,
  deleteButton,
  assignedProductsLoading,
  assignProductsSubmitLoading,
}) => {
  const [productDialog, setProductDialog] = useState(false);

  const dispatch = useDispatch();
  const dirty = useSelector((state) => isDirty('collectionCreateForm')(state));
  const [pageControl, , onTableChange] = useTableControl({});
  const { page, limit } = pageControl;

  const columns = [
    {
      title: '商品名',
      width: 300,
      key: 'name',
      render: (record) => (
        <Space className="flex items-center">
          <img
            style={{ borderRadius: 4 }}
            alt="name"
            src={_get(record, 'images[0].url', DefaultProductImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'note', '--')}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'right',
      width: 150,
      render: (record) => (
        <Button
          type="text"
          danger
          icon={<CloseOutlined />}
          onClick={() => handleRemoveAssignedProduct(record)}
        >
          削除
        </Button>
      ),
    },
  ];

  const button = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      disabled={assignedProductsLoading}
      onClick={() => setProductDialog(true)}
    >
      商品を追加
    </Button>
  );

  return (
    <>
      <Card
        className="bg-transparent collection-form"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
        style={{ paddingBottom: 64 }}
      >
        {productDialog && (
          <ProductDialog
            title={title}
            assignedProductList={assignedProducts?.list}
            handleCloseDialog={() => setProductDialog(false)}
            handleSubmit={handleAssignProductsSubmit}
            submitConfirm={submitConfirm}
            submitLoading={assignProductsSubmitLoading}
          />
        )}
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  className="highlight-card padding-card"
                  bordered={false}
                  title="商品リスト"
                  headStyle={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    padding: '0 16px',
                    borderBottom: '3px solid #e8e8e8',
                  }}
                  extra={!_isEmpty(assignedProducts?.list) && button}
                >
                  {assignedProducts?.total > 0 ? (
                    <Table
                      rowKey="objectId"
                      columns={columns}
                      data={assignedProducts?.list}
                      total={assignedProducts?.total}
                      onChange={onTableChange}
                      loading={assignedProductsLoading}
                      pagination={{
                        current: Number(page),
                        pageSize: Number(limit),
                        marginRight: true,
                      }}
                      scroll={{
                        x: 400,
                      }}
                    />
                  ) : (
                    <Space
                      size="middle"
                      direction="vertical"
                      align="center"
                      className="full-w"
                      style={{ paddingBottom: 24 }}
                    >
                      <Empty
                        image={EmptyMenu}
                        imageStyle={{
                          width: 160,
                          height: 160,
                          margin: '0 auto',
                        }}
                        description={(
                          <Text type="secondary">
                            商品が設定されておりません。
                          </Text>
                        )}
                      />
                      {button}
                    </Space>
                  )}
                </Card>
              </Col>
              <Col lg={{ span: 24 }} xs={{ span: 0 }}>
                {deleteButton}
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <CollectionCreateForm
                onSubmit={onSubmit}
                initialValues={{
                  name: collectionDetail?.name || '',
                  note: collectionDetail?.note || '',
                }}
              />
              {children}
            </Space>
          </Col>
          <Col lg={{ span: 0 }} xs={{ span: 24 }}>
            {deleteButton}
          </Col>
        </Row>
      </Card>
      {dirty && (
        <div
          style={{
            height: 64,
            zIndex: 10,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            left: 0,
          }}
        >
          <Space
            size="middle"
            className="full-w flex justify-end"
            style={{
              height: '100%',
              padding: '0 16px',
              backgroundColor: '#293137',
            }}
          >
            <Button onClick={onCancel} disabled={submitLoading}>
              キャンセル
            </Button>
            <Button
              type="primary"
              onClick={() => dispatch(submit('collectionCreateForm'))}
              loading={submitLoading}
              style={{ marginRight: '10px' }}
            >
              送信
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

CollectionsUpdate.propTypes = {
  collectionDetail: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  handleAssignProductsSubmit: PropTypes.func,
  submitLoading: PropTypes.bool,
  submitConfirm: PropTypes.any,
  handleRemoveAssignedProduct: PropTypes.func,
  title: PropTypes.string,
  assignedProductsLoading: PropTypes.bool,
  children: PropTypes.node,
  deleteButton: PropTypes.node,
  assignedProducts: PropTypes.object,
  assignProductsSubmitLoading: PropTypes.any,
};

export default CollectionsUpdate;
