import validator from 'validator';

import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.email)) {
    errors.email = '無効なメールアドレスです';
  }

  return errors;
};

export default validate;
