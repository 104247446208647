import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field, submit } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';

import { getBrandOwnerListRequest } from 'providers/BrandOwnerProvider/actions';
import DefaultBrandImage from 'images/defaultBrand.svg';
import { MultipleSelectionWithRemoteSearch } from 'components/Form';

import './style.less';

const AddBrandOwnerForm = ({
  onCancel,
  createActionLoading,
  brandId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getBrandOwnerListRequest({
        checkAssignedToBrandId: brandId,
        limit: 10000,
      })
    );
  }, [brandId]);

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getBrandOwnerListRequest({
          ...data,
          checkAssignedToBrandId: brandId,
          limit: 10000,
        })
      ),
      500
    ),
    []
  );

  const onSearchEmailKeyWork = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const { list: brandOwnerList } = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerList,
    shallowEqual
  );

  const handleSubmit = () => dispatch(submit('addBrandOwnerForm'));
  return (
    <Form layout="vertical">
      <div>
        <Field
          label="メールアドレス"
          name="brandOwnerIds"
          placeholder="email@mail.com"
          options={_map(brandOwnerList, (item) => ({
            ...item,
            isAdded: !!item?.isAssigned,
          }))}
          onSearch={onSearchEmailKeyWork}
          component={MultipleSelectionWithRemoteSearch}
          defaultImage={DefaultBrandImage}
        />
      </div>
      <div id="brand-owner-create-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button
            className="form-button"
            onClick={onCancel}
            disabled={createActionLoading}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            loading={createActionLoading}
            onClick={handleSubmit}
          >
            送信
          </Button>
        </Space>
      </div>
    </Form>
  );
};

AddBrandOwnerForm.propTypes = {
  onCancel: PropTypes.func,
  createActionLoading: PropTypes.any,
  brandId: PropTypes.any,
};

export default reduxForm({ form: 'addBrandOwnerForm' })(AddBrandOwnerForm);
