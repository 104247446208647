import {
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS,
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR,
  GET_RESTAURANT_POINT_INFO_REQUEST,
  GET_RESTAURANT_POINT_INFO_SUCCESS,
  GET_RESTAURANT_POINT_INFO_ERROR,
} from './constants';

export function getRestaurantDiscountPointHistoryRequest(payload) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantDiscountPointHistorySuccess(payload, meta) {
  return { type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_SUCCESS, payload, meta };
}

export function getRestaurantDiscountPointHistoryError(payload, meta) {
  return {
    type: GET_RESTAURANT_DISCOUNT_POINT_HISTORY_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantPointInfoRequest(payload) {
  return {
    type: GET_RESTAURANT_POINT_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantPointInfoSuccess(payload, meta) {
  return { type: GET_RESTAURANT_POINT_INFO_SUCCESS, payload, meta };
}

export function getRestaurantPointInfoError(payload, meta) {
  return {
    type: GET_RESTAURANT_POINT_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}
