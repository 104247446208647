import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  reset, isDirty, submit, getFormValues
} from 'redux-form';
import {
  Card, Space, Typography, Button, Affix, Modal
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  getBrandDetailRequest,
  updateBrandRequest,
} from 'providers/BrandProvider/actions';
import { getSupplierBrandListRequest } from 'providers/SupplierBrandProvider/actions';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import EmailInvitation from 'components/EmailInvitation';
import BrandCreateForm from '../../Create/BrandCreateForm';
import CreateSupplierBrand from '../../CreateSupplierBrand';
import AddBrandOwner from '../../AddBrandOwner';
import Columns from './Columns';
import BrandOwnerList from './BrandOwnerList';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const BrandDetail = ({ match }) => {
  const [updateActionLoading, setUpdateActionLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [showSupplierBrandModal, setShowSupplierBrandModal] = useState(false);
  const [showBrandOwnerModal, setShowBrandOwnerModal] = useState(false);

  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );
  const supplierBrandList = useSelector(
    (state) => state.supplierBrandProvider.supplierBrandList,
    shallowEqual
  );

  const dirty = useSelector((state) => isDirty('brandCreateForm')(state));
  const formValues = useSelector((state) => getFormValues('brandCreateForm')(state)
  );

  const objectId = _get(match, 'params.brandId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();

  const {
    page, limit, orderBy, order
  } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getSupplierBrandListRequest({
            page,
            limit,
            orderBy,
            order,
            brandId: objectId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, objectId]);

  const handleSubmit = async () => {
    setUpdateActionLoading(true);
    try {
      await dispatch(updateBrandRequest(formValues));
      dispatch(getBrandDetailRequest({ objectId }));
    } catch (err) {
      console.error(err);
    }
    setUpdateActionLoading(false);
  };

  const columnsAssignSupplier = [
    Columns.supplierName,
    Columns.createAt,
    Columns.productCount,
  ];

  return (
    <>
      <CreateSupplierBrand
        visible={showSupplierBrandModal}
        brand={objectId}
        handleCloseDialog={() => {
          setShowSupplierBrandModal(false);
        }}
      />
      <AddBrandOwner
        visible={showBrandOwnerModal}
        brandId={objectId}
        handleCloseDialog={() => {
          setShowBrandOwnerModal(false);
        }}
      />
      <BrandCreateForm
        initialValues={{
          ...brandDetail,
          image: brandDetail.image
            ? [{ uid: '-1', url: _get(brandDetail, 'image') }]
            : [],
          brandOwnerId: _get(brandDetail, 'brandOwner.objectId'),
        }}
        onSubmit={handleSubmit}
        isShowAssignField={false}
      >
        <Space direction="vertical" size={16} className="full-w">
          <Card
            bordered={false}
            className="highlight-card"
            title="連絡用メールアドレス"
            headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            <EmailInvitation
              brandId={objectId}
              name={brandDetail?.name}
              title="連絡用メールアドレスを追加"
              invoiceForwardEmailHeader="下記メールアドレスにも月次売上レポートが送付されます。"
              removeInvoiceForwardEmailConfirmText="{owner_name}の連絡メールアドレスから{cc_email}を削除してよろしいですか？"
            />
          </Card>
          <BrandOwnerList showConfirm={showConfirm} objectId={objectId} />
          <Card
            bordered={false}
            className="highlight-card padding-card"
            title="販売者"
            headStyle={{ fontWeight: 'bold', fontSize: '20px', padding: '0 16px' }}
            extra={(
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowSupplierBrandModal(true);
                }}
              >
                販売者追加
              </Button>
            )}
          >
            <Table
              columns={columnsAssignSupplier}
              data={_get(supplierBrandList, 'list', [])}
              total={_get(supplierBrandList, 'total', 0)}
              loading={tableLoading}
              onChange={onTableChange}
              pagination={{
                current: Number(page),
                pageSize: Number(limit),
              }}
              scroll={{ x: 470 }}
            />
          </Card>
        </Space>
      </BrandCreateForm>
      {dirty && (
        <Affix offsetBottom={0}>
          <div
            className="full-w flex justify-between items-center"
            style={{
              padding: '16px 16px 16px 24px',
              backgroundColor: '#293137',
            }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8}>
              <Button
                disabled={updateActionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('brandCreateForm'))
                )}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(submit('brandCreateForm'))}
                loading={updateActionLoading}
              >
                送信
              </Button>
            </Space>
          </div>
        </Affix>
      )}
    </>
  );
};

BrandDetail.propTypes = {
  match: PropTypes.any,
};
export default BrandDetail;
