import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Space, Typography } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

const RadioGroup = (props) => {
  const {
    label,
    description,
    disabled,
    input,
    options,
    isButton,
    fullWidth,
    requiredSpace,
    required,
    meta: {
      touched, invalid, error, valid
    },
    className,
    note,
  } = props;

  const isError = touched && invalid && error;
  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace ? 'transparent' : requiredIconColor;

  const optionElements = options
    && options.map((option) => {
      if (isButton) {
        let optionWidth = 100;
        if (Array.isArray(options) && options.length > 0) {
          optionWidth = 100 / options.length;
        }
        return (
          <Radio.Button
            style={{
              ...(fullWidth && { width: `${optionWidth}%` }),
              maxWidth: `${optionWidth}%`,
              padding: '0 3.5%',
              textAlign: 'center',
            }}
            className="radio-button"
            key={option.objectId}
            value={option.objectId}
            disabled={disabled}
          >
            {option.name}
          </Radio.Button>
        );
      }
      return (
        <div key={option.objectId}>
          <Radio value={option.objectId} disabled={disabled}>
            {option.name}
          </Radio>
        </div>
      );
    });

  return (
    <Form.Item
      className={`field-form-item ${fullWidth && 'full-w'} radio-field`}
      label={
        !description ? (
          label
        ) : (
          <Space
            style={{ display: 'inline-block' }}
            size={0}
            direction="vertical"
          >
            {label}
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {description}
            </Typography.Text>
          </Space>
        )
      }
      help={
        isError && (
          <Space size={4}>
            <InfoCircleOutlined />
            {error}
          </Space>
        )
      }
      validateStatus={touched && invalid && error ? 'error' : ''}
    >
      <div className="flex items-center">
        <div
          className="items-center"
          style={{
            width: '100%',
            flex: 1,
          }}
        >
          <Radio.Group
            style={{ width: '100%' }}
            {...input}
            onBlur={(e) => { e.preventDefault(); }}
            buttonStyle="solid"
            className={`radio-group ${className}`}
          >
            {optionElements}
          </Radio.Group>
          {note}
        </div>
        {(required || requiredSpace) && (
          <CheckCircleFilled
            style={{
              fontSize: 16,
              marginLeft: 8,
              color: requiredSpaceColor,
            }}
          />
        )}
      </div>
    </Form.Item>
  );
};

RadioGroup.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  description: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  isButton: PropTypes.bool,
  fullWidth: PropTypes.any,
  requiredSpace: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  note: PropTypes.any,
};

export default RadioGroup;
