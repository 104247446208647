import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';

import { STATUS } from 'utils/constants';

import './style.less';
import { updateRankingRequest } from 'providers/RankingProvider/actions';

const { confirm } = Modal;

const showConfirm = (message, content, actionFn) => {
  confirm({
    title: message,
    content,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const StatusToggle = ({ record, rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const handleChangeStatus = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(updateRankingRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <div
      role="button"
      className="status-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-hidden="true"
    >
      <div>
        <span
          className={`${record === STATUS.ACTIVE ? 'dot' : 'dot dot--inactive'}`}
        />
        <span>{record === STATUS.ACTIVE ? '有効' : '無効'}</span>
      </div>
      <Switch
        disabled={
          changeStatusLoading
          || rowRecord?.manufacturerStatus === STATUS.INACTIVE
        }
        size="small"
        checked={record === STATUS.ACTIVE}
        onClick={() => {
          const { objectId } = rowRecord;
          const { name } = rowRecord;
          const nextStatus = record === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE;
          const isActive = nextStatus === STATUS.ACTIVE;
          const message = isActive ? `ランキング ${name} を有効にしますか？` : `ランキング ${name} を無効にしますか？`;
          const content = isActive ? 'このランキングがレストランに表示されます。' : 'このランキングがレストランに表示されなくなります。';
          showConfirm(
            message,
            content,
            () => handleChangeStatus({
              objectId,
              status: nextStatus,
            })
          );
        }}
      />
    </div>
  );
};

StatusToggle.propTypes = {
  rowRecord: PropTypes.object,
  record: PropTypes.string,
};

export default StatusToggle;
