import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Button, Space, Avatar, Typography
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import { getRestaurantCollectionListRequest } from 'providers/CollectionProvider/actions';

import Table from 'components/Table';
import { STATUS } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import withRefresh from 'components/HOCs/withRefresh';
import useTableControl from 'components/Hooks/useTableControl';
import AddRestaurant from './AddRestaurant';

const RestaurantList = () => {
  const [showModal, setShowModal] = useState(false);

  const restaurantList = useSelector(
    (state) => state.collectionProvider.restaurantCollectionList,
    shallowEqual
  );
  const collectionDetail = useSelector(
    (state) => state.collectionProvider.collectionDetail,
    shallowEqual
  );
  const [pageControl, , onTableChange] = useTableControl();
  const { page, limit } = pageControl;
  const collectionId = collectionDetail?.objectId;
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantCollectionListRequest({
          collectionId,
          page,
          limit,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit]);

  const columns = [
    {
      title: 'ID',
      width: 150,
      dataIndex: ['restaurant', 'formattedObjectId'],
      key: 'formattedObjectId',
      fixed: 'left',
    },
    {
      title: 'レストラン名',
      dataIndex: 'restaurant',
      width: 250,
      render: (record) => (
        <div className="flex items-center full-w">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultRestaurantImage)}
            size={32}
          />
          <Space direction="vertical" size={0} className="restaurant-info-wrapper">
            <Typography.Text
              ellipsis={{
                tooltip: _get(record, 'name'),
              }}
            >{_get(record, 'name')}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email', '-')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
  ];

  const handleShowModal = () => {
    if (collectionDetail?.status === STATUS.INACTIVE) {
      toastMessage.error({
        description: 'Cannot add restaurant to a disabled collection',
      });
      return;
    }
    setShowModal(true);
  };

  return (
    <Card className="bg-transparent" bordered={false}>
      {showModal && (
        <AddRestaurant
          visible={showModal}
          collectionId={collectionId}
          handleCloseDialog={() => {
            setShowModal(false);
          }}
        />
      )}
      <Card className="highlight-card padding-card" bordered={false}>
        <Space className="flex items-center justify-end flex-wrap padding-16 border-bottom">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleShowModal}
          >
            レストラン選択
          </Button>
        </Space>
        <Table
          columns={columns}
          data={_get(restaurantList, 'list', [])}
          total={_get(restaurantList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 360 }}
        />
      </Card>
    </Card>
  );
};

export default withRefresh('restaurantList')(RestaurantList);
