import {
  GET_MANUFACTURER_LIST_REQUEST,
  GET_MANUFACTURER_LIST_SUCCESS,
  GET_MANUFACTURER_LIST_ERROR,
  CHANGE_STATUS_MANUFACTURER_REQUEST,
  CHANGE_STATUS_MANUFACTURER_SUCCESS,
  CHANGE_STATUS_MANUFACTURER_ERROR,
  GET_MANUFACTURER_DETAIL_REQUEST,
  GET_MANUFACTURER_DETAIL_SUCCESS,
  GET_MANUFACTURER_DETAIL_ERROR,
  CREATE_MANUFACTURER_REQUEST,
  CREATE_MANUFACTURER_SUCCESS,
  CREATE_MANUFACTURER_ERROR,
  EXPORT_MANUFACTURER_REQUEST,
  EXPORT_MANUFACTURER_SUCCESS,
  EXPORT_MANUFACTURER_ERROR,
  UPDATE_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_SUCCESS,
  UPDATE_MANUFACTURER_ERROR,
} from './constants';

export function getManufacturerListRequest(payload) {
  return {
    type: GET_MANUFACTURER_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getManufacturerListSuccess(payload, meta) {
  return { type: GET_MANUFACTURER_LIST_SUCCESS, payload, meta };
}

export function getManufacturerListError(payload, meta) {
  return {
    type: GET_MANUFACTURER_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function changeManufacturerStatusRequest(payload) {
  return {
    type: CHANGE_STATUS_MANUFACTURER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function changeManufacturerStatusSuccess(payload, meta) {
  return { type: CHANGE_STATUS_MANUFACTURER_SUCCESS, payload, meta };
}

export function changeManufacturerStatusError(payload, meta) {
  return {
    type: CHANGE_STATUS_MANUFACTURER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getManufacturerDetailRequest(payload) {
  return {
    type: GET_MANUFACTURER_DETAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getManufacturerDetailSuccess(payload, meta) {
  return { type: GET_MANUFACTURER_DETAIL_SUCCESS, payload, meta };
}

export function getManufacturerDetailError(payload, meta) {
  return {
    type: GET_MANUFACTURER_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function createManufacturerRequest(payload) {
  return { type: CREATE_MANUFACTURER_REQUEST, payload, meta: { thunk: true } };
}

export function createManufacturerSuccess(payload, meta) {
  return { type: CREATE_MANUFACTURER_SUCCESS, payload, meta };
}

export function createManufacturerError(payload, meta) {
  return {
    type: CREATE_MANUFACTURER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function exportManufacturerRequest(payload) {
  return { type: EXPORT_MANUFACTURER_REQUEST, payload, meta: { thunk: true } };
}

export function exportManufacturerSuccess(payload, meta) {
  return { type: EXPORT_MANUFACTURER_SUCCESS, payload, meta };
}

export function exportManufacturerError(payload, meta) {
  return {
    type: EXPORT_MANUFACTURER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateManufacturerRequest(payload) {
  return {
    type: UPDATE_MANUFACTURER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateManufacturerSuccess(payload, meta) {
  return { type: UPDATE_MANUFACTURER_SUCCESS, payload, meta };
}

export function updateManufacturerError(payload, meta) {
  return {
    type: UPDATE_MANUFACTURER_ERROR,
    payload,
    meta,
    error: true,
  };
}
