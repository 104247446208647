import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Divider } from 'antd';

import { CheckboxField, InputNumber } from 'components/Form';
import Helpers from 'utils/helpers';

import validate from './validate';

const ShippingFeeForm = ({ setPriceOfFreeShip, handleSubmit, disabled }) => (
  <Form
    colon={false}
    layout="horizontal"
    labelCol={{ span: 6 }}
    onSubmit={handleSubmit}
  >
    <Card
      headStyle={{ fontSize: 20, fontWeight: 'bold' }}
      className="highlight-card"
      title="送料無料の設定"
      bordered={false}
    >
      <Field
        label="送料"
        description="税込"
        name="shippingFee"
        component={InputNumber}
        min={0}
        precision={0}
        formatter={(value) => Helpers.numberWithCommas(value)}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        addonBefore="¥"
        disabled={disabled}
      />
      <Divider style={{ marginTop: 0 }} />
      <Field
        label="送料無料金額を設定する"
        description="一度の注文で「送料無料金額」を超える購入(税抜金額)があった場合、送料が無料になります。"
        name="setPriceOfFreeShip"
        component={CheckboxField}
        disabled={disabled}
      />
      <Field
        label="送料無料金額"
        description="税抜"
        name="priceOfFreeShip"
        disabled={disabled || !setPriceOfFreeShip}
        component={InputNumber}
        min={0}
        precision={0}
        formatter={(value) => Helpers.numberWithCommas(value)}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        addonBefore="¥"
      />
    </Card>
  </Form>
);

ShippingFeeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setPriceOfFreeShip: PropTypes.any,
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: 'shippingFeeForm',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(ShippingFeeForm);
