import React from 'react';
import {
  Space, Typography, Button, Modal
} from 'antd';
import PropTypes from 'prop-types';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: actionFn,
  });
};

const FormActionFooter = ({
  handleCancel,
  actionLoading,
  handleOk,
  isShowLabel = true,
  cancelText = '取り消す',
  okText = '保存する'
}) => (
  <div
    style={{
      zIndex: 999,
      position: 'sticky',
      bottom: 0,
      background: '#293137',
      width: '100%',
    }}
  >
    <Space
      size={8}
      className="flex justify-between full-w"
      style={{ padding: '16px 16px 16px 32px' }}
    >
      <Typography.Text style={{ color: '#fff' }}>
        {isShowLabel ? '保存されていない変更' : ''}
      </Typography.Text>
      <Space size={8} className="full-w flex justify-end">
        <Button
          type="default"
          disabled={actionLoading}
          onClick={() => showConfirm('中断してよろしいですか？', () => {
            handleCancel();
          })}
        >
          {cancelText}
        </Button>
        <Button type="primary" loading={actionLoading} onClick={handleOk}>
          {okText}
        </Button>
      </Space>
    </Space>
  </div>
);

FormActionFooter.propTypes = {
  actionLoading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  isShowLabel: PropTypes.bool,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
};

export default FormActionFooter;
