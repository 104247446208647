import {
  COUNT_UNREAD_CONVERSATION_REQUEST,
  COUNT_UNREAD_CONVERSATION_SUCCESS,
  GET_CONVERSATION_LIST_REQUEST,
  GET_CONVERSATION_LIST_SUCCESS,
  GET_CONVERSATION_LIST_ERROR,
  UPDATE_CONVERSATION,
} from './constants';

export function countUnreadConversationRequest() {
  return { type: COUNT_UNREAD_CONVERSATION_REQUEST };
}

export function countUnreadConversationSuccess(data) {
  return { type: COUNT_UNREAD_CONVERSATION_SUCCESS, data };
}

export function getConversationListRequest(data) {
  return { type: GET_CONVERSATION_LIST_REQUEST, data };
}

export function getConversationListSuccess(data) {
  return { type: GET_CONVERSATION_LIST_SUCCESS, data };
}

export function getConversationListError(error) {
  return { type: GET_CONVERSATION_LIST_ERROR, error };
}

export function updateConversationWhenReceiveLiveMessage(data) {
  return { type: UPDATE_CONVERSATION, data };
}
