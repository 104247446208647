export const CREATE_COLLECTION_REQUEST = 'CollectionProvider/CREATE_COLLECTION_REQUEST';
export const CREATE_COLLECTION_SUCCESS = 'CollectionProvider/CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_ERROR = 'CollectionProvider/CREATE_COLLECTION_ERROR';

export const GET_COLLECTION_LIST_REQUEST = 'CollectionProvider/GET_COLLECTION_LIST_REQUEST';
export const GET_COLLECTION_LIST_SUCCESS = 'CollectionProvider/GET_COLLECTION_LIST_SUCCESS';
export const GET_COLLECTION_LIST_ERROR = 'CollectionProvider/GET_COLLECTION_LIST_ERROR';

export const GET_RESTAURANT_COLLECTION_LIST_REQUEST = 'CollectionProvider/GET_RESTAURANT_COLLECTION_LIST_REQUEST';
export const GET_RESTAURANT_COLLECTION_LIST_SUCCESS = 'CollectionProvider/GET_RESTAURANT_COLLECTION_LIST_SUCCESS';
export const GET_RESTAURANT_COLLECTION_LIST_ERROR = 'CollectionProvider/GET_RESTAURANT_COLLECTION_LIST_ERROR';

export const GET_COLLECTION_DETAIL_REQUEST = 'CollectionProvider/GET_COLLECTION_DETAIL_REQUEST';
export const GET_COLLECTION_DETAIL_SUCCESS = 'CollectionProvider/GET_COLLECTION_DETAIL_SUCCESS';
export const GET_COLLECTION_DETAIL_ERROR = 'CollectionProvider/GET_COLLECTION_DETAIL_ERROR';

export const GET_COLLECTION_MENUS_REQUEST = 'CollectionProvider/GET_COLLECTION_MENUS_REQUEST';
export const GET_COLLECTION_MENUS_SUCCESS = 'CollectionProvider/GET_COLLECTION_MENUS_SUCCESS';
export const GET_COLLECTION_MENUS_ERROR = 'CollectionProvider/GET_COLLECTION_MENUS_ERROR';

export const REMOVE_COLLECTION_MENUS_REQUEST = 'CollectionProvider/REMOVE_COLLECTION_MENUS_REQUEST';
export const REMOVE_COLLECTION_MENUS_SUCCESS = 'CollectionProvider/REMOVE_COLLECTION_MENUS_SUCCESS';
export const REMOVE_COLLECTION_MENUS_ERROR = 'CollectionProvider/REMOVE_COLLECTION_MENUS_ERROR';

export const UPDATE_COLLECTION_REQUEST = 'CollectionProvider/UPDATE_COLLECTION_REQUEST';
export const UPDATE_COLLECTION_SUCCESS = 'CollectionProvider/UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_ERROR = 'CollectionProvider/UPDATE_COLLECTION_ERROR';

export const DELETE_COLLECTION_REQUEST = 'CollectionProvider/DELETE_COLLECTION_REQUEST';
export const DELETE_COLLECTION_SUCCESS = 'CollectionProvider/DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_ERROR = 'CollectionProvider/DELETE_COLLECTION_ERROR';

export const ASSIGN_MENU_TO_COLLECTION_REQUEST = 'CollectionProvider/ASSIGN_MENU_TO_COLLECTION_REQUEST';
export const ASSIGN_MENU_TO_COLLECTION_SUCCESS = 'CollectionProvider/ASSIGN_MENU_TO_COLLECTION_SUCCESS';
export const ASSIGN_MENU_TO_COLLECTION_ERROR = 'CollectionProvider/ASSIGN_MENU_TO_COLLECTION_ERROR';

export const ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST = 'CollectionProvider/ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST';
export const ASSIGN_RESTAURANT_TO_COLLECTION_SUCCESS = 'CollectionProvider/ASSIGN_RESTAURANT_TO_COLLECTION_SUCCESS';
export const ASSIGN_RESTAURANT_TO_COLLECTION_ERROR = 'CollectionProvider/ASSIGN_RESTAURANT_TO_COLLECTION_ERROR';
