import _get from 'lodash/get';
import _pick from 'lodash/pick';
import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';
import moment from 'moment';

import request from 'utils/request';
import Helpers from 'utils/helpers';
import {
  CLOUD_FUNCTION_PREFIX,
  MESSAGE,
  UTF_8_BOM,
  DATE_FORMAT_CSV_FILE_NAME,
} from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getManufacturerListSuccess,
  getManufacturerListError,
  changeManufacturerStatusSuccess,
  changeManufacturerStatusError,
  getManufacturerDetailSuccess,
  getManufacturerDetailError,
  createManufacturerSuccess,
  createManufacturerError,
  exportManufacturerSuccess,
  exportManufacturerError,
  updateManufacturerSuccess,
  updateManufacturerError,
} from './actions';
import {
  GET_MANUFACTURER_LIST_REQUEST,
  CHANGE_STATUS_MANUFACTURER_REQUEST,
  GET_MANUFACTURER_DETAIL_REQUEST,
  CREATE_MANUFACTURER_REQUEST,
  EXPORT_MANUFACTURER_REQUEST,
  UPDATE_MANUFACTURER_REQUEST,
} from './constants';

export function* handleGetManufacturerList(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getManufacturerList`,
      payload
    );
    yield put(getManufacturerListSuccess(result, meta));
  } catch (error) {
    yield put(getManufacturerListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleChangeManufacturerStatus(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}changeStatusManufacturer`,
      payload
    );
    yield put(changeManufacturerStatusSuccess(result, meta));
  } catch (error) {
    yield put(changeManufacturerStatusError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetManufacturerDetail(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getManufacturerDetail`,
      payload
    );
    yield put(getManufacturerDetailSuccess(result, meta));
  } catch (error) {
    yield put(getManufacturerDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleCreateManufacturer(action) {
  const { meta, payload } = action;
  try {
    const result = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createManufacturer`,
      payload
    );
    yield put(createManufacturerSuccess(result, meta));
  } catch (error) {
    yield put(createManufacturerError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleExportManufacturerList(action) {
  const { meta } = action;
  try {
    const { data } = yield call(request, '/manufacturerLists/export', {});
    const fileName = `${
      process.env.REACT_APP_CSV_PREFIX || ''
    }${moment().format(DATE_FORMAT_CSV_FILE_NAME)}_製造会社一覧.csv`;

    Helpers.saveAsFile(data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportManufacturerSuccess(MESSAGE.EXPORT_CSV_SUCCESS, meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportManufacturerError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleUpdateManufacturer(action) {
  const { meta, payload } = action;

  try {
    const params = _pick(payload, [
      'name',
      'nameKana',
      'code',
      'postalCode',
      'prefecture',
      'address',
      'buildingName',
      'phone',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'bankName',
      'bankBranchName',
      'bankAccountType',
      'bankAccountNumber',
      'manufacturerCode',
    ]);

    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateManufacturer`,
      params
    );
    yield put(updateManufacturerSuccess(response.result, meta));
    toastMessage.success({ description: 'Update Manufacturer success' });
  } catch (error) {
    yield put(updateManufacturerError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export default function* saga() {
  yield takeLatest(GET_MANUFACTURER_LIST_REQUEST, handleGetManufacturerList);
  yield takeLeading(CHANGE_STATUS_MANUFACTURER_REQUEST, handleChangeManufacturerStatus);
  yield takeLatest(GET_MANUFACTURER_DETAIL_REQUEST, handleGetManufacturerDetail);
  yield takeLeading(CREATE_MANUFACTURER_REQUEST, handleCreateManufacturer);
  yield takeLeading(EXPORT_MANUFACTURER_REQUEST, handleExportManufacturerList);
  yield takeLeading(UPDATE_MANUFACTURER_REQUEST, handleUpdateManufacturer);
}
