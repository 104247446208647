import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, Space, Row, Col, Tag
} from 'antd';
import moment from 'moment';

import { getSupplierHolidayListRequest } from 'providers/SupplierProvider/actions';

import { SHIPPING_METHOD_TYPE } from 'utils/constants';
import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';
import HolidaySettingForm from './HolidaySettingForm';

const HolidaySetting = ({ match }) => {
  const dispatch = useDispatch();

  const { list, total } = useSelector(
    (state) => state.supplierProvider.supplierHolidayList
  );
  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail
  );
  const supplierId = _get(match, 'params.supplierId');
  const shippingMethod = _get(supplierDetail, 'shippingMethod');

  const [holidayListLoading, setHolidayListLoading] = useState(false);

  const [{ page, limit }, , onTableChange] = useTableControl({});

  const mapShippingMethod = {
    [SHIPPING_METHOD_TYPE.OWN_SHIPPING]: '自社配送',
    [SHIPPING_METHOD_TYPE.SHIPPING_COMPANY]: '配送業者',
  };

  const fetchSupplierHolidayList = async () => {
    setHolidayListLoading(true);
    try {
      await dispatch(getSupplierHolidayListRequest({ limit, page, supplierId }));
    } catch (error) {
      console.error(error);
    }
    setHolidayListLoading(false);
  };

  useEffect(() => {
    fetchSupplierHolidayList();
  }, [page, limit]);

  const columns = [
    {
      title: '日時',
      dataIndex: 'date',
      render: (record) => moment(record).format('YYYY年MM月DD日'),
    },
    {
      title: 'ステータス',
      key: 'status',
      dataIndex: 'date',
      align: 'center',
      render: (record) => (
        <Tag
          color={moment(record) > moment() ? 'green' : 'default'}
          style={{ fontWeight: 500, fontSize: 14 }}
        >
          <Space>
            <div
              style={{
                borderRadius: '50%',
                width: 14,
                height: 14,
                backgroundColor:
                  moment(record) > moment() ? 'green' : 'rgba(0, 0, 0, 0.45)',
              }}
            />
            {moment(record) > moment() ? '設定済' : '過去日'}
          </Space>
        </Tag>
      ),
    },
  ];

  return (
    <Space
      direction="vertical"
      size={16}
      className="full-w flex"
      id="supplier-holiday-detail"
    >
      <Row gutter={16}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <HolidaySettingForm
            initialValues={{
              ...supplierDetail,
              orderDeadlineTime: moment(_get(supplierDetail, 'orderDeadlineTime')),
              workingDays: _get(supplierDetail, 'workingDays', []),
            }}
          />
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Card
            className="highlight-card"
            bordered={false}
            title="配送方法"
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
          >
            <div>{mapShippingMethod[shippingMethod]}</div>
          </Card>
        </Col>
      </Row>
      <Card
        className="highlight-card padding-card"
        bordered={false}
        title="休業日"
        headStyle={{
          fontSize: 20, fontWeight: 'bold', padding: '0 16px', borderBottom: '2px solid #e8e8e8'
        }}
        bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
      >
        <Table
          columns={columns}
          data={list}
          total={total}
          loading={holidayListLoading}
          onChange={onTableChange}
          pagination={
            total > limit && {
              current: Number(page),
              pageSize: Number(limit),
            }
          }
        />
      </Card>
    </Space>
  );
};

HolidaySetting.propTypes = {
  match: PropTypes.any,
};
export default HolidaySetting;
