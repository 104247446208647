import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

import TrackingIdForm from 'components/Form/TrackingIdForm';

import '@ant-design/compatible/assets/index.css';
import './style.scss';

const TrackingIdModal = ({
  visible, onSubmit, onCancel, orderDetail
}) => (
  <Modal
    className="tracking-id-modal"
    title='ステータスを"配送中"に変更しますか？'
    visible={visible}
    onCancel={onCancel}
    footer={false}
    destroyOnClose
    centered
    maskClosable={false}
    width={578}
  >
    <TrackingIdForm
      showDeliveryServiceSection
      initialValues={orderDetail?.trackingInfo || []}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isModalForm
    />
  </Modal>
);

TrackingIdModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  orderDetail: PropTypes.any,
};

export default TrackingIdModal;
