import {
  call,
  put,
  takeLeading,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  createCollectionSuccess,
  createCollectionError,
  getCollectionListSuccess,
  getCollectionListError,
  getCollectionDetailSuccess,
  getCollectionDetailError,
  updateCollectionSuccess,
  updateCollectionError,
  getCollectionMenusSuccess,
  getCollectionMenusError,
  removeCollectionMenusError,
  removeCollectionMenusSuccess,
  assignMenuToCollectionError,
  assignMenuToCollectionSuccess,
  getRestaurantCollectionListSuccess,
  getRestaurantCollectionListError,
  assignRestaurantToCollectionSuccess,
  assignRestaurantToCollectionError,
  deleteCollectionError,
  deleteCollectionSuccess,
} from './actions';
import {
  CREATE_COLLECTION_REQUEST,
  GET_COLLECTION_LIST_REQUEST,
  GET_COLLECTION_DETAIL_REQUEST,
  UPDATE_COLLECTION_REQUEST,
  GET_COLLECTION_MENUS_REQUEST,
  REMOVE_COLLECTION_MENUS_REQUEST,
  ASSIGN_MENU_TO_COLLECTION_REQUEST,
  GET_RESTAURANT_COLLECTION_LIST_REQUEST,
  ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST,
  DELETE_COLLECTION_REQUEST,
} from './constants';

export function* handleCreateCollection(action) {
  const { meta, payload } = action;
  try {
    const result = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createCollection`,
      payload
    );
    yield put(createCollectionSuccess(result, meta));
  } catch (error) {
    yield put(createCollectionError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetCollectionList(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getCollectionList`,
      payload
    );
    yield put(getCollectionListSuccess(result, meta));
  } catch (error) {
    yield put(getCollectionListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export function* handleGetRestaurantCollectionList(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantCollectionList`,
      payload
    );
    yield put(getRestaurantCollectionListSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantCollectionListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetCollectionDetail(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getCollectionDetail`,
      payload
    );
    yield put(getCollectionDetailSuccess(result, meta));
  } catch (error) {
    yield put(getCollectionDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetCollectionMenus(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getCollectionMenus`,
      payload
    );
    yield put(getCollectionMenusSuccess({ menus: result, ...payload }, meta));
  } catch (error) {
    yield put(getCollectionMenusError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateCollection(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateCollection`,
      payload
    );
    yield put(updateCollectionSuccess(result, meta));
  } catch (error) {
    yield put(updateCollectionError(error, meta));
    if (error.code === 9647) {
      toastMessage.error({
        message: 'コレクションを無効化できません。',
        description:
          'このコレクションは現在、別のレストランで使用中のため無効化することができません。',
      });
    } else {
      toastMessage.error({ message: 'エラー', description: error.error });
    }
  }
}
export function* handleDeleteCollection(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}deleteCollection`,
      payload
    );
    yield put(deleteCollectionSuccess(result, meta));
  } catch (error) {
    yield put(deleteCollectionError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveCollectionMenu(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeCollectionMenu`,
      payload
    );
    yield put(removeCollectionMenusSuccess(result, meta));
  } catch (error) {
    yield put(removeCollectionMenusError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAssignMenuToCollection(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}assignMenuToCollection`,
      payload
    );
    yield put(assignMenuToCollectionSuccess(result, meta));
  } catch (error) {
    yield put(assignMenuToCollectionError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export function* handleAssignRestaurantToCollection(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}assignRestaurantsToCollection`,
      payload
    );
    yield put(assignRestaurantToCollectionSuccess(result, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。',
    });
  } catch (error) {
    yield put(assignRestaurantToCollectionError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(CREATE_COLLECTION_REQUEST, handleCreateCollection);
  yield takeLeading(UPDATE_COLLECTION_REQUEST, handleUpdateCollection);
  yield takeLeading(DELETE_COLLECTION_REQUEST, handleDeleteCollection);
  yield takeLeading(
    REMOVE_COLLECTION_MENUS_REQUEST,
    handleRemoveCollectionMenu
  );
  yield takeLeading(
    ASSIGN_MENU_TO_COLLECTION_REQUEST,
    handleAssignMenuToCollection
  );
  yield takeLeading(
    ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST,
    handleAssignRestaurantToCollection
  );
  yield takeLatest(GET_COLLECTION_LIST_REQUEST, handleGetCollectionList);
  yield takeLatest(
    GET_RESTAURANT_COLLECTION_LIST_REQUEST,
    handleGetRestaurantCollectionList
  );
  yield takeLatest(GET_COLLECTION_DETAIL_REQUEST, handleGetCollectionDetail);
  yield takeEvery(GET_COLLECTION_MENUS_REQUEST, handleGetCollectionMenus);
}
