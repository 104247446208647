import {
  call, put, takeLatest, takeLeading, all
} from 'redux-saga/effects';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import request from 'utils/request';
import Helpers from 'utils/helpers';
import {
  MESSAGE,
  CLOUD_FUNCTION_PREFIX,
  UTF_8_BOM,
  DATE_FORMAT_CSV_FILE_NAME,
  STATUS,
} from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getMenuItemListSuccess,
  pagingGetMenuItemListError,
  pagingGetMenuItemListSuccess,
  getMenuItemListError,
  getMenuItemDetailSuccess,
  getMenuItemDetailError,
  createMenuItemSuccess,
  createMenuItemError,
  updateMenuItemSuccess,
  updateMenuItemError,
  getUnitListSuccess,
  getUnitListError,
  changeMenuItemStatusSuccess,
  changeMenuItemStatusError,
  exportMenuListSuccess,
  exportMenuListError,
  changeSoldOutStatusError,
  changeSoldOutStatusSuccess,
  removeProductRankingSuccess,
  removeProductRankingError,
  reorderProductRankingError,
  reorderProductRankingSuccess,
  addRankingProductSuccess,
  addRankingProductError,
} from './actions';
import {
  GET_MENU_ITEM_LIST_REQUEST,
  PAGING_GET_MENU_ITEM_LIST_REQUEST,
  GET_MENU_ITEM_DETAIL_REQUEST,
  CREATE_MENU_ITEM_REQUEST,
  UPDATE_MENU_ITEM_REQUEST,
  GET_UNIT_LIST_REQUEST,
  CHANGE_MENU_ITEM_STATUS_REQUEST,
  EXPORT_MENU_LIST_REQUEST,
  CHANGE_SOLD_OUT_STATUS_REQUEST,
  REMOVE_PRODUCT_RANKING_REQUEST,
  REORDER_PRODUCT_RANKING_REQUEST,
  ADD_PRODUCT_RANKING_REQUEST,
} from './constants';

const { getUploadUrlList, uploadFileList } = Helpers;

export function* handleGetMenuItemList(action) {
  const { page, limit } = _pick(action.payload, ['page', 'limit']);
  const params = {
    page: page || 1,
    limit: limit || 100,
    ..._pick(action.payload, [
      'subCategoryId',
      'menuType',
      'keyword',
      'genreId',
      'brandId',
      'status',
      'opts',
      'isPriceDiscounted',
      'genreId',
      'tagId',
    ]),
  };
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getMenuItemList`,
      params
    );
    yield put(getMenuItemListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getMenuItemListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handlePagingGetMenuItemList(action) {
  const { meta, payload } = action;
  const params = _pick(payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'status',
    'manufacturerCode',
    'brandId',
    'supplierId',
    'opts',
    'genreId',
    'isPriceDiscounted',
  ]);
  if (payload.menuType && payload.menuType !== 'ALL') {
    params.menuType = payload.menuType;
  }

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getMenuItemList`,
      params
    );
    yield put(pagingGetMenuItemListSuccess(result, meta));
  } catch (error) {
    yield put(pagingGetMenuItemListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetMenuItemDetail(action) {
  const { formattedObjectId } = _pick(action.payload, ['formattedObjectId']);
  const params = { formattedObjectId };
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getMenuItemDetail`,
      params
    );
    yield put(getMenuItemDetailSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getMenuItemDetailError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleCreateMenuItem(action) {
  const { payload, meta } = action;
  let fileIds = [];
  let incomingFileIds = [];
  let uploadImageResults = [];
  let uploadFileResults = [];
  const { images = [], snsFiles = [] } = payload;

  try {
    if (!_isEmpty(images)) {
      uploadImageResults = yield all(
        getUploadUrlList(images, 'generateUploadImageUrl', 'AVATAR')
      );
      fileIds = _map(uploadImageResults, (o) => _get(o, 'result.objectId'));
    }

    if (!_isEmpty(snsFiles)) {
      uploadFileResults = yield all(
        getUploadUrlList(snsFiles, 'generateUploadFileUrl', 'SNS')
      );
      incomingFileIds = [
        ...incomingFileIds,
        ..._map(uploadFileResults, (o) => _get(o, 'result.objectId')),
      ];
    }

    if (!_isEmpty(images) || !_isEmpty(snsFiles)) {
      yield all(
        uploadFileList(
          [...uploadImageResults, ...uploadFileResults],
          [...images, ...snsFiles]
        )
      );
    }

    const params = _pick(payload, [
      'isBasic',
      'name',
      'nameKatakana',
      'category',
      'subcategory',
      'genres',
      'brand',
      'note',
      'standard',
      'amount',
      'unit',
      'manufacturer',
      'cost',
      'leadTime',
      'maxLimitOrder',
      'minLimitOrder',
      'storageCondition',
      'allergen',
      'janCode',
      'freeCode',
      'isMarketPrice',
      'isIndefinitePrice',
      'collectionIds',
      'relatedProductIds',
      'stock_quantity',
      'stock_arrivalDate',
      'warehouse_airLogiIntegrated',
      'stock_isSoldOut',
      'warehouse_directShipment',
      'membership_tier',
      'order',
      'tagIds',
      'discountPrice',
      'snsText',
      'basicPricePerUnit',
      'basicPricePerMeal',
      'estimatedOfferPricePerMeal',
      'estimatedGrossProfitPerMeal',
      'howToCook',
      'preservationMethod',
      'remarksMemo',
      'mainAllergies',
      'subAllergies',
      'isAllowDownloadImage',
    ]);
    if (_get(payload, 'brand')) {
      params.brandFee = _get(payload, 'brandFee');
    }
    if (!_isEmpty(payload.prices)) {
      params.prices = _map(payload.prices, (price) => _pick(price, ['type', 'name', 'priceBeforeTax', 'taxRate'])
      );
    }
    params.images = fileIds;
    params.snsFiles = incomingFileIds;

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createMenuItem`,
      params
    );
    yield put(createMenuItemSuccess(result, meta));

    if (params?.warehouse_airLogiIntegrated && params?.janCode) {
      toastMessage.success({
        message: '商品を作成しました。',
        description: `商品 ${params?.name} を作成しました。現在、AiR Logi に同期中です。`,
      });
    } else if (params?.warehouse_airLogiIntegrated && !params?.janCode) {
      toastMessage.success({
        message: '商品を作成しました。',
        description: `商品 ${params?.name} を作成しました。現在、AiR Logi に同期中です。この商品はJANコードが未入力のため無効になっています。`,
      });
    } else {
      toastMessage.success({
        message: '商品を作成しました。',
        description: `商品 ${params?.name} を作成しました。`,
      });
    }
  } catch (error) {
    yield put(createMenuItemError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateMenuItem(action) {
  const { payload, meta } = action;

  const params = _pick(payload, [
    'isBasic',
    'name',
    'nameKatakana',
    'category',
    'subcategory',
    'genres',
    'brand',
    'note',
    'standard',
    'amount',
    'unit',
    'manufacturer',
    'cost',
    'leadTime',
    'maxLimitOrder',
    'minLimitOrder',
    'storageCondition',
    'allergen',
    'janCode',
    'freeCode',
    'formattedObjectId',
    'isMarketPrice',
    'isIndefinitePrice',
    'stock_quantity',
    'stock_arrivalDate',
    'stock_isSoldOut',
    'warehouse_directShipment',
    'warehouse_airLogiIntegrated',
    'membership_tier',
    'order',
    'tagIds',
    'discountPrice',
    'snsText',
    'basicPricePerUnit',
    'basicPricePerMeal',
    'estimatedOfferPricePerMeal',
    'estimatedGrossProfitPerMeal',
    'howToCook',
    'preservationMethod',
    'remarksMemo',
    'mainAllergies',
    'subAllergies',
    'isAllowDownloadImage',
  ]);
  let incomingImageIds = [];
  const preImageIds = [];
  const preSnsFileIds = [];
  let incomingFileIds = [];
  let uploadImageResults = [];
  let uploadFileResults = [];

  try {
    const uploadImageList = payload.images?.filter((image) => {
      if (_get(image, 'originFileObj')) {
        return true;
      }
      preImageIds.push(_get(image, 'objectId'));
      return false;
    });

    const uploadSnsFileList = payload.snsFiles?.filter((snsFile) => {
      if (_get(snsFile, 'originFileObj')) {
        return true;
      }

      preSnsFileIds.push(_get(snsFile, 'objectId'));
      return false;
    });

    if (!_isEmpty(uploadImageList)) {
      uploadImageResults = yield all(
        getUploadUrlList(uploadImageList, 'generateUploadImageUrl', 'AVATAR')
      );

      incomingImageIds = _map(uploadImageResults, (o) => _get(o, 'result.objectId')
      );

      yield all(uploadFileList(uploadImageResults, uploadImageList));
    }

    if (!_isEmpty(uploadSnsFileList)) {
      uploadFileResults = yield all(
        getUploadUrlList(uploadSnsFileList, 'generateUploadFileUrl', 'SNS')
      );

      incomingFileIds = _map(uploadFileResults, (o) => _get(o, 'result.objectId')
      );

      yield all(uploadFileList(uploadFileResults, uploadSnsFileList));
    }

    if (!_isEmpty(uploadImageList) || !_isEmpty(uploadSnsFileList)) {
      yield all(
        [...uploadImageResults, ...uploadFileResults],
        [...uploadImageList, ...uploadSnsFileList]
      );
    }

    if (_get(payload, 'brand')) {
      params.brandFee = _get(payload, 'brandFee');
    }
    if (!_isEmpty(payload.prices)) {
      params.prices = _map(payload.prices, (price) => _pick(price, ['objectId', 'type', 'name', 'priceBeforeTax', 'taxRate'])
      );
    }
    if (payload.images) {
      params.images = [...preImageIds, ...incomingImageIds];
    }

    if (payload.snsFiles) {
      params.snsFiles = [...preSnsFileIds, ...incomingFileIds];
    }

    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateMenuItem`,
      params
    );
    yield put(updateMenuItemSuccess(response.result, meta));
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    yield put(updateMenuItemError(error, meta));
    if (error?.code === 9501) {
      toastMessage.error({
        message: 'エラー',
        description: '現在、商品の更新はできません。',
      });
      return;
    }
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetUnitList() {
  const params = {};
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getUnitList`,
      params
    );
    yield put(getUnitListSuccess(response.result));
  } catch (error) {
    yield put(getUnitListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleToggleMenuItemStatus(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}changeStatusMenuItem`,
      payload
    );
    yield put(changeMenuItemStatusSuccess({ ...result, ...payload }, meta));
  } catch (error) {
    const errorCode = _get(error, 'code');
    switch (errorCode) {
      case 9518:
        toastMessage.error({
          message: '商品を有効にできませんでした。',
          description: 'この商品を有効にするには、仕入先を有効にしてください',
        });
        break;
      case 9649:
        toastMessage.error({
          message: 'エラー',
          description: 'JANコードが未入力のため商品を有効にできません。',
        });
        break;
      default:
        if (payload.status === STATUS.INACTIVE) {
          toastMessage.error({
            message: 'この商品を無効にすることができません。',
            description: error.error,
          });
        } else {
          toastMessage.error({ message: 'エラー', description: error.error });
        }
        break;
    }
    yield put(changeMenuItemStatusError(error, meta));
  }
}
export function* handleToggleSoldOutStatus(action) {
  const { payload, meta } = action;
  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}changeSoldOutStatusMenuItem`,
      payload
    );
    yield put(changeSoldOutStatusSuccess(payload, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。',
    });
  } catch (error) {
    toastMessage.error({ message: 'エラー', description: error.error });
    yield put(changeSoldOutStatusError(error, meta));
  }
}

export function* handleExportMenuList(action) {
  const { meta } = action;
  try {
    const { data } = yield call(request, '/menuList/export', {});
    const fileName = `${
      process.env.REACT_APP_CSV_PREFIX || ''
    }${moment().format(DATE_FORMAT_CSV_FILE_NAME)}_商品一覧.csv`;

    Helpers.saveAsFile(data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportMenuListSuccess(MESSAGE.EXPORT_CSV_SUCCESS, meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportMenuListError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleRemoveProductRanking(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeProductRanking`,
      payload
    );
    yield put(
      removeProductRankingSuccess(
        { ...result, menuItemId: payload.menuIds[0] },
        meta
      )
    );
    toastMessage.success({ description: '削除しました。' });
  } catch (error) {
    yield put(removeProductRankingError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleReorderProductRanking(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}reorderProductRanking`,
      payload
    );
    yield put(reorderProductRankingSuccess(result, meta));
  } catch (error) {
    yield put(reorderProductRankingError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAddRankingProduct(action) {
  const { meta, payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}addProductRanking`,
      payload
    );

    yield put(addRankingProductSuccess(result, meta));
  } catch (error) {
    yield put(addRankingProductError(error, meta));
    toastMessage.error({
      message: 'ランキング登録できませんでした。',
      description: error.error,
    });
  }
}

export default function* saga() {
  yield takeLatest(GET_MENU_ITEM_LIST_REQUEST, handleGetMenuItemList);
  yield takeLatest(
    PAGING_GET_MENU_ITEM_LIST_REQUEST,
    handlePagingGetMenuItemList
  );
  yield takeLatest(GET_MENU_ITEM_DETAIL_REQUEST, handleGetMenuItemDetail);
  yield takeLeading(CREATE_MENU_ITEM_REQUEST, handleCreateMenuItem);
  yield takeLeading(UPDATE_MENU_ITEM_REQUEST, handleUpdateMenuItem);
  yield takeLeading(GET_UNIT_LIST_REQUEST, handleGetUnitList);
  yield takeLeading(
    CHANGE_MENU_ITEM_STATUS_REQUEST,
    handleToggleMenuItemStatus
  );
  yield takeLeading(CHANGE_SOLD_OUT_STATUS_REQUEST, handleToggleSoldOutStatus);
  yield takeLeading(EXPORT_MENU_LIST_REQUEST, handleExportMenuList);
  yield takeLeading(REMOVE_PRODUCT_RANKING_REQUEST, handleRemoveProductRanking);
  yield takeLeading(
    REORDER_PRODUCT_RANKING_REQUEST,
    handleReorderProductRanking
  );
  yield takeLeading(ADD_PRODUCT_RANKING_REQUEST, handleAddRankingProduct);
}
