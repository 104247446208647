import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { operatorConnectSupplierRestaurantRequest } from 'providers/RestaurantProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import toastMessage from 'utils/toastMessage';
import AddRestaurantForm from './AddRestaurantForm';

const { confirm } = Modal;
const showConfirm = (title, content, actionFn) => {
  confirm({
    title,
    content,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const AddRestaurant = ({ handleCloseDialog, supplierId }) => {
  const dispatch = useDispatch();
  const [createActionLoading, setCreateActionLoading] = useState(false);

  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const handleCancel = () => {
    showConfirm(
      'この画面から移動してよろしいですか？',
      '入力した内容は全て破棄されます',
      handleCloseDialog
    );
  };

  const handleSubmit = async ({ restaurantIds }) => {
    if (_isEmpty(restaurantIds)) {
      toastMessage.error({
        message: 'Error',
        description: 'Please select at least 1 restaurant',
      });
    }
    if (!_isEmpty(restaurantIds)) {
      setCreateActionLoading(true);
      try {
        await dispatch(
          operatorConnectSupplierRestaurantRequest({
            restaurantIds,
            supplierId,
          })
        );
        toastMessage.success({
          message: '保存しました。',
          description: '変更した内容を保存しました。',
        });
        handleCloseDialog();
        dispatch(doRefresh({ target: 'connectedRestaurantOfSupplier' }));
      } catch (error) {
        console.error(error);
      }
      setCreateActionLoading(false);
    }
  };

  return (
    <Modal
      visible
      title={`${_get(supplierDetail, 'name')}にレストランを連携する`}
      onCancel={handleCancel}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
      width={578}
    >
      <p>
        複数のレストランを一括に連携することが可能
        <br />
        連携成功通知はレストランにも販売者にも送られません
      </p>
      <AddRestaurantForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        createActionLoading={createActionLoading}
        supplierId={supplierId}
      />
    </Modal>
  );
};

AddRestaurant.propTypes = {
  handleCloseDialog: PropTypes.func,
  supplierId: PropTypes.any,
};

export default AddRestaurant;
