import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import { getCollectionListRequest } from 'providers/CollectionProvider/actions';

import StatusSwitch from '../StatusSwitch';
import Columns from './Columns';

const CollectionsList = () => {
  const dispatch = useDispatch();

  const collectionList = useSelector(
    (state) => state.collectionProvider.collectionList,
    shallowEqual
  );

  const [pageControl, , onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(getCollectionListRequest({ page, limit }));
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit]);

  const columns = [
    Columns.ID,
    Columns.name,
    Columns.memo,
    Columns.productCount,
    Columns.createAt,
    Columns.updateAt,
    {
      ...Columns.status,
      render: (record, rowRecord) => (
        <StatusSwitch status={record} rowRecord={rowRecord} />
      ),
    },
  ];

  const handleRowClick = (record) => {
    dispatch(push(`/collections/detail/${record.objectId}`));
  };

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card
        className="highlight-card padding-card"
        bordered={false}
      >
        <div className="flex justify-end padding-16 border-bottom">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(push('/collections/create'))}
          >
            セット商品を追加
          </Button>
        </div>
        <Table
          bordered={false}
          columns={columns}
          data={_get(collectionList, 'list', [])}
          total={_get(collectionList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            marginRight: true,
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 1050 }}
          onRowClick={handleRowClick}
        />
      </Card>
    </Card>
  );
};

export default CollectionsList;
