/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Modal } from 'antd';

import {
  changePasswordRequest,
  clearCurrentUserData,
} from 'providers/AuthProvider/actions';

import PageHeader from 'components/PageHeader';
import ChangePasswordForm from './ChangePasswordForm';

const { success } = Modal;

const showConfirm = (message, actionFn) => {
  success({
    title: message,
    okText: 'ログイン画面へ',
    content: '新しいパスワードで再ログインしてください。',
    maskClosable: false,
    okType: 'primary',
    onOk: () => {
      actionFn();
    },
  });
};

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [actionLoading, setActionLoading] = useState(false);

  const breadcrumbItems = [
    {
      path: '/settings',
      breadcrumbName: '各種設定',
    },
    {
      breadcrumbName: 'パスワード変更',
    },
  ];

  const redirectToLogin = () => {
    dispatch(clearCurrentUserData());
    dispatch(push('/auth/login'));
  };

  const handleSubmit = async (value) => {
    setActionLoading(true);
    try {
      await dispatch(changePasswordRequest(value));
      showConfirm('パスワードを更新しました', redirectToLogin);
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={() => dispatch(push('/settings'))}
      />
      <Card className="bg-transparent" bordered={false}>
        <Card className="highlight-card max-width-644" bordered={false}>
          <ChangePasswordForm
            onSubmit={handleSubmit}
            actionLoading={actionLoading}
          />
        </Card>
      </Card>
    </>
  );
};

export default ChangePassword;
