import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row, Col, Card, Space
} from 'antd';
import {
  TextField,
  ImageField,
  MultipleSelectionWithRemoteSearch,
  RadioGroup,
} from 'components/Form';

import { getSupplierListRequest } from 'providers/SupplierProvider/actions';
import { getBrandOwnerListRequest } from 'providers/BrandOwnerProvider/actions';
import useSearch from 'components/EmailInvitation/useSearch';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';
import validate from './validate';

import './style.less';

const BrandCreateForm = ({
  handleSubmit,
  isShowAssignField = true,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSupplierListRequest({
        limit: 10000,
      })
    );
    dispatch(getBrandOwnerListRequest({ limit: 10000 }));
  }, []);

  const getSupplierListWithDelay = useCallback(
    _debounce((data, api) => dispatch(api({ ...data, limit: 10000 })), 500),
    []
  );

  const [{ invoiceEmails }, { onSearchEmail }] = useSearch();

  const onSearchEmailKeyWork = (value) => {
    const data = {};
    if (value !== '') {
      data.emailKeyword = value;
    }
    getSupplierListWithDelay(data, getSupplierListRequest);
  };

  const onSearchBrandOwner = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getSupplierListWithDelay(data, getBrandOwnerListRequest);
  };

  const { list } = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );

  const { list: brandOwnerList } = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerList,
    shallowEqual
  );

  return (
    <Form
      id="brand-create-form"
      colon={false}
      onSubmit={handleSubmit}
      layout="horizontal"
      labelCol={{ span: 6 }}
    >
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
      >
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <Card
                className="highlight-card"
                title="一般"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  name="image"
                  label="ブランドロゴ"
                  component={ImageField}
                  maxCount={1}
                />
                <Field
                  label="メールアドレス"
                  name="email"
                  component={TextField}
                  required
                />
                <Field
                  label="ブランド名"
                  name="name"
                  maxLength={50}
                  component={TextField}
                  required
                />
                <Field
                  label="ブランド名"
                  name="nameKana"
                  description="カナ"
                  maxLength={50}
                  component={TextField}
                  required
                />
                <Field
                  label="ブランド表示設定"
                  name="isVisibleToRestaurant"
                  component={RadioGroup}
                  options={[
                    { objectId: true, name: '表示する' },
                    { objectId: false, name: '表示しない' },
                  ]}
                  isButton
                />
                <Field
                  label="メモ"
                  name="memo"
                  maxLength={500}
                  component={TextField}
                  textArea
                  requiredSpace
                />
              </Card>
              <Card
                className="highlight-card"
                title="連絡先情報"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  name="managerName"
                  label="担当者名"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                />
                <Field
                  name="managerNameKana"
                  label="担当者名"
                  description="カナ"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                />
                <Field
                  name="emergencyPhone"
                  label="担当者緊急連絡先"
                  maxLength={11}
                  component={TextField}
                  requiredSpace
                />
              </Card>
            </Space>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              {isShowAssignField && (
                <>
                  <Card
                    className="highlight-card"
                    title="連絡用メールアドレス"
                    bordered={false}
                    headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
                  >
                    <Form labelCol={false} colon={false}>
                      <Field
                        label="メールアドレス"
                        name="ccEmails"
                        placeholder="名称/メールアドレスから検索"
                        options={invoiceEmails}
                        component={MultipleSelectionWithRemoteSearch}
                        onSearch={onSearchEmail}
                        isShowAvatar={false}
                      />
                    </Form>
                  </Card>
                  <Card
                    className="highlight-card"
                    title="ブランドオーナー"
                    bordered={false}
                    headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
                  >
                    <Form labelCol={false} colon={false}>
                      <Field
                        label="オーナー"
                        name="assignBrandOwnerIds"
                        placeholder="ブランドオーナー名から検索"
                        options={brandOwnerList}
                        component={MultipleSelectionWithRemoteSearch}
                        onSearch={onSearchBrandOwner}
                        defaultImage={DefaultBrandImage}
                      />
                    </Form>
                  </Card>
                  <Card
                    className="highlight-card"
                    title="販売者"
                    bordered={false}
                    headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
                  >
                    <Form labelCol={false} colon={false}>
                      <Field
                        label="販売者"
                        name="assignedSuppliers"
                        placeholder="販売者名から検索"
                        options={list}
                        component={MultipleSelectionWithRemoteSearch}
                        onSearch={onSearchEmailKeyWork}
                        defaultImage={DefaultSupplierImage}
                      />
                    </Form>
                  </Card>
                </>
              )}
              {children}
            </Space>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

BrandCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.element,
  isShowAssignField: PropTypes.bool,
};

export default reduxForm({
  form: 'brandCreateForm',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(BrandCreateForm);
