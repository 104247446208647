import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Field } from 'redux-form';
import { Card, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { ImageField, SelectField, TextField } from 'components/Form';

import { getPrefectureListRequest } from 'providers/PrefectureProvider/actions';

const ProfileForm = ({ handleSubmit, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrefectureListRequest());
  }, []);

  const prefectureList = useSelector(
    (state) => state.prefectureProvider.prefectureList,
    shallowEqual
  );

  return (
    <Form
      labelCol={{ span: 6 }}
      colon={false}
      layout="horizontal"
      onSubmit={handleSubmit}
    >
      <Space size={16} direction="vertical" className="full-w">
        <Card
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          className="highlight-card"
          title="企業情報"
          bordered={false}
        >
          <Field
            label="企業ロゴ"
            description="任意"
            name="image"
            component={ImageField}
            maxCount={1}
            shape="round"
          />
          <Field
            label="企業名"
            name="name"
            component={TextField}
            maxLength={50}
            required
          />
          <Field
            label="企業名"
            description="カナ"
            name="nameKana"
            component={TextField}
            maxLength={50}
            required
          />
        </Card>
        <Card
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          className="highlight-card"
          title="住所"
          bordered={false}
        >
          <Field
            label="郵便番号"
            name="postalCode"
            component={TextField}
            maxLength={7}
            required
          />
          <Field
            label="都道府県"
            name="prefecture"
            component={SelectField}
            loading={_isEmpty(prefectureList)}
            options={_map(prefectureList, (item) => ({
              objectId: item,
              name: item,
            }))}
            required
          />
          <Field
            label="住所1"
            description="市区町村"
            name="address"
            component={TextField}
            maxLength={50}
            required
          />
          <Field
            label="住所2"
            description="番地・建物名"
            name="building"
            component={TextField}
            maxLength={50}
            requiredSpace
          />
        </Card>
        <Card
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          className="highlight-card"
          title="連絡先"
          bordered={false}
        >
          <Field
            label="電話番号"
            name="phone"
            component={TextField}
            maxLength={11}
            required
          />
          <Field
            label="FAX番号"
            description="任意"
            name="fax"
            component={TextField}
            maxLength={11}
            requiredSpace
          />
          <Field
            label="担当者"
            name="managerName"
            component={TextField}
            maxLength={50}
            required
          />
          <Field
            label="担当者"
            description="カナ"
            name="managerNameKana"
            component={TextField}
            maxLength={50}
            required
          />
          <Field
            label="担当者緊急連絡先"
            description="※電話番号 / TEL"
            name="emergencyPhone"
            component={TextField}
            maxLength={11}
            required
          />
        </Card>
        {children}
      </Space>
    </Form>
  );
};

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
};
export default ProfileForm;
