import {
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_ERROR,
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_ERROR,
  UPDATE_ORDER_ITEMS_REQUEST,
  UPDATE_ORDER_ITEMS_SUCCESS,
  UPDATE_ORDER_ITEMS_ERROR,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_ERROR,
  EXPORT_ORDERS_REQUEST,
  EXPORT_ORDERS_SUCCESS,
  EXPORT_ORDERS_ERROR,
  ADD_ITEM_TO_ORDER,
  REMOVE_ITEM_FROM_ORDER,
  GET_ORDER_MESSAGE_LIST_REQUEST,
  GET_ORDER_MESSAGE_SUCCESS,
  GET_ORDER_MESSAGE_ERROR,
  CREATE_ORDER_MESSAGE_REQUEST,
  CREATE_ORDER_MESSAGE_ERROR,
  CREATE_ORDER_MESSAGE_SUCCESS,
  BULK_UPDATE_ORDERS_REQUEST,
  BULK_UPDATE_ORDERS_SUCCESS,
  BULK_UPDATE_ORDERS_ERROR,
  ADD_NEW_MESSAGE,
  EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST,
  EXPORT_ORDER_WITH_PURCHASE_INFO_SUCCESS,
  EXPORT_ORDER_WITH_PURCHASE_INFO_ERROR,
  UPDATE_TRACKING_INFO_REQUEST,
  UPDATE_TRACKING_INFO_SUCCESS,
  UPDATE_TRACKING_INFO_ERROR,
  EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST,
  EXPORT_ORDER_FOR_BRAND_OWNER_SUCCESS,
  EXPORT_ORDER_FOR_BRAND_OWNER_ERROR,
  SELECT_FILE_AIRLOGI_IMPORT_REQUEST,
  SELECT_FILE_AIRLOGI_IMPORT_SUCCESS,
  SELECT_FILE_AIRLOGI_IMPORT_ERROR,
  IMPORT_FILE_AIRLOGI_REQUEST,
  IMPORT_FILE_AIRLOGI_SUCCESS,
  IMPORT_FILE_AIRLOGI_ERROR,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR,
  RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST,
  RE_REGISTER_ORDER_TO_AIR_LOGI_SUCCESS,
  RE_REGISTER_ORDER_TO_AIR_LOGI_ERROR,
  UNLINK_ORDER_WITH_AIRLOGI_REQUEST,
  UNLINK_ORDER_WITH_AIRLOGI_SUCCESS,
  UNLINK_ORDER_WITH_AIRLOGI_ERROR,
} from './constants';

export function getOrderListRequest(data) {
  return { type: GET_ORDER_LIST_REQUEST, data };
}

export function getOrderListSuccess(data) {
  return { type: GET_ORDER_LIST_SUCCESS, data };
}

export function getOrderListError(data) {
  return { type: GET_ORDER_LIST_ERROR, data };
}

export function getOrderDetailRequest(payload) {
  return { type: GET_ORDER_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getOrderDetailSuccess(payload, meta) {
  return { type: GET_ORDER_DETAIL_SUCCESS, payload, meta };
}

export function getOrderDetailError(payload, meta) {
  return {
    type: GET_ORDER_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateOrderItemsRequest(data) {
  return { type: UPDATE_ORDER_ITEMS_REQUEST, data, meta: { thunk: true } };
}

export function updateOrderItemsSuccess(data, meta) {
  return { type: UPDATE_ORDER_ITEMS_SUCCESS, data, meta };
}

export function updateOrderItemsError(data, meta) {
  return {
    type: UPDATE_ORDER_ITEMS_ERROR,
    data,
    meta,
    error: true,
  };
}

export function updateOrderStatusRequest(data) {
  return { type: UPDATE_ORDER_STATUS_REQUEST, data, meta: { thunk: true } };
}

export function updateOrderStatusSuccess(data, meta) {
  return { type: UPDATE_ORDER_STATUS_SUCCESS, data, meta };
}

export function updateOrderStatusError(data, meta) {
  return {
    type: UPDATE_ORDER_STATUS_ERROR,
    data,
    meta,
    error: true,
  };
}

export function exportOrdersRequest(payload) {
  return { type: EXPORT_ORDERS_REQUEST, payload, meta: { thunk: true } };
}

export function exportOrdersSuccess(payload, meta) {
  return { type: EXPORT_ORDERS_SUCCESS, payload, meta };
}

export function exportOrdersError(payload, meta) {
  return {
    type: EXPORT_ORDERS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addItemToOrder(data) {
  return { type: ADD_ITEM_TO_ORDER, data };
}

export function removeItemFromOrder(data) {
  return { type: REMOVE_ITEM_FROM_ORDER, data };
}
export function getOrderMessageListRequest(data) {
  return { type: GET_ORDER_MESSAGE_LIST_REQUEST, data };
}
export function getOrderMessageSuccess(data) {
  return { type: GET_ORDER_MESSAGE_SUCCESS, data };
}
export function getOrderMessageError(data) {
  return { type: GET_ORDER_MESSAGE_ERROR, data };
}
export function createOrderMessageRequest(data) {
  return { type: CREATE_ORDER_MESSAGE_REQUEST, data, meta: { thunk: true } };
}
export function createOrderMessageSuccess(data, meta) {
  return { type: CREATE_ORDER_MESSAGE_SUCCESS, data, meta };
}
export function createOrderMessageError(data, meta) {
  return { type: CREATE_ORDER_MESSAGE_ERROR, data, meta };
}
export function bulkUpdateOrdersRequest(payload) {
  return { type: BULK_UPDATE_ORDERS_REQUEST, payload, meta: { thunk: true } };
}
export function bulkUpdateOrdersSuccess(payload, meta) {
  return { type: BULK_UPDATE_ORDERS_SUCCESS, payload, meta };
}
export function bulkUpdateOrdersError(payload, meta) {
  return {
    type: BULK_UPDATE_ORDERS_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function addLiveMessageToOrderMessages(data) {
  return { type: ADD_NEW_MESSAGE, data };
}

export function exportOrdersWithPurchaseInfoRequest(payload) {
  return {
    type: EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}
export function exportOrdersWithPurchaseInfoSuccess(payload, meta) {
  return { type: EXPORT_ORDER_WITH_PURCHASE_INFO_SUCCESS, payload, meta };
}
export function exportOrdersWithPurchaseInfoError(payload, meta) {
  return {
    type: EXPORT_ORDER_WITH_PURCHASE_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateTrackingInfoRequest(payload) {
  return { type: UPDATE_TRACKING_INFO_REQUEST, payload, meta: { thunk: true } };
}
export function updateTrackingInfoSuccess(payload, meta) {
  return { type: UPDATE_TRACKING_INFO_SUCCESS, payload, meta };
}
export function updateTrackingInfoError(payload, meta) {
  return { type: UPDATE_TRACKING_INFO_ERROR, payload, meta };
}

export function exportOrderForBrandOwnerRequest(payload) {
  return {
    type: EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function exportOrderForBrandOwnerSuccess(payload, meta) {
  return { type: EXPORT_ORDER_FOR_BRAND_OWNER_SUCCESS, payload, meta };
}

export function exportOrderForBrandOwnerError(payload, meta) {
  return {
    type: EXPORT_ORDER_FOR_BRAND_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function selectFileForAirLogiImportRequest(payload) {
  return {
    type: SELECT_FILE_AIRLOGI_IMPORT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function selectFileForAirLogiImportSuccess(payload, meta) {
  return { type: SELECT_FILE_AIRLOGI_IMPORT_SUCCESS, payload, meta };
}

export function selectFileForAirLogiImportError(payload, meta) {
  return {
    type: SELECT_FILE_AIRLOGI_IMPORT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function importFileAirLogiRequest(payload) {
  return {
    type: IMPORT_FILE_AIRLOGI_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function importFileAirLogiSuccess(payload, meta) {
  return { type: IMPORT_FILE_AIRLOGI_SUCCESS, payload, meta };
}

export function importFileAirLogiError(payload, meta) {
  return {
    type: IMPORT_FILE_AIRLOGI_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getAirLogiImportHistoryListRequest(payload) {
  return {
    type: GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getAirLogiImportHistoryListSuccess(payload, meta) {
  return { type: GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS, payload, meta };
}

export function getAirLogiImportHistoryListError(payload, meta) {
  return {
    type: GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getAirLogiImportHistoryDetailRequest(payload) {
  return {
    type: GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getAirLogiImportHistoryDetailSuccess(payload, meta) {
  return { type: GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS, payload, meta };
}

export function getAirLogiImportHistoryDetailError(payload, meta) {
  return {
    type: GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function reRegisterOrderToAirLogiRequest(payload) {
  return {
    type: RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function reRegisterOrderToAirLogiSuccess(payload, meta) {
  return { type: RE_REGISTER_ORDER_TO_AIR_LOGI_SUCCESS, payload, meta };
}

export function reRegisterOrderToAirLogiError(payload, meta) {
  return {
    type: RE_REGISTER_ORDER_TO_AIR_LOGI_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function unlinkOrderWithAirLogiRequest(payload) {
  return {
    type: UNLINK_ORDER_WITH_AIRLOGI_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function unlinkOrderWithAirLogiSuccess(payload, meta) {
  return { type: UNLINK_ORDER_WITH_AIRLOGI_SUCCESS, payload, meta };
}

export function unlinkOrderWithAirLogiError(payload, meta) {
  return {
    type: UNLINK_ORDER_WITH_AIRLOGI_ERROR,
    payload,
    meta,
    error: true,
  };
}
