import {
  GET_IN_APP_MESSAGE_LIST_REQUEST,
  GET_IN_APP_MESSAGE_LIST_SUCCESS,
  GET_IN_APP_MESSAGE_LIST_ERROR,
  GET_IN_APP_MESSAGE_DETAIL_REQUEST,
  GET_IN_APP_MESSAGE_DETAIL_SUCCESS,
  GET_IN_APP_MESSAGE_DETAIL_ERROR,
  CREATE_IN_APP_MESSAGE_REQUEST,
  CREATE_IN_APP_MESSAGE_SUCCESS,
  CREATE_IN_APP_MESSAGE_ERROR,
  STOP_IN_APP_MESSAGE_REQUEST,
  STOP_IN_APP_MESSAGE_SUCCESS,
  STOP_IN_APP_MESSAGE_ERROR,
} from './constants';

export function getInAppMessageListRequest(payload) {
  return { type: GET_IN_APP_MESSAGE_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getInAppMessageListSuccess(payload, meta) {
  return { type: GET_IN_APP_MESSAGE_LIST_SUCCESS, payload, meta };
}

export function getInAppMessageListError(payload, meta) {
  return {
    type: GET_IN_APP_MESSAGE_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getInAppMessageDetailRequest(payload) {
  return { type: GET_IN_APP_MESSAGE_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getInAppMessageDetailSuccess(payload, meta) {
  return { type: GET_IN_APP_MESSAGE_DETAIL_SUCCESS, payload, meta };
}

export function getInAppMessageDetailError(payload, meta) {
  return {
    type: GET_IN_APP_MESSAGE_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function createInAppMessageRequest(payload) {
  return { type: CREATE_IN_APP_MESSAGE_REQUEST, payload, meta: { thunk: true } };
}

export function createInAppMessageSuccess(payload, meta) {
  return { type: CREATE_IN_APP_MESSAGE_SUCCESS, payload, meta };
}

export function createInAppMessageError(payload, meta) {
  return {
    type: CREATE_IN_APP_MESSAGE_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function stopInAppMessageRequest(payload) {
  return { type: STOP_IN_APP_MESSAGE_REQUEST, payload, meta: { thunk: true } };
}

export function stopInAppMessageSuccess(payload, meta) {
  return { type: STOP_IN_APP_MESSAGE_SUCCESS, payload, meta };
}

export function stopInAppMessageError(payload, meta) {
  return {
    type: STOP_IN_APP_MESSAGE_ERROR,
    payload,
    meta,
    error: true,
  };
}
