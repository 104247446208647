import moment from 'moment';
import {
  Card, Button, Tag, Typography
} from 'antd';
import { push } from 'connected-react-router';
import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import useTableControl from 'components/Hooks/useTableControl';

import Table from 'components/Table';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { getTagListRequest } from 'providers/GenreProvider/actions';
import StatusToggle from './StatusToggle';

const freeTagType = 2;

const TagList = () => {
  const dispatch = useDispatch();
  const [pageControl, , onTableChange] = useTableControl();
  const { orderBy, order } = pageControl;
  const tagList = useSelector(
    (state) => state.genreProvider.tagList,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getTagListRequest({
          orderBy,
          order,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [orderBy, order, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'objectId',
      fixed: 'left',
      width: 130,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 370,
      render: (record, rowRecord) => (
        <Tag
          style={{ maxWidth: '100%' }}
          bordered={false}
          color={rowRecord?.type === freeTagType ? '#00000026' : '#d0dfe7'}
        >
          <Typography.Text ellipsis={{ tooltip: record }}>
            {record}
          </Typography.Text>
        </Tag>
      ),
    },
    {
      title: 'Number of Product',
      dataIndex: 'numberOfProduct',
      align: 'right',
      width: 150,
    },
    {
      title: '登録日',
      width: 150,
      dataIndex: 'createdAt',
      align: 'right',
      key: 'createdAt',
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
    },
    {
      title: '更新日',
      width: 150,
      dataIndex: 'updatedAt',
      align: 'right',
      key: 'updatedAt',
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
    },
    {
      title: '表示優先度',
      width: 120,
      dataIndex: 'order',
      align: 'right',
      key: 'order',
      sorter: true,
    },
    {
      width: 140,
      fixed: 'right',
      align: 'left',
      title: 'ステータス',
      render: (record) => (
        <StatusToggle record={record.isVisible} rowRecord={record} />
      ),
    },
  ];

  const handleRowClick = (record) => {
    dispatch(push(`/tags/detail/${record.objectId}`));
  };

  return (
    <div className="tag-list-page">
      <Card className="bg-transparent" bordered={false}>
        <Card className="highlight-card padding-card" bordered={false}>
          <div className="flex justify-end padding-16 border-bottom">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                dispatch(push('/tags/create'));
              }}
            >
              フリータグを追加
            </Button>
          </div>
          <Table
            bordered={false}
            columns={columns}
            data={tagList}
            loading={tableLoading}
            pagination={false}
            scroll={{ x: 1300 }}
            onRowClick={handleRowClick}
            onChange={onTableChange}
          />
        </Card>
      </Card>
    </div>
  );
};

export default TagList;
