import {
  GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST,
  GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS,
  GET_LIST_SUPPLIER_FOR_OPERATOR_ERROR,
  REGISTER_SUPPLIER_REQUEST,
  REGISTER_SUPPLIER_SUCCESS,
  REGISTER_SUPPLIER_ERROR,
  GET_SUPPLIER_DETAIL_REQUEST,
  GET_SUPPLIER_DETAIL_SUCCESS,
  GET_SUPPLIER_DETAIL_ERROR,
  UPDATE_SUPPLIER_REQUEST,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_ERROR,
  GET_SUPPLIER_HOLIDAY_LIST_REQUEST,
  GET_SUPPLIER_HOLIDAY_LIST_SUCCESS,
  GET_SUPPLIER_HOLIDAY_LIST_ERROR,
  CREATE_SUPPLIER_HOLIDAY_REQUEST,
  CREATE_SUPPLIER_HOLIDAY_SUCCESS,
  CREATE_SUPPLIER_HOLIDAY_ERROR,
  DELETE_SUPPLIER_HOLIDAY_REQUEST,
  DELETE_SUPPLIER_HOLIDAY_SUCCESS,
  DELETE_SUPPLIER_HOLIDAY_ERROR,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_ERROR,
  GET_INVITATION_EMAIL_REQUEST,
  GET_INVITATION_EMAIL_SUCCESS,
  GET_INVITATION_EMAIL_ERROR,
  REMOVE_INVITATION_EMAIL_REQUEST,
  REMOVE_INVITATION_EMAIL_SUCCESS,
  REMOVE_INVITATION_EMAIL_ERROR,
  RESEND_INVITATION_EMAIL_REQUEST,
  RESEND_INVITATION_EMAIL_SUCCESS,
  RESEND_INVITATION_EMAIL_ERROR,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS,
  GET_LIST_SUPPLIER_FOR_RESTAURANT_ERROR,
  DISABLE_SUPPLIER_ACCOUNT_REQUEST,
  DISABLE_SUPPLIER_ACCOUNT_SUCCESS,
  DISABLE_SUPPLIER_ACCOUNT_ERROR,
  ENABLE_SUPPLIER_ACCOUNT_REQUEST,
  ENABLE_SUPPLIER_ACCOUNT_SUCCESS,
  ENABLE_SUPPLIER_ACCOUNT_ERROR,
} from './constants';

export function getSupplierListRequest(payload) {
  return {
    type: GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getSupplierListSuccess(payload, meta) {
  return { type: GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS, payload, meta };
}

export function getSupplierListError(payload, meta) {
  return {
    type: GET_LIST_SUPPLIER_FOR_OPERATOR_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function getListSupplierForRestaurantRequest(payload) {
  return {
    type: GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getListSupplierForRestaurantSuccess(payload, meta) {
  return { type: GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS, payload, meta };
}

export function getListSupplierForRestaurantError(payload, meta) {
  return {
    type: GET_LIST_SUPPLIER_FOR_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function registerSupplierRequest(payload) {
  return { type: REGISTER_SUPPLIER_REQUEST, payload, meta: { thunk: true } };
}

export function registerSupplierSuccess(payload, meta) {
  return { type: REGISTER_SUPPLIER_SUCCESS, payload, meta };
}

export function registerSupplierError(payload, meta) {
  return {
    type: REGISTER_SUPPLIER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getSupplierDetailRequest(payload) {
  return { type: GET_SUPPLIER_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getSupplierDetailSuccess(payload, meta) {
  return { type: GET_SUPPLIER_DETAIL_SUCCESS, payload, meta };
}

export function getSupplierDetailError(payload, meta) {
  return {
    type: GET_SUPPLIER_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateSupplierRequest(payload) {
  return { type: UPDATE_SUPPLIER_REQUEST, payload, meta: { thunk: true } };
}

export function updateSupplierSuccess(payload, meta) {
  return { type: UPDATE_SUPPLIER_SUCCESS, payload, meta };
}

export function updateSupplierError(payload, meta) {
  return {
    type: UPDATE_SUPPLIER_ERROR,
    payload,
    meta,
    error: true,
  };
}
export const getSupplierHolidayListRequest = (payload) => ({
  type: GET_SUPPLIER_HOLIDAY_LIST_REQUEST,
  payload,
  meta: { thunk: true },
});

export const getSupplierHolidayListSuccess = (payload, meta) => ({
  type: GET_SUPPLIER_HOLIDAY_LIST_SUCCESS,
  payload,
  meta,
});

export const getSupplierHolidayListError = (payload, meta) => ({
  type: GET_SUPPLIER_HOLIDAY_LIST_ERROR,
  payload,
  meta,
  error: true,
});

export const createSupplierHolidayRequest = (payload) => ({
  type: CREATE_SUPPLIER_HOLIDAY_REQUEST,
  payload,
  meta: { thunk: true },
});

export const createSupplierHolidaySuccess = (payload, meta) => ({
  type: CREATE_SUPPLIER_HOLIDAY_SUCCESS,
  payload,
  meta,
});

export const createSupplierHolidayError = (payload, meta) => ({
  type: CREATE_SUPPLIER_HOLIDAY_ERROR,
  payload,
  meta,
  error: true,
});

export const deleteSupplierHolidayRequest = (payload) => ({
  type: DELETE_SUPPLIER_HOLIDAY_REQUEST,
  payload,
  meta: { thunk: true },
});

export const deleteSupplierHolidaySuccess = (payload, meta) => ({
  type: DELETE_SUPPLIER_HOLIDAY_SUCCESS,
  payload,
  meta,
});

export const deleteSupplierHolidayError = (payload, meta) => ({
  type: DELETE_SUPPLIER_HOLIDAY_ERROR,
  payload,
  meta,
  error: true,
});

export const changeEmailRequest = (payload) => ({
  type: CHANGE_EMAIL_REQUEST,
  payload,
  meta: { thunk: true },
});

export const changeEmailSuccess = (payload, meta) => ({
  type: CHANGE_EMAIL_SUCCESS,
  payload,
  meta,
});

export const changeEmailError = (payload, meta) => ({
  type: CHANGE_EMAIL_ERROR,
  payload,
  meta,
  error: true,
});

export const getInvitationEmailRequest = (payload) => ({
  type: GET_INVITATION_EMAIL_REQUEST,
  payload,
  meta: { thunk: true },
});

export const getInvitationEmailSuccess = (payload, meta) => ({
  type: GET_INVITATION_EMAIL_SUCCESS,
  payload,
  meta,
});

export const getInvitationEmailError = (payload, meta) => ({
  type: GET_INVITATION_EMAIL_ERROR,
  payload,
  meta,
  error: true,
});

export const removeInvitationEmailRequest = (payload) => ({
  type: REMOVE_INVITATION_EMAIL_REQUEST,
  payload,
  meta: { thunk: true },
});

export const removeInvitationEmailSuccess = (payload, meta) => ({
  type: REMOVE_INVITATION_EMAIL_SUCCESS,
  payload,
  meta,
});

export const removeInvitationEmailError = (payload, meta) => ({
  type: REMOVE_INVITATION_EMAIL_ERROR,
  payload,
  meta,
  error: true,
});

export const resendInvitationEmailRequest = (payload) => ({
  type: RESEND_INVITATION_EMAIL_REQUEST,
  payload,
  meta: { thunk: true },
});

export const resendInvitationEmailSuccess = (payload, meta) => ({
  type: RESEND_INVITATION_EMAIL_SUCCESS,
  payload,
  meta,
});

export const resendInvitationEmailError = (payload, meta) => ({
  type: RESEND_INVITATION_EMAIL_ERROR,
  payload,
  meta,
  error: true,
});

export const disableSupplierAccountRequest = (payload) => ({
  type: DISABLE_SUPPLIER_ACCOUNT_REQUEST,
  payload,
  meta: { thunk: true },
});

export const disableSupplierAccountSuccess = (payload, meta) => ({
  type: DISABLE_SUPPLIER_ACCOUNT_SUCCESS,
  payload,
  meta,
});

export const disableSupplierAccountError = (payload, meta) => ({
  type: DISABLE_SUPPLIER_ACCOUNT_ERROR,
  payload,
  meta,
  error: true,
});

export const enableSupplierAccountRequest = (payload) => ({
  type: ENABLE_SUPPLIER_ACCOUNT_REQUEST,
  payload,
  meta: { thunk: true },
});

export const enableSupplierAccountSuccess = (payload, meta) => ({
  type: ENABLE_SUPPLIER_ACCOUNT_SUCCESS,
  payload,
  meta,
});

export const enableSupplierAccountError = (payload, meta) => ({
  type: ENABLE_SUPPLIER_ACCOUNT_ERROR,
  payload,
  meta,
  error: true,
});
