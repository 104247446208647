import React, { useState } from 'react';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { submit, getFormValues, isDirty } from 'redux-form';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { createInAppMessageRequest } from 'providers/InAppMessageProvider/actions';
import PageHeader from 'components/PageHeader';
import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import {
  DESTINATION_TYPE,
  EFFECTIVE_DATE,
  EXPIRED_DATE,
} from 'utils/constants';
import InAppMessageCreateForm from './InAppMessageCreateForm';

const breadcrumbItems = [
  {
    path: '/in-app-messages',
    breadcrumbName: 'TOPお知らせメッセージ',
  },
  {
    breadcrumbName: 'メッセージの追加',
  },
];

const AnnouncementCreate = () => {
  const { push, goBack } = useHistory();
  const [createActionLoading, setCreateActionLoading] = useState(false);
  const dispatch = useDispatch();
  const formValues = useSelector((state) => getFormValues('InAppMessageCreateForm')(state));
  const dirty = useSelector((state) => isDirty('InAppMessageCreateForm')(state));

  const { title, message, images } = formValues || {};
  const createInAppMessage = async (value) => {
    setCreateActionLoading(true);
    try {
      await dispatch(createInAppMessageRequest(value));
      push('/in-app-messages');
    } catch (err) {
      console.error(err);
    }
    setCreateActionLoading(false);
  };

  const handleSubmit = (value) => {
    let params = _omit(value, ['effectiveDate', 'expiredDate', 'attachmentType', 'productIds']);

    if (value?.attachmentType === DESTINATION_TYPE.FILE) {
      params = _omit(params, ['targetUrl']);
    }
    if (value?.attachmentType === DESTINATION_TYPE.URL) {
      params = _omit(params, ['file']);
    }

    if (value.attachmentType === DESTINATION_TYPE.PRODUCT) {
      params = _omit(params, ['targetUrl', 'file']);
      params.targetMenuId = value?.productIds[0];
    }
    return createInAppMessage(params);
  };

  const handleOk = () => {
    dispatch(submit('InAppMessageCreateForm'));
  };

  return (
    <div className="create-in-app-message-page flex direction-column">
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={goBack}
      />
      <Card className="bg-transparent">
        <InAppMessageCreateForm
          onSubmit={handleSubmit}
          initialValues={{
            attachmentType: DESTINATION_TYPE.FILE,
            effectiveDate: EFFECTIVE_DATE.NOW,
            expiredDate: EXPIRED_DATE.NEVER,
          }}
        />
      </Card>
      {dirty && (title?.trim() || message?.trim() || !_isEmpty(images)) && (
        <FormActionFooter
          handleCancel={goBack}
          actionLoading={createActionLoading}
          handleOk={handleOk}
          isShowLabel={false}
          okText="配 信"
          cancelText="キャンセル"
        />
      )}
    </div>
  );
};

export default AnnouncementCreate;
