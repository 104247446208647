import _get from 'lodash/get';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  List, Row, Col, Card, Typography, Space
} from 'antd';
import { useParams } from 'react-router-dom';

import Helpers from 'utils/helpers';

import { getOrderDetailRequest } from 'providers/OrderProvider/actions';

import withRefresh from 'components/HOCs/withRefresh';
import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import TrackingDetail from '../OperatorAndSupplier_Detail/TrackingDetail';
import DeliveryInfo from '../OperatorAndSupplier_Detail/DeliveryInfo';
import Columns from '../OperatorAndSupplier_Detail/Columns';
import SupplierInfo from '../OperatorAndSupplier_Detail/SupplierInfo';
import NavigationButton from '../OperatorAndSupplier_Detail/NavigationButton';

const { Text } = Typography;

const OrderDetail = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const orderDetail = useSelector(
    (state) => state.orderProvider.orderDetails,
    shallowEqual
  );
  const orderListForBrandOwner = useSelector(
    (state) => state.brandOwnerProvider.orderListForBrandOwner,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.orderProvider.loading,
    shallowEqual
  );
  const dispatch = useDispatch();

  const { orderId } = useParams();

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: `#${orderId}`,
    },
  ];

  const getOrderDetail = useCallback(() => {
    dispatch(getOrderDetailRequest({ id: orderId }));
  }, [orderId, dispatch]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  const renderOrderList = () => {
    const orderItems = _get(orderDetail, 'items', []).map((item) => ({
      ...item,
      isOrderItem: true,
      formattedUnit: `${_get(item, 'standard', '-')} • ${_get(
        item,
        'amount',
        '-'
      )} ／ ${_get(item, 'unit', '-')}`,
    }));

    const columns = [
      Columns.menuName,
      Columns.brand,
      { ...Columns.quantity, dataIndex: 'quantity' },
      Columns.totalBrandFee,
      Columns.totalPriceAfterTax,
    ];

    return (
      <Table
        rowKey="objectId"
        columns={columns}
        data={orderItems}
        total={orderItems.length}
        loading={loading}
        pagination={null}
        scroll={{ x: 640 }}
      />
    );
  };

  return (
    <div id="order-detail-container">
      <PageHeader
        title={`Order #${orderId}`}
        breadcrumbRoutes={breadcrumbItems}
        onBack={() => dispatch(goBack())}
        extra={<NavigationButton orderList={orderListForBrandOwner} />}
      />
      <Card className="bg-transparent" bordered={false}>
        <Row gutter={32}>
          <Col lg={{ span: 15 }} xs={{ span: 24 }}>
            <Space className="full-w flex" direction="vertical" size={16}>
              <Card
                className="highlight-card padding-card"
                bordered={false}
                title="注文商品"
                headStyle={{
                  fontSize: '20px', color: '#000', padding: '0 16px', borderBottom: '2px solid #e8e8e8'
                }}
              >
                {renderOrderList()}
                <List
                  itemLayout="horizontal"
                  style={{ margin: '0 16px' }}
                  dataSource={[
                    {
                      content: (
                        <div className="total-value">
                          <Row justify="space-between">
                            <Col>小計（税込）</Col>
                            <Col>
                              {Helpers.priceFormat(
                                orderDetail?.totalPriceAfterTax
                              )}
                            </Col>
                          </Row>
                          <Row gutter={64}>
                            <Col>
                              <Text strong>合計ブランドフィー</Text>
                            </Col>
                            <Col>
                              <Text strong>
                                {Helpers.priceFormat(
                                  orderDetail?.totalBrandFee
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </div>
                      ),
                    },
                  ]}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        className="text-bold"
                        title={item.title}
                      />
                      <div className="text-bold">{item.content}</div>
                    </List.Item>
                  )}
                />
              </Card>
              <TrackingDetail
                currentUser={currentUser}
                orderDetail={orderDetail}
              />
            </Space>
          </Col>
          <Col lg={{ span: 9 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <DeliveryInfo orderDetail={orderDetail} />
              <SupplierInfo orderDetail={orderDetail} />
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default withRefresh('orderDetail')(OrderDetail);
