import produce from 'immer';

import {
  GET_BRAND_LIST_SUCCESS,
  GET_BRAND_LIST_ERROR,
  GET_BRAND_DETAIL_REQUEST,
  GET_BRAND_DETAIL_SUCCESS,
  GET_BRAND_DETAIL_ERROR,
  GET_BRAND_LIST_OF_RESTAURANT_REQUEST,
  GET_BRAND_LIST_OF_RESTAURANT_SUCCESS,
} from './constants';

const initialState = {
  brandList: {},
  brandDetail: {},
  brandListOfRestaurant: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_LIST_SUCCESS:
      state.brandList = action.payload;
      break;

    case GET_BRAND_LIST_ERROR:
      break;

    case GET_BRAND_DETAIL_REQUEST:
      state.brandDetail = {};
      break;

    case GET_BRAND_DETAIL_SUCCESS:
      state.brandDetail = action.payload;
      break;

    case GET_BRAND_DETAIL_ERROR:
      break;

    case GET_BRAND_LIST_OF_RESTAURANT_REQUEST:
      state.brandListOfRestaurant = {};
      break;

    case GET_BRAND_LIST_OF_RESTAURANT_SUCCESS:
      state.brandListOfRestaurant = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
