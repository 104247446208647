import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import {
  Button, Space, Typography, Card
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { resetPasswordRequest } from 'providers/AuthProvider/actions';

import ResetPasswordForm from './ResetPasswordForm';

import '../style.less';

const ResetPassword = () => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    setActionLoading(true);
    try {
      await dispatch(resetPasswordRequest(data));
      setActionLoading(false);
      dispatch(push('/auth/reset-password/success', { email: data.email }));
    } catch (err) {
      setActionLoading(false);
    }
  };

  return (
    <Card className="highlight-card" style={{ minHeight: 486 }} bordered={false}>
      <Space direction="vertical" size={32} className="full-w">
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          style={{ padding: 0 }}
          onClick={() => {
            dispatch(goBack());
          }}
        >
          ログインに戻る
        </Button>
        <Space direction="vertical" className="full-w">
          <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
            パスワードを忘れた場合
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            パスワードをリセットするためのURLをお送り致しますので、下記にメールアドレスを入力してください。
          </Typography.Text>
        </Space>
        <ResetPasswordForm
          onSubmit={handleSubmit}
          actionLoading={actionLoading}
        />
      </Space>
    </Card>
  );
};

export default ResetPassword;
