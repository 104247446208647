import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import ShippingFeeForm from 'containers/AdminPage/Content/SettingsPage/ShippingFee/ShippingFeeForm';

const ShippingFee = () => {
  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  return (
    <Row>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <ShippingFeeForm
          initialValues={{
            ...supplierDetail,
            setPriceOfFreeShip: !!_isNumber(_get(supplierDetail, 'priceOfFreeShip')),
          }}
          disabled
        />
      </Col>
    </Row>
  );
};

export default ShippingFee;
