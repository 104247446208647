import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (!values.date) {
    errors.date = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values?.applyTo || !values?.applyTo?.length) {
    errors.applyTo = Constants.ERROR_MESSAGE.REQUIRED;
  }
  return errors;
};

export default validate;
