import _remove from 'lodash/remove';
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';

import { CheckboxField, DatePickerField } from 'components/Form';
import { ORDER_STATUS, ORDER_STATUS_JP } from 'utils/constants';

import './style.scss';

const ExportOrderForm = ({
  handleSubmit, onCancel, statusFixed, loading
}) => {
  const renderStatusCheckbox = (options) => (
    <Field
      name={options.value}
      label={options.label}
      component={CheckboxField}
    />
  );

  const statusOptions = Object.keys(ORDER_STATUS).map((key) => ({
    value: ORDER_STATUS[key],
    label: ORDER_STATUS_JP[key],
  }));

  return (
    <Form id="export-order-form" onSubmit={handleSubmit}>
      {_remove(statusOptions, (o) => o.value !== ORDER_STATUS.FIXED).map(
        (option) => renderStatusCheckbox(option)
      )}
      <div className="flex items-center">
        <Field
          name={ORDER_STATUS.FIXED}
          label={`${ORDER_STATUS_JP.FIXED} in`}
          component={CheckboxField}
        />
        <Field
          disabled={!statusFixed}
          colon={false}
          name="fixedDate"
          placeholder="全ての月"
          component={DatePickerField}
          picker="month"
          dateFormat="YYYY年MM月"
        />
      </div>
      <div id="export-orders-form-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            ダウンロード
          </Button>
        </Space>
      </div>
    </Form>
  );
};

ExportOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  statusFixed: PropTypes.any,
  loading: PropTypes.bool,
};

export default reduxForm({ form: 'exportOrdersForm' })(ExportOrderForm);
