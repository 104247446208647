import produce from 'immer';

import {
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_ERROR,
  RESET_CATEGORY_LIST,
} from './constants';

const initialState = {
  loading: false,
  error: false,
  categoryList: [],
  subcategoryMap: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      state.categoryList = [];
      state.subcategoryMap = {};
      break;

    case GET_CATEGORY_LIST_SUCCESS:
      state.loading = false;
      state.categoryList = action.payload;
      state.subcategoryMap = action.payload.reduce((acc, cur) => {
        acc[cur.objectId] = cur.childs;
        return acc;
      }, {});
      break;

    case GET_CATEGORY_LIST_ERROR:
      state.loading = false;
      state.error = action.payload;
      break;

    case RESET_CATEGORY_LIST:
      state.loading = false;
      state.error = false;
      state.categoryList = [];
      state.subcategoryMap = {};
      break;

    default:
  }
  return state;
});

export default reducer;
