import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Tooltip } from 'antd';

import InfoIcon from 'images/icon-order-info.svg';
import OrderItemUpdateForm from './OrderItemUpdateForm';
import './style.scss';

const { Text } = Typography;

const OrderItemUpdate = ({ orderDetail, onSubmit }) => {
  const updateOrderItems = _get(orderDetail, 'updateItems', []);
  const totalItemsPriceAfterTax = _get(orderDetail, 'items', []).reduce(
    (acc, item) => acc + item.totalPriceAfterTax,
    0
  );

  return (
    <div
      className="order-edit-action"
    >
      <>
        <div
          style={{
            padding: '2px 16px',
            fontSize: 12,
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
            marginBottom: 16,
          }}
        >
          <Text strong type="secondary">
            更新
          </Text>
          <Tooltip
            placement="topLeft"
            title="更新するとレストランに変更内容が通知されます。"
          >
            <Button icon={<img src={InfoIcon} alt="info-icon" />} type="text" />
          </Tooltip>
        </div>
        <OrderItemUpdateForm
          totalItemsPriceAfterTax={totalItemsPriceAfterTax}
          initialValues={{ updateItems: updateOrderItems }}
          onSubmit={onSubmit}
        />
      </>
    </div>
  );
};

OrderItemUpdate.propTypes = {
  orderDetail: PropTypes.any,
  onSubmit: PropTypes.func,
};

export default OrderItemUpdate;
