import React from 'react';
import { Field } from 'redux-form';
import { Typography } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import PropTypes from 'prop-types';

import { RadioGroup, TextField } from 'components/Form';
import { GENERAL_ANSWER } from 'utils/constants';
import './style.less';

const InvoiceForm = ({
  isUpdateInvoice
}) => (
  <>
    <Form.Item>
      <Typography.Text>
        下記はレストランに送付される請求書に印字される情報です。
      </Typography.Text>
    </Form.Item>
    <Field
      label="振込先銀行名"
      name="bankName"
      component={TextField}
      maxLength={50}
      required
    />
    <Field
      label="振込先支店名"
      name="bankBranchName"
      component={TextField}
      maxLength={50}
      required
    />
    <Field
      label="振込先口座種別"
      name="bankAccountType"
      component={TextField}
      maxLength={20}
      required
    />
    <Field
      label="振込先口座番号"
      name="bankAccountNumber"
      component={TextField}
      maxLength={20}
      required
    />
    <Field
      label="振込先口座名義"
      name="bankAccountName"
      component={TextField}
      maxLength={50}
      required
    />
    <Field
      label="請求書備考"
      name="invoiceNote"
      component={TextField}
      textArea
      maxLength={500}
      requiredSpace
    />
    {isUpdateInvoice && (
      <Field
        label="登録番号"
        description="任意"
        name="taxId"
        component={TextField}
        maxLength={14}
        requiredSpace
      />
    )}
    <Field
      label="支払い期限を表示しますか？"
      name="invoiceShowPaymentDeadline"
      component={RadioGroup}
      isButton
      options={GENERAL_ANSWER}
    />
  </>
);

InvoiceForm.propTypes = {
  isUpdateInvoice: PropTypes.bool
};

export default InvoiceForm;
