import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Col, Row, Card, Button, Modal, Typography, Empty, Space
} from 'antd';
import { isDirty, submit, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import withRefresh from 'components/HOCs/withRefresh';
import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';
import EmptyMenu from 'images/icon-empty-product.svg';

import toastMessage from 'utils/toastMessage';

import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import RestaurantForm from './RestaurantForm';
import ProductDialog from './ProductUpdate';
import InvoiceList from './InvoiceList';
import StatementInvoiceList from './StatementInvoiceList';
import Columns from './columns';

const { confirm } = Modal;
const { Text } = Typography;
const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const InAppConfig = (props) => {
  const {
    currentUser,
    restaurantDetail,
    restaurantLoading,
    restaurantMenuItemList,
    restaurantMenuItemTotal,
    getRestaurantMenuItemRequest,
    assignRestaurantMenuItemRequest,
    removeRestaurantMenuItemRequest,
    doRefresh,
  } = props;
  const dispatch = useDispatch();
  const [productDialog, setProductDialog] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const [pageControl, , onTableChange] = useTableControl({});
  const { page, limit } = pageControl;
  const restaurantId = _get(restaurantDetail, 'objectId');

  const fetchRestaurantMenuItem = useCallback(() => {
    if (restaurantId) {
      getRestaurantMenuItemRequest({
        restaurantId,
        page,
        limit,
      });
    }
  }, [restaurantId, page, limit]);

  const maxPage = Math.ceil(restaurantMenuItemTotal / limit);

  if (page > maxPage && maxPage > 0) {
    onTableChange({
      pagination: { current: maxPage, pageSize: limit },
      sorter: {},
    });
  }

  useEffect(() => {
    fetchRestaurantMenuItem();
  }, [fetchRestaurantMenuItem]);

  const removeRestaurantMenuItem = async (menu) => {
    try {
      await removeRestaurantMenuItemRequest({
        restaurantMenuId: _get(menu, 'objectId'),
      });
      doRefresh({ target: 'restaurant/supplier_detail' });
    } catch (error) {
      console.error(error);
    }
  };

  const restaurantForm = useMemo(
    () => (
      <RestaurantForm
        currentUser={currentUser}
        restaurantDetail={restaurantDetail}
        setActionLoading={setActionLoading}
      />
    ),
    [currentUser, restaurantDetail]
  );

  const columns = [
    Columns.name,
    Columns.priceBeforeTax,
    Columns.leadTime,
    Columns.brand,
    Columns.priceAfterTax,
    {
      ...Columns.action,
      render: (record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showConfirm('消去してよろしいですか？', () => removeRestaurantMenuItem(record)
            );
          }}
          type="text"
          danger
          icon={<CloseOutlined />}
        >
          削除
        </Button>
      ),
    },
  ];

  const assignMenuItem = async ({ menus, collectionIds }) => {
    try {
      const menuItems = menus.map((menu) => ({
        menuPriceId: menu.menuPriceId,
        objectId: menu.objectId,
      }));
      await assignRestaurantMenuItemRequest({
        restaurantId,
        menuItems,
        collectionIds,
      });
      doRefresh({ target: 'restaurant/supplier_detail' });
      toastMessage.success({
        description: `この商品をレストラン${restaurantDetail.name}に設定しました。`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const dirty = useSelector((state) => isDirty('restaurantForm')(state));
  const handleCancel = () => {
    dispatch(reset('restaurantForm'));
  };
  const handleOk = () => {
    dispatch(submit('restaurantForm'));
  };

  return (
    <div id="in-app-config-tab" className="flex direction-column">
      <Card className="bg-transparent" bordered={false}>
        {productDialog && (
          <ProductDialog
            {...props}
            dialogVisible
            handleCloseDialog={() => setProductDialog(false)}
            handleAssignMenuItem={assignMenuItem}
          />
        )}
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 16 }} xs={{ span: 24 }}>
            <Card
              className="highlight-card padding-card"
              bordered={false}
              title="店舗に登録されている商品"
              headStyle={{
                padding: '0 16px',
                borderBottom: '2px solid #e8e8e8',
              }}
              extra={
                !_isEmpty(restaurantMenuItemList) && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setProductDialog(true)}
                  >
                    商品を編集
                  </Button>
                )
              }
            >
              {!_isEmpty(restaurantMenuItemList) || restaurantLoading ? (
                <Table
                  rowKey="objectId"
                  columns={columns}
                  data={restaurantMenuItemList}
                  total={restaurantMenuItemTotal}
                  loading={restaurantLoading}
                  onChange={onTableChange}
                  pagination={{
                    current: Number(page),
                    pageSize: Number(limit),
                  }}
                  scroll={{ x: 935 }}
                />
              ) : (
                <Space
                  size="middle"
                  direction="vertical"
                  align="center"
                  className="full-w"
                  style={{ paddingBottom: 24 }}
                >
                  <Empty
                    image={EmptyMenu}
                    imageStyle={{
                      width: 160,
                      height: 160,
                      margin: '0 auto',
                    }}
                    description={(
                      <Text type="secondary">
                        このレストランに登録されている商品はありません。
                      </Text>
                    )}
                  />
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setProductDialog(true)}
                  >
                    商品を追加
                  </Button>
                </Space>
              )}
            </Card>
          </Col>
          <Col lg={{ span: 8 }} xs={{ span: 24 }}>
            <Space direction="vertical" className="full-w flex" size={16}>
              {restaurantForm}
              <InvoiceList restaurantId={restaurantId} />
              {_get(currentUser, 'supplier.type') === 'SHIKOMEL' && (
                <StatementInvoiceList restaurantId={restaurantId} />
              )}
            </Space>
          </Col>
        </Row>
      </Card>
      {dirty && (
        <FormActionFooter
          handleCancel={handleCancel}
          actionLoading={actionLoading}
          handleOk={handleOk}
        />
      )}
    </div>
  );
};

InAppConfig.propTypes = {
  currentUser: PropTypes.object,
  restaurantDetail: PropTypes.object,
  restaurantLoading: PropTypes.bool,
  getRestaurantMenuItemRequest: PropTypes.func,
  restaurantMenuItemList: PropTypes.array,
  restaurantMenuItemTotal: PropTypes.number,
  match: PropTypes.any,
  assignRestaurantMenuItemRequest: PropTypes.func,
  removeRestaurantMenuItemRequest: PropTypes.func,
  doRefresh: PropTypes.func,
};
export default withRefresh('assignedMenuList')(InAppConfig);
