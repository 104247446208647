import React from 'react';
import { Typography, Tag } from 'antd';
import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';
import moment from 'moment';
import StatusToggle from './StatusToggle';
import DisplayToggle from './DisplayToggle';

const Columns = {};
const { Text } = Typography;

Columns.sort = {
  title: 'Sort',
  key: 'dragHandle',
  dataIndex: 'dragHandle',
  width: 60,
};

Columns.ID = {
  title: 'ID',
  width: 110,
  dataIndex: 'objectId',
  key: 'objectId',
  align: 'left',
};

Columns.name = {
  title: 'ランキング名',
  dataIndex: 'name',
  key: 'name',
  width: 200,
  align: 'left',
  render: (record) => (
    <Text
      ellipsis={{
        tooltip: record,
      }}
    >
      {record || '-'}
    </Text>
  ),
};

Columns.numberOfProduct = {
  title: 'Number of product',
  dataIndex: 'numberOfProduct',
  key: 'numberOfProduct',
  align: 'right',
  width: 160,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  fixed: 'right',
  width: 120,
  render: (record, rowRecord) => (
    <StatusToggle record={record} rowRecord={rowRecord} />
  ),
};

Columns.isVisibleOnHome = {
  title: 'ホーム画面で表示するか',
  dataIndex: 'isVisibleOnHome',
  key: 'isVisibleOnHome',
  fixed: 'right',
  width: 170,
  render: (record, rowRecord) => (
    <DisplayToggle record={record} rowRecord={rowRecord} />
  ),
};

Columns.memo = {
  title: 'メモ',
  width: 350,
  dataIndex: 'memo',
  key: 'memo',
  render: (record) => (record ? (
    <Typography.Paragraph
      className="note"
      ellipsis={{
        rows: 1,
        tooltip: (
          <Text style={{ color: '#fff', whiteSpace: 'pre-line' }}>
            {record}
          </Text>
        ),
      }}
    >
      {record}
    </Typography.Paragraph>
  ) : (
    '-'
  )),
};

Columns.updateAt = {
  title: 'Last update',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  align: 'left',
  width: 140,
  render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
};

Columns.specifyTo = {
  title: 'Specify to',
  width: 230,
  align: 'left',
  render: (_, rowRecord) => {
    const tag = rowRecord?.tag;
    const genre = rowRecord?.genre;
    return (
      <Tag bordered={false} color={tag ? '#d0dfe7' : 'inherit'}>
        <Text>{genre?.name || tag?.name || '-'}</Text>
      </Tag>
    );
  },
};

export default Columns;
