import { call, put, takeLatest } from 'redux-saga/effects';
import _pick from 'lodash/pick';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getInvoiceListSuccess,
  getInvoiceListError,
  getStatementInvoiceListSuccess,
  getStatementInvoiceListError,
} from './actions';
import {
  GET_INVOICE_LIST_REQUEST,
  GET_STATEMENT_INVOICE_LIST_REQUEST,
} from './constants';

export function* handleGetInvoiceList(action) {
  const { payload, meta } = action;

  const { restaurantId } = _pick(payload, ['restaurantId']);
  const params = { restaurantId };

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getInvoiceList`,
      params
    );
    yield put(getInvoiceListSuccess(result, meta));
  } catch (error) {
    yield put(getInvoiceListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetStatementInvoiceList(action) {
  const { payload, meta } = action;

  const { restaurantId } = _pick(payload, ['restaurantId']);
  const params = { restaurantId };

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getStatementInvoiceList`,
      params
    );
    yield put(getStatementInvoiceListSuccess(result, meta));
  } catch (error) {
    yield put(getStatementInvoiceListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_INVOICE_LIST_REQUEST, handleGetInvoiceList);
  yield takeLatest(
    GET_STATEMENT_INVOICE_LIST_REQUEST,
    handleGetStatementInvoiceList
  );
}
