import _map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Checkbox, Row, Col, Space
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

class CheckboxGroup extends React.PureComponent {
  render() {
    const {
      label,
      input,
      meta: { touched, invalid, error },
      disabled,
      options,
      colSpan,
    } = this.props;
    const isError = touched && invalid && error;

    return (
      <Form.Item
        label={label}
        help={
          isError && (
            <Space size={4}>
              <InfoCircleOutlined />
              {error}
            </Space>
          )
        }
        validateStatus={touched && invalid && error ? 'error' : ''}
      >
        <Checkbox.Group
          className="full-w"
          {...input}
          disabled={disabled}
          onBlur={() => {}}
        >
          <Row>
            {_map(options, (option) => (
              <Col span={colSpan} key={option.value}>
                <Checkbox
                  value={option.value}
                  style={{ ...option.style, width: 'fit-content' }}
                  disabled={option.disabled}
                >
                  {option.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  }
}

CheckboxGroup.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFormValueChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  colSpan: PropTypes.any,
};

export default CheckboxGroup;
