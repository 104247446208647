export const GET_PREFECTURE_LIST_REQUEST = 'PrefectureProvider/GET_PREFECTURE_LIST_REQUEST';
export const GET_PREFECTURE_LIST_SUCCESS = 'PrefectureProvider/GET_PREFECTURE_LIST_SUCCESS';
export const GET_PREFECTURE_LIST_ERROR = 'PrefectureProvider/GET_PREFECTURE_LIST_ERROR';

export const GET_PARSE_CONFIG_REQUEST = 'PrefectureProvider/GET_PARSE_CONFIG_REQUEST';
export const GET_PARSE_CONFIG_SUCCESS = 'PrefectureProvider/GET_PARSE_CONFIG_SUCCESS';
export const GET_PARSE_CONFIG_ERROR = 'PrefectureProvider/GET_PARSE_CONFIG_ERROR';

export const GET_ALLERGY_LIST_REQUEST = 'PrefectureProvider/GET_ALLERGY_LIST_REQUEST';
export const GET_ALLERGY_LIST_SUCCESS = 'PrefectureProvider/GET_ALLERGY_LIST_SUCCESS';
export const GET_ALLERGY_LIST_ERROR = 'PrefectureProvider/GET_ALLERGY_LIST_ERROR';
