import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import {
  Descriptions, Card, Col, Row, Space, Typography, Divider
} from 'antd';

import { DATE_TIME_FORMAT } from 'utils/constants';
import { useSelector, shallowEqual } from 'react-redux';

import EmailInvitation from 'components/EmailInvitation';
import StatusToggle from '../../List/StatusToggle';

const { Text } = Typography;
const { Item } = Descriptions;

const RestaurantOwnerInfo = () => {
  const restaurantOwnerDetail = useSelector(
    (state) => state.restaurantOwnerProvider.restaurantOwnerDetail,
    shallowEqual
  );

  const {
    postalCode,
    prefecture,
    address,
    buildingName,
    phone,
    fax,
    updatedAt,
    createdAt,
    objectId,
    email,
  } = restaurantOwnerDetail;

  const renderAddressDescription = (
    _postalCode,
    _prefecture,
    _address,
    _buildingName,
    _phone,
    _fax
  ) => (
    <Descriptions.Item>
      <div style={{ marginLeft: -8 }}>
        <div>{_postalCode}</div>
        <div>
          {_prefecture} {_address} {_buildingName}
        </div>
        <div>店舗電話番号: {_phone}</div>
        <div>店舗FAX番号: {_fax}</div>
      </div>
    </Descriptions.Item>
  );

  const renderDate = (date, format = DATE_TIME_FORMAT) => (date ? moment(date).format(format) : '-');

  return (
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Card
          className="highlight-card"
          title="プロフィール設定"
          bordered={false}
        >
          <Descriptions
            title="店舗名"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            <Item label="会社詳細">
              <div>
                <div>{_get(restaurantOwnerDetail, 'name')}</div>
                <Text style={{ fontSize: 12 }}>
                  {_get(restaurantOwnerDetail, 'nameKana')}
                </Text>
              </div>
            </Item>
          </Descriptions>
          <Divider />
          <Descriptions
            title="会社の住所"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            {renderAddressDescription(
              postalCode,
              prefecture,
              address,
              buildingName,
              phone,
              fax
            )}
          </Descriptions>
          <Divider />
          <Descriptions
            title="会社の連絡先"
            column={1}
            bordered
            labelStyle={{ verticalAlign: 'top' }}
          >
            <Item label="担当者">
              <div>
                <div>{_get(restaurantOwnerDetail, 'managerName')}</div>
                <Text style={{ fontSize: 12 }}>
                  {_get(restaurantOwnerDetail, 'managerNameKana')}
                </Text>
              </div>
            </Item>
            <Item label="担当者緊急連絡先">
              {_get(restaurantOwnerDetail, 'emergencyPhone')}
            </Item>
          </Descriptions>
        </Card>
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <Card className="highlight-card" title="アカウント" bordered={false}>

            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="アカウント">
                <EmailInvitation
                  isPrimary
                  supplierId={objectId}
                  email={email}
                  emailVerified={restaurantOwnerDetail?.emailVerified}
                />
              </Item>
              <Item>
                <div className="divider">
                  <Divider />
                </div>
              </Item>
              <Item label="ステータス">
                <StatusToggle
                  rowRecord={{ ...restaurantOwnerDetail, callBack: () => {} }}
                />
              </Item>
            </Descriptions>
          </Card>
          <Card className="highlight-card" title="変更履歴" bordered={false}>
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Descriptions.Item label="更新日">
                {renderDate(updatedAt)}
              </Descriptions.Item>
              <Descriptions.Item label="作成日">
                {renderDate(createdAt)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

export default RestaurantOwnerInfo;
