/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert, Button, Descriptions, Modal, Typography, Space
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import CardInfo from 'components/CardInfo';
import premiumTag from 'images/tag-premium.svg';
import basicIcon from 'images/icon-basic.svg';

import helpers from 'utils/helpers';
import { DATE_FORMAT, PAYMENT_STATUS } from 'utils/constants';

import { doRefresh } from 'providers/CommonProvider/actions';
import {
  downgradeToBasicRequest,
  retryMembershipPaymentRequest,
} from 'providers/RestaurantProvider/actions';

const { Text } = Typography;
const { Item } = Descriptions;
const { confirm } = Modal;

const MembershipInformation = ({
  name,
  formattedObjectId,
  restaurant_membership,
}) => {
  const dispatch = useDispatch();
  const [actionLoading, setActionLoading] = useState(false);

  const {
    membership_tier,
    is_joined_trial,
    payment_method,
    veritrans_card_info,
    last_renewal_date,
    paymentLog,
    payment_status,
    current_subscription_receipt_id,
    last_sign_up_date,
    last_expiry_date,
    last_cancel_date,
    is_trial_period,
    is_it_subsidy_period,
    is_auto_renew,
  } = restaurant_membership;

  const isPremiumMembership = membership_tier === 2;
  const daysLeft = last_renewal_date && moment(last_renewal_date).diff(moment(), 'days');
  const paymentFailed = payment_status === PAYMENT_STATUS.FAILED
    || payment_status === PAYMENT_STATUS.UNPAID;

  const cardNumber = veritrans_card_info?.cardNumber;
  const cardNiceType = helpers.getCardType(cardNumber);

  const handleDowngradeToBasic = () => {
    confirm({
      title: `${name}をベーシックプランに降格しますか？`,
      content: `${name}のベーシックプランへの降格はすぐに適用され、今後プレミアム機能を一切利用できなくなります。`,
      okText: '降格',
      okType: 'primary',
      cancelText: 'キャンセル',
      onOk: async () => {
        try {
          await dispatch(downgradeToBasicRequest({ formattedObjectId }));
          dispatch(doRefresh({ target: 'restaurantDetail' }));
        } catch (error) {
          console.log(error);
        }
      },
      centered: true,
    });
  };

  const retryMembershipPayment = async () => {
    try {
      setActionLoading(true);
      await dispatch(
        retryMembershipPaymentRequest({
          subscriptionReceiptId: current_subscription_receipt_id,
        })
      );
      dispatch(doRefresh({ target: 'restaurantDetail' }));
    } catch (error) {
      console.log(error);
    }
    setActionLoading(false);
  };

  const retryMembershipPaymentButton = (
    <Button onClick={retryMembershipPayment} loading={actionLoading}>
      再実行
    </Button>
  );

  const renderMembershipBadge = () => {
    if (isPremiumMembership) {
      return <img src={premiumTag} alt="premium-tag" />;
    }
    return (
      <Space size={4} className="flex items-center">
        <img src={basicIcon} alt="basic-icon" />
        <Text>ベーシック</Text>
      </Space>
    );
  };

  const renderSupportingText = () => {
    // Basic membership
    if (!isPremiumMembership) {
      if (!is_joined_trial) {
        return <Text type="secondary">トライアル利用前</Text>;
      }
      return null;
    }
    // Premium membership
    // IT_subsidy
    if (is_it_subsidy_period) {
      return <Text type="secondary">IT Subsidy</Text>;
    }
    // Not IT_subsidy
    // Is trial period
    if (is_trial_period) {
      return <Text type="secondary">トライアル利用前トライアル中。</Text>;
    }
    // Is not trial period
    if (daysLeft) {
      return <Text type="secondary">残り日数：{daysLeft}日</Text>;
    }
    return null;
  };

  return (
    <>
      <Item label="契約プラン">
        <Space wrap className="flex justify-between items-start">
          <Space direction="vertical" size={0}>
            {renderMembershipBadge()}
            {renderSupportingText()}
          </Space>
          {isPremiumMembership && (
            <Button onClick={handleDowngradeToBasic}>
              ベーシックプランに自動降格
            </Button>
          )}
        </Space>
      </Item>
      <Item label="加入日">
        <Text>
          {last_sign_up_date
            ? moment(last_sign_up_date).format(DATE_FORMAT)
            : '-'}
        </Text>
      </Item>
      <Item label="解約日">
        <Text>
          {!is_it_subsidy_period && last_cancel_date // Show cancel date when not in IT_subsidy period and has cancel date
            ? moment(last_cancel_date).format(DATE_FORMAT)
            : '-'}
        </Text>
      </Item>
      <Item label="有効期限">
        <Text>
          {last_expiry_date
            ? moment(last_expiry_date).format(DATE_FORMAT)
            : '-'}
        </Text>
      </Item>
      <Item label="月額料金支払方法">
        {isPremiumMembership && is_auto_renew && !is_it_subsidy_period ? ( // Show payment method when is in premium & auto renew & not in IT_Subsidy
          <Space direction="vertical" className="full-w" size={16}>
            <Space className="flex justify-between items-center">
              <CardInfo
                method={payment_method}
                niceType={cardNiceType}
                cardNumber={cardNumber?.slice(-4)}
              />
              {paymentFailed && retryMembershipPaymentButton}
            </Space>
            {paymentFailed && paymentLog?.errorMessage && (
              <Alert
                message="エラー"
                description={paymentLog?.errorMessage}
                type="error"
                showIcon
                icon={<CloseCircleFilled />}
              />
            )}
          </Space>
        ) : (
          '-'
        )}
      </Item>
    </>
  );
};

MembershipInformation.propTypes = {
  name: PropTypes.string,
  formattedObjectId: PropTypes.string,
  restaurant_membership: PropTypes.any,
};

export default MembershipInformation;
