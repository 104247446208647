/* eslint-disable react/display-name */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const withAuthorize = (roles) => (WrappedComponent) => (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  if (!roles.includes(currentUser.role)) {
    return null;
  }
  return <WrappedComponent {...props} />;
};

export default withAuthorize;
