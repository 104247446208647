import { Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { getGenreListRequest } from 'providers/GenreProvider/actions';
import { getBrandListRequest } from 'providers/BrandProvider/actions';

const { Item } = Form;

const Filter = ({ onFilter }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const genresList = useSelector(
    (state) => state.genreProvider.genreList,
    shallowEqual
  );

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const brandsList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getGenreListRequest({ selectFields: ['name', 'objectId'] }));
    dispatch(getBrandListRequest({ page: 1, limit: 1000 }));
  }, []);

  const renderItem = (item) => (
    <Select.Option key={item.objectId} value={item.objectId} label={item.name}>
      {item.name}
    </Select.Option>
  );

  const valuesChangeHandler = (changedValues, allValues) => {
    if (Object.keys(changedValues).includes('menuKeyword')) {
      _debounce((data) => onFilter(data), 500)(allValues);
    } else {
      onFilter(allValues);
    }
  };

  return (
    <Form form={form} layout="inline" onValuesChange={valuesChangeHandler}>
      <Item name="menuKeyword">
        <Input.Search placeholder="商品名で検索" style={{ width: 280 }} />
      </Item>
      <Item label="ブランド:" name="brandId">
        <Select placeholder="すべて" allowClear style={{ width: 240 }}>
          {_map(brandsList?.list, renderItem)}
        </Select>
      </Item>
      {_get(currentUser, 'supplier.type') === 'SHIKOMEL' && (
        <Item label="ジャンル:" name="genreId">
          <Select placeholder="すべて" allowClear style={{ width: 240 }}>
            {_map(genresList, renderItem)}
          </Select>
        </Item>
      )}
    </Form>
  );
};

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default Filter;
