import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import axios from 'axios';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getRestaurantOwnerListSuccess,
  getRestaurantOwnerListError,
  getRestaurantOwnerDetailSuccess,
  getRestaurantOwnerDetailError,
  updateRestaurantOwnerError,
  updateRestaurantOwnerSuccess,
  getRestaurantByEmailSuccess,
  getRestaurantByEmailError,
  restaurantOwnerInviteRestaurantSuccess,
  restaurantOwnerInviteRestaurantError,
  restaurantOwnerRequestConnectToRestaurantSuccess,
  restaurantOwnerRequestConnectToRestaurantError,
  updateRestaurantInvitationStatusSuccess,
  updateRestaurantInvitationStatusError,
  disableRestaurantOwnerAccountSuccess,
  disableRestaurantOwnerAccountError,
  enableRestaurantOwnerAccountSuccess,
  enableRestaurantOwnerAccountError,
} from './actions';
import {
  GET_RESTAURANT_OWNER_LIST_REQUEST,
  GET_RESTAURANT_OWNER_DETAIL_REQUEST,
  UPDATE_RESTAURANT_OWNER_REQUEST,
  GET_RESTAURANT_BY_EMAIL_REQUEST,
  RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST,
  RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST,
  DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
  ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
} from './constants';

export function* handleGetRestaurantOwnerList(action) {
  const params = _pick(action.payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
  ]);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantOwnerList`,
      params
    );

    yield put(getRestaurantOwnerListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getRestaurantOwnerListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export function* handleGetRestaurantOwnerDetail(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantOwnerDetail`,
      action.payload
    );

    yield put(getRestaurantOwnerDetailSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getRestaurantOwnerDetailError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateRestaurantOwner(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    const params = _pick(payload, [
      'name',
      'nameKana',
      'postalCode',
      'prefecture',
      'address',
      'building',
      'phone',
      'fax',
    ]);

    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      if (image?.originFileObj) {
        const { result: fileUploadResult } = yield call(
          request,
          `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
          {
            fileName: _get(image, 'originFileObj.name'),
            fileType: _get(image, 'originFileObj.type'),
            type: 'AVATAR',
          }
        );

        fileId = _get(fileUploadResult, 'objectId');

        yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
          headers: {
            'Content-Type': _get(image, 'originFileObj.type'),
          },
        })
        );
      }
    }
    params.image = fileId
      || (_isArray(payload.image) && _isEmpty(payload.image) ? '' : undefined);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateRestaurantOwner`,
      params
    );
    yield put(updateRestaurantOwnerSuccess(result, meta));
    toastMessage.success({ description: 'プロフィールを更新しました。' });
  } catch (error) {
    yield put(updateRestaurantOwnerError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantbyEmail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}rO_getRestaurantByEmail`,
      payload
    );
    yield put(getRestaurantByEmailSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantByEmailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleInviteRestaurant(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}rO_inviteRestaurant`,
      payload
    );
    yield put(restaurantOwnerInviteRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(restaurantOwnerInviteRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRequestConnectToRestaurant(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}rO_requestConnectToRestaurants`,
      payload
    );
    yield put(restaurantOwnerRequestConnectToRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(restaurantOwnerRequestConnectToRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateRestaurantInvitationStatus(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}rO_updateRestaurantInvitationStatus`,
      payload
    );
    yield put(updateRestaurantInvitationStatusSuccess(result, meta));
    toastMessage.success({
      message: '成功',
      description: '削除に成功しました',
    });
  } catch (error) {
    yield put(updateRestaurantInvitationStatusError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleDisableRestaurantOwnerAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}disableRestaurantOwnerAccount`,
      payload
    );
    yield put(disableRestaurantOwnerAccountSuccess(result, meta));
  } catch (error) {
    yield put(disableRestaurantOwnerAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleEnableRestaurantAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}enableRestaurantOwnerAccount`,
      payload
    );
    yield put(enableRestaurantOwnerAccountSuccess(result, meta));
  } catch (error) {
    yield put(enableRestaurantOwnerAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_RESTAURANT_OWNER_LIST_REQUEST, handleGetRestaurantOwnerList);
  yield takeLatest(GET_RESTAURANT_OWNER_DETAIL_REQUEST, handleGetRestaurantOwnerDetail);
  yield takeLeading(UPDATE_RESTAURANT_OWNER_REQUEST, handleUpdateRestaurantOwner);
  yield takeLeading(DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST, handleDisableRestaurantOwnerAccount);
  yield takeLeading(ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST, handleEnableRestaurantAccount);
  yield takeLatest(GET_RESTAURANT_BY_EMAIL_REQUEST, handleGetRestaurantbyEmail);
  yield takeLatest(RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST, handleInviteRestaurant);
  yield takeLatest(RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST, handleRequestConnectToRestaurant);
  yield takeLatest(UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST, handleUpdateRestaurantInvitationStatus);
}
