export const GET_RESTAURANT_OWNER_LIST_REQUEST = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_LIST_REQUEST';
export const GET_RESTAURANT_OWNER_LIST_SUCCESS = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_LIST_SUCCESS';
export const GET_RESTAURANT_OWNER_LIST_ERROR = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_LIST_ERROR';

export const GET_RESTAURANT_OWNER_DETAIL_REQUEST = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_DETAIL_REQUEST';
export const GET_RESTAURANT_OWNER_DETAIL_SUCCESS = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_DETAIL_SUCCESS';
export const GET_RESTAURANT_OWNER_DETAIL_ERROR = 'RestaurantOwnerProvider/GET_RESTAURANT_OWNER_DETAIL_ERROR';

export const GET_RESTAURANT_BY_EMAIL_REQUEST = 'RestaurantOwnerProvider/GET_RESTAURANT_BY_EMAIL_REQUEST';
export const GET_RESTAURANT_BY_EMAIL_SUCCESS = 'RestaurantOwnerProvider/GET_RESTAURANT_BY_EMAIL_SUCCESS';
export const GET_RESTAURANT_BY_EMAIL_ERROR = 'RestaurantOwnerProvider/GET_RESTAURANT_BY_EMAIL_ERROR';

export const RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST = 'RestaurantOwnerProvider/RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST';
export const RESTAURANT_OWNER_INVITE_RESTAURANT_SUCCESS = 'RestaurantOwnerProvider/RESTAURANT_OWNER_INVITE_RESTAURANT_SUCCESS';
export const RESTAURANT_OWNER_INVITE_RESTAURANT_ERROR = 'RestaurantOwnerProvider/RESTAURANT_OWNER_INVITE_RESTAURANT_ERROR';

export const RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST = 'RestaurantOwnerProvider/RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST';
export const RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_SUCCESS = 'RestaurantOwnerProvider/RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_SUCCESS';
export const RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_ERROR = 'RestaurantOwnerProvider/RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_ERROR';

export const UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST';
export const UPDATE_RESTAURANT_INVITATION_STATUS_SUCCESS = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_INVITATION_STATUS_SUCCESS';
export const UPDATE_RESTAURANT_INVITATION_STATUS_ERROR = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_INVITATION_STATUS_ERROR';

export const UPDATE_RESTAURANT_OWNER_REQUEST = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_OWNER_REQUEST';
export const UPDATE_RESTAURANT_OWNER_SUCCESS = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_OWNER_SUCCESS';
export const UPDATE_RESTAURANT_OWNER_ERROR = 'RestaurantOwnerProvider/UPDATE_RESTAURANT_OWNER_ERROR';

export const DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST = 'RestaurantOwnerProvider/DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST';
export const DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS = 'RestaurantOwnerProvider/DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS';
export const DISABLE_RESTAURANT_OWNER_ACCOUNT_ERROR = 'RestaurantOwnerProvider/DISABLE_RESTAURANT_OWNER_ACCOUNT_ERROR';

export const ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST = 'RestaurantOwnerProvider/ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST';
export const ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS = 'RestaurantOwnerProvider/ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS';
export const ENABLE_RESTAURANT_OWNER_ACCOUNT_ERROR = 'RestaurantOwnerProvider/ENABLE_RESTAURANT_OWNER_ACCOUNT_ERROR';
