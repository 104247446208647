import React from 'react';
import { Redirect } from 'react-router-dom';
import BrandList from '../../Supplier_List/BrandList';
import BrandOwnerList from '../BrandOwnerList';

const RedirectToBrandList = () => <Redirect to={'/brands/list'} />;

const indexRoutes = [
  {
    path: '/brands/brand-owners',
    component: BrandOwnerList,
  },
  {
    path: '/brands/list',
    component: BrandList,
  },
  {
    path: '/brands',
    component: RedirectToBrandList,
  },
];
export default indexRoutes;
