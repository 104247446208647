/* eslint-disable no-unused-vars */
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Space, Typography, Tooltip, Card, Spin
} from 'antd';

import useTableControl from 'components/Hooks/useTableControl';

import { getRestaurantMenuItemRequest } from 'providers/RestaurantProvider/actions';

import Table from 'components/Table';
import Helpers from 'utils/helpers';

import DefaultProductImage from 'images/defaultProduct.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import ProductListSearchForm from '../../../Suppliers/BrandOwner_Detail/ProductList/ProductListSearchForm';

const ProductList = () => {
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );
  const { restaurantMenuItemList, restaurantMenuItemTotal } = useSelector(
    (state) => state.restaurantProvider,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      brandId: 'bi',
    },
  });
  const { page, limit } = pageControl;
  const { brandId } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    if (restaurantDetail?.objectId) {
      const fetchData = async () => {
        setTableLoading(true);
        try {
          await dispatch(
            getRestaurantMenuItemRequest({
              page,
              limit,
              restaurantId: restaurantDetail?.objectId,
              brandId,
            })
          );
        } catch (error) {
          console.error(error);
        }
        setTableLoading(false);
      };

      fetchData();
    }
  }, [page, limit, restaurantDetail, brandId]);

  const columns = [
    {
      title: 'ID',
      width: 110,
      dataIndex: 'objectId',
      key: 'objectId',
      fixed: 'left',
    },
    {
      ellipsis: true,
      width: 210,
      dataIndex: 'menu',
      title: '商品名',
      render: (record) => (
        <div className="flex items-center full-w">
          <img
            style={{ borderRadius: 4, marginRight: 8 }}
            alt="name"
            src={_get(record, 'images[0]', DefaultProductImage)}
            width={32}
            height={32}
          />
          <Space
            direction="vertical"
            size={0}
            className="full-w"
            style={{ overflow: 'hidden' }}
          >
            <Tooltip placement="topLeft" title={_get(record, 'name')}>
              <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
            </Tooltip>
            <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
              {_get(record, 'standard', '-')} • {_get(record, 'amount', '-')} ／{' '}
              {_get(record, 'unit', '-')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
    {
      ellipsis: true,
      width: 190,
      title: 'ブランド',
      dataIndex: 'menu',
      key: 'brand',
      render: (record) => !_isEmpty(record) && (
        <div className="flex items-center full-w">
          <img
            style={{ borderRadius: 4, marginRight: 8 }}
            alt="name"
            src={_get(record, 'brand.image', DefaultBrandImage)}
            width={32}
            height={32}
          />
          <Typography.Text ellipsis>
            {_get(record, 'brand.name')}
          </Typography.Text>
        </div>
      ),
    },
    {
      ellipsis: true,
      width: 210,
      title: '販売者 ',
      dataIndex: 'supplier',
      render: (record) => (
        <div className="flex items-center full-w">
          <img
            style={{ borderRadius: 4, marginRight: 8 }}
            alt="name"
            src={_get(record, 'image', DefaultSupplierImage)}
            width={32}
            height={32}
          />
          <Space
            direction="vertical"
            size={0}
            className="full-w"
            style={{ overflow: 'hidden', flex: 1 }}
          >
            <Tooltip placement="topLeft" title={_get(record, 'name')}>
              <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
            </Tooltip>
            <Tooltip placement="topLeft" title={_get(record, 'email')}>
              <Typography.Text
                type="secondary"
                style={{ fontSize: 12 }}
                ellipsis
                copyable={
                  !!_get(record, 'email') && {
                    tooltips: ['コピー', 'コピーしました'],
                  }
                }
              >
                {_get(record, 'email', '-')}
              </Typography.Text>
            </Tooltip>
          </Space>
        </div>
      ),
    },
    {
      title: '単価（税込）',
      key: 'prices',
      dataIndex: 'menuPrice',
      align: 'right',
      width: 160,
      render: (record) => `¥${Helpers.numberWithCommas(record?.priceAfterTax)}`,
    },
    {
      width: 160,
      title: 'ブランドフィー',
      dataIndex: 'menu',
      key: 'brandFee',
      align: 'right',
      render: (record) => record?.brandFee && `¥${Helpers.numberWithCommas(record?.brandFee)}`,
    },
  ];

  if (!restaurantDetail?.objectId) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Space direction="vertical" size="middle" className="full-w">
      <Card className="highlight-card padding-card-16">
        <ProductListSearchForm {...{ filterControl, onFilterChange }} />
      </Card>
      <Card
        className="highlight-card padding-card"
        bordered={false}
      >
        <Table
          columns={columns}
          data={restaurantMenuItemList}
          total={restaurantMenuItemTotal}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
            marginRight: true,
          }}
          scroll={{ x: 1140 }}
        />
      </Card>
    </Space>
  );
};

export default ProductList;
