/* eslint-disable react/display-name */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const withRefresh = (name) => (WrappedComponent) => (props) => {
  const refreshValue = useSelector(
    (state) => state.commonProvider.refreshValue[name],
    shallowEqual
  );

  return <WrappedComponent key={refreshValue} {...props} />;
};

export default withRefresh;
