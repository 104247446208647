import _get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Switch, Route, useLocation, Redirect
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Dropdown, Space, Typography, Menu, Avatar, Alert, Button
} from 'antd';
import { LogoutOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';

import {
  logoutRequest,
  resendVerifyEmailRequest,
} from 'providers/AuthProvider/actions';

import FooterBar from 'components/FooterBar';
import AppLogo from 'images/app-logo.png';
import TextLogo from 'images/AuthPage/text-logo.svg';

import ForRestaurantOwner from 'images/logo-restaurant-owner.svg';
import ForBrandOwner from 'images/logo-brand-owner.svg';
import ForSupplier from 'images/logo-supplier.svg';
import RoleRegister from './RoleRegister';
import SupplierRegister from './SupplierRegister';
import BrandOwnerRegister from './BrandOwnerRegister';
import RestaurantOwnerRegister from './RestaurantOwnerRegister';

import './style.less';

const menu = () => {
  const dispatch = useDispatch();
  return (
    <Menu>
      <Menu.Item
        icon={<LogoutOutlined />}
        key="logout"
        onClick={() => dispatch(logoutRequest())}
        style={{
          color: '#cf1421',
        }}
      >
        ログアウト
      </Menu.Item>
    </Menu>
  );
};

const LogoByPathName = {
  '/reg/brand': ForBrandOwner,
  '/reg/restaurant': ForRestaurantOwner,
  '/reg/supplier': ForSupplier,
};

const HeaderBar = () => {
  const { pathname } = useLocation();

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  return (
    <div id="header-bar" className="full-w flex justify-between items-center">
      {Object.keys(LogoByPathName).includes(pathname) ? (
        <img src={LogoByPathName[pathname]} alt="for-role" height={33} />
      ) : (
        <Space size={10}>
          <img alt="logo-1" src={AppLogo} height={20} />
          <img alt="logo-2" src={TextLogo} height={16} width={70} />
        </Space>
      )}
      <Dropdown
        placement="bottomRight"
        overlay={menu()}
        trigger={['click']}
        overlayStyle={{ minWidth: 0 }}
      >
        <Space
          size={12}
          className="flex justify-center items-center cursor-pointer"
        >
          <Avatar size={24} icon={<UserOutlined />} />
          <Typography.Text>{_get(currentUser, 'email')}</Typography.Text>
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
};

const indexRoutes = [
  {
    path: '/reg/role',
    component: RoleRegister,
    transitionClass: 'fade-from-left',
  },
  {
    path: '/reg/supplier',
    component: SupplierRegister,
    transitionClass: 'fade-from-right',
  },
  {
    path: '/reg/brand',
    component: BrandOwnerRegister,
    transitionClass: 'fade-from-right',
  },
  {
    path: '/reg/restaurant',
    component: RestaurantOwnerRegister,
    transitionClass: 'fade-from-right',
  },
  { path: '/reg', component: () => <Redirect to="/reg/role" /> },
];

const RegisterPage = () => {
  const location = useLocation();

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  if (currentUser.role) {
    return <Redirect to="/" />;
  }

  const dispatch = useDispatch();
  const [resendVerifyEmailLoading, setResendVerifyEmailLoading] = useState(false);
  const handleResendVerifyEmail = async () => {
    setResendVerifyEmailLoading(true);
    try {
      await dispatch(resendVerifyEmailRequest());
    } catch (error) {
      console.error(error);
    }
    setResendVerifyEmailLoading(false);
  };

  return (
    <div id="register" className="flex direction-column items-center">
      <div className="footer">
        <FooterBar />
      </div>
      <HeaderBar />

      {!currentUser.emailVerified && (
        <Alert
          type="warning"
          showIcon
          message={(
            <>
              <Typography.Text>
                <Typography.Text strong>{currentUser.email}</Typography.Text>
                宛に認証メールをお送りしました。認証していただき、登録を完了してください。
              </Typography.Text>
              <Button
                type="link"
                loading={resendVerifyEmailLoading}
                onClick={handleResendVerifyEmail}
              >
                再送する
              </Button>
            </>
          )}
          className="email-alert"
        />
      )}

      <div className="container">
        <TransitionGroup>
          <Switch>
            {indexRoutes.map((route, index) => (
              <Route key={index} path={route.path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    key={location.key}
                    classNames={route.transitionClass}
                    timeout={300}
                    unmountOnExit
                  >
                    <route.component />
                  </CSSTransition>
                )}
              </Route>
            ))}
          </Switch>
        </TransitionGroup>
      </div>
      <div style={{ visibility: 'hidden', marginTop: 32 }}>
        <FooterBar />
      </div>
    </div>
  );
};

export default RegisterPage;
