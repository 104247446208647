import _map from 'lodash/map';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import {
  Tag, Space, Dropdown, Menu, Modal
} from 'antd';

import {
  ORDER_STATUS,
  ORDER_STATUS_JP,
  ORDER_STATUS_COLOR,
} from 'utils/constants';
import toastMessage from 'utils/toastMessage';

import { doRefresh } from 'providers/CommonProvider/actions';
import { updateOrderStatusRequest } from 'providers/OrderProvider/actions';

import TrackingIdModal from './TrackingIdModal';

const { confirm } = Modal;

const OrderStatusChange = ({ orderDetail }) => {
  const orderId = _get(orderDetail, 'objectId');
  const orderStatus = _get(orderDetail, 'status');

  const [trackingIdModal, setTrackingIdModal] = useState(false);

  const dispatch = useDispatch();
  const updateOrder = async (params) => {
    await dispatch(updateOrderStatusRequest(params));
    dispatch(doRefresh({ target: 'orderList' }));
  };

  const showConfirm = (message, actionFn, confirmText) => {
    confirm({
      title: message,
      okText: _get(confirmText, 'yes', 'はい'),
      okType: 'primary',
      cancelText: _get(confirmText, 'no', 'いいえ'),
      onOk: () => {
        actionFn();
      },
    });
  };

  const handleTrackingIdModalSubmit = async (value) => {
    showConfirm('配送しますか？', async () => {
      await updateOrder({
        orderId,
        status: ORDER_STATUS.DELIVERING,
        ...value,
      });
      setTrackingIdModal(false);
    });
  };

  const targetStatusActionMap = {
    [ORDER_STATUS.CANCELLED]: () => showConfirm(
      '注文をキャンセルしますか？',
      () => updateOrder({
        orderId,
        status: ORDER_STATUS.CANCELLED,
      }),
      { yes: 'キャンセルする', no: 'もどる' }
    ),
    [ORDER_STATUS.PROCESSING]: () => showConfirm('続行してよろしいですか？', () => updateOrder({
      orderId,
      status: ORDER_STATUS.PROCESSING,
    })
    ),
    [ORDER_STATUS.DELIVERING]: () => {
      if (_findIndex(orderDetail?.items, (i) => i.isIndefinitePrice || i.isMarketPrice) > -1) {
        toastMessage.error({
          message: '注文ステータスの更新をキャンセルしました。',
          description:
            '注文詳細に移動し、金額と数量を確認した上で注文ステータスを更新してください。',
        });
      } else {
        setTrackingIdModal(true);
      }
    },
    [ORDER_STATUS.DELIVERED]: () => showConfirm('配送を完了しますか？', () => updateOrder({
      orderId,
      status: ORDER_STATUS.DELIVERED,
    })
    ),
  };

  const targetStatusMap = {
    [ORDER_STATUS.SUBMITTED]: [ORDER_STATUS.PROCESSING, ORDER_STATUS.CANCELLED],
    [ORDER_STATUS.PROCESSING]: [
      ORDER_STATUS.DELIVERING,
      ORDER_STATUS.CANCELLED,
    ],
    [ORDER_STATUS.DELIVERING]: [ORDER_STATUS.DELIVERED],
  };

  const renderStatusMenu = () => (
    <Menu
      onClick={({ key }) => {
        targetStatusActionMap[key]();
      }}
    >
      {_map(targetStatusMap[orderStatus], (target) => (
        <Menu.Item key={target}>
          <Space>
            <span style={{ fontWeight: 500, fontSize: 14 }}>
              ステータス変更
            </span>
            <Tag color={ORDER_STATUS_COLOR[target]}>
              {ORDER_STATUS_JP[target]}
            </Tag>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      {trackingIdModal && (
        <TrackingIdModal
          visible
          orderDetail={orderDetail}
          onSubmit={handleTrackingIdModalSubmit}
          onCancel={() => setTrackingIdModal(false)}
        />
      )}
      <Dropdown overlay={renderStatusMenu} trigger={['click']}>
        <Tag
          style={{
            fontSize: 14,
            fontWeight: 500,
            padding: '2px 8px',
          }}
          color={ORDER_STATUS_COLOR[orderStatus]}
        >
          <Space>
            <span>{ORDER_STATUS_JP[orderStatus]}</span>
            {_get(targetStatusMap, orderStatus, []).length > 0 && (
              <DownOutlined />
            )}
          </Space>
        </Tag>
      </Dropdown>
    </>
  );
};

OrderStatusChange.propTypes = {
  orderDetail: PropTypes.object,
};

export default OrderStatusChange;
