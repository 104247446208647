import Constants from 'utils/constants';
import Regex from 'utils/regex';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg',
  'application/pdf',
];

const validate = (values) => {
  const errors = {};

  if (!values?.title) {
    errors.title = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values?.message) {
    errors.message = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (values?.file) {
    if (!acceptedFileExtensions.includes(values?.file.type)) {
      errors.file = 'ファイル拡張子が正しくありません';
    }
    if (values?.file?.size > 50e6) {
      // Limit size to 50MB
      errors.file = `${50e6 / 1e6}MB以下のファイルを指定してください`;
    }
  }
  if (values?.schedule && !values?.announceTime) {
    errors.announceTime = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    values?.announcementTarget
    === Constants.RESTAURANT_ANNOUNCEMENT.TARGET.RESTAURANT_GROUP
  ) {
    if (!values?.restaurantGroup) {
      errors.restaurantGroup = Constants.ERROR_MESSAGE.REQUIRED;
    } else if (
      [
        Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
          .USER_REGISTER_DATE_VERSUS_TIME,
        Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
          .USER_LAST_ORDER_DATE_VERSUS_TIME,
      ].includes(values?.restaurantGroup)
    ) {
      if (!values?.userDateComparison) {
        errors.userDateComparison = Constants.ERROR_MESSAGE.REQUIRED;
      }
      if (!values?.userDate) {
        errors.userDate = Constants.ERROR_MESSAGE.REQUIRED;
      }
    } else if (
      values?.restaurantGroup
        === Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER
      && !values?.restaurantIds?.trim()
    ) {
      errors.restaurantIds = Constants.ERROR_MESSAGE.REQUIRED;
    }
  }

  if (
    values?.attachmentType === 'url'
    && values?.url?.trim()
    && !Regex.URL.test(values?.url?.trim())
  ) {
    errors.url = 'Invalid URL. Please enter a valid URL';
  }

  return errors;
};

export default validate;
