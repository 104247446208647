import React from 'react';
import { Card } from 'antd';

import BrandList from './BrandList';

const SupplierBrandList = () => (
  <Card className="bg-transparent" bordered={false}>
    <BrandList />
  </Card>
);

export default SupplierBrandList;
