import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Card, Typography, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import {
  getGuestInformationListRequest,
  exportGuestListRequest,
} from 'providers/GuestInformationProvider/actions';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import withRefresh from 'components/HOCs/withRefresh';

import StatusChange from './StatusChange';

import './style.less';

const GuestInformationList = () => {
  const dispatch = useDispatch();

  const guestInformationList = useSelector(
    (state) => state.guestInformationProvider.guestInformationList,
    shallowEqual
  );

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const [tableLoading, setTableLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(getGuestInformationListRequest({ page, limit }));
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit]);

  const handleExport = async () => {
    setExportLoading(true);
    try {
      await dispatch(exportGuestListRequest());
    } catch (error) {
      console.error(error);
    }
    setExportLoading(false);
  };

  const columns = [
    {
      title: 'ID',
      key: 'objectId',
      dataIndex: 'objectId',
      width: 130,
    },
    {
      title: 'メールアドレス',
      key: 'email',
      dataIndex: 'email',
      width: 220,
      render: (record) => (
        <Typography.Text
          copyable={{
            tooltips: ['コピー', 'コピーしました'],
          }}
        >
          {record}
        </Typography.Text>
      ),
    },
    {
      title: '店舗名',
      key: 'name',
      dataIndex: 'name',
      width: 220,
    },
    {
      title: '店舗の電話番号',
      key: 'phone',
      dataIndex: 'phone',
      width: 170,
    },
    {
      title: 'コンタクト状況',
      key: 'status',
      width: 146,
      fixed: 'right',
      render: (record) => <StatusChange guestInformationDetail={record} />,
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card
        id="guest-information-list"
        className="highlight-card padding-card"
        bordered={false}
      >
        <span
          className="flex justify-end"
          style={{
            padding: '15px 18px',
          }}
        >
          <Button
            loading={exportLoading}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
          >
            ゲストリストをダウンロード
          </Button>
        </span>
        <Table
          columns={columns}
          data={guestInformationList?.list || []}
          total={guestInformationList?.total || 0}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 866 }}
        />
      </Card>
    </Card>
  );
};

export default withRefresh('guestInformationList')(GuestInformationList);
