import _get from 'lodash/get';
import { isDirty } from 'redux-form';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Switch,
  Route,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Modal, Spin, Typography, Tabs
} from 'antd';
import { getCollectionDetailRequest } from 'providers/CollectionProvider/actions';

import PageHeader from 'components/PageHeader';
import NavigationButton from 'components/Navigation';
import StatusSwitch from '../StatusSwitch';
import indexRoutes from './routes';
import './style.less';

const { confirm } = Modal;
const { TabPane } = Tabs;

const breadcrumbItems = [
  {
    path: '/collections',
    breadcrumbName: 'セット商品',
  },
  {
    breadcrumbName: 'セット商品の追加',
  },
];

const showConfirm = (modalProps, actionFn) => {
  confirm({
    okText: '削除',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
    ...modalProps,
  });
};

const CollectionsDetail = () => {
  const { pathname } = useLocation();
  const { collectionId } = useParams();
  const { replace, push } = useHistory();
  const dispatch = useDispatch();
  const [collectionDetailLoaded, setCollectionDetailLoaded] = useState(false);
  const collectionList = useSelector(
    (state) => state.collectionProvider.collectionList,
    shallowEqual
  );
  const dirty = useSelector((state) => isDirty('collectionCreateForm')(state));

  const handleCancel = () => {
    if (!dirty) {
      return push('/collections');
    }
    return showConfirm(
      { title: '中断してよろしいですか？', okText: 'はい' },
      () => push('/collections')
    );
  };

  const collectionDetail = useSelector(
    (state) => state.collectionProvider.collectionDetail,
    shallowEqual
  );

  const fetchCollectionDetail = useCallback(async () => {
    setCollectionDetailLoaded(false);
    try {
      await dispatch(getCollectionDetailRequest({ objectId: collectionId }));
    } catch (error) {
      console.log(error);
    }
    setCollectionDetailLoaded(true);
  }, [dispatch, collectionId]);

  useEffect(() => {
    fetchCollectionDetail();
  }, [fetchCollectionDetail]);

  const onTabClick = (key) => {
    const pushPath = `/collections/detail/${collectionId}/${key}`;
    replace(pushPath);
  };

  const selectedKeys = pathname.split('/')[4];

  const renderHeaderTitle = () => (
    <div className="collection-info">
      <Typography.Title level={3}>
        {_get(collectionDetail, 'name', '--')}
      </Typography.Title>
      <div className="flex items-center">
        <Typography.Text type="secondary">
          ID: {collectionId} |{' '}
        </Typography.Text>
        <StatusSwitch
          rowRecord={{
            status: collectionDetail?.status,
            objectId: collectionDetail?.objectId,
          }}
        />
      </div>
    </div>
  );

  if (!collectionDetailLoaded) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const getPath = (id) => `/collections/detail/${id}/in-app`;

  return (
    <div className="collection-detail">
      <PageHeader
        title={renderHeaderTitle()}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleCancel}
        extra={NavigationButton(
          collectionId,
          dispatch,
          collectionList,
          getPath
        )}
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="セット商品詳細" key="in-app" />
            <TabPane
              tab={(
                <span>
                  レストラン
                  <span className="background-filter-menu-total">
                    {_get(collectionDetail, 'restaurantCount', 0)}
                  </span>
                </span>
              )}
              key="restaurant-list"
            />
          </Tabs>
        )}
      />
      <Switch>
        {indexRoutes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

export default CollectionsDetail;
