import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, Descriptions, Divider, Empty, Typography
} from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const { Text } = Typography;

const BillingInformation = ({ restaurantDetail, showEditAction = false }) => {
  const {
    billingName,
    billingPostalCode,
    billingPrefecture,
    billingAddress,
    billingPhone,
    billingManagerName,
    bankAccountName,
    bankAccountNameKana,
    billingBuildingName,
  } = restaurantDetail;

  const dispatch = useDispatch();

  const dataBillingInformation = _pick(restaurantDetail, [
    'billingName',
    'billingPostalCode',
    'billingPrefecture',
    'billingAddress',
    'billingPhone',
    'billingManagerName',
    'bankAccountName',
    'bankAccountNameKana',
    'buildingName',
  ]);
  const renderAddressBillingDescription = (
    _billingName,
    _billingPostalCode,
    _billingPrefecture,
    _billingAddress,
    _billingPhone,
    _billingBuildingName
  ) => (
    <Descriptions.Item>
      <div>
        <div>{_billingName}</div>
        <div>{_billingPostalCode}</div>
        <div>
          {_billingPrefecture} {_billingAddress} {_billingBuildingName}
        </div>
        <div>店舗電話番号: {_billingPhone}</div>
      </div>
    </Descriptions.Item>
  );

  return (
    <>
      <Card
        className="highlight-card padding-card-16"
        title="請求先情報"
        bordered={false}
        extra={
          showEditAction && (
            <Button
              onClick={() => dispatch(
                push(
                  `/restaurants/detail/${restaurantDetail?.formattedObjectId}/billing`
                )
              )}
            >
              更新
            </Button>
          )
        }
      >
        {!_isEmpty(dataBillingInformation) ? (
          <>
            {(billingName
              || billingPostalCode
              || billingPrefecture
              || billingAddress
              || billingPhone
              || billingBuildingName) && (
              <Descriptions
                title="請求情報の住所"
                column={1}
                bordered
                labelStyle={{ verticalAlign: 'top' }}
              >
                {renderAddressBillingDescription(
                  billingName,
                  billingPostalCode,
                  billingPrefecture,
                  billingAddress,
                  billingPhone,
                  billingBuildingName
                )}
              </Descriptions>
            )}
            {billingManagerName && (
              <>
                <Divider />
                <Descriptions
                  title="担当者名"
                  column={1}
                  bordered
                  labelStyle={{ verticalAlign: 'top' }}
                >
                  <Descriptions.Item>{billingManagerName}</Descriptions.Item>
                </Descriptions>
              </>
            )}
            {(bankAccountName || bankAccountNameKana) && (
              <>
                <Divider />
                <Descriptions
                  title="振込元口座名義"
                  column={1}
                  bordered
                  labelStyle={{ verticalAlign: 'top' }}
                >
                  <Descriptions.Item>
                    <div>
                      <div>{bankAccountName}</div>
                      <Text style={{ fontSize: 12 }}>
                        {bankAccountNameKana}
                      </Text>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    </>
  );
};

BillingInformation.propTypes = {
  restaurantDetail: PropTypes.object,
  showEditAction: PropTypes.bool,
};

export default BillingInformation;
