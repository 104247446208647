import _pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { isDirty, reset, submit } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Button, Space, Modal, Spin, Typography
} from 'antd';

import { doRefresh } from 'providers/CommonProvider/actions';
import {
  getBrandOwnerDetailRequest,
  updateBrandOwnerRequest,
} from 'providers/BrandOwnerProvider/actions';
import { getCurrentUserRequest } from 'providers/AuthProvider/actions';

import toastMessage from 'utils/toastMessage';

import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';
import ProfileUpdateForm from 'containers/RegisterPage/BrandOwnerRegister/RegisterBrandOwnerForm';

const { confirm } = Modal;

const BrandOwnerProfile = () => {
  const dispatch = useDispatch();
  const profileDetail = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerDetail,
    shallowEqual
  );
  const dataLoading = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerDetailLoading,
    shallowEqual
  );

  const [actionLoading, setActionLoading] = useState(false);

  const dirty = useSelector((state) => isDirty('registerProfileForm')(state));

  const fetchBrandOwnerDetail = useCallback(() => {
    dispatch(getBrandOwnerDetailRequest());
  });

  useEffect(() => {
    fetchBrandOwnerDetail();
  }, []);

  const showConfirm = (message, actionFn) => {
    confirm({
      title: message,
      okText: 'はい',
      okType: 'primary',
      cancelText: 'いいえ',
      onOk: () => {
        actionFn();
      },
    });
  };

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateBrandOwnerRequest(values));
      toastMessage.success({
        description: 'プロフィールを更新しました。',
      });
      dispatch(getCurrentUserRequest());
      dispatch(doRefresh({ target: 'profileDetail' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const breadcrumbItems = [
    {
      path: '/settings',
      breadcrumbName: '各種設定',
    },
    {
      breadcrumbName: '販売者の住所',
    },
  ];

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title="プロフィール設定"
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <ProfileUpdateForm
          onSubmit={handleSubmit}
          initialValues={{
            ..._pick(profileDetail, ['name', 'nameKana', 'phone']),
            image: profileDetail.image && [
              { uid: '-1', url: profileDetail.image },
            ],
          }}
        />
      </Card>
      {dirty && (
        <div
          className="full-w"
          style={{
            background: '#293137',
            position: 'absolute',
            bottom: 0,
          }}
        >
          <Space
            size={8}
            className="flex justify-between max-width-644"
            style={{ padding: '16px 32px' }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8} className="full-w flex justify-end">
              <Button
                type="default"
                disabled={actionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('registerProfileForm'))
                )}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                loading={actionLoading}
                onClick={() => dispatch(submit('registerProfileForm'))}
              >
                保存する
              </Button>
            </Space>
          </Space>
        </div>
      )}
    </div>
  );
};

export default withRefresh('profileDetail')(BrandOwnerProfile);
