import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import React from 'react';
import { replace } from 'connected-react-router';
import { Button } from 'antd';
import ArrowLeftIcon from 'images/icon-arrow-left.svg';
import ArrowRightIcon from 'images/icon-arrow-right.svg';

const NavigationButton = (orderId, dispatch, orderList) => {
  const calcNavigateId = () => {
    const orderIdList = _map(orderList?.list, (item) => item.objectId) || [];
    const index = _findIndex(orderIdList, (item) => item === orderId);
    return {
      current: index,
      backTo: index > 0 && orderIdList[index - 1],
      nextTo: index < orderIdList.length - 1 && orderIdList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, id) => {
    evt.preventDefault();
    dispatch(replace(`/orders/detail/${id}`));
  };

  const { current, backTo, nextTo } = calcNavigateId();
  const orderListList = _get(orderList, 'list', []);
  if (orderListList.length < 1) {
    return [];
  }

  return (
    <>
      <span
        key="current"
        className="text-bold"
        style={{ fontSize: 14, marginLeft: 16 }}
      >
        {`${current + 1} / ${orderListList.length}`}
      </span>
      <Button
        key="prev"
        type="primary"
        disabled={!backTo}
        onClick={(evt) => handleReplaceRoute(evt, backTo)}
        icon={<img src={ArrowLeftIcon} alt="arrow-left" />}
      />
      <Button
        key="next"
        type="primary"
        disabled={!nextTo}
        onClick={(evt) => handleReplaceRoute(evt, nextTo)}
        icon={<img src={ArrowRightIcon} alt="arrow-right" />}
      />
    </>
  );
};

export default NavigationButton;
