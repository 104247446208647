import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';
import { updateRankingRequest } from 'providers/RankingProvider/actions';
import { STATUS } from 'utils/constants';

const { confirm } = Modal;

const showConfirm = (message, content, actionFn) => {
  confirm({
    title: message,
    content,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const DisplayToggle = ({ record, rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const changeStatusTag = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(updateRankingRequest(params));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <div
      role="button"
      className="status-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-hidden
    >
      <div
        style={{
          opacity: rowRecord?.status === STATUS.INACTIVE ? 0.4 : 1,
        }}
      >
        <span
          className={`${record ? 'dot' : 'dot dot--inactive'}`}
        />
        <span
          style={{
            color: '#000000d9',
            fontWeight: 'normal'
          }}
        >{record ? '表示' : '非表示'}
        </span>
      </div>
      <Switch
        disabled={
          changeStatusLoading || rowRecord?.status === STATUS.INACTIVE
        }
        size="small"
        checked={record}
        onClick={() => {
          const { name, objectId } = rowRecord;
          const nextStatus = !record;
          showConfirm(
            nextStatus
              ? ` アプリトップ画面でランキング ${name} を表示しますか？`
              : `アプリトップ画面でランキング ${name} を非表示にしますか？`,
            nextStatus ? `このランキング ${name} がアプリトップ画面で表示されます。` : `このランキング ${name} がアプリトップ画面で表示されなくなります。`,
            () => changeStatusTag({
              objectId,
              isVisibleOnHome: nextStatus,
            })
          );
        }}
      />
    </div>
  );
};

DisplayToggle.propTypes = {
  rowRecord: PropTypes.object,
  record: PropTypes.string,
};

export default DisplayToggle;
