import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';
import _pick from 'lodash/pick';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  countUnreadConversationSuccess,
  getConversationListSuccess,
  getConversationListError,
} from './actions';
import {
  COUNT_UNREAD_CONVERSATION_REQUEST,
  GET_CONVERSATION_LIST_REQUEST,
} from './constants';

export function* handleCountUnreadConversation() {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}countUnreadConversations`,
      {}
    );

    yield put(countUnreadConversationSuccess(response.result));
  } catch (error) {
    console.error(error.error);
  }
}

export function* handleGetConversationList(action) {
  const { skip, limit } = _pick(action.data, ['skip', 'limit']);
  const params = {
    skip: skip || 0,
    limit: limit || 10,
  };
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getConversationList`,
      params
    );

    yield put(getConversationListSuccess(response.result));
  } catch (error) {
    yield put(getConversationListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(COUNT_UNREAD_CONVERSATION_REQUEST, handleCountUnreadConversation);
  yield takeLeading(GET_CONVERSATION_LIST_REQUEST, handleGetConversationList);
}
