import _get from 'lodash/get';
import moment from 'moment';
import Helpers from 'utils/helpers';
import React from 'react';
import { DATE_TIME_FORMAT } from 'utils/constants';
import {
  Space, Typography, Avatar, Tooltip
} from 'antd';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';

const Columns = {};

Columns.id = {
  title: 'ID',
  dataIndex: 'formattedObjectId',
  key: 'restaurantId',
  fixed: 'left',
  width: 150
};

Columns.restaurantInfo = {
  title: 'レストラン名',
  width: 250,
  render: (record) => (
    <div className="flex items-center full-w">
      <Avatar
        alt="name"
        src={_get(record, 'image', DefaultRestaurantImage)}
        size={32}
      />
      <Space
        direction="vertical"
        size={0}
        className="restaurant-info-wrapper"
      >
        <Tooltip title={_get(record, 'name')}>
          <Typography.Text ellipsis>
            {_get(record, 'name')}
          </Typography.Text>
        </Tooltip>
        <Typography.Text type="secondary" style={{ fontSize: 12 }} ellipsis>
          {_get(record, 'email')}
        </Typography.Text>
      </Space>
    </div>
  ),
};

Columns.invitedDate = {
  title: '招待日',
  dataIndex: 'supplierRestaurant',
  key: 'invitedDate',
  width: 150,
  render: (record) => (_get(record, 'statusLog.PENDING')
    ? moment(_get(record, 'statusLog.PENDING')).format(DATE_TIME_FORMAT)
    : '-'),
};

Columns.connectedDate = {
  title: '承認日',
  dataIndex: 'supplierRestaurant',
  key: 'connectedDate',
  width: 150,
  render: (record) => (_get(record, 'statusLog.ACCEPTED')
    ? moment(_get(record, 'statusLog.ACCEPTED')).format(DATE_TIME_FORMAT)
    : '-'),
};

Columns.numberOfProduct = {
  title: '商品件数',
  dataIndex: 'menuCount',
  align: 'right',
  width: 150,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

export default Columns;
