import _range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  DndContext,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button, Table
} from 'antd';
import { push } from 'connected-react-router';

import DragIcon from 'images/icon-drag.svg';
import './style.less';
import { updateRankingDetail, updateRankingList } from 'providers/RankingProvider/actions';

const DraggableRow = (props, isRankingDetail) => {
  const dispatch = useDispatch();

  const {
    attributes, listeners, setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const { children, ...restProps } = props;
  const style = {
    ...restProps.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
    transition,
    cursor: isRankingDetail ? 'default' : 'pointer',
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };

  const handleRowClick = () => {
    if (!isRankingDetail) {
      dispatch(
        push(`/rankings/detail/${props['data-row-key']}`)
      );
    }
  };
  return (
    <tr
      ref={setNodeRef}
      {...restProps}
      style={style}
      {...attributes}
      onClick={handleRowClick}
    >
      {React.Children.map(children, (child) => {
        const { key } = child;
        if (key === 'dragHandle') {
          return (
            React.cloneElement(child, {
              children: (
                <Button
                  ref={setActivatorNodeRef}
                  icon={<img src={DragIcon} alt="drag-icon" />}
                  type="text"
                  style={{
                    touchAction: 'none',
                    cursor: 'move',
                  }}
                  {...listeners}
                />
              ),
            })
          );
        }
        return child;
      })}
    </tr>
  );
};

DraggableRow.propTypes = {
  children: PropTypes.any,
  'data-row-key': PropTypes.string,
};

const Sortable = ({
  tableLoading, rankingList, columns, reorderProductRanking, scroll = { x: 1400 }, rankingId,
}) => {
  const dispatch = useDispatch();

  const dataSource = rankingList.map((item) => ({ ...item, key: rankingId ? item.rankingMenuId : item.objectId }));

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const prevDataSource = [...dataSource];
      const oldIndex = prevDataSource.findIndex(
        (item) => item.key === active.id
      );
      const newIndex = prevDataSource.findIndex((item) => item.key === over.id);
      const newRankingList = arrayMove(prevDataSource, oldIndex, newIndex);
      if (!rankingId) {
        dispatch(updateRankingList(newRankingList));
      }
      const toAfterId = newIndex === 0 ? '' : newRankingList[newIndex - 1].key;

      let params = {
        rankingId: active.id,
        toAfterRankingId: toAfterId,
      };

      if (rankingId) {
        dispatch(updateRankingDetail(newRankingList));
        params = {
          rankingId,
          rankingMenuId: active.id,
          toAfterRankingMenuId: toAfterId,
        };
      }

      try {
        await dispatch(
          reorderProductRanking(params)
        );
      } catch (error) {
        if (!rankingId) {
          dispatch(updateRankingList(prevDataSource));
        } else {
          dispatch(updateRankingDetail(prevDataSource));
        }
      }
    }
  };

  return (
    <div className="flex" id="ranking-list-container">
      <div className="flex direction-column ranking-numbers">
        {!tableLoading
              && rankingList.length > 0
              && _range(1, rankingList.length + 1).map((item) => (
                <div className="item" key={item}>
                  {item}
                </div>
              ))}
      </div>
      <DndContext
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: (props) => DraggableRow(props, !!rankingId),
              },
            }}
            loading={tableLoading}
            pagination={false}
            columns={columns}
            dataSource={tableLoading ? [] : dataSource}
            scroll={scroll}
            style={{ flexGrow: 1, width: '100%', overflow: 'auto' }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

Sortable.propTypes = {
  tableLoading: PropTypes.bool.isRequired,
  rankingList: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  reorderProductRanking: PropTypes.func.isRequired,
  scroll: PropTypes.object,
  rankingId: PropTypes.string
};

export default Sortable;
