import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CheckCircleFilled } from '@ant-design/icons';
import {
  Modal, Button, Typography, Col, Row, Card
} from 'antd';

import { getRestaurantCollectionListRequest } from 'providers/CollectionProvider/actions';

import AssignMenus from './AssignMenus';
import Collections from './Collections';
import renderSelectedMenus from './Menu';

import './style.less';

const { confirm } = Modal;
const { Text } = Typography;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const UpdateDialog = ({
  getRestaurantMenuItemRequest,
  restaurantDetail,
  handleCloseDialog,
  handleAssignMenuItem,
  dialogVisible,
  resetCategoryList,
  resetMenuItemList,
  assignedMenuItemList,
  resetAllAssignedMenuItemList,
}) => {
  const [restaurantMenuDataLoaded, setRestaurantMenuDataLoaded] = useState(false);
  const [selectedMenusMap, setSelectedMenusMap] = useState({});
  const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);

  const resetModal = () => {
    resetCategoryList();
    resetMenuItemList();
    resetAllAssignedMenuItemList();
    handleCloseDialog();
  };

  const restaurantCollectionList = useSelector(
    (state) => state.collectionProvider.restaurantCollectionList,
    shallowEqual
  );
  useEffect(() => {
    setSelectedCollectionIds(
      _map(restaurantCollectionList?.list, (o) => o?.collection?.objectId)
    );
  }, [restaurantCollectionList]);

  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    await getRestaurantMenuItemRequest({
      page: 1,
      limit: 1000,
      restaurantId: _get(restaurantDetail, 'objectId'),
    });
    await dispatch(
      getRestaurantCollectionListRequest({
        restaurantId: _get(restaurantDetail, 'objectId'),
        page: 1,
        limit: 1000,
      })
    );
    setRestaurantMenuDataLoaded(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setSelectedMenusMap(
      _reduce(
        assignedMenuItemList,
        (pre, resMenu) => {
          pre[resMenu?.menu?.objectId] = {
            ...resMenu.menu,
            images: _get(resMenu, 'menu.images').map((image) => ({
              url: image,
            })),
            collectionIds: resMenu.collectionIds,
            menuPriceId: _get(resMenu, 'menuPrice.objectId'),
          };
          return pre;
        },
        {}
      )
    );
  }, [assignedMenuItemList]);

  const assignRestaurantMenuItem = async () => {
    await handleAssignMenuItem({
      menus: Object.values(selectedMenusMap),
      collectionIds: selectedCollectionIds,
    });
  };

  const handleUnselectItem = (e, id) => {
    e.preventDefault();
    setSelectedMenusMap((pre) => {
      const incoming = { ...pre };
      delete incoming[id];
      return incoming;
    });
  };

  const handleSelectMenuPrice = (priceId, menuId) => {
    setSelectedMenusMap((pre) => {
      const incoming = { ...pre };
      incoming[menuId].menuPriceId = priceId;
      return incoming;
    });
  };

  return (
    <Modal
      title={`${_get(restaurantDetail, 'name', '')}の商品を選択`}
      visible={dialogVisible}
      width={1200}
      onCancel={() => showConfirm('中断してよろしいですか？', resetModal)}
      footer={(
        <div>
          <Button
            className="form-button"
            onClick={() => showConfirm('中断してよろしいですか？', resetModal)}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            disabled={!restaurantMenuDataLoaded}
            onClick={() => showConfirm(
              `この商品を${_get(
                restaurantDetail,
                'name',
                ''
              )}に追加しますか？`,
              assignRestaurantMenuItem
            )}
          >
            保存する
          </Button>
        </div>
      )}
      destroyOnClose
      centered
      maskClosable={false}
      bodyStyle={{
        minHeight: '80vh',
        padding: 0,
        position: 'relative',
      }}
    >
      <Row
        style={{ height: '100%', position: 'relative' }}
        id="restaurant-detail-assign-menu-modal"
      >
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 16 }}
          style={{
            padding: '24px',
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AssignMenus
            selectedMenusMap={selectedMenusMap}
            setSelectedMenusMap={setSelectedMenusMap}
          />
        </Col>
        <Col
          id="selected-menu-column"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 8 }}
        >
          <div className="flex items-center">
            <CheckCircleFilled className="checked-icon" />
            <Text strong>
              選択中の商品 {Object.values(selectedMenusMap).length}
            </Text>
          </div>
          <div className="selected-menu-list">
            <div className="selected-menu-list-content">
              <Card
                style={{
                  maxHeight: 'calc(55% - 16px)',
                  margin: '8px 0',
                  border: 'solid 1px rgba(0, 0, 0, 0.15)',
                  borderRadius: 4,
                }}
              >
                <Collections
                  selectedCollectionIds={selectedCollectionIds}
                  setSelectedCollectionIds={setSelectedCollectionIds}
                  selectedMenusMap={selectedMenusMap}
                  setSelectedMenusMap={setSelectedMenusMap}
                >
                  <div className="menu-list">
                    {renderSelectedMenus(
                      restaurantMenuDataLoaded,
                      _filter(
                        Object.values(selectedMenusMap),
                        (o) => !_isEmpty(o.collectionIds)
                      ),
                      handleSelectMenuPrice,
                      handleUnselectItem,
                      true
                    )}
                  </div>
                </Collections>
              </Card>
              <Card
                style={{
                  flexGrow: 1,
                  height: '100%',
                  border: 'solid 1px rgba(0, 0, 0, 0.15)',
                  borderRadius: 4,
                  overflow: 'hidden',
                }}
              >
                <div className="overview">
                  <Typography.Text>
                    選択した商品 (
                    {
                      _filter(Object.values(selectedMenusMap), (o) => _isEmpty(o.collectionIds)
                      ).length
                    }
                    )
                  </Typography.Text>
                </div>
                <div className="menu-list">
                  <div>
                    {renderSelectedMenus(
                      restaurantMenuDataLoaded,
                      _filter(Object.values(selectedMenusMap), (o) => _isEmpty(o.collectionIds)
                      ),
                      handleSelectMenuPrice,
                      handleUnselectItem
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

UpdateDialog.propTypes = {
  restaurantDetail: PropTypes.object,
  dialogVisible: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleAssignMenuItem: PropTypes.func,
  resetCategoryList: PropTypes.func,
  resetMenuItemList: PropTypes.func,
  getRestaurantMenuItemRequest: PropTypes.func,
  assignedMenuItemList: PropTypes.array,
  resetAllAssignedMenuItemList: PropTypes.func,
};

export default UpdateDialog;
