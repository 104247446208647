import React, { useState } from 'react';
import { Card, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { submit, isDirty, getFormValues } from 'redux-form';
import PageHeader from 'components/PageHeader';
import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import { goBack, push } from 'connected-react-router';
import { addTagRequest } from 'providers/GenreProvider/actions';
import TagCreateForm from './TagForm';
import StatusToggle from '../List/StatusToggle';
import './style.scss';

const breadcrumbItems = [
  {
    path: '/tags',
    breadcrumbName: '商品のタグ',
  },
  {
    breadcrumbName: 'フリータグの追加',
  },
];

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '配信',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const TagCreate = () => {
  const [createActionLoading, setCreateActionLoading] = useState(false);
  const dispatch = useDispatch();

  const dirty = useSelector((state) => isDirty('tagForm')(state));
  const formValues = useSelector((state) => getFormValues('tagForm')(state));

  const handleSubmit = async (value) => {
    setCreateActionLoading(true);
    try {
      await dispatch(addTagRequest(value));
      dispatch(push('/tags'));
    } catch (err) {
      console.error(err);
    }
    setCreateActionLoading(false);
  };

  const handleOk = () => {
    dispatch(submit('tagForm'));
  };

  const handleCancel = () => {
    dispatch(goBack());
  };

  const handleGoBack = () => {
    if (dirty) {
      showConfirm('変更を破棄しますか？', handleCancel);
    } else {
      handleCancel();
    }
  };

  const title = (
    <div className="flex direction-column items-start tag-header">
      <div className="title">フリータグの追加</div>
      <StatusToggle record={formValues?.isVisible} rowRecord={{ name: formValues?.name }} isEditForm />
    </div>
  );

  return (
    <div className="create-announcement-page flex direction-column">
      <PageHeader
        title={title}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleGoBack}
      />
      <Card className="bg-transparent">
        <TagCreateForm
          onSubmit={handleSubmit}
          initialValues={{
            isVisible: false
          }}
        />
      </Card>
      <FormActionFooter
        handleCancel={handleCancel}
        actionLoading={createActionLoading}
        handleOk={handleOk}
        isShowLabel={false}
        cancelText="キャンセル"
      />
    </div>
  );
};

export default TagCreate;
