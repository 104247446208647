import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import React from 'react';
import { replace } from 'connected-react-router';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const NavigationButton = (objectId, dispatch, brandList) => {
  const calcNavigateId = () => {
    const brandIdList = _map(brandList.list, (item) => item.objectId) || [];
    const index = _findIndex(brandIdList, (item) => item === objectId);
    return {
      current: index,
      backTo: index > 0 && brandIdList[index - 1],
      nextTo: index < brandIdList.length - 1 && brandIdList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, brandId) => {
    evt.preventDefault();
    dispatch(replace(`/brands/detail/${brandId}/in-app`));
  };

  const { current, backTo, nextTo } = calcNavigateId();
  const brandListList = _get(brandList, 'list', []);
  if (brandListList.length < 1) {
    return [];
  }

  return [
    <span
      key="current"
      className="text-bold"
      style={{ fontSize: 14, marginLeft: 16 }}
    >
      {`${current + 1} / ${brandListList.length}`}
    </span>,
    <Button
      key="prev"
      type="primary"
      disabled={!backTo}
      onClick={(evt) => handleReplaceRoute(evt, backTo)}
      icon={<LeftOutlined />}
    />,
    <Button
      key="next"
      type="primary"
      disabled={!nextTo}
      onClick={(evt) => handleReplaceRoute(evt, nextTo)}
      icon={<RightOutlined />}
    />,
  ];
};

export default NavigationButton;
