import {
  GET_GENRE_LIST_REQUEST,
  GET_GENRE_LIST_SUCCESS,
  GET_GENRE_LIST_ERROR,
  ADD_TAG_REQUEST,
  ADD_TAG_SUCCESS,
  ADD_TAG_ERROR,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_ERROR,
  GET_TAG_DETAILS_REQUEST,
  GET_TAG_DETAILS_SUCCESS,
  GET_TAG_DETAILS_ERROR,
  GET_TAG_LIST_REQUEST,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_ERROR,
  CHANGE_TAG_STATUS_REQUEST,
  CHANGE_TAG_STATUS_SUCCESS,
  CHANGE_TAG_STATUS_ERROR
} from './constants';

export function getGenreListRequest(data) {
  return { type: GET_GENRE_LIST_REQUEST, data };
}

export function getGenreListSuccess(data) {
  return { type: GET_GENRE_LIST_SUCCESS, data };
}

export function getGenreListError(error) {
  return { type: GET_GENRE_LIST_ERROR, error };
}

export function addTagRequest(payload) {
  return { type: ADD_TAG_REQUEST, payload, meta: { thunk: true } };
}

export function addTagSuccess(payload, meta) {
  return { type: ADD_TAG_SUCCESS, payload, meta };
}

export function addTagError(payload, meta) {
  return {
    type: ADD_TAG_ERROR, payload, meta, error: true
  };
}

export function updateTagRequest(payload) {
  return { type: UPDATE_TAG_REQUEST, payload, meta: { thunk: true } };
}

export function updateTagSuccess(payload, meta) {
  return { type: UPDATE_TAG_SUCCESS, payload, meta };
}

export function updateTagError(payload, meta) {
  return {
    type: UPDATE_TAG_ERROR, payload, meta, error: true
  };
}

export function getTagDetailsRequest(payload) {
  return { type: GET_TAG_DETAILS_REQUEST, payload, meta: { thunk: true } };
}

export function getTagDetailsSuccess(payload, meta) {
  return { type: GET_TAG_DETAILS_SUCCESS, payload, meta };
}

export function getTagDetailsError(payload, meta) {
  return {
    type: GET_TAG_DETAILS_ERROR, payload, meta, error: true
  };
}

export function getTagListRequest(payload) {
  return { type: GET_TAG_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getTagListSuccess(payload, meta) {
  return { type: GET_TAG_LIST_SUCCESS, payload, meta };
}

export function getTagListError(payload, meta) {
  return {
    type: GET_TAG_LIST_ERROR, payload, meta, error: true
  };
}

export function changeTagStatusRequest(payload) {
  return { type: CHANGE_TAG_STATUS_REQUEST, payload, meta: { thunk: true } };
}

export function changeTagStatusSuccess(payload, meta) {
  return { type: CHANGE_TAG_STATUS_SUCCESS, payload, meta };
}

export function changeTagStatusError(payload, meta) {
  return {
    type: CHANGE_TAG_STATUS_ERROR, payload, meta, error: true
  };
}
