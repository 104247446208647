import moment from 'moment';
import { Card, Image } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import useTableControl from 'components/Hooks/useTableControl';

import Table from 'components/Table';
import { BANNER_TYPE, DATE_TIME_FORMAT, STATUS } from 'utils/constants';

import { getBannerListRequest } from 'providers/BannerProvider/actions';

const BannerList = () => {
  const dispatch = useDispatch();
  const [pageControl, , onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;
  const bannerList = useSelector(
    (state) => state.bannerProvider.bannerList,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getBannerListRequest({
          orderBy,
          order,
          page,
          limit,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [orderBy, order, page, limit, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'objectId',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'バナー画像',
      dataIndex: 'image',
      width: 150,
      render: (record) => (
        <Image
          src={record}
          alt={record}
          width={120}
          style={{
            borderRadius: '4px',
          }}
          preview={false}
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      render: (record) => <div>{BANNER_TYPE[record]}</div>,
    },
    {
      title: '作成日',
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
      sorter: true,
    },
    {
      title: '最終更新日',
      width: 150,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
    },
    {
      width: 100,
      fixed: 'right',
      dataIndex: 'status',
      align: 'left',
      title: 'ステータス',
      render: (record) => {
        const activated = record === STATUS.ACTIVE;
        return (
          <div
            className="status-container"
          >
            <div>
              <span
                className={`${activated ? 'dot' : 'dot dot--inactive'}`}
              />
              <span
                style={{
                  color: '#000000d9',
                  fontWeight: 'normal'
                }}
              >{activated ? '有効' : '無効'}
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="banner-list-page">
      <Card className="bg-transparent" bordered={false}>
        <Card className="highlight-card padding-card" bordered={false}>
          <Table
            bordered={false}
            columns={columns}
            data={bannerList?.list}
            total={bannerList?.total}
            loading={tableLoading}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
            scroll={{ x: 940 }}
            onChange={onTableChange}
          />
        </Card>
      </Card>
    </div>
  );
};

export default BannerList;
