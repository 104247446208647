const UtilValidate = {};

UtilValidate.validUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  const pattern = new RegExp('^(https?:\\/\\/)' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(url);
};

UtilValidate.validPostalCode = (postalCode) => {
  const pattern = /^\d{7}$/;
  return pattern.test(postalCode);
};

export default UtilValidate;
