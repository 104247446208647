import {
  GET_SHIPPING_COMPANY_LIST_REQUEST,
  GET_SHIPPING_COMPANY_LIST_SUCCESS,
  GET_SHIPPING_COMPANY_LIST_ERROR,
} from './constants';

export function getShippingCompanyListRequest(data) {
  return { type: GET_SHIPPING_COMPANY_LIST_REQUEST, data };
}

export function getShippingCompanyListSuccess(data) {
  return { type: GET_SHIPPING_COMPANY_LIST_SUCCESS, data };
}

export function getShippingCompanyListError(error) {
  return { type: GET_SHIPPING_COMPANY_LIST_ERROR, error };
}
