import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import {
  Switch, Route, useParams, useHistory
} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Tabs, Spin, Modal } from 'antd';
import PropTypes from 'prop-types';
import { isDirty } from 'redux-form';

import withRefresh from 'components/HOCs/withRefresh';

import indexRoutes from './routes/indexRoutes';
import RestaurantHeader from './RestaurantHeader';
import './style.less';

const { TabPane } = Tabs;
const { confirm } = Modal;

const showConfirm = (modalProps, actionFn) => {
  confirm({
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
    ...modalProps,
  });
};

const RestaurantDetailDialog = ({
  restaurantDetail,
  getRestaurantDetailRequest,
}) => {
  const { restaurantId } = useParams();
  const { goBack } = useHistory();

  const [restaurantDetailLoaded, setRestaurantDetailLoaded] = useState(false);
  const dirty = useSelector((state) => isDirty('restaurantForm')(state));

  const restaurantListData = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  useEffect(() => {
    const fetchData = async () => {
      setRestaurantDetailLoaded(false);
      await getRestaurantDetailRequest({
        formattedObjectId: restaurantId,
      });
      setRestaurantDetailLoaded(true);
    };
    fetchData();
  }, [restaurantId]);

  if (!restaurantDetailLoaded) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }
  const handleGoBack = () => {
    if (!dirty) {
      return goBack();
    }
    return showConfirm(
      { title: '中断してよろしいですか？', okText: 'はい' },
      goBack
    );
  };

  return (
    <div id="restaurant-detail-page" className="flex direction-column">
      <RestaurantHeader
        restaurantDetail={restaurantDetail}
        footer={(
          <>
            <TabPane tab="商品と請求書" key="in-app" />
            <TabPane tab="レストラン詳細" key="restaurant" />
            <TabPane
              tab={(
                <span>
                  ブランド
                  <span className="background-brand-total">
                    {_get(restaurantDetail, 'brandCount', 0)}
                  </span>
                </span>
              )}
              key="brand-list"
            />
          </>
        )}
        idList={_map(restaurantListData?.list, (item) => _get(item, 'formattedObjectId')
        )}
        onBack={handleGoBack}
      />
      <Switch>
        {indexRoutes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

RestaurantDetailDialog.propTypes = {
  restaurantDetail: PropTypes.object,
  getRestaurantDetailRequest: PropTypes.any,
};

export default withRefresh('restaurant/supplier_detail')(
  RestaurantDetailDialog
);
