import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Switch, Route, useParams } from 'react-router-dom';
import { Tabs, Spin, Card } from 'antd';

import { getSupplierDetailRequest } from 'providers/SupplierProvider/actions';

import SupplierHeader from './SupplierHeader';
import routes from './routes';
import './style.less';

const { TabPane } = Tabs;

const Detail = () => {
  const dispatch = useDispatch();

  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const [supplierDetailLoading, setSupplierDetailLoading] = useState(false);
  const { supplierId } = useParams();

  const fetchSupplierDetail = useCallback(async () => {
    setSupplierDetailLoading(true);
    try {
      await dispatch(getSupplierDetailRequest({ objectId: supplierId }));
    } catch (error) {
      console.log(error);
    }
    setSupplierDetailLoading(false);
  }, [dispatch, supplierId]);

  useEffect(() => {
    fetchSupplierDetail();
  }, [fetchSupplierDetail]);

  if (supplierDetailLoading || _isEmpty(supplierDetail)) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="supplier-detail-page">
      <SupplierHeader
        supplierDetail={supplierDetail}
        supplierId={supplierId}
        footer={(
          <>
            <TabPane tab="概要" key="in-app" />
            <TabPane tab="休業日" key="holiday" />
            <TabPane tab="送料" key="shippingFee" />
            <TabPane tab="レストラン" key="connected-restaurants" />
            <TabPane tab="ブランド" key="connected-brands" />
          </>
        )}
      />
      <Card className="bg-transparent">
        <Switch>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} component={route.component} />
          ))}
        </Switch>
      </Card>
    </div>
  );
};

export default Detail;
