import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Space, Typography, Card } from 'antd';

import { getBrandListRequest } from 'providers/BrandProvider/actions';

import Helpers from 'utils/helpers';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import DefaultBrandImage from 'images/defaultBrand.svg';

const BODetailBrandList = ({ match }) => {
  const [tableLoading, setTableLoading] = useState(false);

  const brandList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  const brandOwnerId = _get(match, 'params.brandOwnerId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getBrandListRequest({
            page,
            limit,
            orderBy,
            order,
            brandOwnerId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order]);

  const columns = [
    {
      title: 'ブランド名',
      width: 250,
      render: (record) => (
        <Space className="flex items-center">
          <img
            style={{ borderRadius: 4 }}
            alt="name"
            src={_get(record, 'image', DefaultBrandImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: '商品件数',
      dataIndex: 'menuCount',
      key: 'menuCount',
      align: 'right',
      width: 150,
      render: (record) => Helpers.numberWithCommas(record || 0),
    },
  ];

  return (
    <Card className="bg-transparent">
      <Card
        bordered={false}
        className="highlight-card padding-card"
      >
        <Table
          columns={columns}
          data={_get(brandList, 'list', [])}
          total={_get(brandList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 400 }}
        />
      </Card>
    </Card>
  );
};

BODetailBrandList.propTypes = {
  match: PropTypes.any,
};

export default BODetailBrandList;
