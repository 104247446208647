import { useState } from 'react';
import validator from 'validator';

const useSearch = (emails = []) => {
  const [invoiceEmails, setInvoiceEmails] = useState([]);

  const onSearchEmail = (value) => {
    if (value !== '' && validator.isEmail(value)) {
      if (emails.find((item) => item.email === value)) {
        setInvoiceEmails([
          {
            email: value,
            isAdded: true,
            objectId: value,
          },
        ]);
        return;
      }
      setInvoiceEmails([{ email: value, objectId: value }]);
    }
  };

  return [
    {
      invoiceEmails,
    },
    {
      onSearchEmail,
    },
  ];
};

export default useSearch;
