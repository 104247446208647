import {
  GET_GUEST_INFORMATION_LIST_REQUEST,
  GET_GUEST_INFORMATION_LIST_SUCCESS,
  GET_GUEST_INFORMATION_LIST_ERROR,
  UPDATE_GUEST_INFORMATION_REQUEST,
  UPDATE_GUEST_INFORMATION_SUCCESS,
  UPDATE_GUEST_INFORMATION_ERROR,
  EXPORT_GUEST_LIST_REQUEST,
  EXPORT_GUEST_LIST_SUCCESS,
  EXPORT_GUEST_LIST_ERROR,
} from './constants';

export function getGuestInformationListRequest(payload) {
  return {
    type: GET_GUEST_INFORMATION_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getGuestInformationListSuccess(payload, meta) {
  return { type: GET_GUEST_INFORMATION_LIST_SUCCESS, payload, meta };
}

export function getGuestInformationListError(payload, meta) {
  return {
    type: GET_GUEST_INFORMATION_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateGuestInformationRequest(payload) {
  return {
    type: UPDATE_GUEST_INFORMATION_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateGuestInformationSuccess(payload, meta) {
  return { type: UPDATE_GUEST_INFORMATION_SUCCESS, payload, meta };
}

export function updateGuestInformationError(payload, meta) {
  return {
    type: UPDATE_GUEST_INFORMATION_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function exportGuestListRequest(payload) {
  return { type: EXPORT_GUEST_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function exportGuestListSuccess(payload, meta) {
  return { type: EXPORT_GUEST_LIST_SUCCESS, payload, meta };
}

export function exportGuestListError(payload, meta) {
  return {
    type: EXPORT_GUEST_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
