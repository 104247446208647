import Constants from 'utils/constants';
import Regex from 'utils/regex';
import UtilValidate from 'utils/validate';

const validate = (values) => {
  const errors = {};

  if (!values.billingName?.trim()) {
    errors.billingName = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.billingPostalCode?.trim()) {
    errors.billingPostalCode = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!UtilValidate.validPostalCode(values.billingPostalCode?.trim())) {
    errors.billingPostalCode = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.billingPrefecture?.trim()) {
    errors.billingPrefecture = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.billingAddress?.trim()) {
    errors.billingAddress = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.billingPhone?.trim()) {
    errors.billingPhone = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regex.PHONE.test(values.billingPhone?.trim())) {
    errors.billingPhone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (!values.billingManagerName?.trim()) {
    errors.billingManagerName = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    values.bankAccountNameKana
    && !Regex.REGX_KATAKANA.test(values.bankAccountNameKana?.trim())
  ) {
    errors.bankAccountNameKana = Constants.ERROR_MESSAGE.INVALID;
  }

  return errors;
};

export default validate;
