import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import React from 'react';
import { replace } from 'connected-react-router';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const NavigationButton = (objectId, dispatch, productList) => {
  const calcNavigateId = () => {
    const productIdList = _map(productList.list, (item) => item.formattedObjectId) || [];
    const index = _findIndex(productIdList, (item) => item === objectId);
    return {
      current: index,
      backTo: index > 0 && productIdList[index - 1],
      nextTo: index < productIdList.length - 1 && productIdList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, productId) => {
    evt.preventDefault();
    dispatch(replace(`/products/detail/${productId}/in-app`));
  };

  const { current, backTo, nextTo } = calcNavigateId();
  const products = _get(productList, 'list', []);
  if (products.length < 1) {
    return [];
  }

  return [
    <span
      key="current"
      className="text-bold"
      style={{ fontSize: 14, marginLeft: 16 }}
    >
      {`${current + 1} / ${products.length}`}
    </span>,
    <Button
      key="prev"
      type="primary"
      disabled={!backTo}
      onClick={(evt) => handleReplaceRoute(evt, backTo)}
      icon={<LeftOutlined />}
    />,
    <Button
      key="next"
      type="primary"
      disabled={!nextTo}
      onClick={(evt) => handleReplaceRoute(evt, nextTo)}
      icon={<RightOutlined />}
    />,
  ];
};

export default NavigationButton;
