import { ERROR_MESSAGE } from 'utils/constants';
import _isUndefined from 'lodash/isUndefined';

const validate = (values, props) => {
  const errors = {};

  if (values.updateItems && values.updateItems.length) {
    const totalReturnedMenuPrice = values.updateItems.reduce(
      (acc, item) => (item.type === 'RETURNED_MENU' ? acc + item.value : acc),
      0
    );
    const updateItemsArrayErrors = [];
    values.updateItems.forEach((updateItem, index) => {
      const updateItemError = {};
      if (
        !updateItem
        || !updateItem.name
        || (updateItem && !updateItem.name?.trim())
      ) {
        updateItemError.name = ERROR_MESSAGE.REQUIRED;
      }
      if (
        !_isUndefined(updateItem.value)
        && updateItem.type === 'RETURNED_MENU'
        && totalReturnedMenuPrice + props.totalItemsPriceAfterTax < 1
      ) {
        updateItemError.value = ERROR_MESSAGE.INVALID;
      }
      updateItemsArrayErrors[index] = updateItemError;
    });
    if (updateItemsArrayErrors.length) {
      errors.updateItems = updateItemsArrayErrors;
    }
  }
  return errors;
};

export default validate;
