import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  Tabs, Card, Spin, Avatar, Typography, Space
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import PageHeader from 'components/PageHeader';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';

import { getRestaurantOwnerDetailRequest } from 'providers/RestaurantOwnerProvider/actions';

import indexRoutes from './routes';
import './style.less';

const { TabPane } = Tabs;
const { Text } = Typography;

const RestaurantOwnerDetail = () => {
  const dispatch = useDispatch();
  const { restaurantOwnerId } = useParams();
  const { pathname } = useLocation();
  const { replace, goBack } = useHistory();
  const [restaurantOwnerDetailLoaded, setRestaurantOwnerDetailLoaded] = useState(false);
  const restaurantOwnerDetail = useSelector(
    (state) => state.restaurantOwnerProvider.restaurantOwnerDetail
  );

  const fetchRestaurantDetail = useCallback(async () => {
    await dispatch(
      getRestaurantOwnerDetailRequest({
        objectId: restaurantOwnerId,
      })
    );
    setRestaurantOwnerDetailLoaded(true);
  }, [restaurantOwnerId, dispatch]);

  useEffect(() => {
    fetchRestaurantDetail();
  }, [fetchRestaurantDetail]);

  const onTabClick = (key) => {
    const pushPath = `/restaurant-owner/detail/${restaurantOwnerId}/${key}`;
    replace(pushPath);
  };

  const selectedKeys = pathname.split('/')[4];

  const breadcrumbItems = [
    {
      path: '/restaurant-owner',
      breadcrumbName: 'レストランオーナー',
    },
    {
      breadcrumbName: _get(restaurantOwnerDetail, 'name', '-'),
    },
  ];

  const renderRestaurantInfoHeader = () => (
    <Space>
      <Avatar
        size={40}
        src={_get(restaurantOwnerDetail, 'image', DefaultRestaurantImage)}
      />
      <div>
        <div className="restaurant-name">
          {_get(restaurantOwnerDetail, 'name', '')}
        </div>
        <Text
          className="email"
          type="secondary"
          copyable={
            restaurantOwnerDetailLoaded && {
              tooltips: ['コピー', 'コピーしました'],
            }
          }
        >
          {_get(restaurantOwnerDetail, 'email', '')}
        </Text>
      </div>
    </Space>
  );

  if (!restaurantOwnerDetailLoaded) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="restaurant-owner-detail-page">
      <PageHeader
        onBack={goBack}
        title={renderRestaurantInfoHeader()}
        breadcrumbRoutes={breadcrumbItems}
        footer={(
          <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
            <TabPane tab="詳細" key="in-app" />
            <TabPane tab="レストラン" key="restaurant-list" />
          </Tabs>
        )}
      />
      <Card className="bg-transparent" bordered={false}>
        <Switch>
          {indexRoutes.map((route, key) => (
            <Route key={key} path={route.path} component={route.component} />
          ))}
        </Switch>
      </Card>
    </div>
  );
};

export default RestaurantOwnerDetail;
