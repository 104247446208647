import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Space, Input, Typography, Tooltip, Avatar
} from 'antd';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';

import { DATE_TIME_FORMAT, USER_ROLE } from 'utils/constants';
import { getSupplierListRequest } from 'providers/SupplierProvider/actions';

import DefaultSupplierImage from 'images/defaultSupplier.svg';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';

import StatusToggle from './StatusToggle';

const { Search } = Input;

const SupplierList = () => {
  const supplierList = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getSupplierListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = {
    [USER_ROLE.OPERATOR]: [
      {
        title: 'ID',
        width: 110,
        dataIndex: 'objectId',
        key: 'objectId',
      },
      {
        title: '販売者名',
        width: 250,
        render: (record) => (
          <Space className="flex items-center">
            <img
              style={{ borderRadius: 4 }}
              alt="name"
              src={_get(record, 'image', DefaultSupplierImage)}
              width={32}
              height={32}
            />
            <Space direction="vertical" size={0}>
              <Typography.Text>{_get(record, 'name')}</Typography.Text>
              <Space size={2}>
                {_get(record, 'emailVerified') ? (
                  <Tooltip placement="topLeft" title="Email is verified">
                    <img alt="verified-icon" src={VerifiedImage} />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="topLeft"
                    title="Email has not been verified"
                  >
                    <img alt="warning-icon" src={WarningImage} />
                  </Tooltip>
                )}
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {_get(record, 'email')}
                </Typography.Text>
              </Space>
            </Space>
          </Space>
        ),
      },
      {
        title: '登録日',
        width: 150,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (record) => (
          <div>{moment(record).format(DATE_TIME_FORMAT)}</div>
        ),
      },
      {
        title: 'レストラン件数',
        width: 130,
        dataIndex: 'restaurantCount',
        key: 'restaurantCount',
        align: 'right',
      },
      {
        title: 'ブランド件数',
        width: 120,
        dataIndex: 'brandCount',
        key: 'brandCount',
        align: 'right',
      },
      {
        title: 'ステータス',
        width: 120,
        dataIndex: 'status',
        key: 'status',
        fixed: 'right',
        render: (record, rowRecord) => (
          <StatusToggle
            record={record}
            rowRecord={{ ...rowRecord, callBack: fetchData }}
          />
        ),
      },
    ],
    [USER_ROLE.BRAND_OWNER]: [
      {
        title: '販売者名',
        width: 250,
        render: (record) => (
          <Space className="flex items-center">
            <Avatar
              alt="supplier"
              src={_get(record, 'image', DefaultSupplierImage)}
              size={32}
            />
            <Space direction="vertical" size={0}>
              <Typography.Text>{_get(record, 'name')}</Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {_get(record, 'email')}
              </Typography.Text>
            </Space>
          </Space>
        ),
      },
    ],
  };

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <div className="padding-16 border-bottom">
          <Search
            placeholder="メール/販売者名で検索"
            defaultValue={keyword}
            onSearch={(val) => onFilterChange({ keyword: val })}
            style={{ width: 220 }}
            className="search-input"
          />
        </div>
        <Table
          columns={columns[currentUser.role]}
          data={_get(supplierList, 'list', [])}
          total={_get(supplierList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 870 }}
          onRowClick={(record) => {
            dispatch(push(`/suppliers/detail/${record.objectId}`));
          }}
        />
      </Card>
    </Card>
  );
};

export default SupplierList;
