import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Card, Space, Typography } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  updateRestaurantRequest,
  addRestaurantNoteRequest,
} from 'providers/RestaurantProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import toastMessage from 'utils/toastMessage';
import { SelectField, TextField } from 'components/Form';

const options = [
  {
    objectId: '25_OF_MONTH',
    name: '月末 翌25日払い',
  },
  {
    objectId: 'LAST_DAY_OF_MONTH',
    name: '月末 翌月末払い',
  },
  {
    objectId: 'LAST_DAY_OF_FOLLOWING_MONTH',
    name: '月末 翌々月末払い',
  },
];

let RestaurantForm = ({ handleSubmit, currentUser }) => (
  <Form onSubmit={handleSubmit}>
    <Space direction="vertical" size={16} className="full-w flex">
      <Card className="highlight-card" bordered={false} title="メモ">
        <Field
          component={TextField}
          label="メモ"
          textArea
          name="noteRestaurant"
          maxLength={500}
        />
      </Card>
      {_get(currentUser, 'supplier.type') === 'SHIKOMEL' && (
        <Card
          className="highlight-card"
          bordered={false}
          title="請求書 支払い期限の設定"
        >
          <Space direction="vertical" size={16} className="full-w">
            <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              毎月の請求書の支払期限を設定します。
              <br />
              この日付は請求書に表示されます。
              <br />
              設定を保存した後の注文から適用されます。
            </Typography.Text>
            <Typography.Text
              style={{
                color: '#000000d9',
                marginBottom: 4,
                display: 'block',
              }}
            >
              請求書支払期限
            </Typography.Text>
            <Field
              name="shikPaymentDeadline"
              component={SelectField}
              options={options}
              allowClear={false}
            />
          </Space>
        </Card>
      )}
    </Space>
  </Form>
);

RestaurantForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

RestaurantForm = reduxForm({
  form: 'restaurantForm',
})(RestaurantForm);

const RestaurantFormCard = ({
  currentUser,
  restaurantDetail,
  setActionLoading,
}) => {
  const restaurantId = restaurantDetail?.objectId;

  const dispatch = useDispatch();

  const handleChange = async ({ shikPaymentDeadline, noteRestaurant }) => {
    setActionLoading(true);
    try {
      await dispatch(
        addRestaurantNoteRequest({ noteRestaurant, restaurantId })
      );
      if (
        shikPaymentDeadline
        && _get(currentUser, 'supplier.type') === 'SHIKOMEL'
      ) {
        await dispatch(
          updateRestaurantRequest({
            restaurantId,
            shikPaymentDeadline,
          })
        );
      }
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      dispatch(doRefresh({ target: 'restaurant/supplier_detail' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  return (
    <RestaurantForm
      onSubmit={handleChange}
      initialValues={{
        shikPaymentDeadline: _get(restaurantDetail, 'shikPaymentDeadline'),
        noteRestaurant: _get(
          restaurantDetail,
          'supplierRestaurant.noteRestaurant',
          ''
        ),
      }}
      currentUser={currentUser}
    />
  );
};

RestaurantFormCard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  restaurantDetail: PropTypes.object.isRequired,
  setActionLoading: PropTypes.func,
};

export default RestaurantFormCard;
