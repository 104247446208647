import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { submit } from 'redux-form';
import {
  Modal, Affix, Space, Button
} from 'antd';

import { createManufacturerRequest } from 'providers/ManufacturerProvider/actions';

import PageHeader from 'components/PageHeader';
import ManufacturerCreateForm from './ManufacturerCreateForm';

const { confirm } = Modal;

const breadcrumbItems = [
  {
    path: '/manufacturers',
    breadcrumbName: '製造会社',
  },
  {
    breadcrumbName: '製造会社を追加',
  },
];

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const ManufacturerCreate = () => {
  const dispatch = useDispatch();
  const [createActionLoading, setCreateActionLoading] = useState(false);

  const handleSubmit = async (value) => {
    setCreateActionLoading(true);
    try {
      await dispatch(createManufacturerRequest(value));
      dispatch(push('/manufacturers'));
    } catch (err) {
      console.error(err);
    }
    setCreateActionLoading(false);
  };

  const handleCancel = () => showConfirm('中断してよろしいですか？', () => dispatch(push('/manufacturers')));

  return (
    <>
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleCancel}
      />
      <ManufacturerCreateForm onSubmit={handleSubmit} />
      <Affix offsetBottom={0}>
        <Space
          size="middle"
          className="full-w flex justify-end"
          style={{
            padding: '16px',
            backgroundColor: '#293137',
          }}
        >
          <Button onClick={handleCancel} disabled={createActionLoading}>
            キャンセル
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => dispatch(submit('manufacturerCreateForm'))}
            loading={createActionLoading}
            style={{ marginRight: '10px' }}
          >
            送信
          </Button>
        </Space>
      </Affix>
    </>
  );
};

export default ManufacturerCreate;
