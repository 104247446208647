import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getBannerListError,
  getBannerListSuccess,
} from './actions';
import {
  GET_BANNER_LIST_REQUEST,
} from './constants';

export function* handleGetBannerList(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBannerList`,
      payload
    );
    yield put(getBannerListSuccess(result, meta));
  } catch (error) {
    yield put(getBannerListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(GET_BANNER_LIST_REQUEST, handleGetBannerList);
}
