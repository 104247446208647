import _get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Button
} from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import {
  getBrandDetailRequest,
  removeBrandOwnerFromBrandRequest,
} from 'providers/BrandProvider/actions';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import AddBrandOwner from '../../AddBrandOwner';
import Columns from './Columns';

const BrandOwnerList = ({ objectId, showConfirm }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [showBrandOwnerModal, setShowBrandOwnerModal] = useState(false);

  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );

  const brandName = _get(brandDetail, 'name');

  const dispatch = useDispatch();

  const [pageControl, , onTableChange] = useTableControl();

  const {
    page, limit
  } = pageControl;

  const removeBrandOwnerFromBrand = async (value) => {
    setTableLoading(true);
    try {
      await dispatch(
        removeBrandOwnerFromBrandRequest({
          brandOwnerIds: [_get(value, 'objectId')],
          brandId: objectId,
        })
      );
      dispatch(getBrandDetailRequest({ objectId }));
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  const columnsAssignBrandOwner = [
    Columns.brandOwnerName,
    {
      ...Columns.action,
      render: (record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showConfirm(
              `${brandName}から${record.name}を削除しますか？`,
              () => removeBrandOwnerFromBrand(record)
            );
          }}
          type="text"
          danger
          icon={<CloseOutlined />}
        >
          削除
        </Button>
      ),
    },
  ];

  return (
    <>
      <AddBrandOwner
        visible={showBrandOwnerModal}
        brandId={objectId}
        handleCloseDialog={() => {
          setShowBrandOwnerModal(false);
        }}
      />
      <Card
        bordered={false}
        className="highlight-card padding-card"
        title="ブランドオーナー"
        headStyle={{ fontWeight: 'bold', fontSize: '20px', padding: '0 16px' }}
        extra={(
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setShowBrandOwnerModal(true);
            }}
          >
            ブランドオーナー追加
          </Button>
        )}
      >
        <Table
          columns={columnsAssignBrandOwner}
          data={_get(brandDetail, 'brandOwners', [])}
          total={_get(brandDetail, 'brandOwners.length', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 370 }}
        />
      </Card>
    </>
  );
};

BrandOwnerList.propTypes = {
  objectId: PropTypes.string,
  showConfirm: PropTypes.func
};
export default BrandOwnerList;
