import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';

import validate from 'containers/RegisterPage/SupplierRegister/validate';
import InvoiceForm from 'containers/RegisterPage/SupplierRegister/InvoiceForm';
import '@ant-design/compatible/assets/index.css';

const InvoiceUpdateForm = ({ handleSubmit }) => (
  <Form
    onSubmit={handleSubmit}
    labelCol={{ span: 7 }}
    layout="horizontal"
    colon={false}
  >
    <InvoiceForm isUpdateInvoice />
  </Form>
);

InvoiceUpdateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'invoiceUpdateForm',
  touchOnChange: true,
  validate,
})(InvoiceUpdateForm);
