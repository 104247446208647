import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Card, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ImageField, TextField } from 'components/Form';

import validate from './validate';

const RegisterBrandOwnerForm = ({ handleSubmit, children }) => (
  <Form
    labelCol={{ span: 6 }}
    colon={false}
    layout="horizontal"
    onSubmit={handleSubmit}
  >
    <Space size={16} direction="vertical" className="full-w">
      <Card
        headStyle={{ fontSize: 20, fontWeight: 'bold' }}
        className="highlight-card"
        title="企業情報"
        bordered={false}
      >
        <Field
          label="企業ロゴ"
          description="任意"
          name="image"
          component={ImageField}
          maxCount={1}
          shape="round"
        />
        <Field
          label="ブランドオーナー名"
          name="name"
          component={TextField}
          maxLength={50}
          required
        />
        <Field
          label="ブランドオーナー名"
          description="カナ"
          name="nameKana"
          component={TextField}
          maxLength={50}
          required
        />
        <Field
          label="電話番号"
          description="任意"
          name="phone"
          component={TextField}
          maxLength={11}
          requiredSpace
        />
      </Card>
      {children}
    </Space>
  </Form>
);

RegisterBrandOwnerForm.propTypes = {
  children: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'registerProfileForm',
  touchOnChange: true,
  validate,
})(RegisterBrandOwnerForm);
