import produce from 'immer';

import {
  GET_IN_APP_MESSAGE_LIST_REQUEST,
  GET_IN_APP_MESSAGE_LIST_SUCCESS,
  GET_IN_APP_MESSAGE_LIST_ERROR,
  GET_IN_APP_MESSAGE_DETAIL_REQUEST,
  GET_IN_APP_MESSAGE_DETAIL_SUCCESS,
  GET_IN_APP_MESSAGE_DETAIL_ERROR,
} from './constants';

const initialState = {
  inAppMessageList: {},
  inAppMessageDetail: {},
  isLoading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_IN_APP_MESSAGE_LIST_REQUEST:
      state.isLoading = true;
      break;
    case GET_IN_APP_MESSAGE_LIST_SUCCESS:
      state.isLoading = false;
      state.inAppMessageList = action.payload;
      break;
    case GET_IN_APP_MESSAGE_LIST_ERROR:
      state.isLoading = false;
      break;
    case GET_IN_APP_MESSAGE_DETAIL_REQUEST:
      state.isLoading = true;
      break;
    case GET_IN_APP_MESSAGE_DETAIL_SUCCESS:
      state.isLoading = false;
      state.inAppMessageDetail = action.payload;
      break;
    case GET_IN_APP_MESSAGE_DETAIL_ERROR:
      state.isLoading = false;
      break;

    default:
  }
  return state;
});

export default reducer;
