import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button, Space, Row, Col, Divider
} from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import TrashIcon from 'images/icon-trash.svg';
import IconOut from 'images/icon-out.svg';

import { TextField, CheckboxGroup } from 'components/Form';
import validate from './validate';
import './styles.scss';

const renderTrackingIdFields = ({
  formTrackingListValue,
  disabled,
  fields,
}) => {
  useEffect(() => {
    if (!fields.length) {
      fields.push();
    }
  }, []);

  const [id, setId] = useState('');

  return (
    <div id="tracking-id-fields">
      {fields.map((tracking, index) => {
        const formTrackingValue = formTrackingListValue && formTrackingListValue[index];
        const disabledField = _get(formTrackingValue, 'disabled');
        const disabledAddButton = disabled || (fields.length > 1 && index < fields.length - 1);
        const disabledRemoveButton = disabledField || disabled || fields.length === 1;
        return (
          <Row
            key={index}
            gutter={8}
            style={{
              background: id === tracking && '#0000000d',
            }}
            className="tracking-field"
            wrap={false}
          >
            <Col flex="auto">
              <Field
                name={`${tracking}.trackingId`}
                component={TextField}
                disabled={disabled || disabledField}
                placeholder="お問い合わせ番号"
              />
            </Col>
            <Col flex="24px">
              <Button
                type="link"
                shape="circle"
                icon={<PlusCircleOutlined />}
                onClick={() => fields.push()}
                disabled={disabledAddButton}
                style={{
                  color: '#1890ff',
                  opacity: disabledAddButton ? 0.2 : 1,
                }}
              />
            </Col>
            <Col flex="24px">
              <Button
                type="link"
                shape="circle"
                className="remove-btn"
                icon={(
                  <MinusCircleOutlined
                    style={{
                      color: '#cf1421',
                      opacity: disabledRemoveButton ? 0.4 : 1,
                    }}
                  />
                )}
                onMouseEnter={() => {
                  setId(tracking);
                }}
                onMouseLeave={() => {
                  setId('');
                }}
                onClick={() => fields.remove(index)}
                disabled={disabledRemoveButton}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

renderTrackingIdFields.propTypes = {
  fields: PropTypes.any,
  formTrackingListValue: PropTypes.any,
  disabled: PropTypes.bool,
};

const renderCompanyFields = ({
  formCompanyListValue,
  disabled,
  fields,
  isModalForm,
}) => {
  const [id, setId] = useState('');

  return (
    <>
      {fields.map((company, index) => {
        const formCompanyValue = formCompanyListValue && formCompanyListValue[index];
        return (
          <div key={index} id="company-fields">
            <Row
              gutter={8}
              style={{
                background: id === company && '#0000000d',
              }}
              className="tracking-field"
              wrap={false}
            >
              {!_get(formCompanyValue, 'disabled') ? (
                <>
                  <Col flex="0 1 174px" style={{ padding: 4 }}>
                    <Field
                      name={`${company}.nameKana`}
                      component={TextField}
                      maxLength={50}
                      placeholder="Company name"
                      disabled={disabled}
                    />
                  </Col>
                </>
              ) : (
                <Col flex="0 1 174px">
                  {_get(formCompanyValue, 'website') ? (
                    <a
                      href={_get(formCompanyValue, 'website')}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span
                        style={{ color: '#000000a6', wordBreak: 'break-all' }}
                      >
                        {_get(formCompanyValue, 'nameKana', '--')}
                      </span>
                      <img
                        style={{ marginLeft: 4 }}
                        alt="icon-out"
                        src={IconOut}
                        height={16}
                      />
                    </a>
                  ) : (
                    <span
                      style={{ color: '#000000a6', wordBreak: 'break-all' }}
                    >
                      {_get(formCompanyValue, 'nameKana', '--')}
                    </span>
                  )}
                </Col>
              )}
              <Col flex="0 1 276px">
                <FieldArray
                  name={`${company}.trackingIds`}
                  component={renderTrackingIdFields}
                  formTrackingListValue={_get(formCompanyValue, 'trackingIds')}
                  disabled={disabled}
                  isModalForm={isModalForm}
                />
              </Col>
              {!_get(formCompanyValue, 'disabled') ? (
                <Col flex={isModalForm ? '26px' : 'auto'} className="flex justify-end">
                  <Button
                    type="link"
                    shape="circle"
                    icon={<img src={TrashIcon} alt="" />}
                    onClick={() => fields.remove(index)}
                    disabled={disabled}
                    onMouseEnter={() => {
                      setId(company);
                    }}
                    onMouseLeave={() => {
                      setId('');
                    }}
                  />
                </Col>
              ) : <Col flex="26px" />}
            </Row>
            <Divider style={{ margin: '12px 0' }} />
          </div>
        );
      })}

      <Button
        icon={<PlusCircleFilled style={{ color: '#1890ff', fontSize: 16 }} />}
        type="text"
        className="flex items-center"
        style={{ color: '#1890ff', opacity: disabled ? 0.4 : 1 }}
        onClick={() => fields.push()}
        disabled={disabled}
      >
        運送会社を追加
      </Button>
    </>
  );
};

renderCompanyFields.propTypes = {
  fields: PropTypes.any,
  formCompanyListValue: PropTypes.any,
  disabled: PropTypes.bool,
};

const TrackingIdForm = ({
  formCompanyListValue,
  formDeliveryServiceValue,
  showDeliveryServiceSection,
  deliveryServiceTypes,
  deliveryServiceOptions,
  handleSubmit,
  isModalForm,
  onCancel,
}) => (
  <Form
    onSubmit={handleSubmit}
    layout="horizontal"
    colon={false}
    className="tracking-id-Form"
  >
    {showDeliveryServiceSection && (
      <Field
        name="deliveryService"
        component={CheckboxGroup}
        options={deliveryServiceOptions}
        colSpan={24}
      />
    )}
    <div
      style={{
        background: '#0000000d',
        padding: '12px 16px',
        borderRadius: 4,
        marginLeft: 24,
      }}
    >
      <FieldArray
        name="companyList"
        component={renderCompanyFields}
        isModalForm={isModalForm}
        formCompanyListValue={formCompanyListValue}
        disabled={
          !formDeliveryServiceValue?.includes(
            deliveryServiceTypes.OTHER_DELIVERY
          )
        }
      />
    </div>
    {isModalForm && (
      <div id="tracking-id-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" htmlType="submit">
            追加
          </Button>
        </Space>
      </div>
    )}
  </Form>
);

TrackingIdForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formCompanyListValue: PropTypes.any,
  formDeliveryServiceValue: PropTypes.any,
  deliveryServiceTypes: PropTypes.any,
  deliveryServiceOptions: PropTypes.any,
  showDeliveryServiceSection: PropTypes.bool,
  isModalForm: PropTypes.bool,
};

export default reduxForm({
  form: 'trackingIdForm',
  enableReinitialize: true,
  validate,
})(TrackingIdForm);
