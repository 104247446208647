import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ja';

import {
  Card, List, Tooltip, Spin, Typography, Avatar
} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import { DATE_TIME_FORMAT } from 'utils/constants';

import './style.scss';

const CONVERSATIONS_PER_PAGE = 20;

const Conversation = ({
  push,
  doRefresh,
  closePopup,
  popupVisible,
  conversationList,
  conversationListLoading,
  conversationListHasMore,
  getConversationListRequest,
  countUnreadConversationRequest,
}) => {
  useEffect(() => {
    if (popupVisible) {
      countUnreadConversationRequest();
      getConversationListRequest({
        skip: 0,
        limit: CONVERSATIONS_PER_PAGE,
      });
    }
  }, [popupVisible]);

  const handleLoadMore = () => {
    getConversationListRequest({
      skip: conversationList.length,
      limit: CONVERSATIONS_PER_PAGE,
    });
  };

  const handleMessageClick = (evt, orderId) => {
    evt.preventDefault();
    doRefresh({ target: 'orderDetail' });
    push(`/orders/detail/${orderId}#bottom-scr`);
    closePopup();
  };

  const timeAgoJp = (time) => moment(time).locale('ja').fromNow();
  return (
    <Card
      className="conversation-container"
      title="メッセージ"
      headStyle={{ fontSize: 20, fontWeight: 500 }}
    >
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={
          !conversationListLoading && conversationListHasMore && handleLoadMore
        }
        hasMore={conversationListHasMore}
        useWindow={false}
      >
        <List
          dataSource={conversationList}
          renderItem={(item) => (
            <List.Item
              onClick={(evt) => handleMessageClick(evt, item.orderId)}
              key={item.orderId}
              className={`conversation-item ${!item.isRead && 'unread'}`}
            >
              <List.Item.Meta
                avatar={(
                  <Avatar
                    src={
                      item.imageUrl || (
                        <img
                          alt="restaurant-default"
                          src={DefaultRestaurantImage}
                        />
                      )
                    }
                    size={40}
                  />
                )}
                title={(
                  <div className="flex justify-between">
                    <div className="conversation-title">#{item.orderId}</div>
                    <Tooltip
                      title={moment(item.createdAt).format(DATE_TIME_FORMAT)}
                      placement="rightTop"
                    >
                      <span className="conversation-time">
                        {timeAgoJp(item.createdAt)}
                      </span>
                    </Tooltip>
                  </div>
                )}
                description={(
                  <Typography.Paragraph
                    ellipsis={{ rows: 2 }}
                    className="conversation-message"
                  >
                    {item.content}
                  </Typography.Paragraph>
                )}
              />
            </List.Item>
          )}
        >
          {conversationListLoading && conversationListHasMore && (
            <div className="conservation-loading">
              <Spin />
            </div>
          )}
        </List>
      </InfiniteScroll>
    </Card>
  );
};

Conversation.propTypes = {
  getConversationListRequest: PropTypes.func,
  countUnreadConversationRequest: PropTypes.func,
  conversationList: PropTypes.array,
  conversationListHasMore: PropTypes.bool,
  conversationListLoading: PropTypes.bool,
  push: PropTypes.func,
  doRefresh: PropTypes.func,
  closePopup: PropTypes.func,
  popupVisible: PropTypes.bool,
};

export default Conversation;
