import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { getConversationListRequest, countUnreadConversationRequest } from 'providers/ConversationProvider/actions';
import {
  makeSelectConversationList,
  makeSelectConversationListLoading,
  makeSelectConversationListHasMore,
} from 'providers/ConversationProvider/selectors';
import { doRefresh } from 'providers/CommonProvider/actions';
import Conversation from './Conversation';

const mapDispatchToProps = (dispatch) => ({
  countUnreadConversationRequest: () => dispatch(countUnreadConversationRequest()),
  getConversationListRequest: (data) => dispatch(getConversationListRequest(data)),
  push: (path) => dispatch(push(path)),
  doRefresh: (data) => dispatch(doRefresh(data))
});

const mapStateToProps = createStructuredSelector({
  conversationList: makeSelectConversationList(),
  conversationListHasMore: makeSelectConversationListHasMore(),
  conversationListLoading: makeSelectConversationListLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Conversation);
