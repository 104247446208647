import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Tabs } from 'antd';

import withAuthorize from 'components/HOCs/withAuthorize';
import { USER_ROLE, MENU_LIST_FILTER } from 'utils/constants';

import './style.less';

const { TabPane } = Tabs;

const MenuType = ({
  currentUser,
  onFilterChange,
  menuType,
  menuCountPerType,
}) => {
  const MENU_TYPE = [
    {
      value: 'ALL',
      label: '全て',
    },
    {
      value: MENU_LIST_FILTER.WITH_BRAND,
      label: 'ブランド商品',
    },
  ];

  const isOperator = _get(currentUser, 'role') === USER_ROLE.OPERATOR;
  const isShikomelSupplier = _get(currentUser, 'role') === USER_ROLE.SUPPLIER
    && _get(currentUser, 'supplier.type') === 'SHIKOMEL';

  if (isOperator || isShikomelSupplier) {
    MENU_TYPE.splice(
      1,
      0,
      ...[
        {
          value: MENU_LIST_FILTER.NOT_BASIC,
          label: 'マイストア商品',
        },
        {
          value: MENU_LIST_FILTER.BASIC,
          label: 'シコメルストア商品',
        },
      ]
    );
    if (isShikomelSupplier || isOperator) {
      MENU_TYPE.push(
        {
          value: MENU_LIST_FILTER.BASIC_PLAN_PRODUCT,
          label: 'ベーシック商品',
        },
        {
          value: MENU_LIST_FILTER.PREMIUM_PLAN_PRODUCT,
          label: 'プレミアム商品',
        }
      );
    }
  }

  return (
    <Tabs
      defaultActiveKey={menuType}
      onChange={(evtMenuType) => {
        onFilterChange({ menuType: evtMenuType });
      }}
      className="menu-type-filter"
    >
      {MENU_TYPE.map((option) => (
        <TabPane
          tab={(
            <div
              className={
                option.value === MENU_LIST_FILTER.BASIC_PLAN_PRODUCT
                  ? 'basic-plan-product' : ''
              }
            >
              <div id="count-filter-status">
                {menuCountPerType && (menuCountPerType[option.value] || 0)}
              </div>
              <div>{option.label}</div>
            </div>
          )}
          key={option.value}
        />
      ))}
    </Tabs>
  );
};

MenuType.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  menuCountPerType: PropTypes.object.isRequired,
  menuType: PropTypes.string,
};

export default withAuthorize([USER_ROLE.SUPPLIER, USER_ROLE.OPERATOR])(
  MenuType
);
