import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Typography } from 'antd';
import '@ant-design/compatible/assets/index.css';

import Step1Form from './Step1Form';

const RegisterFormStep1 = ({ onSubmit, onPrevious }) => (
  <Space size={40} direction="vertical" className="full-w">
    <div style={{ textAlign: 'center' }}>
      <Typography.Text>ステップ 1/2</Typography.Text>
      <br />
      <Typography.Text
        strong
        style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.85)' }}
      >
        基本情報登録
      </Typography.Text>
    </div>
    <Step1Form onPrevious={onPrevious} onSubmit={onSubmit}>
      <Space size={16} direction="vertical" className="full-w">
        <Space size={16} className="flex justify-end">
          <Button type="default" onClick={onPrevious}>
            戻る
          </Button>
          <Button type="primary" htmlType="submit">
            次へ
          </Button>
        </Space>
      </Space>
    </Step1Form>
  </Space>
);

RegisterFormStep1.propTypes = {
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterFormStep1;
