import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select, Button, Card, Empty, Typography, Space
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getInvoiceListRequest } from 'providers/InvoiceProvider/actions';

import Helper from 'utils/helpers';
import EmptyInvoiceIcon from 'images/icon-empty-document.svg';

const { Option } = Select;

const InvoiceList = ({ restaurantId }) => {
  const dispatch = useDispatch();
  const [selectedFileObject, setSelectedFileObject] = useState({});
  const [invoiceListLoading, setInvoiceListLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (restaurantId) {
        setInvoiceListLoading(true);
        try {
          await dispatch(getInvoiceListRequest({ restaurantId }));
        } catch (error) {
          console.error(error);
        }
        setInvoiceListLoading(false);
      }
    };
    fetchData();
  }, [restaurantId]);

  const invoiceList = useSelector((state) => state.invoiceProvider.invoiceList);

  if (_isEmpty(invoiceList)) {
    return (
      <Card
        className="highlight-card"
        bordered={false}
        title="請求書"
        loading={invoiceListLoading}
      >
        <Empty
          image={EmptyInvoiceIcon}
          imageStyle={{
            width: 160,
            height: 160,
            margin: '0 auto',
          }}
          description={(
            <Typography.Text type="secondary">
              ダウンロードできる請求書がありません。
            </Typography.Text>
          )}
        />
      </Card>
    );
  }

  return (
    <Card
      className="highlight-card"
      bordered={false}
      title="請求書"
      bodyStyle={{ minHeight: 230 }}
    >
      <Space direction="vertical" size={16} className="full-w">
        <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          ダウンロードしたい請求書を選択して下さい。
        </Typography.Text>
        <div className="flex">
          <Select
            style={{ flexGrow: 1, marginRight: 8, overflow: 'hidden' }}
            allowClear
            onChange={(value) => {
              setSelectedFileObject(
                _get(
                  _find(invoiceList, (o) => o.objectId === value),
                  'invoicePDF'
                )
              );
            }}
          >
            {_map(invoiceList, (invoiceItem) => (
              <Option key={invoiceItem.objectId} value={invoiceItem.objectId}>
                {invoiceItem.invoicePDF.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            disabled={_isEmpty(selectedFileObject)}
            onClick={() => Helper.downloadFile(
              selectedFileObject.url,
              selectedFileObject.name
            )}
            icon={<DownloadOutlined />}
          >
            ダウンロード
          </Button>
        </div>
      </Space>
    </Card>
  );
};

InvoiceList.propTypes = {
  restaurantId: PropTypes.string,
};

export default InvoiceList;
