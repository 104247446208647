import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (values.text !== '無効にする') {
    errors.text = Constants.ERROR_MESSAGE.INVALID;
  }

  return errors;
};

export default validate;
