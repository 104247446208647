import React, {
  useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Space, Typography, Upload } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import 'antd/es/slider/style';
import ImgCrop from 'antd-img-crop';
import { IMAGE_ACCEPT_TYPE } from 'utils/constants';

const ImageField = (props) => {
  const {
    label,
    input,
    description,
    additionalContent,
    maxCount,
    meta: { touched, invalid, error },
    shape,
    withCrop = false,
    aspect = 16 / 9,
    placeholder,
    disabled
  } = props;

  const onChange = ({ fileList }) => {
    if (fileList.length < input.value.length) {
      input.onChange(fileList);
    }
  };

  const uploadRef = useRef(null);

  useEffect(() => () => {
    if (uploadRef.current) { URL.revokeObjectURL(uploadRef.current); }
  }, []);

  const beforeUploadHandler = (file) => {
    if (uploadRef.current) { URL.revokeObjectURL(uploadRef.current); }
    const url = URL.createObjectURL(file);

    input.onChange([
      ...input.value,
      {
        url, originFileObj: file, status: 'done', name: file.name
      },
    ]);
    uploadRef.current = url;

    return false;
  };

  const upload = (
    <Upload
      className={shape === 'round' ? 'round-image-field' : ''}
      listType="picture-card"
      fileList={input.value || []}
      beforeUpload={beforeUploadHandler}
      onChange={onChange}
      accept={IMAGE_ACCEPT_TYPE}
      maxCount={maxCount}
      showUploadList={{
        showPreviewIcon: false,
      }}
      disabled={disabled}
    >
      {input.value.length < maxCount && (
        <Space direction="vertical">
          <PlusOutlined />
          <Typography.Text style={{ fontSize: 12 }}>{placeholder || 'Image'}</Typography.Text>
        </Space>
      )}
    </Upload>
  );

  return (
    <Form.Item
      label={
        !description ? (
          label
        ) : (
          <Space
            size={0}
            direction="vertical"
            style={{ display: 'inline-block' }}
          >
            {label}
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {description}
            </Typography.Text>
          </Space>
        )
      }
      help={touched && invalid && error}
      validateStatus={touched && invalid && error ? 'error' : ''}
      className="field-form-item"
    >
      {additionalContent}
      {withCrop ? (
        <ImgCrop
          rotate
          aspect={aspect}
          modalOk="はい"
          modalCancel="いいえ"
          modalTitle="画像の編集"
        >
          {upload}
        </ImgCrop>
      ) : (
        upload
      )}
    </Form.Item>
  );
};

ImageField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  additionalContent: PropTypes.any,
  meta: PropTypes.object.isRequired,
  description: PropTypes.any,
  maxCount: PropTypes.any,
  shape: PropTypes.string,
  withCrop: PropTypes.bool,
  aspect: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default ImageField;
