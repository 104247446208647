import _get from 'lodash/get';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';
import {
  Button, Space, Typography, Card, Alert
} from 'antd';

import '../style.less';

const ResetPasswordSuccess = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Card className="highlight-card" style={{ minHeight: 486 }}>
      <Space direction="vertical" size={32} className="full-w">
        <Space direction="vertical" className="full-w">
          <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
            パスワードの設定が完了しました
          </Typography.Text>
          <Alert
            className="reset-password-success-alert"
            type="success"
            showIcon
            message={(
              <Typography.Text>
                パスワードをリセットするためのメールを{' '}
                <Typography.Text strong>
                  {_get(location, 'state.email')}
                </Typography.Text>
                へ送信いたしました。メールの内容に従って、パスワードをリセットしてください。
              </Typography.Text>
            )}
          />
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            メールが届いていない場合は迷惑メールフォルダを確認してください。メールはsupport@shikomel.comから送信されています。
          </Typography.Text>
        </Space>
        <Button
          className="full-w"
          type="primary"
          onClick={() => {
            dispatch(replace('/auth/login'));
          }}
        >
          ログインに戻る
        </Button>
      </Space>
    </Card>
  );
};

export default ResetPasswordSuccess;
