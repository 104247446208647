import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

const PublicRoutes = ({ component, path }) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  if (currentUser.objectId) {
    return <Redirect to="/" />;
  }

  return <Route path={path} component={component} />;
};

PublicRoutes.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export default PublicRoutes;
