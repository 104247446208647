import React from 'react';
import { Typography } from 'antd';
import { DATE_TIME_FORMAT } from 'utils/constants';
import moment from 'moment';

const Columns = {};
const { Text } = Typography;

Columns.ID = {
  title: 'ID',
  width: 110,
  dataIndex: 'objectId',
  key: 'objectId',
  align: 'left',
};

Columns.name = {
  title: 'Group name',
  dataIndex: 'name',
  key: 'name',
  width: 200,
  align: 'left',
  render: (record) => (
    <Text
      ellipsis={{
        tooltip: record,
      }}
    >
      {record || '-'}
    </Text>
  ),
};

Columns.note = {
  title: 'メモ',
  width: 350,
  dataIndex: 'note',
  key: 'note',
  render: (record) => (record ? (
    <Typography.Paragraph
      style={{
        marginBottom: 0,
        whiteSpace: 'pre-line',
      }}
    >
      {record}
    </Typography.Paragraph>
  ) : (
    '-'
  )),
};

Columns.createdAt = {
  title: '作成日',
  dataIndex: 'createdAt',
  key: 'createdAt',
  align: 'left',
  width: 140,
  render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
};

export default Columns;
