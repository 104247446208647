import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { reduxForm, getFormValues } from 'redux-form';

import { registerSupplierRequest } from 'providers/SupplierProvider/actions';
import { getCurrentUserRequest } from 'providers/AuthProvider/actions';

import RegisterFormStep1 from './RegisterFormStep1';
import RegisterFormStep2 from './RegisterFormStep2';

import './style.less';

const SupplierRegister = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [actionLoading, setActionLoading] = useState(false);
  const formValues = useSelector((state) => getFormValues('supplierRegisterForm')(state));

  const handleRegister = async (value) => {
    setActionLoading(true);
    try {
      await dispatch(registerSupplierRequest(value));
      await dispatch(getCurrentUserRequest());
      dispatch(push('/'));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div id="supplier-register">
      {step === 1 && (
        <RegisterFormStep1
          onPrevious={() => dispatch(push('/reg/role'))}
          onSubmit={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <RegisterFormStep2
          onPrevious={() => setStep(1)}
          onSubmit={handleRegister}
          actionLoading={actionLoading}
          formValues={formValues}
        />
      )}
    </div>
  );
};

export default reduxForm({
  form: 'supplierRegisterForm',
  initialValues: {
    workingDays: [1, 2, 3, 4, 5, 6],
    shippingMethod: 'OWN_SHIPPING',
    shippingFee: 0,
    setPriceOfFreeShip: false,
    priceOfFreeShip: 0,
    invoiceNote: 'お振込み手数料はご負担をお願いいたします。',
    invoiceShowPaymentDeadline: false,
    orderDeadlineTime: moment.tz('15:00', 'HH:mm', 'Asia/Tokyo'),
    leadTimeIncludeHoliday: false,
  },
})(SupplierRegister);
