import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';

import {
  disableRestaurantOwnerAccountRequest,
  enableRestaurantOwnerAccountRequest,
} from 'providers/RestaurantOwnerProvider/actions';

import { STATUS } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

import DisableRestaurantOwnerForm from './DisableRestaurantOwnerFrom';

import './style.less';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '有効にする',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: actionFn,
  });
};

const DisableRestaurantOwnerModal = ({
  name,
  restaurantOwnerId,
  showModal,
  setShowModal,
  callBack,
}) => {
  const dispatch = useDispatch();

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      await dispatch(
        disableRestaurantOwnerAccountRequest({ restaurantOwnerId })
      );
      setShowModal(false);
      toastMessage.success({
        message: '成功',
        description: `アカウント ${name} を無効にしました。`,
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
    setSubmitLoading(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} aria-hidden="true">
      <Modal
        title={`アカウント ${name} を無効にします`}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        destroyOnClose
        centered
        maskClosable={false}
      >
        <DisableRestaurantOwnerForm
          onSubmit={handleSubmit}
          onCancel={() => setShowModal(false)}
          submitLoading={submitLoading}
        />
      </Modal>
    </div>
  );
};

DisableRestaurantOwnerModal.propTypes = {
  name: PropTypes.string,
  restaurantOwnerId: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  callBack: PropTypes.func,
};

const StatusToggle = ({ rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    name, callBack, status, objectId: restaurantOwnerId
  } = rowRecord;

  const handleEnableRestaurantOwnerAccount = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(enableRestaurantOwnerAccountRequest(params));
      toastMessage.success({
        message: '成功',
        description: `アカウント ${name} を有効にしました。`,
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  const handleChangeStatus = () => {
    if (status === STATUS.INACTIVE) {
      showConfirm(`アカウント ${name} を有効にしますか？`, () => handleEnableRestaurantOwnerAccount({ restaurantOwnerId })
      );
    } else {
      setShowModal(true);
    }
  };

  return (
    <div id="restaurant-owner-status-toggle">
      <DisableRestaurantOwnerModal
        name={name}
        restaurantOwnerId={restaurantOwnerId}
        showModal={showModal}
        setShowModal={setShowModal}
        callBack={callBack}
      />
      <div
        aria-hidden="true"
        role="button"
        className="status-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <span
            className={status === STATUS.ACTIVE ? 'dot' : 'dot--inactive'}
          />
          <span>{status === STATUS.ACTIVE ? '有効' : '無効'}</span>
        </div>
        <Switch
          disabled={changeStatusLoading}
          checked={status === STATUS.ACTIVE}
          onClick={handleChangeStatus}
          size="small"
        />
      </div>
    </div>
  );
};

StatusToggle.propTypes = {
  rowRecord: PropTypes.object,
};

export default StatusToggle;
