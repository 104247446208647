import moment from 'moment-timezone';
import _isEmpty from 'lodash/isEmpty';
import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (_isEmpty(values.workingDays)) {
    errors.workingDays = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.orderDeadlineTime) {
    errors.orderDeadlineTime = Constants.ERROR_MESSAGE.REQUIRED;
  } else {
    const orderDeadlineTime = moment(values.orderDeadlineTime)
      .tz('Asia/Tokyo')
      .format('HH:mm');
    if (orderDeadlineTime === '00:00') {
      errors.orderDeadlineTime = `${moment(values.orderDeadlineTime).format(
        'H:mm'
      )}は設定することができません。`;
    }
  }
  return errors;
};

export default validate;
