import {
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_ERROR,
  RESET_CATEGORY_LIST,
} from './constants';

export function resetCategoryList() {
  return { type: RESET_CATEGORY_LIST };
}

export function getCategoryListRequest(payload) {
  return { type: GET_CATEGORY_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getCategoryListSuccess(payload, meta) {
  return { type: GET_CATEGORY_LIST_SUCCESS, payload, meta };
}

export function getCategoryListError(payload, meta) {
  return {
    type: GET_CATEGORY_LIST_ERROR,
    error: true,
    payload,
    meta,
  };
}
