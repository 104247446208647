import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Modal,
  Spin,
  Button,
  Input,
  Typography,
  Avatar,
  Checkbox,
  Space,
  Col,
  Row,
  Tooltip,
  List,
  Select,
  Form,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { STATUS, MENU_LIST_FILTER } from 'utils/constants';
import { getGenreListRequest, getTagListRequest } from 'providers/GenreProvider/actions';
import { addRankingMenuRequest } from 'providers/RankingProvider/actions';
import toastMessage from 'utils/toastMessage';
import { doRefresh } from 'providers/CommonProvider/actions';
import DefaultProductImage from 'images/defaultProduct.svg';
import './style.scss';

const { confirm } = Modal;
const { Text, Paragraph } = Typography;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const UpdateDialog = ({
  handleCloseDialog,
  getMenuItemListRequest,
  menuItemList,
  assignedProductList,
  title,
  rankingId,
}) => {
  const [selections, setSelections] = useState([]);
  const [menuKeyword, setMenuKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [productList, setProductList] = useState(menuItemList || []);
  const [selectedGenreId, setSelectedGenreId] = useState();
  const [selectedTagId, setSelectedTagId] = useState();
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_isEmpty(assignedProductList)) {
      setSelections(assignedProductList);
    }
  }, [assignedProductList]);

  const { genreList, tagList } = useSelector(
    (state) => state.genreProvider,
    shallowEqual
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getMenuItemListRequest({
        page,
        limit: 20,
        keyword: menuKeyword,
        menuType: MENU_LIST_FILTER.BASIC,
        opts: { getMenuCountPerType: false, getWarehouseMenuData: false },
        genreId: selectedGenreId?.value,
        tagId: selectedTagId?.value,
      });
      setLoading(false);
      if (page > 1) {
        setProductList((prevProductList) => {
          const updateProductList = [...prevProductList, ...result?.list];
          setHasMore(updateProductList.length < result?.total);
          return updateProductList;
        });
        return;
      }
      setProductList(result?.list);
    } catch (error) {
      setLoading(false);
    }
  }, [page, menuKeyword, selectedGenreId, selectedTagId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dispatch(getGenreListRequest());
    dispatch(getTagListRequest());
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getGenreListWithDelay = useCallback(
    _debounce(
      (data, api, limit) => dispatch(
        api({
          ...data,
          limit
        })
      ),
      500
    ),
    []
  );

  const onSearchGenreKeyword = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getGenreListWithDelay(data, getGenreListRequest, 1000);
  };

  const onSearchTagKeyword = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getGenreListWithDelay(data, getTagListRequest);
  };

  const handleSelectMenuItem = (e, menuItem) => {
    e.preventDefault();
    if (!selections.find((item) => item.objectId === menuItem.objectId)) {
      if (selections.length >= 10) {
        toastMessage.error({
          description: '最大10商品までしか設定できません',
        });
        return;
      }
      setSelections([
        ...selections,
        menuItem
      ]);
    } else {
      setSelections(
        selections.filter((item) => item.objectId !== menuItem.objectId)
      );
    }
  };

  const handleUnselectItem = (e, id) => {
    e.preventDefault();
    setSelections(selections.filter((menuItem) => menuItem.objectId !== id));
  };

  const selectedIds = selections.map((item) => item.objectId);

  const renderSelection = () => selections.map((menuItem) => (
    <div key={menuItem.objectId} className="selected-menu-bg">
      <div className="selected-menu-item">
        <div
          className="flex items-center menu-detail-container"
          style={{ flex: 1 }}
        >
          <Avatar
            size={32}
            shape="square"
            src={_get(menuItem, 'images[0].url', DefaultProductImage)}
          />
          <div className="menu-detail">
            <Tooltip title={menuItem.name}>
              <Paragraph ellipsis={{ rows: 1 }} className="menu-name">
                {menuItem.name}
              </Paragraph>
            </Tooltip>
            {menuItem.note && (
              <div className="menu-note">{menuItem.note}</div>
            )}
          </div>
        </div>
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={(event) => handleUnselectItem(event, menuItem.objectId)}
        />
      </div>
    </div>
  ));

  const handleAddRankingMenu = async () => {
    try {
      setUpdateLoading(true);
      const menuIds = selections?.map((item) => item?.objectId);
      await dispatch(addRankingMenuRequest({ menuIds, rankingId }));
      handleCloseDialog();
      setUpdateLoading(false);
      dispatch(doRefresh({ target: 'ranking-detail' }));
    } catch (error) {
      setUpdateLoading(false);
    }
  };

  const footer = (
    <div>
      <Button
        className="form-button"
        disabled={updateLoading}
        onClick={() => showConfirm('中断してよろしいですか？', handleCloseDialog)}
      >
        キャンセル
      </Button>
      <Button
        type="primary"
        className="form-button"
        loading={updateLoading}
        onClick={handleAddRankingMenu}
      >
        保存する
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      visible
      width={1000}
      closable={!updateLoading}
      onCancel={() => showConfirm('中断してよろしいですか？', handleCloseDialog)}
      footer={footer}
      destroyOnClose
      centered
      maskClosable={false}
      className="ranking-update-modal"
    >
      <Row>
        <Col
          className="menu-selector-container"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 14 }}
          lg={{ span: 15 }}
        >
          <div className="filter-container">
            <Input.Search
              onSearch={(value) => {
                setMenuKeyword(value);
                setPage(1);
              }}
              placeholder="Search product name/ID/Free code"
            />
            <Space className="flex justify-between flex-wrap">
              <Form.Item
                label="タグ"
                colon
              >
                <Select
                  value={selectedTagId}
                  labelInValue
                  onChange={(val) => {
                    setSelectedTagId(val);
                    setPage(1);
                    dispatch(getTagListRequest());
                  }}
                  style={{ width: 200 }}
                  showSearch
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={onSearchTagKeyword}
                  allowClear
                  placeholder="すべて"
                >
                  {_map(tagList, (tag) => (
                    <Select.Option key={tag.objectId} value={tag.objectId}>
                      {tag.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="ジャンル"
                colon
              >
                <Select
                  value={selectedGenreId}
                  labelInValue
                  onChange={(val) => {
                    setSelectedGenreId(val);
                    setPage(1);
                    dispatch(getGenreListRequest());
                  }}
                  style={{ width: 200 }}
                  showSearch
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={onSearchGenreKeyword}
                  allowClear
                  placeholder="すべて"
                >
                  {_map(genreList, (genre) => (
                    <Select.Option key={genre.objectId} value={genre.objectId}>
                      {genre.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
          </div>
          <div className="collection-menu-container">
            <Text strong> 商品 </Text>
            <div className="category-menu-list">
              <InfiniteScroll
                initialLoad={false}
                pageStart={1}
                loadMore={
                  !loading && hasMore && handleLoadMore
                }
                hasMore={hasMore}
                useWindow={false}
              >
                <List
                  dataSource={productList}
                  renderItem={(menuItem) => {
                    const disabled = menuItem?.status === STATUS.INACTIVE;
                    const selected = selectedIds.includes(menuItem.objectId);
                    return (
                      <List.Item
                        onClick={(event) => !disabled && handleSelectMenuItem(event, menuItem)}
                        key={menuItem.objectId}
                        className={
                          `category-menu-item${
                            selected && !disabled ? '--selected' : ''
                          }${disabled ? '--disabled' : ''}`
                        }
                        extra={(disabled && '無効')}
                      >
                        <div className="menu-item-detail flex items-center">
                          <Checkbox
                            checked={selectedIds.includes(menuItem.objectId)}
                            disabled={menuItem?.status === STATUS.INACTIVE}
                          />
                          <div
                            className="flex items-center"
                            style={{
                              width: 'calc(100% - 32px)',
                            }}
                          >
                            <Avatar
                              size={32}
                              shape="square"
                              src={_get(
                                menuItem,
                                'images[0].url',
                                DefaultProductImage
                              )}
                            />
                            <div
                              style={{
                                width: 'calc(100% - 40px)',
                              }}
                            >
                              <Tooltip title={menuItem.name}>
                                <Paragraph ellipsis={{ rows: 1 }} className="product-name">
                                  {menuItem.name}
                                </Paragraph>
                              </Tooltip>
                              {menuItem.note && (
                                <div className="menu-item-note">
                                  {menuItem.note}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </List.Item>
                    );
                  }}
                >
                  {
                    loading && hasMore && (
                      <div className="loading-icon">
                        <Spin />
                      </div>
                    )
                  }
                </List>
              </InfiniteScroll>
            </div>
          </div>
        </Col>
        <Col
          className="selected-menu-container"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 9 }}
        >
          <div className="flex items-center">
            <CheckCircleFilled className="checked-icon" />
            <Text>選択済み商品 ({selections.length})</Text>
          </div>
          <div className="selected-menu-list">{renderSelection()}</div>
        </Col>
      </Row>
    </Modal>
  );
};

UpdateDialog.propTypes = {
  menuItemList: PropTypes.array,
  getMenuItemListRequest: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  assignedProductList: PropTypes.array,
  title: PropTypes.string,
  rankingId: PropTypes.string,
};

export default UpdateDialog;
