import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Space, Avatar, Typography, Checkbox
} from 'antd';

import AllergyImage from './AllergyImage';

import './style.less';

const AllergyField = (props) => {
  const {
    input,
    label,
    description,
    options,
    meta: { invalid, error },
  } = props;

  const handleChange = (value) => {
    if (input.value[value]) {
      const newSelections = { ...input.value };
      delete newSelections[value];
      input.onChange(newSelections);
    } else {
      const newSelections = { ...input.value };
      newSelections[value] = true;
      input.onChange(newSelections);
    }
  };

  const optionElements = _map(options, (option) => {
    const selected = !!input.value[option.value];
    const ImageComponent = AllergyImage[option.value];
    return (
      <button
        type="button"
        onClick={() => handleChange(option.value)}
        style={{
          cursor: 'pointer',
          background: selected ? '#0a6bd61a' : '#ffffff',
          border: selected ? '1px solid #1890ff' : '1px solid #00000026',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: '72px',
          width: '64px',
          position: 'relative',
        }}
      >
        <Checkbox
          checked={selected}
          style={{
            lineHeight: '0px',
            position: 'absolute',
            top: '2px',
            right: '2px',
          }}
        />
        <Avatar
          src={<ImageComponent />}
          shape="square"
          size={24}
          className={`allergy-image${selected ? '--selected' : ''}`}
        />
        <span
          style={{
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: 1.4,
            textAlign: 'center',
            color: selected ? '#1890ff' : '#00000073',
          }}
        >
          {option.label}
        </span>
      </button>
    );
  });

  return (
    <Form.Item
      label={(
        <Space
          style={{ display: 'inline-block', whiteSpace: 'pre-line' }}
          size={0}
          direction="vertical"
        >
          {label}
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {description}
          </Typography.Text>
        </Space>
      )}
      help={invalid && error}
      validateStatus={invalid && error ? 'error' : ''}
      hasFeedback
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          width: '100%',
          maxWidth: '424px',
        }}
      >
        {optionElements}
      </div>
    </Form.Item>
  );
};

AllergyField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
};

export default AllergyField;
