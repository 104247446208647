import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { Modal, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { isDirty } from 'redux-form';

import { getRankingDetailRequest, removeRankingMenuRequest } from 'providers/RankingProvider/actions';
import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';
import RankingUpdate from './RankingUpdate';
import './styles.less';

const { confirm } = Modal;

const showConfirm = (modalProps, actionFn) => {
  confirm({
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
    ...modalProps,
  });
};

const RankingDetail = () => {
  const dispatch = useDispatch();
  const { rankingId } = useParams();
  const { goBack } = useHistory();
  const dirty = useSelector((state) => isDirty('rankingForm')(state));

  const { rankingDetail, isLoading } = useSelector((state) => state.rankingProvider);

  const breadcrumbItems = [
    {
      path: '/rankings',
      breadcrumbName: 'ランキング',
    },
    {
      breadcrumbName: rankingDetail?.name,
    },
  ];

  const getRankingDetail = useCallback(() => {
    dispatch(getRankingDetailRequest({ objectId: rankingId }));
  }, [rankingId]);

  useEffect(() => {
    getRankingDetail();
  }, [getRankingDetail]);

  const handleCancel = () => showConfirm({ title: '中断してよろしいですか？' }, () => dispatch(replace('/rankings'))
  );

  const handleRemove = async (rankingMenuId) => {
    try {
      await dispatch(removeRankingMenuRequest({ rankingId, rankingMenuId }));
      getRankingDetail();
    } catch (error) {
      console.error(error);
    }
  };

  const selectedProducts = rankingDetail?.products?.map((item) => ({ ...item, objectId: item?.menuId }));

  if (isLoading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: '100%' }}
      ><Spin />
      </div>
    );
  }

  return (
    <div className="ranking-detail-page">
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={dirty ? handleCancel : goBack}
      />
      <RankingUpdate
        assignedProducts={{
          list: selectedProducts || [],
          total: rankingDetail?.products?.length || 0,
        }}
        rankingDetail={rankingDetail}
        onRemoveAssignedProduct={handleRemove}
      />
    </div>
  );
};

export default withRefresh('ranking-detail')(RankingDetail);
