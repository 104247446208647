import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Button, Card, Space } from 'antd';
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';

import { getPrefectureListRequest } from 'providers/PrefectureProvider/actions';

import { SelectField, TextField } from 'components/Form';

import validate from './validate';

const DeliveryForm = ({ handleSubmit, deliveryDetail }) => {
  const dispatch = useDispatch();

  const prefectureList = useSelector(
    (state) => state.prefectureProvider.prefectureList,
    shallowEqual
  );

  useEffect(() => {
    if (_isEmpty(prefectureList)) {
      dispatch(getPrefectureListRequest());
    }
  }, []);
  const handleUpdateDelivery = () => {
    _forEach(deliveryDetail, (value, key) => {
      dispatch(change('deliveryForm', key, value));
    });
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      colon={false}
      layout="horizontal"
      onSubmit={handleSubmit}
    >
      <Space size={40} direction="vertical" className="full-w">
        <Space size={16} direction="vertical" className="full-w">
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="納品場所の住所"
            extra={(
              <Button onClick={handleUpdateDelivery}>
                店舗情報と同じ内容を設定する
              </Button>
            )}
          >
            <Field
              label="郵便番号"
              name="deliveryPostalCode"
              component={TextField}
              maxLength={7}
              required
            />
            <Field
              label="都道府県"
              name="deliveryPrefecture"
              component={SelectField}
              loading={_isEmpty(prefectureList)}
              options={_map(prefectureList, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="住所1"
              description="市区町村"
              name="deliveryAddress"
              component={TextField}
              maxLength={50}
              required
            />
            <Field
              label="ビル名等以降の住所"
              description="任意"
              name="deliveryBuildingName"
              component={TextField}
              maxLength={50}
              requiredSpace
            />
            <Field
              label="電話番号"
              name="deliveryPhone"
              component={TextField}
              maxLength={11}
              required
            />
            <Field
              label="FAX番号"
              description="任意"
              name="deliveryFax"
              component={TextField}
              maxLength={11}
              requiredSpace
            />
          </Card>
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="納品場所補足情報"
          >
            <Field
              label="補足情報"
              description="任意"
              name="deliveryNote"
              component={TextField}
              maxLength={100}
              textArea
            />
          </Card>
        </Space>
      </Space>
    </Form>
  );
};

DeliveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  deliveryDetail: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'deliveryForm',
  touchOnChange: true,
  validate,
})(DeliveryForm);
