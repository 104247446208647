import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { Modal } from 'antd';

import { createCollectionRequest } from 'providers/CollectionProvider/actions';

import PageHeader from 'components/PageHeader';
import toastMessage from 'utils/toastMessage';

import CollectionsUpdate from './CollectionUpdate';

const { confirm } = Modal;

const showConfirm = (modalProps, actionFn) => {
  confirm({
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
    ...modalProps,
  });
};

const breadcrumbItems = [
  {
    path: '/collections',
    breadcrumbName: 'セット商品',
  },
  {
    breadcrumbName: 'セット商品の追加',
  },
];

const CollectionsCreate = () => {
  const dispatch = useDispatch();

  const [createActionLoading, setCreateActionLoading] = useState(false);
  const [collectionMenuItems, setCollectionMenuItems] = useState([]);

  const handleCancel = () => showConfirm({ title: '中断してよろしいですか？' }, () => dispatch(replace('/collections'))
  );

  const handleSubmit = async (value) => {
    setCreateActionLoading(true);
    try {
      const menuItems = collectionMenuItems.map((menu) => menu.objectId);
      await dispatch(createCollectionRequest({ ...value, menuItems, note: value?.note || undefined }));
      dispatch(replace('/collections'));
      toastMessage.success({
        description: `商品グループ${value?.name}を作成しました`,
      });
    } catch (err) {
      console.error(err);
    }
    setCreateActionLoading(false);
  };

  const handleRemove = (record) => {
    setCollectionMenuItems(
      collectionMenuItems.filter(
        (menuItem) => menuItem.objectId !== record.objectId
      )
    );
  };

  return (
    <>
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleCancel}
      />
      <CollectionsUpdate
        title="商品選択"
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitLoading={createActionLoading}
        handleAssignProductsSubmit={setCollectionMenuItems}
        handleRemoveAssignedProduct={handleRemove}
        assignedProducts={{
          list: collectionMenuItems,
          total: collectionMenuItems.length,
        }}
      />
    </>
  );
};

export default CollectionsCreate;
