/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';
import {
  disableRestaurantAccountRequest,
  enableRestaurantAccountRequest,
} from 'providers/RestaurantProvider/actions';

import { STATUS } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

import DisableRestaurantForm from './DisableRestaurantForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '有効にする',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const DisableRestaurantModal = ({
  name,
  restaurantId,
  showModal,
  setShowModal,
  callBack,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      await dispatch(disableRestaurantAccountRequest({ restaurantId }));
      setShowModal(false);
      toastMessage.success({
        message: '成功',
        description: name
          ? `アカウント ${name} を無効にしました。`
          : 'アカウントを無効にしました。',
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div role="button" onClick={(e) => e.stopPropagation()}>
      <Modal
        title={
          name
            ? `アカウント ${name} を無効にします`
            : 'アカウントを無効にします'
        }
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        destroyOnClose
        centered
        maskClosable={false}
      >
        <DisableRestaurantForm
          onSubmit={handleSubmit}
          onCancel={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};

DisableRestaurantModal.propTypes = {
  name: PropTypes.string,
  restaurantId: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  callBack: PropTypes.func,
};

const StatusToggle = ({ rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    name, callBack, status, objectId: restaurantId
  } = rowRecord;

  const handleEnableRestaurantAccount = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(enableRestaurantAccountRequest(params));
      toastMessage.success({
        message: '成功',
        description: name
          ? `アカウント ${name} を有効にしました。`
          : 'アカウントを有効にしました。',
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <>
      <DisableRestaurantModal
        name={name}
        restaurantId={restaurantId}
        showModal={showModal}
        setShowModal={setShowModal}
        callBack={callBack}
      />
      <div
        role="button"
        className="status-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <span
            className={`${status === STATUS.ACTIVE ? 'dot' : 'dot dot--inactive'}`}
          />
          <span>{status === STATUS.ACTIVE ? '有効' : '無効'}</span>
        </div>
        <Switch
          disabled={changeStatusLoading}
          checked={status === STATUS.ACTIVE}
          size="small"
          onClick={() => {
            if (status === STATUS.INACTIVE) {
              showConfirm(
                name
                  ? `アカウント ${name} を有効にしますか？`
                  : 'アカウントを有効にしますか？',
                () => handleEnableRestaurantAccount({ restaurantId })
              );
            } else {
              setShowModal(true);
            }
          }}
        />
      </div>
    </>
  );
};

StatusToggle.propTypes = {
  rowRecord: PropTypes.object,
};

export default StatusToggle;
