import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Space, Typography, Button } from 'antd';

import { registerBrandOwnerRequest } from 'providers/BrandProvider/actions';
import { getCurrentUserRequest } from 'providers/AuthProvider/actions';
import RegisterBrandOwnerForm from './RegisterBrandOwnerForm';

import './style.less';

const BrandOwnerRegister = () => {
  const dispatch = useDispatch();

  const [actionLoading, setActionLoading] = useState(false);

  const handleRegister = async (value) => {
    setActionLoading(true);
    try {
      await dispatch(registerBrandOwnerRequest(value));
      await dispatch(getCurrentUserRequest());
      dispatch(push('/'));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  return (
    <div id="brand-owner-register">
      <Space size={40} direction="vertical" className="full-w">
        <div style={{ textAlign: 'center' }}>
          <Typography.Text
            strong
            style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.85)' }}
          >
            基本情報登録
          </Typography.Text>
        </div>
        <RegisterBrandOwnerForm
          onSubmit={handleRegister}
          actionLoading={actionLoading}
        >
          <Space size={16} className="flex justify-end">
            <Button
              type="default"
              onClick={() => dispatch(push('/reg/role'))}
              disabled={actionLoading}
            >
              戻る
            </Button>
            <Button type="primary" htmlType="submit" loading={actionLoading}>
              送信
            </Button>
          </Space>
        </RegisterBrandOwnerForm>
      </Space>
    </div>
  );
};

export default BrandOwnerRegister;
