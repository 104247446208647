import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Card, Button, Modal } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { USER_ROLE } from 'utils/constants';

import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import withRefresh from 'components/HOCs/withRefresh';

import {
  getRelatedProductListRequest,
  removeProductRelationRequest,
} from 'providers/RelatedProductProvider/actions';

import AddRelatedProducts from './AddRelatedProducts';
import Columns from '../../List/Columns';

import './style.less';

const { confirm } = Modal;
const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '削除する',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: actionFn,
  });
};

const RelatedProductList = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const [showAddRelatedProductsModal, setShowAddRelatedProductsModal] = useState(false);

  const relatedProductList = useSelector(
    (state) => state.relatedProductProvider.relatedProductList,
    shallowEqual
  );
  const menuItemDetail = useSelector(
    (state) => state.menuProvider.menuItemDetail,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const ownerId = _get(menuItemDetail, 'objectId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRelatedProductListRequest({
          page,
          limit,
          ownerId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [page, limit, ownerId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteButton = (record) => {
    const handleRemoveRelatedProduct = (e) => {
      e.stopPropagation();

      showConfirm(
        `${menuItemDetail?.name}から${record?.name}を削除してよろしいですか？`,
        async () => {
          try {
            await dispatch(
              removeProductRelationRequest({
                ownerId,
                relatedTo: [record.objectId],
              })
            );
            fetchData();
          } catch (error) {
            console.error(error);
          }
        }
      );
    };

    return (
      <Button
        onClick={handleRemoveRelatedProduct}
        type="text"
        danger
        icon={<CloseOutlined />}
      >
        削除
      </Button>
    );
  };

  const columns = [
    { ...Columns.ID, sorter: false },
    Columns.productName,
    Columns.leadTime,
    Columns.brand,
    Columns.prices,
    Columns.brandFee,
    Columns.inventory,
    Columns.status,
  ];

  if (currentUser.role === USER_ROLE.SUPPLIER) {
    columns.push({
      title: '操作',
      key: 'action',
      width: 115,
      fixed: 'right',
      render: deleteButton,
    });
  }

  if (!menuItemDetail?.isBasic) {
    return null;
  }

  const handleAddRelatedProducts = async () => {
    try {
      await dispatch(
        getRelatedProductListRequest({ ownerId, page: 1, limit: 1000 })
      );
      setShowAddRelatedProductsModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseAddRelatedProducts = () => {
    setShowAddRelatedProductsModal(false);
  };

  return (
    <Card
      id="related-product-list"
      className="bg-transparent"
      bodyStyle={{ paddingTop: 16 }}
      bordered={false}
    >
      {showAddRelatedProductsModal && (
        <AddRelatedProducts
          visible
          handleCloseDialog={handleCloseAddRelatedProducts}
          ownerDetail={menuItemDetail}
        />
      )}
      <Card
        className="highlight-card padding-card"
        bordered={false}
        headStyle={{ padding: '0 16px', borderBottom: '2px solid #e8e8e8' }}
        extra={
          currentUser.role === USER_ROLE.SUPPLIER && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddRelatedProducts}
            >
              関連商品追加
            </Button>
          )
        }
      >
        <Table
          columns={columns}
          data={_get(relatedProductList, 'list', [])}
          total={_get(relatedProductList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
            marginRight: true,
          }}
          scroll={{ x: currentUser.role === USER_ROLE.SUPPLIER ? 1332 : 1217 }}
        />
      </Card>
    </Card>
  );
};

export default withRefresh('relatedProductList')(RelatedProductList);
