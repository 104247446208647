import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Typography, Divider } from 'antd';
import { CheckboxGroup, CheckboxField, TimePickerField } from 'components/Form';
import { WORKING_DAYS } from 'utils/constants';

import './style.less';

const workingDayColor = {
  6: '#1890ff',
  7: '#cf1421',
};

const workingDayOptions = Object.keys(WORKING_DAYS).map((key) => ({
  label: WORKING_DAYS[key],
  value: Number(key),
  style: { color: workingDayColor[key] || '#262626' },
}));

const HolidaySettingForm = () => (
  <Form colon={false} layout="horizontal">
    <Card
      headStyle={{ fontSize: 20, fontWeight: 'bold' }}
      bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
      className="highlight-card"
      bordered={false}
      title="営業日"
    >
      <Typography.Text>営業日</Typography.Text>
      <span id="working-day-field">
        <Field
          name="workingDays"
          disabled
          component={CheckboxGroup}
          colSpan={3}
          options={workingDayOptions}
        />
      </span>
      <Divider />
      <Field
        label="休業日をリードタイムに含める"
        description="リードタイムに休業日を含む場合のみチェックを入れてください。最短納品日の計算に影響します。"
        name="leadTimeIncludeHoliday"
        component={CheckboxField}
        disabled
      />
      <Divider />
      <Field
        label="注文締め時間"
        name="orderDeadlineTime"
        timeFormat="HH:mm"
        component={TimePickerField}
        disabled
      />
    </Card>
  </Form>
);

export default reduxForm({
  form: 'suppliersHolidaySettingForm',
  enableReinitialize: true,
  touchOnChange: true,
})(HolidaySettingForm);
