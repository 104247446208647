import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Descriptions, Card, Typography, Divider, Button, Space
} from 'antd';

import BillingInformation from './BillingInformation';

const { Text } = Typography;
const { Item } = Descriptions;

const renderAddressDescription = (
  _postalCode,
  _prefecture,
  _address,
  _buildingName,
  _phone,
  _fax
) => (
  <Item>
    <div style={{ marginLeft: -8 }}>
      <div>{_postalCode}</div>
      <div>
        {_prefecture} {_address} {_buildingName}
      </div>
      <div>店舗電話番号: {_phone}</div>
      <div>店舗FAX番号: {_fax}</div>
    </div>
  </Item>
);

const ProfileInformation = ({ restaurantDetail, showEditAction }) => {
  const {
    postalCode,
    prefecture,
    address,
    buildingName,
    deliveryAddress,
    deliveryPostalCode,
    deliveryPrefecture,
    deliveryBuildingName,
    phone,
    fax,
    deliveryPhone,
    deliveryFax,
  } = restaurantDetail;

  const dispatch = useDispatch();
  return (
    <Space direction="vertical" size={16} className="full-w flex">
      <Card
        className="highlight-card padding-card-16"
        title="店舗情報"
        bordered={false}
        extra={
          showEditAction && (
            <Button
              onClick={() => dispatch(
                push(
                  `/restaurants/detail/${restaurantDetail?.formattedObjectId}/profile`
                )
              )}
            >
              更新
            </Button>
          )
        }
      >
        <Descriptions
          title="Business information"
          column={1}
          bordered
          labelStyle={{ verticalAlign: 'top' }}
        >
          <Item label="店舗名">
            <div>
              <div>{_get(restaurantDetail, 'name', '-')}</div>
              <Text style={{ fontSize: 12 }}>
                {_get(restaurantDetail, 'nameKana')}
              </Text>
            </div>
          </Item>
          <Item label="事業">
            <div>{_get(restaurantDetail, 'restaurantBusiness', '-')}</div>
          </Item>
          <Item label="業態">
            <div>{_get(restaurantDetail, 'restaurantType', '-')}</div>
          </Item>
          <Item label="業種">
            <div>{_get(restaurantDetail, 'restaurantIndustry', '-')}</div>
          </Item>
          <Item label="事業所区分">
            <div>{_get(restaurantDetail, 'businessType', '-')}</div>
          </Item>
        </Descriptions>
        <Divider />
        <Descriptions
          title="会社の住所"
          column={1}
          bordered
          labelStyle={{ verticalAlign: 'top' }}
        >
          {renderAddressDescription(
            postalCode,
            prefecture,
            address,
            buildingName,
            phone,
            fax
          )}
        </Descriptions>
        <Divider />
        <Descriptions
          title="担当者の連絡先"
          column={1}
          bordered
          labelStyle={{ verticalAlign: 'top' }}
        >
          <Item label="担当者">
            <div>
              <div>{_get(restaurantDetail, 'managerName')}</div>
              <Text style={{ fontSize: 12 }}>
                {_get(restaurantDetail, 'managerNameKana')}
              </Text>
            </div>
          </Item>
          <Item label="担当者緊急連絡先">
            {_get(restaurantDetail, 'emergencyPhone')}
          </Item>
        </Descriptions>
      </Card>
      <Card
        className="highlight-card padding-card-16"
        title="配送に関する情報"
        bordered={false}
        extra={
          showEditAction && (
            <Button
              onClick={() => dispatch(
                push(
                  `/restaurants/detail/${restaurantDetail?.formattedObjectId}/delivery`
                )
              )}
            >
              更新
            </Button>
          )
        }
      >
        <Descriptions
          title="配送に関する情報"
          column={1}
          bordered
          labelStyle={{ verticalAlign: 'top' }}
        >
          <Descriptions>
            {renderAddressDescription(
              deliveryPostalCode,
              deliveryPrefecture,
              deliveryAddress,
              deliveryBuildingName,
              deliveryPhone,
              deliveryFax
            )}
          </Descriptions>
        </Descriptions>
        <Divider />
        <Descriptions
          title="追加情報"
          column={1}
          bordered
          labelStyle={{ verticalAlign: 'top' }}
        >
          <Item label="納品場所補足情報">
            {_get(restaurantDetail, 'deliveryNote')}
          </Item>
        </Descriptions>
      </Card>
      <BillingInformation restaurantDetail={restaurantDetail} showEditAction />
    </Space>
  );
};

ProfileInformation.propTypes = {
  restaurantDetail: PropTypes.object,
  showEditAction: PropTypes.bool,
};

export default ProfileInformation;
