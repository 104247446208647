import React from 'react';
import _get from 'lodash/get';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Descriptions, Card, Col, Row, Space, Typography, Divider
} from 'antd';
import { useSelector, shallowEqual } from 'react-redux';

import { CONNECT_STATUS, USER_ROLE } from 'utils/constants';
import BillingInformation from '../../Operator_Detail/RestaurantInfo/BillingInformation';

const { Text } = Typography;

const RestaurantInfo = ({ restaurantDetail = {} }) => {
  const {
    postalCode,
    prefecture,
    address,
    buildingName,
    deliveryAddress,
    deliveryPostalCode,
    deliveryPrefecture,
    deliveryBuildingName,
    phone,
    fax,
    deliveryPhone,
    deliveryFax,
    supplierRestaurant,
  } = restaurantDetail;

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const statusLog = _get(supplierRestaurant, 'statusLog', {});
  const inviteDate = statusLog[CONNECT_STATUS.PENDING];
  const connectDate = statusLog[CONNECT_STATUS.ACCEPTED];
  const renderDate = (date, format = 'YYYY/MM/DD HH:mm') => (date ? moment(date).format(format) : '');

  const DATE = {
    [USER_ROLE.BRAND_OWNER]: {
      更新日: renderDate(restaurantDetail.updatedAt),
      作成日: renderDate(restaurantDetail.createdAt),
    },
    [USER_ROLE.SUPPLIER]: {
      招待日: renderDate(inviteDate),
      承認日: renderDate(connectDate),
    },
  };

  const renderAddressDescription = (
    _postalCode,
    _prefecture,
    _address,
    _buildingName,
    _phone,
    _fax
  ) => (
    <Descriptions.Item>
      <div style={{ marginLeft: -8 }}>
        <div>{_postalCode}</div>
        <div>
          {_prefecture} {_address} {_buildingName}
        </div>
        <div>店舗電話番号: {_phone}</div>
        <div>店舗FAX番号: {_fax}</div>
      </div>
    </Descriptions.Item>
  );

  return (
    <Card className="bg-transparent restaurant-info" bordered={false}>
      <Row gutter={[16, 16]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Card className="highlight-card" title="店舗情報" bordered={false}>
            <Descriptions
              title="Business information"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Descriptions.Item label="店舗名">
                <div>
                  <div>{_get(restaurantDetail, 'name', '-')}</div>
                  <Text style={{ fontSize: 12 }}>
                    {_get(restaurantDetail, 'nameKana')}
                  </Text>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="事業">
                <div>{_get(restaurantDetail, 'restaurantBusiness', '-')}</div>
              </Descriptions.Item>
              <Descriptions.Item label="業態">
                <div>{_get(restaurantDetail, 'restaurantType', '-')}</div>
              </Descriptions.Item>
              <Descriptions.Item label="業種">
                <div>{_get(restaurantDetail, 'restaurantIndustry', '-')}</div>
              </Descriptions.Item>
              <Descriptions.Item label="事業所区分">
                <div>{_get(restaurantDetail, 'businessType', '-')}</div>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions
              title="店舗の住所"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              {renderAddressDescription(
                postalCode,
                prefecture,
                address,
                buildingName,
                phone,
                fax
              )}
            </Descriptions>
            <Divider />
            <Descriptions
              title="担当者の連絡先"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Descriptions.Item label="担当者">
                <div>
                  <div>{_get(restaurantDetail, 'managerName')}</div>
                  <Text style={{ fontSize: 12 }}>
                    {_get(restaurantDetail, 'managerNameKana')}
                  </Text>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="担当者緊急連絡先">
                {_get(restaurantDetail, 'emergencyPhone')}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Space direction="vertical" size={16} className="full-w flex">
            <Card
              className="highlight-card"
              title="納品場所の住所"
              bordered={false}
            >
              <Descriptions
                title="配送に関する情報"
                column={1}
                bordered
                labelStyle={{ verticalAlign: 'top' }}
              >
                <Descriptions.Item label="">
                  {renderAddressDescription(
                    deliveryPostalCode,
                    deliveryPrefecture,
                    deliveryAddress,
                    deliveryBuildingName,
                    deliveryPhone,
                    deliveryFax
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <Descriptions
                title="追加情報"
                column={1}
                bordered
                labelStyle={{ verticalAlign: 'top' }}
              >
                <Descriptions.Item label="納品場所補足情報">
                  {_get(restaurantDetail, 'deliveryNote')}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <BillingInformation restaurantDetail={restaurantDetail} />
            <Card className="highlight-card" title="変更履歴" bordered={false}>
              <Descriptions
                column={1}
                bordered
                labelStyle={{ verticalAlign: 'top' }}
              >
                {_map(DATE[currentUser.role], (value, key) => (
                  <Descriptions.Item label={key}>{value}</Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

RestaurantInfo.propTypes = {
  restaurantDetail: PropTypes.object,
};

export default RestaurantInfo;
