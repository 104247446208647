import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Avatar,
  AutoComplete,
  Select,
  Tooltip,
  Typography,
  Space,
} from 'antd';

import { getBrandListRequest } from 'providers/BrandProvider/actions';

import DefaultBrandImage from 'images/defaultBrand.svg';

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

const ProductListSearchForm = ({ filterControl, onFilterChange }) => {
  const { brandId } = filterControl;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { list: brandList } = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getBrandListRequest({ limit: 10000 }));
  }, []);

  const renderItem = (option) => (
    <Option
      key={option.objectId}
      value={option.objectId}
      label={(
        <Space className="flex items-center">
          <Avatar
            alt="avatar"
            src={option.image || <img alt="default" src={DefaultBrandImage} />}
            size={16}
          />
          <Typography.Text>{option.name}</Typography.Text>
        </Space>
      )}
    >
      <Card
        bordered={false}
        className="bg-transparent"
        bodyStyle={{ padding: '4px 3px' }}
      >
        <div className="full-w flex justify-between items-center">
          <Meta
            style={{
              overflow: 'hidden',
              width: '100%',
            }}
            avatar={(
              <Avatar
                src={
                  option.image || <img alt="default" src={DefaultBrandImage} />
                }
              />
            )}
            title={(
              <Tooltip placement="topLeft" title={option.name}>
                {option.name}
              </Tooltip>
            )}
            description={(
              <Tooltip placement="topLeft" title={option.email}>
                <Text type="secondary" ellipsis>
                  {option.email}
                </Text>
              </Tooltip>
            )}
          />
        </div>
      </Card>
    </Option>
  );

  const getBrandListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(getBrandListRequest({ ...data, limit: 10000 })),
      500
    ),
    []
  );

  const onSearchBrandList = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    getBrandListWithDelay(data);
  };

  const onFilter = (_, values) => {
    onFilterChange(values);
  };

  return (
    <Form
      layout="inline"
      initialValues={{
        brandId,
      }}
      onValuesChange={onFilter}
      form={form}
    >
      <Form.Item name="brandId">
        <Select
          allowClear
          showSearch
          filterOption={false}
          style={{ width: '250px' }}
          placeholder="ブランド すべて"
          onSearch={onSearchBrandList}
          onClear={() => onSearchBrandList('')}
          optionLabelProp="label"
        >
          {_map(brandList, (o) => renderItem(o))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ProductListSearchForm.propTypes = {
  filterControl: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default ProductListSearchForm;
