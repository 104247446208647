import produce from 'immer';

import {
  GET_PREFECTURE_LIST_REQUEST,
  GET_PREFECTURE_LIST_SUCCESS,
  GET_PREFECTURE_LIST_ERROR,
  GET_PARSE_CONFIG_SUCCESS,
  GET_ALLERGY_LIST_SUCCESS,
  GET_ALLERGY_LIST_ERROR,
} from './constants';

const initialState = {
  loading: false,
  error: false,
  prefectureList: [],
  parseConfig: {},
  allergyList: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_PREFECTURE_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      break;

    case GET_PREFECTURE_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.prefectureList = action.data;
      break;

    case GET_PREFECTURE_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      state.prefectureList = [];
      break;

    case GET_PARSE_CONFIG_SUCCESS:
      state.parseConfig = action.data;
      break;

    case GET_ALLERGY_LIST_SUCCESS:
      state.allergyList = action.payload;
      break;

    case GET_ALLERGY_LIST_ERROR:
      state.allergyList = {};
      break;

    default:
  }
  return state;
});

export default reducer;
