import {
  ERROR_MESSAGE,
  EFFECTIVE_DATE,
  EXPIRED_DATE,
} from 'utils/constants';
import validator from 'validator';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];

const validate = (values) => {
  const errors = {};
  const isEffectiveDateSchedule = values.effectiveDate === EFFECTIVE_DATE.SCHEDULE;
  const isExpiredDateSchedule = values.expiredDate === EXPIRED_DATE.SCHEDULE;

  if (values.file) {
    if (![...acceptedFileExtensions, 'application/pdf'].includes(values.file.type)) {
      errors.file = 'ファイル拡張子が正しくありません';
    }
    if (values.file.size > 50e6) {
      // Limit size to 50MB
      errors.file = `${50e6 / 1e6}MB以下のファイルを指定してください`;
    }
  }

  if (!_isEmpty(values.images)) {
    if (values.images.length > 1) {
      errors.images = ERROR_MESSAGE.INVALID;
    }
    _forEach(values.images, (image) => {
      if (image.originFileObj) {
        if (!acceptedFileExtensions.includes(image.originFileObj.type)) {
          errors.images = 'ファイル拡張子が正しくありません';
        }
        if (image.originFileObj.size > 5e6) {
          // Limit size to 5MB
          errors.images = `${5e6 / 1e6}MB以下のファイルを指定してください`;
        }
      }
    });
  }

  if (values.targetUrl && !validator.isURL(values.targetUrl)) {
    errors.targetUrl = ERROR_MESSAGE.INVALID;
  }
  if (values.schedule && !values.announceTime) {
    errors.announceTime = ERROR_MESSAGE.REQUIRED;
  }

  if (isEffectiveDateSchedule && !values.startDate) {
    errors.startDate = ERROR_MESSAGE.REQUIRED;
  }

  if (isExpiredDateSchedule && !values.endDate) {
    errors.endDate = ERROR_MESSAGE.REQUIRED;
  }

  if (values.startDate && values.endDate && isEffectiveDateSchedule && isExpiredDateSchedule && values.startDate > values.endDate) {
    errors.endDate = ERROR_MESSAGE.INVALID;
  }

  return errors;
};

export default validate;
