import React from 'react';
import _get from 'lodash/get';
import {
  Space, Typography, Avatar, Tooltip
} from 'antd';

import Helpers from 'utils/helpers';

import DefaultBrandImage from 'images/defaultBrand.svg';
import DefaultProductImage from 'images/defaultProduct.svg';
import MarketPriceIcon from 'images/icon-market-price.svg';
import PackagePriceIcon from 'images/icon-package-price.svg';

const Columns = {};

Columns.name = {
  title: '商品名',
  width: 220,
  dataIndex: 'menu',
  fixed: 'left',
  render: (record) => (
    <div className="flex items-center full-w">
      <Avatar
        alt="name"
        src={_get(record, 'images[0]', DefaultProductImage)}
        shape="square"
        style={{ marginRight: 8 }}
      />
      <Space
        direction="vertical"
        size={0}
        className="full-w"
        style={{ overflow: 'hidden' }}
      >
        <Typography.Text>{_get(record, 'name')}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {_get(record, 'standard', '-')} • {_get(record, 'amount', '-')} ／{' '}
          {_get(record, 'unit', '-')}
        </Typography.Text>
      </Space>
    </div>
  ),
};

Columns.priceBeforeTax = {
  title: '単価',
  width: 130,
  dataIndex: ['menuPrice', 'priceBeforeTax'],
  key: 'priceBeforeTax',
  render: (priceBeforeTax) => `¥${Helpers.numberWithCommas(priceBeforeTax)}`,
};

Columns.leadTime = {
  title: 'リードタイム',
  width: 120,
  dataIndex: ['menu', 'leadTime'],
  key: 'leadTime',
  render: (leadTime) => `${Helpers.numberWithCommas(leadTime)}日`,
};

Columns.brand = {
  title: 'ブランド',
  width: 220,
  dataIndex: ['menu', 'brand'],
  render: (brand) => (
    <Space className="flex items-center">
      <Avatar alt="name" src={_get(brand, 'image', DefaultBrandImage)} />
      <Space direction="vertical" size={0}>
        <Typography.Text style={{ wordBreak: 'break-word' }}>
          {_get(brand, 'name', '-')}
        </Typography.Text>
        <Typography.Text
          type="secondary"
          style={{ fontSize: 12, wordBreak: 'break-word' }}
        >
          {_get(brand, 'email', '-')}
        </Typography.Text>
      </Space>
    </Space>
  ),
};

Columns.priceAfterTax = {
  title: '税込',
  width: 130,
  key: 'priceAfterTax',
  align: 'right',
  render: (record) => (
    <Space direction="vertical" size={0}>
      <Space>
        {record?.menu?.isMarketPrice && (
          <Tooltip placement="topLeft" title="時価">
            <img src={MarketPriceIcon} alt="market-price-icon" />
          </Tooltip>
        )}
        {record?.menu?.isIndefinitePrice && (
          <Tooltip placement="topLeft" title="不定貫">
            <img src={PackagePriceIcon} alt="indefinite-price-icon" />
          </Tooltip>
        )}
        <Typography.Text>
          ¥{Helpers.numberWithCommas(_get(record, 'menuPrice.priceAfterTax'))}
        </Typography.Text>
      </Space>
    </Space>
  ),
};

Columns.action = {
  title: '操作',
  width: 115,
  key: 'action',
  fixed: 'right',
};

export default Columns;
