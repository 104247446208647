/* eslint-disable camelcase */
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import React from 'react';
import {
  Space, Typography, Tooltip, Avatar
} from 'antd';
import moment from 'moment';

import Helpers from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';

import DefaultProductImage from 'images/defaultProduct.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import MarketPriceIcon from 'images/icon-market-price.svg';
import PackagePriceIcon from 'images/icon-package-price.svg';

const Columns = {};
const { Text } = Typography;

Columns.ID = {
  title: 'ID',
  width: 110,
  dataIndex: 'formattedObjectId',
  key: 'formattedObjectId',
  sorter: true,
  fixed: 'left',
};

Columns.productName = {
  title: '商品名',
  width: 220,
  render: (record) => (
    <div className="flex items-center full-w">
      <Avatar
        alt="product-name"
        src={_get(record.images[0], 'url', DefaultProductImage)}
        size={32}
        shape="square"
      />
      <div
        className="flex direction-column restaurant-info-wrapper"
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Text ellipsis>{_get(record, 'name')}</Text>
        </Tooltip>
        <Text type="secondary" ellipsis style={{ fontSize: 12 }}>
          {_get(record, 'standard', '-')} • {_get(record, 'amount', '-')} ／{' '}
          {_get(record, 'unit', '-')}
        </Text>
      </div>
    </div>
  ),
};

Columns.supplierName = {
  title: '販売者 ',
  width: 220,
  dataIndex: 'supplier',
  render: (record) => (
    <div className="flex items-center full-w">
      <Avatar
        alt="supplier-name"
        src={_get(record, 'image', DefaultSupplierImage)}
        size={32}
        shape="circle"
      />
      <Space
        direction="vertical"
        size={0}
        style={{
          overflow: 'hidden', width: '70%', flex: 1, marginLeft: 4
        }}
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Text ellipsis>{_get(record, 'name')}</Text>
        </Tooltip>
        <Tooltip placement="topLeft" title={_get(record, 'email')}>
          <Text
            type="secondary"
            style={{ fontSize: 12 }}
            ellipsis
            copyable={
              !!_get(record, 'email') && {
                tooltips: ['コピー', 'コピーしました'],
              }
            }
          >
            {_get(record, 'email', '-')}
          </Text>
        </Tooltip>
      </Space>
    </div>
  ),
};

Columns.leadTime = {
  title: 'リードタイム',
  dataIndex: 'leadTime',
  width: 120,
  key: 'leadTime',
  render: (record) => `${Helpers.numberWithCommas(record)}日`,
};

Columns.brand = {
  title: 'ブランド',
  dataIndex: 'brand',
  width: 150,
  key: 'brand',
  render: (record) => !_isEmpty(record) && (
    <div className="flex items-center full-w">
      <Avatar
        alt="brand-name"
        size={32}
        src={_get(record, 'image', DefaultBrandImage)}
      />
      <Text
        style={{
          overflow: 'hidden', width: '70%', flex: 1, marginLeft: 4
        }}
        ellipsis={{
          tooltip: _get(record, 'name'),
        }}
      >{_get(record, 'name')}
      </Text>
    </div>
  ),
};

Columns.prices = {
  title: '単価（税込）',
  key: 'prices',
  align: 'right',
  width: 130,
  render: (record) => {
    const masterPrice = _find(record.prices, (o) => o.type === 'MASTER');
    const isSelected = record?.discountPrice?.isSelected;
    return (
      <Space direction="vertical" size={0}>
        <Space>
          {record.isMarketPrice && (
            <Tooltip placement="topLeft" title="時価">
              <img src={MarketPriceIcon} alt="market-price-icon" />
            </Tooltip>
          )}
          {record.isIndefinitePrice && (
            <Tooltip placement="topLeft" title="不定貫">
              <img src={PackagePriceIcon} alt="indefinite-price-icon" />
            </Tooltip>
          )}
          <Text className="price">
            ¥{Helpers.numberWithCommas(isSelected ? record?.discountPrice?.priceAfterTax : masterPrice.priceAfterTax)}
          </Text>
        </Space>
        {isSelected && (
          <Text
            className="original-price"
          >
            ¥{Helpers.numberWithCommas(masterPrice.priceAfterTax)}
          </Text>
        )}
        {record.prices.length > 1 && (
          <Text type="secondary" style={{ fontSize: 12 }}>
            + {record.prices.length - 1} other prices
          </Text>
        )}
      </Space>
    );
  },
};

Columns.brandFee = {
  title: 'ブランドフィー',
  dataIndex: 'brandFee',
  key: 'brandFee',
  align: 'right',
  width: 150,
  render: (record) => record && `¥${Helpers.numberWithCommas(record)}`,
};

Columns.freeCode = {
  title: '自社コード',
  dataIndex: 'freeCode',
  key: 'freeCode',
  align: 'right',
  width: 100,
};

Columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  fixed: 'right',
  width: 120,
  render: (record) => (
    <Space className="status-container">
      <div className={record === 'ACTIVE' ? 'active' : ''}>
        <Text>
          {record === 'ACTIVE' ? '有効' : '無効'}
        </Text>
      </div>
    </Space>
  ),
};

Columns.inventory = {
  title: '在庫状況',
  dataIndex: 'warehouseMenus',
  key: 'inventory',
  fixed: 'right',
  width: 200,
  render: (record = []) => {
    const { stock_isSoldOut, stock_arrivalDate } = record[0] || {};

    return (
      <div className="inventory-container">
        <div className="status-wrapper">
          <div className={`status ${stock_isSoldOut ? 'sold-out' : ''}`}>
            {stock_isSoldOut ? '売り切れ' : '在庫あり'}
          </div>
        </div>
        {stock_isSoldOut && stock_arrivalDate && (
          <div
            style={{
              marginLeft: 18,
            }}
          >
            入荷予定日{moment(stock_arrivalDate).format(DATE_FORMAT)}
          </div>
        )}
      </div>
    );
  },
};

Columns.commerceRobo = {
  title: 'コマースロボ',
  dataIndex: 'warehouseMenus',
  key: 'airLogi_syncStatus',
  width: 125,
  render: (warehouseMenus, rowRecord) => {
    const status = Helpers.getSyncStatus(warehouseMenus);

    if (rowRecord?.warehouse_airLogiIntegrated) {
      return (
        <div className="inventory-container">
          <div className="status-wrapper">
            <div className={`status ${status?.className}`}>{status?.label}</div>
          </div>
        </div>
      );
    }

    return null;
  },
};

Columns.genres = {
  title: 'ジャンル',
  dataIndex: 'genres',
  key: 'genres',
  width: 150,
  render: (record) => (
    <div className="flex items-center full-w">
      {record && record.map((genre) => genre.name).join(', ')}
    </div>
  ),
};

Columns.order = {
  title: '表示優先度',
  dataIndex: 'order',
  key: 'order',
  sorter: true,
  width: 120,
  render: (record) => record && Helpers.numberWithCommas(record),
};

export default Columns;
