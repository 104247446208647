/* eslint-disable camelcase */
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import React, { useEffect, useCallback, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card, Space, Row, Col, Typography
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import Helpers from 'utils/helpers';
import { USER_ROLE, STATUS } from 'utils/constants';
import {
  TextField,
  SelectField,
  InputNumber,
  RadioGroup,
  ImageField,
  CheckboxGroup,
  MultipleSelectionWithRemoteSearch,
  CheckboxField,
} from 'components/Form';

import { getCategoryListRequest } from 'providers/CategoryProvider/actions';
import { getUnitListRequest } from 'providers/MenuProvider/actions';
import { getGenreListRequest } from 'providers/GenreProvider/actions';
import { getBrandListRequest } from 'providers/BrandProvider/actions';
import { getManufacturerListRequest } from 'providers/ManufacturerProvider/actions';
import { getCollectionListRequest } from 'providers/CollectionProvider/actions';
import { getAllergyListRequest } from 'providers/PrefectureProvider/actions';

import RelatedProductsField from './RelatedProductsField';
import PriceField from './PriceField';
import validate from './validate';

import './style.less';
import ProductOrganization from './ProductOrganization';
import TagField from './TagField';
import SnsField from './SnsField';
import AllergyField from './AllergyField';

const { Text } = Typography;
const ProductForm = ({
  handleSubmit,
  change,
  isCreateForm,
  disabled = false,
  menuItemDetail,
}) => {
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const {
    brand,
    category,
    isBasic,
    warehouse_airLogiIntegrated,
    warehouse_directShipment,
    images
  } = useSelector(
    (state) => _get(state.form, 'productCreateForm.values', {}),
    shallowEqual
  );
  const categories = useSelector(
    (state) => state.categoryProvider.categoryList,
    shallowEqual
  );
  const subcategories = useSelector(
    (state) => state.categoryProvider.subcategoryMap,
    shallowEqual
  )[category];
  const genresList = useSelector(
    (state) => state.genreProvider.genreList,
    shallowEqual
  );
  const unitsList = useSelector(
    (state) => state.menuProvider.unitList,
    shallowEqual
  );
  const allergies = useSelector(
    (state) => state.prefectureProvider.allergyList,
    shallowEqual
  );
  const brandsList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );
  const manufacturerList = useSelector(
    (state) => state.manufacturerProvider.manufacturerList,
    shallowEqual
  ).map((option) => ({
    objectId: option.objectId,
    name: option.name,
    extraText: option.status === STATUS.INACTIVE && '無効',
    disabled: option.status === STATUS.INACTIVE,
  }));
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const { list: collectionList } = useSelector(
    (state) => state.collectionProvider.collectionList,
    shallowEqual
  );

  const { relatedProductIds } = useSelector(
    (state) => _get(state.form, 'productCreateForm.values', {}),
    shallowEqual
  );

  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';
  const isOperator = _get(currentUser, 'role') === USER_ROLE.OPERATOR;

  useEffect(() => {
    dispatch(getCategoryListRequest());
    dispatch(getUnitListRequest());
    dispatch(getAllergyListRequest());
    dispatch(getGenreListRequest({ selectFields: ['name', 'objectId'] }));
    dispatch(getBrandListRequest({ page: 1, limit: 1000 }));
    dispatch(
      getManufacturerListRequest({
        page: 1,
        limit: 1000,
      })
    );
    if (isCreateForm) {
      dispatch(getCollectionListRequest({ page: 1, limit: 1000 }));
    }
  }, []);

  const getDataWithDelay = useCallback(
    _debounce(
      (data, callback) => dispatch(
        callback({
          ...data,
          limit: 10000,
        })
      ),
      500
    ),
    []
  );

  const onSearchByKeyWork = (value, callback) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    setIsSearch(true);
    getDataWithDelay(data, callback);
  };

  const onSearchCollection = (value) => onSearchByKeyWork(value, getCollectionListRequest);
  const onSearchBrand = (value) => onSearchByKeyWork(value, getBrandListRequest);
  const onSearchManufacturer = (value) => onSearchByKeyWork(value, getManufacturerListRequest);
  const onBrandFieldChange = () => {
    if (isSearch) {
      dispatch(getBrandListRequest({ page: 1, limit: 1000 }));
      setIsSearch(false);
    }
  };

  const onManufacturerFieldChange = () => {
    if (isSearch) {
      dispatch(
        getManufacturerListRequest({
          page: 1,
          limit: 1000,
        })
      );
      setIsSearch(false);
    }
  };

  return (
    <Form
      id="product-create-form"
      onSubmit={handleSubmit}
      labelCol={{ span: 7 }}
      colon={false}
      layout="horizontal"
    >
      <Space direction="vertical" size={16} className="full-w flex">
        <Card
          headStyle={{
            fontSize: 20,
            fontWeight: 'bold',
          }}
          className="highlight-card"
          title="商品の追加"
          bordered={false}
        >
          {(isShikomelSupplier || isOperator) && (
            <Row gutter={32}>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <div className="basic-field">
                  <Field
                    name="isBasic"
                    label="商品タイプ"
                    component={RadioGroup}
                    isButton
                    requiredSpace
                    options={[
                      {
                        objectId: false,
                        name: (
                          <Text
                            ellipsis={{
                              tooltip: 'マイストア商品',
                            }}
                          >
                            マイストア商品
                          </Text>
                        ),
                      },
                      {
                        objectId: true,
                        name: (
                          <Text
                            ellipsis={{
                              tooltip: 'シコメルストア商品',
                            }}
                          >
                            シコメルストア商品
                          </Text>
                        ),
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={32}>
            <Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
              {(isShikomelSupplier || isOperator) && isBasic && (
                <div className="basic-field">
                  <Field
                    name="membership_tier"
                    label="対象プラン"
                    component={RadioGroup}
                    isButton
                    requiredSpace
                    options={[
                      {
                        objectId: 1,
                        name: (
                          <Text
                            ellipsis={{
                              tooltip: 'ベーシックプラン',
                            }}
                          >
                            ベーシックプラン
                          </Text>
                        ),
                      },
                      {
                        objectId: 2,
                        name: (
                          <Text
                            ellipsis={{
                              tooltip: 'プレミアムプラン',
                            }}
                          >
                            プレミアムプラン
                          </Text>
                        ),
                      },
                    ]}
                  />
                </div>
              )}
              <Field
                label="アイテム名"
                name="name"
                maxLength={50}
                component={TextField}
                required
              />
              <Field
                label="アイテム名"
                description="フリガナ"
                name="nameKatakana"
                maxLength={50}
                component={TextField}
                required
              />
              <Form labelCol={{ span: 11 }} colon={false}>
                <Row gutter={4}>
                  <Col span={15}>
                    <Field
                      label="カテゴリー"
                      name="category"
                      placeholder="カテゴリー"
                      component={SelectField}
                      options={categories || []}
                      loading={_isEmpty(categories)}
                      onFormValueChange={() => change('subcategory', '')}
                    />
                  </Col>
                  <Col span={1} className="flex justify-center">
                    <ArrowRightOutlined
                      style={{ lineHeight: '32px', fontSize: 16 }}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      name="subcategory"
                      placeholder="サブカテゴリー"
                      disabled={_isEmpty(subcategories)}
                      component={SelectField}
                      options={subcategories || []}
                      required
                    />
                  </Col>
                </Row>
              </Form>
              {isBasic && (
                <Field
                  label="ジャンル"
                  name="genres"
                  component={CheckboxGroup}
                  colSpan={12}
                  options={_map(genresList, (o) => ({
                    label: o.name,
                    value: o.objectId,
                  }))}
                />
              )}
              {(isShikomelSupplier || isOperator) && isBasic && <TagField />}
              <Field
                label="ブランド"
                name="brand"
                placeholder="なし"
                component={SelectField}
                requiredSpace
                options={_map(_get(brandsList, 'list', []), (o) => ({
                  objectId: _get(o, 'objectId'),
                  name: _get(o, 'name'),
                  avatar: _get(o, 'image'),
                }))}
                showSearch
                filterOption={false}
                onSearch={onSearchBrand}
                onFormValueChange={onBrandFieldChange}
              />
              {brand && (
                <Field
                  label="ブランドフィー"
                  name="brandFee"
                  component={InputNumber}
                  min={0}
                  precision={0}
                  formatter={(value) => Helpers.numberWithCommas(value)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  addonBefore="¥"
                  required
                />
              )}
              <Field
                name="note"
                label="社内メモ"
                textArea
                maxLength={500}
                component={TextField}
                requiredSpace
              />
              <Form labelCol={{ span: 14 }} colon={false}>
                <Row gutter={4}>
                  <Col span={12}>
                    <Field
                      label="単位"
                      description="規格 • 入数 ／ 単位"
                      placeholder="10個入 "
                      name="standard"
                      maxLength={30}
                      component={TextField}
                    />
                  </Col>
                  <Col span={1} style={{ textAlign: 'center' }}>
                    <div style={{ lineHeight: '32px' }}>•</div>
                  </Col>
                  <Col span={4}>
                    <Field
                      name="amount"
                      placeholder="20"
                      maxLength={30}
                      component={TextField}
                    />
                  </Col>
                  <Col span={1} style={{ textAlign: 'center' }}>
                    <div style={{ lineHeight: '32px' }}>／</div>
                  </Col>
                  <Col span={6}>
                    <Field
                      name="unit"
                      placeholder="C/S"
                      component={SelectField}
                      loading={_isEmpty(unitsList)}
                      options={_map(unitsList, (i) => ({
                        objectId: i,
                        name: i,
                      }))}
                      required
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col lg={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
              {(isShikomelSupplier || isOperator) && isBasic && (
                <Field
                  label="Display number"
                  name="order"
                  min={1}
                  max={9999}
                  precision={0}
                  formatter={(value) => Helpers.numberWithCommas(value)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  component={InputNumber}
                  required
                />
              )}
              <Field
                label="商品画像"
                description="任意"
                additionalContent={(
                  <span style={{ lineHeight: 'normal', display: 'block' }}>
                    <Text type="secondary">最大8枚の画像が設定可能です。</Text>
                    <br />
                    <Text type="secondary">
                      解像度が1080px以上の画像を設定してください。
                    </Text>
                    <span id="isAllowDownloadImageField">
                      <Field
                        name="isAllowDownloadImage"
                        label="レストランが商品写真をダウンロードできるようにする。"
                        component={CheckboxField}
                        disabled={_isEmpty(images)}
                      />
                    </span>
                  </span>
                )}
                name="images"
                component={ImageField}
                maxCount={8}
                withCrop
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
              <Field
                label="製造会社"
                name="manufacturer"
                component={SelectField}
                options={manufacturerList}
                required={
                  warehouse_directShipment && !warehouse_airLogiIntegrated
                }
                requiredSpace={
                  !warehouse_directShipment || warehouse_airLogiIntegrated
                }
                showSearch
                filterOption={false}
                onSearch={onSearchManufacturer}
                onFormValueChange={onManufacturerFieldChange}
              />
              <Field
                label="仕入価格・原価"
                name="cost"
                component={InputNumber}
                min={0}
                precision={0}
                formatter={(value) => Helpers.numberWithCommas(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                addonBefore="¥"
                requiredSpace
              />
            </Col>
            <Col lg={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
              <Field
                name="janCode"
                label="JANコード"
                description="任意"
                maxLength={50}
                component={TextField}
                requiredSpace
              />
              <Field
                name="freeCode"
                label="自社コード"
                description="任意"
                maxLength={50}
                component={TextField}
                required={
                  warehouse_airLogiIntegrated || warehouse_directShipment
                }
                requiredSpace={
                  !warehouse_airLogiIntegrated && !warehouse_directShipment
                }
              />
            </Col>
          </Row>
          <PriceField />
        </Card>
        <ProductOrganization
          disabled={disabled}
          menuItemDetail={menuItemDetail}
        />
        <Card
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          className="highlight-card"
          title="商品の納期および発注数量"
          bordered={false}
          id="order-info"
        >
          <Row gutter={32}>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              {!warehouse_airLogiIntegrated && isShikomelSupplier && (
                <Form.Item label="直送品" className="form-item-no-margin">
                  <Field
                    name="warehouse_directShipment"
                    component={CheckboxField}
                  />
                </Form.Item>
              )}
              <Field
                label="リードタイム"
                description="日数"
                name="leadTime"
                min={1}
                max={99}
                precision={0}
                formatter={(value) => Helpers.numberWithCommas(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                component={InputNumber}
                required
              />
              <Field
                label="最小発注単位"
                description="任意"
                name="minLimitOrder"
                min={1}
                precision={0}
                formatter={(value) => Helpers.numberWithCommas(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                component={InputNumber}
                requiredSpace
              />
              <Field
                label="最大発注単位"
                description="任意"
                name="maxLimitOrder"
                min={1}
                precision={0}
                formatter={(value) => Helpers.numberWithCommas(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                component={InputNumber}
                requiredSpace
              />
            </Col>
          </Row>
        </Card>
        <Card
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          className="highlight-card"
          title="商品の追加情報"
          bordered={false}
          id="addition-info"
        >
          <Row gutter={32} justify="space-between">
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Field
                name="storageCondition"
                label="商品紹介"
                description="任意"
                textArea
                maxLength={2000}
                component={TextField}
              />
              <Field
                name="allergen"
                label="原材料・食品添加物"
                description="任意"
                textArea
                maxLength={500}
                component={TextField}
              />
              <Field
                name="mainAllergies"
                label={`アレルギー情報\n[主要${allergies?.MAIN?.length}項目]`}
                description="任意"
                component={AllergyField}
                colSpan={12}
                options={_map(allergies?.MAIN, (o) => ({
                  value: o.objectId,
                  label: o.name,
                  imageUrl: o.imageUrl,
                }))}
              />
              <Field
                name="subAllergies"
                label={`アレルギー情報\n[その他${allergies?.SUB?.length}項目]`}
                description="任意"
                component={AllergyField}
                colSpan={12}
                options={_map(allergies?.SUB, (o) => ({
                  value: o.objectId,
                  label: o.name,
                  imageUrl: o.imageUrl,
                }))}
              />
            </Col>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              {isCreateForm && !isBasic && (
                <Field
                  name="collectionIds"
                  label="セット商品名"
                  placeholder="セット商品名から検索"
                  component={MultipleSelectionWithRemoteSearch}
                  options={collectionList || []}
                  onSearch={onSearchCollection}
                  isShowAvatar={false}
                />
              )}
              <Field
                name="basicPricePerUnit"
                label="一個あたりの単価"
                description="任意"
                maxLength={50}
                component={TextField}
              />
              <Field
                name="basicPricePerMeal"
                label="一食あたりの単価"
                description="任意"
                maxLength={50}
                component={TextField}
              />
              <Field
                name="estimatedOfferPricePerMeal"
                label="一食あたりの想定提供価格"
                description="任意"
                maxLength={2000}
                component={TextField}
              />
              <Field
                name="estimatedGrossProfitPerMeal"
                label="一食あたりの想定粗利"
                description="任意"
                maxLength={2000}
                component={TextField}
              />
              <Field
                name="howToCook"
                label="調理方法"
                description="任意"
                textArea
                maxLength={2000}
                component={TextField}
              />
              <Field
                name="preservationMethod"
                label="保存方法"
                description="任意"
                textArea
                maxLength={2000}
                component={TextField}
              />
              <Field
                name="remarksMemo"
                label="備考メモ"
                description="任意"
                textArea
                maxLength={2000}
                component={TextField}
              />
            </Col>
          </Row>
        </Card>
        {isCreateForm && isBasic && (
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="関連商品"
            bordered={false}
            id="related-products"
          >
            <div style={{ width: 445 }}>
              <RelatedProductsField
                placeholder="販売者名から検索"
                relatedProductIds={relatedProductIds}
              />
            </div>
          </Card>
        )}
        {isShikomelSupplier && <SnsField />}
      </Space>
    </Form>
  );
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  isCreateForm: PropTypes.bool,
  disabled: PropTypes.bool,
  menuItemDetail: PropTypes.object,
};

export default reduxForm({
  form: 'productCreateForm',
  validate,
  touchOnChange: true,
})(ProductForm);
