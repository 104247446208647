import validator from 'validator';

import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.username)) {
    errors.username = '無効なメールアドレスです';
  }
  if (!values.password) {
    errors.password = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Constants.REGEX.PASSWORD.test(values.password)) {
    errors.password = Constants.ERROR_MESSAGE.INVALID_PASSWORD;
  }
  if (!values.passwordConfirm) {
    errors.passwordConfirm = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Constants.REGEX.PASSWORD.test(values.passwordConfirm)) {
    errors.passwordConfirm = Constants.ERROR_MESSAGE.INVALID_PASSWORD;
  }
  if (
    values.password
    && values.passwordConfirm
    && values.passwordConfirm !== values.password
  ) {
    errors.passwordConfirm = 'パスワードが一致しません。';
  }

  return errors;
};

export default validate;
