import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import produce from 'immer';

import {
  COUNT_UNREAD_CONVERSATION_SUCCESS,
  GET_CONVERSATION_LIST_REQUEST,
  GET_CONVERSATION_LIST_SUCCESS,
  GET_CONVERSATION_LIST_ERROR,
  UPDATE_CONVERSATION,
} from './constants';

const initialState = {
  unreadCount: 0,
  loading: false,
  error: false,
  conversationList: [],
  hasMore: true,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case COUNT_UNREAD_CONVERSATION_SUCCESS:
      state.unreadCount = action.data;
      break;

    case GET_CONVERSATION_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      if (action.data.skip === 0) {
        state.conversationList = [];
        state.hasMore = true;
      }
      break;

    case GET_CONVERSATION_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.conversationList.push(...action.data);
      if (_isEmpty(action.data)) {
        state.hasMore = false;
      }
      break;

    case GET_CONVERSATION_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      break;

    case UPDATE_CONVERSATION: {
      // Find conversation in current list
      const index = _findIndex(
        state.conversationList,
        (o) => o.orderId === action.data.orderId
      );

      if (index >= 0) {
        state.conversationList.splice(index, 1);
      }
      state.conversationList.unshift(action.data);
      break;
    }

    default:
  }
  return state;
});

export default reducer;
