import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }

  return errors;
};

export default validate;
