/* eslint-disable camelcase */
import React from 'react';
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Space,
  Descriptions,
  Alert,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Field, getFormValues } from 'redux-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import _find from 'lodash/find';
import _last from 'lodash/last';
import _get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';

import {
  InputNumber,
  DatePickerField,
  SwitchField,
  RadioGroup,
} from 'components/Form';
import Helpers from 'utils/helpers';
import {
  WAREHOUSE_MENU_SYNC_STATUS,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  USER_ROLE,
} from 'utils/constants';
import { doRefresh } from 'providers/CommonProvider/actions';
import InventoryStatus from '../List/InventoryStatus';

const { Item } = Descriptions;

const ProductOrganization = ({ menuItemDetail }) => {
  const { stock_quantity, warehouse_airLogiIntegrated, isInStock } = useSelector((state) => getFormValues('productCreateForm')(state)) || {};
  const { productId } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  let warehouseMenus = menuItemDetail?.warehouseMenus;
  const isShikomelSupplier = currentUser?.supplier?.type === 'SHIKOMEL';
  let status = {
    className: 'in-stock',
    content: 'In stock',
  };

  if (!isInStock) {
    status = {
      className: 'sold-out',
      content: 'Sold out',
    };
  }

  const note = (
    <Typography.Text
      style={{
        lineHeight: 1,
      }}
      type="secondary"
    >
      注文が入ると在庫数が更新されます。
      <br />
      在庫数を管理しない場合は空欄にしてください。
    </Typography.Text>
  );

  let warehouse = [];

  if (_get(warehouseMenus, '[0.airLogi_warehouseKey') === 'OSAKA') {
    warehouseMenus = [...warehouseMenus].reverse();
  }
  warehouse = productId
    && warehouse_airLogiIntegrated
    && warehouseMenus.map((item) => {
      const name = item?.airLogi_warehouseKey === 'ODAWARA' ? '小田原倉庫' : '大阪倉庫';
      const quantity = Helpers.numberWithCommas(item?.stock_quantity);
      const syncStatus = WAREHOUSE_MENU_SYNC_STATUS.find((e) => e.keys.includes(item?.airLogi_syncStatus)
      );
      const syncStatusLog = _last(item?.airLogi_syncStatusLog);
      const lastUpdated = moment(syncStatusLog?.date).format(DATE_TIME_FORMAT);
      const errorMessage = item?.airLogi_errorMessage;
      const type = item?.stock_quantity > 0 ? 'success' : 'danger';

      let expirationDate;
      if (
        !item.stock_isSoldOut
        && item?.stock_quantity
        && item?.stock_quantity > 0
        && item?.airLogi_stock?.expiration_date
      ) {
        expirationDate = (
          <Space direction="vertical" size="small">
            <Typography.Text>
              {moment(item?.airLogi_stock?.expiration_date).format(DATE_FORMAT)}
            </Typography.Text>
            <Typography.Text type="secondary">
              ※倉庫内で最短の賞味期限を表示しています。
            </Typography.Text>
          </Space>
        );
      }

      if (syncStatus) {
        switch (syncStatus.className) {
          case 'synced':
            return {
              ...syncStatus,
              className: `${syncStatus.className} green`,
              productId: item?.productId,
              updatedAt: (
                <Space direction="vertical">
                  <Typography.Text type={type}>{quantity}</Typography.Text>
                  <Typography.Text type="secondary">
                    最終更新日時: {lastUpdated}
                  </Typography.Text>
                </Space>
              ),
              content: (
                <Space direction="vertical">
                  <Typography.Text>同期成功</Typography.Text>
                  <Typography.Text type="secondary">
                    最終更新日時: {lastUpdated}
                  </Typography.Text>
                </Space>
              ),
              name,
              expirationDate,
            };
          case 'sync-error':
            return {
              ...syncStatus,
              className: `${syncStatus.className} red sold-out`,
              productId: item?.productId,
              updatedAt: (
                <Space direction="vertical">
                  <Typography.Text type={type}>{quantity}</Typography.Text>
                  <Typography.Text type="secondary">
                    最終更新日時: {lastUpdated}
                  </Typography.Text>
                </Space>
              ),
              error: errorMessage && (
                <Alert
                  type="error"
                  showIcon
                  message={`${name}からデータを取得中にエラーが発生しました。`}
                  description={errorMessage}
                  icon={<CloseCircleFilled />}
                />
              ),
              content: null,
              name,
              expirationDate,
            };
          default:
            return {
              ...syncStatus,
              className: `${syncStatus.className} orange syncing`,
              name,
              updatedAt: '同期中',
              productId: '同期中',
              content: (
                <Space direction="vertical">
                  <Typography.Text type="secondary">
                    予期せぬエラーを防ぐため同期中は商品情報を編集しないでください。
                  </Typography.Text>
                </Space>
              ),
              expirationDate,
            };
        }
      }
      return {};
    });

  const syncStatus = productId
    && warehouse_airLogiIntegrated
    && _find(warehouse, ['label', Helpers.getSyncStatus(warehouseMenus)?.label]);
  const fetchMenuItemDetail = () => {
    dispatch(doRefresh({ target: 'product-detail' }));
  };
  const isShowAirLogiField = isShikomelSupplier || currentUser?.role === USER_ROLE.OPERATOR;
  return (
    <Card
      headStyle={{ fontSize: 20, fontWeight: 'bold' }}
      className="highlight-card"
      title="商品の管理"
      bordered={false}
      id="productOrganization"
    >
      {isShowAirLogiField && (
        <>
          <Typography.Paragraph strong>エアロジ自動連携</Typography.Paragraph>
          <Row gutter={[16, 16]} justify="space-between">
            <Col
              lg={{ span: 12 }}
              xs={{ span: 24 }}
              className="product-type-field"
            >
              <Field
                name="warehouse_airLogiIntegrated"
                component={RadioGroup}
                label="在庫・注文"
                isButton
                requiredSpace
                options={[
                  {
                    objectId: true,
                    name: (
                      <Typography.Text
                        ellipsis={{
                          tooltip: 'コマースロボで管理',
                        }}
                      >
                        コマースロボで管理
                      </Typography.Text>
                    ),
                  },
                  {
                    objectId: false,
                    name: (
                      <Typography.Text
                        ellipsis={{
                          tooltip: 'シコメルで管理',
                        }}
                      >
                        シコメルで管理
                      </Typography.Text>
                    ),
                  },
                ]}
                note={
                  warehouse_airLogiIntegrated
                    ? 'この商品の在庫と注文はエアロジで管理しています。'
                    : 'この商品の在庫と注文はシコメルで管理しています。'
                }
              />
            </Col>
            {menuItemDetail?.warehouse_airLogiIntegrated
              && warehouse_airLogiIntegrated && (
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item label="同期ステータス">
                  {menuItemDetail?.warehouse_airLogiIntegrated && (
                    <div className="inventory-container">
                      <div className="status-wrapper">
                        <div className={`status ${syncStatus.className}`}>
                          {syncStatus.label}
                        </div>
                        {syncStatus.content}
                      </div>
                    </div>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <Divider />
        </>
      )}
      <Row gutter={[16, 16]} justify="space-between">
        <Col span={24}>
          {isShowAirLogiField && (
            <Typography.Paragraph strong>在庫状況</Typography.Paragraph>
          )}
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          {productId && (
            <InventoryStatus
              menuDetail={menuItemDetail}
              fetchData={fetchMenuItemDetail}
              showInStock={false}
              isMenuDetail
            />
          )}
          {!productId && (
            <div className="solOut-field">
              <Field
                name="isInStock"
                size="small"
                label="ステータス"
                component={SwitchField}
                description={(
                  <Typography.Text className={`status ${status.className}`}>
                    {!isInStock ? '売り切れ' : '在庫あり'}
                  </Typography.Text>
                )}
              />
              <Field
                name="stock_arrivalDate"
                disabledDate={(current) => current && current < moment().endOf('day')}
                component={DatePickerField}
                label="入荷予定日"
                requiredSpace
              />
            </div>
          )}
        </Col>
        {!warehouse_airLogiIntegrated && (
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Field
                name="stock_quantity"
                component={InputNumber}
                label="在庫数"
                placeholder="在庫管理しない"
                note={note}
                min={0}
                precision={0}
                formatter={(value) => Helpers.numberWithCommas(value)}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                className={stock_quantity === 0 ? 'red' : 'green'}
              />
            </div>
          </Col>
        )}
      </Row>
      {menuItemDetail?.warehouse_airLogiIntegrated && warehouse && (
        <>
          <Divider />
          <Row gutter={[16, 16]}>
            {warehouse?.map((item) => (
              <Col lg={{ span: 12 }} xs={{ span: 24 }} key={item.name}>
                <Descriptions
                  title={item.name}
                  colon={false}
                  labelStyle={{ width: 100 }}
                >
                  <Item label="商品ID" span={24}>
                    {_get(item, 'productId', '--')}
                  </Item>
                  <Item label="在庫数" span={24}>
                    {item.updatedAt}
                  </Item>
                  {item.expirationDate && (
                    <Item label="賞味期限" span={24}>
                      {item.expirationDate}
                    </Item>
                  )}
                </Descriptions>
                {item.error && item.error}
              </Col>
            ))}
          </Row>
        </>
      )}
    </Card>
  );
};

ProductOrganization.propTypes = {
  menuItemDetail: PropTypes.object,
};

export default ProductOrganization;
