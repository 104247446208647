import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Space, Row, Col, Typography
} from 'antd';
import PropTypes from 'prop-types';

import DeliveryImage from 'images/icon-delivery.svg';
import HolidayImage from 'images/icon-holiday.svg';
import InvoiceImage from 'images/icon-invoice.svg';
import ProfileImage from 'images/icon-profile.svg';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';
import EmailImage from 'images/icon-email.svg';
import PasswordImage from 'images/icon-password.svg';
import EmailNotification from 'images/icon-email-notification.svg';

import './style.less';
import { USER_ROLE } from 'utils/constants';

const RenderItem = ({
  alt, path, label, content, src, extra
}) => {
  const dispatch = useDispatch();
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      lg={{ span: 8 }}
      className="hover-item"
      onClick={() => dispatch(push(path))}
    >
      <Space className="flex items-start">
        <div className="show-icon">
          <img alt={alt} src={src} className="icon" />
        </div>
        <Space direction="vertical" size={0}>
          <Typography.Text style={{ color: '#1890ff' }}>
            {label}
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {content}
          </Typography.Text>
          {extra}
        </Space>
      </Space>
    </Col>
  );
};

const SettingsPage = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card settings-page" bordered={false}>
        <Row gutter={[16, 24]}>
          <RenderItem
            alt="email-icon"
            path="/settings/changeEmail"
            label="メールアドレス"
            content={currentUser?.email}
            src={EmailImage}
            extra={
              currentUser?.emailVerified ? (
                <Space size={4}>
                  <img alt="verified-icon" src={VerifiedImage} />
                  <Typography.Text type="success" style={{ fontSize: 12 }}>
                    確認済
                  </Typography.Text>
                </Space>
              ) : (
                <Space size={4}>
                  <img alt="warning-icon" src={WarningImage} />
                  <Typography.Text type="warning" style={{ fontSize: 12 }}>
                    確認待ち
                  </Typography.Text>
                </Space>
              )
            }
          />
          <RenderItem
            alt="password-icon"
            path="/settings/changePassword"
            label="パスワード"
            content="パスワード変更"
            src={PasswordImage}
          />
          <RenderItem
            alt="profile-icon"
            path="/settings/profile"
            label="プロフィール設定"
            content="プロフィールの登録・編集が可能です"
            src={ProfileImage}
          />
          {currentUser?.role === USER_ROLE.SUPPLIER && (
            <>
              <RenderItem
                alt="holiday-icon"
                path="/settings/holiday"
                label="営業日・休業日"
                content="休業日の登録・編集はこちら"
                src={HolidayImage}
              />
              <RenderItem
                alt="delivery-icon"
                path="/settings/shippingFee"
                label="送料"
                content="送料・送料無料金額の登録・編集はこちら"
                src={DeliveryImage}
              />
              <RenderItem
                alt="invoice-icon"
                path="/settings/invoice"
                label="請求書"
                content="請求書の設定はこちら"
                src={InvoiceImage}
              />
              <RenderItem
                alt="notification-icon"
                path="/settings/notification"
                label="メール通知"
                content="メール通知設定はこちら"
                src={EmailNotification}
              />
            </>
          )}
        </Row>
      </Card>
    </Card>
  );
};

RenderItem.propTypes = {
  alt: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.string,
  src: PropTypes.string,
  extra: PropTypes.element,
};

export default SettingsPage;
