import { createSelector } from 'reselect';

const selectRestaurantAnnouncement = (state) => state.announcementProvider || {};

const makeSelectRestaurantAnnouncementList = () => createSelector(selectRestaurantAnnouncement, (state) => state.restaurantAnnouncementList);
const makeSelectRestaurantAnnouncementTotal = () => createSelector(selectRestaurantAnnouncement, (state) => state.restaurantAnnouncementTotal);
const makeSelectRestaurantAnnouncementDataLoading = () => createSelector(selectRestaurantAnnouncement, (state) => state.dataLoading);
const makeSelectRestaurantAnnouncementActionLoading = () => createSelector(selectRestaurantAnnouncement, (state) => state.actionLoading);
const makeSelectRestaurantAnnouncementDetail = () => createSelector(selectRestaurantAnnouncement, (state) => state.restaurantAnnouncementDetail);

export {
  makeSelectRestaurantAnnouncementList,
  makeSelectRestaurantAnnouncementTotal,
  makeSelectRestaurantAnnouncementDataLoading,
  makeSelectRestaurantAnnouncementActionLoading,
  makeSelectRestaurantAnnouncementDetail,
};
