export const GET_MENU_ITEM_LIST_REQUEST = 'MenuProvider/GET_MENU_ITEM_LIST_REQUEST';
export const GET_MENU_ITEM_LIST_SUCCESS = 'MenuProvider/GET_MENU_ITEM_LIST_SUCCESS';
export const GET_MENU_ITEM_LIST_ERROR = 'MenuProvider/GET_MENU_ITEM_LIST_ERROR';
export const RESET_MENU_ITEM_LIST = 'MenuProvider/RESET_MENU_ITEM_LIST';

export const PAGING_GET_MENU_ITEM_LIST_REQUEST = 'MenuProvider/PAGING_GET_MENU_ITEM_LIST_REQUEST';
export const PAGING_GET_MENU_ITEM_LIST_SUCCESS = 'MenuProvider/PAGING_GET_MENU_ITEM_LIST_SUCCESS';
export const PAGING_GET_MENU_ITEM_LIST_ERROR = 'MenuProvider/PAGING_GET_MENU_ITEM_LIST_ERROR';

export const GET_MENU_ITEM_DETAIL_REQUEST = 'MenuProvider/GET_MENU_ITEM_DETAIL_REQUEST';
export const GET_MENU_ITEM_DETAIL_SUCCESS = 'MenuProvider/GET_MENU_ITEM_DETAIL_SUCCESS';
export const GET_MENU_ITEM_DETAIL_ERROR = 'MenuProvider/GET_MENU_ITEM_DETAIL_ERROR';

export const CREATE_MENU_ITEM_REQUEST = 'MenuProvider/CREATE_MENU_ITEM_REQUEST';
export const CREATE_MENU_ITEM_SUCCESS = 'MenuProvider/CREATE_MENU_ITEM_SUCCESS';
export const CREATE_MENU_ITEM_ERROR = 'MenuProvider/CREATE_MENU_ITEM_ERROR';

export const UPDATE_MENU_ITEM_REQUEST = 'MenuProvider/UPDATE_MENU_ITEM_REQUEST';
export const UPDATE_MENU_ITEM_SUCCESS = 'MenuProvider/UPDATE_MENU_ITEM_SUCCESS';
export const UPDATE_MENU_ITEM_ERROR = 'MenuProvider/UPDATE_MENU_ITEM_ERROR';

export const GET_UNIT_LIST_REQUEST = 'MenuProvider/GET_UNIT_LIST_REQUEST';
export const GET_UNIT_LIST_SUCCESS = 'MenuProvider/GET_UNIT_LIST_SUCCESS';
export const GET_UNIT_LIST_ERROR = 'MenuProvider/GET_UNIT_LIST_ERROR';

export const CHANGE_MENU_ITEM_STATUS_REQUEST = 'MenuProvider/CHANGE_MENU_ITEM_STATUS_REQUEST';
export const CHANGE_MENU_ITEM_STATUS_SUCCESS = 'MenuProvider/CHANGE_MENU_ITEM_STATUS_SUCCESS';
export const CHANGE_MENU_ITEM_STATUS_ERROR = 'MenuProvider/CHANGE_MENU_ITEM_STATUS_ERROR';

export const CHANGE_SOLD_OUT_STATUS_REQUEST = 'MenuProvider/CHANGE_SOLD_OUT_STATUS_REQUEST';
export const CHANGE_SOLD_OUT_STATUS_SUCCESS = 'MenuProvider/CHANGE_SOLD_OUT_STATUS_SUCCESS';
export const CHANGE_SOLD_OUT_STATUS_ERROR = 'MenuProvider/CHANGE_SOLD_OUT_STATUS_ERROR';

export const EXPORT_MENU_LIST_REQUEST = 'MenuProvider/EXPORT_MENU_LIST_REQUEST';
export const EXPORT_MENU_LIST_SUCCESS = 'MenuProvider/EXPORT_MENU_LIST_SUCCESS';
export const EXPORT_MENU_LIST_ERROR = 'MenuProvider/EXPORT_MENU_LIST_ERROR';

export const REMOVE_PRODUCT_RANKING_REQUEST = 'MenuProvider/REMOVE_PRODUCT_RANKING_REQUEST';
export const REMOVE_PRODUCT_RANKING_SUCCESS = 'MenuProvider/REMOVE_PRODUCT_RANKING_SUCCESS';
export const REMOVE_PRODUCT_RANKING_ERROR = 'MenuProvider/REMOVE_PRODUCT_RANKING_ERROR';

export const REORDER_PRODUCT_RANKING_REQUEST = 'MenuProvider/REORDER_PRODUCT_RANKING_REQUEST';
export const REORDER_PRODUCT_RANKING_SUCCESS = 'MenuProvider/REORDER_PRODUCT_RANKING_SUCCESS';
export const REORDER_PRODUCT_RANKING_ERROR = 'MenuProvider/REORDER_PRODUCT_RANKING_ERROR';

export const ADD_PRODUCT_RANKING_REQUEST = 'MenuProvider/ADD_PRODUCT_RANKING_REQUEST';
export const ADD_PRODUCT_RANKING_SUCCESS = 'MenuProvider/ADD_PRODUCT_RANKING_SUCCESS';
export const ADD_PRODUCT_RANKING_ERROR = 'MenuProvider/ADD_PRODUCT_RANKING_ERROR';
