import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getRestaurantMenuItemRequest,
  removeRestaurantMenuItemRequest,
  assignRestaurantMenuItemRequest,
} from 'providers/RestaurantProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import {
  makeSelectRestaurantDetail,
  makeSelectRestaurantDataLoading,
  makeSelectRestaurantMenuItemList,
  makeSelectRestaurantMenuItemTotal,
} from 'providers/RestaurantProvider/selectors';
import { makeSelectCurrentUser } from 'providers/AuthProvider/selectors';

import InAppConfig from './InAppConfig';

const mapStateToProps = createStructuredSelector({
  restaurantDetail: makeSelectRestaurantDetail(),
  restaurantLoading: makeSelectRestaurantDataLoading(),
  restaurantMenuItemList: makeSelectRestaurantMenuItemList(),
  restaurantMenuItemTotal: makeSelectRestaurantMenuItemTotal(),
  currentUser: makeSelectCurrentUser(),
});

const mapDispatchToProps = (dispatch) => ({
  getRestaurantMenuItemRequest: (value) => dispatch(getRestaurantMenuItemRequest(value)),
  removeRestaurantMenuItemRequest: (value) => dispatch(removeRestaurantMenuItemRequest(value)),
  assignRestaurantMenuItemRequest: (value) => dispatch(assignRestaurantMenuItemRequest(value)),
  doRefresh: (data) => dispatch(doRefresh(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(InAppConfig);
