import _reduce from 'lodash/reduce';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import ExportOrdersForm from './ExportOrdersForm';

const ExportOrdersDialog = ({
  handleCloseDialog,
  exportOrdersRequest,
  visible,
  statusFixed,
  title,
}) => {
  const [exportLoading, setExportLoading] = useState(false);

  const handleCancel = () => {
    handleCloseDialog();
  };

  const handleSubmit = async (value) => {
    setExportLoading(true);

    const { ...statuses } = value;
    const stt = _reduce(
      statuses,
      (array, bool, key) => {
        if (bool === true) {
          array.push(key);
        }

        return array;
      },
      []
    );
    const data = {
      format: 'csv',
    };

    if (stt.length > 0) data.statuses = stt;

    if (value.fixedDate) {
      data.fixedDate = value.fixedDate.toDate();
    }

    try {
      await exportOrdersRequest(data);
    } catch (error) {
      console.error(error);
    }
    setExportLoading(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <ExportOrdersForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        statusFixed={statusFixed}
        loading={exportLoading}
      />
    </Modal>
  );
};

ExportOrdersDialog.propTypes = {
  title: PropTypes.any,
  visible: PropTypes.bool,
  exportOrdersRequest: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  statusFixed: PropTypes.any,
};

export default ExportOrdersDialog;
