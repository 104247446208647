import {
  call, put, takeEvery, takeLatest
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import request from 'utils/request';
import helpers from 'utils/helpers';
import toastMessage from 'utils/toastMessage';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import {
  listenOrderMessageRemove,
  listenWebNotificationRemove,
} from 'providers/LiveQueryProvider/actions';
import {
  getCurrentUserSuccess,
  getCurrentUserError,
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  resetPasswordSuccess,
  resetPasswordError,
  changePasswordSuccess,
  changePasswordError,
  signupSuccess,
  signupError,
  resendVerifyEmailSuccess,
  resendVerifyEmailError,
  clearCurrentUserData,
} from './actions';
import {
  GET_CURRENT_USER_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  SIGNUP_REQUEST,
  RESEND_VERIFY_EMAIL_REQUEST,
  INVALID_SESSION_TOKEN,
} from './constants';

export function* handleGetCurrentUser(payload) {
  const { meta } = payload;
  try {
    if (getAuthToken()) {
      const response = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}getCurrentUser`
      );
      yield put(getCurrentUserSuccess(response.result, meta));
    } else {
      yield put(getCurrentUserSuccess({}, meta));
    }
  } catch (error) {
    yield put(getCurrentUserError(error.error, meta));
    removeAuthToken();
    yield put(push('/auth/login'));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleLogin(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['username', 'password']);
  params.installationId = helpers.getInstallationId();

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}dashboardLogin`,
      params
    );
    setAuthToken(_get(result, 'sessionToken'));
    yield put(loginSuccess(result, meta));
  } catch (error) {
    yield put(loginError(error, meta));
    if (error?.code === 101) {
      toastMessage.error({
        message: 'エラー',
        description: 'メールアドレスもしくはパスワードが間違っています。',
      });
    } else {
      toastMessage.error({
        message: 'エラー',
        description: error.error,
      });
    }
  }
}

export function* handleLogout() {
  try {
    const response = yield call(request, '/logout');
    removeAuthToken();
    yield put(logoutSuccess(response));
    yield put(listenOrderMessageRemove());
    yield put(listenWebNotificationRemove());
    yield put(push('/'));
  } catch (error) {
    yield put(logoutError(error.error));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleResetPassword(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['email']);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}resetPassword`,
      params
    );
    yield put(resetPasswordSuccess(result, meta));
  } catch (error) {
    yield put(resetPasswordError(error, meta));
    if (error.code === 101) {
      toastMessage.error({
        message: 'Account not found',
        description:
          'We cannot find the account match with your email. If you have not registered the account, please sign up',
      });
    } else {
      toastMessage.error({
        message: 'エラー',
        description: error.error,
      });
    }
  }
}

export function* handleChangePassword(action) {
  const { meta, payload } = action;
  const params = _pick(payload, ['oldPassword', 'newPassword']);
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}changePassword`,
      params
    );
    yield put(changePasswordSuccess(response.result, meta));
    removeAuthToken();
  } catch (error) {
    yield put(changePasswordError(error, meta));
    if (error.code === 101) {
      toastMessage.error({
        message: 'エラー',
        description: 'ユーザー名またはパスワードが誤っています。',
      });
    } else {
      toastMessage.error({
        message: 'エラー',
        description: error.error,
      });
    }
  }
}

export function* handleSignup(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['username', 'password']);
  params.installationId = helpers.getInstallationId();

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createDashboardUser`,
      params
    );
    setAuthToken(_get(result, 'sessionToken'));
    yield put(signupSuccess(result, meta));
  } catch (error) {
    yield put(signupError(error, meta));
    if (error?.code === 202) {
      toastMessage.error({
        message: 'エラー',
        description: 'このメールアドレスは既に利用されています。',
      });
    } else {
      toastMessage.error({
        message: 'エラー',
        description: error.error,
      });
    }
  }
}

export function* handleResendVerifyEmail(action) {
  const { meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}resendVerifyEmail`,
      {}
    );
    yield put(resendVerifyEmailSuccess(result, meta));
    toastMessage.success({
      description: '認証メールを再送しました',
    });
  } catch (error) {
    yield put(resendVerifyEmailError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: error.error,
    });
  }
}

export function* handleInvalidSessionToken() {
  yield put(clearCurrentUserData());
  removeAuthToken();
  yield put(push('/auth/login'));
}

export default function* saga() {
  yield takeLatest(GET_CURRENT_USER_REQUEST, handleGetCurrentUser);
  yield takeLatest(LOGIN_REQUEST, handleLogin);
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
  yield takeLatest(RESET_PASSWORD_REQUEST, handleResetPassword);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, handleChangePassword);
  yield takeLatest(SIGNUP_REQUEST, handleSignup);
  yield takeLatest(RESEND_VERIFY_EMAIL_REQUEST, handleResendVerifyEmail);
  yield takeEvery(INVALID_SESSION_TOKEN, handleInvalidSessionToken);
}
