import _get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'antd';

import toastMessage from 'utils/toastMessage';

import {
  assignBrandOwnerToBrandRequest,
  getBrandDetailRequest,
} from 'providers/BrandProvider/actions';

import AddBrandOwnerForm from './AddBrandOwnerForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const AddBrandOwner = ({ handleCloseDialog, visible, brandId }) => {
  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );

  const brandName = _get(brandDetail, 'name');

  const handleCancel = () => {
    handleCloseDialog();
  };

  const dispatch = useDispatch();

  const [createActionLoading, setCreateActionLoading] = useState(false);

  const handleSubmit = async (value) => {
    setCreateActionLoading(true);
    try {
      await dispatch(assignBrandOwnerToBrandRequest({ ...value, brandId }));
      handleCloseDialog();
      dispatch(getBrandDetailRequest({ objectId: brandId }));
      toastMessage.success({
        message: '成功',
        description: `件の販売者をブランド${brandName} に追加しました。`,
      });
    } catch (error) {
      console.error(error);
    }
    setCreateActionLoading(false);
  };

  return (
    <Modal
      title={`${brandName} にブランドオーナーを追加`}
      visible={visible}
      onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
      width={578}
    >
      <p>
        ブランドを管理するブランドオーナーを追加します。 <br></br>
        複数のメールアドレスを一括で入力することも可能です。 <br></br>
        ブランドオーナーは管理しているブランドの情報を閲覧できるようになります。
      </p>
      <AddBrandOwnerForm
        onSubmit={handleSubmit}
        onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
        createActionLoading={createActionLoading}
        brandId={brandId}
      />
    </Modal>
  );
};

AddBrandOwner.propTypes = {
  handleCloseDialog: PropTypes.func,
  visible: PropTypes.bool,
  brandId: PropTypes.any,
};

export default AddBrandOwner;
