import { call, put, takeLeading } from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getPrefectureListSuccess,
  getPrefectureListError,
  getParseConfigSuccess,
  getParseConfigError,
  getAllergyListSuccess,
  getAllergyListError,
} from './actions';
import {
  GET_PREFECTURE_LIST_REQUEST,
  GET_PARSE_CONFIG_REQUEST,
  GET_ALLERGY_LIST_REQUEST,
} from './constants';

export function* handleGetPrefectureList() {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getPrefectureList`,
      {}
    );
    yield put(getPrefectureListSuccess(response.result));
  } catch (error) {
    yield put(getPrefectureListError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetParseConfig() {
  try {
    const response = yield call(request, 'config', {}, {}, 'GET');
    yield put(getParseConfigSuccess(response));
  } catch (error) {
    yield put(getParseConfigError(error));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetAllergyList(action) {
  const { meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getAllergyList`
    );
    yield put(getAllergyListSuccess(result, meta));
  } catch (error) {
    yield put(getAllergyListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(GET_PREFECTURE_LIST_REQUEST, handleGetPrefectureList);
  yield takeLeading(GET_PARSE_CONFIG_REQUEST, handleGetParseConfig);
  yield takeLeading(GET_ALLERGY_LIST_REQUEST, handleGetAllergyList);
}
