export const CHANGE_PRIMARY_EMAIL_REQUEST = 'InvitationEmailProvider/CHANGE_PRIMARY_EMAIL_REQUEST';
export const CHANGE_PRIMARY_EMAIL_SUCCESS = 'InvitationEmailProvider/CHANGE_PRIMARY_EMAIL_SUCCESS';
export const CHANGE_PRIMARY_EMAIL_ERROR = 'InvitationEmailProvider/CHANGE_PRIMARY_EMAIL_ERROR';

export const GET_INVITATION_EMAILS_REQUEST = 'InvitationEmailProvider/GET_INVITATION_EMAILS_REQUEST';
export const GET_INVITATION_EMAILS_SUCCESS = 'InvitationEmailProvider/GET_INVITATION_EMAILS_SUCCESS';
export const GET_INVITATION_EMAILS_ERROR = 'InvitationEmailProvider/GET_INVITATION_EMAILS_ERROR';

export const REMOVE_INVITATION_EMAIL_REQUEST = 'InvitationEmailProvider/REMOVE_INVITATION_EMAIL_REQUEST';
export const REMOVE_INVITATION_EMAIL_SUCCESS = 'InvitationEmailProvider/REMOVE_INVITATION_EMAIL_SUCCESS';
export const REMOVE_INVITATION_EMAIL_ERROR = 'InvitationEmailProvider/REMOVE_INVITATION_EMAIL_ERROR';

export const RESEND_INVITATION_EMAIL_REQUEST = 'InvitationEmailProvider/RESEND_INVITATION_EMAIL_REQUEST';
export const RESEND_INVITATION_EMAIL_SUCCESS = 'InvitationEmailProvider/RESEND_INVITATION_EMAIL_SUCCESS';
export const RESEND_INVITATION_EMAIL_ERROR = 'InvitationEmailProvider/RESEND_INVITATION_EMAIL_ERROR';

export const ADD_INVOICE_EMAIL_REQUEST = 'InvitationEmailProvider/ADD_INVOICE_EMAIL_REQUEST';
export const ADD_INVOICE_EMAIL_SUCCESS = 'InvitationEmailProvider/ADD_INVOICE_EMAIL_SUCCESS';
export const ADD_INVOICE_EMAIL_ERROR = 'InvitationEmailProvider/ADD_INVOICE_EMAIL_ERROR';
