import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';

import ProductList from '../ProductList';
import AssignedBrand from '../AssignedBrand';
import SupplierDetail from '../SupplierDetail';

const RedirectToInAppConfig = (props) => {
  const { supplierId } = _get(props, 'match.params');
  return <Redirect to={`/suppliers/detail/${supplierId}/assigned-brand`} />;
};

const indexRoutes = [
  {
    path: '/suppliers/detail/:supplierId/product-list',
    component: ProductList,
  },
  {
    path: '/suppliers/detail/:supplierId/assigned-brand',
    component: AssignedBrand,
  },
  {
    path: '/suppliers/detail/:supplierId/supplier-detail',
    component: SupplierDetail,
  },
  {
    path: '/suppliers/detail/:supplierId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
