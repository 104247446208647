import React, { useEffect } from 'react';
import { withRouter, Switch, useLocation } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Spin, notification } from 'antd';

import { getCurrentUserRequest } from 'providers/AuthProvider/actions';
import {
  listenOrderMessageRequest,
  listenWebNotificationRequest,
} from 'providers/LiveQueryProvider/actions';
import { getParseConfigRequest } from 'providers/PrefectureProvider/actions';

import AuthenticationPage from 'containers/AuthenticationPage';
import RegisterPage from 'containers/RegisterPage';
import AdminPage from 'containers/AdminPage';

import { PublicRoutes, PrivateRoutes } from './routes';

const App = () => {
  const dispatch = useDispatch();

  const { key } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key]);

  notification.config({
    placement: 'bottomRight',
  });

  useEffect(() => {
    (async function fetchUser() {
      await dispatch(getCurrentUserRequest());
      dispatch(listenOrderMessageRequest());
      dispatch(listenWebNotificationRequest());
      dispatch(getParseConfigRequest());
    }());
  }, []);

  const currentUserLoading = useSelector(
    (state) => state.authProvider.currentUserLoading,
    shallowEqual
  );

  if (currentUserLoading) {
    return (
      <div
        className="flex justify-center items-center full-w"
        style={{ height: '100vh' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Switch>
      <PublicRoutes path="/auth" component={AuthenticationPage} />
      <PrivateRoutes path="/reg" component={RegisterPage} />
      <PrivateRoutes path="/" component={AdminPage} />
    </Switch>
  );
};

export default withRouter(App);
