import React from 'react';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  Tabs, Button, Avatar, Typography, Space
} from 'antd';
import PropTypes from 'prop-types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import PageHeader from 'components/PageHeader';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';

import './style.less';

const { Text } = Typography;

const RestaurantHeader = ({
  restaurantDetail,
  footer,
  idList = [],
  onBack,
}) => {
  const { restaurantId } = useParams();
  const { pathname } = useLocation();
  const { replace } = useHistory();

  const onTabClick = (key) => {
    const pushPath = `/restaurants/detail/${restaurantId}/${key}`;
    replace(pushPath);
  };

  const selectedKeys = pathname.split('/')[4];

  const breadcrumbItems = [
    {
      path: '/restaurants',
      breadcrumbName: 'レストラン',
    },
    {
      breadcrumbName: _get(restaurantDetail, 'name', '-'),
    },
  ];

  const calcNavigateId = () => {
    const index = _findIndex(idList, (item) => item === restaurantId);
    return {
      current: index,
      backTo: index > 0 && idList[index - 1],
      nextTo: index < idList.length - 1 && idList[index + 1],
    };
  };

  const handleReplaceRoute = (evt, id) => {
    evt.preventDefault();
    replace(`/restaurants/detail/${id}`);
  };

  const renderNavigationButton = () => {
    const { current, backTo, nextTo } = calcNavigateId();

    if (idList.length < 1) {
      return [];
    }

    return [
      <span
        key="current"
        className="text-bold"
        style={{ fontSize: 14, marginLeft: 16 }}
      >
        {`${current + 1} / ${idList.length}`}
      </span>,
      <Button
        key="prev"
        type="primary"
        disabled={!backTo}
        onClick={(evt) => handleReplaceRoute(evt, backTo)}
        icon={<LeftOutlined />}
      />,
      <Button
        key="next"
        type="primary"
        disabled={!nextTo}
        onClick={(evt) => handleReplaceRoute(evt, nextTo)}
        icon={<RightOutlined />}
      />,
    ];
  };

  const renderRestaurantInfoHeader = () => (
    <Space>
      <Avatar
        size={40}
        src={_get(restaurantDetail, 'image', DefaultRestaurantImage)}
      />
      <div>
        <Text
          className="restaurant-name"
          ellipsis={{
            tooltip: restaurantDetail?.name
          }}
          style={{
            width: 250
          }}
        >
          {_get(restaurantDetail, 'name', '')}
        </Text>
        <Text
          className="email"
          type="secondary"
          copyable={
            restaurantDetail?.email && {
              tooltips: ['コピー', 'コピーしました'],
            }
          }
        >
          {_get(restaurantDetail, 'email', '')}
        </Text>
      </div>
    </Space>
  );

  return (
    <PageHeader
      onBack={onBack}
      title={renderRestaurantInfoHeader()}
      breadcrumbRoutes={breadcrumbItems}
      extra={renderNavigationButton()}
      footer={(
        <Tabs onTabClick={onTabClick} activeKey={selectedKeys}>
          {footer}
        </Tabs>
      )}
    />
  );
};

RestaurantHeader.propTypes = {
  restaurantDetail: PropTypes.object,
  footer: PropTypes.element,
  idList: PropTypes.any,
  onBack: PropTypes.func,
};

export default RestaurantHeader;
