import _get from 'lodash/get';
import _find from 'lodash/find';
import produce from 'immer';

import {
  GET_MENU_ITEM_LIST_REQUEST,
  GET_MENU_ITEM_LIST_SUCCESS,
  GET_MENU_ITEM_LIST_ERROR,
  RESET_MENU_ITEM_LIST,
  PAGING_GET_MENU_ITEM_LIST_REQUEST,
  PAGING_GET_MENU_ITEM_LIST_SUCCESS,
  GET_MENU_ITEM_DETAIL_REQUEST,
  GET_MENU_ITEM_DETAIL_SUCCESS,
  GET_MENU_ITEM_DETAIL_ERROR,
  GET_UNIT_LIST_REQUEST,
  GET_UNIT_LIST_SUCCESS,
  GET_UNIT_LIST_ERROR,
  CHANGE_MENU_ITEM_STATUS_SUCCESS,
  PAGING_GET_MENU_ITEM_LIST_ERROR,
  CHANGE_SOLD_OUT_STATUS_SUCCESS,
  REMOVE_PRODUCT_RANKING_SUCCESS,
} from './constants';

const initialState = {
  dataLoading: false,
  actionLoading: false,
  error: false,
  pagingMenuItemList: {},
  menuItemList: [],
  menuItemTotal: 0,
  menuItemDetail: {},
  unitList: [],
  rankingList: {},
  menuCountPerType: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_ITEM_LIST_REQUEST:
      state.dataLoading = true;
      state.error = false;
      if (action.payload.page === 1) {
        state.menuItemList = [];
        state.menuItemTotal = 0;
      }
      break;

    case GET_MENU_ITEM_LIST_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      state.menuItemList.push(...action.payload.list);
      state.menuItemTotal = action.payload.total;
      if (action?.payload?.menuCountPerType) {
        state.menuCountPerType = action?.payload?.menuCountPerType;
      }
      break;

    case GET_MENU_ITEM_LIST_ERROR:
      state.dataLoading = false;
      state.error = action.payload;
      state.menuItemList = [];
      state.menuItemTotal = 0;
      state.menuCountPerType = {};
      break;

    case RESET_MENU_ITEM_LIST:
      state.dataLoading = false;
      state.error = false;
      state.menuItemList = [];
      state.menuItemTotal = 0;
      break;

    case PAGING_GET_MENU_ITEM_LIST_REQUEST:
      break;

    case PAGING_GET_MENU_ITEM_LIST_SUCCESS:
      state.pagingMenuItemList = {
        ...action.payload,
        menuCountPerType: undefined,
      };
      if (action?.payload?.menuCountPerType) {
        state.menuCountPerType = action?.payload?.menuCountPerType;
      }
      break;

    case PAGING_GET_MENU_ITEM_LIST_ERROR:
      state.pagingMenuItemList = {};
      state.menuCountPerType = {};
      break;

    case GET_MENU_ITEM_DETAIL_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_MENU_ITEM_DETAIL_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      state.menuItemDetail = action.payload;
      break;

    case GET_MENU_ITEM_DETAIL_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      state.menuItemDetail = {};
      break;

    case GET_UNIT_LIST_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_UNIT_LIST_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      state.unitList = action.data;
      break;

    case GET_UNIT_LIST_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      break;

    case CHANGE_MENU_ITEM_STATUS_SUCCESS: {
      const { status, menuItemId } = _get(action, 'payload');

      if (state.menuItemDetail.objectId === menuItemId) {
        state.menuItemDetail.status = status;
      }

      _find(_get(state, 'pagingMenuItemList.list'), (menuItem) => {
        if (menuItem.objectId === menuItemId) {
          menuItem.status = status;
          return true;
        }
        return false;
      });

      break;
    }

    case CHANGE_SOLD_OUT_STATUS_SUCCESS: {
      const { isSoldOut, menuItemId, arrivalDate } = action.payload;
      state.menuItemDetail = {
        ...state.menuItemDetail,
        isSoldOut,
        arrivalDate,
      };
      _find(_get(state, 'pagingMenuItemList.list'), (menuItem) => {
        if (menuItem.objectId === menuItemId) {
          menuItem.isSoldOut = isSoldOut;
          menuItem.arrivalDate = arrivalDate;
          return true;
        }
        return false;
      });
      break;
    }

    case REMOVE_PRODUCT_RANKING_SUCCESS: {
      const { menuItemId } = action.payload;

      const newMenuList = _get(state, 'rankingList.list', []).filter(
        (menuItem) => menuItem.objectId !== menuItemId
      );

      state.menuCountPerType.MENU_RANKING = newMenuList.length;
      state.rankingList = {
        list: newMenuList,
        total: newMenuList.length,
      };

      break;
    }

    default:
  }
  return state;
});

export default reducer;
