import _get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {
  Space,
  Badge,
  Card,
  Row,
  Col,
  Descriptions,
  Button,
  Modal,
  Avatar,
  Tooltip,
  Typography,
  Image,
  Spin,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import {
  getInAppMessageDetailRequest,
  stopInAppMessageRequest,
} from 'providers/InAppMessageProvider/actions';
import PageHeader from 'components/PageHeader';
import { IN_APP_MESSAGE_STATUS, DATE_TIME_FORMAT } from 'utils/constants';

import DefaultProductImage from 'images/defaultProduct.svg';
import popOutIcon from 'images/icon-pop-out.svg';
import attachmentIcon from 'images/icon-attachment.svg';
import linkIcon from 'images/icon-link.svg';
import './style.less';
import withRefresh from 'components/HOCs/withRefresh';
import { doRefresh } from 'providers/CommonProvider/actions';

const { confirm } = Modal;
export const STATUS_BADGE_CONTENT = {
  [IN_APP_MESSAGE_STATUS.PENDING]: {
    type: 'warning',
    text: 'Scheduled',
  },
  [IN_APP_MESSAGE_STATUS.ACTIVE]: {
    type: 'success',
    text: '有効',
  },
  [IN_APP_MESSAGE_STATUS.INACTIVE]: {
    type: 'default',
    text: '無効',
  },
};

const showConfirm = (title, content, actionFn) => {
  confirm({
    title,
    content,
    okText: 'Stop',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk: () => {
      actionFn();
    },
  });
};

const InAppMessageDetail = () => {
  const dispatch = useDispatch();
  const { inAppMessageId: objectId } = useParams();
  const { goBack } = useHistory();
  const { inAppMessageDetail, isLoading } = useSelector((state) => state.inAppMessageProvider, shallowEqual);
  const {
    status, targetFile, title, message, startDate, endDate, createdAt, targetUrl, image, targetMenu,
  } = inAppMessageDetail;

  const getInAppMessageDetail = useCallback(
    () => dispatch(getInAppMessageDetailRequest({ objectId })),
    [dispatch, objectId],
  );

  useEffect(() => {
    getInAppMessageDetail();
  }, [getInAppMessageDetail]);

  const {
    images, name, standard, unit, amount, formattedObjectId,
  } = targetMenu || {};

  const link = targetUrl && validator.isURL(targetUrl, {
    require_protocol: true,
  }) ? targetUrl : `http://${targetUrl}`;

  const stopRestaurantAnnouncement = async (announcementId) => {
    await dispatch(stopInAppMessageRequest({ objectId: announcementId }));
    dispatch(doRefresh({ target: 'in-app-message-detail' }));
  };

  const breadcrumbItems = [
    {
      path: '/in-app-messages',
      breadcrumbName: 'TOPお知らせメッセージ /',
    },
    {
      breadcrumbName: objectId,
    },
  ];

  const productItem = (
    <div
      className="flex items-center full-w product-item"
    >
      <Avatar
        alt="name"
        src={_get(images, '[0].url', DefaultProductImage)}
        size={32}
        shape="square"
      />
      <Space
        direction="vertical"
        size={0}
        className="restaurant-info-wrapper"
      >
        <Tooltip placement="topLeft" title={name}>
          <Typography.Text ellipsis>{name}</Typography.Text>
        </Tooltip>
        <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
          {standard || '-'} • {amount || '-'} ／{' '}{unit || '-'}
        </Typography.Text>
      </Space>
      <Button
        type="link"
        href={`/products/detail/${formattedObjectId}/in-app`}
        icon={<img src={popOutIcon} alt="icon-pop-out" />}
        target="_blank"
      />
    </div>
  );

  const titleElement = (
    <div className="flex direction-column announcement-header">
      <Typography.Text>{objectId}</Typography.Text>
      <Badge
        id="in-app-message-detail-status-dot"
        status={STATUS_BADGE_CONTENT[status]?.type}
        text={STATUS_BADGE_CONTENT[status]?.text}
      />
    </div>
  );

  const cancelButton = [IN_APP_MESSAGE_STATUS.PENDING, IN_APP_MESSAGE_STATUS.ACTIVE].includes(status) && (
    <Button
      type="primary"
      danger
      onClick={() => {
        showConfirm('Stop this announcement immediately', 'The restaurant will not be see this', () => stopRestaurantAnnouncement(objectId));
      }}
      icon={<CloseOutlined />}
    >
      無効にする
    </Button>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="in-app-message-detail-page">
        <PageHeader
          title={titleElement}
          breadcrumbRoutes={breadcrumbItems}
          onBack={goBack}
        />
        <Card className="bg-transparent" bordered={false}>
          <Row gutter={[16, 16]}>
            <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card
                    className="highlight-card"
                    bordered={false}
                    title="通知内容"
                    id="in-app-message-detail-description"
                  >
                    <Descriptions
                      layout="horizontal"
                      column={1}
                      bordered
                      labelStyle={{
                        display: 'block',
                      }}
                    >
                      <Descriptions.Item label="タイトル">
                        {title || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label="本文">
                        {message || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label="画像">
                        {image ? (
                          <Image
                            src={image?.url}
                            alt="image"
                            preview={false}
                            width={200}
                          />
                        ) : '-'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col lg={{ span: 24 }} md={{ span: 0 }} xs={{ span: 0 }}>
                  {cancelButton}
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <Space direction="vertical" size={16} className="full-w flex">
                <Card
                  className="highlight-card"
                  bordered={false}
                  title="詳細な遷移先"
                  id="in-app-message-detail-description"
                >
                  <Descriptions layout="horizontal" column={1} bordered>
                    <Descriptions.Item>
                      {(targetFile?.url || targetUrl) && (
                        <a
                          href={targetFile?.url || link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Space direction="horizontal" className="items-center">
                            <img src={targetUrl ? linkIcon : attachmentIcon} alt="icon" />
                            {targetFile?.name || 'URL'}
                          </Space>
                        </a>
                      )}
                      {formattedObjectId && productItem}
                      {!targetFile?.url && !formattedObjectId && !targetUrl && '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card className="highlight-card" bordered={false} title="配信予約" id="in-app-message-detail-description">
                  <Descriptions
                    layout="horizontal"
                    column={1}
                    bordered
                  >
                    <Descriptions.Item label="作成日時">
                      {moment(createdAt).format(
                        DATE_TIME_FORMAT
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="配信開始日">
                      {startDate ? moment(startDate).format(
                        DATE_TIME_FORMAT
                      ) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="配信終了日">
                      {endDate ? moment(endDate).format(
                        DATE_TIME_FORMAT
                      ) : '期限なし'}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Space>
            </Col>
            <Col lg={{ span: 0 }} md={{ span: 24 }} xs={{ span: 24 }}>
              {cancelButton}
            </Col>
          </Row>
        </Card>
      </div>
    </Spin>
  );
};

export default withRefresh('in-app-message-detail')(InAppMessageDetail);
