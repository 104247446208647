import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Space, Typography, Row, Col, Button
} from 'antd';
import HoverVideoPlayer from 'react-hover-video-player';

import BrandOwnerVideo from 'images/AuthPage/RoleRegister/Brand_Owner.mp4';
import RestaurantVideo from 'images/AuthPage/RoleRegister/Restaurant.mp4';
import SupplierVideo from 'images/AuthPage/RoleRegister/Supplier.mp4';

import './style.less';

const RoleRegister = () => {
  const dispatch = useDispatch();

  const supplierRoleCardRef = useRef();
  const restaurantRoleCardRef = useRef();
  const brandRoleCardRef = useRef();

  return (
    <div id="role-register">
      <Space
        size={40}
        direction="vertical"
        className="flex justify-center items-center full-w"
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
            ようこそシコメルへ
          </Typography.Text>
          <br />
          <Typography.Text
            strong
            style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.85)' }}
          >
            ご利用目的は何ですか？
          </Typography.Text>
        </div>
        <Row gutter={14}>
          <Col span={8}>
            <Button
              ref={supplierRoleCardRef}
              className="highlight-card role-card-active"
              onClick={() => dispatch(push('/reg/supplier'))}
            >
              <Space
                direction="vertical"
                size={8}
                className="full-w flex justify-center items-center"
              >
                <HoverVideoPlayer
                  className="animated-intro"
                  videoSrc={SupplierVideo}
                  hoverTarget={supplierRoleCardRef}
                  restartOnPaused
                  muted
                  loop={false}
                  overlayTransitionDuration={0}
                  sizingMode="container"
                />
                <Typography.Text
                  strong
                  style={{ fontSize: 18, color: '#231916' }}
                >
                  シコメルで
                  <br />
                  商品を売りたい
                </Typography.Text>
                <Button type="link">選択する</Button>
              </Space>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              ref={restaurantRoleCardRef}
              className="highlight-card role-card-active"
              onClick={() => dispatch(push('/reg/restaurant'))}
            >
              <Space
                direction="vertical"
                size={8}
                className="full-w flex justify-center items-center"
              >
                <HoverVideoPlayer
                  className="animated-intro"
                  videoSrc={RestaurantVideo}
                  hoverTarget={restaurantRoleCardRef}
                  restartOnPaused
                  muted
                  loop={false}
                  overlayTransitionDuration={0}
                  sizingMode="container"
                />
                <Typography.Text
                  strong
                  style={{ fontSize: 18, color: '#231916' }}
                >
                  複数店舗を <br />
                  とりまとめたい
                </Typography.Text>
                <Button type="link">選択する</Button>
              </Space>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              ref={brandRoleCardRef}
              className="highlight-card role-card-active"
              onClick={() => dispatch(push('/reg/brand'))}
            >
              <Space
                direction="vertical"
                size={8}
                className="full-w flex justify-center items-center"
              >
                <HoverVideoPlayer
                  className="animated-intro"
                  videoSrc={BrandOwnerVideo}
                  hoverTarget={brandRoleCardRef}
                  restartOnPaused
                  muted
                  loop={false}
                  overlayTransitionDuration={0}
                  sizingMode="container"
                />
                <Typography.Text
                  strong
                  style={{ fontSize: 18, color: '#231916' }}
                >
                  ブランド商品を
                  <br />
                  管理したい
                </Typography.Text>
                <Button type="link">選択する</Button>
              </Space>
            </Button>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default RoleRegister;
