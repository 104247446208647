import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Avatar, Card, Space, Typography
} from 'antd';

import { getBrandListOfRestaurantRequest } from 'providers/BrandProvider/actions';

import Table from 'components/Table';
import DefaultBrandImage from 'images/defaultBrand.svg';
import useTableControl from 'components/Hooks/useTableControl';

const BrandList = () => {
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);
  const [pageControl, , onTableChange] = useTableControl();

  const brandListOfRestaurant = useSelector(
    (state) => state.brandProvider.brandListOfRestaurant,
    shallowEqual
  );
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );

  const { page, limit } = pageControl;
  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getBrandListOfRestaurantRequest({
          page,
          limit,
          restaurantId: restaurantDetail?.objectId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [page, limit, restaurantDetail]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: 'ブランド名',
      key: 'name',
      render: (record) => (
        <Space className="flex items-center">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultBrandImage)}
            size={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card
        className="highlight-card"
        bodyStyle={{ padding: 0 }}
        bordered={false}
      >
        <Table
          columns={columns}
          data={_get(brandListOfRestaurant, 'list', [])}
          total={_get(brandListOfRestaurant, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
        />
      </Card>
    </Card>
  );
};

export default BrandList;
