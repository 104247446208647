import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';

import { getListRestaurantAnnouncementRequest } from 'providers/AnnouncementProvider/actions';
import {
  makeSelectRestaurantAnnouncementList,
  makeSelectRestaurantAnnouncementTotal,
  makeSelectRestaurantAnnouncementDataLoading,
  makeSelectRestaurantAnnouncementActionLoading,
} from 'providers/AnnouncementProvider/selectors';
import {
  makeSelectLocationPathname,
  makeSelectLocationSearch
} from 'providers/RouterProvider/selectors';
import AnnouncementList from './AnnouncementList';

const mapDispatchToProps = (dispatch) => ({
  getListRestaurantAnnouncement: (params) => dispatch(getListRestaurantAnnouncementRequest(params)),
  push: (path) => dispatch(push(path)),
});

const mapStateToProps = createStructuredSelector({
  restaurantAnnouncements: makeSelectRestaurantAnnouncementList(),
  restaurantAnnouncementTotal: makeSelectRestaurantAnnouncementTotal(),
  isRestaurantAnnouncementLoading: makeSelectRestaurantAnnouncementDataLoading(),
  actionLoading: makeSelectRestaurantAnnouncementActionLoading(),
  pathname: makeSelectLocationPathname(),
  search: makeSelectLocationSearch()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(AnnouncementList);
