import _get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Upload } from 'antd';
import { IMAGE_ACCEPT_TYPE } from 'utils/constants';

const FileField = (props) => {
  const {
    input,
    label,
    colon,
    meta: { touched, invalid, error },
  } = props;

  const handleChange = (e) => {
    // Remove event also has file prop, so need to check status to know when file is removed
    const isRemove = _get(e, 'file.status') === 'removed';

    if (isRemove) {
      input.onChange('');
    } else {
      input.onChange(e.file || '');
    }
  };

  return (
    <Form.Item
      help={touched && invalid && error}
      validateStatus={touched && invalid && error ? 'error' : ''}
      label={label}
      colon={colon}
    >
      <Upload
        {...input}
        listType="picture"
        onChange={handleChange}
        maxCount={1}
        beforeUpload={() => false}
        accept={`${IMAGE_ACCEPT_TYPE}, .pdf`}
      >
        <Button style={{ padding: '0 32px 0 16px' }} icon={<UploadOutlined />}>
          ファイルを選択
        </Button>
      </Upload>
    </Form.Item>
  );
};

FileField.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  colon: PropTypes.bool,
};

export default FileField;
