import _get from 'lodash/get';
import React from 'react';
import { CloseOutlined, DownOutlined, CheckOutlined } from '@ant-design/icons';
import {
  Button,
  Typography,
  Avatar,
  Dropdown,
  Tooltip,
  Space,
  Menu,
  Spin,
} from 'antd';

import DefaultProductImage from 'images/defaultProduct.svg';
import helpers from 'utils/helpers';

const { Text, Paragraph } = Typography;

function renderSelectedMenu(
  menuItem,
  handleSelectMenuPrice,
  handleUnselectItem,
  disabledRemove
) {
  const menuPrices = _get(menuItem, 'prices', []);
  const selectedPrice = menuPrices.find((price) => price.objectId === menuItem.menuPriceId) || {};
  const imageUrl = _get(menuItem, 'images[0].url') || _get(menuItem, 'images[0]');

  const menu = (prices = [], menuPriceId) => (
    <Menu>
      {prices.map((price) => {
        const isSelectedPrice = price.objectId === menuPriceId;
        return (
          <Menu.Item
            onClick={() => handleSelectMenuPrice(price.objectId, menuItem.objectId)}
            key={price.objectId}
            style={{
              padding: '8px 40px 8px 8px',
              whiteSpace: 'pre-line',
              backgroundColor: isSelectedPrice ? '#e6f7ff' : 'transparent',
            }}
          >
            <Space>
              <CheckOutlined
                style={{ color: isSelectedPrice ? '#399e0e' : 'transparent' }}
              />
              <div>
                <Tooltip placement="right" title={price.name}>
                  <Paragraph
                    ellipsis={{ rows: 1 }}
                    style={{ marginBottom: 0, maxWidth: 200 }}
                  >
                    {price.name}
                  </Paragraph>
                </Tooltip>
                <Text type="secondary">
                  単価 : {helpers.priceFormat(price.priceBeforeTax)}
                </Text>
                <br />
                <Text type="secondary">
                  税金 :{' '}
                  {price.taxRate > 0 ? `${price.taxRate * 100}%` : '非課税'}
                </Text>
                <br />
                <Text type="secondary">
                  税込 : {helpers.priceFormat(price.priceAfterTax)}
                </Text>
              </div>
            </Space>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <div className="selected-menu-item">
      <div className="flex items-center" style={{ flex: 1 }}>
        <Avatar
          size={32}
          shape="square"
          src={imageUrl || DefaultProductImage}
        />
        <div className="menu-detail">
          <Tooltip title={menuItem.name}>
            <Paragraph ellipsis={{ rows: 2 }} className="menu-name">
              {menuItem.name}
            </Paragraph>
          </Tooltip>
          {menuPrices.length > 1 && (
            <Dropdown
              overlay={menu(menuPrices, menuItem.menuPriceId)}
              trigger={['click']}
            >
              <Tooltip placement="right" title={selectedPrice.name}>
                <span className="price-dropdown">
                  <Text ellipsis className="selected-price-name">
                    {selectedPrice.name}
                  </Text>
                  <DownOutlined />
                </span>
              </Tooltip>
            </Dropdown>
          )}
          {menuItem.note && <div className="menu-note">{menuItem.note}</div>}
        </div>
      </div>
      {!disabledRemove && (
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={(event) => handleUnselectItem(event, menuItem.objectId)}
        />
      )}
    </div>
  );
}

export default function renderSelectedMenus(
  loaded,
  selectedMenus,
  handleSelectMenuPrice,
  handleUnselectItem,
  disabledRemove = false
) {
  if (!loaded) {
    return (
      <Spin
        style={{
          position: 'relative',
          left: 'calc(50% - 10px)',
          top: '50%',
        }}
      />
    );
  }

  return selectedMenus.map((selectedMenu) => (
    <div key={selectedMenu.objectId}>
      {renderSelectedMenu(
        selectedMenu,
        handleSelectMenuPrice,
        handleUnselectItem,
        disabledRemove
      )}
    </div>
  ));
}
