import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card } from 'antd';

import { TextField } from 'components/Form';

import validate from './validate';

const CollectionCreateForm = ({ handleSubmit }) => (
  <Form
    colon={false}
    onSubmit={handleSubmit}
    layout="horizontal"
    labelCol={{ span: 6 }}
  >
    <Card
      className="highlight-card"
      title="基本情報"
      bordered={false}
      headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
    >
      <Field
        label="セット商品名"
        name="name"
        maxLength={50}
        component={TextField}
        required
      />
      <Field
        label="メモ"
        name="note"
        maxLength={500}
        component={TextField}
        textArea
        requiredSpace
      />
    </Card>
  </Form>
);

CollectionCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'collectionCreateForm',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(CollectionCreateForm);
