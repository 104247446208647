import {
  GET_RANKING_LIST_REQUEST,
  GET_RANKING_LIST_SUCCESS,
  GET_RANKING_LIST_ERROR,
  GET_RANKING_DETAIL_REQUEST,
  GET_RANKING_DETAIL_SUCCESS,
  GET_RANKING_DETAIL_ERROR,
  REORDER_RANKING_LIST_REQUEST,
  REORDER_RANKING_LIST_SUCCESS,
  REORDER_RANKING_LIST_ERROR,
  REORDER_RANKING_MENU_REQUEST,
  REORDER_RANKING_MENU_SUCCESS,
  REORDER_RANKING_MENU_ERROR,
  REMOVE_RANKING_MENU_REQUEST,
  REMOVE_RANKING_MENU_SUCCESS,
  REMOVE_RANKING_MENU_ERROR,
  ADD_RANKING_MENU_ERROR,
  ADD_RANKING_MENU_SUCCESS,
  ADD_RANKING_MENU_REQUEST,
  UPDATE_RANKING_REQUEST,
  UPDATE_RANKING_SUCCESS,
  UPDATE_RANKING_ERROR,
  UPDATE_RANKING_LIST,
  UPDATE_RANKING_DETAIL,
} from './constants';

export function getRankingListRequest(payload) {
  return { type: GET_RANKING_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getRankingListSuccess(payload, meta) {
  return { type: GET_RANKING_LIST_SUCCESS, payload, meta };
}

export function getRankingListError(payload, meta) {
  return {
    type: GET_RANKING_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRankingDetailRequest(payload) {
  return { type: GET_RANKING_DETAIL_REQUEST, payload, meta: { thunk: true } };
}

export function getRankingDetailSuccess(payload, meta) {
  return { type: GET_RANKING_DETAIL_SUCCESS, payload, meta };
}

export function getRankingDetailError(payload, meta) {
  return {
    type: GET_RANKING_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function reorderRankingListRequest(payload) {
  return { type: REORDER_RANKING_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function reorderRankingListSuccess(payload, meta) {
  return { type: REORDER_RANKING_LIST_SUCCESS, payload, meta };
}

export function reorderRankingListError(payload, meta) {
  return {
    type: REORDER_RANKING_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function reorderRankingMenuRequest(payload) {
  return { type: REORDER_RANKING_MENU_REQUEST, payload, meta: { thunk: true } };
}

export function reorderRankingMenuSuccess(payload, meta) {
  return { type: REORDER_RANKING_MENU_SUCCESS, payload, meta };
}

export function reorderRankingMenuError(payload, meta) {
  return {
    type: REORDER_RANKING_MENU_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeRankingMenuRequest(payload) {
  return { type: REMOVE_RANKING_MENU_REQUEST, payload, meta: { thunk: true } };
}

export function removeRankingMenuSuccess(payload, meta) {
  return { type: REMOVE_RANKING_MENU_SUCCESS, payload, meta };
}

export function removeRankingMenuError(payload, meta) {
  return {
    type: REMOVE_RANKING_MENU_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addRankingMenuRequest(payload) {
  return { type: ADD_RANKING_MENU_REQUEST, payload, meta: { thunk: true } };
}

export function addRankingMenuSuccess(payload, meta) {
  return { type: ADD_RANKING_MENU_SUCCESS, payload, meta };
}

export function addRankingMenuError(payload, meta) {
  return {
    type: ADD_RANKING_MENU_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRankingRequest(payload) {
  return { type: UPDATE_RANKING_REQUEST, payload, meta: { thunk: true } };
}

export function updateRankingSuccess(payload, meta) {
  return { type: UPDATE_RANKING_SUCCESS, payload, meta };
}

export function updateRankingError(payload, meta) {
  return {
    type: UPDATE_RANKING_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRankingList(payload) {
  return { type: UPDATE_RANKING_LIST, payload };
}

export function updateRankingDetail(payload) {
  return { type: UPDATE_RANKING_DETAIL, payload };
}
