import {
  LISTEN_ORDER_MESSAGE_REQUEST,
  LISTEN_WEB_NOTIFICATION_REQUEST,
  LISTEN_ORDER_MESSAGE_REMOVE,
  LISTEN_WEB_NOTIFICATION_REMOVE
} from './constants';

export const listenOrderMessageRequest = () => ({
  type: LISTEN_ORDER_MESSAGE_REQUEST,
});

export const listenOrderMessageRemove = () => ({
  type: LISTEN_ORDER_MESSAGE_REMOVE
});

export const listenWebNotificationRequest = () => ({
  type: LISTEN_WEB_NOTIFICATION_REQUEST
});

export const listenWebNotificationRemove = () => ({
  type: LISTEN_WEB_NOTIFICATION_REMOVE
});
