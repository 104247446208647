import {
  CREATE_COLLECTION_REQUEST,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_ERROR,
  GET_COLLECTION_LIST_REQUEST,
  GET_COLLECTION_LIST_SUCCESS,
  GET_COLLECTION_LIST_ERROR,
  GET_COLLECTION_DETAIL_REQUEST,
  GET_COLLECTION_DETAIL_SUCCESS,
  GET_COLLECTION_DETAIL_ERROR,
  GET_COLLECTION_MENUS_REQUEST,
  GET_COLLECTION_MENUS_SUCCESS,
  GET_COLLECTION_MENUS_ERROR,
  REMOVE_COLLECTION_MENUS_REQUEST,
  REMOVE_COLLECTION_MENUS_SUCCESS,
  REMOVE_COLLECTION_MENUS_ERROR,
  UPDATE_COLLECTION_REQUEST,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_ERROR,
  ASSIGN_MENU_TO_COLLECTION_REQUEST,
  ASSIGN_MENU_TO_COLLECTION_SUCCESS,
  ASSIGN_MENU_TO_COLLECTION_ERROR,
  GET_RESTAURANT_COLLECTION_LIST_REQUEST,
  GET_RESTAURANT_COLLECTION_LIST_SUCCESS,
  GET_RESTAURANT_COLLECTION_LIST_ERROR,
  ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST,
  ASSIGN_RESTAURANT_TO_COLLECTION_SUCCESS,
  ASSIGN_RESTAURANT_TO_COLLECTION_ERROR,
  DELETE_COLLECTION_ERROR,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_REQUEST,
} from './constants';

export function createCollectionRequest(payload) {
  return { type: CREATE_COLLECTION_REQUEST, payload, meta: { thunk: true } };
}

export function createCollectionSuccess(payload, meta) {
  return { type: CREATE_COLLECTION_SUCCESS, payload, meta };
}

export function createCollectionError(payload, meta) {
  return {
    type: CREATE_COLLECTION_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getCollectionListRequest(payload) {
  return { type: GET_COLLECTION_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getCollectionListSuccess(payload, meta) {
  return { type: GET_COLLECTION_LIST_SUCCESS, payload, meta };
}

export function getCollectionListError(payload, meta) {
  return {
    type: GET_COLLECTION_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantCollectionListRequest(payload) {
  return {
    type: GET_RESTAURANT_COLLECTION_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantCollectionListSuccess(payload, meta) {
  return { type: GET_RESTAURANT_COLLECTION_LIST_SUCCESS, payload, meta };
}

export function getRestaurantCollectionListError(payload, meta) {
  return {
    type: GET_RESTAURANT_COLLECTION_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getCollectionDetailRequest(payload) {
  return {
    type: GET_COLLECTION_DETAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getCollectionDetailSuccess(payload, meta) {
  return { type: GET_COLLECTION_DETAIL_SUCCESS, payload, meta };
}

export function getCollectionDetailError(payload, meta) {
  return {
    type: GET_COLLECTION_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getCollectionMenusRequest(payload) {
  return { type: GET_COLLECTION_MENUS_REQUEST, payload, meta: { thunk: true } };
}

export function getCollectionMenusSuccess(payload, meta) {
  return { type: GET_COLLECTION_MENUS_SUCCESS, payload, meta };
}

export function getCollectionMenusError(payload, meta) {
  return {
    type: GET_COLLECTION_MENUS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateCollectionRequest(payload) {
  return { type: UPDATE_COLLECTION_REQUEST, payload, meta: { thunk: true } };
}

export function updateCollectionSuccess(payload, meta) {
  return { type: UPDATE_COLLECTION_SUCCESS, payload, meta };
}

export function updateCollectionError(payload, meta) {
  return {
    type: UPDATE_COLLECTION_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function deleteCollectionRequest(payload) {
  return { type: DELETE_COLLECTION_REQUEST, payload, meta: { thunk: true } };
}

export function deleteCollectionSuccess(payload, meta) {
  return { type: DELETE_COLLECTION_SUCCESS, payload, meta };
}

export function deleteCollectionError(payload, meta) {
  return {
    type: DELETE_COLLECTION_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeCollectionMenusRequest(payload) {
  return {
    type: REMOVE_COLLECTION_MENUS_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeCollectionMenusSuccess(payload, meta) {
  return { type: REMOVE_COLLECTION_MENUS_SUCCESS, payload, meta };
}

export function removeCollectionMenusError(payload, meta) {
  return {
    type: REMOVE_COLLECTION_MENUS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function assignMenuToCollectionRequest(payload) {
  return {
    type: ASSIGN_MENU_TO_COLLECTION_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function assignMenuToCollectionSuccess(payload, meta) {
  return { type: ASSIGN_MENU_TO_COLLECTION_SUCCESS, payload, meta };
}

export function assignMenuToCollectionError(payload, meta) {
  return {
    type: ASSIGN_MENU_TO_COLLECTION_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function assignRestaurantToCollectionRequest(payload) {
  return {
    type: ASSIGN_RESTAURANT_TO_COLLECTION_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function assignRestaurantToCollectionSuccess(payload, meta) {
  return { type: ASSIGN_RESTAURANT_TO_COLLECTION_SUCCESS, payload, meta };
}

export function assignRestaurantToCollectionError(payload, meta) {
  return {
    type: ASSIGN_RESTAURANT_TO_COLLECTION_ERROR,
    payload,
    meta,
    error: true,
  };
}
