import {
  GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST,
  GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS,
  GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR,
  CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST,
  CREATE_RESTAURANT_ANNOUNCEMENT_SUCCESS,
  CREATE_RESTAURANT_ANNOUNCEMENT_ERROR,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR,
  DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST,
  DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS,
  DELETE_RESTAURANT_ANNOUNCEMENT_ERROR,
  EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST,
  EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_SUCCESS,
  EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_ERROR,
} from './constants';

export function getListRestaurantAnnouncementRequest(data) {
  return { type: GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST, data };
}

export function getListRestaurantAnnouncementSuccess(data) {
  return { type: GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS, data };
}

export function getListRestaurantAnnouncementError(error) {
  return { type: GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR, error };
}

export function createRestaurantAnnouncementRequest(payload) {
  return {
    type: CREATE_RESTAURANT_ANNOUNCEMENT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function createRestaurantAnnouncementSuccess(payload, meta) {
  return { type: CREATE_RESTAURANT_ANNOUNCEMENT_SUCCESS, payload, meta };
}

export function createRestaurantAnnouncementError(payload, meta) {
  return {
    type: CREATE_RESTAURANT_ANNOUNCEMENT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantAnnouncementDetailRequest(data) {
  return { type: GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST, data };
}

export function getRestaurantAnnouncementDetailSuccess(data) {
  return { type: GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS, data };
}

export function getRestaurantAnnouncementDetailError(error) {
  return { type: GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR, error };
}

export function deleteRestaurantAnnouncementRequest(payload) {
  return {
    type: DELETE_RESTAURANT_ANNOUNCEMENT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function deleteRestaurantAnnouncementSuccess(payload, meta) {
  return {
    type: DELETE_RESTAURANT_ANNOUNCEMENT_SUCCESS,
    payload,
    meta,
  };
}

export function deleteRestaurantAnnouncementError(payload, meta) {
  return {
    type: DELETE_RESTAURANT_ANNOUNCEMENT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function exportRestaurantAnnouncementResultRequest(payload) {
  return {
    type: EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function exportRestaurantAnnouncementResultSuccess(payload, meta) {
  return { type: EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_SUCCESS, payload, meta };
}

export function exportRestaurantAnnouncementResultError(payload, meta) {
  return {
    type: EXPORT_RESTAURANT_ANNOUNCEMENT_RESULT_ERROR,
    payload,
    meta,
    error: true,
  };
}
