import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';

import RestaurantInfo from '../RestaurantInfo';
import SupplierList from '../SupplierList';
import DiscountPointHistory from '../DiscountPointHistory';

const RedirectToInAppConfig = (props) => {
  const { restaurantId } = _get(props, 'match.params');
  return <Redirect to={`/restaurants/detail/${restaurantId}/in-app`} />;
};

const indexRoutes = [
  {
    path: '/restaurants/detail/:restaurantId/in-app',
    component: RestaurantInfo,
  },
  {
    path: '/restaurants/detail/:restaurantId/connected-suppliers',
    component: SupplierList,
  },
  {
    path: '/restaurants/detail/:restaurantId/point-history',
    component: DiscountPointHistory,
  },
  {
    path: '/restaurants/detail/:restaurantId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
