import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getMenuItemListRequest,
  resetMenuItemList,
} from 'providers/MenuProvider/actions';
import { getCategoryListRequest } from 'providers/CategoryProvider/actions';
import {
  makeSelectCategoryList,
  makeSelectCategoryLoading,
} from 'providers/CategoryProvider/selectors';
import {
  makeSelectMenuItemList,
  makeSelectMenuDataLoading,
} from 'providers/MenuProvider/selectors';

import ProductUpdate from './ProductUpdate';

const mapStateToProps = createStructuredSelector({
  menuItemList: makeSelectMenuItemList(),
  menuLoading: makeSelectMenuDataLoading(),
  categoryList: makeSelectCategoryList(),
  categoryLoading: makeSelectCategoryLoading(),
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItemListRequest: (value) => dispatch(getMenuItemListRequest(value)),
  getCategoryListRequest: (value) => dispatch(getCategoryListRequest(value)),
  resetMenuItemList: () => dispatch(resetMenuItemList()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProductUpdate);
