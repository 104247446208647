import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';
import BrandOwnerProfile from '../BrandOwnerProfile';
import BrandList from '../BrandList';
import SupplierList from '../SupplierList';

const RedirectToInAppConfig = (props) => {
  const { brandOwnerId } = _get(props, 'match.params');
  return <Redirect to={`/brands/brand-owners/detail/${brandOwnerId}/in-app`} />;
};

const indexRoutes = [
  {
    path: '/brands/brand-owners/detail/:brandOwnerId/in-app',
    component: BrandOwnerProfile,
  },
  {
    path: '/brands/brand-owners/detail/:brandOwnerId/brand-list',
    component: BrandList,
  },
  {
    path: '/brands/brand-owners/detail/:brandOwnerId/supplier-list',
    component: SupplierList,
  },
  {
    path: '/brands/brand-owners/detail/:brandOwnerId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
