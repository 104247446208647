import React from 'react';
import { Card, Typography } from 'antd';
import { Field } from 'redux-form';
import { TextField, UploadField } from 'components/Form';

const { Text } = Typography;

const SnsField = () => (
  <Card
    headStyle={{ fontSize: 20, fontWeight: 'bold' }}
    className="highlight-card"
    title="Promotion Materials"
    bordered={false}
    id="sns"
  >
    <Field
      name="snsText"
      label="Posting Text for SNS"
      textArea
      maxLength={500}
      component={TextField}
      requiredSpace
    />
    <Field
      label="Other Materials"
      additionalContent={(
        <span style={{ lineHeight: 'normal', display: 'block', marginBottom: 4 }}>
          <Text type="secondary">
            Allow image or PDF. Max 8 files - 50Mb for each file
          </Text>
        </span>
      )}
      name="snsFiles"
      component={UploadField}
      maxCount={8}
    />
  </Card>
);

export default SnsField;
