import React from 'react';
import { Form } from '@ant-design/compatible';
import PropTypes from 'prop-types';

import { reduxForm, Field } from 'redux-form';
import { DatePickerField, CheckboxGroup } from 'components/Form';
import moment from 'moment';
import { APPLY_TO } from 'utils/constants';
import validate from './validate';
import './style.less';

const AddHolidayForm = ({ handleSubmit, isShikomelSupplier }) => (
  <div id="add-holiday-form-container">
    <Form colon={false} onSubmit={handleSubmit} layout="vertical">
      {isShikomelSupplier
      && <div className="label">休業日を選択してください。</div>}
      <Field
        name="date"
        placeholder="日付を選択"
        component={DatePickerField}
        disabledDate={(current) => current <= moment()}
      />
      {isShikomelSupplier
        && (
          <>
            <div className="label">対象</div>
            <Field
              name="applyTo"
              options={[
                {
                  label:
                    (
                      <div
                        style={{
                          padding: 4,
                          borderRadius: 4
                        }}
                        className="warehouse-bg"
                      >
                        {APPLY_TO.WAREHOUSE.label}
                      </div>
                    ),
                  value: APPLY_TO.WAREHOUSE.value

                },
                {
                  label: (
                    <div
                      style={{
                        padding: 4,
                        borderRadius: 4
                      }}
                      className="factory-bg"
                    >
                      {APPLY_TO.MANUFACTURER.label}
                    </div>
                  ),
                  value: APPLY_TO.MANUFACTURER.value
                },
              ]}
              colSpan={7}
              component={CheckboxGroup}
            />
          </>
        )}
    </Form>
  </div>
);

AddHolidayForm.propTypes = {
  handleSubmit: PropTypes.func,
  isShikomelSupplier: PropTypes.string,
};

export default reduxForm({
  form: 'addHolidayForm',
  touchOnChange: true,
  validate,
})(AddHolidayForm);
