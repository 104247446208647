import produce from 'immer';

import {
  GET_BRAND_OWNER_LIST_REQUEST,
  GET_BRAND_OWNER_LIST_SUCCESS,
  GET_BRAND_OWNER_LIST_ERROR,
  GET_BRAND_OWNER_DETAIL_REQUEST,
  GET_BRAND_OWNER_DETAIL_SUCCESS,
  GET_BRAND_OWNER_DETAIL_ERROR,
  GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST,
  GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS,
} from './constants';

const initialState = {
  brandOwnerList: {},
  brandOwnerDetail: {},
  orderListForBrandOwner: {},
  brandOwnerDetailLoading: true,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_OWNER_LIST_REQUEST:
      state.brandOwnerList = {};
      break;

    case GET_BRAND_OWNER_LIST_SUCCESS:
      state.brandOwnerList = action.payload;
      break;

    case GET_BRAND_OWNER_LIST_ERROR:
      break;

    case GET_BRAND_OWNER_DETAIL_REQUEST:
      state.brandOwnerDetailLoading = true;
      state.brandOwnerDetail = {};
      break;

    case GET_BRAND_OWNER_DETAIL_SUCCESS:
      state.brandOwnerDetailLoading = false;
      state.brandOwnerDetail = action.payload;
      break;

    case GET_BRAND_OWNER_DETAIL_ERROR:
      state.brandOwnerDetailLoading = false;
      break;

    case GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST:
      state.orderListForBrandOwner = {};
      break;

    case GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS:
      state.orderListForBrandOwner = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
