import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, Typography, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { TextField } from 'components/Form';
import validate from './validate';

const SignupForm = ({ handleSubmit, actionLoading }) => {
  const [checked, setCheck] = useState(false);
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Field
        name="username"
        type="text"
        placeholder="メールアドレス"
        component={TextField}
      />
      <Field
        name="password"
        type="password"
        placeholder="パスワード"
        component={TextField}
      />
      <Field
        name="passwordConfirm"
        type="password"
        placeholder="パスワード（確認）"
        component={TextField}
      />
      <Form.Item>
        <Checkbox onChange={() => setCheck(!checked)} checked={checked}>
          <Typography.Text className="block" style={{ fontSize: 12 }}>
            登録をもって、{' '}
            <a
              href={process.env.REACT_APP_TERM_URL}
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </a>{' '}
            と{' '}
            <a
              href={process.env.REACT_APP_PRIVACY_URL}
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
            <br />
            に同意したとみなします
          </Typography.Text>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          className="full-w"
          type="primary"
          htmlType="submit"
          loading={actionLoading}
          disabled={!checked}
        >
          同意してサインアップする
        </Button>
      </Form.Item>
    </Form>
  );
};

SignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.any,
};

export default reduxForm({ form: 'signupForm', validate })(SignupForm);
