import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';

import { getSupplierListRequest } from 'providers/SupplierProvider/actions';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import { MultipleSelectionWithRemoteSearch } from 'components/Form';

import './style.scss';

const SupplierBrandCreateForm = ({
  handleSubmit,
  onCancel,
  createActionLoading,
  brandId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSupplierListRequest({
        checkConnectionWithBrandId: brandId,
        limit: 10000,
      })
    );
  }, [brandId]);

  const getSupplierListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getSupplierListRequest({
          ...data,
          checkConnectionWithBrandId: brandId,
          limit: 10000,
        })
      ),
      500
    ),
    []
  );

  const onSearchEmailKeyWork = (value) => {
    const data = {};
    if (value !== '') {
      data.emailKeyword = value;
    }
    getSupplierListWithDelay(data);
  };

  const { list } = useSelector(
    (state) => state.supplierProvider.supplierList,
    shallowEqual
  );

  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <div>
        <Field
          label="メールアドレス"
          name="suppliers"
          placeholder="email@mail.com"
          options={_map(list, (item) => ({
            ...item,
            isAdded: !!item?.supplierBrand,
          }))}
          onSearch={onSearchEmailKeyWork}
          component={MultipleSelectionWithRemoteSearch}
          defaultImage={DefaultSupplierImage}
        />
      </div>
      <div id="supplier-brand-create-action">
        <Space
          direction="horizontal"
          size="middle"
          className="full-w flex justify-end"
        >
          <Button
            className="form-button"
            onClick={onCancel}
            disabled={createActionLoading}
          >
            キャンセル
          </Button>
          <Button
            type="primary"
            className="form-button"
            htmlType="submit"
            loading={createActionLoading}
          >
            送信
          </Button>
        </Space>
      </div>
    </Form>
  );
};

SupplierBrandCreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  createActionLoading: PropTypes.any,
  brandId: PropTypes.any,
};

export default reduxForm({ form: 'supplierBrandCreateForm' })(
  SupplierBrandCreateForm
);
