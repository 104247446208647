import {
  Button, Modal, Space, Typography
} from 'antd';
import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Text } = Typography;

const DeleteCollectionModal = ({ collectionName, setVisible, onDelete }) => {
  const handleDeleteCollectionAndRemoveProduct = () => {
    onDelete({
      removeRestaurantMenu: true,
    });
  };

  const handleDeleteCollectionOnly = () => {
    onDelete({
      removeRestaurantMenu: false,
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      centered
      visible
      footer={null}
      closable={false}
      onCancel={onCancel}
      className="delete-collection-modal"
      width={420}
    >
      <Space className="flex justify-start items-start" size={16}>
        <WarningFilled style={{ color: 'orange', fontSize: 24 }} />
        <Space direction="vertical" className="flex full-w" size={0}>
          <Text strong>コレクションを削除します?</Text>
          <Text>
            <span style={{ fontWeight: 500 }}>{collectionName}</span> が削除されます。
          </Text>
          <ul>
            <li>
              <span style={{ fontWeight: 500 }}>「コレクションのみ削除」</span>{' '}
              を選択すると、コレクションは削除しますが、リンクしているレストランの商品は削除されません。
            </li>
            <li>
              <span style={{ fontWeight: 500 }}>
                「コレクションとリンク商品を削除」
              </span>{' '}
              を選択すると、コレクションとコレクションにリンクしているレストラン商品を削除します。
            </li>
          </ul>
        </Space>
      </Space>
      <Space direction="vertical" className="full-w actions-container">
        <Button
          onClick={handleDeleteCollectionOnly}
          type="primary"
          danger
          block
        >
          コレクションのみ削除
        </Button>
        <Button onClick={handleDeleteCollectionAndRemoveProduct} danger block>
          コレクションとリンク商品を削除
        </Button>
        <Button onClick={onCancel} block>
          キャンセル
        </Button>
      </Space>
    </Modal>
  );
};

DeleteCollectionModal.propTypes = {
  collectionName: PropTypes.string,
  setVisible: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteCollectionModal;
