import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card
} from 'antd';

import Table from 'components/Table';
import { USER_ROLE } from 'utils/constants';
import useTableControl from 'components/Hooks/useTableControl';
import { getRestaurantMenuItemRequest } from 'providers/RestaurantProvider/actions';
import Columns from './Columns';

const AssignedRestaurant = ({ match }) => {
  const [tableLoading, setTableLoading] = useState(false);

  const assignedMenuItemList = useSelector(
    (state) => state.restaurantProvider.restaurantMenuItemList,
    shallowEqual
  );

  const assignedMenuItemTotal = useSelector(
    (state) => state.restaurantProvider.restaurantMenuItemTotal,
    shallowEqual
  );

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const formattedMenuId = _get(match, 'params.productId');

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl();

  const { page, limit } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getRestaurantMenuItemRequest({
            page,
            limit,
            formattedMenuId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, formattedMenuId]);

  const columns = {
    [USER_ROLE.SUPPLIER]: [
      Columns.id,
      Columns.restaurantInfo,
      Columns.invitedDate,
      Columns.connectedDate,
      Columns.numberOfProduct,
      Columns.price,
    ],
    [USER_ROLE.OPERATOR]: [
      Columns.id,
      Columns.restaurantInfo,
      Columns.restaurantCreatedDate,
    ],
  };

  const scrollX = {
    [USER_ROLE.SUPPLIER]: 1000,
    [USER_ROLE.OPERATOR]: 600
  };

  return (
    <Card
      className="bg-transparent"
      bodyStyle={{ paddingTop: 16 }}
      bordered={false}
    >
      <Card className="highlight-card padding-card" bordered={false}>
        <Table
          columns={columns[currentUser?.role]}
          data={assignedMenuItemList}
          total={assignedMenuItemTotal}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: scrollX[currentUser?.role] }}
        />
      </Card>
    </Card>
  );
};

AssignedRestaurant.propTypes = {
  match: PropTypes.any,
};
export default AssignedRestaurant;
