import _isEmpty from 'lodash/isEmpty';
import validator from 'validator';

import Constants from 'utils/constants';
import Regexs from 'utils/regex';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg',
];

const validate = (values) => {
  const errors = {};

  if (!values.email?.trim()) {
    errors.email = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!validator.isEmail(values.email?.trim())) {
    errors.email = '無効なメールアドレスです';
  }
  if (!values.name?.trim()) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.nameKana?.trim()) {
    errors.nameKana = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regexs.REGX_KATAKANA.test(values.nameKana?.trim())) {
    errors.nameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (
    values.managerNameKana?.trim()
    && !Regexs.REGX_KATAKANA.test(values.managerNameKana?.trim())
  ) {
    errors.managerNameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (![true, false].includes(values.isVisibleToRestaurant)) {
    errors.isVisibleToRestaurant = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (
    values.emergencyPhone?.trim()
    && !Regexs.PHONE.test(values.emergencyPhone?.trim())
  ) {
    errors.emergencyPhone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (!_isEmpty(values.image)) {
    if (values.image.length > 1) {
      errors.image = Constants.ERROR_MESSAGE.INVALID;
    }

    const image = values.image[0];
    if (image.originFileObj) {
      if (!acceptedFileExtensions.includes(image.originFileObj.type)) {
        errors.image = 'ファイル拡張子が正しくありません';
      }
      if (image.originFileObj.size > 5e6) {
        // Limit size to 5MB
        errors.image = `${5e6 / 1e6}MB以下のファイルを指定してください`;
      }
    }
  }

  return errors;
};

export default validate;
