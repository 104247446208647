export const GET_IN_APP_MESSAGE_LIST_REQUEST = 'InAppMessageProvider/GET_IN_APP_MESSAGE_LIST_REQUEST';
export const GET_IN_APP_MESSAGE_LIST_SUCCESS = 'InAppMessageProvider/GET_IN_APP_MESSAGE_LIST_SUCCESS';
export const GET_IN_APP_MESSAGE_LIST_ERROR = 'InAppMessageProvider/GET_IN_APP_MESSAGE_LIST_ERROR';

export const GET_IN_APP_MESSAGE_DETAIL_REQUEST = 'InAppMessageProvider/GET_IN_APP_MESSAGE_DETAIL_REQUEST';
export const GET_IN_APP_MESSAGE_DETAIL_SUCCESS = 'InAppMessageProvider/GET_IN_APP_MESSAGE_DETAIL_SUCCESS';
export const GET_IN_APP_MESSAGE_DETAIL_ERROR = 'InAppMessageProvider/GET_IN_APP_MESSAGE_DETAIL_ERROR';

export const CREATE_IN_APP_MESSAGE_REQUEST = 'InAppMessageProvider/CREATE_IN_APP_MESSAGE_REQUEST';
export const CREATE_IN_APP_MESSAGE_SUCCESS = 'InAppMessageProvider/CREATE_IN_APP_MESSAGE_SUCCESS';
export const CREATE_IN_APP_MESSAGE_ERROR = 'InAppMessageProvider/CREATE_IN_APP_MESSAGE_ERROR';

export const STOP_IN_APP_MESSAGE_REQUEST = 'InAppMessageProvider/STOP_IN_APP_MESSAGE_REQUEST';
export const STOP_IN_APP_MESSAGE_SUCCESS = 'InAppMessageProvider/STOP_IN_APP_MESSAGE_SUCCESS';
export const STOP_IN_APP_MESSAGE_ERROR = 'InAppMessageProvider/STOP_IN_APP_MESSAGE_ERROR';
