import moment from 'moment';
import _get from 'lodash/get';
import React from 'react';
import {
  Typography, Space, Avatar, Tooltip
} from 'antd';

import CardInfo from 'components/CardInfo';
import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT, PAYMENT_STATUS } from 'utils/constants';

import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import premiumTag from 'images/tag-premium.svg';
import basicIcon from 'images/icon-basic.svg';

const columns = {};

columns.ID = {
  title: 'ID',
  width: 110,
  dataIndex: 'formattedObjectId',
  key: 'formattedObjectId',
};

columns.info = {
  title: 'レストラン名',
  width: 250,
  render: (record) => (
    <div className="flex items-center full-w">
      <Avatar
        alt="name"
        src={_get(record, 'image', DefaultRestaurantImage)}
        size={32}
      />
      <Space
        direction="vertical"
        size={0}
        className="restaurant-info-wrapper"
      >
        <Tooltip title={record?.name}>
          <Typography.Text
            ellipsis
          >{_get(record, 'name')}
          </Typography.Text>
        </Tooltip>
        <Space size={2}>
          {_get(record, 'emailVerified') ? (
            <Tooltip placement="topLeft" title="Email is verified">
              <img alt="verified-icon" src={VerifiedImage} />
            </Tooltip>
          ) : (
            <Tooltip
              placement="topLeft"
              title="Email has not been verified"
            >
              <img alt="warning-icon" src={WarningImage} />
            </Tooltip>
          )}
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {_get(record, 'email', '-')}
          </Typography.Text>
        </Space>
      </Space>
    </div>
  ),
};

columns.createdAt = {
  title: '登録日',
  width: 150,
  dataIndex: 'createdAt',
  key: 'createdAt',
  render: (record) => moment(record).format(DATE_TIME_FORMAT),
};

columns.supplierCount = {
  title: '販売者件数',
  width: 120,
  dataIndex: 'supplierCount',
  key: 'supplierCount',
  align: 'right',
  render: (record) => Helpers.numberWithCommas(record || 0),
};

columns.productCount = {
  title: '商品件数',
  width: 120,
  dataIndex: 'menuCount',
  key: 'menuCount',
  align: 'right',
  render: (record) => Helpers.numberWithCommas(record || 0),
};

columns.status = {
  title: 'ステータス',
  width: 150,
  dataIndex: 'status',
  key: 'status',
  fixed: 'right',
};

columns.invitedDate = {
  title: '招待日',
  width: 150,
  dataIndex: ['statusLog', 'PENDING'],
  key: 'invitedAt',
  render: (record) => (record
    ? moment(record).format(DATE_TIME_FORMAT) : '-'),
};

columns.connectedDate = {
  title: '承認日',
  width: 150,
  dataIndex: ['statusLog', 'ACCEPTED'],
  key: 'connectedAt',
  render: (record) => (record
    ? moment(record).format(DATE_TIME_FORMAT) : '-'),
};

columns.subscription = {
  title: '契約プラン',
  dataIndex: ['restaurant_membership', 'membership_tier'],
  key: 'subscription',
  width: 130,
  render: (record) => (record === 1 ? (
    <Space size={4} className="flex items-center">
      <img src={basicIcon} alt="basic-icon" />
      <Typography.Text>ベーシック</Typography.Text>
    </Space>
  ) : <img src={premiumTag} alt="premium-tag" />),
};

columns.subscriptionPayment = {
  title: '月額料金支払方法',
  dataIndex: 'restaurant_membership',
  key: 'subscriptionPayment',
  width: 180,
  render: (record) => {
    const paymentMethod = _get(record, 'payment_method');
    const cardNumber = _get(record, 'veritrans_card_info.cardNumber');
    const niceType = Helpers.getCardType(cardNumber);
    const isPremium = _get(record, 'membership_tier') === 2;

    if (!isPremium) return null;

    return (
      <CardInfo
        cardNumber={cardNumber?.slice(-4)}
        method={paymentMethod}
        niceType={niceType}
      />
    );
  },
};

columns.paymentError = {
  title: '月額決済エラー',
  dataIndex: ['restaurant_membership', 'payment_status'],
  key: 'paymentError',
  width: 130,
  render: (record) => (record === PAYMENT_STATUS.FAILED || record === PAYMENT_STATUS.UNPAID) && (
    <div
      role="button"
      className="status-container"
    >
      <div>
        <span
          className="dot error"
        />
        <span>エラー</span>
      </div>
    </div>
  ),
};

export default columns;
