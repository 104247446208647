import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, replace } from 'connected-react-router';
import {
  Button, Space, Typography, Card
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import {
  signupRequest,
  getCurrentUserRequest,
} from 'providers/AuthProvider/actions';

import SignupForm from './SignupForm';

import '../style.less';

const Signup = () => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSignup = async (data) => {
    setActionLoading(true);
    try {
      await dispatch(signupRequest(data));
      setActionLoading(false);
      dispatch(getCurrentUserRequest());

      dispatch(replace('/reg/role'));
    } catch (err) {
      setActionLoading(false);
    }
  };

  return (
    <Card className="highlight-card" style={{ minHeight: 486 }} bordered={false}>
      <Space direction="vertical" size={32} className="full-w">
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          style={{ padding: 0 }}
          onClick={() => {
            dispatch(goBack());
          }}
        >
          ログインに戻る
        </Button>
        <Space direction="vertical" className="full-w">
          <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
            アカウントを作成する
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            ログインに利用するメールアドレス・パスワードを設定してください
          </Typography.Text>
        </Space>
        <SignupForm onSubmit={handleSignup} actionLoading={actionLoading} />
      </Space>
    </Card>
  );
};

export default Signup;
