/* eslint-disable camelcase */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal, Button, Typography, Switch, Space
} from 'antd';
import { Form } from '@ant-design/compatible';
import { reduxForm, Field, submit } from 'redux-form';
import _get from 'lodash/get';

import Helpers from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';
import { DatePickerField } from 'components/Form';
import { updateMenuItemRequest } from 'providers/MenuProvider/actions';

import './style.less';

const ArrivalDateModal = ({ title, actionLoading, onCancel }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => dispatch(submit('arrivalDateForm'));
  const handleDisableDate = (current) => current && current < moment().endOf('day');
  return (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      okText="追加"
      cancelText="キャンセル"
      centered
      destroyOnClose
      maskClosable={false}
      onOk={handleSubmit}
      okButtonProps={{
        loading: actionLoading,
        disabled: actionLoading,
      }}
      cancelButtonProps={{
        disabled: actionLoading,
      }}
      className="arrival-date-modal"
    >
      <Form layout="vertical">
        <Typography.Paragraph>
          次回入荷予定日を設定することができます。日付を選択してください。
          <br />
          入荷予定日はアプリにも表示されます。
          <br />
          入荷予定日が未定の場合は日付未入力のまま追加も可能です。
        </Typography.Paragraph>
        <Field
          name="stock_arrivalDate"
          component={DatePickerField}
          placeholder="日付を選択"
          disabledDate={handleDisableDate}
        />
      </Form>
    </Modal>
  );
};

export const ArrivalDateForm = reduxForm({ form: 'arrivalDateForm' })(
  ArrivalDateModal
);

ArrivalDateModal.propTypes = {
  title: PropTypes.string,
  actionLoading: PropTypes.bool,
  onCancel: PropTypes.func,
};

const StatusToggle = ({
  menuDetail,
  fetchData,
  showInStock = true,
  isMenuDetail,
}) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showArrivalDateModal, setShowArrivalDateModal] = useState(false);

  let warehouseMenus = menuDetail?.warehouseMenus || [];
  const formattedObjectId = menuDetail?.formattedObjectId;
  const warehouseAirLogiIntegrated = menuDetail?.warehouse_airLogiIntegrated;

  const { stock_isSoldOut, stock_quantity, stock_arrivalDate } = _get(
    menuDetail,
    'warehouseMenus[0]',
    {}
  );
  const [isSoldOut, setIsSoldOut] = useState(stock_isSoldOut);
  const stock = {
    OSAKA: '大阪',
    ODAWARA: '小田原',
  };

  const buttonText = stock_arrivalDate
    ? `入荷予定日${moment(stock_arrivalDate).format(DATE_FORMAT)}`
    : '売り切れの設定';

  const changeStatusMenuItem = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(updateMenuItemRequest(params));
      fetchData();
    } catch (error) {
      console.error(error);
    }
    setShowArrivalDateModal(false);
    setChangeStatusLoading(false);
  };

  const handleSubmit = ({ stock_arrivalDate: arrivalDate }) => {
    changeStatusMenuItem({
      formattedObjectId,
      stock_arrivalDate: arrivalDate || '',
      stock_isSoldOut: isSoldOut,
    });
  };

  const onSwitchChange = (value) => {
    if (!value) {
      setShowArrivalDateModal(true);
      setIsSoldOut(true);
    } else {
      setIsSoldOut(false);
      changeStatusMenuItem({
        formattedObjectId,
        stock_isSoldOut: !value,
      });
    }
  };

  const handleCancel = () => setShowArrivalDateModal(false);
  const handleShowModal = () => setShowArrivalDateModal(true);

  const warehouse = (
    <div className="status in-stock">
      {stock_quantity >= 0
        ? `${Helpers.numberWithCommas(stock_quantity)} in stock`
        : '在庫管理しない'}
    </div>
  );
  if (_get(warehouseMenus, '[0.airLogi_warehouseKey') === 'OSAKA') {
    warehouseMenus = [...warehouseMenus].reverse();
  }
  const warehouseAirLogi = warehouseMenus.map((item, i) => (
    <div className="status in-stock" key={i}>
      {`${
        stock[item.airLogi_warehouseKey]
      }: ${Helpers.numberWithCommas(item.stock_quantity) || 0}`}
    </div>
  ));

  if (isMenuDetail) {
    return (
      <div>
        {showArrivalDateModal && (
          <ArrivalDateForm
            title="売り切れの設定"
            onCancel={handleCancel}
            onOk={handleSubmit}
            onSubmit={handleSubmit}
            initialValues={
              stock_arrivalDate
              && moment(stock_arrivalDate) > moment().endOf('day') && {
                stock_arrivalDate: moment(stock_arrivalDate),
              }
            }
            actionLoading={changeStatusLoading}
          />
        )}
        <Form.Item label="ステータス">
          <Space>
            <div className="status-wrapper">
              <div className={`status ${stock_isSoldOut ? 'sold-out' : ''}`}>
                {stock_isSoldOut ? '売り切れ' : '在庫あり'}
              </div>
            </div>
            <Switch
              size="small"
              checked={!stock_isSoldOut}
              onChange={onSwitchChange}
              loading={changeStatusLoading}
            />
          </Space>
        </Form.Item>
        <Form.Item label="入荷予定日">
          <Button
            type="link"
            onClick={handleShowModal}
            loading={changeStatusLoading}
          >
            {buttonText}
          </Button>
        </Form.Item>
      </div>
    );
  }

  return (
    <div
      role="button"
      className="inventory-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-hidden
    >
      {showArrivalDateModal && (
        <ArrivalDateForm
          title="売り切れの設定"
          onCancel={handleCancel}
          onOk={handleSubmit}
          onSubmit={handleSubmit}
          initialValues={
            stock_arrivalDate
            && moment(stock_arrivalDate) > moment().endOf('day') && {
              stock_arrivalDate: moment(stock_arrivalDate),
            }
          }
          actionLoading={changeStatusLoading}
        />
      )}
      <Space>
        <div className="status-wrapper">
          <div className={`status ${stock_isSoldOut ? 'sold-out' : ''}`}>
            {stock_isSoldOut ? '売り切れ' : '在庫あり'}
          </div>
        </div>
        <Switch
          size="small"
          checked={!stock_isSoldOut}
          onChange={onSwitchChange}
          loading={changeStatusLoading}
        />
      </Space>
      {showInStock
        && (warehouseAirLogiIntegrated ? warehouseAirLogi : warehouse)}
      <Button
        type="link"
        onClick={handleShowModal}
        loading={changeStatusLoading}
        className="arrival-date-button"
      >
        {buttonText}
      </Button>
    </div>
  );
};

StatusToggle.propTypes = {
  menuDetail: PropTypes.object,
  fetchData: PropTypes.func,
  showInStock: PropTypes.bool,
  isMenuDetail: PropTypes.bool,
};

export default StatusToggle;
