import _get from 'lodash/get';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'antd';

import toastMessage from 'utils/toastMessage';

import {
  createSupplierBrandRequest,
  getSupplierBrandListRequest,
} from 'providers/SupplierBrandProvider/actions';

import SupplierBrandCreateForm from './SupplierBrandCreateForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const CreateSupplierBrand = ({ handleCloseDialog, visible, brand }) => {
  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );

  const brandName = _get(brandDetail, 'name');

  const handleCancel = () => {
    handleCloseDialog();
  };

  const dispatch = useDispatch();

  const [createActionLoading, setCreateActionLoading] = useState(false);

  const handleSubmit = async (value) => {
    setCreateActionLoading(true);
    try {
      const result = await dispatch(
        createSupplierBrandRequest({ ...value, brand })
      );
      handleCloseDialog();
      dispatch(
        getSupplierBrandListRequest({ brandId: brand, page: 1, limit: 10 })
      );
      const count = result.length;
      toastMessage.success({
        message: '成功',
        description: `${count}件の販売者をブランド${brandName} に追加しました。`,
      });
    } catch (error) {
      console.error(error);
    }
    setCreateActionLoading(false);
  };

  return (
    <Modal
      title={`${brandName}に販売者を追加します。`}
      visible={visible}
      onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
      width={578}
    >
      <p>
        このブランドに販売者を追加することができます。 <br></br>
        一度に複数のメールアドレスを入力することができます<br></br>
        追加した販売者はこのブランドのメニューを登録することができます。
      </p>
      <SupplierBrandCreateForm
        onSubmit={handleSubmit}
        onCancel={() => showConfirm('中断してよろしいですか？', handleCancel)}
        createActionLoading={createActionLoading}
        brandId={brand}
      />
    </Modal>
  );
};

CreateSupplierBrand.propTypes = {
  handleCloseDialog: PropTypes.func,
  visible: PropTypes.bool,
  brand: PropTypes.any,
};

export default CreateSupplierBrand;
