import produce from 'immer';
import _find from 'lodash/find';

import {
  GET_RANKING_LIST_REQUEST,
  GET_RANKING_LIST_SUCCESS,
  GET_RANKING_LIST_ERROR,
  GET_RANKING_DETAIL_REQUEST,
  GET_RANKING_DETAIL_SUCCESS,
  GET_RANKING_DETAIL_ERROR,
  UPDATE_RANKING_SUCCESS,
  UPDATE_RANKING_LIST,
  UPDATE_RANKING_DETAIL,

} from './constants';

const initialState = {
  rankingList: [],
  rankingDetail: {},
  isLoading: false,
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_RANKING_LIST_REQUEST:
      state.rankingList = [];
      state.isLoading = true;
      break;

    case GET_RANKING_LIST_SUCCESS:
      state.rankingList = action.payload;
      state.isLoading = false;
      break;

    case GET_RANKING_LIST_ERROR:
      state.isLoading = false;
      break;
    case GET_RANKING_DETAIL_REQUEST:
      state.rankingDetail = {};
      state.isLoading = true;
      break;

    case GET_RANKING_DETAIL_SUCCESS:
      state.rankingDetail = action.payload;
      state.isLoading = false;
      break;
    case UPDATE_RANKING_SUCCESS: {
      const handleUpdateRanking = (key, value) => {
        state.rankingDetail[key] = value;

        _find(state.rankingList, (ranking) => {
          if (ranking.objectId === action.payload.objectId) {
            ranking[key] = value;
            return true;
          }
          return false;
        });
      };

      if ('status' in action.payload) {
        handleUpdateRanking('status', action.payload.status);
      }

      if ('isVisibleOnHome' in action.payload) {
        handleUpdateRanking('isVisibleOnHome', action.payload.isVisibleOnHome);
      }

      break;
    }

    case GET_RANKING_DETAIL_ERROR:
      state.isLoading = false;
      break;
    case UPDATE_RANKING_LIST:
      state.rankingList = action.payload;
      break;
    case UPDATE_RANKING_DETAIL:
      state.rankingDetail = {
        ...state.rankingDetail,
        products: action.payload,
      };
      break;

    default:
  }
  return state;
});

export default reducer;
