import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { goBack, push } from 'connected-react-router';
import { createRestaurantAnnouncementRequest } from 'providers/AnnouncementProvider/actions';

import AnnouncementCreate from './AnnouncementCreate';

const formSelector = formValueSelector('announcementCreateForm');

const mapStateToProps = createStructuredSelector({
  formScheduleValue: (state) => formSelector(state, 'schedule'),
});

const mapDispatchToProps = (dispatch) => ({
  createAnnouncement: (params) => dispatch(createRestaurantAnnouncementRequest(params)),
  goBack: (path) => dispatch(goBack(path)),
  push: (path) => dispatch(push(path)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(AnnouncementCreate);
