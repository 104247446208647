import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row, Col, Card, Space, Typography
} from 'antd';
import { useSelector } from 'react-redux';

import {
  DESTINATION_TYPE,
  EFFECTIVE_DATE,
  EXPIRED_DATE,
} from 'utils/constants';
import {
  TextField,
  DatePickerField,
  FileField,
  RadioGroup,
  ImageField,
} from 'components/Form';
import './style.less';
import ProductsField from 'containers/AdminPage/Content/Announcements/Create/ProductField';
import validate from './validate';

const { Text } = Typography;

const options = [
  {
    name: 'PDF/Images',
    objectId: DESTINATION_TYPE.FILE,
  },
  {
    name: (
      <Typography.Text
        ellipsis={{
          tooltip: 'シコメルストア商品',
        }}
      >
        シコメルストア商品
      </Typography.Text>),
    objectId: DESTINATION_TYPE.PRODUCT,
  },
  {
    name: 'URL',
    objectId: DESTINATION_TYPE.URL,
  }
];

const InAppMessageCreateForm = ({
  handleSubmit,
}) => {
  const formValues = useSelector((state) => getFormValues('InAppMessageCreateForm')(state));
  const isFile = formValues?.attachmentType === DESTINATION_TYPE.FILE;
  const isProduct = formValues?.attachmentType === DESTINATION_TYPE.PRODUCT;
  const productIds = formValues?.productIds || [];
  const isEffectiveDateSchedule = formValues?.effectiveDate === EFFECTIVE_DATE.SCHEDULE;
  const isExpiredDateSchedule = formValues?.expiredDate === EXPIRED_DATE.SCHEDULE;
  return (
    <Form
      colon={false}
      onSubmit={handleSubmit}
      layout="horizontal"
      labelCol={{
        xxl: { span: 4 },
        xl: { span: 6 },
        lg: { span: 8 },
        md: { span: 8 },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Card title="通知内容" bordered={false}>
            <Text>少なくとも１項目は入力必須</Text>
            <Field
              label="タイトル"
              name="title"
              placeholder="タイトル"
              disabled={formValues?.images?.length > 0}
              component={TextField}
              maxLength={100}
              showCount
            />
            <Field
              label="本文"
              name="message"
              placeholder="本文"
              component={TextField}
              disabled={formValues?.images?.length > 0}
              maxLength={500}
              showCount
              textArea
            />
            <Field
              label="画像"
              additionalContent={(
                <span style={{ lineHeight: 'normal', display: 'block' }}>
                  <Text type="secondary">
                    1080px以上を設定してください
                  </Text>
                </span>
              )}
              name="images"
              component={ImageField}
              disabled={formValues?.title || formValues?.message}
              maxCount={1}
              withCrop
              aspect={3 / 4}
              placeholder="ファイルを選択するか、
              ドロップしてアップロードする"
            />
          </Card>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Space direction="vertical" size={16} className="full-w flex">
            <Card title="詳細な遷移先" bordered={false}>
              <Form.Item label="遷移先タイプ" className="attachment-type">
                <Field
                  name="attachmentType"
                  component={RadioGroup}
                  options={options}
                  isButton
                />
                <Space direction="vertical" size={16}>
                  <Text
                    type="secondary"
                  >
                    {isFile && 'PDFまたは画像を選択してください。ユーザーが通知をタップするとファイルが開かれます。'}
                    {isProduct && 'Select a Shikomel Store product. The restaurant tap on the notification will open product detail in app. If the selected product is disable, the restaurant will see error message'}
                    {!isFile && !isProduct && '通知メッセージの詳細を見るボタンを押下した後に表示されるURLを入力してください。'}
                  </Text>
                  {isFile && (
                    <Field
                      name="file"
                      component={FileField}
                    />
                  )}
                  {isProduct && (
                    <ProductsField
                      placeholder="販売者名から検索"
                      productIds={productIds}
                    />
                  )}
                  {!isFile && !isProduct && (
                    <Field
                      name="targetUrl"
                      placeholder="URL"
                      component={TextField}
                    />
                  )}
                </Space>
              </Form.Item>
            </Card>
            <Card title="配信予約" bordered={false} id="form-schedule-items">
              <Space direction="vertical" size={24} className="full-w flex">
                <Form.Item label="配信開始日">
                  <Space direction="vertical" size={8} className="full-w flex">
                    <Field
                      name="effectiveDate"
                      component={RadioGroup}
                      fullWidth
                      options={[
                        {
                          name: '今すぐ',
                          objectId: EFFECTIVE_DATE.NOW,
                        },
                        {
                          name: '日時設定',
                          objectId: EFFECTIVE_DATE.SCHEDULE,
                        }
                      ]}
                      isButton
                    />
                    {
                      isEffectiveDateSchedule
                    && (
                      <Field
                        name="startDate"
                        placeholder="開始日"
                        dateFormat={'YYYY/MM/DD'}
                        component={DatePickerField}
                        disabledDate={(current) => current < moment().startOf('day')}
                        required
                      />
                    )
                    }
                  </Space>
                </Form.Item>
                <Form.Item label="配信終了日">
                  <Space direction="vertical" size={8} className="full-w flex">
                    <Field
                      name="expiredDate"
                      component={RadioGroup}
                      fullWidth
                      options={[
                        {
                          name: '期限なし',
                          objectId: EXPIRED_DATE.NEVER,
                        },
                        {
                          name: '日時設定',
                          objectId: EXPIRED_DATE.SCHEDULE,
                        },
                      ]}
                      isButton
                    />
                    {!isExpiredDateSchedule
                    && (
                      <Text
                        style={{
                          color: '#00000073'
                        }}
                      >この通知は後で手動で終了させることができます。
                      </Text>
                    )}
                    {isExpiredDateSchedule
                    && (
                      <Field
                        name="endDate"
                        placeholder="終了日"
                        dateFormat={'YYYY/MM/DD'}
                        component={DatePickerField}
                        disabledDate={(current) => current < moment().startOf('day') || (formValues?.startDate && current < formValues?.startDate)}
                        required
                      />
                    )}
                  </Space>
                </Form.Item>
              </Space>
            </Card>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

InAppMessageCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'InAppMessageCreateForm',
  validate,
  touchOnChange: true,
})(InAppMessageCreateForm);
