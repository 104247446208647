/* eslint-disable no-unused-vars */
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Space, Typography, Avatar
} from 'antd';

import Helpers from 'utils/helpers';
import Table from 'components/Table';
import { pagingGetMenuItemListRequest } from 'providers/MenuProvider/actions';
import useTableControl from 'components/Hooks/useTableControl';

import DefaultBrandImage from 'images/defaultBrand.svg';
import DefaultProductImage from 'images/defaultProduct.svg';
import ProductListSearchForm from './ProductListSearchForm';

const ProductList = () => {
  const dispatch = useDispatch();
  const { supplierId } = useParams();

  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );
  const [tableLoading, setTableLoading] = useState(false);
  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      brandId: 'bi',
    },
  });

  const {
    page, limit, orderBy, order
  } = pageControl;
  const { brandId } = filterControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          pagingGetMenuItemListRequest({
            page,
            limit,
            orderBy,
            order,
            supplierId,
            brandId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, supplierId, brandId]);

  const columns = [
    {
      title: '商品名',
      width: 220,
      textWrap: 'word-break',
      ellipsis: true,
      render: (record) => (
        <div
          className="flex items-center full-w"
        >
          <Avatar
            alt="name"
            src={_get(record.images[0], 'url', DefaultProductImage)}
            size={32}
            shape="square"
            style={{ marginRight: 8 }}
          />
          <Space
            direction="vertical"
            size={0}
            className="full-w"
            style={{ overflow: 'hidden', flex: 1 }}
          >
            <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
              {_get(record, 'standard', '-')} • {_get(record, 'amount', '-')} ／{' '}
              {_get(record, 'unit', '-')}
            </Typography.Text>
          </Space>
        </div>
      ),
      fixed: 'left',
    },
    {
      title: 'ブランド',
      dataIndex: 'brand',
      width: 250,
      key: 'brand',
      render: (record) => !_isEmpty(record) && (
        <div
          className="flex items-center full-w"
        >
          <Avatar src={_get(record, 'image', DefaultBrandImage)} size={32} />
          <Typography.Text
            ellipsis={{
              tooltip: _get(record, 'name'),
            }}
            style={{
              overflow: 'hidden',
              flex: 1,
              width: '70%',
            }}
          >{_get(record, 'name')}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: 'ブランドフィー',
      dataIndex: 'brandFee',
      key: 'brandFee',
      align: 'right',
      width: 150,
      render: (record) => record && `¥${Helpers.numberWithCommas(record)}`,
    },
  ];

  return (
    <Space direction="vertical" size="middle" className="full-w">
      <Card className="highlight-card padding-card-16" bordered={false}>
        <ProductListSearchForm {...{ filterControl, onFilterChange }} />
      </Card>
      <Card
        className="highlight-card padding-card"
        bordered={false}
      >
        <Table
          columns={columns}
          data={_get(productList, 'list', [])}
          total={_get(productList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
            marginRight: true,
          }}
          scroll={{ x: 520 }}
        />
      </Card>
    </Space>
  );
};

export default ProductList;
