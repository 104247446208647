import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resetMenuItemList } from 'providers/MenuProvider/actions';
import { resetCategoryList } from 'providers/CategoryProvider/actions';
import {
  getRestaurantMenuItemRequest,
  resetAllAssignedMenuItemList,
} from 'providers/RestaurantProvider/actions';
import {
  makeSelectRestaurantDetail,
  makeSelectRestaurantMenuItemList,
  makeSelectAssignedMenuItemList,
} from 'providers/RestaurantProvider/selectors';

import ProductUpdate from './ProductUpdate';

const mapStateToProps = createStructuredSelector({
  restaurantDetail: makeSelectRestaurantDetail(),
  restaurantMenuItemList: makeSelectRestaurantMenuItemList(),
  assignedMenuItemList: makeSelectAssignedMenuItemList(),
});

const mapDispatchToProps = (dispatch) => ({
  getRestaurantMenuItemRequest: (value) => dispatch(getRestaurantMenuItemRequest(value)),
  resetMenuItemList: () => dispatch(resetMenuItemList()),
  resetCategoryList: () => dispatch(resetCategoryList()),
  resetAllAssignedMenuItemList: () => dispatch(resetAllAssignedMenuItemList()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProductUpdate);
