import _get from 'lodash/get';
import _map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Switch, Route, useParams, useHistory
} from 'react-router-dom';
import { Tabs, Card, Spin } from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import withRefresh from 'components/HOCs/withRefresh';
import RestaurantHeader from 'containers/AdminPage/Content/Restaurants/Supplier_Detail/RestaurantHeader';

import { getRestaurantDetailRequest } from 'providers/RestaurantProvider/actions';

import indexRoutes from './routes';

const { TabPane } = Tabs;

const RestaurantDetail = () => {
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [restaurantDetailLoaded, setRestaurantDetailLoaded] = useState(false);
  const { restaurantId } = useParams();

  const restaurantListData = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );

  const fetchRestaurantDetail = useCallback(async () => {
    try {
      await dispatch(
        getRestaurantDetailRequest({
          formattedObjectId: restaurantId,
        })
      );
    } catch (error) {
      push('/restaurants');
    }
    setRestaurantDetailLoaded(true);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchRestaurantDetail();
  }, [fetchRestaurantDetail]);

  if (!restaurantDetailLoaded) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="restaurant-detail-page">
      <RestaurantHeader
        restaurantDetail={restaurantDetail}
        footer={(
          <>
            <TabPane tab="レストラン詳細" key="in-app" />
            <TabPane tab="販売者" key="supplier-list" />
          </>
        )}
        idList={_map(restaurantListData?.list, (item) => _get(item, 'restaurant.formattedObjectId')
        )}
        onBack={goBack}
      />
      <Card className="bg-transparent" bordered={false}>
        <Switch>
          {indexRoutes.map((route, key) => (
            <Route key={key} path={route.path} component={route.component} />
          ))}
        </Switch>
      </Card>
    </div>
  );
};

export default withRefresh('restaurantDetail')(RestaurantDetail);
