import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';
import RestaurantList from '../RestaurantList';
import CollectionInfo from '../CollectionInfo';

const RedirectToInAppConfig = (props) => {
  const { collectionId } = _get(props, 'match.params');
  return <Redirect to={`/collections/detail/${collectionId}/in-app`} />;
};

const indexRoutes = [
  {
    path: '/collections/detail/:collectionId/restaurant-list',
    component: RestaurantList,
  },
  {
    path: '/collections/detail/:collectionId/in-app',
    component: CollectionInfo,
  },
  {
    path: '/collections/detail/:collectionId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
