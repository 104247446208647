import {
  GET_RESTAURANT_OWNER_LIST_REQUEST,
  GET_RESTAURANT_OWNER_LIST_SUCCESS,
  GET_RESTAURANT_OWNER_LIST_ERROR,
  GET_RESTAURANT_OWNER_DETAIL_REQUEST,
  GET_RESTAURANT_OWNER_DETAIL_SUCCESS,
  GET_RESTAURANT_OWNER_DETAIL_ERROR,
  GET_RESTAURANT_BY_EMAIL_REQUEST,
  GET_RESTAURANT_BY_EMAIL_SUCCESS,
  GET_RESTAURANT_BY_EMAIL_ERROR,
  RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST,
  RESTAURANT_OWNER_INVITE_RESTAURANT_SUCCESS,
  RESTAURANT_OWNER_INVITE_RESTAURANT_ERROR,
  RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST,
  RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_SUCCESS,
  RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_ERROR,
  UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST,
  UPDATE_RESTAURANT_INVITATION_STATUS_SUCCESS,
  UPDATE_RESTAURANT_INVITATION_STATUS_ERROR,
  UPDATE_RESTAURANT_OWNER_ERROR,
  UPDATE_RESTAURANT_OWNER_SUCCESS,
  UPDATE_RESTAURANT_OWNER_REQUEST,
  DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
  DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS,
  DISABLE_RESTAURANT_OWNER_ACCOUNT_ERROR,
  ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
  ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS,
  ENABLE_RESTAURANT_OWNER_ACCOUNT_ERROR,
} from './constants';

export function getRestaurantOwnerListRequest(payload) {
  return {
    type: GET_RESTAURANT_OWNER_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantOwnerListSuccess(payload, meta) {
  return { type: GET_RESTAURANT_OWNER_LIST_SUCCESS, payload, meta };
}

export function getRestaurantOwnerListError(payload, meta) {
  return {
    type: GET_RESTAURANT_OWNER_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
export function getRestaurantOwnerDetailRequest(payload) {
  return {
    type: GET_RESTAURANT_OWNER_DETAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantOwnerDetailSuccess(payload, meta) {
  return { type: GET_RESTAURANT_OWNER_DETAIL_SUCCESS, payload, meta };
}

export function getRestaurantOwnerDetailError(payload, meta) {
  return {
    type: GET_RESTAURANT_OWNER_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantByEmailRequest(payload) {
  return {
    type: GET_RESTAURANT_BY_EMAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantByEmailSuccess(payload, meta) {
  return { type: GET_RESTAURANT_BY_EMAIL_SUCCESS, payload, meta };
}

export function getRestaurantByEmailError(payload, meta) {
  return {
    type: GET_RESTAURANT_BY_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function restaurantOwnerInviteRestaurantRequest(payload) {
  return {
    type: RESTAURANT_OWNER_INVITE_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function restaurantOwnerInviteRestaurantSuccess(payload, meta) {
  return { type: RESTAURANT_OWNER_INVITE_RESTAURANT_SUCCESS, payload, meta };
}

export function restaurantOwnerInviteRestaurantError(payload, meta) {
  return {
    type: RESTAURANT_OWNER_INVITE_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function restaurantOwnerRequestConnectToRestaurantRequest(payload) {
  return {
    type: RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function restaurantOwnerRequestConnectToRestaurantSuccess(
  payload,
  meta
) {
  return {
    type: RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_SUCCESS,
    payload,
    meta,
  };
}

export function restaurantOwnerRequestConnectToRestaurantError(payload, meta) {
  return {
    type: RESTAURANT_OWNER_REQUEST_CONNECT_TO_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRestaurantInvitationStatusRequest(payload) {
  return {
    type: UPDATE_RESTAURANT_INVITATION_STATUS_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateRestaurantInvitationStatusSuccess(payload, meta) {
  return { type: UPDATE_RESTAURANT_INVITATION_STATUS_SUCCESS, payload, meta };
}

export function updateRestaurantInvitationStatusError(payload, meta) {
  return {
    type: UPDATE_RESTAURANT_INVITATION_STATUS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRestaurantOwnerRequest(payload) {
  return {
    type: UPDATE_RESTAURANT_OWNER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateRestaurantOwnerSuccess(payload, meta) {
  return { type: UPDATE_RESTAURANT_OWNER_SUCCESS, payload, meta };
}

export function updateRestaurantOwnerError(payload, meta) {
  return {
    type: UPDATE_RESTAURANT_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function disableRestaurantOwnerAccountRequest(payload) {
  return {
    type: DISABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function disableRestaurantOwnerAccountSuccess(payload, meta) {
  return { type: DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS, payload, meta };
}

export function disableRestaurantOwnerAccountError(payload, meta) {
  return {
    type: DISABLE_RESTAURANT_OWNER_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function enableRestaurantOwnerAccountRequest(payload) {
  return {
    type: ENABLE_RESTAURANT_OWNER_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function enableRestaurantOwnerAccountSuccess(payload, meta) {
  return { type: ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS, payload, meta };
}

export function enableRestaurantOwnerAccountError(payload, meta) {
  return {
    type: ENABLE_RESTAURANT_OWNER_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}
