export const GET_RANKING_LIST_REQUEST = 'RankingProvider/GET_RANKING_LIST_REQUEST';
export const GET_RANKING_LIST_SUCCESS = 'RankingProvider/GET_RANKING_LIST_SUCCESS';
export const GET_RANKING_LIST_ERROR = 'RankingProvider/GET_RANKING_LIST_ERROR';

export const UPDATE_RANKING_LIST = 'RankingProvider/UPDATE_RANKING_LIST';
export const UPDATE_RANKING_DETAIL = 'RankingProvider/UPDATE_RANKING_DETAIL';

export const GET_RANKING_DETAIL_REQUEST = 'RankingProvider/GET_RANKING_DETAIL_REQUEST';
export const GET_RANKING_DETAIL_SUCCESS = 'RankingProvider/GET_RANKING_DETAIL_SUCCESS';
export const GET_RANKING_DETAIL_ERROR = 'RankingProvider/GET_RANKING_DETAIL_ERROR';

export const UPDATE_RANKING_REQUEST = 'RankingProvider/UPDATE_RANKING_REQUEST';
export const UPDATE_RANKING_SUCCESS = 'RankingProvider/UPDATE_RANKING_SUCCESS';
export const UPDATE_RANKING_ERROR = 'RankingProvider/UPDATE_RANKING_ERROR';

export const REORDER_RANKING_LIST_REQUEST = 'RankingProvider/REORDER_RANKING_LIST_REQUEST';
export const REORDER_RANKING_LIST_SUCCESS = 'RankingProvider/REORDER_RANKING_LIST_SUCCESS';
export const REORDER_RANKING_LIST_ERROR = 'RankingProvider/REORDER_RANKING_LIST_ERROR';

export const REORDER_RANKING_MENU_REQUEST = 'RankingProvider/REORDER_RANKING_MENU_REQUEST';
export const REORDER_RANKING_MENU_SUCCESS = 'RankingProvider/REORDER_RANKING_MENU_SUCCESS';
export const REORDER_RANKING_MENU_ERROR = 'RankingProvider/REORDER_RANKING_MENU_ERROR';

export const REMOVE_RANKING_MENU_REQUEST = 'RankingProvider/REMOVE_RANKING_MENU_REQUEST';
export const REMOVE_RANKING_MENU_SUCCESS = 'RankingProvider/REMOVE_RANKING_MENU_SUCCESS';
export const REMOVE_RANKING_MENU_ERROR = 'RankingProvider/REMOVE_RANKING_MENU_ERROR';

export const ADD_RANKING_MENU_REQUEST = 'RankingProvider/ADD_RANKING_MENU_REQUEST';
export const ADD_RANKING_MENU_SUCCESS = 'RankingProvider/ADD_RANKING_MENU_SUCCESS';
export const ADD_RANKING_MENU_ERROR = 'RankingProvider/ADD_RANKING_MENU_ERROR';
