import _pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { isDirty, reset, submit } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  Card,
  Button,
  Affix,
  Space,
  Modal,
  Spin,
  Typography,
  Row,
  Col,
} from 'antd';
import { useParams } from 'react-router-dom';

import { doRefresh } from 'providers/CommonProvider/actions';
import {
  getRestaurantDetailRequest,
  updateBillingInfoRequest,
} from 'providers/RestaurantProvider/actions';

import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';
import ProfileUpdateForm from './BillingForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const Billing = () => {
  const dispatch = useDispatch();
  const profileDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );
  const { restaurantId } = useParams();

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const dirty = useSelector((state) => isDirty('billingForm')(state));

  const fetchRestaurantDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(
        getRestaurantDetailRequest({
          formattedObjectId: restaurantId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchRestaurantDetail();
  }, []);

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(
        updateBillingInfoRequest({
          ...values,
          restaurantId: profileDetail?.objectId,
        })
      );
      dispatch(doRefresh({ target: 'billingDetail' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const breadcrumbItems = [
    {
      path: '/restaurants',
      breadcrumbName: 'レストラン',
    },
    {
      path: `/detail/${restaurantId}`,
      breadcrumbName: profileDetail?.name,
    },
    {
      breadcrumbName: '請求先情報',
    },
  ];

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    dispatch(goBack());
  };

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title="請求先情報"
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <ProfileUpdateForm
          onSubmit={handleSubmit}
          initialValues={{
            ..._pick(profileDetail, [
              'billingName',
              'billingPostalCode',
              'billingPrefecture',
              'billingAddress',
              'billingBuildingName',
              'billingPhone',
              'billingManagerName',
              'bankAccountName',
              'bankAccountNameKana',
            ]),
          }}
          billingDetail={{
            billingPostalCode: profileDetail?.postalCode,
            billingPrefecture: profileDetail?.prefecture,
            billingAddress: profileDetail?.address,
            billingBuildingName: profileDetail?.buildingName,
            billingPhone: profileDetail?.phone,
          }}
        />
      </Card>
      {dirty && (
        <Affix offsetBottom={0} className="full-w">
          <div className="full-w" style={{ background: '#293137' }}>
            <Row>
              <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                <Space
                  size={8}
                  className="flex justify-between max-width-644"
                  style={{ padding: '16px 16px 16px 32px' }}
                >
                  <Typography.Text style={{ color: '#fff' }}>
                    保存されていない変更
                  </Typography.Text>
                  <Space size={8} className="full-w flex justify-end">
                    <Button
                      type="default"
                      disabled={actionLoading}
                      onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('billingForm'))
                      )}
                    >
                      取り消す
                    </Button>
                    <Button
                      type="primary"
                      loading={actionLoading}
                      onClick={() => dispatch(submit('billingForm'))}
                    >
                      保存する
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </Affix>
      )}
    </>
  );
};

export default withRefresh('billingDetail')(Billing);
