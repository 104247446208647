import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route, useLocation, Redirect
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import BottomLeft from 'images/AuthPage/bottom-left.svg';
import BottomRight from 'images/AuthPage/bottom-right.svg';
import TextLogo from 'images/AuthPage/text-logo.svg';

import FooterBar from 'components/FooterBar';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import ResetPasswordPage from './ResetPasswordPage';
import ResetPasswordSuccessPage from './ResetPasswordPage/Success';

import './style.less';

const NextArrow = ({ className, style, onClick }) => (
  <Button
    type="link"
    icon={<RightOutlined />}
    className={`${className} arrow`}
    style={{
      ...style,
      width: 16,
      height: 16,
    }}
    onClick={onClick}
  />
);

NextArrow.propTypes = {
  className: PropTypes.any,
  style: PropTypes.any,
  onClick: PropTypes.any,
};

const PreArrow = ({ className, style, onClick }) => (
  <Button
    type="link"
    icon={<LeftOutlined />}
    className={`${className} arrow`}
    style={{
      ...style,
      width: 16,
      height: 16,
    }}
    onClick={onClick}
  />
);

PreArrow.propTypes = {
  className: PropTypes.any,
  style: PropTypes.any,
  onClick: PropTypes.any,
};

const Background = () => (
  <div id="authentication-background">
    <div className="footer">
      <div className="flex justify-between items-end">
        <img
          alt="bottom-left"
          src={BottomLeft}
          style={{ width: '60%', maxWidth: '436px', height: 'auto' }}
        />
        <img
          alt="bottom-right"
          src={BottomRight}
          style={{ width: '40%', maxWidth: '287px', height: 'auto' }}
        />
      </div>
      <FooterBar />
    </div>
  </div>
);

// const carouselContent = [
// {
//   title: (
//     <Space
//       direction="vertical"
//       style={{ fontSize: 20 }}
//       className="full-w"
//       size={16}
//     >
//       <img alt="text-logo" src={TextLogo} />
//       <div>
//         <Typography.Text>Bring you business online</Typography.Text>
//         <Typography.Text>
//           <br />
//           With <Typography.Text strong>Shikomel for Supplier</Typography.Text>
//         </Typography.Text>
//       </div>
//     </Space>
//   ),
//   description:
//     'Create a business on Shikomel, connect to your customer, drive sales and manage your day-byday',
//   embed: 'https://www.youtube.com/embed/IUN664s7N-c',
// },
// {
//   title: (
//     <Space
//       direction="vertical"
//       style={{ fontSize: 20 }}
//       className="full-w"
//       size={16}
//     >
//       <img alt="text-logo" src={TextLogo} />
//       <div>
//         <Typography.Text>Manage your restaurant&#39;s Branch</Typography.Text>
//         <br />
//         <Typography.Text>
//           With{' '}
//           <Typography.Text strong>
//             Shikomel for Restaurant Owner
//           </Typography.Text>
//         </Typography.Text>
//       </div>
//     </Space>
//   ),
//   description:
//     'Create a business on Shikomel, connect to you customer, drive sales and manage your day-byday',
//   embed: 'https://www.youtube.com/embed/1wkPMUZ9vX4',
// },
// {
//   title: (
//     <Space
//       direction="vertical"
//       style={{ fontSize: 20 }}
//       className="full-w"
//       size={16}
//     >
//       <img alt="text-logo" src={TextLogo} />
//       <div>
//         <Typography.Text>Sell & Manage your Branch menu</Typography.Text>
//         <br />
//         <Typography.Text>
//           With{' '}
//           <Typography.Text strong>Shikomel for Branch Owner</Typography.Text>
//         </Typography.Text>
//       </div>
//     </Space>
//   ),
//   description:
//     'Connect to the supplier. Sell your product via the supplier and see your revenue easily',
//   embed: 'https://www.youtube.com/embed/fEErySYqItI',
// },
// ];

// const LeftContent = () => (
//   <div id="left-content" className="flex justify-center items-center">
//     <div id="container">
//       <Carousel
//         autoplay
//         arrows
//         autoplaySpeed={5000}
//         prevArrow={<PreArrow />}
//         nextArrow={<NextArrow />}
//         appendDots={(dots) => (
//           <div
//             style={{
//               position: 'relative',
//               margin: '16px 0 0 0',
//               bottom: 0,
//             }}
//           >
//             <ul
//               style={{
//                 marginBlock: 0,
//                 paddingInline: 0,
//               }}
//             >
//               {dots}
//             </ul>
//           </div>
//         )}
//       >
//         {carouselContent.map((item, index) => (
//           <div key={index}>
//             <Space direction="vertical" size={16} className="full-w">
//               {item.title}
//               <iframe
//                 src={item.embed}
//                 frameBorder="0"
//                 allow="autoplay; encrypted-media"
//                 title="video"
//                 width="100%"
//                 height="202"
//                 style={{
//                   borderRadius: '4px',
//                 }}
//               />
//               <Typography.Text>{item.description}</Typography.Text>
//             </Space>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   </div>
// );

const indexRoutes = [
  {
    path: '/auth/reset-password/success',
    component: ResetPasswordSuccessPage,
    transitionClass: 'fade-from-right',
  },
  {
    path: '/auth/reset-password',
    component: ResetPasswordPage,
    transitionClass: 'fade-from-right',
  },
  {
    path: '/auth/signup',
    component: SignupPage,
    transitionClass: 'fade-from-right',
  },
  {
    path: '/auth/login',
    component: LoginPage,
    transitionClass: 'fade-from-left',
  },
  { path: '/auth', component: () => <Redirect to="/auth/login" /> },
];

const Authentication = () => {
  const location = useLocation();

  return (
    <div id="authentication" className="flex direction-column items-center">
      <Background />
      <Space
        className="flex justify-center items-center flex-wrap full-w"
        size={[160, 32]}
        wrap
      >
        {/* {LeftContent()} */}
        <div
          className="container flex direction-column items-center"
          id="right-content"
        >
          <img
            style={{
              margin: '40px 0 32px 0',
            }}
            alt="text-logo"
            src={TextLogo}
          />
          <TransitionGroup>
            <Switch>
              {indexRoutes.map((route, index) => (
                <Route key={index} path={route.path}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      key={location.key}
                      classNames={route.transitionClass}
                      timeout={300}
                      unmountOnExit
                    >
                      <route.component />
                    </CSSTransition>
                  )}
                </Route>
              ))}
            </Switch>
          </TransitionGroup>
        </div>
      </Space>
      <div style={{ visibility: 'hidden', marginTop: 32 }}>
        <FooterBar />
      </div>
    </div>
  );
};

export default Authentication;
