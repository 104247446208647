import _pick from 'lodash/pick';
import {
  call, put, takeLatest, takeLeading
} from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getSupplierBrandListSuccess,
  getSupplierBrandListError,
  createSupplierBrandSuccess,
  createSupplierBrandError,
} from './actions';
import {
  GET_SUPPLIER_BRAND_LIST_REQUEST,
  CREATE_SUPPLIER_BRAND_REQUEST,
} from './constants';

export function* handleGetSupplierOfBrandList(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getSupplierBrandList`,
      payload
    );
    yield put(getSupplierBrandListSuccess(result, meta));
  } catch (error) {
    yield put(getSupplierBrandListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleCreateSupplierBrand(action) {
  const { meta, payload } = action;

  try {
    const params = _pick(payload, ['suppliers', 'brand']);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createSupplierBrand`,
      params
    );

    yield put(createSupplierBrandSuccess(result, meta));
  } catch (error) {
    yield put(createSupplierBrandError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export default function* saga() {
  yield takeLatest(GET_SUPPLIER_BRAND_LIST_REQUEST, handleGetSupplierOfBrandList);
  yield takeLeading(CREATE_SUPPLIER_BRAND_REQUEST, handleCreateSupplierBrand);
}
