import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card, Row, Col, Divider
} from 'antd';

import {
  CheckboxGroup,
  TimePickerField,
  CheckboxField,
} from 'components/Form';
import { WORKING_DAYS } from 'utils/constants';
import ShippingMethodForm from 'containers/RegisterPage/SupplierRegister/ShippingMethodForm';

import validate from './validate';

const workingDaysColor = {
  6: '#1890ff',
  7: '#cf1421',
};

const workingDaysOptions = Object.keys(WORKING_DAYS).map((key) => ({
  label: WORKING_DAYS[key],
  value: Number(key),
  style: { color: workingDaysColor[key] || '#262626' },
}));

const HolidaySettingForm = ({ handleSubmit, formValues = {}, isShikomelSupplier }) => (
  <Form colon={false} onSubmit={handleSubmit} layout="horizontal">
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Card
          className="highlight-card working-day-card"
          bordered={false}
          title="営業日"
          headStyle={{ fontSize: 20, fontWeight: 'bold' }}
          bodyStyle={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <div className="label">{isShikomelSupplier ? '倉庫の営業日を選択してください。' : '営業日を選択してください。'}</div>
          <div className={`working-days-field ${isShikomelSupplier ? 'warehouse-bg' : ''}`}>
            <Field
              name="workingDays"
              options={workingDaysOptions}
              colSpan={3}
              component={CheckboxGroup}
            />
          </div>
          {isShikomelSupplier && (
            <>
              <div
                className="label"
                style={{
                  marginTop: 16
                }}
              >製造会社の営業日を選択してください。
              </div>
              <div className={`working-days-field ${isShikomelSupplier ? 'factory-bg' : ''}`}>
                <Field
                  name="workingDays_manufacturer"
                  options={workingDaysOptions}
                  colSpan={3}
                  component={CheckboxGroup}
                />
              </div>
            </>
          )}
          <Divider />
          <Field
            label="休業日をリードタイムに含める"
            description="リードタイムに休業日を含む場合のみチェックを入れてください。最短納品日の計算に影響します。"
            name="leadTimeIncludeHoliday"
            component={CheckboxField}
          />
          <Divider />
          <Field
            label="注文締め時間"
            name="orderDeadlineTime"
            timeFormat="HH:mm"
            component={TimePickerField}
          />
        </Card>
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <ShippingMethodForm formValues={formValues} className="working-day-card" />
      </Col>
    </Row>
  </Form>
);

HolidaySettingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  isShikomelSupplier: PropTypes.string,
};

export default reduxForm({
  form: 'holidaySettingForm',
  enableReinitialize: true,
  touchOnChange: true,
  validate,
})(HolidaySettingForm);
