export const GET_GUEST_INFORMATION_LIST_REQUEST = 'GuestInformationProvider/GET_GUEST_INFORMATION_LIST_REQUEST';
export const GET_GUEST_INFORMATION_LIST_SUCCESS = 'GuestInformationProvider/GET_GUEST_INFORMATION_LIST_SUCCESS';
export const GET_GUEST_INFORMATION_LIST_ERROR = 'GuestInformationProvider/GET_GUEST_INFORMATION_LIST_ERROR';

export const UPDATE_GUEST_INFORMATION_REQUEST = 'GuestInformationProvider/UPDATE_GUEST_INFORMATION_REQUEST';
export const UPDATE_GUEST_INFORMATION_SUCCESS = 'GuestInformationProvider/UPDATE_GUEST_INFORMATION_SUCCESS';
export const UPDATE_GUEST_INFORMATION_ERROR = 'GuestInformationProvider/UPDATE_GUEST_INFORMATION_ERROR';

export const EXPORT_GUEST_LIST_REQUEST = 'GuestInformationProvider/EXPORT_GUEST_LIST_REQUEST';
export const EXPORT_GUEST_LIST_SUCCESS = 'GuestInformationProvider/EXPORT_GUEST_LIST_SUCCESS';
export const EXPORT_GUEST_LIST_ERROR = 'GuestInformationProvider/EXPORT_GUEST_LIST_ERROR';
