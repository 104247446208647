import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { TextField } from 'components/Form';
import validate from './validate';

const LoginForm = ({ handleSubmit, actionLoading }) => (
  <Form layout="vertical" onSubmit={handleSubmit}>
    <Field
      name="username"
      type="text"
      placeholder="メールアドレス"
      component={TextField}
    />
    <Field
      name="password"
      type="password"
      placeholder="パスワード"
      component={TextField}
    />
    <Form.Item>
      <Button
        className="full-w"
        type="primary"
        htmlType="submit"
        loading={actionLoading}
      >
        ログイン
      </Button>
    </Form.Item>
    <Link className="full-w flex justify-center" to="/auth/reset-password">
      パスワードをお忘れの方
    </Link>
  </Form>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.any,
};

export default reduxForm({ form: 'loginForm', validate })(LoginForm);
