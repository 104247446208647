import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row, Col, Card, Space, Descriptions, Avatar, Typography
} from 'antd';

import withAuthorize from 'components/HOCs/withAuthorize';
import { TextField, SelectField } from 'components/Form';
import { DATE_TIME_FORMAT, USER_ROLE } from 'utils/constants';

import { getPrefectureListRequest } from 'providers/PrefectureProvider/actions';

import DefaultSupplierImage from 'images/defaultSupplier.svg';

import validate from './validate';

const { Item } = Descriptions;

const ManufacturerCreateForm = ({
  handleSubmit,
  createdAt,
  updatedAt,
  initialValues,
  disabled,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrefectureListRequest());
  }, []);

  const prefectureList = useSelector(
    (state) => state.prefectureProvider.prefectureList,
    shallowEqual
  );

  const SupplierDetail = withAuthorize([USER_ROLE.OPERATOR])(() => (
    <Form.Item label="販売者">
      <Space size={4}>
        <Avatar
          size={24}
          src={_get(initialValues, 'supplier.image', DefaultSupplierImage)}
        />
        <Typography.Text>{initialValues?.supplier?.name}</Typography.Text>
      </Space>
    </Form.Item>
  ));

  return (
    <Form
      colon={false}
      onSubmit={handleSubmit}
      layout="horizontal"
      labelCol={{ span: 6 }}
    >
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
      >
        <Row gutter={16}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <Card
                className="highlight-card"
                title="基本情報"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  label="仕入先名"
                  name="name"
                  maxLength={50}
                  component={TextField}
                  required
                  disabled={disabled}
                />
                <Field
                  label="仕入先名"
                  name="nameKana"
                  description="カナ"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  label="自社コード"
                  name="code"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <SupplierDetail />
              </Card>
              <Card
                className="highlight-card"
                title="住所"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  name="postalCode"
                  label="郵便番号"
                  maxLength={7}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="prefecture"
                  label="都道府県"
                  component={SelectField}
                  loading={_isEmpty(prefectureList)}
                  options={_map(prefectureList, (item) => ({
                    objectId: item,
                    name: item,
                  }))}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="address"
                  label="市区町村番地"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="buildingName"
                  label="ビル名等以降の住所"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="phone"
                  label="電話番号"
                  maxLength={11}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
              </Card>
            </Space>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <Card
                className="highlight-card"
                title="連絡先"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  label="担当者"
                  name="managerName"
                  maxLength={30}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  label="担当者"
                  name="managerNameKana"
                  description="カナ"
                  maxLength={30}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  label="担当者緊急連絡先"
                  name="emergencyPhone"
                  maxLength={11}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
              </Card>
              <Card
                className="highlight-card"
                title="振込先"
                bordered={false}
                headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
              >
                <Field
                  name="bankName"
                  label="銀行名"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="bankBranchName"
                  label="支店名"
                  maxLength={50}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="bankAccountType"
                  label="口座種別"
                  maxLength={20}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
                <Field
                  name="bankAccountNumber"
                  label="口座番号"
                  maxLength={20}
                  component={TextField}
                  requiredSpace
                  disabled={disabled}
                />
              </Card>
              {createdAt && updatedAt && (
                <Card
                  title="変更履歴"
                  className="highlight-card"
                  bordered={false}
                  headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
                >
                  <Descriptions bordered column={1}>
                    <Item label="更新日">
                      {moment(updatedAt).format(DATE_TIME_FORMAT)}
                    </Item>
                    <Item label="作成日">
                      {moment(createdAt).format(DATE_TIME_FORMAT)}
                    </Item>
                  </Descriptions>
                </Card>
              )}
            </Space>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

ManufacturerCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createdAt: PropTypes.any,
  updatedAt: PropTypes.any,
  initialValues: PropTypes.object,
  disabled: PropTypes.any,
};

export default reduxForm({
  form: 'manufacturerCreateForm',
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(ManufacturerCreateForm);
