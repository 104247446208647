export const GET_RELATED_PRODUCT_LIST_REQUEST = 'RelatedProductProvider/GET_RELATED_PRODUCT_LIST_REQUEST';
export const GET_RELATED_PRODUCT_LIST_SUCCESS = 'RelatedProductProvider/GET_RELATED_PRODUCT_LIST_SUCCESS';
export const GET_RELATED_PRODUCT_LIST_ERROR = 'RelatedProductProvider/GET_RELATED_PRODUCT_LIST_ERROR';

export const ADD_PRODUCT_RELATION_REQUEST = 'RelatedProductProvider/ADD_PRODUCT_RELATION_REQUEST';
export const ADD_PRODUCT_RELATION_SUCCESS = 'RelatedProductProvider/ADD_PRODUCT_RELATION_SUCCESS';
export const ADD_PRODUCT_RELATION_ERROR = 'RelatedProductProvider/ADD_PRODUCT_RELATION_ERROR';

export const REMOVE_PRODUCT_RELATION_REQUEST = 'RelatedProductProvider/REMOVE_PRODUCT_RELATION_REQUEST';
export const REMOVE_PRODUCT_RELATION_SUCCESS = 'RelatedProductProvider/REMOVE_PRODUCT_RELATION_SUCCESS';
export const REMOVE_PRODUCT_RELATION_ERROR = 'RelatedProductProvider/REMOVE_PRODUCT_RELATION_ERROR';
