import moment from 'moment';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import {
  call, put, takeLeading, takeLatest
} from 'redux-saga/effects';

import Helpers from 'utils/helpers';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX, MESSAGE, UTF_8_BOM } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getGuestInformationListSuccess,
  getGuestInformationListError,
  updateGuestInformationSuccess,
  updateGuestInformationError,
  exportGuestListSuccess,
  exportGuestListError,
} from './actions';
import {
  GET_GUEST_INFORMATION_LIST_REQUEST,
  UPDATE_GUEST_INFORMATION_REQUEST,
  EXPORT_GUEST_LIST_REQUEST,
} from './constants';

export function* handleGetGuestInformationList(action) {
  const { payload, meta } = action;

  const params = _pick(payload, ['page', 'limit', 'orderBy', 'order']);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getGuestInformationList`,
      params
    );
    yield put(getGuestInformationListSuccess(result, meta));
  } catch (error) {
    yield put(getGuestInformationListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateBrand(action) {
  const { meta, payload } = action;

  const params = _pick(payload, ['objectId', 'status']);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateGuestInformation`,
      params
    );
    yield put(updateGuestInformationSuccess(result, meta));
  } catch (error) {
    yield put(updateGuestInformationError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleExportGuestList(action) {
  try {
    const { data } = yield call(request, '/guestList/export', {});
    const fileName = `${
      process.env.REACT_APP_CSV_PREFIX || ''
    }Guest-information_${moment().format('YYYY-MM-DD')}.csv`;

    Helpers.saveAsFile(data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportGuestListSuccess(MESSAGE.EXPORT_CSV_SUCCESS, action.meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportGuestListError(error, action.meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export default function* saga() {
  yield takeLatest(
    GET_GUEST_INFORMATION_LIST_REQUEST,
    handleGetGuestInformationList
  );
  yield takeLeading(UPDATE_GUEST_INFORMATION_REQUEST, handleUpdateBrand);
  yield takeLeading(EXPORT_GUEST_LIST_REQUEST, handleExportGuestList);
}
