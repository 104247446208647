import {
  GET_RESTAURANT_GROUP_REQUEST,
  GET_RESTAURANT_GROUP_SUCCESS,
  GET_RESTAURANT_GROUP_ERROR,
} from './constants';

export function getRestaurantGroupListRequest(payload) {
  return { type: GET_RESTAURANT_GROUP_REQUEST, payload, meta: { thunk: true } };
}

export function getRestaurantGroupListSuccess(payload, meta) {
  return { type: GET_RESTAURANT_GROUP_SUCCESS, payload, meta };
}

export function getRestaurantGroupListError(payload, meta) {
  return {
    type: GET_RESTAURANT_GROUP_ERROR,
    payload,
    meta,
    error: true,
  };
}
