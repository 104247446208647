import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';

import { TextField } from 'components/Form';

import validate from './validate';
import './style.less';

const ChangePasswordForm = ({ handleSubmit, actionLoading }) => (
  <Form
    colon={false}
    onSubmit={handleSubmit}
    layout="horizontal"
    labelCol={{ span: 8 }}
  >
    <Field
      label="現在のパスワード"
      name="oldPassword"
      type="password"
      component={TextField}
    />
    <Field
      label="新しいパスワード"
      name="newPassword"
      type="password"
      component={TextField}
    />
    <Field
      label="新しいパスワードの確認"
      name="newPasswordConfirm"
      type="password"
      component={TextField}
    />
    <div className="update-form-action-container">
      <Button
        type="primary"
        className="form-button"
        htmlType="submit"
        loading={actionLoading}
      >
        送信
      </Button>
    </div>
  </Form>
);

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.any,
};

export default reduxForm({
  form: 'changePasswordForm',
  validate,
  touchOnChange: true,
})(ChangePasswordForm);
