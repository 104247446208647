import React from 'react';
import PropTypes from 'prop-types';

import VisaCard from 'images/Card/card-visa.svg';
import MasterCard from 'images/Card/card-master.svg';
import JCBCard from 'images/Card/card-jcb.svg';
import AmexCard from 'images/Card/card-amex.svg';
import DinersCard from 'images/Card/card-diners.svg';
import InvoiceCard from 'images/Card/card-invoice.svg';
import CodCard from 'images/Card/card-cod.svg';

const CARD_IMAGE = {
  Visa: VisaCard,
  Mastercard: MasterCard,
  JCB: JCBCard,
  'Diners Club': DinersCard,
  'American Express': AmexCard,
  PAY_LATER: InvoiceCard,
  COD: CodCard,
};

const RESTAURANT_PAY_TYPE = {
  COD: 'COD',
  PAY_LATER: 'PAY_LATER',
  CREDIT_CARD: 'CREDIT_CARD',
};

const RESTAURANT_PAY_TYPE_JP = {
  [RESTAURANT_PAY_TYPE.COD]: '代金引換',
  [RESTAURANT_PAY_TYPE.PAY_LATER]: '請求書',
  [RESTAURANT_PAY_TYPE.CREDIT_CARD]: 'クレジットカード',
};

const CardInfo = ({ method, niceType, cardNumber }) => {
  const isCreditCard = method === RESTAURANT_PAY_TYPE.CREDIT_CARD;

  return (
    <>
      <img
        style={{ height: 20, marginRight: 7 }}
        src={CARD_IMAGE[isCreditCard ? niceType : method]}
        alt="payment-method"
      />
      {isCreditCard ? (cardNumber || niceType) : RESTAURANT_PAY_TYPE_JP[method]}
    </>
  );
};

CardInfo.propTypes = {
  method: PropTypes.string.isRequired,
  niceType: PropTypes.string.isRequired,
  cardNumber: PropTypes.string,
};

export default CardInfo;
