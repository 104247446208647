import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { TimePicker, ConfigProvider, Space } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import './style.less';

const TimePickerField = (props) => {
  const {
    placeholder,
    label,
    colon,
    input,
    timeFormat,
    meta: {
      touched, invalid, error, valid
    },
    disabled,
    labelCol = {},
    required,
    requiredSpace,
  } = props;
  const isError = touched && invalid && error;
  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace ? 'transparent' : requiredIconColor;
  return (
    <Form.Item
      help={
        isError && (
          <Space size={4}>
            <InfoCircleOutlined />
            {error}
          </Space>
        )
      }
      validateStatus={isError ? 'error' : ''}
      label={label}
      colon={colon}
      labelCol={labelCol}
      className="field-form-item"
    >
      <ConfigProvider locale={jaJP}>
        <div className="flex items-center">
          <TimePicker
            {...input}
            placeholder={placeholder}
            disabled={disabled}
            className="form-time-picker-field"
            onBlur={(e) => e.preventDefault()}
            format={timeFormat}
          />
          {(required || requiredSpace) && (
            <CheckCircleFilled
              style={{
                fontSize: 16,
                marginLeft: 8,
                color: requiredSpaceColor,
              }}
            />
          )}
        </div>
      </ConfigProvider>
    </Form.Item>
  );
};

TimePickerField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  colon: PropTypes.bool,
  disabled: PropTypes.bool,
  timeFormat: PropTypes.any,
  meta: PropTypes.object.isRequired,
  labelCol: PropTypes.object,
  required: PropTypes.bool,
  requiredSpace: PropTypes.bool,
};

export default TimePickerField;
