import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Card, Upload, Button, Space, Typography
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import csv from 'csv';
import { replace } from 'connected-react-router';

import toastMessage from 'utils/toastMessage';

import PageHeader from 'components/PageHeader';

import {
  importFileAirLogiRequest,
  selectFileForAirLogiImportRequest,
} from 'providers/OrderProvider/actions';

import './style.less';

const breadcrumbItems = [
  {
    path: '/orders',
    breadcrumbName: '注文一覧',
  },
  {
    breadcrumbName: 'AirLogiをダウンロード',
  },
];

const Step1 = ({ setStep }) => {
  const dispatch = useDispatch();

  const fileList = useSelector(
    (state) => state.orderProvider.airLogiImport.fileList,
    shallowEqual
  );

  return (
    <Card
      id="airlogi-import-card"
      className="highlight-card"
      bordered={false}
      title="選択したAiRLogiデータをシステムに取り込みます"
      style={{
        maxWidth: 596,
      }}
      headStyle={{
        fontSize: 20,
        fontWeight: 'bold',
      }}
    >
      選択したファイル <br />
      <Upload
        beforeUpload={(file, curFileList) => {
          if (file?.type !== 'text/csv') {
            toastMessage.error({
              description: 'ファイル拡張子が正しくありません',
            });
            return false;
          }

          const reader = new FileReader();

          reader.onload = (e) => {
            csv.parse(e.target.result, (err, data) => {
              if (err) {
                toastMessage.error({ description: err?.message });
                return;
              }

              dispatch(
                selectFileForAirLogiImportRequest({
                  data,
                  fileList: curFileList,
                })
              );
            });
          };
          reader.readAsText(file);

          return false;
        }}
        fileList={fileList}
        accept=".csv"
        maxCount={1}
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: false,
          showDownloadIcon: false,
        }}
      >
        <Button icon={<UploadOutlined />}>再選択</Button>
      </Upload>
      <Space size={8} className="full-w flex justify-end">
        <Button
          type="default"
          onClick={() => {
            dispatch(replace('/orders'));
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setStep(2);
          }}
        >
          アップロード
        </Button>
      </Space>
    </Card>
  );
};

Step1.propTypes = {
  setStep: PropTypes.any,
};

const Step2 = () => {
  const dispatch = useDispatch();

  const trackingIdsPerOrderId = useSelector(
    (state) => state.orderProvider.airLogiImport.data,
    shallowEqual
  );
  const fileList = useSelector(
    (state) => state.orderProvider.airLogiImport.fileList,
    shallowEqual
  );

  const [isImportLoading, setIsImportLoading] = useState(false);

  return (
    <Card
      className="highlight-card"
      bordered={false}
      title="取込前確認"
      style={{
        maxWidth: 596,
      }}
      headStyle={{
        fontSize: 20,
        fontWeight: 'bold',
      }}
    >
      <Typography.Text>
        <p>
          {Object.keys(trackingIdsPerOrderId).length}の出荷情報を取り込みます
        </p>
        <ul style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          <li>
            <p>「注文受領」と「発送中」の注文のみ更新対象となります</p>
          </li>
          <li>
            <p>
              「注文受領」の注文は「発送中」に変更され、レストランに通知が送信されます。
              ※但し既に荷物お問い合わせ番号が1件以上登録済の場合は、レストランには非通知
              及び ステータスの変更はありません。
            </p>
          </li>
          <li>
            <p>
              条件を満たさない注文情報は取込スキップとなります。取込完了後結果を一覧で参照することが可能です
            </p>
          </li>
        </ul>
      </Typography.Text>
      <Space size={8} className="full-w flex justify-end">
        <Button
          type="default"
          disabled={isImportLoading}
          onClick={() => {
            dispatch(replace('/orders'));
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={isImportLoading}
          onClick={async () => {
            setIsImportLoading(true);
            try {
              const result = await dispatch(
                importFileAirLogiRequest({
                  file: fileList[0],
                  trackingIdsPerOrderId,
                })
              );
              dispatch(
                replace(
                  `/orders/airLogi-import/history/${result?.objectId}?stt=1`
                )
              );
            } catch (error) {
              console.error(error);
            }
            setIsImportLoading(false);
          }}
        >
          取込
        </Button>
      </Space>
    </Card>
  );
};

const AirLogiImport = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const fileList = useSelector(
    (state) => state.orderProvider.airLogiImport.fileList,
    shallowEqual
  );

  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL' || _isEmpty(fileList)) {
    return <Redirect to="/orders" />;
  }

  const [step, setStep] = useState(1);

  return (
    <>
      <PageHeader
        title="AiRLogiから荷物お問い合わせ番号を取り込む"
        breadcrumbRoutes={breadcrumbItems}
      >
      </PageHeader>
      <Card className="bg-transparent" bodyStyle={{ paddingTop: 0 }}>
        {step === 1 && <Step1 setStep={setStep} />}
        {step === 2 && <Step2 />}
      </Card>
    </>
  );
};

export default AirLogiImport;
