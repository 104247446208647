import _get from 'lodash/get';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Spin } from 'antd';

import TrackingIdForm from './TrackingIdForm';

const deliveryServiceTypes = {
  SHIKOMEL_DELIVERY: 'SHIKOMEL_DELIVERY',
  OTHER_DELIVERY: 'OTHER_DELIVERY',
};

const TrackingIdFormWrapper = ({
  getShippingCompanyListRequest,
  onSubmit,
  onCancel,
  shippingCompanyList,
  shippingCompanyLoading,
  initialValues,
  formCompanyListValue,
  formDeliveryServiceValue,
  showDeliveryServiceSection,
  isModalForm,
  inHouseDelivery,
}) => {
  useEffect(() => {
    getShippingCompanyListRequest();
  }, []);

  if (shippingCompanyLoading) {
    return <Spin />;
  }

  const deliveryServicesInfo = [
    {
      value: deliveryServiceTypes.SHIKOMEL_DELIVERY,
      label: '自社配送',
      disabled: inHouseDelivery,
    },
    {
      value: deliveryServiceTypes.OTHER_DELIVERY,
      label: '追跡番号を追加',
      disabled: !_isEmpty(initialValues)
    },
  ];

  // Official shipping company always display in form array list
  const officialCompanyInitialValue = _map(shippingCompanyList, (company) => ({
    objectId: _get(company, 'objectId'),
    website: _get(company, 'website'),
    nameKana: _get(company, 'nameKana', '--'),
    trackingIds: [{ trackingId: '' }],
    disabled: true,
  }));

  const companyInitialValue = _reduce(
    initialValues,
    (res, cur) => {
      const curObj = {
        ...cur,
        trackingIds: !_isEmpty(cur.trackingIds)
          ? _map(cur.trackingIds, (i) => ({
            trackingId: i,
            disabled: true,
          }))
          : [{ trackingId: '' }],
        disabled: true,
      };

      // If current initialValue is value of official shipping company, update exited company
      const officialCompanyIndex = _findIndex(
        res,
        (o) => o.objectId === cur.objectId
      );
      if (officialCompanyIndex >= 0) {
        res[officialCompanyIndex] = {
          ...res[officialCompanyIndex],
          ...curObj,
        };
        return res;
      }

      // Else add new shipping company
      res.push(curObj);
      return res;
    },
    officialCompanyInitialValue
  );

  let deliveryServiceInitialValue = [deliveryServiceTypes.OTHER_DELIVERY];
  if (showDeliveryServiceSection && _isEmpty(initialValues)) {
    deliveryServiceInitialValue = [deliveryServiceTypes.SHIKOMEL_DELIVERY];
  }

  if (showDeliveryServiceSection && !_isEmpty(initialValues) && inHouseDelivery) {
    deliveryServiceInitialValue = [
      deliveryServiceTypes.SHIKOMEL_DELIVERY, deliveryServiceTypes.OTHER_DELIVERY,
    ];
  }

  const handleSubmit = (value) => {
    let inHouseDelivering = false;
    const data = _reduce(
      value.companyList,
      (pre, company) => {
        const companyName = _get(company, 'nameKana');
        if (!companyName || !companyName.trim()) {
          return pre;
        }

        pre.push({
          ..._pick(company, ['objectId', 'nameKana', 'website']),
          trackingIds: _reduce(
            company.trackingIds,
            (res, cur) => {
              if (cur && cur.trackingId) {
                res.push(cur.trackingId);
              }
              return res;
            },
            []
          ),
        });

        return pre;
      },
      []
    );

    if (value?.deliveryService?.includes(deliveryServiceTypes.SHIKOMEL_DELIVERY)) {
      inHouseDelivering = true;
    }

    if (value?.deliveryService?.includes(deliveryServiceTypes.OTHER_DELIVERY)) {
      onSubmit({ inHouseDelivering, trackingInfo: data });
      return;
    }
    onSubmit({ inHouseDelivering });
  };

  return (
    <TrackingIdForm
      showDeliveryServiceSection={showDeliveryServiceSection}
      deliveryServiceTypes={deliveryServiceTypes}
      deliveryServiceOptions={deliveryServicesInfo}
      formDeliveryServiceValue={formDeliveryServiceValue}
      formCompanyListValue={formCompanyListValue}
      initialValues={{
        deliveryService: deliveryServiceInitialValue,
        companyList: companyInitialValue,
      }}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isModalForm={isModalForm}
    />
  );
};

TrackingIdFormWrapper.propTypes = {
  initialValues: PropTypes.any,
  formCompanyListValue: PropTypes.any,
  formDeliveryServiceValue: PropTypes.any,
  shippingCompanyList: PropTypes.array,
  getShippingCompanyListRequest: PropTypes.func,
  shippingCompanyLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showDeliveryServiceSection: PropTypes.bool,
  isModalForm: PropTypes.bool,
  inHouseDelivery: PropTypes.bool,
};

export default TrackingIdFormWrapper;
