export const GET_BRAND_OWNER_LIST_REQUEST = 'BrandOwnerProvider/GET_BRAND_OWNER_LIST_REQUEST';
export const GET_BRAND_OWNER_LIST_SUCCESS = 'BrandOwnerProvider/GET_BRAND_OWNER_LIST_SUCCESS';
export const GET_BRAND_OWNER_LIST_ERROR = 'BrandOwnerProvider/GET_BRAND_OWNER_LIST_ERROR';

export const GET_BRAND_OWNER_DETAIL_REQUEST = 'BrandOwnerProvider/GET_BRAND_OWNER_DETAIL_REQUEST';
export const GET_BRAND_OWNER_DETAIL_SUCCESS = 'BrandOwnerProvider/GET_BRAND_OWNER_DETAIL_SUCCESS';
export const GET_BRAND_OWNER_DETAIL_ERROR = 'BrandOwnerProvider/GET_BRAND_OWNER_DETAIL_ERROR';

export const UPDATE_BRAND_OWNER_REQUEST = 'BrandOwnerProvider/UPDATE_BRAND_OWNER_REQUEST';
export const UPDATE_BRAND_OWNER_SUCCESS = 'BrandOwnerProvider/UPDATE_BRAND_OWNER_SUCCESS';
export const UPDATE_BRAND_OWNER_ERROR = 'BrandOwnerProvider/UPDATE_BRAND_OWNER_ERROR';

export const GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST = 'BrandOwnerProvider/GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST';
export const GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS = 'BrandOwnerProvider/GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS';
export const GET_ORDER_LIST_FOR_BRAND_OWNER_ERROR = 'BrandOwnerProvider/GET_ORDER_LIST_FOR_BRAND_OWNER_ERROR';

export const DISABLE_BRAND_OWNER_ACCOUNT_REQUEST = 'BrandOwnerProvider/DISABLE_BRAND_OWNER_ACCOUNT_REQUEST';
export const DISABLE_BRAND_OWNER_ACCOUNT_SUCCESS = 'BrandOwnerProvider/DISABLE_BRAND_OWNER_ACCOUNT_SUCCESS';
export const DISABLE_BRAND_OWNER_ACCOUNT_ERROR = 'BrandOwnerProvider/DISABLE_BRAND_OWNER_ACCOUNT_ERROR';

export const ENABLE_BRAND_OWNER_ACCOUNT_REQUEST = 'BrandOwnerProvider/ENABLE_BRAND_OWNER_ACCOUNT_REQUEST';
export const ENABLE_BRAND_OWNER_ACCOUNT_SUCCESS = 'BrandOwnerProvider/ENABLE_BRAND_OWNER_ACCOUNT_SUCCESS';
export const ENABLE_BRAND_OWNER_ACCOUNT_ERROR = 'BrandOwnerProvider/ENABLE_BRAND_OWNER_ACCOUNT_ERROR';
