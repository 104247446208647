import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Space, Input, Typography, Button
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import { DATE_TIME_FORMAT, CONNECT_STATUS } from 'utils/constants';
import { getRestaurantListForSupplierRequest } from 'providers/RestaurantProvider/actions';

import withRefresh from 'components/HOCs/withRefresh';
import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import AddRestaurant from './AddRestaurant';

const { Search } = Input;

const RestaurantList = ({ match }) => {
  const supplierId = _get(match, 'params.supplierId');
  const [showRestaurantModal, setShowRestaurantModal] = useState(false);

  const restaurantListForSupplier = useSelector(
    (state) => state.restaurantProvider.restaurantListForSupplier,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getRestaurantListForSupplierRequest({
            supplierId,
            status: CONNECT_STATUS.ACCEPTED,
            page,
            limit,
            orderBy,
            order,
            keyword,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = [
    {
      title: 'レストラン名',
      width: 250,
      fixed: 'left',
      render: (record) => (
        <div className="flex items-center full-w">
          <img
            style={{ borderRadius: 4 }}
            alt="name"
            src={_get(record, 'image', DefaultRestaurantImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0} className="restaurant-info-wrapper">
            <Typography.Text
              ellipsis={{
                tooltip: _get(record, 'name'),
              }}
            >{_get(record, 'name')}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
    {
      title: '招待日',
      width: 150,
      dataIndex: ['supplierRestaurant', 'statusLog'],
      key: 'pendingDate',
      render: (record) => (record && record[CONNECT_STATUS.PENDING]
        ? moment(record[CONNECT_STATUS.PENDING]).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '承認日',
      width: 150,
      dataIndex: ['supplierRestaurant', 'statusLog'],
      key: 'acceptedDate',
      render: (record) => (record && record[CONNECT_STATUS.ACCEPTED]
        ? moment(record[CONNECT_STATUS.ACCEPTED]).format(DATE_TIME_FORMAT) : '-'),
    },
    {
      title: '商品件数',
      width: 100,
      dataIndex: 'menuCount',
      key: 'menuCount',
      align: 'right',
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      {showRestaurantModal && (
        <AddRestaurant
          supplierId={supplierId}
          handleCloseDialog={() => {
            setShowRestaurantModal(false);
          }}
        />
      )}
      <Space className="flex items-center justify-between flex-wrap padding-16 border-bottom">
        <Search
          placeholder="名称/メールアドレスから検索"
          defaultValue={keyword}
          onSearch={(val) => onFilterChange({ keyword: val })}
          style={{ width: 250 }}
          className="search-input"
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setShowRestaurantModal(true);
          }}
        >
          レストランを追加する
        </Button>
      </Space>
      <Table
        columns={columns}
        data={_get(restaurantListForSupplier, 'list', [])}
        total={_get(restaurantListForSupplier, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 650 }}
      />
    </Card>
  );
};

RestaurantList.propTypes = {
  match: PropTypes.any,
};

export default withRefresh('connectedRestaurantOfSupplier')(RestaurantList);
