import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, PageHeader, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <Typography.Text
      style={{
        width: 250
      }}
      ellipsis={{
        tooltip: route.breadcrumbName
      }}
    >{route.breadcrumbName}
    </Typography.Text>
  ) : (
    <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
  );
};

const PageHeaderComponent = ({
  title,
  breadcrumbRoutes,
  footer,
  extra,
  children,
  onBack,
  avatar,
}) => (
  <PageHeader
    title={(
      <Typography.Text
        strong
        style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.85)' }}
      >
        {title}
      </Typography.Text>
    )}
    backIcon={<Button icon={<ArrowLeftOutlined />} />}
    breadcrumb={{ routes: breadcrumbRoutes, itemRender }}
    footer={footer}
    extra={extra}
    onBack={onBack}
    avatar={avatar}
  >
    {children}
  </PageHeader>
);

PageHeaderComponent.propTypes = {
  title: PropTypes.any,
  breadcrumbRoutes: PropTypes.any,
  footer: PropTypes.any,
  extra: PropTypes.any,
  children: PropTypes.any,
  onBack: PropTypes.any,
  avatar: PropTypes.any,
};

export default PageHeaderComponent;
