import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Space, Typography, Avatar
} from 'antd';

import Table from 'components/Table';
import { getBrandListOfRestaurantRequest } from 'providers/BrandProvider/actions';
import useTableControl from 'components/Hooks/useTableControl';
import DefaultBrandImage from 'images/defaultBrand.svg';

const BrandList = () => {
  const dispatch = useDispatch();

  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );
  const brandListOfRestaurant = useSelector(
    (state) => state.brandProvider.brandListOfRestaurant,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);

  /* eslint-disable no-unused-vars */
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;

  useEffect(() => {
    if (restaurantDetail?.objectId) {
      const fetchData = async () => {
        setTableLoading(true);
        try {
          await dispatch(
            getBrandListOfRestaurantRequest({
              page,
              limit,
              orderBy,
              order,
              restaurantId: restaurantDetail?.objectId,
            })
          );
        } catch (error) {
          console.error(error);
        }
        setTableLoading(false);
      };
      fetchData();
    }
  }, [page, limit, orderBy, order, restaurantDetail]);

  const columns = [
    {
      title: 'ブランド名',
      render: (record) => (
        <div className="flex items-center full-w">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultBrandImage)}
            size={32}
            style={{ marginRight: 8 }}
          />
          <Space
            direction="vertical"
            size={0}
            className="full-w"
            style={{ overflow: 'hidden', flex: 1 }}
          >
            <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <Table
        columns={columns}
        data={_get(brandListOfRestaurant, 'list', [])}
        total={_get(brandListOfRestaurant, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 250 }}
      />
    </Card>
  );
};

export default BrandList;
