import _get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import {
  Card, Typography, Space, Input
} from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Table from 'components/Table';

import { getRestaurantListRequest } from 'providers/RestaurantProvider/actions';
import withRefresh from 'components/HOCs/withRefresh';

import useTableControl from 'components/Hooks/useTableControl';
import Columns from './Columns';
import InvitationList from './InvitationList';
import './style.scss';

const { Search } = Input;

const RestaurantList = () => {
  const dispatch = useDispatch();

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({ filterKeys: { keyword: 'k' } });
  const { page, limit } = pageControl;
  const { keyword } = filterControl;

  const restaurantList = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );
  const dataLoading = useSelector(
    (state) => state.restaurantProvider.dataLoading
  );

  const getRestaurantList = useCallback(() => {
    dispatch(
      getRestaurantListRequest({
        ...pageControl,
        status: 'ACCEPTED',
        keyword,
      })
    );
  }, [pageControl, filterControl]);

  useEffect(() => {
    getRestaurantList();
  }, [getRestaurantList]);

  const onRowClick = (record) => {
    dispatch(
      push(`/restaurants/detail/${record.formattedObjectId}/inapp-config`)
    );
  };

  const columns = [
    Columns.id,
    Columns.restaurantInfo,
    {
      title: 'メモ',
      width: 350,
      dataIndex: ['supplierRestaurant', 'noteRestaurant'],
      key: 'note',
      render: (record) => (record ? (
        <Typography.Paragraph
          className="note"
          ellipsis={{
            rows: 3,
            tooltip: (
              <Typography.Text
                style={{ color: '#fff', whiteSpace: 'pre-line' }}
              >
                {record}
              </Typography.Text>
            ),
          }}
        >
          {record}
        </Typography.Paragraph>
      ) : (
        '-'
      )),
    },
    Columns.invitedDate,
    Columns.connectedDate,
    Columns.numberOfProduct
  ];

  return (
    <Card
      className="bg-transparent"
      bordered={false}
      id="restaurant-list-container"
    >
      <Space direction="vertical" className="full-w" size={16}>
        <InvitationList />
        <Card className="highlight-card padding-card" bordered={false}>
          <div className="padding-16 border-bottom">
            <Search
              placeholder="名称/メールアドレスから検索"
              defaultValue={keyword}
              onSearch={(val) => onFilterChange({ keyword: val })}
              style={{ width: 244 }}
              className="search-input"
            />
          </div>
          <Table
            onRowClick={onRowClick}
            columns={columns}
            data={_get(restaurantList, 'list', [])}
            total={_get(restaurantList, 'total', 0)}
            loading={dataLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
            scroll={{ x: 1200 }}
          />
        </Card>
      </Space>
    </Card>
  );
};

export default withRefresh('restaurantList')(RestaurantList);
