import {
  CHANGE_PRIMARY_EMAIL_REQUEST,
  CHANGE_PRIMARY_EMAIL_SUCCESS,
  CHANGE_PRIMARY_EMAIL_ERROR,
  GET_INVITATION_EMAILS_REQUEST,
  GET_INVITATION_EMAILS_SUCCESS,
  GET_INVITATION_EMAILS_ERROR,
  REMOVE_INVITATION_EMAIL_REQUEST,
  REMOVE_INVITATION_EMAIL_SUCCESS,
  REMOVE_INVITATION_EMAIL_ERROR,
  RESEND_INVITATION_EMAIL_REQUEST,
  RESEND_INVITATION_EMAIL_SUCCESS,
  RESEND_INVITATION_EMAIL_ERROR,
  ADD_INVOICE_EMAIL_REQUEST,
  ADD_INVOICE_EMAIL_SUCCESS,
  ADD_INVOICE_EMAIL_ERROR,
} from './constants';

export function changePrimaryEmailRequest(payload) {
  return { type: CHANGE_PRIMARY_EMAIL_REQUEST, payload, meta: { thunk: true } };
}

export function changePrimaryEmailSuccess(payload, meta) {
  return { type: CHANGE_PRIMARY_EMAIL_SUCCESS, payload, meta };
}

export function changePrimaryEmailError(payload, meta) {
  return {
    type: CHANGE_PRIMARY_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getInvitationEmailsRequest(payload) {
  return { type: GET_INVITATION_EMAILS_REQUEST, payload };
}

export function getInvitationEmailsSuccess(payload) {
  return { type: GET_INVITATION_EMAILS_SUCCESS, payload };
}

export function getInvitationEmailsError(payload) {
  return { type: GET_INVITATION_EMAILS_ERROR, payload };
}

export function removeInvitationEmailRequest(payload) {
  return {
    type: REMOVE_INVITATION_EMAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeInvitationEmailSuccess(payload, meta) {
  return { type: REMOVE_INVITATION_EMAIL_SUCCESS, payload, meta };
}

export function removeInvitationEmailError(payload, meta) {
  return {
    type: REMOVE_INVITATION_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function resendInvitationEmailRequest(payload) {
  return {
    type: RESEND_INVITATION_EMAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function resendInvitationEmailSuccess(payload, meta) {
  return { type: RESEND_INVITATION_EMAIL_SUCCESS, payload, meta };
}

export function resendInvitationEmailError(payload, meta) {
  return {
    type: RESEND_INVITATION_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addInvoiceEmailRequest(payload) {
  return { type: ADD_INVOICE_EMAIL_REQUEST, payload, meta: { thunk: true } };
}

export function addInvoiceEmailSuccess(payload, meta) {
  return { type: ADD_INVOICE_EMAIL_SUCCESS, payload, meta };
}

export function addInvoiceEmailError(payload, meta) {
  return {
    type: ADD_INVOICE_EMAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}
