import { createSelector } from 'reselect';

const selectOrder = (state) => state.orderProvider || {};
const makeSelectOrderList = () => createSelector(selectOrder, (state) => state.orderList);
const makeSelectOrderLoad = () => createSelector(selectOrder, (state) => state.loading);
const makeSelectOrderDetails = () => createSelector(selectOrder, (state) => state.orderDetails);

const selectMessage = (state) => state.orderProvider.orderMessage || {};
const makeSelectOrderMessage = () => createSelector(selectMessage, (state) => state.data);
const makeSelectOrderLoadMessage = () => createSelector(selectMessage, (state) => state.loading);
export {
  makeSelectOrderList,
  makeSelectOrderLoad,
  makeSelectOrderDetails,
  makeSelectOrderMessage,
  makeSelectOrderLoadMessage
};
