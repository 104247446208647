import React from 'react';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { Tooltip } from 'antd';

import Helpers from 'utils/helpers';

const Columns = {};

Columns.ID = {
  title: 'ID',
  dataIndex: 'objectId',
  key: 'objectId',
  width: 150,
  fixed: 'left',
};

Columns.name = {
  title: 'セット商品名',
  key: 'name',
  dataIndex: 'name',
  width: 200,
};

Columns.memo = {
  title: 'メモ',
  dataIndex: 'note',
  key: 'memo',
  ellipsis: {
    showTitle: false,
  },
  width: 170,
  render: (record) => (
    <Tooltip placement="topLeft" title={record}>
      {record || '-'}
    </Tooltip>
  ),
};

Columns.productCount = {
  title: '商品件数',
  dataIndex: 'productCount',
  key: 'productCount',
  align: 'right',
  width: 120,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.createAt = {
  title: '作成日',
  width: 150,
  dataIndex: 'createdAt',
  key: 'createdAt',
  render: (record) => record && moment(record).format(DATE_TIME_FORMAT),
};

Columns.updateAt = {
  title: '最終更新日',
  width: 150,
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  render: (record) => record && moment(record).format(DATE_TIME_FORMAT),
};

Columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  width: 150,
  fixed: 'right',
};

export default Columns;
