import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { replace, push } from 'connected-react-router';
import {
  Button, Divider, Space, Typography, Card
} from 'antd';

import { listenOrderMessageRequest, listenWebNotificationRequest } from 'providers/LiveQueryProvider/actions';
import {
  loginRequest,
  getCurrentUserRequest,
} from 'providers/AuthProvider/actions';

import LoginForm from './LoginForm';

import '../style.less';

const Login = ({ location }) => {
  const [actionLoading, setActionLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (data) => {
    setActionLoading(true);
    try {
      await dispatch(loginRequest(data));
      setActionLoading(false);
      await dispatch(getCurrentUserRequest());
      dispatch(listenOrderMessageRequest());
      dispatch(listenWebNotificationRequest());
      const redirectUrl = _get(location, 'state.redirectUrl', '/');
      dispatch(replace(redirectUrl));
    } catch (err) {
      setActionLoading(false);
    }
  };

  return (
    <Card className="highlight-card" style={{ minHeight: 486 }} bordered={false}>
      <Space direction="vertical" size={32} className="full-w">
        <Space direction="vertical" className="full-w">
          <Typography.Text strong style={{ fontSize: 20, color: '#000' }}>
            ログイン
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            ログインしてシコメルを利用する
          </Typography.Text>
        </Space>
        <LoginForm onSubmit={handleLogin} actionLoading={actionLoading} />
        <div
          className="flex justify-center items-center"
          style={{ position: 'relative' }}
        >
          <Divider style={{ margin: 0 }} />
          <div
            style={{
              position: 'absolute',
              padding: 4,
              backgroundColor: '#fff',
            }}
          >
            または
          </div>
        </div>
        <Button
          className="block full-w"
          style={{
            margin: '0 auto',
            boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
          }}
          type="default"
          onClick={() => {
            dispatch(push('/auth/signup'));
          }}
        >
          新規登録はこちら
        </Button>
        <Typography.Text
          className="block"
          style={{ textAlign: 'center', fontSize: 12 }}
        >
          登録をもって、{' '}
          <a
            href={process.env.REACT_APP_TERM_URL}
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>{' '}
          と{' '}
          <a
            href={process.env.REACT_APP_PRIVACY_URL}
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          <br />
          に同意したとみなします
        </Typography.Text>
      </Space>
    </Card>
  );
};

Login.propTypes = {
  location: PropTypes.any,
};

export default Login;
