import {
  GET_BANNER_LIST_REQUEST,
  GET_BANNER_LIST_SUCCESS,
  GET_BANNER_LIST_ERROR,
} from './constants';

export function getBannerListRequest(payload) {
  return { type: GET_BANNER_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getBannerListSuccess(payload, meta) {
  return { type: GET_BANNER_LIST_SUCCESS, payload, meta };
}

export function getBannerListError(payload, meta) {
  return {
    type: GET_BANNER_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
