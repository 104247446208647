import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  Card, Button, Space, Select, Input
} from 'antd';
import { push } from 'connected-react-router';
import queryString from 'query-string';

import useTableControl from 'components/Hooks/useTableControl';
import { STATUS, USER_ROLE } from 'utils/constants';
import {
  exportMenuListRequest,
  pagingGetMenuItemListRequest,
} from 'providers/MenuProvider/actions';
import { getGenreListRequest } from 'providers/GenreProvider/actions';

import Table from 'components/Table';
import Helpers from 'utils/helpers';
import StatusToggle from './StatusToggle';
import ProductTypeFilter from './ProductTypeFilter';
import ProductListSearchForm from './ProductListSearchForm';
import Columns from './Columns';
import InventoryStatus from './InventoryStatus';

import './style.less';
import './InventoryStatus/style.less';

const { Search } = Input;

const ProductList = ({ manufacturerCode }) => {
  const [isSearch, setIsSearch] = useState(false);
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );
  const menuCountPerType = useSelector(
    (state) => state.menuProvider?.menuCountPerType,
    shallowEqual
  );
  const genresList = useSelector(
    (state) => state.genreProvider.genreList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
      status: 'stt',
      menuType: 't',
      brandId: 'bi',
      supplierId: 'si',
      genreId: 'gi',
      isPriceDiscounted: 'pd',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const {
    keyword, status, menuType, brandId, supplierId, genreId, isPriceDiscounted
  } = filterControl;

  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState(false);

  const [tableLoading, setTableLoading] = useState(false);
  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';

  const fetchData = async () => {
    setTableLoading(true);

    try {
      await dispatch(
        pagingGetMenuItemListRequest({
          page,
          limit,
          order,
          orderBy,
          keyword,
          status,
          menuType,
          manufacturerCode,
          brandId,
          supplierId,
          genreId,
          isPriceDiscounted: isPriceDiscounted || undefined,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  const getGenreListWithDelay = useCallback(
    _debounce(
      (data) => dispatch(
        getGenreListRequest({
          ...data,
          limit: 1000,
        })
      ),
      500
    ),
    []
  );

  const onSearchGenreKeyword = (value) => {
    const data = {};
    if (value !== '') {
      data.keyword = value;
    }
    setIsSearch(true);
    getGenreListWithDelay(data);
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    limit,
    orderBy,
    order,
    keyword,
    status,
    menuType,
    manufacturerCode,
    brandId,
    supplierId,
    genreId,
    isPriceDiscounted,
  ]);

  useEffect(() => {
    if (isShikomelSupplier) {
      dispatch(getGenreListRequest());
    }
  }, [isShikomelSupplier]);

  const from = `/products?${queryString.stringify({
    p: page,
    l: limit,
    ob: orderBy,
    o: order,
    kw: keyword,
    stt: status,
    t: menuType,
    bi: brandId,
    si: supplierId,
    gi: genreId,
    pd: isPriceDiscounted,
  }, {
    skipEmptyString: true,
  })}`;
  const handleRowClick = (record) => {
    dispatch(
      push(`/products/detail/${record.formattedObjectId}/in-app`, {
        from,
      })
    );
  };

  const handleCreateProduct = () => dispatch(push('/products/create', { from }));
  const statusCol = {
    ...Columns.status,
    render: (record, rowRecord) => (
      <StatusToggle record={record} rowRecord={rowRecord} />
    ),
  };

  const inventoryCol = {
    ...Columns.inventory,
    render: (record, rowRecord) => (
      <InventoryStatus
        isSoldOut={record}
        menuDetail={rowRecord}
        fetchData={fetchData}
      />
    ),
  };

  const columns = {
    [USER_ROLE.OPERATOR]: [
      Columns.ID,
      Columns.productName,
      Columns.supplierName,
      Columns.leadTime,
      Columns.brand,
      Columns.prices,
      Columns.brandFee,
      Columns.order,
      Columns.commerceRobo,
      inventoryCol,
      statusCol,
    ],
    [USER_ROLE.SUPPLIER]: [
      Columns.ID,
      Columns.productName,
      Columns.freeCode,
      Columns.leadTime,
      Columns.brand,
      Columns.prices,
      Columns.brandFee,
      Columns.order,
      Columns.commerceRobo,
      Columns.genres,
      inventoryCol,
      statusCol,
    ],
    [USER_ROLE.BRAND_OWNER]: [
      {
        ...Columns.productName,
        ellipsis: true,
        width: 220,
      },
      {
        ...Columns.brand,
        ellipsis: true,
        width: 200,
      },
      {
        ...Columns.supplierName,
        ellipsis: true,
        width: 220,
      },
      {
        ...Columns.prices,
        width: 160,
        render: (record) => {
          const masterPrice = _find(record.prices, (o) => o.type === 'MASTER');
          return `¥${Helpers.numberWithCommas(masterPrice.priceAfterTax)}`;
        },
      },
      {
        ...Columns.brandFee,
        width: 160,
      },
    ],
  };

  const tableScroll = {
    [USER_ROLE.OPERATOR]: 1160,
    [USER_ROLE.BRAND_OWNER]: 960,
    [USER_ROLE.SUPPLIER]: 1913,
  };

  const renderFilterForBrandOwner = currentUser.role
    === USER_ROLE.BRAND_OWNER && (
    <Search
      placeholder="商品名で検索"
      defaultValue={keyword}
      onSearch={(val) => onFilterChange({ keyword: val })}
      style={{ width: 200 }}
      className="search-input"
    />
  );

  const renderFilterForOtherRoles = [
    USER_ROLE.SUPPLIER,
    USER_ROLE.OPERATOR,
  ].includes(currentUser?.role)
    && menuType !== 'MENU_RANKING' && (
    <Space wrap>
      <Search
        placeholder="ID/自社コード/商品名で検索"
        defaultValue={keyword}
        onSearch={(val) => onFilterChange({ keyword: val })}
        style={{ width: 240 }}
        className="search-input"
      />
      <Select
        defaultValue={{ value: status || '' }}
        labelInValue
        onChange={(val) => onFilterChange({ status: val.value })}
        style={{ width: 200 }}
      >
        <Select.Option value="">ステータス：<span className="label">全て</span></Select.Option>
        <Select.Option value={STATUS.ACTIVE}>ステータス：<span className="label">有効</span></Select.Option>
        <Select.Option value={STATUS.INACTIVE}>
          ステータス：<span className="label">無効</span>
        </Select.Option>
      </Select>
      {isShikomelSupplier && (
        <>
          <Select
            defaultValue={{ value: isPriceDiscounted || '' }}
            labelInValue
            onChange={(val) => onFilterChange({ isPriceDiscounted: val.value })}
            style={{ width: 200 }}
          >
            <Select.Option value="">税込: <span className="label">All</span></Select.Option>
            <Select.Option value="false">税込: <span className="label">Original</span></Select.Option>
            <Select.Option value="true">
              税込: <span className="label">Discount</span>
            </Select.Option>
          </Select>
          <Select
            value={genreId || undefined}
            labelInValue
            onChange={(val) => {
              onFilterChange({ genreId: val?.value || '' });
              if (isSearch) {
                dispatch(getGenreListRequest());
                setIsSearch(false);
              }
            }}
            style={{ width: 200 }}
            showSearch
            filterOption={false}
            notFoundContent={null}
            onSearch={onSearchGenreKeyword}
            allowClear
            placeholder="ジャンル：全て"
          >
            {_map(genresList, (genre) => (
              <Select.Option key={genre.objectId} value={genre.objectId}>
                {genre.name}
              </Select.Option>
            ))}
          </Select>
        </>
      )}
    </Space>
  );

  const renderExtraActions = () => {
    if (_get(currentUser, 'role') !== USER_ROLE.SUPPLIER) {
      return null;
    }

    return (
      <Space direction="horizontal" size={[8, 8]} wrap>
        <Button
          type="ghost"
          icon={<DownloadOutlined />}
          loading={exportLoading}
          onClick={async () => {
            setExportLoading(true);
            try {
              await dispatch(exportMenuListRequest());
            } catch (error) {
              console.error(error);
            }
            setExportLoading(false);
          }}
        >
          商品リストをダウンロード
        </Button>
        {!manufacturerCode && (
          <Button
            type="primary"
            onClick={handleCreateProduct}
            icon={<PlusOutlined />}
          >
            商品を追加
          </Button>
        )}
      </Space>
    );
  };

  return (
    <Card
      id="product-list-container"
      className="bg-transparent"
      bordered={false}
    >
      <Space className="full-w" direction="vertical" size="middle">
        {currentUser?.role !== USER_ROLE.BRAND_OWNER && (
          <Card
            className="highlight-card order-status-container"
            bordered={false}
          >
            <ProductTypeFilter
              {...{
                menuCountPerType,
                menuType,
                onFilterChange,
                currentUser,
                isManufacturer: !!manufacturerCode,
              }}
            />
          </Card>
        )}
        {currentUser.role === USER_ROLE.BRAND_OWNER && (
          <Card className="highlight-card padding-card-16" bordered={false}>
            <ProductListSearchForm {...{ filterControl, onFilterChange }} />
          </Card>
        )}
        <Card className="highlight-card padding-card" bordered={false}>
          <Space
            size={8}
            className="filter-container flex items-center flex-wrap justify-between padding-16 border-bottom"
          >
            {renderFilterForBrandOwner}
            {renderFilterForOtherRoles}
            {renderExtraActions()}
          </Space>
          <Table
            onRowClick={handleRowClick}
            columns={columns[currentUser?.role]}
            data={_get(productList, 'list', [])}
            total={_get(productList, 'total', 0)}
            loading={tableLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
            scroll={{ x: tableScroll[currentUser?.role] }}
          />
        </Card>
      </Space>
    </Card>
  );
};

ProductList.propTypes = {
  manufacturerCode: PropTypes.any,
};

export default ProductList;
