import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { getShippingCompanyListRequest } from 'providers/ShippingCompanyProvider/actions';
import {
  makeSelectShippingCompanyList,
  makeSelectShippingCompanyLoading,
} from 'providers/ShippingCompanyProvider/selectors';

import TrackingIdFormWrapper from './TrackingIdFormWrapper';

const formSelector = formValueSelector('trackingIdForm');

const mapStateToProps = createStructuredSelector({
  shippingCompanyList: makeSelectShippingCompanyList(),
  shippingCompanyLoading: makeSelectShippingCompanyLoading(),
  formCompanyListValue: (state) => formSelector(state, 'companyList'),
  formDeliveryServiceValue: (state) => formSelector(state, 'deliveryService'),
});

const mapDispatchToProps = (dispatch) => ({
  getShippingCompanyListRequest: (value) => dispatch(getShippingCompanyListRequest(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(TrackingIdFormWrapper);
