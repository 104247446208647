import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { submit } from 'redux-form';
import PropTypes from 'prop-types';
import AddHolidayForm from './AddHolidayForm';
import './style.less';

const AddHolidayModal = ({
  visible, onSubmit, onCancel, actionLoading, isShikomelSupplier
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      className="add-holiday-modal"
      title="休業日を追加"
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{
        loading: actionLoading,
      }}
      okText="追加"
      cancelText="キャンセル"
      onOk={() => dispatch(submit('addHolidayForm'))}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <AddHolidayForm onSubmit={onSubmit} isShikomelSupplier={isShikomelSupplier} />
    </Modal>
  );
};

AddHolidayModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  actionLoading: PropTypes.bool,
  isShikomelSupplier: PropTypes.string,
};

export default AddHolidayModal;
