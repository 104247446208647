import React from 'react';
import _get from 'lodash/get';
import { Tooltip, Space, Typography } from 'antd';
import DefaultBrandImage from 'images/defaultBrand.svg';

import Helpers from 'utils/helpers';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';

const Columns = {};

Columns.memo = {
  title: 'メモ',
  dataIndex: 'memo',
  key: 'memo',
  ellipsis: {
    showTitle: false,
  },
  width: 170,
  render: (record) => (
    <Tooltip placement="topLeft" title={record}>
      {record || '-'}
    </Tooltip>
  ),
};

Columns.ID = {
  title: 'ID',
  dataIndex: 'objectId',
  key: 'objectId',
  width: 120,
  fixed: 'left',
};

Columns.brandOwnerName = {
  title: '企業名',
  width: 250,
  key: 'brandOwnerName',
  render: (record) => (
    <Space className="flex items-center">
      <img
        style={{ borderRadius: 4 }}
        alt="name"
        src={_get(record, 'image', DefaultBrandImage)}
        width={32}
        height={32}
      />
      <Space direction="vertical" size={0}>
        <Typography.Text>{_get(record, 'name')}</Typography.Text>
        <Space size={2}>
          {_get(record, 'user.emailVerified') ? (
            <Tooltip placement="topLeft" title="Email is verified">
              <img alt="verified-icon" src={VerifiedImage} />
            </Tooltip>
          ) : (
            <Tooltip placement="topLeft" title="Email has not been verified">
              <img alt="warning-icon" src={WarningImage} />
            </Tooltip>
          )}
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {_get(record, 'email')}
          </Typography.Text>
        </Space>
      </Space>
    </Space>
  ),
};

Columns.numberOfBrand = {
  title: 'ブランド件数',
  width: 120,
  dataIndex: 'brandCount',
  align: 'right',
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.status = {
  title: 'ステータス',
  dataIndex: 'status',
  key: 'status',
  width: 150,
};

export default Columns;
