import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Modal, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import _omit from 'lodash/omit';
import _split from 'lodash/split';
import _reduce from 'lodash/reduce';

import PageHeader from 'components/PageHeader';
import FormActionFooter from 'containers/AdminPage/FormActionFooter';
import Constants from 'utils/constants';

import AnnouncementCreateForm from './AnnouncementCreateForm';

const breadcrumbItems = [
  {
    path: '/announcements',
    breadcrumbName: 'プッシュ通知',
  },
  {
    breadcrumbName: '新規作成',
  },
];

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '配信',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const AnnouncementCreate = ({
  goBack,
  createAnnouncement,
  formScheduleValue,
  push,
}) => {
  const [createActionLoading, setCreateActionLoading] = useState(false);
  const dispatch = useDispatch();

  const createAnnouncementRestaurant = useCallback(
    async (value) => {
      setCreateActionLoading(true);
      try {
        await createAnnouncement(value);
        push('/announcements');
      } catch (error) {
        if (error.code === 9548) {
          Modal.error({
            title: 'エラー',
            content: (
              <div
                style={{
                  maxHeight: '50vh',
                  overflowY: 'auto',
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: 'pre-line',
                  }}
                >
                  {error.error}
                </Typography.Text>
              </div>
            ),
          });
        }
        console.error(error);
      }
      setCreateActionLoading(false);
    },
    [setCreateActionLoading, createAnnouncement, push]
  );

  const handleSubmit = useCallback(
    (value) => {
      const { schedule } = value;
      let params = { ...value };

      switch (value?.attachmentType) {
        case 'file':
          params = _omit(params, ['attachmentType', 'productIds', 'url']);
          break;

        case 'product':
          params = _omit(params, ['attachmentType', 'file', 'url']);
          params.menuId = value?.productIds[0];
          break;

        case 'url':
          params = _omit(params, ['attachmentType', 'file', 'productIds']);
          break;

        default:
          break;
      }

      params = _omit(params, [
        'restaurantGroup',
        'userDateComparison',
        'userDate',
        'restaurantIds',
      ]);
      if (
        value?.announcementTarget
        === Constants.RESTAURANT_ANNOUNCEMENT.TARGET.RESTAURANT_GROUP
      ) {
        params.restaurantGroupKey = value?.restaurantGroup;
        if (
          [
            Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
              .USER_LAST_ORDER_DATE_VERSUS_TIME,
            Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY
              .USER_REGISTER_DATE_VERSUS_TIME,
          ].includes(value?.restaurantGroup)
        ) {
          params.restaurantGroupParams = {
            comparison: value?.userDateComparison,
            date:
              value?.userDateComparison === 'lte'
                ? moment(value?.userDate)
                  .tz('Asia/Tokyo')
                  .startOf('date')
                  .toISOString()
                : moment(value?.userDate)
                  .tz('Asia/Tokyo')
                  .endOf('date')
                  .toISOString(),
          };
        }
        if (
          value?.restaurantGroup
          === Constants.RESTAURANT_ANNOUNCEMENT.RESTAURANT_GROUP_KEY.CUSTOM_USER
        ) {
          params.restaurantGroupParams = {
            formattedIds: _reduce(
              _split(value?.restaurantIds?.trim(), '\n'),
              (pre, cur) => {
                if (cur) {
                  pre.push(cur.trim());
                }
                return pre;
              },
              []
            ),
          };
        }
      }

      if (!schedule) {
        return showConfirm(
          'メッセージは直ぐに公開されます。公開して宜しいですか？ ',
          () => createAnnouncementRestaurant(params)
        );
      }
      return createAnnouncementRestaurant(params);
    },
    [showConfirm, createAnnouncementRestaurant]
  );

  const handleOk = useCallback(() => {
    dispatch(submit('announcementCreateForm'));
  }, [dispatch, submit]);

  return (
    <div className="create-announcement-page flex direction-column">
      <PageHeader
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        breadcrumbRoutes={breadcrumbItems}
        onBack={goBack}
      />
      <Card className="bg-transparent">
        <AnnouncementCreateForm
          onSubmit={handleSubmit}
          formScheduleValue={formScheduleValue}
          initialValues={{
            attachmentType: 'file',
            announcementTarget: Constants.RESTAURANT_ANNOUNCEMENT.TARGET.ALL,
            userDateComparison: 'lte',
          }}
        />
      </Card>
      <FormActionFooter
        handleCancel={goBack}
        actionLoading={createActionLoading}
        handleOk={handleOk}
        isShowLabel={false}
        okText={formScheduleValue ? '予約確定' : '配信'}
        cancelText="キャンセル"
      />
    </div>
  );
};

AnnouncementCreate.propTypes = {
  goBack: PropTypes.func,
  createAnnouncement: PropTypes.func,
  formScheduleValue: PropTypes.any,
  push: PropTypes.func,
};

export default AnnouncementCreate;
