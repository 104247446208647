import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { reset, isDirty, submit } from 'redux-form';
import { push } from 'connected-react-router';
import {
  Card, Row, Col, Modal, Typography, Space, Button, Spin
} from 'antd';

import {
  getSupplierDetailRequest,
  updateSupplierRequest,
} from 'providers/SupplierProvider/actions';

import toastMessage from 'utils/toastMessage';

import PageHeader from 'components/PageHeader';
import ShippingFeeForm from './ShippingFeeForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: '送料の設定',
  },
];

const ShippingFee = () => {
  const dispatch = useDispatch();

  const supplierDetail = useSelector(
    (state) => state.supplierProvider.supplierDetail,
    shallowEqual
  );

  const dirty = useSelector((state) => isDirty('shippingFeeForm')(state));

  const formVal = useSelector(
    (state) => _get(state.form, 'shippingFeeForm.values', {}),
    shallowEqual
  );
  const setPriceOfFreeShip = _get(formVal, 'setPriceOfFreeShip');

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchSupplierDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getSupplierDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  });

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateSupplierRequest(values));
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      dispatch(getSupplierDetailRequest());
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <ShippingFeeForm
          initialValues={{
            ...supplierDetail,
            setPriceOfFreeShip: !!_isNumber(
              _get(supplierDetail, 'priceOfFreeShip')
            ),
          }}
          setPriceOfFreeShip={setPriceOfFreeShip}
          onSubmit={handleSubmit}
        />
      </Card>
      {dirty && (
        <div
          className="full-w"
          style={{
            background: '#293137',
            position: 'absolute',
            bottom: 0,
          }}
        >
          <Row>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Space
                size={8}
                className="flex justify-between"
                style={{ padding: '16px 16px 16px 32px' }}
              >
                <Typography.Text style={{ color: '#fff' }}>
                  保存されていない変更
                </Typography.Text>
                <Space size={8} className="full-w flex justify-end">
                  <Button
                    type="default"
                    disabled={actionLoading}
                    onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('shippingFeeForm'))
                    )}
                  >
                    取り消す
                  </Button>
                  <Button
                    type="primary"
                    loading={actionLoading}
                    onClick={() => dispatch(submit('shippingFeeForm'))}
                  >
                    保存する
                  </Button>
                </Space>
              </Space>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ShippingFee;
