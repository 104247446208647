import _pick from 'lodash/pick';
import React, { useCallback, useEffect, useState } from 'react';
import { isDirty, reset, submit } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Button, Affix, Space, Modal, Spin, Typography
} from 'antd';

import PageHeader from 'components/PageHeader';
import withRefresh from 'components/HOCs/withRefresh';
import RegisterRestaurantOwnerForm from 'containers/RegisterPage/RestaurantOwnerRegister/RegisterRestaurantOwnerForm';

import { doRefresh } from 'providers/CommonProvider/actions';
import { getCurrentUserRequest } from 'providers/AuthProvider/actions';
import {
  getRestaurantOwnerDetailRequest,
  updateRestaurantOwnerRequest,
} from 'providers/RestaurantOwnerProvider/actions';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: actionFn,
  });
};

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: 'プロフィール',
  },
];

const Profile = () => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const restaurantOwnerDetail = useSelector(
    (state) => state.restaurantOwnerProvider.restaurantOwnerDetail,
    shallowEqual
  );

  const dirty = useSelector((state) => isDirty('registerRestaurantOwnerForm')(state)
  );

  const fetchRestaurantOwnerDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getRestaurantOwnerDetailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  });

  useEffect(() => {
    fetchRestaurantOwnerDetail();
  }, []);

  const onSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(updateRestaurantOwnerRequest(values));
      dispatch(getCurrentUserRequest());
      dispatch(doRefresh({ target: 'profileDetail' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  const handleGoBack = () => {
    dispatch(push('/settings'));
  };

  const handleCancel = () => showConfirm('中断してよろしいですか？', () => {
    dispatch(reset('registerRestaurantOwnerForm'));
  });
  const handleSubmit = () => dispatch(submit('registerRestaurantOwnerForm'));

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent max-width-644"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <RegisterRestaurantOwnerForm
          onSubmit={onSubmit}
          initialValues={{
            ..._pick(restaurantOwnerDetail, [
              'name',
              'nameKana',
              'postalCode',
              'prefecture',
              'address',
              'building',
              'phone',
              'fax',
            ]),
            image: restaurantOwnerDetail?.image && [
              { uid: '-1', url: restaurantOwnerDetail.image },
            ],
          }}
        />
      </Card>
      {dirty && (
        <Affix offsetBottom={0} className="full-w">
          <div className="full-w" style={{ background: '#293137' }}>
            <Space
              size={8}
              className="flex justify-between max-width-644"
              style={{ padding: '16px 32px' }}
            >
              <Typography.Text style={{ color: '#fff' }}>
                保存されていない変更
              </Typography.Text>
              <Space size={8} className="full-w flex justify-end">
                <Button
                  type="default"
                  disabled={actionLoading}
                  onClick={handleCancel}
                >
                  取り消す
                </Button>
                <Button
                  type="primary"
                  loading={actionLoading}
                  onClick={handleSubmit}
                >
                  保存する
                </Button>
              </Space>
            </Space>
          </div>
        </Affix>
      )}
    </>
  );
};

export default withRefresh('profileDetail')(Profile);
