import {
  GET_PENDING_LIST_REQUEST,
  GET_PENDING_LIST_SUCCESS,
  GET_PENDING_LIST_ERROR,
} from './constants';

export function getPendingListRequest(payload) {
  return {
    type: GET_PENDING_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getPendingListSuccess(payload, meta) {
  return { type: GET_PENDING_LIST_SUCCESS, payload, meta };
}

export function getPendingListError(payload, meta) {
  return {
    type: GET_PENDING_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
