import {
  call,
  put,
  takeLatest,
  takeLeading,
  takeEvery,
  select,
} from 'redux-saga/effects';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import moment from 'moment';
import axios from 'axios';

import request from 'utils/request';
import {
  CLOUD_FUNCTION_PREFIX,
  MESSAGE,
  UTF_8_BOM,
  DATE_FORMAT_CSV_FILE_NAME,
  USER_ROLE,
} from 'utils/constants';
import Helpers from 'utils/helpers';
import toastMessage from 'utils/toastMessage';
import {
  getRestaurantMenuItemSuccess,
  getRestaurantMenuItemError,
  removeRestaurantMenuItemSuccess,
  removeRestaurantMenuItemError,
  assignRestaurantMenuItemSuccess,
  assignRestaurantMenuItemError,
  exportRestaurantListSuccess,
  exportRestaurantListError,
  getRestaurantListSuccess,
  getRestaurantListError,
  getRestaurantListForSupplierSuccess,
  getRestaurantListForSupplierError,
  getRestaurantListForOperatorSuccess,
  getRestaurantListForOperatorError,
  acceptAllRestaurantSuccess,
  acceptAllRestaurantError,
  acceptRestaurantSuccess,
  acceptRestaurantError,
  declineAllRestaurantSuccess,
  declineAllRestaurantError,
  declineRestaurantSuccess,
  declineRestaurantError,
  getRestaurantDetailSuccess,
  getRestaurantDetailError,
  disableRestaurantAccountSuccess,
  disableRestaurantAccountError,
  enableRestaurantAccountSuccess,
  enableRestaurantAccountError,
  updateRestaurantSuccess,
  updateRestaurantError,
  getRestaurantSelectOptionsSuccess,
  getRestaurantSelectOptionsError,
  updateBillingInfoError,
  updateBillingInfoSuccess,
  updateDeliveryInfoError,
  updateDeliveryInfoSuccess,
  updateRestaurantInfoError,
  updateRestaurantInfoSuccess,
  registerRestaurantOwnerSuccess,
  registerRestaurantOwnerError,
  addRestaurantNoteSuccess,
  addRestaurantNoteError,
  downgradeToBasicSuccess,
  downgradeToBasicError,
  retryMembershipPaymentError,
  retryMembershipPaymentSuccess,
  operatorConnectSupplierRestaurantError,
  operatorConnectSupplierRestaurantSuccess,
  updateInfoOEMSuccess,
  updateInfoOEMError,
} from './actions';
import {
  GET_RESTAURANT_MENU_ITEM_REQUEST,
  REMOVE_RESTAURANT_MENU_ITEM_REQUEST,
  ASSIGN_RESTAURANT_MENU_ITEM_REQUEST,
  SEND_RESTAURANT_PASSWORD_RESET_EMAIL,
  EXPORT_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST,
  GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST,
  ACCEPT_ALL_RESTAURANT_REQUEST,
  ACCEPT_RESTAURANT_REQUEST,
  DECLINE_ALL_RESTAURANT_REQUEST,
  DECLINE_RESTAURANT_REQUEST,
  GET_RESTAURANT_DETAIL_REQUEST,
  DISABLE_RESTAURANT_ACCOUNT_REQUEST,
  ENABLE_RESTAURANT_ACCOUNT_REQUEST,
  UPDATE_RESTAURANT_REQUEST,
  GET_RESTAURANT_SELECT_OPTIONS_REQUEST,
  UPDATE_BILLING_INFO_REQUEST,
  UPDATE_DELIVERY_INFO_REQUEST,
  UPDATE_RESTAURANT_INFO_REQUEST,
  REGISTER_RESTAURANT_OWNER_REQUEST,
  ADD_RESTAURANT_NOTE_REQUEST,
  DOWNGRADE_TO_BASIC_REQUEST,
  RETRY_MEMBERSHIP_PAYMENT_REQUEST,
  OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST,
  UPDATE_INFO_OEM_REQUEST,
} from './constants';

export function* handleGetRestaurantMenuItem(action) {
  const { meta, payload } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getAssignedMenuItems`,
      payload
    );
    yield put(getRestaurantMenuItemSuccess({ ...payload, ...result }, meta));
  } catch (error) {
    yield put(getRestaurantMenuItemError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveRestaurantMenuItem(action) {
  const { payload, meta } = action;

  const params = _pick(payload, ['restaurantMenuId']);
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeRestaurantMenuItems`,
      params
    );
    yield put(
      removeRestaurantMenuItemSuccess(
        { ...response.result, index: _get(payload, 'index') },
        meta
      )
    );
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    yield put(removeRestaurantMenuItemError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAssignRestaurantMenuItem(action) {
  const { payload, meta } = action;

  const params = _pick(payload, ['restaurantId', 'menuItems', 'collectionIds']);
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}assignMenuItemsToRestaurant`,
      params
    );
    yield put(assignRestaurantMenuItemSuccess(response.result, meta));
  } catch (error) {
    yield put(assignRestaurantMenuItemError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleSendResetRestaurantPassword(action) {
  const params = _pick(action.data, 'restaurantEmail');

  try {
    yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}resetRestaurantPassword`,
      params
    );
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleExportRestaurantList(action) {
  try {
    const { data } = yield call(request, '/restaurants/export', {});
    const fileName = `${
      process.env.REACT_APP_CSV_PREFIX || ''
    }${moment().format(DATE_FORMAT_CSV_FILE_NAME)}_店舗一覧.csv`;

    Helpers.saveAsFile(data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportRestaurantListSuccess(action.meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportRestaurantListError(action.meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleGetRestaurantList(action) {
  const params = _pick(action.payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'formattedObjectId',
    'keyword',
    'status',
    'supplierId',
    'checkAssignedToCollectionId',
    'statuses',
    'restaurantOwnerId',
  ]);

  const currentUser = yield select((state) => state.authProvider.currentUser);
  let roleToCallAPI = _get(currentUser, 'role');
  if (action.payload.supplierId) {
    roleToCallAPI = USER_ROLE.SUPPLIER;
  }

  if (action.payload.restaurantOwnerId) {
    roleToCallAPI = USER_ROLE.RESTAURANT_OWNER;
  }

  const appCallForRole = {
    [USER_ROLE.OPERATOR]: `${CLOUD_FUNCTION_PREFIX}getRestaurantList`,
    [USER_ROLE.SUPPLIER]: `${CLOUD_FUNCTION_PREFIX}getRestaurantListForSupplier`,
    [USER_ROLE.BRAND_OWNER]: `${CLOUD_FUNCTION_PREFIX}getRestaurantList`,
    [USER_ROLE.RESTAURANT_OWNER]: `${CLOUD_FUNCTION_PREFIX}getRestaurant_RestaurantOwnerList`,
  };

  try {
    const response = yield call(
      request,
      `${appCallForRole[roleToCallAPI]}`,
      params
    );

    yield put(getRestaurantListSuccess(response.result, action.meta));
  } catch (error) {
    yield put(getRestaurantListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantListForSupplier(action) {
  const params = _pick(action.payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'status',
    'supplierId',
  ]);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantListForSupplier`,
      params
    );

    yield put(
      getRestaurantListForSupplierSuccess(response.result, action.meta)
    );
  } catch (error) {
    yield put(getRestaurantListForSupplierError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantListForOperator(action) {
  const params = _pick(action.payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
  ]);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantList`,
      params
    );

    yield put(
      getRestaurantListForOperatorSuccess(response.result, action.meta)
    );
  } catch (error) {
    yield put(getRestaurantListForOperatorError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAcceptAllRestaurantRequest(action) {
  const { meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}acceptAllSupplierRestaurantRequest`
    );
    yield put(acceptAllRestaurantSuccess(result, meta));
    toastMessage.success({
      description: `${result?.length}件の招待を全て承認しました。`,
    });
  } catch (error) {
    yield put(acceptAllRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export function* handleAcceptRestaurantRequest(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}acceptSupplierRestaurantRequest`,
      payload
    );
    yield put(acceptRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(acceptRestaurantError(error, meta));
    if (error.code === 101) {
      toastMessage.error({
        message: 'Expired invitation',
        description: 'The restaurant cancelled this invitation',
      });
    } else {
      toastMessage.error({ message: 'エラー', description: error.error });
    }
  }
}
export function* handleDeclineAllRestaurantRequest(action) {
  const { meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}deleteAllSupplierRestaurantRequest`
    );
    yield put(declineAllRestaurantSuccess(result, meta));
    toastMessage.success({
      description: `${result?.length}件の招待を全て否認しました。`,
    });
  } catch (error) {
    yield put(declineAllRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}
export function* handleDeclineRestaurantRequest(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}deleteSupplierRestaurantRequest`,
      payload
    );
    yield put(declineRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(declineRestaurantError(error, meta));
    if (error.code === 101) {
      toastMessage.error({
        message: 'Expired invitation',
        description: 'The restaurant cancelled this invitation',
      });
    } else {
      toastMessage.error({ message: 'エラー', description: error.error });
    }
  }
}

export function* handleGetRestaurantDetail(action) {
  const { payload, meta } = action;
  const { formattedObjectId } = _pick(payload, ['formattedObjectId']);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantDetail`,
      { formattedObjectId }
    );
    yield put(getRestaurantDetailSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleDisableRestaurantAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}disableRestaurantAccount`,
      payload
    );
    yield put(disableRestaurantAccountSuccess(result, meta));
  } catch (error) {
    yield put(disableRestaurantAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleEnableRestaurantAccount(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}enableRestaurantAccount`,
      payload
    );
    yield put(enableRestaurantAccountSuccess(result, meta));
  } catch (error) {
    yield put(enableRestaurantAccountError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateRestaurant(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateRestaurant`,
      payload
    );
    yield put(updateRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(updateRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRegisterRestaurantOwner(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
        {
          fileName: _get(image, 'originFileObj.name'),
          fileType: _get(image, 'originFileObj.type'),
          type: 'AVATAR',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
        headers: {
          'Content-Type': _get(image, 'originFileObj.type'),
        },
      })
      );
    }

    const params = _pick(payload, [
      'name',
      'nameKana',
      'postalCode',
      'prefecture',
      'address',
      'building',
      'phone',
      'fax',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
    ]);

    params.image = fileId;

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}registerRestaurantOwner`,
      params
    );
    yield put(registerRestaurantOwnerSuccess(result, meta));
  } catch (error) {
    yield put(registerRestaurantOwnerError(error, meta));
  }
}

export function* handleGetRestaurantSelectOptions(action) {
  const { payload, meta } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantSelectOptions`,
      payload
    );
    yield put(getRestaurantSelectOptionsSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantSelectOptionsError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateRestaurantInfo(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    const params = _pick(payload, [
      'restaurantId',
      'name',
      'nameKana',
      'postalCode',
      'prefecture',
      'address',
      'buildingName',
      'phone',
      'fax',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'restaurantType',
      'restaurantBusiness',
      'restaurantIndustry',
    ]);

    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      if (image?.originFileObj) {
        const { result: fileUploadResult } = yield call(
          request,
          `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
          {
            fileName: _get(image, 'originFileObj.name'),
            fileType: _get(image, 'originFileObj.type'),
            type: 'AVATAR',
          }
        );

        fileId = _get(fileUploadResult, 'objectId');

        yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
          headers: {
            'Content-Type': _get(image, 'originFileObj.type'),
          },
        })
        );
      }
    }
    params.image = fileId
      || (_isArray(payload.image) && _isEmpty(payload.image) ? '' : undefined);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateRestaurantInfo`,
      params
    );
    yield put(updateRestaurantInfoSuccess(result, meta));
    toastMessage.success({ description: 'プロフィールを更新しました' });
  } catch (error) {
    yield put(updateRestaurantInfoError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateDeliveryInfo(action) {
  const { payload, meta } = action;
  try {
    const params = _pick(payload, [
      'restaurantId',
      'deliveryPostalCode',
      'deliveryAddress',
      'deliveryPrefecture',
      'deliveryBuildingName',
      'deliveryPhone',
      'deliveryNote',
      'deliveryFax',
    ]);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateDeliveryInfo`,
      params
    );
    yield put(updateDeliveryInfoSuccess(result, meta));
    toastMessage.success({ description: 'プロフィールを更新しました' });
  } catch (error) {
    yield put(updateDeliveryInfoError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateBillingInfo(action) {
  const { payload, meta } = action;
  try {
    const params = _pick(payload, [
      'restaurantId',
      'billingName',
      'billingPostalCode',
      'billingPrefecture',
      'billingAddress',
      'billingBuildingName',
      'billingPhone',
      'billingManagerName',
      'bankAccountName',
      'bankAccountNameKana',
    ]);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateBillingInfo`,
      params
    );
    yield put(updateBillingInfoSuccess(result, meta));
    toastMessage.success({ description: 'プロフィールを更新しました。' });
  } catch (error) {
    yield put(updateBillingInfoError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleAddRestaurantNote(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}supplier_addRestaurantNote`,
      payload
    );
    yield put(addRestaurantNoteSuccess(result, meta));
  } catch (error) {
    yield put(addRestaurantNoteError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRenewMembership(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}retryMembershipPayment`,
      payload
    );
    yield put(retryMembershipPaymentSuccess(result, meta));
    toastMessage.success({ description: 'Retry successfully' });
  } catch (error) {
    yield put(retryMembershipPaymentError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleOperatorConnectSupplierRestaurant(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}operator_connectSupplierRestaurant`,
      payload
    );
    yield put(operatorConnectSupplierRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(operatorConnectSupplierRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleDowngradeToBasic(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}downgradeMembership`,
      payload
    );
    yield put(downgradeToBasicSuccess(result, meta));
    toastMessage.success({ description: 'ベーシックプランに降格しました。' });
  } catch (error) {
    yield put(downgradeToBasicError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateInfoOEM(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateInfoOEM`,
      payload
    );
    yield put(
      updateInfoOEMSuccess(
        {
          ...result,
          isOriginalRecipeConsultationSupport:
            payload.isOriginalRecipeConsultationSupport,
        },
        meta
      )
    );
  } catch (error) {
    yield put(updateInfoOEMError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLatest(
    GET_RESTAURANT_MENU_ITEM_REQUEST,
    handleGetRestaurantMenuItem
  );
  yield takeLatest(UPDATE_RESTAURANT_REQUEST, handleUpdateRestaurant);
  yield takeLeading(
    REMOVE_RESTAURANT_MENU_ITEM_REQUEST,
    handleRemoveRestaurantMenuItem
  );
  yield takeLeading(
    ASSIGN_RESTAURANT_MENU_ITEM_REQUEST,
    handleAssignRestaurantMenuItem
  );
  yield takeLeading(
    SEND_RESTAURANT_PASSWORD_RESET_EMAIL,
    handleSendResetRestaurantPassword
  );
  yield takeLeading(EXPORT_RESTAURANT_LIST_REQUEST, handleExportRestaurantList);
  yield takeLeading(
    ACCEPT_ALL_RESTAURANT_REQUEST,
    handleAcceptAllRestaurantRequest
  );
  yield takeLeading(ACCEPT_RESTAURANT_REQUEST, handleAcceptRestaurantRequest);
  yield takeLeading(
    DECLINE_ALL_RESTAURANT_REQUEST,
    handleDeclineAllRestaurantRequest
  );
  yield takeLeading(DECLINE_RESTAURANT_REQUEST, handleDeclineRestaurantRequest);
  yield takeEvery(GET_RESTAURANT_LIST_REQUEST, handleGetRestaurantList);
  yield takeLeading(
    GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST,
    handleGetRestaurantListForSupplier
  );
  yield takeLeading(
    GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST,
    handleGetRestaurantListForOperator
  );
  yield takeEvery(GET_RESTAURANT_DETAIL_REQUEST, handleGetRestaurantDetail);
  yield takeLeading(
    DISABLE_RESTAURANT_ACCOUNT_REQUEST,
    handleDisableRestaurantAccount
  );
  yield takeLeading(
    ENABLE_RESTAURANT_ACCOUNT_REQUEST,
    handleEnableRestaurantAccount
  );
  yield takeLatest(
    GET_RESTAURANT_SELECT_OPTIONS_REQUEST,
    handleGetRestaurantSelectOptions
  );
  yield takeLatest(UPDATE_RESTAURANT_INFO_REQUEST, handleUpdateRestaurantInfo);
  yield takeLatest(UPDATE_DELIVERY_INFO_REQUEST, handleUpdateDeliveryInfo);
  yield takeLatest(UPDATE_BILLING_INFO_REQUEST, handleUpdateBillingInfo);
  yield takeLatest(
    REGISTER_RESTAURANT_OWNER_REQUEST,
    handleRegisterRestaurantOwner
  );
  yield takeLeading(ADD_RESTAURANT_NOTE_REQUEST, handleAddRestaurantNote);
  yield takeLeading(DOWNGRADE_TO_BASIC_REQUEST, handleDowngradeToBasic);
  yield takeLeading(RETRY_MEMBERSHIP_PAYMENT_REQUEST, handleRenewMembership);
  yield takeLeading(
    OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST,
    handleOperatorConnectSupplierRestaurant
  );
  yield takeLeading(UPDATE_INFO_OEM_REQUEST, handleUpdateInfoOEM);
}
