import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card, Space, Input, Typography
} from 'antd';
import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import { getBrandListRequest } from 'providers/BrandProvider/actions';

import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';

import DefaultBrandImage from 'images/defaultBrand.svg';

const { Search } = Input;

const BrandList = ({ match }) => {
  const brandList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );
  const supplierId = _get(match, 'params.supplierId');

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getBrandListRequest({
            supplierId,
            page,
            limit,
            orderBy,
            order,
            keyword,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = [
    {
      title: 'ブランド名',
      fixed: 'left',
      render: (record) => (
        <Space className="flex items-center">
          <img
            style={{ borderRadius: 4 }}
            alt="name"
            src={_get(record, 'image', DefaultBrandImage)}
            width={32}
            height={32}
          />
          <Space direction="vertical" size={0}>
            <Typography.Text>{_get(record, 'name')}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email')}
            </Typography.Text>
          </Space>
        </Space>
      ),
      width: 250,
    },
    {
      title: '承認日',
      dataIndex: ['supplierBrand', 'createdAt'],
      key: 'createdAt',
      render: (record) => (record ? moment(record).format(DATE_TIME_FORMAT) : '-'),
      width: 150,
    },
    {
      title: '商品件数',
      dataIndex: 'menuCount',
      key: 'menuCount',
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
      width: 120,
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <div className="padding-16 border-bottom">
        <Search
          placeholder="名称/メールアドレスから検索"
          defaultValue={keyword}
          onSearch={(val) => onFilterChange({ keyword: val })}
          style={{ width: 260 }}
          className="search-input"
        />
      </div>
      <Table
        columns={columns}
        data={_get(brandList, 'list', [])}
        total={_get(brandList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 500 }}
      />
    </Card>
  );
};

BrandList.propTypes = {
  match: PropTypes.any,
};

export default BrandList;
