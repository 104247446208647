import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { Space, Typography } from 'antd';

import Helpers from 'utils/helpers';
import { DATE_FORMAT } from 'utils/constants';

import DefaultSupplierImage from 'images/defaultSupplier.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';

const Columns = {};

Columns.supplierName = {
  title: '販売者名',
  dataIndex: 'supplier',
  width: 250,
  fixed: 'left',
  ellipsis: {
    showTitle: false,
  },
  render: (record) => (
    <Space className="flex items-center">
      <img
        style={{ borderRadius: 4 }}
        alt="name"
        src={_get(record, 'image', DefaultSupplierImage)}
        width={32}
        height={32}
      />
      <Space direction="vertical" size={0}>
        <Typography.Text>{_get(record, 'name')}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {_get(record, 'email')}
        </Typography.Text>
      </Space>
    </Space>
  ),
};

Columns.createAt = {
  title: '承認日',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 120,
  render: (record) => moment(record).format(DATE_FORMAT),
};

Columns.productCount = {
  title: '商品件数',
  dataIndex: 'productCount',
  align: 'right',
  width: 100,
  render: (record) => Helpers.numberWithCommas(record || 0),
};

Columns.brandOwnerName = {
  title: 'ブランド名',
  fixed: 'left',
  width: 250,
  ellipsis: {
    showTitle: false,
  },
  render: (record) => (
    <Space className="flex items-center">
      <img
        style={{ borderRadius: 4 }}
        alt="name"
        src={_get(record, 'image', DefaultBrandImage)}
        width={32}
        height={32}
      />
      <Space direction="vertical" size={0}>
        <Typography.Text>{_get(record, 'name')}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {_get(record, 'email')}
        </Typography.Text>
      </Space>
    </Space>
  ),
};

Columns.action = {
  title: '操作',
  key: 'action',
  fixed: 'right',
  width: 120,
};

export default Columns;
