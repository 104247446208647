import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Card, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  ImageField,
  SelectField,
  TextField,
  RadioGroup,
} from 'components/Form';

import { getPrefectureListRequest } from 'providers/PrefectureProvider/actions';

import { getRestaurantSelectOptionsRequest } from 'providers/RestaurantProvider/actions';

import validate from './validate';

const RestaurantProfileForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const prefectureList = useSelector(
    (state) => state.prefectureProvider.prefectureList,
    shallowEqual
  );

  useEffect(() => {
    if (_isEmpty(prefectureList)) {
      dispatch(getPrefectureListRequest());
    }
    dispatch(getRestaurantSelectOptionsRequest());
  }, []);

  const {
    RESTAURANT_TYPES,
    BUSINESS_TYPES,
    RESTAURANT_BUSINESS,
    RESTAURANT_INDUSTRY,
  } = useSelector(
    (state) => state.restaurantProvider.selectOptions,
    shallowEqual
  );

  return (
    <Form
      labelCol={{ span: 6 }}
      colon={false}
      layout="horizontal"
      onSubmit={handleSubmit}
    >
      <Space size={40} direction="vertical" className="full-w">
        <Space size={16} direction="vertical" className="full-w">
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="店舗名"
          >
            <Field
              label="店舗ロゴ"
              description="任意"
              name="image"
              component={ImageField}
              maxCount={1}
              shape="round"
            />
            <Field
              label="店舗名"
              name="name"
              component={TextField}
              maxLength={200}
              required
            />
            <Field
              label="店舗名"
              description="カナ"
              name="nameKana"
              component={TextField}
              maxLength={200}
              required
            />
            <Field
              label="事業"
              name="restaurantBusiness"
              component={SelectField}
              options={_map(RESTAURANT_BUSINESS, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="業態"
              name="restaurantType"
              component={SelectField}
              options={_map(RESTAURANT_TYPES, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="業種"
              name="restaurantIndustry"
              component={SelectField}
              options={_map(RESTAURANT_INDUSTRY, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="事業所区分"
              name="businessType"
              className="business-type"
              component={RadioGroup}
              isButton
              options={BUSINESS_TYPES?.map((option) => ({
                name: option,
                objectId: option,
              }))}
              required
              disabled
            />
          </Card>
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="店舗の住所"
          >
            <Field
              label="郵便番号"
              name="postalCode"
              component={TextField}
              maxLength={7}
              required
            />
            <Field
              label="都道府県"
              name="prefecture"
              component={SelectField}
              loading={_isEmpty(prefectureList)}
              options={_map(prefectureList, (item) => ({
                objectId: item,
                name: item,
              }))}
              required
            />
            <Field
              label="住所1"
              description="市区町村"
              name="address"
              component={TextField}
              maxLength={50}
              required
            />
            <Field
              label="ビル名等以降の住所"
              description="任意"
              name="buildingName"
              component={TextField}
              maxLength={50}
              requiredSpace
            />
            <Field
              label="電話番号"
              name="phone"
              component={TextField}
              maxLength={11}
              required
            />
            <Field
              label="FAX番号"
              description="任意"
              name="fax"
              component={TextField}
              maxLength={11}
              requiredSpace
            />
          </Card>
          <Card
            headStyle={{ fontSize: 20, fontWeight: 'bold' }}
            className="highlight-card"
            title="担当者の連絡先"
          >
            <Field
              label="担当者"
              name="managerName"
              component={TextField}
              maxLength={50}
            />
            <Field
              label="担当者"
              description="カナ"
              name="managerNameKana"
              component={TextField}
              maxLength={50}
            />
            <Field
              label="担当者緊急連絡先"
              description="※電話番号 / TEL"
              name="emergencyPhone"
              component={TextField}
              maxLength={11}
            />
          </Card>
        </Space>
      </Space>
    </Form>
  );
};

RestaurantProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'profileForm',
  touchOnChange: true,
  validate,
})(RestaurantProfileForm);
