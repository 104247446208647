import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Button, Space, Input, Typography, Card
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getBrandListRequest } from 'providers/BrandProvider/actions';

import withAuthorize from 'components/HOCs/withAuthorize';
import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';

import { DATE_TIME_FORMAT, USER_ROLE } from 'utils/constants';

import DefaultBrandImage from 'images/defaultBrand.svg';

import ProfileInfo from 'components/ProfileInfo';
import Columns from './Columns';

import './style.less';

const { Search } = Input;

const columns = {
  [USER_ROLE.OPERATOR]: [
    Columns.ID,
    {
      ...Columns.name,
      render: (record) => (
        <ProfileInfo
          name={_get(record, 'name')}
          email={_get(record, 'email')}
          image={_get(record, 'image', DefaultBrandImage)}
          shape="square"
        />
      ),
    },
    Columns.brandOwner,
    Columns.memo,
    Columns.supplierCount,
    Columns.menuCount,
    {
      ...Columns.managerName,
      render: (record) => (
        <Space direction="vertical" size={0}>
          <Typography.Text>{_get(record, 'managerName')}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {_get(record, 'managerNameKana')}
          </Typography.Text>
        </Space>
      ),
    },
    Columns.emergencyPhone,
  ],
  [USER_ROLE.SUPPLIER]: [
    {
      ...Columns.name,
      render: (record) => (
        <ProfileInfo
          name={_get(record, 'name')}
          email={_get(record, 'email')}
          image={_get(record, 'image', DefaultBrandImage)}
          shape="square"
        />
      ),
      fixed: 'left',
    },
    Columns.menuCount,
    {
      title: '承認日',
      dataIndex: ['supplierBrand', 'createdAt'],
      key: 'createdAt',
      width: 200,
      render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
    },
  ],
  [USER_ROLE.BRAND_OWNER]: [
    Columns.ID,
    {
      ...Columns.name,
      render: (record) => (
        <ProfileInfo
          name={_get(record, 'name')}
          email={_get(record, 'email')}
          image={_get(record, 'image', DefaultBrandImage)}
          shape="square"
        />
      ),
    },
    Columns.supplierCount,
    Columns.menuCount,
    {
      ...Columns.managerName,
      render: (record) => (
        <Space direction="vertical" size={0}>
          <Typography.Text>{_get(record, 'managerName', '-')}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {_get(record, 'managerNameKana', '-')}
          </Typography.Text>
        </Space>
      ),
    },
    Columns.emergencyPhone,
  ],
};

const tableMinWidth = {
  [USER_ROLE.OPERATOR]: 1366,
  [USER_ROLE.SUPPLIER]: 570,
  [USER_ROLE.BRAND_OWNER]: 946,
};

const BrandCreateButton = withAuthorize([USER_ROLE.OPERATOR])(() => {
  const dispatch = useDispatch();
  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => dispatch(push('/brands/create'))}
    >
      ブランドを追加
    </Button>
  );
});

const BrandList = () => {
  const brandList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });

  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getBrandListRequest({
            page,
            limit,
            orderBy,
            order,
            keyword,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  return (
    <Card className="highlight-card padding-card" bordered={false} id="brand-list">
      {[USER_ROLE.SUPPLIER, USER_ROLE.OPERATOR].includes(currentUser?.role) && (
        <Space className="flex items-center justify-between border-bottom padding-16" wrap>
          <Search
            placeholder="名称/メールアドレスから検索"
            defaultValue={keyword}
            onSearch={(val) => onFilterChange({ keyword: val })}
            style={{ width: 244 }}
            className="search-input"
          />
          <div style={{ flexGrow: 1 }} />
          <BrandCreateButton />
        </Space>
      )}
      <Table
        onRowClick={(record) => {
          dispatch(push(`/brands/detail/${record.objectId}/in-app`));
        }}
        columns={columns[currentUser?.role]}
        data={_get(brandList, 'list', [])}
        total={_get(brandList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: tableMinWidth[currentUser?.role] }}
      />
    </Card>
  );
};

export default BrandList;
