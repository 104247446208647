import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { createStructuredSelector } from 'reselect';

import {
  getRestaurantAnnouncementDetailRequest,
  deleteRestaurantAnnouncementRequest,
  getListRestaurantAnnouncementRequest
} from 'providers/AnnouncementProvider/actions';
import {
  makeSelectRestaurantAnnouncementDetail,
} from 'providers/AnnouncementProvider/selectors';
import {
  makeSelectLocationPathname,
} from 'providers/RouterProvider/selectors';

import AnnouncementDetail from './AnnouncementDetail';

const mapStateToProps = createStructuredSelector({
  announcementDetail: makeSelectRestaurantAnnouncementDetail(),
  pathname: makeSelectLocationPathname(),
});

const mapDispatchToProps = (dispatch) => ({
  getAnnouncementDetailRequest: (value) => dispatch(getRestaurantAnnouncementDetailRequest(value)),
  deleteAnnouncementRequest: (value) => dispatch(deleteRestaurantAnnouncementRequest(value)),
  getListRestaurantAnnouncement: (params) => dispatch(getListRestaurantAnnouncementRequest(params)),
  push: (path) => dispatch(push(path)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(AnnouncementDetail);
