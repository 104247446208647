import axios from 'axios';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { getAuthToken } from 'providers/AuthProvider/actions';
import { API_APP_ID, API_REST_API_KEY, API_SERVER_URL } from './config';

const customErrorMessageByCode = {
  209: '再度お試しください',
};

const getAxiosInstance = () => {
  const headers = {
    'X-Parse-Application-Id': API_APP_ID,
    'X-Parse-REST-API-Key': API_REST_API_KEY,
  };

  const token = getAuthToken();
  if (token) {
    headers['X-Parse-Session-Token'] = token;
  }

  const axiosInstance = axios.create({
    baseURL: API_SERVER_URL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([200, 201].includes(response.status)) {
        let result = response.data;
        if (typeof response.data === 'string') {
          result = { data: response.data };
        }
        result.statusCode = response.status;
        return result;
      }
      return Promise.reject(response);
    },
    (error) => {
      if (_get(error, 'response.data.code')) {
        if (customErrorMessageByCode[_get(error, 'response.data.code')]) {
          _set(
            error,
            'response.data.error',
            customErrorMessageByCode[_get(error, 'response.data.code')]
          );
        }
        if (_get(error, 'response.data.code') === 209) {
          window.store.dispatch({
            type: 'AuthProvider/INVALID_SESSION_TOKEN',
            payload: {},
          });
        }
        return Promise.reject(_get(error, 'response.data'));
      }
      return Promise.reject(_get(error, 'response.statusText'));
    }
  );

  return axiosInstance;
};

const request = (url, data = {}, opts, method = 'POST') => {
  try {
    const API = getAxiosInstance();
    switch (method) {
      case 'GET':
        return API.get(url, data, opts);

      default:
        return API.post(url, data, opts);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const getBlob = (url) => {
  try {
    return axios
      .get(url, {
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getBlob };
export default request;
