import produce from 'immer';
import { STATUS } from 'utils/constants';

import {
  GET_RESTAURANT_OWNER_LIST_SUCCESS,
  GET_RESTAURANT_OWNER_DETAIL_REQUEST,
  GET_RESTAURANT_OWNER_DETAIL_SUCCESS,
  ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS,
  DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS,
} from './constants';

const initialState = {
  restaurantOwnerList: {},
  restaurantOwnerDetail: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_RESTAURANT_OWNER_LIST_SUCCESS: {
      state.restaurantOwnerList = action.payload;
      break;
    }
    case GET_RESTAURANT_OWNER_DETAIL_REQUEST:
      state.restaurantOwnerDetail = {};
      break;
    case GET_RESTAURANT_OWNER_DETAIL_SUCCESS:
      state.restaurantOwnerDetail = action.payload;
      break;
    case ENABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS: {
      state.restaurantOwnerDetail = {
        ...state.restaurantOwnerDetail,
        status: STATUS.ACTIVE,
      };
      break;
    }
    case DISABLE_RESTAURANT_OWNER_ACCOUNT_SUCCESS: {
      state.restaurantOwnerDetail = {
        ...state.restaurantOwnerDetail,
        status: STATUS.INACTIVE,
      };
      break;
    }
    default:
  }
  return state;
});

export default reducer;
