import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Typography,
  Skeleton,
  Card,
  Space,
  List,
  Divider,
  Modal,
} from 'antd';
import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import {
  getInvitationEmailsRequest,
  removeInvitationEmailRequest,
  resendInvitationEmailRequest,
} from 'providers/EmailInvitationProvider/actions';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';
import PrimaryEmailModal from './PrimaryEmailModal';
import InvoiceEmailModal from './InvoiceEmailModal';

const invitationEmailType = {
  PRIMARY_EMAIL_CHANGE: 'PRIMARY_EMAIL_CHANGE',
  INVOICE_FORWARD: 'INVOICE_FORWARD',
  BRAND_INVOICE_FORWARD: 'BRAND_INVOICE_FORWARD',
};
const { Text } = Typography;

const EmailInvitation = ({
  isPrimary,
  restaurantId,
  supplierId,
  brandOwnerId,
  brandId,
  email,
  emailVerified,
  isHidden,
  title,
  name,
  invoiceForwardEmailHeader,
  removeInvoiceForwardEmailConfirmText,
}) => {
  const [primaryEmailModal, setPrimaryEmailModal] = useState(false);
  const [invoiceEmailModal, setInvoiceEmailModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const invitationEmails = useSelector(
    (state) => state.emailInvitationProvider.invitationEmails,
    shallowEqual
  );
  const dataLoading = useSelector(
    (state) => state.emailInvitationProvider.dataLoading,
    shallowEqual
  );

  const dispatch = useDispatch();

  const getInvitationEmails = useCallback(() => {
    dispatch(
      getInvitationEmailsRequest({
        restaurantId,
        supplierId,
        brandOwnerId,
        brandId,
      })
    );
  }, [dispatch, restaurantId, supplierId, brandOwnerId, brandId]);

  useEffect(() => {
    getInvitationEmails();
  }, [getInvitationEmails]);

  const handleRemoveInvitationEmail = async ({ invitationEmailId }) => {
    setActionLoading(true);
    try {
      await dispatch(
        removeInvitationEmailRequest({
          objectId: invitationEmailId,
          restaurantId,
          supplierId,
          brandOwnerId,
          brandId,
        })
      );
      getInvitationEmails();
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  const handleResendInvitationEmail = async ({ invitationEmailId }) => {
    setActionLoading(true);
    try {
      await dispatch(
        resendInvitationEmailRequest({
          objectId: invitationEmailId,
          restaurantId,
          supplierId,
          brandOwnerId,
        })
      );
    } catch (err) {
      console.error(err);
    }
    setActionLoading(false);
  };

  const pendingPrimaryEmailChange = _get(
    invitationEmails,
    invitationEmailType.PRIMARY_EMAIL_CHANGE,
    []
  )[0];

  const renderStatus = (isVerified) => {
    if (isVerified) {
      return (
        <Space size={4}>
          <img alt="verified-icon" src={VerifiedImage} />
          <Typography.Text type="success" style={{ fontSize: 12 }}>
            確認済
          </Typography.Text>
        </Space>
      );
    }

    return (
      <Space size={4}>
        <img alt="warning-icon" src={WarningImage} />
        <Typography.Text type="warning" style={{ fontSize: 12 }}>
          確認待ち
        </Typography.Text>
      </Space>
    );
  };

  const showConfirm = (message, actionFn) => {
    Modal.confirm({
      title: message,
      okText: 'はい',
      okType: 'primary',
      cancelText: 'いいえ',
      onOk: () => {
        actionFn();
      },
    });
  };

  const renderItem = (item) => {
    const confirmText = removeInvoiceForwardEmailConfirmText
      ? removeInvoiceForwardEmailConfirmText
        ?.replace('{owner_name}', name)
        ?.replace('{cc_email}', item?.email)
      : `${item?.email}から${name}を削除してよろしいですか`;
    const handleDelete = () => showConfirm(confirmText, () => handleRemoveInvitationEmail({
      invitationEmailId: item.objectId,
    })
    );
    return (
      <List.Item
        actions={[
          <Button
            key="remove-btn"
            disabled={actionLoading}
            type="ghost"
            danger
            onClick={handleDelete}
          >
            削除
          </Button>,
        ]}
      >
        <List.Item.Meta
          title={item.email}
          description={renderStatus(item.status === 'ACCEPTED')}
        />
      </List.Item>
    );
  };

  const renderInvoiceForwardEmails = () => {
    const invoiceForwardEmails = _get(
      invitationEmails,
      invitationEmailType[
        brandId ? 'BRAND_INVOICE_FORWARD' : 'INVOICE_FORWARD'
      ],
      []
    );
    return (
      <List
        loading={dataLoading}
        itemLayout="horizontal"
        dataSource={invoiceForwardEmails}
        renderItem={renderItem}
      />
    );
  };

  return (
    <>
      {primaryEmailModal && (
        <PrimaryEmailModal
          restaurantId={restaurantId}
          supplierId={supplierId}
          brandOwnerId={brandOwnerId}
          handleFinish={() => {
            getInvitationEmails();
            setPrimaryEmailModal(false);
          }}
          handleCancel={() => {
            setPrimaryEmailModal(false);
          }}
        />
      )}
      {invoiceEmailModal && (
        <InvoiceEmailModal
          restaurantId={restaurantId}
          brandId={brandId}
          title={title}
          handleFinish={() => {
            getInvitationEmails();
            setInvoiceEmailModal(false);
          }}
          handleCancel={() => {
            setInvoiceEmailModal(false);
          }}
        />
      )}
      <div>
        {dataLoading && isPrimary && (
          <Skeleton active title={false} paragraph={{ rows: 1, width: 250 }} />
        )}
        {isPrimary && !dataLoading && (
          <Space direction="vertical" className="full-w">
            <Space className="flex justify-between">
              <Space direction="vertical" size={0}>
                <Text>{email}</Text>
                {renderStatus(emailVerified)}
              </Space>
              {!pendingPrimaryEmailChange
                    && (isHidden ? (
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: 12 }}
                      >
                        Restaurant has not finished registration
                      </Typography.Text>
                    ) : (
                      <Button
                        disabled={dataLoading || actionLoading}
                        type="ghost"
                        onClick={() => {
                          setPrimaryEmailModal(true);
                        }}
                      >
                        更新
                      </Button>
                    ))}
            </Space>
            {pendingPrimaryEmailChange && (
              <Card
                style={{ background: '#fff1b8' }}
                className="highlight-card max-width-644 full-w"
              >
                <Space size={16} direction="vertical" className="full-w">
                  <Space align="start" size={16} className="full-w">
                    <WarningFilled
                      style={{ color: '#edb317', fontSize: 22 }}
                      size={22}
                    />
                    <div>
                      <Typography.Text strong>
                        新しいメールアドレスを認証してください
                      </Typography.Text>
                      <br />
                      <Typography>
                        <Typography.Text strong>
                          {pendingPrimaryEmailChange.email}
                        </Typography.Text>{' '}
                        に認証依頼をお送りしています。メールに記載されたボタンをタップして認証してください。認証後、メールアドレスの変更が完了します。
                      </Typography>
                    </div>
                  </Space>
                  <Space className="flex justify-end" size={8}>
                    <Button
                      type="default"
                      disabled={actionLoading}
                      onClick={() => handleRemoveInvitationEmail({
                        invitationEmailId: pendingPrimaryEmailChange.objectId,
                      })}
                    >
                      キャンセル
                    </Button>
                    <Button
                      type="primary"
                      disabled={actionLoading}
                      onClick={() => handleResendInvitationEmail({
                        invitationEmailId: pendingPrimaryEmailChange.objectId,
                      })}
                    >
                      再送する
                    </Button>
                  </Space>
                </Space>
              </Card>
            )}
          </Space>
        )}
        {!isPrimary && (
          <>
            <Space direction="vertical" className="full-w">
              <Typography.Text>
                {invoiceForwardEmailHeader
                  || '下記メールアドレスにも月次請求書が送付されます'}
              </Typography.Text>
              {isHidden && (
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  Restaurant has not finished registration
                </Typography.Text>
              )}
            </Space>
            {!isHidden && (
              <>
                {renderInvoiceForwardEmails()}
                <Divider />
                <Button
                  disabled={dataLoading || actionLoading}
                  icon={<PlusOutlined />}
                  type="ghost"
                  className="full-w"
                  style={{ color: '#1890ff' }}
                  onClick={() => {
                    setInvoiceEmailModal(true);
                  }}
                >
                  メールアドレスを追加
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

EmailInvitation.propTypes = {
  isPrimary: PropTypes.bool,
  restaurantId: PropTypes.string,
  supplierId: PropTypes.string,
  brandOwnerId: PropTypes.string,
  brandId: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  isHidden: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  invoiceForwardEmailHeader: PropTypes.string,
  removeInvoiceForwardEmailConfirmText: PropTypes.string,
};

export default EmailInvitation;
