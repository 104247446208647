import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Modal } from 'antd';

import {
  getCollectionDetailRequest,
  assignRestaurantToCollectionRequest,
} from 'providers/CollectionProvider/actions';
import { doRefresh } from 'providers/CommonProvider/actions';
import AddRestaurantForm from './AddRestaurantForm';

const { confirm } = Modal;
const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const AddRestaurant = ({ handleCloseDialog, visible, collectionId }) => {
  const dispatch = useDispatch();
  const [createActionLoading, setCreateActionLoading] = useState(false);

  const collectionDetail = useSelector(
    (state) => state.collectionProvider.collectionDetail,
    shallowEqual
  );

  const collectionName = _get(collectionDetail, 'name');

  const handleCancel = () => {
    showConfirm('中断してよろしいですか？', handleCloseDialog);
  };

  const handleSubmit = async ({ restaurantIds }) => {
    if (!_isEmpty(restaurantIds)) {
      setCreateActionLoading(true);
      try {
        await dispatch(assignRestaurantToCollectionRequest({
          restaurantIds,
          collectionId
        }));
        handleCloseDialog();
        dispatch(getCollectionDetailRequest({ objectId: collectionId }));
        dispatch(doRefresh({ target: 'restaurantList' }));
      } catch (error) {
        console.error(error);
      }
      setCreateActionLoading(false);
    }
  };

  return (
    <Modal
      title="レストラン選択"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      destroyOnClose
      centered
      maskClosable={false}
      width={578}
    >
      <p>
        {collectionName} を利用するレストランを選択して下さい。<br />
        一つのレストランにつき一つのセット商品だけを利用できる<br />
        既に他のセット商品を利用しているレストランを選択できません。
      </p>
      <AddRestaurantForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        createActionLoading={createActionLoading}
        collectionId={collectionId}
      />
    </Modal>
  );
};

AddRestaurant.propTypes = {
  handleCloseDialog: PropTypes.func,
  visible: PropTypes.bool,
  collectionId: PropTypes.any,
};

export default AddRestaurant;
