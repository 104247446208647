import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Space, Typography
} from 'antd';

const { Text } = Typography;

const ProfileInfo = ({
  image,
  name,
  email,
  shape,
  copyable,
}) => (
  <div className="flex items-center full-w">
    <Avatar
      alt="avatar"
      src={image}
      size={32}
      shape={shape}
    />
    <Space direction="vertical" size={0} style={{ overflow: 'hidden', flex: 1, marginLeft: 4 }}>
      <Text
        ellipsis={{
          tooltip: name,
        }}
      >{name}
      </Text>
      <Text
        ellipsis={{
          tooltip: email,
        }}
        copyable={copyable}
        type="secondary"
        style={{ fontSize: 12 }}
      >{email}
      </Text>
    </Space>
  </div>
);

ProfileInfo.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  shape: PropTypes.string,
  copyable: PropTypes.bool,
};

export default ProfileInfo;
