import {
  GET_INVOICE_LIST_REQUEST,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_ERROR,
  GET_STATEMENT_INVOICE_LIST_REQUEST,
  GET_STATEMENT_INVOICE_LIST_SUCCESS,
  GET_STATEMENT_INVOICE_LIST_ERROR,
} from './constants';

export function getInvoiceListRequest(payload) {
  return { type: GET_INVOICE_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getInvoiceListSuccess(payload, meta) {
  return { type: GET_INVOICE_LIST_SUCCESS, payload, meta };
}

export function getInvoiceListError(payload, meta) {
  return {
    type: GET_INVOICE_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getStatementInvoiceListRequest(payload) {
  return {
    type: GET_STATEMENT_INVOICE_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getStatementInvoiceListSuccess(payload, meta) {
  return { type: GET_STATEMENT_INVOICE_LIST_SUCCESS, payload, meta };
}

export function getStatementInvoiceListError(payload, meta) {
  return {
    type: GET_STATEMENT_INVOICE_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}
