import moment from 'moment';
import _get from 'lodash/get';
import React, { useState } from 'react';
import { replace } from 'connected-react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { reset, isDirty, submit } from 'redux-form';
import {
  Col,
  Row,
  Card,
  Descriptions,
  Typography,
  Divider,
  Space,
  Button,
  Affix,
  Modal,
} from 'antd';

import toastMessage from 'utils/toastMessage';
import { DATE_TIME_FORMAT } from 'utils/constants';

import {
  getBrandOwnerDetailRequest,
  updateBrandOwnerRequest,
} from 'providers/BrandOwnerProvider/actions';

import EmailInvitation from 'components/EmailInvitation';
import { doRefresh } from 'providers/CommonProvider/actions';
import StatusToggle from '../../../StatusSwitch';
import BrandOwnerForm from './BrandOwnerForm';

const { Item } = Descriptions;
const { Text } = Typography;
const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const BrandOwnerProfile = () => {
  const dispatch = useDispatch();
  const [updateActionLoading, setUpdateActionLoading] = useState(false);

  const brandOwnerDetail = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerDetail,
    shallowEqual
  );

  const objectId = _get(brandOwnerDetail, 'objectId');

  const dirty = useSelector((state) => isDirty('brandOwnerForm')(state));

  const handleSubmit = async (value) => {
    setUpdateActionLoading(true);
    try {
      await dispatch(
        updateBrandOwnerRequest({ ...value, brandOwnerId: objectId })
      );
      toastMessage.success({
        description: 'プロフィールを更新しました。',
      });
      dispatch(getBrandOwnerDetailRequest({ objectId }));
    } catch (err) {
      console.error(err);
    }
    setUpdateActionLoading(false);
  };

  return (
    <>
      <Card className="bg-transparent">
        <Row gutter={[16, 16]} id="supplier-info-container">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <Card
                className="highlight-card"
                title="ブランドオーナー情報"
                bordered={false}
              >
                <Descriptions
                  column={1}
                  bordered
                  labelStyle={{ verticalAlign: 'top' }}
                >
                  <Item label="ブランドオーナー名">
                    <div>{brandOwnerDetail?.name}</div>
                    <Text>{brandOwnerDetail?.nameKana}</Text>
                  </Item>
                  <Item label="電話番号">
                    <div>{brandOwnerDetail?.phone || '--'}</div>
                  </Item>
                </Descriptions>
              </Card>
              <Card className="highlight-card" title="メモ" bordered={false}>
                <BrandOwnerForm
                  initialValues={{ memo: _get(brandOwnerDetail, 'memo') }}
                  onSubmit={handleSubmit}
                />
              </Card>
            </Space>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w flex">
              <Card
                title="アカウント"
                className="highlight-card"
                bordered={false}
              >
                <Descriptions column={1} bordered>
                  <Item label="アカウント">
                    <EmailInvitation
                      isPrimary
                      brandOwnerId={brandOwnerDetail?.objectId}
                      email={brandOwnerDetail?.email}
                      emailVerified={brandOwnerDetail?.emailVerified}
                    />
                  </Item>
                  <Item>
                    <div className="divider">
                      <Divider />
                    </div>
                  </Item>
                  <Item label="ステータス">
                    <StatusToggle
                      rowRecord={{
                        objectId: brandOwnerDetail.objectId,
                        name: brandOwnerDetail.name,
                        status: brandOwnerDetail?.status,
                        callBack: () => {
                          dispatch(doRefresh({ target: 'brandOwnerDetail' }));
                        },
                      }}
                    />
                  </Item>
                </Descriptions>
              </Card>
              <Card title="販売者" className="highlight-card">
                <Descriptions column={1} bordered>
                  <Item label="販売者件数">
                    <Space className="flex justify-between">
                      <Text>{brandOwnerDetail?.supplierCount} 販売者</Text>
                      <Button
                        type="ghost"
                        onClick={() => {
                          dispatch(
                            replace(
                              `/brands/brand-owners/detail/${objectId}/supplier-list`
                            )
                          );
                        }}
                      >
                        詳細
                      </Button>
                    </Space>
                  </Item>
                </Descriptions>
                <Divider />
                <Descriptions column={1} bordered>
                  <Item label="ブランド件数">
                    <Space className="flex justify-between">
                      <Text>{brandOwnerDetail?.brandCount} ブランド</Text>
                      <Button
                        type="ghost"
                        onClick={() => {
                          dispatch(
                            replace(
                              `/brands/brand-owners/detail/${objectId}/brand-list`
                            )
                          );
                        }}
                      >
                        詳細
                      </Button>
                    </Space>
                  </Item>
                </Descriptions>
              </Card>
              <Card title="変更履歴" className="highlight-card">
                <Descriptions column={1} bordered>
                  <Item label="更新日">
                    {brandOwnerDetail?.updatedAt
                      && moment(brandOwnerDetail?.updatedAt).format(
                        DATE_TIME_FORMAT
                      )}
                  </Item>
                  <Divider />
                  <Item label="作成日">
                    {brandOwnerDetail?.createdAt
                      && moment(brandOwnerDetail?.createdAt).format(
                        DATE_TIME_FORMAT
                      )}
                  </Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
        </Row>
      </Card>
      {dirty && (
        <Affix offsetBottom={0}>
          <div
            className="full-w flex justify-between items-center"
            style={{
              padding: '16px 16px 16px 24px',
              backgroundColor: '#293137',
            }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8}>
              <Button
                disabled={updateActionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('brandOwnerForm'))
                )}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(submit('brandOwnerForm'))}
                loading={updateActionLoading}
              >
                送信
              </Button>
            </Space>
          </div>
        </Affix>
      )}
    </>
  );
};

export default BrandOwnerProfile;
