import moment from 'moment';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { submit, reset, isDirty } from 'redux-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Modal,
  Button,
  Card,
  Space,
  Row,
  Col,
  Anchor,
  Affix,
  Spin,
  Alert,
  Typography,
} from 'antd';
import Icon, { CloseCircleFilled } from '@ant-design/icons';
import { replace } from 'connected-react-router';

import Helpers from 'utils/helpers';
import { doRefresh } from 'providers/CommonProvider/actions';
import {
  updateMenuItemRequest,
  changeMenuItemStatusRequest,
} from 'providers/MenuProvider/actions';
import { PRODUCT_PRICE_TYPE, STATUS, USER_ROLE } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import pdfIcon from 'images/icon-pdf.svg';
import { ReactComponent as IconClose } from 'images/icon-close.svg';
import ProductCreateForm from '../../Create/ProductCreateForm';

import './style.scss';

const { confirm } = Modal;
const { Link } = Anchor;
const { Text } = Typography;

const ProductUpdateDialog = ({ match }) => {
  const dispatch = useDispatch();
  const menuId = _get(match, 'params.productId');

  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteProductLoading, setDeleteProductLoading] = useState(false);

  const menuItemDetail = useSelector(
    (state) => state.menuProvider.menuItemDetail,
    shallowEqual
  );
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';
  const isOperator = currentUser?.role === USER_ROLE.OPERATOR;

  const dirty = useSelector((state) => isDirty('productCreateForm')(state));
  const syncingStatus = Helpers.getSyncStatus(menuItemDetail.warehouseMenus);

  const updateMenuItem = async (values) => {
    setUpdateLoading(true);
    try {
      let params = { ...values };
      if (values?.warehouse_airLogiIntegrated) {
        params = _omit(params, ['stock_quantity', 'warehouse_directShipment']);
        if (!values?.janCode && menuItemDetail?.status === STATUS.ACTIVE) {
          setUpdateLoading(false);
          toastMessage.error({
            message: 'エラー',
            description:
              '有効な商品のJANコードを空にすることはできません。まず商品を無効にしてください。',
          });
          return;
        }
      }
      if (!values?.isBasic) {
        params = _omit(params, ['membership_tier', 'order']);
      }

      if (values?.isBasic) {
        const discountPrice = values?.prices.find(
          (price) => price?.type === PRODUCT_PRICE_TYPE.DISCOUNT
        );
        params.discountPrice = {
          priceBeforeTax: discountPrice?.priceBeforeTax,
          taxRate: discountPrice?.taxRate,
          name: discountPrice?.name,
          isSelected: discountPrice?.isSelected,
        };
      }
      params.prices = values?.prices.filter(
        (price) => price?.type !== PRODUCT_PRICE_TYPE.DISCOUNT
      );
      await dispatch(
        updateMenuItemRequest({
          ...params,
          formattedObjectId: menuId,
          stock_isSoldOut: !params?.isInStock,
          mainAllergies: Object.keys(params?.mainAllergies || {}),
          subAllergies: Object.keys(params?.subAllergies || {}),
        })
      );
      dispatch(doRefresh({ target: 'product-detail' }));
    } catch (error) {
      console.error(error);
      setUpdateLoading(false);
    }
  };

  const showConfirm = (
    message,
    actionFn,
    content,
    okText = 'はい',
    danger = false
  ) => {
    confirm({
      content,
      title: message,
      className: 'ant-btn-dangerous-wrapper',
      okText,
      okType: 'primary',
      cancelText: 'キャンセル',
      onOk: () => {
        actionFn();
      },
      okButtonProps: {
        danger,
      },
    });
  };

  const arrivalDate = _get(
    menuItemDetail,
    'warehouseMenus[0].stock_arrivalDate'
  );
  const airLogiIntegrated = menuItemDetail?.warehouse_airLogiIntegrated;
  const stockQuantity = _get(
    menuItemDetail,
    'warehouseMenus[0].stock_quantity'
  );
  const inventory = {
    stock_arrivalDate: (arrivalDate && moment(arrivalDate)) || '',
    isInStock: !menuItemDetail?.warehouseMenus[0]?.stock_isSoldOut,
  };

  if (!airLogiIntegrated) {
    inventory.stock_quantity = stockQuantity;
  }

  const defaultValues = _pick(menuItemDetail, [
    'name',
    'nameKatakana',
    'note',
    'allergen',
    'amount',
    'brandFee',
    'cost',
    'freeCode',
    'isBasic',
    'janCode',
    'leadTime',
    'minLimitOrder',
    'maxLimitOrder',
    'standard',
    'storageCondition',
    'unit',
    'isMarketPrice',
    'isIndefinitePrice',
    'warehouse_airLogiIntegrated',
    'warehouse_directShipment',
    'order',
    'snsText',
    'basicPricePerUnit',
    'basicPricePerMeal',
    'estimatedOfferPricePerMeal',
    'estimatedGrossProfitPerMeal',
    'howToCook',
    'preservationMethod',
    'remarksMemo',
    'isAllowDownloadImage',
  ]);
  const mainAllergies = _reduce(
    menuItemDetail?.mainAllergies,
    (pre, cur) => {
      pre[cur.objectId] = true;
      return pre;
    },
    {}
  );
  const subAllergies = _reduce(
    menuItemDetail?.subAllergies,
    (pre, cur) => {
      pre[cur.objectId] = true;
      return pre;
    },
    {}
  );

  const handleUpdateMenu = (value) => {
    if (syncingStatus?.className === 'syncing') {
      showConfirm(
        '同期中に変更を保存しますか？',
        () => updateMenuItem(value),
        '現在 AiR Logi に商品情報を同期中です。このまま商品情報を保存すると、シコメルと AiR Logi の間で不整合が発生する可能性があります。保存しますか？',
        '保存する'
      );
      return;
    }
    if (
      !_isEqual(mainAllergies, value.mainAllergies)
      || !_isEqual(subAllergies, value.subAllergies)
    ) {
      confirm({
        title: 'アレルギー情報が変更されています。保存してよろしいでしょうか？',
        okText: '保存して公開する',
        okType: 'primary',
        cancelText: '再確認する',
        onOk: () => updateMenuItem(value),
      });
      return;
    }
    updateMenuItem(value);
  };

  const warehouseMenus = menuItemDetail?.warehouseMenus || [];
  const syncError = _find(
    warehouseMenus,
    'airLogi_errorMessage'
  )?.airLogi_errorMessage;

  const prices = [..._get(menuItemDetail, 'prices', [])];
  if (isShikomelSupplier || isOperator) {
    prices.push({
      taxRate: menuItemDetail?.discountPrice?.taxRate || 0,
      priceBeforeTax: menuItemDetail?.discountPrice?.priceBeforeTax || 0,
      name: menuItemDetail?.discountPrice?.name || 'Discount',
      isSelected: menuItemDetail?.discountPrice?.isSelected || false,
      type: PRODUCT_PRICE_TYPE.DISCOUNT,
    });
  }

  const errorMessage = syncingStatus?.className === 'sync-error'
    && syncError && (
    <Col span={24}>
      <Alert
        description={(
          <Space direction="vertical">
            {warehouseMenus[0]?.airLogi_errorMessage && (
              <div className="flex direction-column">
                <Text strong>
                  {warehouseMenus[0]?.airLogi_warehouseKey === 'OSAKA'
                    ? '大阪'
                    : '小田原'}
                  倉庫からデータを取得中にエラーが発生しました。
                </Text>
                <Text type="secondary">
                  {warehouseMenus[0]?.airLogi_errorMessage}
                </Text>
              </div>
            )}
            {warehouseMenus[1]?.airLogi_errorMessage && (
              <div className="flex direction-column">
                <Text strong>
                  {warehouseMenus[1]?.airLogi_warehouseKey === 'OSAKA'
                    ? '大阪'
                    : '小田原'}
                  倉庫からデータを取得中にエラーが発生しました。
                </Text>
                <Text type="secondary">
                  {warehouseMenus[1]?.airLogi_errorMessage}
                </Text>
              </div>
            )}
          </Space>
        )}
        type="error"
        showIcon
        icon={<CloseCircleFilled />}
      />
    </Col>
  );

  const deleteProduct = async () => {
    setDeleteProductLoading(true);
    try {
      await dispatch(
        changeMenuItemStatusRequest({
          menuItemId: menuItemDetail?.objectId,
          status: STATUS.DELETED,
        })
      );
      toastMessage.success({
        description: 'Product is deleted successfully',
      });
      dispatch(replace('/products'));
    } catch (error) {
      console.error(error);
    }
    setDeleteProductLoading(false);
  };

  if (_isEmpty(menuItemDetail)) {
    return <Spin />;
  }

  return (
    <div id="basic-info">
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 16 }}
        bordered={false}
      >
        <Space direction="vertical" size={16} className="full-w flex">
          <Row gutter={[16, 16]}>
            <Col span={20}>
              <Row gutter={[16, 16]}>
                {errorMessage}
                <Col span={24}>
                  <ProductCreateForm
                    onSubmit={handleUpdateMenu}
                    initialValues={{
                      ...defaultValues,
                      manufacturer: menuItemDetail?.manufacturer?.objectId,
                      category: menuItemDetail?.category?.objectId,
                      subcategory: menuItemDetail?.subcategory?.objectId,
                      brand: menuItemDetail?.brand?.objectId,
                      images: menuItemDetail?.images?.map((image, index) => ({
                        uid: index,
                        url: image?.url,
                        objectId: image?.objectId,
                      })),
                      snsFiles: menuItemDetail?.snsFiles?.map(
                        (file, index) => ({
                          uid: index,
                          url:
                            file.name?.slice(-3).trim() === 'pdf'
                              ? pdfIcon
                              : file?.url,
                          objectId: file?.objectId,
                          name: file?.name,
                        })
                      ),
                      genres: menuItemDetail?.genres?.map(
                        (genre) => genre?.objectId
                      ),
                      membership_tier: menuItemDetail?.membership_tier || 1,
                      ...inventory,
                      tagIds: menuItemDetail?.tags?.map((tag) => tag?.objectId),
                      prices,
                      mainAllergies,
                      subAllergies,
                    }}
                    disabled
                    menuItemDetail={menuItemDetail}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={4} id="product-create-anchor">
              <Anchor offsetTop={80}>
                <div className="anchor-item">
                  <Link href="#basic-info" title="商品の追加" />
                </div>
                <div className="anchor-item">
                  <Link href="#productOrganization" title="商品の管理" />
                </div>
                <div className="anchor-item">
                  <Link href="#order-info" title="商品の納期および発注数量" />
                </div>
                <div className="anchor-item">
                  <Link href="#addition-info" title="商品の追加情報" />
                </div>
                {isShikomelSupplier && (
                  <div className="anchor-item">
                    <Link href="#sns" title="Promotion Materials" />
                  </div>
                )}
              </Anchor>
            </Col>
          </Row>
          <Button
            id="delete-product-btn"
            type="primary"
            loading={deleteProductLoading}
            icon={<Icon component={IconClose} />}
            danger
            disabled={
              menuItemDetail?.status !== STATUS.INACTIVE || updateLoading
            }
            onClick={() => showConfirm(
              '商品を完全に削除しますか？',
              deleteProduct,
              '商品削除をすると元に戻すことはできません。一時的にアプリから非表示にする場合は、無効にしてください。',
              '削除',
              true
            )}
          >
            商品削除
          </Button>
        </Space>
      </Card>
      {dirty && (
        <Affix offsetBottom={0} className="full-w">
          <Row
            className="full-w"
            style={{
              backgroundColor: '#293137',
            }}
          >
            <Col span={20}>
              <Space
                size={8}
                className="full-w flex justify-end"
                style={{ padding: '16px' }}
              >
                <Button
                  type="default"
                  disabled={updateLoading || deleteProductLoading}
                  onClick={() => showConfirm('中断してよろしいですか', () => dispatch(reset('productCreateForm'))
                  )}
                >
                  キャンセル
                </Button>
                <Button
                  type="primary"
                  loading={updateLoading}
                  disabled={deleteProductLoading}
                  onClick={() => dispatch(submit('productCreateForm'))}
                  style={{ marginRight: '16px' }}
                >
                  保存
                </Button>
              </Space>
            </Col>
          </Row>
        </Affix>
      )}
    </div>
  );
};
ProductUpdateDialog.propTypes = {
  match: PropTypes.any,
};

export default ProductUpdateDialog;
