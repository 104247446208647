import moment from 'moment';
import React from 'react';
import _get from 'lodash/get';
import { Space, Typography, Tooltip } from 'antd';

import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT, DATE_FORMAT } from 'utils/constants';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';

const Columns = {};

Columns.ID = {
  title: 'ID',
  width: 120,
  key: 'objectId',
  dataIndex: 'objectId',
};

Columns.restaurantInfo = {
  title: 'レストラン',
  width: 250,
  key: 'restaurantName',
  dataIndex: 'restaurant',
  render: (record) => (
    <div className="flex items-center full-w">
      <img
        style={{ borderRadius: 25, marginRight: 8 }}
        alt="name"
        src={_get(record, 'image', DefaultRestaurantImage)}
        width={32}
        height={32}
      />
      <Space
        direction="vertical"
        size={0}
        className="full-w"
        style={{ overflow: 'hidden' }}
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
        </Tooltip>
        <Tooltip placement="topLeft" title={_get(record, 'email', '-')}>
          <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
            {_get(record, 'email', '-')}
          </Typography.Text>
        </Tooltip>
      </Space>
    </div>
  ),
};

Columns.supplierInfo = {
  title: '販売者',
  width: 250,
  key: 'supplierInfo',
  dataIndex: 'supplier',
  render: (record) => (
    <div className="flex items-center full-w">
      <img
        style={{ borderRadius: 25, marginRight: 8 }}
        alt="name"
        src={_get(record, 'image', DefaultSupplierImage)}
        width={32}
        height={32}
      />
      <Space
        direction="vertical"
        size={0}
        className="full-w"
        style={{ overflow: 'hidden', flex: 1 }}
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
        </Tooltip>
        <Tooltip placement="topLeft" title={_get(record, 'email', '-')}>
          <Typography.Text type="secondary" ellipsis style={{ fontSize: 12 }}>
            {_get(record, 'email', '-')}
          </Typography.Text>
        </Tooltip>
      </Space>
    </div>
  ),
};

Columns.numberOfProduct = {
  title: '注文商品数',
  align: 'right',
  dataIndex: 'brandProductCount',
  width: 110,
};

Columns.totalBrandFee = {
  title: '合計ブランドフィー',
  align: 'right',
  dataIndex: 'totalBrandFee',
  width: 160,
  render: (record) => `¥${Helpers.numberWithCommas(record)}`,
};

Columns.orderDate = {
  title: '注文日',
  width: 150,
  dataIndex: 'createdAt',
  key: 'orderDate',
  render: (record) => record && moment(record).format(DATE_TIME_FORMAT),
};

Columns.deliveryDate = {
  title: '納品希望日',
  width: 120,
  dataIndex: 'deliveryDate',
  key: 'deliveryDate',
  render: (record) => record && moment(record).format(DATE_FORMAT),
};

Columns.status = {
  title: '注文の状況',
  width: 150,
  key: 'status',
  fixed: 'right',
};

Columns.numberOfOrderEachSupplier = {
  title: '注文件数',
  align: 'right',
  width: 150,
  dataIndex: 'orderCount',
};

export default Columns;
