import moment from 'moment';
import _get from 'lodash/get';
import React, { useCallback, useEffect } from 'react';
import {
  Card, Button, Space, Badge, Typography, Image
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useTableControl from 'components/Hooks/useTableControl';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { getInAppMessageListRequest } from 'providers/InAppMessageProvider/actions';

import Table from 'components/Table';
import ProfileInfo from 'components/ProfileInfo';
import DefaultProductImage from 'images/defaultProduct.svg';
import './style.less';
import { STATUS_BADGE_CONTENT } from '../Detail';

const columns = [
  {
    title: 'ID',
    dataIndex: 'objectId',
    fixed: 'left',
    width: 130,
  },
  {
    title: 'タイトル',
    dataIndex: 'title',
    width: 250,
    render: (title) => (
      <Typography.Paragraph
        ellipsis={{
          rows: 3,
          tooltip: title,
        }}
        style={{
          marginBottom: 0,
        }}
      >{title || '-'}
      </Typography.Paragraph>
    )
  },
  {
    title: '本文',
    dataIndex: 'message',
    width: 250,
    render: (message) => (
      <Typography.Paragraph
        ellipsis={{
          rows: 3,
          tooltip: message,
        }}
        style={{
          marginBottom: 0,
        }}
      >{message || '-'}
      </Typography.Paragraph>
    ),
  },
  {
    title: '画像',
    dataIndex: 'image',
    width: 120,
    render: (image) => (image ? <Image src={image?.url} preview={false} /> : '-'),
  },
  {
    title: '添付ファイル',
    key: 'attachmentFile',
    render: (value) => {
      const attachmentFile = value?.targetFile;
      const productDetail = value?.targetMenu;
      const targetUrl = value?.targetUrl;
      const productItem = (
        <ProfileInfo
          image={_get(productDetail, 'images[0].url', DefaultProductImage)}
          shape="square"
          name={_get(productDetail, 'name')}
          email={
            `${_get(productDetail, 'standard', '-')} • ${_get(productDetail, 'amount', '-')} ／ 
              ${_get(productDetail, 'unit', '-')}`
          }
        />
      );
      const fileItem = (
        <Typography.Text
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {attachmentFile?.name || targetUrl}
        </Typography.Text>
      );
      return (
        <>
          {(attachmentFile?.url || targetUrl) && fileItem}
          {productDetail && productItem}
          {!attachmentFile?.url && !productDetail && !targetUrl && '-'}
        </>
      );
    },
    width: 250,
  },
  {
    title: '配信開始日',
    dataIndex: 'startDate',
    width: 150,
    render: (record) => (record ? <div>{moment(record).format(DATE_TIME_FORMAT)}</div> : '-'),
  },
  {
    title: '配信終了日',
    dataIndex: 'endDate',
    width: 150,
    render: (record) => (record ? <div>{moment(record).format(DATE_TIME_FORMAT)}</div> : '期限なし'),
  },
  {
    title: '作成日時',
    dataIndex: 'createdAt',
    width: 150,
    render: (record) => <div>{moment(record).format(DATE_TIME_FORMAT)}</div>,
  },
  {
    title: 'ステータス',
    key: 'status',
    width: 130,
    fixed: 'right',
    render: (record) => (
      <div id="announcement-detail-status-dot">
        <Badge
          status={STATUS_BADGE_CONTENT[record.status]?.type}
          text={STATUS_BADGE_CONTENT[record.status]?.text}
        />

      </div>
    ),
  },
];

const AnnouncementList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [pageControl, , onTableChange] = useTableControl();
  const {
    page, limit
  } = pageControl;

  const {
    inAppMessageList,
    isLoading,
  } = useSelector(
    (state) => state.inAppMessageProvider,
    shallowEqual,
  );

  const fetchAnnouncementList = useCallback(
    () => dispatch(getInAppMessageListRequest({ page, limit })),
    [dispatch, page, limit],
  );

  useEffect(() => {
    fetchAnnouncementList();
  }, [fetchAnnouncementList]);

  const onRowClick = (record) => {
    push(`/in-app-messages/detail/${record.objectId}`);
  };

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Space
          direction="horizontal"
          size="middle"
          className="flex justify-end padding-16 border-bottom"
          id="manufacturer-list-action"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => push('/in-app-messages/create')}
          >
            新規作成
          </Button>
        </Space>
        <Table
          columns={columns}
          onRowClick={onRowClick}
          data={inAppMessageList?.list || []}
          total={inAppMessageList?.total || 0}
          loading={isLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 1200 }}
        />
      </Card>
    </Card>
  );
};
export default AnnouncementList;
