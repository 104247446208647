import _get from 'lodash/get';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ProductDetail from '../ProductDetail';
import AssignedRestaurant from '../AssignedRestaurant';
import CollectionList from '../CollectionList';
import RelatedProductList from '../RelatedProductList';

const RedirectToInAppConfig = (props) => {
  const { restaurantId } = _get(props, 'match.params');
  return <Redirect to={`/products/${restaurantId}/in-app`} />;
};

const indexRoutes = [
  { path: '/products/detail/:productId/in-app', component: ProductDetail },
  {
    path: '/products/detail/:productId/assigned-restaurant',
    component: AssignedRestaurant,
  },
  {
    path: '/products/detail/:productId/collection-list',
    component: CollectionList,
  },
  {
    path: '/products/detail/:productId/related-product',
    component: RelatedProductList,
  },
  {
    path: '/products/detail/:productId',
    component: RedirectToInAppConfig,
  },
];

export default indexRoutes;
