import _get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  Card,
  Typography,
  Descriptions,
  Row,
  Col,
  Divider,
  Space,
  Avatar,
} from 'antd';
import { useParams } from 'react-router-dom';

import { getSupplierBrandListRequest } from 'providers/SupplierBrandProvider/actions';
import Table from 'components/Table';
import useTableControl from 'components/Hooks/useTableControl';
import { DATE_FORMAT } from 'utils/constants';
import Helpers from 'utils/helpers';
import DefaultBrandImage from 'images/defaultBrand.svg';

const { Item } = Descriptions;
const { Text } = Typography;

const columns = [
  {
    title: '販売者名',
    render: (record) => (
      <Space className="flex items-center">
        <Avatar
          src={_get(record, 'supplier.image', DefaultBrandImage)}
          size={32}
        />
        <Space direction="vertical" size={0}>
          <Typography.Text>{_get(record, 'supplier.name')}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {_get(record, 'supplier.email')}
          </Typography.Text>
        </Space>
      </Space>
    ),
    width: 250,
    fixed: 'left',
  },
  {
    title: '承認日',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (record) => moment(record).format(DATE_FORMAT),
    width: 120,
  },
  {
    title: '商品件数',
    dataIndex: 'productCount',
    align: 'right',
    width: 120,
    render: (record) => Helpers.numberWithCommas(record || 0),
  },
];

const BrandDetailForBrandOwner = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );
  const supplierBrandList = useSelector(
    (state) => state.supplierBrandProvider.supplierBrandList,
    shallowEqual
  );

  const { brandId } = useParams();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        await dispatch(
          getSupplierBrandListRequest({
            page,
            limit,
            orderBy,
            order,
            brandId,
          })
        );
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };
    fetchData();
  }, [page, limit, orderBy, order, brandId]);

  return (
    <Card className="bg-transparent" bordered={false}>
      <Row gutter={[16, 16]}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Card
            bordered={false}
            className="highlight-card"
            title="General"
            headStyle={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
              title="一般"
            >
              <Item label="会社詳細">
                <div>{brandDetail?.name}</div>
                <Text>{brandDetail?.nameKana}</Text>
              </Item>
              <Item label="メールアドレス">
                <div>{brandDetail?.email}</div>
              </Item>
            </Descriptions>
            <Divider />
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
              title="担当者の連絡先"
            >
              <Item label="担当者">
                <div>{brandDetail?.managerName}</div>
                <Text>{brandDetail?.managerNameKana}</Text>
              </Item>
              <Item label="担当者緊急連絡先">
                <div>{brandDetail?.emergencyPhone}</div>
              </Item>
            </Descriptions>
          </Card>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Card
            bordered={false}
            className="highlight-card"
            title="連携設定"
            headStyle={{
              fontWeight: 'bold', fontSize: '20px', padding: '0 16px', borderBottom: '2px solid #e8e8e8'
            }}
            bodyStyle={{ padding: 0 }}
          >
            <Table
              columns={columns}
              data={_get(supplierBrandList, 'list', [])}
              total={_get(supplierBrandList, 'total', 0)}
              loading={tableLoading}
              onChange={onTableChange}
              pagination={{
                marginRight: true,
                current: Number(page),
                pageSize: Number(limit),
              }}
              scroll={{ x: 500 }}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default BrandDetailForBrandOwner;
