import _isEmpty from 'lodash/isEmpty';
import Constants from 'utils/constants';
import Regex from 'utils/regex';
import UtilValidate from 'utils/validate';

const acceptedFileExtensions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg',
];

const validate = (values) => {
  const errors = {};

  if (!values.name?.trim()) {
    errors.name = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.nameKana?.trim()) {
    errors.nameKana = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regex.REGX_KATAKANA.test(values.nameKana)) {
    errors.nameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.postalCode?.trim()) {
    errors.postalCode = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!UtilValidate.validPostalCode(values.postalCode?.trim())) {
    errors.postalCode = Constants.ERROR_MESSAGE.INVALID;
  }
  if (!values.prefecture?.trim()) {
    errors.prefecture = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.address?.trim()) {
    errors.address = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.phone?.trim()) {
    errors.phone = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Regex.PHONE.test(values.phone?.trim())) {
    errors.phone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (values.fax?.trim() && !Regex.PHONE.test(values.fax?.trim())) {
    errors.fax = Constants.ERROR_MESSAGE.INVALID_FAX;
  }
  if (
    values.managerNameKana
    && !Regex.REGX_KATAKANA.test(values.managerNameKana?.trim())
  ) {
    errors.managerNameKana = Constants.ERROR_MESSAGE.INVALID;
  }
  if (
    values.emergencyPhone
    && !Regex.PHONE.test(values.emergencyPhone?.trim())
  ) {
    errors.emergencyPhone = Constants.ERROR_MESSAGE.INVALID_PHONE;
  }
  if (!_isEmpty(values.image)) {
    if (values.image.length > 1) {
      errors.image = Constants.ERROR_MESSAGE.INVALID;
    }
    const image = values.image[0];
    if (image?.originFileObj) {
      if (!acceptedFileExtensions.includes(image.originFileObj.type)) {
        errors.image = 'ファイル拡張子が正しくありません';
      }
      if (image.originFileObj.size > 5e6) {
        // Limit size to 5MB
        errors.image = `${5e6 / 1e6}MB以下のファイルを指定してください`;
      }
    }
  }
  if (!values.restaurantType) {
    errors.restaurantType = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.businessType) {
    errors.businessType = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.restaurantBusiness) {
    errors.restaurantBusiness = Constants.ERROR_MESSAGE.REQUIRED;
  }
  if (!values.restaurantIndustry) {
    errors.restaurantIndustry = Constants.ERROR_MESSAGE.REQUIRED;
  }

  return errors;
};

export default validate;
