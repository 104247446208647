import { ERROR_MESSAGE } from 'utils/constants';
import _isEmpty from 'lodash/isEmpty';

const validate = (values) => {
  const errors = {};
  if (
    (_isEmpty(values.deliveryService)
      || values.deliveryService?.includes('OTHER_DELIVERY'))
    && values.companyList?.length
  ) {
    const companyListErrors = [];
    const companyNameList = [];
    values.companyList.forEach((company, index) => {
      const companyError = {};
      const companyName = company?.nameKana?.trim();
      if (companyNameList.includes(companyName)) {
        companyError.nameKana = ERROR_MESSAGE.INVALID;
      } else {
        companyNameList.push(companyName);
      }

      if (!companyName) {
        companyError.nameKana = ERROR_MESSAGE.REQUIRED;
      }

      if (company?.trackingIds?.length) {
        const trackingIdErrors = [];
        company.trackingIds.forEach((tracking, trackingIndex) => {
          const trackingIdError = {};
          if (trackingIndex > 0 && !tracking?.trackingId?.trim()) {
            trackingIdError.trackingId = ERROR_MESSAGE.REQUIRED;
            trackingIdErrors[trackingIndex] = trackingIdError;
          }
        });
        if (trackingIdErrors.length) {
          companyError.trackingIds = trackingIdErrors;
        }
      }
      companyListErrors[index] = companyError;
    });

    if (companyListErrors.length) {
      errors.companyList = companyListErrors;
    }
  }
  return errors;
};

export default validate;
