import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { logoutRequest } from 'providers/AuthProvider/actions';
import { makeSelectCurrentUser } from 'providers/AuthProvider/selectors';
import { countUnreadConversationRequest } from 'providers/ConversationProvider/actions';
import { makeSelectUnreadConversationCount } from 'providers/ConversationProvider/selectors';

import Header from './Header';

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logoutRequest()),
  countUnreadConversationRequest: () => dispatch(countUnreadConversationRequest()),
});

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  unreadConversationCount: makeSelectUnreadConversationCount(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Header);
