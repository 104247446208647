import { ReactComponent as ABALONE } from 'images/Allergy/graphic-allergies-abalon.svg';
import { ReactComponent as ALMOND } from 'images/Allergy/graphic-allergies-almond.svg';
import { ReactComponent as APPLE } from 'images/Allergy/graphic-allergies-apple.svg';
import { ReactComponent as BANANA } from 'images/Allergy/graphic-allergies-banana.svg';
import { ReactComponent as BEEF } from 'images/Allergy/graphic-allergies-beef.svg';
import { ReactComponent as CASHEW_NUTS } from 'images/Allergy/graphic-allergies-cashew.svg';
import { ReactComponent as CHICKEN } from 'images/Allergy/graphic-allergies-chicken.svg';
import { ReactComponent as EGG } from 'images/Allergy/graphic-allergies-egg.svg';
import { ReactComponent as GELATIN } from 'images/Allergy/graphic-allergies-gelatin.svg';
import { ReactComponent as CRAB } from 'images/Allergy/graphic-allergies-grab.svg';
import { ReactComponent as KIWI } from 'images/Allergy/graphic-allergies-kiwi.svg';
import { ReactComponent as MACKEREL } from 'images/Allergy/graphic-allergies-makerel.svg';
import { ReactComponent as MILK_WHEAT } from 'images/Allergy/graphic-allergies-milk-wheat.svg';
import { ReactComponent as MILK } from 'images/Allergy/graphic-allergies-milk.svg';
import { ReactComponent as MATSUTAKE_MUSHROOM } from 'images/Allergy/graphic-allergies-mushroom.svg';
import { ReactComponent as ORANGE } from 'images/Allergy/graphic-allergies-orange.svg';
import { ReactComponent as PEACH } from 'images/Allergy/graphic-allergies-peach.svg';
import { ReactComponent as PEANUT } from 'images/Allergy/graphic-allergies-peanut.svg';
import { ReactComponent as PORK } from 'images/Allergy/graphic-allergies-pork.svg';
import { ReactComponent as SALMON_ROE } from 'images/Allergy/graphic-allergies-roe.svg';
import { ReactComponent as SALMON } from 'images/Allergy/graphic-allergies-salmon.svg';
import { ReactComponent as SESAME_SEEDS } from 'images/Allergy/graphic-allergies-sesame.svg';
import { ReactComponent as SHRIMP } from 'images/Allergy/graphic-allergies-shrimp.svg';
import { ReactComponent as SOBA } from 'images/Allergy/graphic-allergies-soba.svg';
import { ReactComponent as SOYA_BEAN } from 'images/Allergy/graphic-allergies-soy.svg';
import { ReactComponent as SQUID } from 'images/Allergy/graphic-allergies-squid.svg';
import { ReactComponent as WALNUT } from 'images/Allergy/graphic-allergies-walnut.svg';
import { ReactComponent as YAM } from 'images/Allergy/graphic-allergies-yam.svg';

export default {
  EGG,
  MILK,
  MILK_WHEAT,
  SOBA,
  SHRIMP,
  CRAB,
  PEANUT,
  ALMOND,
  ABALONE,
  SQUID,
  SALMON_ROE,
  ORANGE,
  CASHEW_NUTS,
  KIWI,
  BEEF,
  WALNUT,
  SESAME_SEEDS,
  SALMON,
  MACKEREL,
  SOYA_BEAN,
  CHICKEN,
  BANANA,
  PORK,
  MATSUTAKE_MUSHROOM,
  PEACH,
  YAM,
  APPLE,
  GELATIN,
};
