/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Modal } from 'antd';
import {
  enableBrandOwnerAccountRequest,
  disableBrandOwnerAccountRequest,
} from 'providers/BrandOwnerProvider/actions';

import toastMessage from 'utils/toastMessage';
import { STATUS } from 'utils/constants';
import DisableAccountForm from '../../Restaurants/Operator_List/DisableRestaurantForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: '有効にする',
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: () => {
      actionFn();
    },
  });
};

const DisableBrandOwnerModal = ({
  name,
  brandOwnerId,
  showModal,
  setShowModal,
  callBack,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      await dispatch(disableBrandOwnerAccountRequest({ brandOwnerId }));
      setShowModal(false);
      toastMessage.success({
        message: '成功',
        description: name
          ? `アカウント ${name} を無効にしました。`
          : 'アカウントを無効にしました。'
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div role="button" onClick={(e) => e.stopPropagation()}>
      <Modal
        title={
          name
            ? `アカウント ${name} を無効にします`
            : 'アカウントを無効にします'
        }
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        destroyOnClose
        centered
        maskClosable={false}
      >
        <DisableAccountForm
          onSubmit={handleSubmit}
          onCancel={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};

DisableBrandOwnerModal.propTypes = {
  name: PropTypes.string,
  brandOwnerId: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  callBack: PropTypes.func,
};

const StatusSwitch = ({ rowRecord }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    name, callBack, status, objectId: brandOwnerId
  } = rowRecord;

  const handleEnableBrandOwnerAccount = async (params) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(enableBrandOwnerAccountRequest(params));
      toastMessage.success({
        message: '成功',
        description: name
          ? `アカウント ${name} を有効にしました。`
          : 'アカウントを有効にしました。'
      });
      callBack();
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <>
      <DisableBrandOwnerModal
        name={name}
        brandOwnerId={brandOwnerId}
        showModal={showModal}
        setShowModal={setShowModal}
        callBack={callBack}
      />
      <div
        role="button"
        className="status-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <span
            className={`dot${status === STATUS.ACTIVE ? '' : '--inactive'}`}
          />
          <span>{status === STATUS.ACTIVE ? '有効' : '無効'}</span>
        </div>
        <Switch
          disabled={changeStatusLoading}
          checked={status === STATUS.ACTIVE}
          onClick={() => {
            if (status === STATUS.INACTIVE) {
              showConfirm(
                name
                  ? `アカウント ${name} を有効にしますか？`
                  : 'アカウントを有効にしますか？',
                () => handleEnableBrandOwnerAccount({ brandOwnerId })
              );
            } else {
              setShowModal(true);
            }
          }}
          size="small"
        />
      </div>
    </>
  );
};

StatusSwitch.propTypes = {
  rowRecord: PropTypes.object,
};

export default StatusSwitch;
