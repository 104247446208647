export const REGISTER_SUPPLIER_REQUEST = 'SupplierProvider/REGISTER_SUPPLIER_REQUEST';
export const REGISTER_SUPPLIER_SUCCESS = 'SupplierProvider/REGISTER_SUPPLIER_SUCCESS';
export const REGISTER_SUPPLIER_ERROR = 'SupplierProvider/REGISTER_SUPPLIER_ERROR';

export const GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_OPERATOR_REQUEST';
export const GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_OPERATOR_SUCCESS';
export const GET_LIST_SUPPLIER_FOR_OPERATOR_ERROR = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_OPERATOR_ERROR';

export const GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_RESTAURANT_REQUEST';
export const GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_RESTAURANT_SUCCESS';
export const GET_LIST_SUPPLIER_FOR_RESTAURANT_ERROR = 'SupplierProvider/GET_LIST_SUPPLIER_FOR_RESTAURANT_ERROR';

export const GET_SUPPLIER_DETAIL_REQUEST = 'SupplierProvider/GET_SUPPLIER_DETAIL_REQUEST';
export const GET_SUPPLIER_DETAIL_SUCCESS = 'SupplierProvider/GET_SUPPLIER_DETAIL_SUCCESS';
export const GET_SUPPLIER_DETAIL_ERROR = 'SupplierProvider/GET_SUPPLIER_DETAIL_ERROR';

export const UPDATE_SUPPLIER_REQUEST = 'SupplierProvider/UPDATE_SUPPLIER_REQUEST';
export const UPDATE_SUPPLIER_SUCCESS = 'SupplierProvider/UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_ERROR = 'SupplierProvider/UPDATE_SUPPLIER_ERROR';

export const GET_SUPPLIER_HOLIDAY_LIST_REQUEST = 'SupplierProvider/GET_SUPPLIER_HOLIDAY_LIST_REQUEST';
export const GET_SUPPLIER_HOLIDAY_LIST_SUCCESS = 'SupplierProvider/GET_SUPPLIER_HOLIDAY_LIST_SUCCESS';
export const GET_SUPPLIER_HOLIDAY_LIST_ERROR = 'SupplierProvider/GET_SUPPLIER_HOLIDAY_LIST_ERROR';

export const CREATE_SUPPLIER_HOLIDAY_REQUEST = 'SupplierProvider/CREATE_SUPPLIER_HOLIDAY_REQUEST';
export const CREATE_SUPPLIER_HOLIDAY_SUCCESS = 'SupplierProvider/CREATE_SUPPLIER_HOLIDAY_SUCCESS';
export const CREATE_SUPPLIER_HOLIDAY_ERROR = 'SupplierProvider/CREATE_SUPPLIER_HOLIDAY_ERROR';

export const DELETE_SUPPLIER_HOLIDAY_REQUEST = 'SupplierProvider/DELETE_SUPPLIER_HOLIDAY_REQUEST';
export const DELETE_SUPPLIER_HOLIDAY_SUCCESS = 'SupplierProvider/DELETE_SUPPLIER_HOLIDAY_SUCCESS';
export const DELETE_SUPPLIER_HOLIDAY_ERROR = 'SupplierProvider/DELETE_SUPPLIER_HOLIDAY_ERROR';

export const CHANGE_EMAIL_REQUEST = 'SupplierProvider/CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'SupplierProvider/CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_ERROR = 'SupplierProvider/CHANGE_EMAIL_ERROR';

export const GET_INVITATION_EMAIL_REQUEST = 'SupplierProvider/GET_INVITATION_EMAIL_REQUEST';
export const GET_INVITATION_EMAIL_SUCCESS = 'SupplierProvider/GET_INVITATION_EMAIL_SUCCESS';
export const GET_INVITATION_EMAIL_ERROR = 'SupplierProvider/GET_INVITATION_EMAIL_ERROR';

export const REMOVE_INVITATION_EMAIL_REQUEST = 'SupplierProvider/REMOVE_INVITATION_EMAIL_REQUEST';
export const REMOVE_INVITATION_EMAIL_SUCCESS = 'SupplierProvider/REMOVE_INVITATION_EMAIL_SUCCESS';
export const REMOVE_INVITATION_EMAIL_ERROR = 'SupplierProvider/REMOVE_INVITATION_EMAIL_ERROR';

export const RESEND_INVITATION_EMAIL_REQUEST = 'SupplierProvider/RESEND_INVITATION_EMAIL_REQUEST';
export const RESEND_INVITATION_EMAIL_SUCCESS = 'SupplierProvider/RESEND_INVITATION_EMAIL_SUCCESS';
export const RESEND_INVITATION_EMAIL_ERROR = 'SupplierProvider/RESEND_INVITATION_EMAIL_ERROR';

export const DISABLE_SUPPLIER_ACCOUNT_REQUEST = 'SupplierProvider/DISABLE_SUPPLIER_ACCOUNT_REQUEST';
export const DISABLE_SUPPLIER_ACCOUNT_SUCCESS = 'SupplierProvider/DISABLE_SUPPLIER_ACCOUNT_SUCCESS';
export const DISABLE_SUPPLIER_ACCOUNT_ERROR = 'SupplierProvider/DISABLE_SUPPLIER_ACCOUNT_ERROR';

export const ENABLE_SUPPLIER_ACCOUNT_REQUEST = 'SupplierProvider/ENABLE_SUPPLIER_ACCOUNT_REQUEST';
export const ENABLE_SUPPLIER_ACCOUNT_SUCCESS = 'SupplierProvider/ENABLE_SUPPLIER_ACCOUNT_SUCCESS';
export const ENABLE_SUPPLIER_ACCOUNT_ERROR = 'SupplierProvider/ENABLE_SUPPLIER_ACCOUNT_ERROR';
