import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Button, Input, Space
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { push } from 'connected-react-router';

import {
  exportRestaurantListRequest,
  getRestaurantListRequest,
} from 'providers/RestaurantProvider/actions';

import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';
import StatusToggle from './StatusToggle';
import Columns from './Columns';

const { Search } = Input;

const RestaurantList = () => {
  const restaurantList = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);
  const [exportCSVLoading, setExportCSVLoading] = useState(false);

  const onRowClick = (record) => dispatch(
    push(`/restaurants/detail/${record.formattedObjectId}/inapp-config`)
  );

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const handleExportCSV = async () => {
    setExportCSVLoading(true);
    try {
      await dispatch(exportRestaurantListRequest());
    } catch (error) {
      console.error(error);
    }
    setExportCSVLoading(false);
  };

  const columns = [
    Columns.ID,
    Columns.info,
    Columns.subscription,
    Columns.subscriptionPayment,
    Columns.paymentError,
    Columns.createdAt,
    Columns.supplierCount,
    Columns.productCount,
    {
      ...Columns.status,
      render: (record, rowRecord) => (
        <StatusToggle
          record={record}
          rowRecord={{ ...rowRecord, callBack: fetchData }}
        />
      ),
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Space className="flex items-center justify-between flex-wrap padding-16 border-bottom">
          <Search
            placeholder="メール/レストランで検索"
            defaultValue={keyword}
            onSearch={(val) => onFilterChange({ keyword: val })}
            style={{ width: 220 }}
            className="search-input"
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={exportCSVLoading}
            onClick={handleExportCSV}
          >
            レストランリストをダウンロード
          </Button>
        </Space>
        <Table
          columns={columns}
          data={_get(restaurantList, 'list', [])}
          total={_get(restaurantList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 1330 }}
          onRowClick={onRowClick}
        />
      </Card>
    </Card>
  );
};

export default RestaurantList;
