import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Space, Typography } from 'antd';

class CheckboxField extends React.PureComponent {
  onChange = (value) => {
    const { input, onFormValueChange } = this.props;
    input.onChange(value || '');
    if (onFormValueChange) {
      onFormValueChange();
    }
  };

  render() {
    const {
      label,
      description,
      input,
      meta: { touched, invalid, error },
      disabled,
    } = this.props;

    return (
      <Form.Item
        help={touched && invalid && error}
        validateStatus={touched && invalid && error ? 'error' : ''}
        hasFeedback
      >
        <Checkbox
          {...input}
          checked={input && input.value}
          disabled={disabled}
          onChange={this.onChange}
        >
          <Space direction="vertical" size={0} style={{ lineHeight: 'normal' }}>
            <Typography.Text>{label}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {description}
            </Typography.Text>
          </Space>
        </Checkbox>
      </Form.Item>
    );
  }
}

CheckboxField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  description: PropTypes.any,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFormValueChange: PropTypes.func,
};

export default CheckboxField;
