import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { createStructuredSelector } from 'reselect';

import {
  getOrderListRequest,
  exportOrdersRequest,
} from 'providers/OrderProvider/actions';
import {
  makeSelectOrderList,
  makeSelectOrderLoad,
} from 'providers/OrderProvider/selectors';
import OrderList from './OrderList';

const mapStateToProps = createStructuredSelector({
  orderList: makeSelectOrderList(),
  loading: makeSelectOrderLoad(),
});

const mapDispatchToProps = (dispatch) => ({
  getOrderListRequest: (value) => dispatch(getOrderListRequest(value)),
  push: (path) => dispatch(push(path)),
  exportOrdersRequest: (value) => dispatch(exportOrdersRequest(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(OrderList);
