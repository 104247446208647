import moment from 'moment';
import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Button, Space, Input, Modal
} from 'antd';
import { push } from 'connected-react-router';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { getRestaurantListRequest } from 'providers/RestaurantProvider/actions';
import { updateRestaurantInvitationStatusRequest } from 'providers/RestaurantOwnerProvider/actions';

import Helpers from 'utils/helpers';
import { DATE_TIME_FORMAT } from 'utils/constants';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import Table from 'components/Table';
import withRefresh from 'components/HOCs/withRefresh';
import useTableControl from 'components/Hooks/useTableControl';
import ProfileInfo from 'components/ProfileInfo';
import InviteRestaurant from './InviteRestaurant';
import PendingList from './PendingList';

const { Search } = Input;
const { confirm } = Modal;

const showConfirm = (title, onOk) => {
  confirm({
    okText: '切断する',
    okType: 'primary',
    cancelText: 'いいえ',
    okButtonProps: { danger: true },
    onOk,
    title,
  });
};

const RestaurantList = () => {
  const restaurantList = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [showInviteRestaurantModal, setShowInviteRestaurantModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
          statuses: ['ACCEPTED'],
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const renderDisconnectButton = (restaurant) => {
    const handleDisconnect = (e) => {
      e.stopPropagation();

      showConfirm(`${restaurant?.restaurant?.name}を切断します?`, async () => {
        try {
          await dispatch(
            updateRestaurantInvitationStatusRequest({
              invitationId: restaurant?.objectId,
              status: 'DELETED',
            })
          );
          fetchData();
        } catch (error) {
          console.error(error);
        }
      });
    };

    return (
      <Button
        type="text"
        danger
        icon={<CloseOutlined />}
        onClick={handleDisconnect}
      >
        削除
      </Button>
    );
  };

  const columns = [
    {
      title: 'ID',
      width: 110,
      dataIndex: ['restaurant', 'formattedObjectId'],
      key: 'objectId',
    },
    {
      title: 'レストラン名',
      width: 250,
      render: (record) => (
        <ProfileInfo
          image={_get(record, 'restaurant.image', DefaultRestaurantImage)}
          name={_get(record, 'restaurant.name')}
          email={_get(record, 'restaurant.email', '-')}
        />
      ),
    },
    {
      title: '招待日',
      width: 150,
      dataIndex: 'statusLog',
      key: 'invitedDate',
      render: (record) => (_get(record, 'PENDING')
        ? moment(_get(record, 'PENDING')).format(DATE_TIME_FORMAT)
        : '-'),
    },
    {
      title: '登録日',
      width: 150,
      dataIndex: 'statusLog',
      key: 'connectedDate',
      render: (record) => (_get(record, 'ACCEPTED')
        ? moment(_get(record, 'ACCEPTED')).format(DATE_TIME_FORMAT)
        : '-'),
    },
    {
      title: '商品件数',
      width: 120,
      dataIndex: ['restaurant', 'productCount'],
      key: 'productCount',
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
    },
    {
      title: '削除',
      width: 150,
      key: 'action',
      fixed: 'right',
      render: renderDisconnectButton,
    },
  ];

  const onRowClick = (record) => {
    dispatch(
      push(
        `/restaurants/detail/${record?.restaurant?.formattedObjectId}/inapp-config`
      )
    );
  };

  return (
    <>
      {showInviteRestaurantModal && (
        <InviteRestaurant
          visible
          handleCloseDialog={() => {
            setShowInviteRestaurantModal(false);
          }}
        />
      )}
      <Card className="bg-transparent" bordered={false}>
        <Space direction="vertical" className="full-w" size={16}>
          <PendingList />
          <Card
            className="highlight-card padding-card"
            bordered={false}
          >
            <Space
              className="flex items-center justify-between flex-wrap padding-16 border-bottom"
            >
              <Search
                placeholder="ID/レストラン名で検索"
                style={{ width: 220 }}
                defaultValue={keyword}
                onSearch={(val) => onFilterChange({ keyword: val })}
                className="search-input"
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowInviteRestaurantModal(true);
                }}
              >
                レストランを招待する
              </Button>
            </Space>
            <Table
              columns={columns}
              data={_get(restaurantList, 'list', [])}
              total={_get(restaurantList, 'total', 0)}
              loading={tableLoading}
              onChange={onTableChange}
              pagination={{
                current: Number(page),
                pageSize: Number(limit),
                marginRight: true,
              }}
              scroll={{ x: 900 }}
              onRowClick={onRowClick}
            />
          </Card>
        </Space>
      </Card>
    </>
  );
};

export default withRefresh('restaurantList')(RestaurantList);
