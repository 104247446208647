import Parse from 'parse';

import {
  API_APP_ID,
  API_REST_API_KEY,
  API_JAVASCRIPT_KEY,
  API_SERVER_URL,
  REACT_APP_LIVE_QUERY_SERVER_URL,
} from './config';

Parse.initialize(API_APP_ID, API_JAVASCRIPT_KEY);
Parse.serverURL = API_SERVER_URL;

const { LiveQueryClient } = Parse;
const client = new LiveQueryClient({
  applicationId: API_APP_ID,
  serverURL: REACT_APP_LIVE_QUERY_SERVER_URL,
  javascriptKey: API_REST_API_KEY
});

client.open();

export default client;
