import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push, replace } from 'connected-react-router';

import queryString from 'query-string';

const parseSearchString = (search) => queryString.parse(search.replace(/^\?/, ''));

const useTableControl = ({
  defaultOrder = {},
  filterKeys = {},
  defaultFilter = {},
} = {}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const search = useMemo(
    () => parseSearchString(location.search),
    [location.search]
  );

  const [pageControl, setPageControl] = useState({
    orderBy: _get(search, 'ob', _get(defaultOrder, 'orderBy')),
    order: _get(search, 'o', _get(defaultOrder, 'order')),
    page: _get(search, 'p', '1'),
    limit: _get(search, 'l', '10'),
  });

  useEffect(() => {
    setPageControl({
      orderBy: _get(search, 'ob', _get(defaultOrder, 'orderBy')),
      order: _get(search, 'o', _get(defaultOrder, 'order')),
      page: _get(search, 'p', '1'),
      limit: _get(search, 'l', '10'),
    });
  }, [search]);

  const [filterControl, setFilterControl] = useState(
    _reduce(
      Object.keys(filterKeys),
      (filterState, key) => {
        filterState[key] = _get(
          search,
          filterKeys[key],
          _get(defaultFilter, key)
        );
        return filterState;
      },
      {}
    )
  );

  // Handle change from antd table
  const onTableChange = ({ pagination, sorter }) => {
    const page = `${pagination?.current}`;
    const limit = `${pagination?.pageSize}`;
    const orderBy = sorter?.orderBy;
    const order = sorter?.order;
    const parsed = {
      ...search,
      p: page,
      l: limit,
      ob: orderBy,
      o: order,
    };
    dispatch(
      push(
        `${location.pathname}?${queryString.stringify(parsed, {
          skipEmptyString: true,
        })}`
      )
    );

    setPageControl({
      page,
      limit,
      orderBy,
      order,
    });
  };

  // Handle change from filter form
  const onFilterChange = (filterData) => {
    const parsed = {
      ...search,
      ..._reduce(
        Object.keys(filterData),
        (pre, curKey) => {
          pre[filterKeys[curKey]] = filterData[curKey];
          return pre;
        },
        {}
      ),
      p: 1, // Reset page 1
    };

    dispatch(
      replace(
        `${location.pathname}?${queryString.stringify(parsed, {
          skipEmptyString: true,
        })}`
      )
    );

    setFilterControl((pre) => ({
      ...pre,
      ...filterData,
    }));

    setPageControl((pre) => ({
      ...pre,
      page: '1',
    }));
  };

  return [pageControl, filterControl, onTableChange, onFilterChange];
};

export default useTableControl;
