import Constants from 'utils/constants';

const validate = (values) => {
  const errors = {};

  if (!values.oldPassword) {
    errors.oldPassword = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Constants.REGEX.PASSWORD.test(values.oldPassword)) {
    errors.oldPassword = Constants.ERROR_MESSAGE.INVALID_PASSWORD;
  }
  if (!values.newPassword) {
    errors.newPassword = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Constants.REGEX.PASSWORD.test(values.newPassword)) {
    errors.newPassword = Constants.ERROR_MESSAGE.INVALID_PASSWORD;
  }
  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = Constants.ERROR_MESSAGE.REQUIRED;
  } else if (!Constants.REGEX.PASSWORD.test(values.newPasswordConfirm)) {
    errors.newPasswordConfirm = Constants.ERROR_MESSAGE.INVALID_PASSWORD;
  }
  if (
    values.newPassword
    && values.newPasswordConfirm
    && values.newPassword !== values.newPasswordConfirm
  ) {
    errors.newPasswordConfirm = 'パスワードが一致しません。';
  }
  if (
    values.oldPassword
    && values.newPassword
    && values.oldPassword === values.newPassword
  ) {
    errors.newPassword = '新しいパスワードは現在のパスワードと違う値にしてください。';
  }

  return errors;
};

export default validate;
