/* eslint-disable react/prop-types */
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from 'antd';

import Header from './Header';
import Menu from './Menu';
import indexRoutes from './routes';
import FormActionFooter from './FormActionFooter';

import './style.less';

const { Content } = Layout;

const AuthRoutes = ({ component, path, roles }) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  if (!roles.includes(currentUser.role)) {
    return <Redirect to="/" />;
  }

  return <Route path={path} component={component} />;
};

const App = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const formActionFooter = useSelector(
    (state) => state.commonProvider.formActionFooter,
    shallowEqual
  );

  if (!currentUser.role) {
    return <Redirect to="/reg/role" />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout>
        <Menu />
        <Layout className="route-container">
          <Content>
            <Switch>
              {indexRoutes.map((route, index) => (
                <AuthRoutes
                  key={index}
                  path={route.path}
                  component={route.component}
                  roles={route.roles}
                />
              ))}
            </Switch>
          </Content>
          {!_isEmpty(formActionFooter) && <FormActionFooter />}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
