import {
  GET_BRAND_OWNER_LIST_REQUEST,
  GET_BRAND_OWNER_LIST_SUCCESS,
  GET_BRAND_OWNER_LIST_ERROR,
  GET_BRAND_OWNER_DETAIL_REQUEST,
  GET_BRAND_OWNER_DETAIL_SUCCESS,
  GET_BRAND_OWNER_DETAIL_ERROR,
  UPDATE_BRAND_OWNER_REQUEST,
  UPDATE_BRAND_OWNER_SUCCESS,
  UPDATE_BRAND_OWNER_ERROR,
  GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST,
  GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS,
  GET_ORDER_LIST_FOR_BRAND_OWNER_ERROR,
  ENABLE_BRAND_OWNER_ACCOUNT_REQUEST,
  ENABLE_BRAND_OWNER_ACCOUNT_SUCCESS,
  ENABLE_BRAND_OWNER_ACCOUNT_ERROR,
  DISABLE_BRAND_OWNER_ACCOUNT_REQUEST,
  DISABLE_BRAND_OWNER_ACCOUNT_SUCCESS,
  DISABLE_BRAND_OWNER_ACCOUNT_ERROR,
} from './constants';

export function getBrandOwnerListRequest(payload) {
  return { type: GET_BRAND_OWNER_LIST_REQUEST, payload, meta: { thunk: true } };
}

export function getBrandOwnerListSuccess(payload, meta) {
  return { type: GET_BRAND_OWNER_LIST_SUCCESS, payload, meta };
}

export function getBrandOwnerListError(payload, meta) {
  return {
    type: GET_BRAND_OWNER_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getBrandOwnerDetailRequest(payload) {
  return {
    type: GET_BRAND_OWNER_DETAIL_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getBrandOwnerDetailSuccess(payload, meta) {
  return { type: GET_BRAND_OWNER_DETAIL_SUCCESS, payload, meta };
}

export function getBrandOwnerDetailError(payload, meta) {
  return {
    type: GET_BRAND_OWNER_DETAIL_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateBrandOwnerRequest(payload) {
  return { type: UPDATE_BRAND_OWNER_REQUEST, payload, meta: { thunk: true } };
}

export function updateBrandOwnerSuccess(payload, meta) {
  return { type: UPDATE_BRAND_OWNER_SUCCESS, payload, meta };
}

export function updateBrandOwnerError(payload, meta) {
  return {
    type: UPDATE_BRAND_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getOrderListForBrandOwnerRequest(payload) {
  return {
    type: GET_ORDER_LIST_FOR_BRAND_OWNER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getOrderListForBrandOwnerSuccess(payload, meta) {
  return { type: GET_ORDER_LIST_FOR_BRAND_OWNER_SUCCESS, payload, meta };
}

export function getOrderListForBrandOwnerError(payload, meta) {
  return {
    type: GET_ORDER_LIST_FOR_BRAND_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function enableBrandOwnerAccountRequest(payload) {
  return {
    type: ENABLE_BRAND_OWNER_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function enableBrandOwnerAccountSuccess(payload, meta) {
  return { type: ENABLE_BRAND_OWNER_ACCOUNT_SUCCESS, payload, meta };
}

export function enableBrandOwnerAccountError(payload, meta) {
  return {
    type: ENABLE_BRAND_OWNER_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function disableBrandOwnerAccountRequest(payload) {
  return {
    type: DISABLE_BRAND_OWNER_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function disableBrandOwnerAccountSuccess(payload, meta) {
  return { type: DISABLE_BRAND_OWNER_ACCOUNT_SUCCESS, payload, meta };
}

export function disableBrandOwnerAccountError(payload, meta) {
  return {
    type: DISABLE_BRAND_OWNER_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}
