import _get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card, Typography, Space, Avatar
} from 'antd';
import { push } from 'connected-react-router';

import { getRestaurantListRequest } from 'providers/RestaurantProvider/actions';
import Helpers from 'utils/helpers';

import DefaultRestaurantImage from 'images/defaultRestaurant.svg';
import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';

const RestaurantList = () => {
  const restaurantList = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange] = useTableControl();
  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const onRowClick = (record) => {
    dispatch(push(`/restaurants/detail/${record.formattedObjectId}`));
  };

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = [
    {
      title: 'ID',
      width: 110,
      fixed: 'left',
      dataIndex: 'formattedObjectId',
      key: 'formattedObjectId',
    },
    {
      title: 'レストラン名',
      width: 250,
      render: (record) => (
        <div className="flex items-center full-w">
          <Avatar
            alt="name"
            src={_get(record, 'image', DefaultRestaurantImage)}
            size={32}
          />
          <Space
            direction="vertical"
            size={0}
            className="restaurant-info-wrapper"
          >
            <Typography.Text
              ellipsis={{
                tooltip: _get(record, 'name'),
              }}
            >{_get(record, 'name')}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {_get(record, 'email', '-')}
            </Typography.Text>
          </Space>
        </div>
      ),
    },
    {
      title: 'ブランド件数',
      width: 120,
      dataIndex: 'brandCount',
      key: 'brandCount',
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
    },
    {
      title: '商品件数',
      width: 120,
      dataIndex: 'menuCount',
      key: 'menuCount',
      align: 'right',
      render: (record) => Helpers.numberWithCommas(record || 0),
    },
  ];

  return (
    <Card className="bg-transparent" bordered={false}>
      <Card className="highlight-card padding-card" bordered={false}>
        <Table
          columns={columns}
          data={_get(restaurantList, 'list', [])}
          total={_get(restaurantList, 'total', 0)}
          loading={tableLoading}
          onChange={onTableChange}
          pagination={{
            current: Number(page),
            pageSize: Number(limit),
          }}
          scroll={{ x: 600 }}
          onRowClick={onRowClick}
        />
      </Card>
    </Card>
  );
};

export default RestaurantList;
