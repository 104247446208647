import _get from 'lodash/get';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  Card,
} from 'antd';
import {
  useParams,
} from 'react-router-dom';
import {
  getRestaurantListRequest,
} from 'providers/RestaurantProvider/actions';

import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';
import StatusToggle from 'containers/AdminPage/Content/Restaurants/Operator_List/StatusToggle';
import Columns from 'containers/AdminPage/Content/Restaurants/Operator_List/Columns';

const RestaurantList = () => {
  const restaurantList = useSelector(
    (state) => state.restaurantProvider.restaurantList,
    shallowEqual
  );
  const { restaurantOwnerId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const {
    page, limit
  } = pageControl;

  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getRestaurantListRequest({
          page,
          limit,
          restaurantOwnerId,
          statuses: ['ACCEPTED'],
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  });

  useEffect(() => {
    fetchData();
  }, [page, limit, restaurantOwnerId]);

  const columns = [

    { ...Columns.ID, dataIndex: ['restaurant', 'formattedObjectId'] },
    { ...Columns.info, dataIndex: 'restaurant' },
    { ...Columns.createdAt, dataIndex: ['restaurant', 'createdAt'] },
    { ...Columns.supplierCount, dataIndex: ['restaurant', 'supplierCount'] },
    { ...Columns.productCount, dataIndex: ['restaurant', 'productCount'] },
    {
      ...Columns.status,
      dataIndex: 'restaurant',
      render: (record) => (
        <StatusToggle
          rowRecord={{ ...record, callBack: fetchData }}
        />
      ),
    },
  ];

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <Table
        columns={columns}
        data={_get(restaurantList, 'list', [])}
        total={_get(restaurantList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 900 }}
      />
    </Card>
  );
};

export default RestaurantList;
