import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select, Button, Card, Empty, Typography, Space, Tooltip
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getStatementInvoiceListRequest } from 'providers/InvoiceProvider/actions';

import Helper from 'utils/helpers';
import EmptyInvoiceIcon from 'images/icon-empty-document.svg';

const { Option } = Select;

const StatementInvoiceList = ({ restaurantId }) => {
  const dispatch = useDispatch();
  const [selectedFileObject, setSelectedFileObject] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (restaurantId) {
        setIsFetching(true);
        try {
          await dispatch(getStatementInvoiceListRequest({ restaurantId }));
        } catch (error) {
          console.error(error);
        }
        setIsFetching(false);
      }
    };
    fetchData();
  }, [restaurantId]);

  const statementInvoiceList = useSelector(
    (state) => state.invoiceProvider.statementInvoiceList
  );

  const handleDownloadFile = async () => {
    setIsDownloading(true);
    try {
      await Helper.downloadFile(
        selectedFileObject.url,
        selectedFileObject.name
      );
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  };

  if (_isEmpty(statementInvoiceList)) {
    return (
      <Card
        className="highlight-card"
        bordered={false}
        title="クレジットカード / 代金引換明細書"
        loading={isFetching}
      >
        <Empty
          image={EmptyInvoiceIcon}
          imageStyle={{
            width: 160,
            height: 160,
            margin: '0 auto',
          }}
          description={(
            <Typography.Text type="secondary">
              ダウンロードできる明細書がありません。
            </Typography.Text>
          )}
        />
      </Card>
    );
  }

  return (
    <Card
      className="highlight-card"
      bordered={false}
      title="クレジットカード / 代金引換明細書"
      loading={isFetching}
    >
      <Space direction="vertical" size={16} className="full-w">
        <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          ダウンロードしたい明細書を選択してください。
        </Typography.Text>
        <Space direction="vertical" size={4} className="full-w">
          <Typography.Text>請求書</Typography.Text>
          <div className="flex">
            <Select
              style={{ flexGrow: 1, marginRight: 8, overflow: 'hidden' }}
              allowClear
              onChange={(value) => {
                setSelectedFileObject(
                  _get(
                    _find(statementInvoiceList, (o) => o.objectId === value),
                    'invoicePDF'
                  )
                );
              }}
            >
              {_map(statementInvoiceList, (i) => (
                <Option key={i.objectId} value={i.objectId}>
                  <Tooltip placement="left" title={i.invoicePDF.name}>
                    {i.invoicePDF.name}
                  </Tooltip>
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              disabled={_isEmpty(selectedFileObject)}
              loading={isDownloading}
              onClick={handleDownloadFile}
              icon={<DownloadOutlined />}
            >
              ダウンロード
            </Button>
          </div>
        </Space>
      </Space>
    </Card>
  );
};

StatementInvoiceList.propTypes = {
  restaurantId: PropTypes.string,
};

export default StatementInvoiceList;
