import _get from 'lodash/get';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import {
  Typography,
  Card,
  Descriptions,
  Row,
  Col,
  Divider,
  Spin,
  Space,
  Avatar,
} from 'antd';

import Helpers from 'utils/helpers';
import { getMenuItemDetailRequest } from 'providers/MenuProvider/actions';
import DefaultBrandImage from 'images/defaultBrand.svg';
import DefaultSupplierImage from 'images/defaultSupplier.svg';
import DefaultProductImage from 'images/defaultProduct.svg';

import PageHeader from 'components/PageHeader';
import NavigationButton from './NavigationButton';
import './style.less';

const { Item } = Descriptions;
const { Text } = Typography;

const ProductDetail = ({ match }) => {
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(true);

  const menuId = _get(match, 'params.menuId');
  const productList = useSelector(
    (state) => state.menuProvider.pagingMenuItemList,
    shallowEqual
  );
  const menuItemDetail = useSelector(
    (state) => state.menuProvider.menuItemDetail,
    shallowEqual
  );
  const masterPrice = _find(
    menuItemDetail?.prices,
    (price) => price.type === 'MASTER'
  );

  const breadcrumbItems = [
    {
      path: '/products',
      breadcrumbName: '商品',
    },
    {
      breadcrumbName: menuItemDetail?.name,
    },
  ];

  const fetchMenuItemDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getMenuItemDetailRequest({ formattedObjectId: menuId }));
    } catch (error) {
      console.log(error);
      dispatch(push('/products'));
    }
    setDataLoading(false);
  }, [dispatch, menuId]);

  useEffect(() => {
    fetchMenuItemDetail();
  }, [fetchMenuItemDetail]);

  const handleGoBack = () => {
    dispatch(goBack());
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="product-detail-page">
      <PageHeader
        title={(
          <Space direction="vertical" size={0}>
            <Text className="product-name">{_get(menuItemDetail, 'name')}</Text>
            <Text className="email">ID:{menuItemDetail?.objectId}</Text>
          </Space>
        )}
        breadcrumbRoutes={breadcrumbItems}
        onBack={handleGoBack}
        extra={NavigationButton(menuId, dispatch, productList)}
      />
      <Card className="bg-transparent" bodyStyle={{ paddingTop: 0 }}>
        <Row gutter={[16, 16]}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Card
              title="商品の追加"
              className="highlight-card"
              bordered={false}
            >
              <Descriptions title="アイテム名" column={1} bordered>
                <Item label="アイテム名">
                  <div>{menuItemDetail?.name}</div>
                  <Text>{menuItemDetail?.nameKatakana}</Text>
                </Item>
                <Item label="商品イメージ">
                  <Space style={{ height: 120 }}>
                    <Avatar
                      src={_get(
                        menuItemDetail,
                        'images[0].url',
                        DefaultProductImage
                      )}
                      size={120}
                      shape="square"
                    />
                    <Space wrap>
                      {menuItemDetail?.images?.slice(1).map((image) => (
                        <Avatar
                          key={image.objectId}
                          src={image.url}
                          size={56}
                          shape="square"
                        />
                      ))}
                    </Space>
                  </Space>
                </Item>
              </Descriptions>
              <Divider />
              <Descriptions title="単価" column={1} bordered>
                <Item label="基本税抜価格">
                  ¥{Helpers.numberWithCommas(masterPrice?.priceBeforeTax)}
                </Item>
                <Item label="税金">
                  {_get(masterPrice, 'taxRate', 0) > 0
                    ? `${_get(masterPrice, 'taxRate', 0) * 100}%`
                    : '非課税'}
                </Item>
                <Item label="単価（税込）">
                  ¥{Helpers.numberWithCommas(masterPrice?.priceAfterTax)}
                </Item>
              </Descriptions>
              <Divider />
              <Descriptions
                title="商品の納期および発注数量"
                column={1}
                bordered
              >
                <Item label="リードタイム">{menuItemDetail?.leadTime}</Item>
                <Item label="最小発注単位">
                  {menuItemDetail?.minLimitOrder}
                </Item>
                <Item label="最大発注単位">
                  {menuItemDetail?.maxLimitOrder}
                </Item>
              </Descriptions>
              <Divider />
              <Descriptions title="商品の追加情報" column={1} bordered>
                <Item label="アレルギー表記">
                  {menuItemDetail?.allergen || '-'}
                </Item>
              </Descriptions>
            </Card>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Space direction="vertical" className="full-w flex" size={16}>
              <Card
                title="ステータス"
                className="highlight-card"
                bordered={false}
              >
                <Descriptions column={1} bordered>
                  <Item label="ステータス">
                    <Space className="status-container">
                      <div
                        className={
                          menuItemDetail?.status === 'ACTIVE' && 'active'
                        }
                      >
                        <Typography.Text>
                          {menuItemDetail?.status === 'ACTIVE'
                            ? '有効'
                            : '無効'}
                        </Typography.Text>
                      </div>
                    </Space>
                  </Item>
                </Descriptions>
              </Card>
              <Card
                title="ブランド"
                className="highlight-card"
                bordered={false}
              >
                <Descriptions column={1} bordered>
                  <Item label="ブランド">
                    <Space className="flex items-center">
                      <Avatar
                        alt="brand"
                        src={_get(
                          menuItemDetail,
                          'brand.image',
                          DefaultBrandImage
                        )}
                        size={32}
                      />
                      <Typography.Text>
                        {_get(menuItemDetail, 'brand.name')}
                      </Typography.Text>
                    </Space>
                  </Item>
                  <Item label="ブランドフィー">
                    ¥{Helpers.numberWithCommas(menuItemDetail?.brandFee)}
                  </Item>
                </Descriptions>
              </Card>
              <Card title="販売者" className="highlight-card" bordered={false}>
                <Descriptions column={1} bordered>
                  <Item label="販売者">
                    <Space className="flex items-center full-w">
                      <Avatar
                        alt="supplier"
                        src={_get(
                          menuItemDetail,
                          'supplier.image',
                          DefaultSupplierImage
                        )}
                        size={32}
                      />
                      <Space
                        direction="vertical"
                        size={0}
                        style={{ overflow: 'hidden' }}
                      >
                        <Typography.Text ellipsis>
                          {_get(menuItemDetail, 'supplier.name')}
                        </Typography.Text>
                        <Typography.Text
                          type="secondary"
                          style={{ fontSize: 12 }}
                        >
                          {_get(menuItemDetail, 'supplier.email', '-')}
                        </Typography.Text>
                      </Space>
                    </Space>
                  </Item>
                </Descriptions>
              </Card>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

ProductDetail.propTypes = {
  match: PropTypes.any,
};

export default ProductDetail;
