import produce from 'immer';

import {
  GET_INVOICE_LIST_REQUEST,
  GET_INVOICE_LIST_SUCCESS,
  GET_STATEMENT_INVOICE_LIST_REQUEST,
  GET_STATEMENT_INVOICE_LIST_SUCCESS,
} from './constants';

const initialState = {
  invoiceList: [],
  statementInvoiceList: [],
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_LIST_REQUEST:
      state.invoiceList = [];
      break;

    case GET_INVOICE_LIST_SUCCESS:
      state.invoiceList = action.payload;
      break;

    case GET_STATEMENT_INVOICE_LIST_REQUEST:
      state.statementInvoiceList = [];
      break;

    case GET_STATEMENT_INVOICE_LIST_SUCCESS:
      state.statementInvoiceList = action.payload;
      break;

    default:
  }
  return state;
});

export default reducer;
