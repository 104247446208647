import _get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import Parse from 'parse';
import { push } from 'connected-react-router';
import { eventChannel } from 'redux-saga';
import {
  put, call, takeEvery, select, race, take
} from 'redux-saga/effects';
import { Button, notification, Typography } from 'antd';

import liveQueryClient from 'utils/liveQueryClient';
import { USER_ROLE } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import { doRefresh } from 'providers/CommonProvider/actions';
import { getUnreadNotificationCountRequest } from '../NotificationProvider/actions';
import {
  countUnreadConversationRequest,
  updateConversationWhenReceiveLiveMessage,
} from '../ConversationProvider/actions';

import { addLiveMessageToOrderMessages } from '../OrderProvider/actions';
import {
  LISTEN_ORDER_MESSAGE_REQUEST,
  LISTEN_WEB_NOTIFICATION_REQUEST,
  LISTEN_ORDER_MESSAGE_REMOVE,
  LISTEN_WEB_NOTIFICATION_REMOVE,
} from './constants';

const createChannel = (query) => eventChannel((emit) => {
  const errorHandler = (errorEvent) => {
    emit(new Error(errorEvent.reason));
  };

  const subscription = liveQueryClient.subscribe(query);

  subscription.on('create', (object) => {
    emit(object);
  });

  subscription.on('error', (error) => {
    errorHandler(error);
  });

  const unsubscribe = () => {
    liveQueryClient.unsubscribe(subscription);
  };

  return unsubscribe;
});

const createWebNotificationChannel = (supplierId, restaurantOwnerId) => {
  const webNotificationQuery = new Parse.Query('WebNotification');

  if (supplierId) {
    const supplier = new Parse.Object('Supplier');
    supplier.id = supplierId;
    webNotificationQuery.equalTo('toSupplier', supplier);
  } else if (restaurantOwnerId) {
    const restaurantOwner = new Parse.Object('RestaurantOwner');
    restaurantOwner.id = restaurantOwnerId;
    webNotificationQuery.equalTo('toRestaurantOwner', restaurantOwner);
  }

  webNotificationQuery.notEqualTo('isRead', true);
  webNotificationQuery.notEqualTo('isDeleted', true);
  return createChannel(webNotificationQuery);
};

const createOrderMessageChannel = (supplierId) => {
  const orderMessageQuery = new Parse.Query('OrderMessage');
  orderMessageQuery.equalTo('orderInfo.supplierId', supplierId);

  return createChannel(orderMessageQuery);
};

function* orderMessage() {
  const currentUser = yield select((state) => state.authProvider.currentUser);

  if (currentUser.role !== USER_ROLE.SUPPLIER) {
    return;
  }
  const orderMessageChannel = yield call(
    createOrderMessageChannel,
    _get(currentUser, 'supplier.objectId')
  );
  try {
    while (true) {
      const { channelData, cancel } = yield race({
        channelData: take(orderMessageChannel),
        cancel: take(LISTEN_ORDER_MESSAGE_REMOVE),
      });
      if (cancel) {
        orderMessageChannel.close();
      } else {
        const messageJSON = channelData.toJSON();
        const orderQuery = new Parse.Query('Order').select(
          'restaurant.name',
          'restaurant.image.thumbMedium',
          'restaurant.image.file'
        );

        const orderMessageQuery = new Parse.Query('OrderMessage').select(
          'supplier.name',
          'supplier.image.thumbMedium',
          'supplier.image.file'
        );

        const orderParse = yield orderQuery.get(messageJSON.order.objectId);
        const orderJSON = orderParse.toJSON();

        const orderMessageParse = yield orderMessageQuery.get(
          messageJSON.objectId
        );
        const orderMessageJSON = orderMessageParse.toJSON();

        const conservationItem = {
          objectId: channelData.id,
          orderId: orderParse.id,
          content: messageJSON.content,
          isRead: true,
          createdAt:
            channelData.get('createdAt')
            && moment(channelData.get('createdAt')).toISOString(),
          imageUrl: _get(
            orderJSON,
            'restaurant.image.thumbMedium.url',
            _get(orderJSON, 'restaurant.image.file.url')
          ),
        };

        const newOrderMessageItem = {
          objectId: channelData.id,
          orderId: orderParse.id,
          content: messageJSON.content,
          isRead: true,
          supplier: {
            name: _get(orderMessageJSON, 'supplier.name'),
            image: _get(
              orderMessageJSON,
              'supplier.image.thumbMedium.url',
              _get(orderJSON, 'supplier.image.file.url')
            ),
          },
          createdAt:
            orderMessageParse.get('createdAt')
            && moment(orderMessageParse.get('createdAt')).toISOString(),
        };

        if (messageJSON.restaurant) {
          // Sent from restaurant
          conservationItem.isRead = false;
          yield put(countUnreadConversationRequest());

          newOrderMessageItem.isRead = false;
          newOrderMessageItem.restaurant = {
            name: _get(orderJSON, 'restaurant.name'),
            image: _get(
              orderJSON,
              'restaurant.image.thumbMedium.url',
              _get(orderJSON, 'restaurant.image.file.url')
            ),
          };

          const toastMessageKey = `toastMessage_orderMessage_${new Date().valueOf()}`;
          toastMessage.info({
            key: toastMessageKey,
            message: _get(orderJSON, 'restaurant.name'),
            description: (
              <>
                <Typography.Paragraph
                  ellipsis={{ rows: 3 }}
                  style={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {messageJSON.content}
                </Typography.Paragraph>
                <Button
                  type="link"
                  style={{
                    padding: 0,
                  }}
                  onClick={() => {
                    window.store.dispatch(doRefresh({ target: 'orderDetail' }));
                    window.store.dispatch(
                      push(
                        `/orders/detail/${messageJSON.order.objectId}#bottom-scr`
                      )
                    );
                    notification.close(toastMessageKey);
                  }}
                >
                  詳細を参照する
                </Button>
              </>
            ),
          });
        }

        yield put(updateConversationWhenReceiveLiveMessage(conservationItem));
        yield put(addLiveMessageToOrderMessages(newOrderMessageItem));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* webNotification() {
  const currentUser = yield select((state) => state.authProvider.currentUser);

  if (
    ![USER_ROLE.SUPPLIER, USER_ROLE.RESTAURANT_OWNER].includes(currentUser.role)
  ) {
    return;
  }

  const webNotificationChannel = yield call(
    createWebNotificationChannel,
    _get(currentUser, 'supplier.objectId'),
    _get(currentUser, 'restaurantOwner.objectId')
  );

  try {
    while (true) {
      const { channelData, cancel } = yield race({
        channelData: take(webNotificationChannel),
        cancel: take(LISTEN_WEB_NOTIFICATION_REMOVE),
      });
      if (cancel) {
        webNotificationChannel.close();
      } else {
        yield put(getUnreadNotificationCountRequest());

        const toastMessageKey = `toastMessage_webNotification_${new Date().valueOf()}`;
        toastMessage.info({
          key: toastMessageKey,
          description: (
            <>
              <Typography.Paragraph
                ellipsis={{ rows: 3 }}
                style={{
                  whiteSpace: 'break-spaces',
                  marginBottom: 0,
                }}
              >
                {channelData.get('message')}
              </Typography.Paragraph>
              {channelData.get('linkTo') && (
                <Button
                  type="link"
                  style={{
                    padding: 0,
                    marginTop: '1em',
                  }}
                  onClick={() => {
                    window.store.dispatch(doRefresh({ target: 'orderDetail' }));
                    window.store.dispatch(push(channelData.get('linkTo')));
                    notification.close(toastMessageKey);
                  }}
                >
                  詳細を参照する
                </Button>
              )}
            </>
          ),
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* saga() {
  yield takeEvery(LISTEN_ORDER_MESSAGE_REQUEST, orderMessage);
  yield takeEvery(LISTEN_WEB_NOTIFICATION_REQUEST, webNotification);
}
