import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Switch, Modal, Typography
} from 'antd';

import { doRefresh } from 'providers/CommonProvider/actions';
import { updateRestaurantRequest } from 'providers/RestaurantProvider/actions';
import toastMessage from 'utils/toastMessage';

const { confirm } = Modal;

const showConfirm = (message, content, actionFn, okText) => {
  confirm({
    title: message,
    content,
    okText,
    okType: 'primary',
    cancelText: 'キャンセル',
    onOk: actionFn,
    centered: true,
  });
};

const MFSetting = ({ status, restaurantDetail }) => {
  const dispatch = useDispatch();
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const handleChangeStatus = async (val) => {
    setChangeStatusLoading(true);
    try {
      await dispatch(
        updateRestaurantRequest({
          isStopMFKessai: val,
          restaurantId: restaurantDetail?.objectId,
        })
      );
      toastMessage.success({
        message: '保存しました。',
        description: '変更した内容を保存しました。',
      });
      setChangeStatusLoading(false);
      dispatch(doRefresh({ target: 'restaurantDetail' }));
    } catch (error) {
      console.error(error);
    }
    setChangeStatusLoading(false);
  };

  return (
    <>
      <div className="status-container">
        <span
          className={`${status ? 'dot' : 'dot dot--inactive'}`}
        />
        <Typography.Text>{status ? '有効' : '無効'}</Typography.Text>
        <Switch
          disabled={changeStatusLoading}
          checked={status}
          size="small"
          onClick={() => {
            if (status) {
              showConfirm(
                'マネーフォワード決済の利用を無効にしますか？',
                'レストランがシコメル販売者の商品を購入する際に請求書払いが利用できなくなります。',
                () => {
                  handleChangeStatus(true);
                },
                '無効にする'
              );
            } else {
              showConfirm(
                'マネーフォワード決済の利用を有効にしますか？',
                'レストランがシコメル販売者の商品を購入する際に請求書払いが可能となります。',
                () => {
                  handleChangeStatus(false);
                },
                '有効にする'
              );
            }
          }}
        />
      </div>
      <div>
        <Typography.Text type="secondary">
          {status
            ? 'このレストランはシコメル販売者の商品を購入する際に請求書払いが利用可能です。'
            : 'このレストランはシコメル販売者の商品を購入する際にクレジット決済のみが利用可能です。'}
        </Typography.Text>
      </div>
    </>
  );
};

MFSetting.propTypes = {
  status: PropTypes.bool.isRequired,
  restaurantDetail: PropTypes.object.isRequired,
};

export default MFSetting;
