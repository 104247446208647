import produce from 'immer';

import {
  GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST,
  GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS,
  GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS,
  GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR,
} from './constants';

const initialState = {
  dataLoading: false,
  loading: false,
  error: false,
  restaurantAnnouncementList: [],
  restaurantAnnouncementTotal: 0,
  restaurantAnnouncementDetail: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_RESTAURANT_ANNOUNCEMENT_REQUEST:
      state.dataLoading = true;
      state.error = false;
      break;

    case GET_LIST_RESTAURANT_ANNOUNCEMENT_SUCCESS:
      state.dataLoading = false;
      state.error = false;
      state.restaurantAnnouncementList = action.data.list;
      state.restaurantAnnouncementTotal = action.data.total;
      break;

    case GET_LIST_RESTAURANT_ANNOUNCEMENT_ERROR:
      state.dataLoading = false;
      state.error = action.error;
      state.restaurantAnnouncementList = [];
      break;

    case GET_RESTAURANT_ANNOUNCEMENT_DETAIL_REQUEST:
      state.loading = true;
      state.error = false;
      state.restaurantAnnouncementDetail = {};
      break;

    case GET_RESTAURANT_ANNOUNCEMENT_DETAIL_SUCCESS:
      state.loading = false;
      state.restaurantAnnouncementDetail = action.data;
      break;

    case GET_RESTAURANT_ANNOUNCEMENT_DETAIL_ERROR:
      state.loading = false;
      state.error = action.error;
      break;

    default:
  }
  return state;
});

export default reducer;
