import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { replace, goBack } from 'connected-react-router';
import { isDirty } from 'redux-form';
import {
  Typography, Tabs, Modal, Spin
} from 'antd';

import { getBrandDetailRequest } from 'providers/BrandProvider/actions';

import PageHeader from 'components/PageHeader';
import DefaultBrandImage from 'images/defaultBrand.svg';
import routes from './routes';
import NavigationButton from './NavigationButton';

const { TabPane } = Tabs;
const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const Detail = ({ match, location }) => {
  const dispatch = useDispatch();
  const [dataLoading, setDataLoading] = useState(true);
  const brandDetail = useSelector(
    (state) => state.brandProvider.brandDetail,
    shallowEqual
  );
  const brandList = useSelector(
    (state) => state.brandProvider.brandList,
    shallowEqual
  );
  const dirty = useSelector((state) => isDirty('brandUpdateForm')(state));

  const breadcrumbItems = [
    {
      path: '/brands',
      breadcrumbName: 'ブランド ',
    },
    {
      breadcrumbName: _get(brandDetail, 'name'),
    },
  ];

  const objectId = _get(match, 'params.brandId');
  const fetchBrandDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getBrandDetailRequest({ objectId }));
    } catch (error) {
      console.log(error);
    }
    setDataLoading(false);
  }, [objectId]);

  useEffect(() => {
    fetchBrandDetail();
  }, [fetchBrandDetail]);

  const selectedKeys = location.pathname.split('/')[4];

  const onTabClick = (key) => {
    const pushPath = `/brands/detail/${objectId}/${key}`;
    dispatch(replace(pushPath));
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div id="brand-detail">
      <PageHeader
        title={(
          <Typography.Text strong style={{ fontSize: '20px' }}>
            {_get(brandDetail, 'name')}
          </Typography.Text>
        )}
        avatar={{ src: _get(brandDetail, 'image', DefaultBrandImage) }}
        breadcrumbRoutes={breadcrumbItems}
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
        extra={NavigationButton(objectId, dispatch, brandList)}
        footer={(
          <Tabs
            onTabClick={
              dirty
                ? (key) => showConfirm('中断してよろしいですか？', () => onTabClick(key)
                )
                : onTabClick
            }
            activeKey={selectedKeys}
          >
            <TabPane tab="詳細" key="in-app" />
            <TabPane tab="ブランド商品" key="product-in-brand" />
          </Tabs>
        )}
      />
      <Switch>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  );
};

Detail.propTypes = {
  match: PropTypes.any,
  pathname: PropTypes.any,
  location: PropTypes.any,
};

export default Detail;
