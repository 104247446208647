import React from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { push } from 'connected-react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Descriptions,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Button,
  List,
} from 'antd';

import { DATE_TIME_FORMAT } from 'utils/constants';

import ProfileInformation from 'containers/AdminPage/Content/Restaurants/Operator_Detail/RestaurantInfo//ProfileInformation';

import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';

const { Text } = Typography;
const { Item } = Descriptions;

const renderStatus = (isVerified) => {
  if (isVerified) {
    return (
      <Space size={4}>
        <img alt="verified-icon" src={VerifiedImage} />
        <Typography.Text type="success" style={{ fontSize: 12 }}>
          確認済
        </Typography.Text>
      </Space>
    );
  }

  return (
    <Space size={4}>
      <img alt="warning-icon" src={WarningImage} />
      <Typography.Text type="warning" style={{ fontSize: 12 }}>
        確認待ち
      </Typography.Text>
    </Space>
  );
};

const renderDate = (date, format = DATE_TIME_FORMAT) => (date ? moment(date).format(format) : '');

const renderItem = (item) => (
  <List.Item>
    <List.Item.Meta
      title={item.email}
      description={renderStatus(item.status === 'ACCEPTED')}
    />
  </List.Item>
);

const RestaurantInfo = () => {
  const dispatch = useDispatch();

  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );

  const { updatedAt, createdAt, email } = restaurantDetail;

  return (
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <ProfileInformation restaurantDetail={restaurantDetail} />
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <Card className="highlight-card" title="アカウント" bordered={false}>
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="アカウント">
                <div>{email}</div>
                {renderStatus(restaurantDetail?.user?.emailVerified)}
              </Item>
            </Descriptions>
          </Card>
          <Card title="販売者" className="highlight-card" bordered={false}>
            <Descriptions column={1} bordered>
              <Item label="販売者件数">
                <Space className="flex justify-between">
                  <Text>{restaurantDetail?.supplierCount} 販売者</Text>
                  <Button
                    type="ghost"
                    onClick={() => dispatch(
                      push(
                        `/restaurants/detail/${restaurantDetail?.formattedObjectId}/supplier-list`
                      )
                    )}
                  >
                    詳細
                  </Button>
                </Space>
              </Item>
            </Descriptions>
          </Card>
          <Card title="請求書送付先を追加" className="highlight-card" bordered={false}>
            <Text>下記メールアドレスにも月次請求書が送付されます</Text>
            <List
              itemLayout="horizontal"
              dataSource={_get(
                restaurantDetail,
                'emailInvitations.INVOICE_FORWARD',
                []
              )}
              renderItem={renderItem}
            />
          </Card>
          <Card className="highlight-card" title="変更履歴" bordered={false}>
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Descriptions.Item label="更新日">
                {renderDate(updatedAt)}
              </Descriptions.Item>
              <Descriptions.Item label="作成日">
                {renderDate(createdAt)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

export default RestaurantInfo;
