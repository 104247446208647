import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import axios from 'axios';
import {
  call, put, takeLeading, takeLatest
} from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  createBrandSuccess,
  createBrandError,
  getBrandListSuccess,
  getBrandListError,
  getBrandDetailSuccess,
  getBrandDetailError,
  updateBrandSuccess,
  updateBrandError,
  registerBrandOwnerSuccess,
  registerBrandOwnerError,
  assignBrandOwnerToBrandSuccess,
  assignBrandOwnerToBrandError,
  removeBrandOwnerFromBrandSuccess,
  removeBrandOwnerFromBrandError,
  getBrandListOfRestaurantSuccess,
  getBrandListOfRestaurantError,
} from './actions';
import {
  CREATE_BRAND_REQUEST,
  GET_BRAND_LIST_REQUEST,
  GET_BRAND_DETAIL_REQUEST,
  UPDATE_BRAND_REQUEST,
  REGISTER_BRAND_OWNER_REQUEST,
  ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST,
  REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST,
  GET_BRAND_LIST_OF_RESTAURANT_REQUEST,
} from './constants';

export function* handleCreateBrand(action) {
  const { meta, payload } = action;
  let fileId;

  try {
    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
        {
          fileName: _get(image, 'originFileObj.name'),
          fileType: _get(image, 'originFileObj.type'),
          type: 'AVATAR',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
        headers: {
          'Content-Type': _get(image, 'originFileObj.type'),
        },
      })
      );
    }
    const params = _pick(payload, [
      'name',
      'nameKana',
      'email',
      'memo',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'assignedSuppliers',
      'assignBrandOwnerIds',
      'ccEmails',
      'isVisibleToRestaurant',
    ]);

    params.image = fileId;
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createBrand`,
      params
    );

    yield put(createBrandSuccess(result, meta));
    const brandName = payload?.name;
    toastMessage.success({
      message: '成功',
      description: `ブランド${brandName} を作成しました`,
    });
  } catch (error) {
    yield put(createBrandError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetBrandList(action) {
  const { payload, meta } = action;

  const params = _pick(payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'supplierId',
    'brandOwnerId',
  ]);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBrandList`,
      params
    );
    yield put(getBrandListSuccess(result, meta));
  } catch (error) {
    yield put(getBrandListError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetBrandDetail(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBrandDetail`,
      payload
    );
    yield put(getBrandDetailSuccess(result, meta));
  } catch (error) {
    yield put(getBrandDetailError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleUpdateBrand(action) {
  const { meta, payload } = action;
  let fileId;

  try {
    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];
      if (!_isEmpty(image.originFileObj)) {
        const { result: fileUploadResult } = yield call(
          request,
          `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
          {
            fileName: _get(image, 'originFileObj.name'),
            fileType: _get(image, 'originFileObj.type'),
            type: 'AVATAR',
          }
        );

        fileId = _get(fileUploadResult, 'objectId');

        yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
          headers: {
            'Content-Type': _get(image, 'originFileObj.type'),
          },
        })
        );
      }
    }
    const params = _pick(payload, [
      'name',
      'nameKana',
      'email',
      'memo',
      'managerName',
      'managerNameKana',
      'emergencyPhone',
      'isVisibleToRestaurant',
    ]);

    params.image = fileId || (_isArray(payload.image) && _isEmpty(payload.image) ? '' : undefined);
    params.brandId = _get(payload, 'objectId');

    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateBrand`,
      params
    );
    yield put(updateBrandSuccess(response.result, meta));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。',
    });
  } catch (error) {
    yield put(updateBrandError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleRegisterBrandOwner(action) {
  const { payload, meta } = action;
  let fileId;

  try {
    if (!_isEmpty(payload.image)) {
      const image = payload.image[0];

      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadImageUrl`,
        {
          fileName: _get(image, 'originFileObj.name'),
          fileType: _get(image, 'originFileObj.type'),
          type: 'AVATAR',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, image.originFileObj, {
        headers: {
          'Content-Type': _get(image, 'originFileObj.type'),
        },
      })
      );
    }

    const params = _pick(payload, ['name', 'nameKana', 'phone']);

    params.image = fileId;

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}registerBrandOwner`,
      params
    );
    yield put(registerBrandOwnerSuccess(result, meta));
  } catch (error) {
    yield put(registerBrandOwnerError(error, meta));
  }
}

export function* handleAssignBrandOwnerToBrand(action) {
  const { meta, payload } = action;

  try {
    const params = _pick(payload, ['brandOwnerIds', 'brandId']);

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}assignBrandOwnersToBrand`,
      params
    );

    yield put(assignBrandOwnerToBrandSuccess(result, meta));
  } catch (error) {
    yield put(assignBrandOwnerToBrandError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleRemoveBrandOwnerFromBrand(action) {
  const { payload, meta } = action;
  console.log('payload', payload);

  const params = _pick(payload, ['brandOwnerIds', 'brandId']);
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}removeBrandOwnersFromBrand`,
      params
    );
    yield put(removeBrandOwnerFromBrandSuccess(result, meta));
    toastMessage.success({ description: '削除しました。' });
  } catch (error) {
    yield put(removeBrandOwnerFromBrandError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetBrandListOfRestaurant(action) {
  const { payload, meta } = action;

  const params = _pick(payload, [
    'page',
    'limit',
    'orderBy',
    'order',
    'restaurantId',
  ]);

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getBrandListOfRestaurant`,
      params
    );
    yield put(getBrandListOfRestaurantSuccess(result, meta));
  } catch (error) {
    yield put(getBrandListOfRestaurantError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(CREATE_BRAND_REQUEST, handleCreateBrand);
  yield takeLatest(GET_BRAND_LIST_REQUEST, handleGetBrandList);
  yield takeLatest(GET_BRAND_DETAIL_REQUEST, handleGetBrandDetail);
  yield takeLeading(UPDATE_BRAND_REQUEST, handleUpdateBrand);
  yield takeLatest(REGISTER_BRAND_OWNER_REQUEST, handleRegisterBrandOwner);
  yield takeLeading(ASSIGN_BRAND_OWNER_TO_BRAND_REQUEST, handleAssignBrandOwnerToBrand);
  yield takeLeading(REMOVE_BRAND_OWNER_FROM_BRAND_REQUEST, handleRemoveBrandOwnerFromBrand);
  yield takeLatest(GET_BRAND_LIST_OF_RESTAURANT_REQUEST, handleGetBrandListOfRestaurant);
}
