import { call, put, takeLeading } from 'redux-saga/effects';
import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  getRestaurantDiscountPointHistorySuccess,
  getRestaurantDiscountPointHistoryError,
  getRestaurantPointInfoSuccess,
  getRestaurantPointInfoError,
} from './actions';
import {
  GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
  GET_RESTAURANT_POINT_INFO_REQUEST,
} from './constants';

export function* handleGetRestaurantDiscountPointHistory(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantDiscountPointHistory`,
      payload
    );
    yield put(getRestaurantDiscountPointHistorySuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantDiscountPointHistoryError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleGetRestaurantPointInfo(action) {
  const { payload, meta } = action;

  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantPointInfo`,
      payload
    );
    yield put(getRestaurantPointInfoSuccess(result, meta));
  } catch (error) {
    yield put(getRestaurantPointInfoError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeLeading(
    GET_RESTAURANT_DISCOUNT_POINT_HISTORY_REQUEST,
    handleGetRestaurantDiscountPointHistory
  );
  yield takeLeading(
    GET_RESTAURANT_POINT_INFO_REQUEST,
    handleGetRestaurantPointInfo
  );
}
