import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import {
  Card
} from 'antd';

import { getRankingListRequest, reorderRankingListRequest } from 'providers/RankingProvider/actions';

import Columns from './Columns';
import './style.less';
import Sortable from '../Sortable';

const RankingList = () => {
  const dispatch = useDispatch();

  const { rankingList, isLoading } = useSelector(
    (state) => state.rankingProvider,
    shallowEqual
  );

  const getRankingList = useCallback(() => {
    dispatch(getRankingListRequest());
  }, [dispatch]);

  useEffect(() => {
    getRankingList();
  }, [getRankingList]);

  const columns = [
    Columns.sort,
    Columns.ID,
    Columns.name,
    Columns.numberOfProduct,
    Columns.memo,
    Columns.updateAt,
    Columns.specifyTo,
    Columns.isVisibleOnHome,
    Columns.status,
  ];

  return (
    <Card
      className="bg-transparent"
      bordered={false}
    >
      <Card
        className="highlight-card ranking-list-page"
        bordered={false}
        title="ランキングリスト"
        headStyle={{ padding: '0 16px' }}
      >
        <Sortable
          tableLoading={isLoading}
          rankingList={rankingList}
          columns={columns}
          reorderProductRanking={reorderRankingListRequest}
          scroll={{ x: 1700 }}
        />
      </Card>
    </Card>
  );
};

export default RankingList;
