import { call, put, takeEvery } from 'redux-saga/effects';

import request from 'utils/request';
import { CLOUD_FUNCTION_PREFIX } from 'utils/constants';
import { setRestaurantRequestCount } from 'providers/AuthProvider/actions';
import toastMessage from 'utils/toastMessage';
import { getPendingListSuccess, getPendingListError } from './actions';
import { GET_PENDING_LIST_REQUEST } from './constants';

export function* handleGetPendingList(action) {
  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getRestaurantListForSupplier`,
      { ...action.payload, status: 'PENDING' }
    );

    yield put(getPendingListSuccess(response.result, action.meta));
    yield put(setRestaurantRequestCount(response.result.total));
  } catch (error) {
    yield put(getPendingListError(error, action.meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export default function* saga() {
  yield takeEvery(GET_PENDING_LIST_REQUEST, handleGetPendingList);
}
