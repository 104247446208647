import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Input } from 'antd';

import { getBrandOwnerListRequest } from 'providers/BrandOwnerProvider/actions';

import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';

import Columns from './Columns';
import StatusSwitch from '../StatusSwitch';

import './style.less';

const { Search } = Input;

const BrandOwnerList = () => {
  const brandOwnerList = useSelector(
    (state) => state.brandOwnerProvider.brandOwnerList,
    shallowEqual
  );

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
    },
  });

  const {
    page, limit, orderBy, order
  } = pageControl;
  const { keyword } = filterControl;

  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getBrandOwnerListRequest({
          page,
          limit,
          orderBy,
          order,
          keyword,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, limit, orderBy, order, keyword]);

  const columns = [
    Columns.ID,
    Columns.brandOwnerName,
    Columns.memo,
    Columns.numberOfBrand,
    {
      ...Columns.status,
      render: (record, rowRecord) => (
        <StatusSwitch
          status={record}
          rowRecord={{ ...rowRecord, callBack: fetchData }}
        />
      ),
    },
  ];

  return (
    <Card
      id="brand-owner-list"
      className="highlight-card padding-card"
      bordered={false}
    >
      <div className="border-bottom padding-16">
        <Search
          placeholder="名称/メールアドレスから検索"
          defaultValue={keyword}
          onSearch={(val) => onFilterChange({ keyword: val })}
          style={{ width: 244 }}
          className="search-input"
        />
      </div>
      <Table
        columns={columns}
        onRowClick={(record) => {
          dispatch(
            push(`/brands/brand-owners/detail/${record.objectId}/in-app`)
          );
        }}
        data={_get(brandOwnerList, 'list', [])}
        total={_get(brandOwnerList, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 806 }}
      />
    </Card>
  );
};

export default BrandOwnerList;
