import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  Descriptions,
  Button,
  Card,
  Space,
  Typography,
  Spin,
  Alert,
} from 'antd';
import { WarningFilled } from '@ant-design/icons';

import {
  changeEmailRequest,
  getInvitationEmailRequest,
  removeInvitationEmailRequest,
  resendInvitationEmailRequest,
} from 'providers/SupplierProvider/actions';
import { resendVerifyEmailRequest } from 'providers/AuthProvider/actions';

import PageHeader from 'components/PageHeader';
import VerifiedImage from 'images/icon-verified.svg';
import WarningImage from 'images/icon-warning.svg';
import ChangeEmailForm from './ChangeEmailForm';

const { Item } = Descriptions;

const invitationEmailType = {
  PRIMARY_EMAIL_CHANGE: 'PRIMARY_EMAIL_CHANGE',
};

const breadcrumbItems = [
  {
    path: '/settings',
    breadcrumbName: '各種設定',
  },
  {
    breadcrumbName: 'メールアドレスの変更',
  },
];

const ChangeEmail = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const invitationEmail = useSelector(
    (state) => state.supplierProvider.invitationEmail,
    shallowEqual
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchInvitationEmail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(getInvitationEmailRequest());
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  }, []);

  useEffect(() => {
    fetchInvitationEmail();
  }, [fetchInvitationEmail]);

  const handleSubmit = async (values) => {
    setActionLoading(true);
    try {
      await dispatch(changeEmailRequest(values));
      fetchInvitationEmail();
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const pendingEmailChange = _get(
    invitationEmail,
    invitationEmailType.PRIMARY_EMAIL_CHANGE,
    []
  )[0];

  const removeInvitationEmail = async () => {
    setActionLoading(true);
    try {
      await dispatch(
        removeInvitationEmailRequest({
          objectId: _get(pendingEmailChange, 'objectId'),
        })
      );
      fetchInvitationEmail();
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const resendInvitationEmail = async () => {
    setActionLoading(true);
    try {
      dispatch(
        resendInvitationEmailRequest({
          objectId: _get(pendingEmailChange, 'objectId'),
        })
      );
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const handleResendVerifyEmail = async () => {
    setActionLoading(true);
    try {
      await dispatch(resendVerifyEmailRequest());
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const renderEmail = () => (
    <div className="flex direction-column" style={{ lineHeight: 'normal' }}>
      <Typography.Text strong>{_get(currentUser, 'email')}</Typography.Text>
      {_get(currentUser, 'emailVerified') === true ? (
        <Space size={4}>
          <img alt="verified-icon" src={VerifiedImage} />
          <Typography.Text type="success" style={{ fontSize: 12 }}>
            確認済
          </Typography.Text>
        </Space>
      ) : (
        <Space size={4}>
          <img alt="warning-icon" src={WarningImage} />
          <Typography.Text type="warning" style={{ fontSize: 12 }}>
            確認待ち
          </Typography.Text>
        </Space>
      )}
    </div>
  );

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={breadcrumbItems[breadcrumbItems.length - 1].breadcrumbName}
        onBack={() => dispatch(goBack())}
      />
      <Card className="bg-transparent" bodyStyle={{ paddingTop: 0 }} bordered={false}>
        {!pendingEmailChange ? (
          <Space direction="vertical" size={16} className="full-w">
            {!currentUser.emailVerified && (
              <Alert
                type="warning"
                showIcon
                message={(
                  <Space>
                    <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                      <Typography.Text strong>
                        {currentUser.email}
                      </Typography.Text>{' '}
                      宛に認証メールをお送りしました。認証していただき、登録を完了してください。
                    </Typography.Text>
                    <Button
                      type="link"
                      disabled={actionLoading}
                      onClick={handleResendVerifyEmail}
                    >
                      再送する
                    </Button>
                  </Space>
                )}
                className="highlight-card"
                style={{ backgroundColor: '#fff1b8' }}
              />
            )}
            <Card className="highlight-card max-width-644" bordered={false}>
              <ChangeEmailForm
                onSubmit={handleSubmit}
                actionLoading={actionLoading}
              >
                {renderEmail()}
              </ChangeEmailForm>
            </Card>
          </Space>
        ) : (
          <Card className="highlight-card max-width-644" bordered={false}>
            <Descriptions
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="メールアドレス">
                <Space direction="vertical">
                  {renderEmail()}
                  <Card
                    style={{ background: '#fff1b8' }}
                    className="highlight-card max-width-644 full-w"
                  >
                    <Space size={16} direction="vertical" className="full-w">
                      <Space align="start" size={16} className="full-w">
                        <WarningFilled
                          style={{ color: '#edb317', fontSize: 22 }}
                          size={22}
                        />
                        <div>
                          <Typography.Text strong>
                            新しいメールアドレスを認証してください
                          </Typography.Text>
                          <br />
                          <Typography>
                            <Typography.Text strong>
                              {_get(pendingEmailChange, 'email')}
                            </Typography.Text>{' '}
                            に認証依頼をお送りしています。メールに記載されたボタンをタップして認証してください。認証後、メールアドレスの変更が完了します。
                          </Typography>
                        </div>
                      </Space>
                      <Space className="flex justify-end" size={8}>
                        <Button
                          type="default"
                          disabled={actionLoading}
                          onClick={removeInvitationEmail}
                        >
                          キャンセル
                        </Button>
                        <Button
                          type="primary"
                          disabled={actionLoading}
                          onClick={resendInvitationEmail}
                          loading={actionLoading}
                        >
                          再送する
                        </Button>
                      </Space>
                    </Space>
                  </Card>
                </Space>
              </Item>
            </Descriptions>
          </Card>
        )}
      </Card>
    </>
  );
};

export default ChangeEmail;
