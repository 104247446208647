export const GET_RESTAURANT_MENU_ITEM_REQUEST = 'RestaurantProvider/GET_RESTAURANT_MENU_ITEM_REQUEST';
export const GET_RESTAURANT_MENU_ITEM_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_MENU_ITEM_SUCCESS';
export const GET_RESTAURANT_MENU_ITEM_ERROR = 'RestaurantProvider/GET_RESTAURANT_MENU_ITEM_ERROR';

export const REMOVE_RESTAURANT_MENU_ITEM_REQUEST = 'RestaurantProvider/REMOVE_RESTAURANT_MENU_ITEM_REQUEST';
export const REMOVE_RESTAURANT_MENU_ITEM_SUCCESS = 'RestaurantProvider/REMOVE_RESTAURANT_MENU_ITEM_SUCCESS';
export const REMOVE_RESTAURANT_MENU_ITEM_ERROR = 'RestaurantProvider/REMOVE_RESTAURANT_MENU_ITEM_ERROR';

export const ASSIGN_RESTAURANT_MENU_ITEM_REQUEST = 'RestaurantProvider/ASSIGN_RESTAURANT_MENU_ITEM_REQUEST';
export const ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS = 'RestaurantProvider/ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS';
export const ASSIGN_RESTAURANT_MENU_ITEM_ERROR = 'RestaurantProvider/ASSIGN_RESTAURANT_MENU_ITEM_ERROR';

export const SEND_RESTAURANT_PASSWORD_RESET_EMAIL = 'RestaurantProvider/SEND_RESTAURANT_PASSWORD_RESET_EMAIL';

export const EXPORT_RESTAURANT_LIST_REQUEST = 'RestaurantProvider/EXPORT_RESTAURANT_LIST_REQUEST';
export const EXPORT_RESTAURANT_LIST_SUCCESS = 'RestaurantProvider/EXPORT_RESTAURANT_LIST_SUCCESS';
export const EXPORT_RESTAURANT_LIST_ERROR = 'RestaurantProvider/EXPORT_RESTAURANT_LIST_ERROR';

export const ACCEPT_ALL_RESTAURANT_REQUEST = 'RestaurantProvider/ACCEPT_ALL_RESTAURANT_REQUEST';
export const ACCEPT_ALL_RESTAURANT_SUCCESS = 'RestaurantProvider/ACCEPT_ALL_RESTAURANT_SUCCESS';
export const ACCEPT_ALL_RESTAURANT_ERROR = 'RestaurantProvider/ACCEPT_ALL_RESTAURANT_ERROR';

export const ACCEPT_RESTAURANT_REQUEST = 'RestaurantProvider/ACCEPT_RESTAURANT_REQUEST';
export const ACCEPT_RESTAURANT_SUCCESS = 'RestaurantProvider/ACCEPT_RESTAURANT_SUCCESS';
export const ACCEPT_RESTAURANT_ERROR = 'RestaurantProvider/ACCEPT_RESTAURANT_ERROR';

export const DECLINE_ALL_RESTAURANT_REQUEST = 'RestaurantProvider/DECLINE_ALL_RESTAURANT_REQUEST';
export const DECLINE_ALL_RESTAURANT_SUCCESS = 'RestaurantProvider/DECLINE_ALL_RESTAURANT_SUCCESS';
export const DECLINE_ALL_RESTAURANT_ERROR = 'RestaurantProvider/DECLINE_ALL_RESTAURANT_ERROR';

export const DECLINE_RESTAURANT_REQUEST = 'RestaurantProvider/DECLINE_RESTAURANT_REQUEST';
export const DECLINE_RESTAURANT_SUCCESS = 'RestaurantProvider/DECLINE_RESTAURANT_SUCCESS';
export const DECLINE_RESTAURANT_ERROR = 'RestaurantProvider/DECLINE_RESTAURANT_ERROR';

export const GET_RESTAURANT_LIST_REQUEST = 'RestaurantProvider/GET_RESTAURANT_LIST_REQUEST';
export const GET_RESTAURANT_LIST_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_LIST_SUCCESS';
export const GET_RESTAURANT_LIST_ERROR = 'RestaurantProvider/GET_RESTAURANT_LIST_ERROR';

export const GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST';
export const GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS';
export const GET_RESTAURANT_LIST_FOR_SUPPLIER_ERROR = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_SUPPLIER_ERROR';

export const GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST';
export const GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS';
export const GET_RESTAURANT_LIST_FOR_OPERATOR_ERROR = 'RestaurantProvider/GET_RESTAURANT_LIST_FOR_OPERATOR_ERROR';

export const GET_RESTAURANT_DETAIL_REQUEST = 'RestaurantProvider/GET_RESTAURANT_DETAIL_REQUEST';
export const GET_RESTAURANT_DETAIL_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_DETAIL_SUCCESS';
export const GET_RESTAURANT_DETAIL_ERROR = 'RestaurantProvider/GET_RESTAURANT_DETAIL_ERROR';

export const RESET_ALL_ASSIGNED_MENU_ITEM_LIST = 'RestaurantProvider/RESET_ALL_ASSIGNED_MENU_ITEM_LIST';

export const DISABLE_RESTAURANT_ACCOUNT_REQUEST = 'RestaurantProvider/DISABLE_RESTAURANT_ACCOUNT_REQUEST';
export const DISABLE_RESTAURANT_ACCOUNT_SUCCESS = 'RestaurantProvider/DISABLE_RESTAURANT_ACCOUNT_SUCCESS';
export const DISABLE_RESTAURANT_ACCOUNT_ERROR = 'RestaurantProvider/DISABLE_RESTAURANT_ACCOUNT_ERROR';

export const ENABLE_RESTAURANT_ACCOUNT_REQUEST = 'RestaurantProvider/ENABLE_RESTAURANT_ACCOUNT_REQUEST';
export const ENABLE_RESTAURANT_ACCOUNT_SUCCESS = 'RestaurantProvider/ENABLE_RESTAURANT_ACCOUNT_SUCCESS';
export const ENABLE_RESTAURANT_ACCOUNT_ERROR = 'RestaurantProvider/ENABLE_RESTAURANT_ACCOUNT_ERROR';
export const UPDATE_RESTAURANT_REQUEST = 'RestaurantProvider/UPDATE_RESTAURANT_REQUEST';
export const UPDATE_RESTAURANT_SUCCESS = 'RestaurantProvider/UPDATE_RESTAURANT_SUCCESS';
export const UPDATE_RESTAURANT_ERROR = 'RestaurantProvider/UPDATE_RESTAURANT_ERROR';

export const GET_RESTAURANT_SELECT_OPTIONS_REQUEST = 'RestaurantProvider/GET_RESTAURANT_SELECT_OPTIONS_REQUEST';
export const GET_RESTAURANT_SELECT_OPTIONS_SUCCESS = 'RestaurantProvider/GET_RESTAURANT_SELECT_OPTIONS_SUCCESS';
export const GET_RESTAURANT_SELECT_OPTIONS_ERROR = 'RestaurantProvider/GET_RESTAURANT_SELECT_OPTIONS_ERROR';

export const UPDATE_BILLING_INFO_REQUEST = 'RestaurantProvider/UPDATE_BILLING_INFO_REQUEST';
export const UPDATE_BILLING_INFO_SUCCESS = 'RestaurantProvider/UPDATE_BILLING_INFO_SUCCESS';
export const UPDATE_BILLING_INFO_ERROR = 'RestaurantProvider/UPDATE_BILLING_INFO_ERROR';

export const UPDATE_DELIVERY_INFO_REQUEST = 'RestaurantProvider/UPDATE_DELIVERY_INFO_REQUEST';
export const UPDATE_DELIVERY_INFO_SUCCESS = 'RestaurantProvider/UPDATE_DELIVERY_INFO_SUCCESS';
export const UPDATE_DELIVERY_INFO_ERROR = 'RestaurantProvider/UPDATE_DELIVERY_INFO_ERROR';

export const UPDATE_RESTAURANT_INFO_REQUEST = 'RestaurantProvider/UPDATE_RESTAURANT_INFO_REQUEST';
export const UPDATE_RESTAURANT_INFO_SUCCESS = 'RestaurantProvider/UPDATE_RESTAURANT_INFO_SUCCESS';
export const UPDATE_RESTAURANT_INFO_ERROR = 'RestaurantProvider/UPDATE_RESTAURANT_INFO_ERROR';

export const REGISTER_RESTAURANT_OWNER_REQUEST = 'RestaurantProvider/REGISTER_RESTAURANT_OWNER_REQUEST';
export const REGISTER_RESTAURANT_OWNER_SUCCESS = 'RestaurantProvider/REGISTER_RESTAURANT_OWNER_SUCCESS';
export const REGISTER_RESTAURANT_OWNER_ERROR = 'RestaurantProvider/REGISTER_RESTAURANT_OWNER_ERROR';

export const ADD_RESTAURANT_NOTE_REQUEST = 'RestaurantProvider/ADD_RESTAURANT_NOTE_REQUEST';
export const ADD_RESTAURANT_NOTE_SUCCESS = 'RestaurantProvider/ADD_RESTAURANT_NOTE_SUCCESS';
export const ADD_RESTAURANT_NOTE_ERROR = 'RestaurantProvider/ADD_RESTAURANT_NOTE_ERROR';

export const DOWNGRADE_TO_BASIC_REQUEST = 'RestaurantProvider/DOWNGRADE_TO_BASIC_REQUEST';
export const DOWNGRADE_TO_BASIC_SUCCESS = 'RestaurantProvider/DOWNGRADE_TO_BASIC_SUCCESS';
export const DOWNGRADE_TO_BASIC_ERROR = 'RestaurantProvider/DOWNGRADE_TO_BASIC_ERROR';

export const RETRY_MEMBERSHIP_PAYMENT_REQUEST = 'RestaurantProvider/RETRY_MEMBERSHIP_PAYMENT_REQUEST';
export const RETRY_MEMBERSHIP_PAYMENT_SUCCESS = 'RestaurantProvider/RETRY_MEMBERSHIP_PAYMENT_SUCCESS';
export const RETRY_MEMBERSHIP_PAYMENT_ERROR = 'RestaurantProvider/RETRY_MEMBERSHIP_PAYMENT_ERROR';

export const OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST = 'RestaurantProvider/OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST';
export const OPERATOR_CONNECT_SUPPLIER_RESTAURANT_SUCCESS = 'RestaurantProvider/OPERATOR_CONNECT_SUPPLIER_RESTAURANT_SUCCESS';
export const OPERATOR_CONNECT_SUPPLIER_RESTAURANT_ERROR = 'RestaurantProvider/OPERATOR_CONNECT_SUPPLIER_RESTAURANT_ERROR';

export const UPDATE_INFO_OEM_REQUEST = 'RestaurantProvider/UPDATE_INFO_OEM_REQUEST';
export const UPDATE_INFO_OEM_SUCCESS = 'RestaurantProvider/UPDATE_INFO_OEM_SUCCESS';
export const UPDATE_INFO_OEM_ERROR = 'RestaurantProvider/UPDATE_INFO_OEM_ERROR';
