import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { TextField } from 'components/Form';
import validate from './validate';

const ResetPasswordForm = ({ handleSubmit, actionLoading }) => (
  <Form onSubmit={handleSubmit} layout="vertical">
    <Field
      name="email"
      type="text"
      placeholder="メールアドレス"
      component={TextField}
    />
    <Form.Item>
      <Button
        className="full-w"
        type="primary"
        htmlType="submit"
        loading={actionLoading}
      >
        送信
      </Button>
    </Form.Item>
  </Form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actionLoading: PropTypes.bool,
};

export default reduxForm({ form: 'resetPasswordForm', validate })(
  ResetPasswordForm
);
