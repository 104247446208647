import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Space, Switch } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import './style.less';

const SwitchField = (props) => {
  const {
    placeholder,
    label,
    colon,
    input,
    meta: {
      touched, invalid, error, valid
    },
    disabled,
    required,
    requiredSpace,
    description,
    ...otherProps
  } = props;
  const isError = touched && invalid && error;
  const requiredIconColor = valid ? '#399E0E' : 'rgba(0, 0, 0, 0.25)';
  const requiredSpaceColor = requiredSpace ? 'transparent' : requiredIconColor;
  return (
    <Form.Item
      help={
        isError && (
          <Space size={4}>
            <InfoCircleOutlined />
            {error}
          </Space>
        )
      }
      validateStatus={isError ? 'error' : ''}
      label={label}
      colon={colon}
      className="field-form-item"
    >
      <div className="flex items-center">
        {description}
        <Switch
          {...input}
          checked={input?.value}
          {...otherProps}
          placeholder={placeholder}
          disabled={disabled}
          className=""
          onBlur={(e) => e.preventDefault()}
        />

        {(required || requiredSpace) && (
          <CheckCircleFilled
            style={{
              fontSize: 16,
              marginLeft: 8,
              color: requiredSpaceColor,
            }}
          />
        )}
      </div>
    </Form.Item>
  );
};

SwitchField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.any,
  colon: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool,
  requiredSpace: PropTypes.bool,
};

export default SwitchField;
