import { createSelector } from 'reselect';

const selectMenu = (state) => state.menuProvider || {};

const makeSelectMenuItemList = () => createSelector(selectMenu, (state) => state.menuItemList);
const makeSelectMenuItemTotal = () => createSelector(selectMenu, (state) => state.menuItemTotal);
const makeSelectPagingMenuItemList = () => createSelector(selectMenu, (state) => state.pagingMenuItemList);
const makeSelectMenuItemDetail = () => createSelector(selectMenu, (state) => state.menuItemDetail);
const makeSelectMenuDataLoading = () => createSelector(selectMenu, (state) => state.dataLoading);
const makeSelectMenuActionLoading = () => createSelector(selectMenu, (state) => state.actionLoading);
const makeSelectUnitList = () => createSelector(selectMenu, (state) => state.unitList);

export {
  makeSelectMenuItemList,
  makeSelectMenuItemTotal,
  makeSelectPagingMenuItemList,
  makeSelectMenuItemDetail,
  makeSelectMenuDataLoading,
  makeSelectMenuActionLoading,
  makeSelectUnitList
};
